import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Loader from "../sharedComponents/Loader";
import Pagination from "../sharedComponents/Pagination";

export const Table = ({ data, columns, pageSize, rowProps, rowGroupProps, theadFilterProps, cellProps, noDataText }) => {
	const [showPaginatiion, setPagination] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [currentDataLength, setCurrentDataLength] = useState(0);
	const tableRef = useRef(null);

	useEffect(() => {
		if (tableRef && tableRef.current) {
			const currentDataLength =
				filtered.length > 0 ? tableRef.current.getResolvedState().sortedData.length : data ? data.length : 0;
			setCurrentDataLength(currentDataLength);
			const pageCount = pageSize ? pageSize : 10;
			const show = currentDataLength > pageCount;
			if (show !== showPaginatiion) {
				setPagination(show);
			}
		}
	}, [pageSize, showPaginatiion, tableRef, data, filtered]);

	useEffect(() => {
		if (!data) {
			setFiltered([]);
		}
	}, [data]);

	return (
		<React.Fragment>
			{!data && <Loader />}
			<ReactTable
				className="table-container"
				ref={tableRef}
				loading={data ? false : true}
				data={data ? data : []}
				columns={columns}
				noDataText={currentDataLength === 0 ? (noDataText ? noDataText : "No Data Found") : ""}
				minRows={0}
				showPagination={false}
				showPageSizeOptions={false}
				filtered={filtered}
				onFilteredChange={(filtered) => {
					setFiltered(filtered);
					setPageNumber(0);
					localStorage.setItem("collectiveFilter", JSON.stringify(filtered));
				}}
				page={pageNumber}
				pageSize={pageSize ? pageSize : 10}
				showPageJump={false}
				resizable={false}
				getTrProps={rowProps}
				getTheadFilterProps={theadFilterProps}
				getTrGroupProps={rowGroupProps}
				getTdProps={cellProps}
				loadingText=""
				previousText=""
				nextText=""
			/>
			{showPaginatiion && (
				<Pagination
					activePage={pageNumber + 1}
					itemsCountPerPage={pageSize ? pageSize : 10}
					totalItemsCount={currentDataLength}
					pageRangeDisplayed={7}
					onChange={(pageNumber) => setPageNumber(pageNumber - 1)}
				/>
			)}
		</React.Fragment>
	);
};
