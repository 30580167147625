import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

import MediaDropZone from "../sharedComponents/DropZone";
import Loader from "../sharedComponents/Loader";
import MediaCard from "../sharedComponents/MediaCard";
import SharedModal from "../sharedComponents/Modal";
import NoData from "../sharedComponents/NoData";
import { setSideMenu } from "../Sidemenu/actions";
import upload from "../../assets/images/modal-icon.png";
import { getUserDetails } from "../../utils/verifyUser";

import { getMediaGallery, deleteMedia, mediaRename, uploadMedia, assetsMap } from "./actions";
import Header from "../header";

import "./Gallery.scss";
import { useTranslation } from "react-i18next";

const ImageGalleryListing = ({
	history,
	setSideMenu,
	menu,
	getMediaGallery,
	deleteMedia,
	mediaRename,
	uploadMedia,
	gallery,
	responseCount,
	assetsMap,
}) => {
	const {t} = useTranslation();
	const [show, setShow] = useState(null);
	const [isImageGallery] = useState(true);
	const [path, setPath] = useState("");
	const [deleteItem, setDeleteItem] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [imageUploadList, setImageUploadList] = useState([]);
	const [imageList, setImageList] = useState(gallery && gallery.length > 0 ? gallery : []);
	const [isLoading, setIsLoading] = useState(false);
	const [index, setIndex] = useState(1);
	const [searchTerm, setSearchTerm] = useState(null);
	const [loader, setLoader] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [deleteAll, setDeleteAll] = useState(false);
	const [deleteItemList, setDeleteItemList] = useState([]);
	const [fileTimeOut, setFileTimeOut] = useState(null);
	const [searchTimeout, setSearchTimeout] = useState(null);

	const selectAllRef = useRef(null);

	const userRole = getUserDetails() && getUserDetails().role && getUserDetails().role.description;

	useEffect(() => {
		searchTerm &&
			getMediaGallery(`image&SortBy=LastModified&SortDirection=Desc&FileName=${searchTerm}`).then((res) => {
				if (res && res.response && res.response.records) {
					if (res.response.records.length > 0) {
						setImageList(res.response.records);
					} else {
						setImageList([]);
					}
				}
			});
	}, [searchTerm, getMediaGallery, setImageList]);

	useEffect(() => {
		setLoader(true);
		getMediaGallery("image&SortBy=LastModified&SortDirection=Desc").then((res) => {
			if (res && res.response && res.response.records) {
				setLoader(false);
				setImageList([...res.response.records]);
			}
		});
	}, [getMediaGallery, setImageList, setLoader]);

	useEffect(() => {
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: location[2], path: "" }],
			language: "",
		});
	}, [history, setSideMenu]);

	const handleClose = () => setShow(null);

	const handleUploadClose = () => {
		setShow(null);
		setUploadFailedList([]);
		failedEntry = [];
	};

	const renderUpdatedList = () => {
		return getMediaGallery(`image&SortBy=LastModified&SortDirection=Desc&Index=${index}`).then((res) => {
			if (res.response.hasNext === true) {
				setIsLoading(false);
				setLoader(false);
				setIndex(index + 1);
				res && res.response && res.response.records && setImageList([...imageList, ...res.response.records]);
			} else {
				setLoader(false);
				setIndex(1);
			}
		});
	};

	const [uploadFailedList, setUploadFailedList] = useState([]);
	let failedEntry = [];

	const uploadFile = (i) => {
		if (i < imageUploadList.length) {
			setLoader(true);
			const formData = new FormData();
			formData.append("uploadedFile", imageUploadList[i]);
			uploadMedia(formData).then((res) => {
				if (res.statusCode === 200) {
					uploadFile(i + 1);
				} else if (res.statusCode === 709) {
					failedEntry.push(imageUploadList[i]);
					setUploadFailedList([...failedEntry]);
					uploadFile(i + 1);
				} else {
					setLoader(false);
					NotificationManager.error(res.statusCode === 704 ? t("helpers.gallery.filelimit") : t("helpers.utils.notification.error"), "", 3000);
				}
			});
		} else if (i >= imageUploadList.length) {
			failedEntry && failedEntry.length > 0 && setShow("uploadFailed");
			assetsMap();
			getMediaGallery("image&SortBy=LastModified&SortDirection=Desc").then((res) => {
				if (res && res.response && res.response.records) {
					setLoader(false);
					setImageList([...res.response.records]);
					failedEntry.length === 0 && NotificationManager.success(t("helpers.utils.uploadsuccesful"), "", 3000);
				}
			});
		}
	};

	const handleUpload = (e) => {
		setLoader(true);
		uploadFile(0);
		setShow(null);
		setImageUploadList([]);
	};

	const multipleDelete = () => {
		setLoader(true);
		setShow(null);
		if (deleteItemList && deleteItemList.length > 0) {
			const deleteFileKey = deleteItemList.map((item) => item.fileKey);
			deleteMedia(deleteAll ? { deleteAll: true } : { fileKeys: deleteFileKey }).then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						assetsMap().then((response) => {
							if (deleteAll) {
								NotificationManager.success(t("helpers.audio.deletedsuccess"), "", 3000);
								setLoader(false);
								setImageList([]);
							} else {
								getMediaGallery("image&SortBy=LastModified&SortDirection=Desc").then((res) => {
									if (res && res.response && res.response.records) {
										setImageList([...res.response.records]);
										setLoader(false);
										NotificationManager.success(t("helpers.audio.deletedsuccess"), "", 3000);
									}
								});
							}
						});
					} else {
						NotificationManager.error(res.response.message, "", 2000);

						setLoader(false);
					}
					setDeleteItemList([]);
				}
			});
		}
	};

	const deleteImage = () => {
		setLoader(true);
		const deleteFileKey = imageList.filter((item) => (item.filePath === deleteItem ? item : null));
		deleteFileKey[0] &&
			deleteFileKey[0].fileKey &&
			deleteMedia({ fileKeys: [deleteFileKey[0].fileKey] }).then((res) => {
				if (res) {
					if (res.statusCode === 200) {
						getMediaGallery("image&SortBy=LastModified&SortDirection=Desc").then((res) => {
							if (res && res.response && res.response.records) {
								setImageList([...res.response.records]);
								setLoader(false);
								NotificationManager.success(t("helpers.audio.deletedsuccess"), "", 3000);
							}
						});
					} else {
						NotificationManager.error(res.response.message, "", 2000);
						setLoader(false);
					}
					setShow(null);
					setDeleteItem("");
				}
			});
	};

	const handleImageZoom = (path) => {
		setShow("zoomImage");
		setPath(path);
	};

	const handleDelete = (item) => {
		setDeleteItem(item);
		setShow("ConfirmationModal");
	};

	const handleImageRename = (oldName, newName, id) => {
		setLoader(true);
		mediaRename({
			oldFileName: oldName,
			newFileName: "xmlassets/image/" + newName,
		}).then((res) => {
			if (res.statusCode === 200) {
				setIsEditing(false);
				setLoader(false);
				setImageList(
					imageList.map((item) =>
						item.filePath === id
							? {
									...item,
									fileName: newName,
									fileKey: "xmlassets/image/" + newName,
									filePath: "https://biblingo.s3.amazonaws.com/xmlassets/image/" + newName,
							  }
							: item
					)
				);
				NotificationManager.success(t("helpers.gallery.renamesuccess"), "", 3000);
			} else {
				NotificationManager.error(res.response.message, "", 2000);
				setLoader(false);
			}
			setIsEditing(false);
		});
	};

	const handleChange = (e) => {
		const value = e.target.value;
		setLoader(true);
		clearTimeout(searchTimeout);
		const timeOut = setTimeout(() => {
			if (value && value.length > 0) {
				setSearchTerm(value);
				setLoader(false);
			} else {
				setSearchTerm("");
				getMediaGallery("image&SortBy=LastModified&SortDirection=Desc").then((res) => {
					if (res && res.response && res.response.records) {
						setLoader(false);
						setImageList([...res.response.records]);
					}
				});
			}
		}, 500);
		setSearchTimeout(timeOut);
	};

	const uploadModalHead = () => <span>{t("helpers.gallery.uploadimg")}</span>;

	const uploadModalBody = () => (
		<div className="px-3">
			<div className="upload-container rounded">
				<MediaDropZone
					uploadFormat=".jpeg,.jpg,.png,.gif"
					fileSize={10485760}
					maxFiles={20}
					inputWithFilesContent={"+"}
					submitButtonContent={null}
					isImageGallery={isImageGallery}
					uploadedFileList={imageUploadList}
					fileTimeOut={fileTimeOut}
					setFileTimeOut={setFileTimeOut}
					setUploadedFileList={setImageUploadList}
				/>
			</div>
		</div>
	);

	const uploadModalFooter = () => (
		<div>
			<button className="btn-cancel" onClick={() => setShow(null)}>
				{t("helpers.utils.cancel")}
			</button>
			<button
				className={"btn-upload"}
				onClick={handleUpload}
				disabled={imageUploadList && imageUploadList.length > 0 ? false : true}>
				{t("helpers.utils.upload")}
			</button>
		</div>
	);

	const zoomImageView = () => (
		<div>
			<img src={path} alt="" />
		</div>
	);

	const confirmationModalBody = () => (
		<React.Fragment>
			{t("helpers.utils.deleteprompt")}
			<b>
				{deleteItemList && deleteItemList.length > 0
					? deleteAll
						? ` ${responseCount} files?`
						: deleteItemList.length > 1
						? ` ${deleteItemList.length} files?`
						: ` ${deleteItemList.length} file?`
					: ` ${deleteItem && deleteItem.split("/").pop()}? `}
			</b>
		</React.Fragment>
	);

	const confirmationModalFooter = () => (
		<React.Fragment>
			<button className="btn-cancel" onClick={() => setShow(null)}>
				{t("helpers.utils.no")}
			</button>
			<button
				className="btn-upload"
				onClick={() => (deleteItemList && deleteItemList.length > 0 ? multipleDelete() : deleteImage())}>
				{t("helpers.utils.yes")}
			</button>
		</React.Fragment>
	);

	const uploadFailedModal = () => {
		return (
			<React.Fragment>
				<div className="upload-failed d-flex justify-content-center ">
					<img src={upload} alt="upload" />
				</div>
				<h3 className="upload-modal-text mb-2 text-center">{t("helpers.gallery.fileexists")}</h3>
				<ul className="list-unstyled text-center">
					{uploadFailedList &&
						uploadFailedList.length > 0 &&
						uploadFailedList.map((item, index) => <li key={index}>{item.name}</li>)}
				</ul>
			</React.Fragment>
		);
	};

	const handleSelectAll = () => {
		if (selectAllRef.current.checked === true) {
			setSelectAll(true);
			setDeleteItemList([...imageList]);
		} else {
			setSelectAll(false);
			setDeleteItemList([]);
		}
	};
	const handleClearSelection = () => {
		setDeleteAll(false);
		setSelectAll(false);
		setDeleteItemList([]);
		selectAllRef.current.checked = false;
	};

	return (
		<React.Fragment>
			{loader && <Loader />}
			<div
				className="empty"
				onScroll={(e) => {
					if (e && e.nativeEvent && e.nativeEvent.target) {
						const scrollHeight = e.nativeEvent.target.scrollHeight;
						const offsetHeight = e.nativeEvent.target.offsetHeight;
						const scrollTop = e.nativeEvent.target.scrollTop;

						if (scrollHeight - offsetHeight - 50 < scrollTop) {
							if (!isLoading && show === null) {
								setLoader(true);
								renderUpdatedList();
								setIsLoading(true);
							}
						}
					}
				}}>
				<Header menu={menu} head={t("helpers.utils.images")} history={history} />

				<div className="d-flex justify-content-between px-4 sub-header-wrapper">
					<div className="position-relative search-container">
						<i className="icon icon-search" />
						<input
							className="media-search"
							type="text"
							placeholder={t("languagelearning.fluencydrills.searchhere")}
							onChange={(e) => handleChange(e)}
						/>
					</div>
					<div className="d-flex align-items-center">
						{deleteItemList && deleteItemList.length > 0 && (
							<div className="position-relative d-flex align-items-center">
								<label className="custom-checkbox mr-2">
									<span className="pl-4">{t("helpers.utils.selectall")}</span>
									<input type="checkbox" ref={selectAllRef} onChange={() => handleSelectAll()} />
									<span className="tickmark"></span>
								</label>

								<button className="mr-2 delete-icon" onClick={() => setShow("ConfirmationModal")}>
									<i className="icon icon-delete1"></i>
								</button>
							</div>
						)}
						{userRole === "Administrator" && (
							<Button onClick={() => setShow("uploadModal")} className="upload-btn">
								<i className="icon icon-upload mr-2" /> {t("helpers.utils.upload")}
							</Button>
						)}
					</div>
				</div>
				{selectAll && (
					<div className="px-4 mt-4">
						<div className="delete-all-container text-center">
							{deleteAll ? (
								<div>
									{responseCount} images were selected .
									<b className="select-all-phrase pl-2" onClick={() => handleClearSelection()}>
										clear selection
									</b>
								</div>
							) : (
								<div>
									{imageList && imageList.length} images selected .
									<b className="select-all-phrase pl-2" onClick={() => setDeleteAll(true)}>
										Select All images in the gallery.
									</b>
								</div>
							)}
						</div>
					</div>
				)}
				<SharedModal
					show={show === "uploadModal" ? true : false}
					handleClose={handleClose}
					modalHead={uploadModalHead()}
					modalBody={uploadModalBody()}
					modalFooter={uploadModalFooter()}
					centered="true"
					modalClass="gallery"
				/>
				<SharedModal
					show={show === "zoomImage" ? true : false}
					handleClose={handleClose}
					modalBody={zoomImageView()}
					modalClass="zoom-image-wrapper"
					icon="icon-close-2"
				/>
				<SharedModal
					show={show === "ConfirmationModal" ? true : false}
					handleClose={handleClose}
					modalBody={confirmationModalBody()}
					modalFooter={confirmationModalFooter()}
					modalClass="confirm-modal"
				/>

				<SharedModal
					show={show === "uploadFailed" ? true : false}
					handleClose={handleUploadClose}
					modalBody={uploadFailedModal()}
					modalClass=""
				/>

				<div className="px-4 mt-4">
					{imageList && imageList.length > 0 ? (
						<div className="row media-gallery">
							{imageList.map((item) => (
								<div className="col-lg-3 col-md-4 col-sm-2" key={item.filePath}>
									{item.filePath && (
										<MediaCard
											cardData={item}
											setShow={handleImageZoom}
											setDeleteItem={handleDelete}
											isImageGallery={isImageGallery}
											handleImageRename={handleImageRename}
											isEditing={isEditing}
											setIsEditing={setIsEditing}
											setDeleteItemList={setDeleteItemList}
											deleteItemList={deleteItemList}
											selectAll={selectAll}
										/>
									)}
								</div>
							))}
						</div>
					) : (
						<NoData />
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
	gallery: state.mediaGallery.gallery,
	responseCount: state.mediaGallery.count,
});

const mapDispatchToProps = {
	setSideMenu,
	getMediaGallery,
	deleteMedia,
	mediaRename,
	uploadMedia,
	assetsMap,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageGalleryListing));
