import React, { useState } from "react";
import moment from "moment";
import { useTranslation, Translation } from "react-i18next";

const Question = ({ id, description, setShowAnswer, askedOn, getAnswer, setSelected, lastAnsweredOn }) => (
	<div
		className={`d-flex align-items-center mr-2 border-bottom-qa pointer question ${setSelected}`}
		onClick={() => {
			if (setSelected) {
				setSelected();
				getAnswer(id);
				setShowAnswer(true);
			}
		}}>
		<div className="icon-div">Q</div>
		<div className="d-flex flex-column">
			<div>
				<Translation>
					{(t) => (
						<>
							<span className="small-text">
								{t("helpers.utils.learning.askquestion.askedon", {
									date: moment(askedOn).format("DD MMM YYYY"),
								})}
							</span>
							<span className="small-text ml-3">
								{t("helpers.utils.learning.askquestion.lastupdated", {
									date: moment(lastAnsweredOn).format("DD MMM YYYY"),
								})}
							</span>
						</>
					)}
				</Translation>
			</div>
			<div className="description mt-1">{description}?</div>
		</div>
	</div>
);

const AnswerList = ({ answers, answerCount }) => {
	const [showAll, setShowAll] = useState(false);
	const { t } = useTranslation();
	const answerBody = (id, answeredBy, answeredOn, description, isVerified) => (
		<div key={id} className="d-flex  mr-2 ml-2 mb-2 border-bottom-qa mb-1 pb-1 pt-1">
			<div className="icon-div">A</div>
			<div className="d-flex flex-column">
				<div className="title">{answeredBy}</div>
				<div className="mt-1">
					<span className="small-text mb-3">
						{t("helpers.utils.learning.askquestion.askedon", {
							date: moment(answeredOn).format("DD MMM YYYY [at] h:mm A z "),
						})}
					</span>
				</div>
				<div className="mb-2" style={{ fontSize: 14, color: "#072F40" }}>
					{description}
				</div>
			</div>
			{isVerified && <div className="best-answer">{t("helpers.utils.learning.askquestion.bestanswer")}</div>}
		</div>
	);
	return (
		<div>
			<div className="answer-count">
				<span className="mr-2">{answerCount}</span>
				<span>{t("helpers.utils.learning.askquestion.answers")}</span>
			</div>

			{showAll
				? answers.map((el) => answerBody(el.id, el.answeredBy, el.answeredOn, el.description, el.isVerified))
				: answerBody(
						answers[0].id,
						answers[0].answeredBy,
						answers[0].answeredOn,
						answers[0].description,
						answers[0].isVerified
				  )}
			{answerCount > 1 && !showAll && (
				<div className="d-flex justify-content-end align-items-center pt-2 pl-2 pr-2">
					<span className="see-all pointer" onClick={() => setShowAll(true)}>
						{t("helpers.utils.learning.askquestion.seeall")}
					</span>
				</div>
			)}
		</div>
	);
};

const PreviousQA = ({ records, getQuestionAnswer, loading, close }) => {
	const { t } = useTranslation();
	const [showAnswer, setShowAnswer] = useState(false);
	const [answer, setAnswer] = useState([]);
	const [selected, setSelected] = useState();
	const [loadingAnswers, setLoadingAnswer] = useState(false);
	const [answerCount, setAnswerCount] = useState(0);

	const getAnswer = (id) => {
		setLoadingAnswer(true);
		getQuestionAnswer(id).then(async (res) => {
			setAnswer(res.response.records);
			setAnswerCount(res.response.count);
			setLoadingAnswer(false);
		});
	};
	return (
		<div className="pre-qa-container">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					{showAnswer && (
						<div className="round-box mr-2" onClick={() => setShowAnswer(false)}>
							<span className="icon icon-prev pointer mr-1"></span>
						</div>
					)}
					<h5 className="title-header">{t("helpers.utils.learning.askquestion.previousq&a")}</h5>
				</div>
				<span onClick={close}>
					<i className="icon icon-close pointer"></i>
				</span>
			</div>
			{showAnswer ? (
				<>
					<Question setShowAnswer={setShowAnswer} askedOn={selected.askedOn} description={selected.description} />
					<div className="answer-height">
						{loadingAnswers ? (
							<div style={{ textAlign: "center", padding: "3%" }}>
								{t("helpers.utils.learning.askquestion.loading")}
							</div>
						) : answer.length > 0 ? (
							<AnswerList answers={answer} answerCount={answerCount} />
						) : (
							<div style={{ textAlign: "center", padding: "3%" }}>
								{t("helpers.utils.learning.askquestion.noanswers")}
							</div>
						)}
					</div>
				</>
			) : (
				<div className="qa-height">
					{loading ? (
						<div style={{ textAlign: "center", padding: "10%" }}>
							{t("helpers.utils.learning.askquestion.loading")}
						</div>
					) : (
						records.map((el, index) => {
							return (
								<Question
									key={el.id}
									id={el.id}
									setShowAnswer={setShowAnswer}
									askedOn={el.askedOn}
									description={el.description}
									getAnswer={getAnswer}
									setSelected={() => setSelected(el)}
									lastAnsweredOn={el.lastAnsweredOn}
								/>
							);
						})
					)}
				</div>
			)}
		</div>
	);
};

export default PreviousQA;
