import React from "react";
import "./loaderStyle.css";

const Loader = props => {
    return (
        <div
            id="preloader"
            className="h-100 w-100"
            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div id="loader"></div>
        </div>
    );
};

export default Loader;
