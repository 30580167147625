export const STUDENT_LANGUAGE_LEARNING = {
	GET_LEVEL_DETAILS: "GET_LEVEL_DETAILS",
	GET_LESSON_DETAILS: "GET_LESSON_DETAILS",
	UPDATE_FIELD: "UPDATE_FIELD",
	CHANGE_ACTIVITY: "CHANGE_ACTIVITY",
	VERIFY_ACTIVITY: "VERIFY_ACTIVITY",
	GET_STAGE_DETAILS: "GET_STAGE_DETAILS",
	GET_LEARNING_POINTS: "GET_LEARNING_POINTS",
	ASK_QUESTION: "ASK_QUESTION",
	GET_SIDE_PANEL: "GET_SIDE_PANEL",
	SET_AUDIO_SPEED: "SET_AUDIO_SPEED",
	GET_QUESTIONS: "GET_QUESTIONS",
	GET_ANSWER: "GET_ANSWER",
	SET_PRACTICE: "SET_PRACTICE",
	GET_PRACTICE: "GET_PRACTICE",
	HANDLE_SIDEPANEL:"HANDLE_SIDEPANEL"
};
