import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";

import { updateField } from "../LanguageLearning/actions";
import { getLanguageFileStatus } from "./actions";
import Logo from "../../../assets/images/logocopy.png";
import MobileLogo from "../../../assets/images/logo.png";
import Greek from "../../../assets/images/greek.png";
import Hebrew from "../../../assets/images/hebrew.png";
import { getUserDetails } from "../../../utils/verifyUser";
import UserDetailsForm from "./userDetailsForm";
import { sessionLogging } from "../../Sidemenu/actions";
import "./home.scss";
import { useTranslation } from "react-i18next";

const StudentHome = ({
	history,
	getLanguageFileStatus,
	isStudentMode,
	fileStatus,
	updateField,
	showPronunciationModal,
	sessionLogging,
	match,
}) => {
	const [hebrewStatus, setHebrewStatus] = useState(false);
	const [greekStatus, setGreekStatus] = useState(false);
	const userDetails = getUserDetails();
	const { t } = useTranslation();

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		if (localStorage.getItem("showPronunciationModal")) {
			updateField("showPronunciationModal", true);
		}
	}, [updateField]);

	useEffect(() => {
		getLanguageFileStatus();
	}, [getLanguageFileStatus]);

	useEffect(() => {
		if (getUserDetails().languages.length === 1) {
			handleLanguageClick(getUserDetails().languages[0] === 0 ? "Hebrew" : "Greek");
		}
	});

	useEffect(() => {
		if (fileStatus && fileStatus.hebrew && fileStatus.greek) {
			const hebrewStatus = Object.values(fileStatus.hebrew).some((val) => !val);
			setHebrewStatus(!hebrewStatus);
			const greekStatus = Object.values(fileStatus.greek).some((val) => !val);
			setGreekStatus(!greekStatus);
		}
	}, [fileStatus]);

	const handleLanguageClick = (language) => {
		const {
			role: { description },
		} = getUserDetails();
		history.push(description === "Administrator" ? `/home/${language}/Language-Learning` : `/home/${language}/Dashboard`);
	};

	const handleLogout = () => {
		localStorage.removeItem("auth");
		localStorage.removeItem("settings");
		localStorage.removeItem("sessionId");
		// localStorage.removeItem("i18nextLng");
		const sessionId = localStorage.getItem("sessionId");
		sessionLogging({
			language: match.params.language === "Hebrew" ? 0 : 1,
			sessionId: sessionId ? sessionId : null,
			module: 6,
		});
		if (userDetails.origin === "Biblingo") history.push("/login");
		else if (userDetails.origin === "CampusEdu") window.location.href = "https://learn.stg.campusedu.tech/";
		else if (userDetails.origin === "Fuller") window.location.href = "https://www.fuller.edu/";
	};

	const changeMode = () => {
		updateField("isStudentMode", !isStudentMode);
		history.push(isStudentMode ? "/Home/Greek/Vocabulary" : "/home");
	};

	const renderUserDetails = () => (
		<div className="d-flex flex-column dtl-section">
			<p className="user-name color-white mb-0 font-18">
				{userDetails.firstName} {userDetails.lastName}
			</p>
			{isStudentMode && (
				<Dropdown className="mode-switch" as="div">
					<Dropdown.Toggle as="div" className="d-flex align-items-center justify-content-end current-mode">
						<p>{isStudentMode ? "Student" : getUserDetails().role.description}</p>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={changeMode}>
							{isStudentMode ? getUserDetails().role.description : "Student"}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)}
		</div>
	);

	const renderHeader = () => (
		<div className="d-flex justify-content-between align-items-center">
			<img src={MobileLogo} alt="" className="mobile-logo" />
			<div className="d-flex align-items-center justify-content-end w-100 p-3">
				<div className=" color-white text-right">
					{!isStudentMode && <span className="font-12">{t("landingpage.welcomeback")}</span>}
					{renderUserDetails()}
				</div>
				<Dropdown className="user-home">
					<Dropdown.Toggle className="hover-shadow">
						<label className="username-label">
							{userDetails.firstName ? userDetails.firstName.charAt(0) : ""}
							{userDetails.lastName ? userDetails.lastName.charAt(0) : ""}
						</label>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => history.push("/Profile")}>
							<i className="icon icon-user-2 mr-2" /> {t("header.myprofile.name")}
						</Dropdown.Item>
						{getUserDetails().origin === "Biblingo" && (
							<Dropdown.Item onClick={() => history.push("/Change-Password")}>
								<i className="icon icon-key mr-2" /> {t("header.changepassword.name")}
							</Dropdown.Item>
						)}
						<Dropdown.Item onClick={handleLogout}>
							<i className="icon icon-logout mr-2" /> {t("header.logout")}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>
	);

	const renderLanguages = () => (
		<div className="student-options d-flex align-items-center flex-column justify-content-center">
			<p className="color-white font-18">{t("landingpage.selectlanguagetostudy")}</p>
			<div className="d-flex justify-content-center align-items-center">
				<button
					className={`d-flex flex-column border-0 student-home ${
						hebrewStatus ? "pointer" : "not-allowed"
					} hover-shadow`}
					{...(hebrewStatus && {
						onClick: () => handleLanguageClick("Hebrew"),
					})}>
					<div className="circle-100 rounded-circle mb-3">
						<img src={Hebrew} alt="" />
					</div>
					<span> {t("helpers.utils.learnhebrew")}</span>
				</button>
				<button
					className={`d-flex flex-column border-0 ${greekStatus ? "pointer" : "not-allowed"} hover-shadow`}
					{...(greekStatus && {
						onClick: () => handleLanguageClick("Greek"),
					})}>
					<div className="circle-100 rounded-circle mb-3">
						<img src={Greek} alt="" />
					</div>
					<span> {t("helpers.utils.learngreek")}</span>
				</button>
				<ReactTooltip />
			</div>
		</div>
	);

	return (
		<div className="d-flex w-100 h-100 bg-white">
			<UserDetailsForm
				show={showPronunciationModal}
				handleClose={() => {
					localStorage.removeItem("showPronunciationModal");
					updateField("showPronunciationModal", false);
				}}
			/>
			<div className="w-50 d-flex justify-content-center align-items-center student-logo-container">
				<img src={Logo} alt="" />
			</div>
			<div className="w-50 student-dashboard">
				{renderHeader()}
				{renderLanguages()}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	fileStatus: state.studentHome.fileStatus,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	showPronunciationModal: state.studentLanguageLearning.showPronunciationModal,
});

const mapDispatchToProps = {
	getLanguageFileStatus,
	updateField,
	sessionLogging,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentHome));
