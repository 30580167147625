import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Keycloak from "keycloak-js";
import { withRouter } from "react-router-dom";
import Loader from "../sharedComponents/Loader";
import { fullerAuth } from "./actions";
import { NotificationManager } from "react-notifications";
import { Button, Card } from "react-bootstrap";
import logo from "../../assets/images/logocopy.png";

const DesktopLauncher = ({ history, fullerAuth }) => {
	const { loading, setLoading } = useState(true);
	const { user, setUser } = useState({});

	const initOptions = {
		url: "https://test1-equipauth.fuller.edu/auth/",
		realm: "equip",
		clientId: "biblingo-test",
		onLoad: "login-required",
	};

	const fullerLogin = async (keycloak, courseId) => {
		const {
			idToken,
			idTokenParsed: { given_name: firstName, family_name: lastName, email },
		} = keycloak;
		const language = ["GK101", "GK102"].includes(courseId) ? "Greek" : "Hebrew";
		const creds = {
			token: idToken,
			courseID: courseId,
			email: email,
			firstName: firstName,
			lastName: lastName,
			language: language,
		};
		setUser(creds);
		const { statusCode, response } = await fullerAuth(creds);
		if (statusCode === 200) {
			setLoading(false);
			window.location.href = `BiblingoURL:\\\\app.biblingo.org?email=${email}&courseid=${courseId}&token=${idToken}`;
		} else {
			NotificationManager.error(response && response.message, "Error", 2000);
			history.push(`/login`);
		}
	};

	useEffect(() => {
		const keycloak = Keycloak(initOptions);
		const {
			location: { search },
		} = window;
		if (search && search.length) {
			const courseId = search.substring(1).split("=")[1];
			localStorage.setItem("courseId", courseId);
		}
		keycloak.init({ onLoad: initOptions.onLoad }).success((authenticated) => {
			if (keycloak) {
				if (authenticated) {
					const courseId = localStorage.getItem("courseId");
					fullerLogin(keycloak, courseId);
				}
			}
		});
	});
	return (
		<>
			<div className="d-flex w-100 login-wrapper">
				<div className="login-bg-wrapper"></div>
				{loading ? (
					<Loader />
				) : (
					<div className="campus">
						<Card className="p-md-5 login-section">
							<img src={logo} className="logo-img floater" alt="logo" />

							<p className="mb-3 login-wecome-text">{`Launch Desktop client by clicking Launch Desktop Client Button.`}</p>
							<Button
								href={`BiblingoURL:\\\\app.biblingo.org?email=${user?.email}&courseid=${user?.courseId}&token=${user?.idToken}`}
								className="mb-3 rounded-lg border-0 mt-3 login-btn">
								Launch Desktop Client
							</Button>
							<p className="mb-3 login-wecome-text">{`If you want to open Biblingo on browser, click on Launch Web Application Button.`}</p>
							<Button
								href={`/home/${user?.language}/Dashboard`}
								className="mb-3 rounded-lg border-0 mt-3 login-btn">
								Launch Web Application
							</Button>
							<p className="mb-3 login-wecome-text">{`Don't have Biblingo Desktop Client, click Download Button.`}</p>
							<Button className="mb-3 rounded-lg border-0 mt-3 login-btn">Download Biblingo</Button>
						</Card>
					</div>
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	auth: state.auth.auth,
});

const mapDispatchToProps = { fullerAuth };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesktopLauncher));
