import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { NotificationManager } from "react-notifications";

import { Card, Row, Col } from "react-bootstrap";
import Loader from "../../sharedComponents/Loader";
import SharedModal from "../../sharedComponents/Modal";
import { NoContent } from "../../sharedComponents/NoContent";
import { Scrollbars } from "react-custom-scrollbars";

import Header from "../../header";
import { setSideMenu } from "../../Sidemenu/actions";
import { getUserDetails } from "../../../utils/verifyUser";
import { updateField } from "../LanguageLearning/actions";
import { getTabData, setQuestions, archieDeck, unArchieDeck, getSettings } from "./actions";
import { useTranslation } from "react-i18next";

import "./fluencyDrill.scss";
import noData from "../../../assets/images/no-data.png";

const FluencyDrills = ({
	setSideMenu,
	history,
	updateField,
	language,
	getTabData,
	setQuestions,
	archieDeck,
	unArchieDeck,
	getSettings,
	match,
}) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(t("languagelearning.fluencydrills.lessonparadigm"));
	const [currentLevel, setCurrentLevel] = useState(null);
	const [tabContent, setTabContent] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openSetting, setOpenSetting] = useState(false);
	const [selected, setSelected] = useState(0);
	const inputRef = useRef(null);
	const [numberOfQuestions, setNuberOfQuestions] = useState(0);

	const tabHeader = [
		t("languagelearning.fluencydrills.lessonparadigm"),
		t("languagelearning.fluencydrills.paradigmneedpractice"),
		t("languagelearning.fluencydrills.generalparadigms"),
	];
	useEffect(() => {
		getTabContent(activeTab);
		return () => {
			setTabContent([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: t(["languagelearning.fluencydrills.name"]), path: "" }],
			language: location[2],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location]);

	const handleDropItem = (level) => {
		setCurrentLevel(currentLevel === level ? "" : level);
	};

	const redirectToFluencyDrillLesson = (id) => history.push(`/home/${language}/fluency-drill/deck/${id}`);

	const title = (name) => (
		<Card.Title className="pointer">
			<span className="circle"></span>
			<span className="header">{name}</span>
		</Card.Title>
	);

	const archiveOrUnarchive = (e, item) => {
		e.stopPropagation();
		setLoading(true);
		if (item.isArchived) {
			unArchieDeck(item.id).then((res) => {
				if (res.statusCode === 200) {
					getTabContent(activeTab, "", 0);
					NotificationManager.success(
						t("languagelearning.fluencydrills.notification.unarchivedmsg", { name: item.name }),
						"",
						3000
					);
				}
			});
		} else {
			archieDeck(item.id).then((res) => {
				if (res.statusCode === 200) {
					getTabContent(activeTab, "", 0);
					NotificationManager.success(
						t("languagelearning.fluencydrills.notification.archivedmsg", { name: item.name }),
						"",
						3000
					);
				}
			});
		}
	};

	const archiveBody = (right, top, item) => (
		<label
			className="pointer lesson-nav-btn position-absolute"
			data-tip={
				item.isArchived
					? t("languagelearning.fluencydrills.unarchivedeck")
					: t("languagelearning.fluencydrills.archivedeck")
			}
			onClick={(e) => archiveOrUnarchive(e, item)}
			style={{ right, top }}>
			<i className="icon icon-archive" />
			<ReactTooltip />
		</label>
	);

	const lessonParadigmBody = (el) => (
		<Card key={el.lessonNumber} className="m-3 tab-card card-lesson">
			<Row className="pl-2 pr-2">
				<i className="arrow down"></i>
				<Col md={12} className="p-0">
					<div
						className="card-border-btm d-flex justify-content-between align-items-center mr-3 ml-3 pt-2 pb-2"
						onClick={() => {
							handleDropItem(el.lessonNumber);
						}}>
						{title(t("languagelearning.fluencydrills.lessonnumber", { lessonnumber: el.lessonNumber }))}
						<div className="dropdown-icon dictionary-dropdown">
							<i
								className={
									currentLevel === el.lessonNumber ? "icon-down down-font" : "icon icon-angle-arrow-down"
								}></i>
						</div>
					</div>
				</Col>
			</Row>
			<Row className="ml-1 mr-1">
				{currentLevel === el.lessonNumber &&
					el.decks.map((ele, index) => (
						<Col md={4} key={ele.id} className="p-0">
							<Card
								className="m-2 tab-card card-lesson pointer"
								onClick={() => redirectToFluencyDrillLesson(ele.id)}>
								<Card.Body className="d-flex flex-column p-2 position-relative">
									<span>
										{t("languagelearning.fluencydrills.lesson_sublesson", {
											lessonnumber: ele.mainLessonNumber,
											sublessonnumber: ele.subLessonNumber,
										})}
									</span>
									<span>{ele.name}</span>
									{typeof ele.status !== "undefined" && (
										<div className="p-2 position-absolute" style={{ top: "3px", right: "40px" }}>
											{ele.status === 2 ? (
												<span
													className="icon-check-2"
													title={t("helpers.utils.lessonstatus.completed")}>
													<span className="path1"></span>
													<span className="path2"></span>
												</span>
											) : ele.status === 1 ? (
												<span
													className="icon-clock-5"
													title={t("helpers.utils.lessonstatus.inprogress")}>
													<span className="path1"></span>
													<span className="path2"></span>
												</span>
											) : (
												<span className="icon-block" title={t("helpers.utils.lessonstatus.notstarted")}>
													<span className="path1"></span>
													<span className="path2"></span>
												</span>
											)}
										</div>
									)}
									{archiveBody(0, 0, ele)}
								</Card.Body>
							</Card>
						</Col>
					))}
			</Row>
		</Card>
	);

	const generalParadigm = (content) => {
		let isArchivedData = false;
		return (
			<>
				{content.map((el) => (
					<>
						{!el.isArchived ? (
							<Card key={el.lessonNumber} className="m-3 tab-card card-lesson">
								<Row className="pl-2 pr-2">
									<i className="arrow down"></i>
									<Col md={12} className="p-1">
										<div
											className="d-block mr-3 ml-3 pt-2 pb-2 pointer"
											onClick={() => redirectToFluencyDrillLesson(el.id)}>
											{title(el.name)}
											{typeof el.status !== "undefined" && (
												<div className="p-2 position-absolute" style={{ top: "4px", right: "60px" }}>
													{el.status === 2 ? (
														<span
															className="icon-check-2"
															title={t("helpers.utils.lessonstatus.completed")}>
															<span className="path1"></span>
															<span className="path2"></span>
														</span>
													) : el.status === 1 ? (
														<span
															className="icon-clock-5"
															title={t("helpers.utils.lessonstatus.inprogress")}>
															<span className="path1"></span>
															<span className="path2"></span>
														</span>
													) : (
														<span
															className="icon-block"
															title={t("helpers.utils.lessonstatus.notstarted")}>
															<span className="path1"></span>
															<span className="path2"></span>
														</span>
													)}
												</div>
											)}
											{archiveBody(10, 1, el)}
										</div>
									</Col>
								</Row>
							</Card>
						) : (
							(isArchivedData = true)
						)}
					</>
				))}

				{isArchivedData && (
					<Card className="m-3 tab-card card-lesson">
						<Row className="pl-2 pr-2">
							<i className="arrow down"></i>
							<Col md={12} className="p-0">
								<div
									className="card-border-btm d-flex justify-content-between align-items-center mr-3 ml-3 pt-2 pb-2"
									onClick={() => {
										handleDropItem("archived");
									}}>
									{title(t("helpers.utils.archived"))}
									<div className="dropdown-icon dictionary-dropdown">
										<i className={currentLevel ? "icon-down down-font" : "icon icon-angle-arrow-down"}></i>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="ml-1 mr-1">
							{currentLevel === "archived" &&
								content.map((el) => (
									<>
										{el.isArchived && (
											<Col md={4} key={el.id} className="p-0">
												<Card
													className="m-2 tab-card card-lesson pointer"
													onClick={() => redirectToFluencyDrillLesson(el.id)}>
													<Card.Body className="d-flex flex-column p-2 position-relative">
														<span className="name-tag">{el.name}</span>
														{typeof el.status !== "undefined" && (
															<div
																className="p-2 position-absolute"
																style={{ top: "4px", right: "40px" }}>
																{el.status === 2 ? (
																	<span
																		className="icon-check-2"
																		title={t("helpers.utils.lessonstatus.completed")}>
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</span>
																) : el.status === 1 ? (
																	<span
																		className="icon-clock-5"
																		title={t("helpers.utils.lessonstatus.inprogress")}>
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</span>
																) : (
																	<span
																		className="icon-block"
																		title={t("helpers.utils.lessonstatus.notstarted")}>
																		<span className="path1"></span>
																		<span className="path2"></span>
																	</span>
																)}
															</div>
														)}
														{archiveBody(0, 1, el)}
													</Card.Body>
												</Card>
											</Col>
										)}
									</>
								))}
						</Row>
					</Card>
				)}
			</>
		);
	};

	const forPractice = (el) => (
		<Card key={el.lessonNumber} className="m-3 tab-card card-lesson">
			<Row className="pl-2 pr-2">
				<i className="arrow down"></i>
				<Col md={12} className="p-0">
					<div
						className="card-border-btm d-flex justify-content-between align-items-center mr-3 ml-3 pt-2 pb-2"
						onClick={() => {
							handleDropItem("practice");
						}}>
						{title(t("languagelearning.fluencydrills.paradigmsforpractice"))}
						<div className="dropdown-icon dictionary-dropdown">
							<i className={currentLevel ? "icon-down down-font" : "icon icon-angle-arrow-down"}></i>
						</div>
					</div>
				</Col>
			</Row>
			<Row className="ml-1 mr-1">
				{currentLevel === "practice" &&
					el.map((ele) => (
						<Col md={4} key={ele.id} className="p-0">
							<Card
								className="m-2 tab-card card-lesson pointer"
								onClick={() => redirectToFluencyDrillLesson(ele.id)}>
								<Card.Body className="d-flex flex-column p-2 position-relative">
									{ele.mainLessonNumber && ele.subLessonNumber && (
										<span>
											{t("languagelearning.fluencydrills.lesson_sublesson", {
												lessonnumber: ele.mainLessonNumber,
												sublessonnumber: ele.subLessonNumber,
											})}
										</span>
									)}
									<span>{ele.name}</span>
									{typeof ele.status !== "undefined" && (
										<div className="p-2 position-absolute" style={{ top: "3px", right: "40px" }}>
											{ele.status === 2 ? (
												<span
													className="icon-check-2"
													title={t("helpers.utils.lessonstatus.completed")}>
													<span className="path1"></span>
													<span className="path2"></span>
												</span>
											) : ele.status === 1 ? (
												<span
													className="icon-clock-5"
													title={t("helpers.utils.lessonstatus.inprogress")}>
													<span className="path1"></span>
													<span className="path2"></span>
												</span>
											) : (
												<span className="icon-block" title={t("helpers.utils.lessonstatus.notstarted")}>
													<span className="path1"></span>
													<span className="path2"></span>
												</span>
											)}
										</div>
									)}
									{archiveBody(10, "0%", ele)}
								</Card.Body>
							</Card>
						</Col>
					))}
			</Row>
		</Card>
	);

	const renderTabComponent = () => (
		<>
			{activeTab === t("languagelearning.fluencydrills.paradigmneedpractice") && (
				<div className="recording-options ml-1">
					<fieldset>
						<form className="d-flex align-items-center justify-content-end mt-3 mr-3">
							<input
								className="mr-1"
								type="radio"
								id="Lesson_paradigm"
								name="Lesson paradigm"
								value={0}
								checked={selected === 0}
								onClick={() => {
									setSelected(0);
									setTabContent([]);
									getTabContent(activeTab, "", 0);
								}}
							/>
							<label htmlFor="Lesson_paradigm" className="mb-0 mr-3">
								{t("languagelearning.fluencydrills.lessonparadigm")}
							</label>
							<br />
							<input
								className="mr-1"
								type="radio"
								id="General_Paradigms"
								name="General Paradigms"
								value={1}
								checked={selected === 1}
								onClick={() => {
									setSelected(1);
									setTabContent([]);
									getTabContent(activeTab, "", 1);
								}}
							/>
							<label htmlFor="General_Paradigms" className="mb-0 mr-2">
								{t("languagelearning.fluencydrills.generalparadigms")}
							</label>
						</form>
					</fieldset>
				</div>
			)}
			<Scrollbars
				style={{ height: activeTab === t("languagelearning.fluencydrills.paradigmneedpractice") ? "73vh" : "75vh" }}>
				{tabContent.length > 0
					? activeTab === t("languagelearning.fluencydrills.paradigmneedpractice")
						? forPractice(tabContent)
						: activeTab === t("languagelearning.fluencydrills.generalparadigms")
							? generalParadigm(tabContent)
							: tabContent.map((el, index) => {
								if (activeTab === t("languagelearning.fluencydrills.lessonparadigm")) {
									return lessonParadigmBody(el);
								}
								return null;
							})
					: !loading && <NoContent bgImage={noData} content={t("helpers.utils.nodata")} />}
			</Scrollbars>
		</>
	);

	const getTabContent = (tab, search, deckType) => {
		setLoading(true);
		const location = history.location.pathname.split("/");
		getTabData(tab, location[2], search, deckType).then((res) => {
			if (res.statusCode === 200) {
				setTabContent(res.response);
				setLoading(false);
			}
		});
		getSettings(location[2]).then(({ response, statusCode }) => {
			if (statusCode === 200) {
				setNuberOfQuestions(response.numberOfQuestions);
			}
		});
	};

	const onSelectTab = (tab) => {
		setActiveTab(tab);
		setTabContent([]);
		getTabContent(tab, "", tab === t("languagelearning.fluencydrills.paradigmneedpractice") ? selected : null);
	};

	const renderModalBody = () => {
		return (
			<div className="d-flex flex-column setting-body">
				<span className="label">{t("languagelearning.fluencydrills.noofquestionspractice")}</span>
				<input
					type="text"
					pattern="[0-9]*"
					value={numberOfQuestions}
					ref={inputRef}
					onChange={(e) => setNuberOfQuestions(e.target.value)}
				/>
				<div className="d-flex justify-content-end">
					<button
						className="btn btn-primary submit-btn"
						onClick={() => {
							setQuestions({
								language: language === "Hebrew" ? 0 : 1,
								numberOfQuestions: parseInt(inputRef.current.value),
							}).then((res) => {
								if (res.statusCode === 200) {
									NotificationManager.success(t("helpers.utils.successfull"), "", 3000);
								}
								else{
									NotificationManager.success(t("helpers.utils.notification.error"), "", 3000);
								}
							})
							setOpenSetting(false);
						}}>
						{t("helpers.utils.submit")}
					</button>
				</div>
			</div>
		);
	};
	return (
		<div className="fluency-drills empty">
			{loading && <Loader />}
			<Header
				enableLanguageSwitch={true}
				redirectionUrl="Fluency-Drills"
				head={t("languagelearning.fluencydrills.name")}
			/>
			<div className="tab-wrapper">
				<div className="position-relative">
					<div className="position-absolute fluency-settings d-flex">
						<div className="search-container">
							<i className="icon icon-search" />
							<input
								className="input-search"
								type="text"
								placeholder={t("languagelearning.fluencydrills.searchhere")}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										getTabContent(activeTab, e.target.value);
									}
								}}
							/>
						</div>
						<span className="icon-setting" onClick={() => setOpenSetting(true)}>
							<i className="icon-settings-2"></i>
						</span>
					</div>
				</div>
				<Tabs className="default-tabs" activeKey={activeTab} onSelect={onSelectTab}>
					{tabHeader.map((tabName) => (
						<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
							{renderTabComponent()}
						</Tab>
					))}
				</Tabs>
			</div>
			<SharedModal
				show={openSetting}
				modalHead={t("languagelearning.fluencydrills.noofquestionspractice")}
				modalBody={renderModalBody()}
				handleClose={() => setOpenSetting(false)}
				modalClass="fluency-settings"
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getTabData,
	setQuestions,
	archieDeck,
	unArchieDeck,
	getSettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FluencyDrills));
