import React, { useEffect, useRef, useState } from "react";
import { Card, OverlayTrigger, Popover, Tab, Tabs } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { ToggleButton } from "../../sharedComponents/toggleButton";
import ProgressCircle from "../LanguageLearning/ProgressBar";
import {
	getBibleData,
	getSenses,
	getVerseList,
	markVerseRead,
	setPoints,
	unMarkVerseRead,
	updateBibleField,
	updateBibleFields,
} from "./actions";
import BulkArchive from "./bulkArchive";
import { progressCircle, wordStatuses } from "./helper";
import SearchBible from "./searchBible";

const copyArray = (arr) => arr.map((item) => ({ ...item }));

const BibleWords = ({
	language,
	showSenses,
	toggleSensesView,
	getBibleData,
	updateBibleField,
	updateBibleFields,
	isLoading,
	isSenseLoading,
	match,
	getSenses,
	getVerseList,
	selectedBook,
	removeSelection,
	fetchBibleData,
	selectedVerse,
	osisID,
	disableScroll,
	currentNodeIndex,
	markVerseRead,
	loadingChapter,
	unMarkVerseRead,
	searchParams,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const verseRef = useRef([]);
	const scrollRef = useRef(null);
	const overlayRef = useRef(null);
	const [highlightWords, setHighlightWords] = useState(true);
	const [hasNext, setHasNext] = useState(false);
	const [hasPrevious, setHasPrevious] = useState(false);
	const [filterOptions, setfilterOptions] = useState({});
	const [filterValues, setfilterValues] = useState({});
	const [currentWordIndex, setCurrentWordIndex] = useState(null);
	const [bibleWords, setBibleWords] = useState([]);
	const [grammaticalInfo, setGrammaticalInfo] = useState("");
	const [chapterProgress, setChapterProgress] = useState(copyArray(progressCircle));
	const [verseProgress, setVerseProgress] = useState(copyArray(progressCircle));
	const [activeTab, setActiveTab] = useState(t("biblereading.reading.name"));
	const [defaultAudioFile, setDefaultAudioFile] = useState();
	const [archiveModal, setArchiveModal] = useState(false);
	const [markVerseRange, setMarkVerseRange] = useState();
	const [showOption, setShowOption] = useState(false);
	const [markedVerse, setMarkedVerse] = useState();
	const [isSelectRange, setIsSelectRange] = useState(false);
	const [elementPosition, updatePosition] = useState(0);
	const [scrollPosition, updateScrollPosition] = useState(0);
	const navButtons =
		language === "Greek"
			? [t("helpers.utils.previous"), t("helpers.utils.next")]
			: [t("helpers.utils.next"), t("helpers.utils.previous")];
	const tabList = [t("biblereading.reading.name"), t("helpers.utils.search.caps")];
	const filterList = [t("helpers.utils.book"), t("helpers.utils.chapter"), t("biblereading.verses.name")];
	const legendList = [
		{
			status: "NotLearned",
			label: t("helpers.legendlist.notlearned"),
		},
		{
			status: "SenseLearned",
			label: t("helpers.legendlist.senselearned"),
		},
		{
			status: "MultipleSensesLearned",
			label: t("helpers.legendlist.multiplesenseslearned"),
		},
		{
			status: "Archived",
			label: t("helpers.legendlist.archived"),
		},
	];

	const shortcutKeysList = [
		{
			title: "l",
			label: t("helpers.shortcutkeys.listentoaudio"),
		},
		{
			title: "f",
			label: t("helpers.shortcutkeys.flashcardaddwordsense"),
		},
		{
			title: "a",
			label: t("helpers.shortcutkeys.archive/unarchivesense"),
		},
		{
			title: "v",
			label: t("helpers.shortcutkeys.upvote/downvote"),
		},
		{
			title: "shift + f",
			label: t("helpers.shortcutkeys.shift+f"),
		},
		{
			title: "shift + a",
			label: t("helpers.shortcutkeys.shift+a"),
		},

		{
			title: "Up/down",
			label: t("helpers.shortcutkeys.up/down"),
		},
		{
			title: "Numbers",
			label: <span>{t("helpers.shortcutkeys.selectspecificwordsensebyitsnumber")}</span>,
		},
		{
			title: "Shift+left/right arrow",
			label: t("helpers.shortcutkeys.selectspecificwordsensebyitsnumber"),
		},
		{
			title: "Left/right arrow ",
			label: t("helpers.shortcutkeys.leftorightarrow"),
		},
	];
	useEffect(() => {
		if (removeSelection) {
			updateBibleField("removeSelection", false);
			removeCurrentSelection();
		}
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeSelection]);

	useEffect(() => {
		scrollRef && scrollRef.current && scrollRef.current.scrollTop(elementPosition);
	}, [elementPosition]);

	const scrolToVerse = (verse, isLoading) => {
		updateBibleField("disableScroll", true);
		if (verse && verse.label) {
			if (verseRef.current && verseRef.current[verse.label - 1]) {
				setTimeout(() => {
					verseRef.current[verse.label - 1].scrollIntoView({
						behavior: "smooth",
						block: "start",
					});
				});
			}
			setTimeout(() => {
				updateBibleField("disableScroll", false);
				updateBibleField("currentNodeIndex", null);
				updateBibleField("loadingChapter", false);
			}, 2500);
		}
	};

	useEffect(() => {
		if (verseRef.current) {
			let time = 0;
			verseRef.current.forEach((node, index) => {
				const options = {
					threshold: 0.5,
				};
				const callback = (entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting && entry.intersectionRatio >= 0.5 && entry.time > time) {
							time = entry.time;
							updateBibleField("currentNodeIndex", index + 1);
						}
					});
				};
				const observer = new IntersectionObserver(callback, options);
				observer.observe(node);
			});
		}
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verseRef.current]);

	useEffect(() => {
		if (!disableScroll && currentNodeIndex) {
			const filterData = { ...filterValues };
			filterData[t("biblereading.verses.name")] = { label: currentNodeIndex };
			setfilterValues(filterData);
		}
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentNodeIndex]);

	useEffect(() => {
		if (fetchBibleData) {
			updateBibleField("fetchBibleData", false);
			if (selectedVerse) {
				updateBibleField("selectedVerse", null);
				setActiveTab(t("biblereading.reading.name"));
				verseRef.current = [];
				fetchBookData(selectedVerse);
			} else {
				getBibleData(`recent?Language=${match.params.language}`).then((res) => {
					if (res && res.response && res.response.verses) {
						let bibleWords = [];
						res.response.verses.forEach((data) => {
							const wordsList = getOrderedWordsList(data.words);
							bibleWords = [
								...bibleWords,
								{
									words: wordsList,
									verse: data.verse,
									isRead: data.isRead,
									readCount: data.readCount,
									orderedWords: data.words,
								},
							];
						});
						verseRef.current = [];
						setHasNext(res.response.hasNext);
						setHasPrevious(res.response.hasPrevious);
						setBibleWords(bibleWords);
					}
				});
			}
		}
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchBibleData]);

	useEffect(() => {
		updateBibleField("isLoading", true);
		if (!selectedBook && !searchParams) {
			getBibleData(`recent?Language=${match.params.language}`).then((res) => {
				updateBibleField("isLoading", false);
				if (res && res.response) {
					const filterValues = {
						[t("helpers.utils.book")]: {
							label: res.response.fullName,
							value: res.response.bookId,
						},
						[t("helpers.utils.chapter")]: {
							label: res.response.chapter,
						},
						[t("biblereading.verses.name")]: {
							label: 1,
						},
					};
					const audioFile = res.response.audioFiles.find((el) => el.isDefault);
					setDefaultAudioFile(audioFile);
					setfilterValues(filterValues);
					scrolToVerse(filterValues[t("biblereading.verses.name")]);
					const verses = handleBibleData(res.response);
					setfilterList(res.response, verses);
				}
			});
		} else if (selectedBook) {
			fetchBookData(selectedBook);
		} else if (searchParams) {
			const bookCB = new Promise((resolve, reject) => {
				getBibleData(`books?Testament=${match.params.language === "Hebrew" ? "Old" : "New"}`).then((res) => {
					if (res && res.response && res.response.length > 0) {
						const filters = { ...filterOptions };
						filters[t("helpers.utils.book")] = res.response.map((book) => ({
							label: book.fullName,
							value: book.id,
						}));
						resolve(filters[t("helpers.utils.book")]);
					}
				});
			});
			bookCB.then((books) => {
				searchParams["book"] = books.filter((book) => book.value === searchParams["bookId"])[0].label;
				fetchBookData(searchParams);
			});
		}
		return () => {
			updateBibleField("selectedBook", null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getBibleData, match.params.language, selectedBook]);

	useEffect(() => {
		if (bibleWords.length) {
			document.addEventListener("keydown", onKeyDown);
		}
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bibleWords, currentWordIndex, isLoading, isSenseLoading]);

	const onKeyDown = (e) => {
		const keyCode = e.keyCode;
		if (!isLoading && !isSenseLoading) {
			if (e.shiftKey && keyCode === 37) {
				if (currentWordIndex) {
					const action = language === t("header.hebrew") ? selectNextWord : selectPrevWord;
					action(currentWordIndex);
				} else if (language === "Hebrew") {
					toggleSenses(
						bibleWords[0].words[0] && bibleWords[0].words[0].isGroup
							? bibleWords[0].words[0].words[0]
							: bibleWords[0].words[0],
						0,
						0
					);
				}
			} else if (e.shiftKey && keyCode === 39) {
				if (currentWordIndex) {
					const action = language === "Hebrew" ? selectPrevWord : selectNextWord;
					action(currentWordIndex);
				} else if (language !== "Hebrew") {
					toggleSenses(bibleWords[0].words[0], 0, 0);
				}
			} else if (keyCode === 37) {
				//left arrow
				if (currentWordIndex) {
					const action = language === "Hebrew" ? selectNextWord : selectPrevWord;
					action(currentWordIndex, 0);
				} else if (language === "Hebrew") {
					const result = getNextWordWithWordStatus(bibleWords, 0, -1, 0);
					if (result) {
						toggleSenses(result.word, result.verseId, result.wordId);
					}
				}
			} else if (keyCode === 39) {
				//right arrow
				if (currentWordIndex) {
					const action = language === t("header.hebrew") ? selectPrevWord : selectNextWord;
					action(currentWordIndex, 0);
				} else if (language !== "Hebrew") {
					const result = getNextWordWithWordStatus(bibleWords, 0, -1, 0);
					if (result) {
						toggleSenses(result.word, result.verseId, result.wordId);
					}
				}
			}
		}
	};

	const getPrevWordWithWordStatus = (bibleWords, verseIndex, wordIndex, wordStatus) => {
		let wordId = wordIndex - 1;
		let verseId = verseIndex;

		while (verseId >= 0) {
			if (wordId >= 0) {
				if (
					bibleWords[verseId].orderedWords[wordId].status === wordStatus &&
					!bibleWords[verseId].orderedWords[wordId].isSegment &&
					!bibleWords[verseId].orderedWords[wordId].isPunctuation
				) {
					return {
						word: bibleWords[verseId].orderedWords[wordId],
						verseId,
						wordId,
					};
				}
				wordId -= 1;
			} else {
				verseId -= 1;
				wordId = bibleWords && bibleWords[verseId] && bibleWords[verseId].orderedWords.length - 1;
			}
		}
	};

	const getNextWordWithWordStatus = (bibleWords, verseIndex, wordIndex, wordStatus) => {
		let wordId = wordIndex + 1;
		let verseId = verseIndex;

		while (verseId < bibleWords.length - 1) {
			if (wordId && wordId < bibleWords[verseIndex].orderedWords.length - 1) {
				if (
					bibleWords[verseId].orderedWords[wordId].status &&
					bibleWords[verseId].orderedWords[wordId].status === wordStatus &&
					!bibleWords[verseId].orderedWords[wordId].isSegment &&
					!bibleWords[verseId].orderedWords[wordId].isPunctuation
				) {
					return {
						word: bibleWords[verseId].orderedWords[wordId],
						verseId,
						wordId,
					};
				}
				wordId += 1;
			} else {
				verseId += 1;
				wordId = 0;
			}
		}
	};

	const selectPrevWord = (currentIndex, nextWordStatus) => {
		const { wordIndex, verseIndex } = currentIndex;

		if (nextWordStatus === 0) {
			const result = getPrevWordWithWordStatus(bibleWords, verseIndex, wordIndex, nextWordStatus);
			if (result) {
				toggleSenses(result.word, result.wordId, result.verseId);
			}
		} else if (wordIndex > 0) {
			avoidSegmentWord(bibleWords[verseIndex].orderedWords[wordIndex - 1], wordIndex - 1, verseIndex, "prev");
		} else if (
			bibleWords[verseIndex - 1] &&
			bibleWords[verseIndex - 1].orderedWords &&
			bibleWords[verseIndex - 1].orderedWords.length
		) {
			const wordsCount = bibleWords[verseIndex - 1].orderedWords.length - 1;
			avoidSegmentWord(bibleWords[verseIndex - 1].orderedWords[wordsCount], wordsCount, verseIndex - 1, "prev");
		}
	};

	const selectNextWord = (currentIndex, nextWordStatus) => {
		const { wordIndex, verseIndex } = currentIndex;
		if (nextWordStatus === 0) {
			const result = getNextWordWithWordStatus(bibleWords, verseIndex, wordIndex, nextWordStatus);
			if (result) {
				toggleSenses(result.word, result.wordId, result.verseId);
			}
		} else if (wordIndex < bibleWords[verseIndex].orderedWords.length - 1) {
			avoidSegmentWord(bibleWords[verseIndex].orderedWords[wordIndex + 1], wordIndex + 1, verseIndex, "next");
		} else if (
			bibleWords[verseIndex + 1] &&
			bibleWords[verseIndex + 1].orderedWords &&
			bibleWords[verseIndex + 1].orderedWords.length
		) {
			avoidSegmentWord(bibleWords[verseIndex + 1].orderedWords[0], 0, verseIndex + 1, "next");
		}
	};

	const avoidSegmentWord = (word, wordId, verseId, action) => {
		const changeWord = action === "prev" ? selectPrevWord : selectNextWord;
		if (word.isSegment || word.isPunctuation) {
			changeWord({ wordIndex: wordId, verseIndex: verseId });
		} else {
			toggleSenses(word, wordId, verseId);
		}
	};

	const fetchBookData = async (book) => {
		const filterValues = {
			[t("helpers.utils.book")]: {
				label: book.book,
				value: book.bookId,
			},
			[t("helpers.utils.chapter")]: {
				label: book.chapter,
			},
			[t("biblereading.verses.name")]: {
				label: book.verse ? book.verse : 1,
			},
		};
		setfilterValues(filterValues);
		scrolToVerse(filterValues[t("biblereading.verses.name")]);
		const verses = await getVerses(book.bookId, book.chapter);
		setfilterList(book, verses);
	};

	const setfilterList = async (bible, verses) => {
		const [books, chapters] = await Promise.all([getBooks(), getChapters(bible.bookId)]);
		const filterOptions = {
			[t("helpers.utils.book")]: books,
			[t("helpers.utils.chapter")]: chapters,
			[t("biblereading.verses.name")]: verses,
		};
		setfilterOptions(filterOptions);
	};

	const getBooks = () =>
		getBibleData(`books?Testament=${match.params.language === "Hebrew" ? "Old" : "New"}`).then((res) => {
			updateBibleField("isLoading", false);
			if (res && res.response && res.response.length > 0) {
				const filters = { ...filterOptions };
				filters[t("helpers.utils.book")] = res.response.map((book) => ({
					label: book.fullName,
					value: book.id,
				}));
				return filters[t("helpers.utils.book")];
			}
		});

	const getChapters = (bookId) =>
		getBibleData(`chapters?BookId=${bookId}`).then((res) => {
			if (res && res.response && res.response.chapters && res.response.chapters.length) {
				const chpaters = res.response.chapters.map((chapter) => ({
					label: chapter,
					value: chapter,
				}));
				return chpaters;
			}
		});

	const getVerses = (bookId, chapterId) => {
		updateBibleField("isLoading", true);
		return getBibleData(`verses?BookId=${bookId}&Chapter=${chapterId}`).then((res) => {
			const audioFile = res.response.audioFiles && res.response.audioFiles.find((el) => el.isDefault);
			setDefaultAudioFile(audioFile);
			updateBibleField("isLoading", false);
			return handleBibleData(res.response);
		});
	};

	const getOrderedWordsList = (words) => {
		const wordsList = [];
		let interMediateList = [];
		let wordIndex = 0;
		words.forEach((word, index) => {
			if (words[index + 1] && words[index + 1].isAppend) {
				interMediateList.push({ ...word, wordIndex });
			} else if ((words[index + 1] && !words[index + 1].isAppend) || !words[index + 1]) {
				if (word.isAppend) {
					interMediateList.push({ ...word, wordIndex });
					wordsList.push({ isGroup: true, words: interMediateList });
				} else {
					wordsList.push({ ...word, wordIndex });
				}
				interMediateList = [];
			}
			wordIndex++;
		});
		return wordsList;
	};

	const handleBibleData = (bible) => {
		const verses = [];
		let bibleWords = [];

		bible.verses &&
			bible.verses.forEach((data) => {
				verses.push({ label: data.verse, value: data.verse });
				const wordsList = getOrderedWordsList(data.words);
				bibleWords = [
					...bibleWords,
					{
						words: wordsList,
						verse: data.verse,
						isRead: data.isRead,
						readCount: data.readCount,
						orderedWords: data.words,
					},
				];
			});
		verseRef.current = [];
		setBibleWords(bibleWords);
		setHasNext(bible.hasNext);
		setHasPrevious(bible.hasPrevious);
		dispatch(setPoints(bible.totalPoints));
		if (bible.percentage || bible.percentage === 0) {
			const progress = copyArray(progressCircle);
			progress[1].value = bible.percentage;
			progress[0].value = 100 - bible.percentage;
			setChapterProgress(progress);
		}
		if (bible.readingPercentage || bible.readingPercentage === 0) {
			const progressVerse = copyArray(progressCircle);
			progressVerse[1].value = bible.readingPercentage;
			progressVerse[0].value = 100 - bible.readingPercentage;
			setVerseProgress(progressVerse);
		}
		removeCurrentSelection();
		return verses;
	};

	const onFilterChange = async (input, item) => {
		const filterData = { ...filterValues };
		filterData[item] = input;
		filterData[t("helpers.utils.chapter")] =
			item === [t("helpers.utils.book")] ? { label: 1 } : filterData[t("helpers.utils.chapter")];
		filterData[t("biblereading.verses.name")] =
			item !== t("biblereading.verses.name") ? { label: 1 } : filterData[t("biblereading.verses.name")];
		setfilterValues(filterData);
		scrolToVerse(filterData[t("biblereading.verses.name")]);
		let options = {};
		// eslint-disable-next-line eqeqeq
		if (item == [t("helpers.utils.book")]) {
			const [verses, chapters] = await Promise.all([getVerses(input.value, 1), getChapters(input.value)]);
			options = { ...filterOptions, [t("helpers.utils.chapter")]: chapters, [t("biblereading.verses.name")]: verses };
			// eslint-disable-next-line eqeqeq
		} else if (item == t("helpers.utils.chapter")) {
			const verses = await getVerses(filterValues[t("helpers.utils.book")].value, input.label);
			options = { ...filterOptions, [t("biblereading.verses.name")]: verses };
		} else {
			options = { ...filterOptions };
		}
		setfilterOptions(options);
	};

	const updateChapter = async (type) => {
		updateBibleField("loadingChapter", true);
		const currentChpater =
			filterValues && filterValues[t("helpers.utils.chapter")] ? filterValues[t("helpers.utils.chapter")].label : 0;
		const totalChpaters =
			filterOptions && filterOptions[t("helpers.utils.chapter")] ? filterOptions[t("helpers.utils.chapter")].length : 0;
		let newChapter = 0;
		if (type === t("helpers.utils.previous") && currentChpater && currentChpater > 1) {
			newChapter = currentChpater - 1;
		} else if (type === t("helpers.utils.next") && currentChpater && totalChpaters && currentChpater < totalChpaters) {
			newChapter = currentChpater + 1;
		}
		if (newChapter) {
			const filterData = { ...filterValues };
			filterData[t("helpers.utils.chapter")] = { label: newChapter };
			filterData[t("biblereading.verses.name")] = { label: 1 };
			setfilterValues(filterData);
			scrolToVerse(filterData[t("biblereading.verses.name")]);
			let options = {};
			setShowOption(false);
			setMarkVerseRange(null);
			setMarkedVerse(null);
			setIsSelectRange(false);
			const verses = await getVerses(filterValues[t("helpers.utils.book")].value, newChapter);
			options = { ...filterOptions, [t("biblereading.verses.name")]: verses };
			setfilterOptions(options);
		}
	};

	const removeCurrentSelection = () => {
		setGrammaticalInfo("");
		toggleSensesView(false);
		updateBibleField("senses", null);
		updateBibleField("osisID", null);
	};

	const toggleSenses = (word, wordIndex, verseIndex) => {
		if (word.attributes) {
			updateBibleField("osisID", word.osisID);
			setCurrentWordIndex({ wordIndex, verseIndex });
			onFilterChange({ label: verseIndex + 1, value: verseIndex + 1 }, [t("biblereading.verses.name")]);
			const atributes = JSON.parse(word.attributes);
			const grammaticalInfo = atributes.find((word) => word.Key === "GrammaticalInfo");
			const strongsNumber = word.strongsNumber;
			setGrammaticalInfo(
				grammaticalInfo ? `${grammaticalInfo.Value}${strongsNumber ? `, ${strongsNumber}` : ""}` : false
			);
			if (strongsNumber && strongsNumber) {
				fetchSenseDetails(strongsNumber, word.text, false, word.osisID);
			} else {
				toggleSensesView(false);
				updateBibleField("senses", null);
			}
		}
	};

	const fetchSenseDetails = async (strongsNumber, text, senseID, osisID) => {
		updateBibleFields({
			isSenseLoading: true,
			isVerseLoading: true,
			strongsNumber: strongsNumber,
			activeTab: activeTab,
			currentWord: text,
			verses: null,
		});
		await getSenses(strongsNumber, language, senseID, osisID);
		await getVerseList(strongsNumber, language);
		updatePosition(scrollPosition);
		toggleSensesView(true);
	};

	const progressValueComponent = (text, progressArray) => (
		<OverlayTrigger
			trigger={window.innerWidth > 1024 ? "hover" : "click"}
			placement="auto"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="progress-value-popup">
					<Popover.Content>
						<span className="word-description">{`${
							text === "Word" ? t("biblereading.wordsyoulearned") : t("biblereading.versesread")
						} `}</span>
					</Popover.Content>
				</Popover>
			}>
			<span>{progressArray[1].value}%</span>
		</OverlayTrigger>
	);

	const AudioButton = () => (
		<>
			{defaultAudioFile && (
				<>
					<audio
						className="bible-reading-audio-btn"
						src={defaultAudioFile.filePath}
						controls
						controlsList="nodownload"
					/>
				</>
			)}
		</>
	);

	const renderTopSection = () => (
		<div className="top-section d-flex justify-content-between flex-wrap mt-2 mb-4 ml-1 mr-1">
			<div className="filter-list d-flex flex-wrap">
				{filterList.map((item) => (
					<div
						key={item}
						className={`d-flex flex-column filter-block ${item}`}
						style={{ width: "helpers.utils.book" && "145px" }}>
						<label className="select-label">{item}</label>
						<Select
							placeholder=""
							value={filterValues[item] || null}
							onChange={(value) => onFilterChange(value, item)}
							options={filterOptions[item] || []}
							className="react-select"
							classNamePrefix="react-select"
						/>
					</div>
				))}
			</div>
			{/* hotfix for audio tag progress bar not showing */}
			<div className="d-flex align-items-end mt-2 progress-wrapper" style={{ width: "240px" }}>
				{AudioButton()}
			</div>
		</div>
	);

	const addToRefs = (el) => {
		if (el && !verseRef.current.includes(el)) {
			verseRef.current.push(el);
		}
	};

	const showMenu = (verse) => {
		setShowOption(true);
		if (!markedVerse) {
			setMarkVerseRange(verse);
		}
		if (markVerseRange) {
			setIsSelectRange(true);
		}
		setMarkedVerse(verse);
	};

	const markVersRange = () => {
		let from;
		let to;
		const {
			[t("helpers.utils.book")]: { value: bookId },
			[t("helpers.utils.chapter")]: { label: chapterId },
		} = filterValues;
		if (markedVerse.verse === markVerseRange.verse) {
			from = markedVerse.verse;
			to = markedVerse.verse;
		} else {
			from = markedVerse.verse < markVerseRange.verse ? markedVerse.verse : markVerseRange.verse;
			to = markedVerse.verse > markVerseRange.verse ? markedVerse.verse : markVerseRange.verse;
		}
		markVerseRead(bookId, chapterId, from, to).then(async (res) => {
			if (res.statusCode === 200) {
				const wordList = bibleWords.map((el) => {
					if (el.verse >= from && el.verse <= to) {
						return { ...el, isRead: true, readCount: el.readCount + 1 };
					}
					return el;
				});
				const progressVerse = copyArray(progressCircle);
				progressVerse[1].value = res.response.readingPercentage;
				progressVerse[0].value = 100 - res.response.readingPercentage;
				setVerseProgress(progressVerse);
				setBibleWords(wordList);
				setShowOption(false);
				setMarkVerseRange(null);
				setMarkedVerse(null);
				setIsSelectRange(false);
			}
		});
	};
	const unMarkVerse = () => {
		let from;
		let to;
		const {
			[t("helpers.utils.book")]: { value: bookId },
			[t("helpers.utils.chapter")]: { label: chapterId },
		} = filterValues;

		if (markedVerse.verse === markVerseRange.verse) {
			from = markedVerse.verse;
			to = markedVerse.verse;
		} else {
			from = markedVerse.verse < markVerseRange.verse ? markedVerse.verse : markVerseRange.verse;
			to = markedVerse.verse > markVerseRange.verse ? markedVerse.verse : markVerseRange.verse;
		}
		unMarkVerseRead(bookId, chapterId, from, to).then(async (res) => {
			if (res.statusCode === 200) {
				const wordList = bibleWords.map((el) => {
					if (el.verse >= from && el.verse <= to) {
						return { ...el, isRead: true, readCount: el.readCount - 1 > 0 ? el.readCount - 1 : 0 };
					}
					return el;
				});
				const progressVerse = copyArray(progressCircle);
				progressVerse[1].value = res.response.readingPercentage;
				progressVerse[0].value = 100 - res.response.readingPercentage;
				setVerseProgress(progressVerse);
				setBibleWords(wordList);
				setShowOption(false);
				setMarkVerseRange(null);
				setMarkedVerse(null);
				setIsSelectRange(false);
			}
		});
	};
	const renderWord = (verse, verseIndex) => (
		<div key={verse.verse} className={`verse-array ${language}`} ref={addToRefs}>
			<div className="verse-container">
				<OverlayTrigger
					placement="auto"
					rootClose={true}
					overlay={
						<Popover id="popover-basic" className="progress-value-popup">
							<Popover.Content>
								<span className="word-description">{t("biblereading.reading.markasread")}</span>
							</Popover.Content>
						</Popover>
					}>
					<div>
						<span className="icon-book-3 verse-number-text pointer" onClick={() => showMenu(verse)}>
							{verse.readCount ? (
								<span className="read-count-position">
									<sup className="read-count">{verse.readCount}</sup>
								</span>
							) : null}
						</span>
					</div>
				</OverlayTrigger>
				<sup className="verse-number">{verse.verse}</sup>
			</div>
			<div className="d-flex flex-wrap">
				{verse.words.map((word, index) =>
					word.isGroup ? (
						<div className="word-group d-flex flex-wrap" key={`top-${index}`}>
							{word.words.map((item, itemIndex) =>
								renderSingleWord(verse, verseIndex, word.words[itemIndex + 1], item)
							)}
						</div>
					) : (
						renderSingleWord(verse, verseIndex, verse.words[index + 1], word)
					)
				)}
			</div>
		</div>
	);

	const renderSingleWord = (verse, verseIndex, nextWord, word) => (
		<span
			key={`${verse.verse}${word.wordIndex}`}
			className={`word  ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} ${
				highlightWords ? wordStatuses[word.status] : ""
			}
        ${word.isSegment ? "segment" : ""} ${word.isPunctuation ? "segment punctuation" : ""} ${word.isAppend ? "append" : ""}
        ${nextWord && nextWord.isAppend ? "prev" : ""} 
        ${word.osisID && osisID && word.osisID === osisID ? "selected" : ""}
        `}
			{...(!word.isSegment && {
				onClick: () => toggleSenses(word, word.wordIndex, verseIndex),
			})}>
			{word.text}
		</span>
	);

	const getButtons = (button) => {
		if (language === "Greek") {
			return (
				<>
					{button === t("helpers.utils.next") ? button : ""}
					<i className="icon icon-left-arrow-1"></i>
					{button === t("helpers.utils.previous") ? button : ""}
				</>
			);
		}
		return (
			<>
				{button === t("helpers.utils.next") ? (
					<>
						<i className="icon icon-left-arrow-1"></i>

						{button}
					</>
				) : (
					<>
						{button}
						<i className="icon icon-left-arrow-1"></i>
					</>
				)}
			</>
		);
	};
	const renderNavButtons = () => (
		<div
			className={`nav-button ${
				(!hasPrevious && language === "Greek") || (!hasNext && language === "Hebrew") ? "justify-content-end" : ""
			} `}>
			{navButtons.map(
				(button) =>
					((button === t("helpers.utils.next") && hasNext) ||
						(button === t("helpers.utils.previous") && hasPrevious)) && (
						<button
							key={button}
							className={`btn btn-default redirect-btn  ${
								button === t("helpers.utils.next") && language === "Greek" && "reverse"
							}  ${button === t("helpers.utils.previous") && language === "Hebrew" && "reverse"} `}
							onClick={() => updateChapter(button)}>
							{getButtons(button)}
						</button>
					)
			)}
		</div>
	);

	const renderTabContent = (tab) => {
		switch (tab) {
			case t("biblereading.reading.name"):
				return (
					<>
						{renderTopSection()}
						{!isLoading && bibleWords.length > 0 && (
							<>
								<Scrollbars
									ref={scrollRef}
									onScroll={(e) => {
										e.preventDefault();
										const { scrollTop } = scrollRef.current.getValues();
										updateScrollPosition(scrollTop);
									}}>
									<div className="bible-words d-flex flex-fill">
										<div className="mt-1 w-100">
											{bibleWords.map((word, index) => renderWord(word, index))}
										</div>
										{renderNavButtons()}
									</div>
								</Scrollbars>
								{grammaticalInfo && (
									<div className="grammatical-info">
										<div className="word-title">{t("biblereading.reading.grammaticalinformation")}</div>
										<span className="word-description">{grammaticalInfo}</span>
									</div>
								)}
							</>
						)}
					</>
				);
			default:
				return <SearchBible fetchSenseDetails={fetchSenseDetails} removeCurrentSelection={removeCurrentSelection} />;
		}
	};

	const renderOverlay = () => (
		<OverlayTrigger
			placement="bottom"
			trigger="click"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="word-status-legends">
					<Popover.Content>
						<Scrollbars>
							<div className="legend-container">
								<p className="title">{t("biblereading.colouryouseeinbibletext")}</p>
								{legendList &&
									legendList.map((legend) => (
										<div className="d-flex wrapper" key={legend.status}>
											<span className={`word-status ${legend.status}`}></span>
											<p className="word-desc">{legend.label}</p>
										</div>
									))}
								<div className="hot-keys">
									<p className="title">{t("biblereading.shortcutkeys")}</p>
									{shortcutKeysList.map((shortcut) => (
										<div className="d-flex wrapper" key={shortcut.title}>
											<span className="keys">
												<span className="word">{shortcut.title}</span>{" "}
											</span>
											<p className="word-desc d-flex">
												<span className="ml-1"> {shortcut.label}</span>
											</p>
										</div>
									))}
								</div>
							</div>
						</Scrollbars>
					</Popover.Content>
				</Popover>
			}>
			<div className="legends popover-icons pointer">
				<i className="icon icon-info"></i>
			</div>
		</OverlayTrigger>
	);

	const markChapterAsRead = () => {
		const {
			[t("helpers.utils.book")]: { value: bookId },
			[t("helpers.utils.chapter")]: { label: chapterId },
		} = filterValues;

		const from = bibleWords[0].verse;
		const to = bibleWords[bibleWords.length - 1].verse;

		markVerseRead(bookId, chapterId, from, to).then(async (res) => {
			if (res.statusCode === 200) {
				const wordList = bibleWords.map((el) => {
					if (el.verse >= from && el.verse <= to) {
						return { ...el, isRead: true, readCount: el.readCount + 1 };
					}
					return el;
				});
				const progressVerse = copyArray(progressCircle);
				progressVerse[1].value = res.response.readingPercentage;
				progressVerse[0].value = 100 - res.response.readingPercentage;
				setVerseProgress(progressVerse);
				setBibleWords(wordList);
				setShowOption(false);
				setMarkVerseRange(null);
				setMarkedVerse(null);
				setIsSelectRange(false);
			}
		});
	};

	const renderOptions = () => (
		<OverlayTrigger
			className="d-flex pr-2"
			placement="bottom"
			trigger="click"
			rootClose={true}
			ref={overlayRef}
			overlay={
				<Popover id="popover-basic" className="popover-options">
					<Popover.Content>
						<div
							className="bulk-archive top-section-btn"
							onClick={() => {
								overlayRef && overlayRef.current && overlayRef.current.hide();
								setArchiveModal(true);
							}}>
							<span>{t("biblereading.bulkarchivemodal.name")}</span>
						</div>
						<div
							className="d-flex bible-mark-read top-section-btn"
							onClick={() => {
								overlayRef && overlayRef.current && overlayRef.current.hide();
								markChapterAsRead();
							}}>
							<span>{t("biblereading.markchapterread")}</span>
						</div>
						<div className="popover-switch-container">
							<span>
								{" "}
								{highlightWords
									? t("biblereading.turnoffcolormarkup")
									: t("biblereading.turnoncolormarkup")}{" "}
							</span>
							<ToggleButton
								onToggle={(e) => {
									overlayRef && overlayRef.current && overlayRef.current.hide();
									setHighlightWords(e.target.checked);
								}}
								selected={highlightWords}
							/>
						</div>
					</Popover.Content>
				</Popover>
			}>
			<div className="more-4 pointer">
				<i className="icon-more-4"></i>
			</div>
		</OverlayTrigger>
	);

	const getRange = (range1, range2) => {
		if (range2 && isSelectRange) {
			if (range1.verse > range2.verse) {
				return `${range2.verse}-${range1.verse}`;
			}
			return `${range1.verse}-${range2.verse}`;
		}
		return `${range1.verse}`;
	};

	return (
		<div
			className={`${showSenses ? "w-50" : "flex-fill"}`}
			style={{ display: "flex", flexDirection: "column", position: "relative" }}>
			<div className="bible-data">
				<Tabs
					className="default-tabs"
					activeKey={activeTab}
					onSelect={(tab) => {
						setActiveTab(tab);
						removeCurrentSelection();
						updateBibleField("currentTab", tab);
					}}>
					{tabList.map((tabName) => (
						<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
							{renderTabContent(tabName)}
						</Tab>
					))}
				</Tabs>
				<div className="bible-reading-buttons-container">
					{filterValues[t("helpers.utils.book")] && filterValues[t("helpers.utils.book")].label && (
						<ProgressCircle
							value={verseProgress}
							displayValue={() => progressValueComponent(t("biblereading.verses.name"), verseProgress)}
							displayComponent={true}
							strokeSize={6}
							rotateCircle={true}
							size={90}
						/>
					)}
					<ProgressCircle
						value={chapterProgress}
						displayValue={() => progressValueComponent("Word", chapterProgress)}
						displayComponent={true}
						strokeSize={6}
						rotateCircle={true}
						size={90}
					/>
					<div className="option-info">
						{renderOverlay()}
						{renderOptions()}
					</div>
					<BulkArchive archiveModal={archiveModal} setArchiveModal={setArchiveModal} />
				</div>
			</div>
			{showOption && (
				<Card className="mark-as-read-popup pt-3 pl-4 pr-4 pb-3">
					<div className="d-flex justify-content-between ">
						<span className="mb-2 heading-text">{`${filterValues[t("helpers.utils.book")].label} ${
							filterValues[t("helpers.utils.chapter")].label
						}:${getRange(markVerseRange, markedVerse)}`}</span>
						<span
							className="icon-close"
							onClick={() => {
								setMarkVerseRange(null);
								setShowOption(false);
								setIsSelectRange(false);
								setMarkedVerse(null);
							}}></span>
					</div>
					<div className="mb-3">
						<span>{t("biblereading.reading.clickonanyverse")}</span>
					</div>
					<div className="d-flex align-items-center">
						<span className="icon-book-3 mr-3" style={{ fontSize: 14, color: "#2A79AB" }}></span>
						<button
							disabled={!isSelectRange}
							className={`${
								!isSelectRange && "disable"
							} button-border mr-2 d-flex align-items-center justify-content-center`}
							onClick={() => markVersRange(markedVerse)}>
							{t("biblereading.reading.markasread")}
						</button>
						<button
							disabled={!isSelectRange}
							className={` ${
								!isSelectRange && "disable"
							} button-border d-flex align-items-center justify-content-center`}
							onClick={unMarkVerse}>
							{t("biblereading.reading.markasunread")}
						</button>
					</div>
				</Card>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	language: state.sideMenu.language,
	isLoading: state.bible.isLoading,
	isSenseLoading: state.bible.isSenseLoading,
	selectedBook: state.bible.selectedBook,
	removeSelection: state.bible.removeSelection,
	fetchBibleData: state.bible.fetchBibleData,
	selectedVerse: state.bible.selectedVerse,
	osisID: state.bible.osisID,
	disableScroll: state.bible.disableScroll,
	currentNodeIndex: state.bible.currentNodeIndex,
	loadingChapter: state.bible.loadingChapter,
});

const mapDispatchToProps = {
	getBibleData,
	updateBibleField,
	updateBibleFields,
	getSenses,
	getVerseList,
	markVerseRead,
	unMarkVerseRead,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BibleWords));
