export const SUBMENU = {
  UPLOAD_FILE: "UPLOAD_FILE",
  GET_ALL_LESSONS: "GET_ALL_LESSONS",
  GET_ALL_WORDS: "GET_ALL_WORDS",
  GET_WORD_DETAILS: "GET_WORD_DETAILS",
  GET_LESSON_DETAILS: "GET_LESSON_DETAILS",
  CHANGE_TYPE: "CHANGE_TYPE",
  GET_GRAMMAR_DETAILS: "GET_GRAMMAR_DETAILS",
  UPDATE_FIELD: "UPDATE_FIELD",
  GET_INTRO_VIDEO: "GET_INTRO_VIDEO",
  GET_MAP_INTRO: "GET_MAP_INTRO",
  SET_LESSON_NUMBER: "SET_LESSON_NUMBER",
  GET_VIDEO_FILES: "GET_VIDEO_FILES",
  GET_ENUM_VALUES: "GET_ENUM_VALUES",
  GET_ALL_GRAMMAR: "GET_ALL_GRAMMAR",
  SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
  SET_IS_SEARCH_ENABLE:"SET_IS_SEARCH_ENABLE",
  CLEAR_DATA: "CLEAR_DATA",
  DELETE_INTRO_VIDEO: "DELETE_INTRO_VIDEO"
};
