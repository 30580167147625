import React, { useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
 
  senseDataFields,
  subSenseFields,
} from "./helper";
import SharedModal from "../../sharedComponents/Modal";
import {
  searchBible,
  updateBibleField,
  getSearchHistory,
  getSearchResult,
} from "./actions";
import noSearchData from "../../..//assets/images/no-search.svg";
import "./search.scss";
import { useEffect } from "react";

const SearchBible = ({
  searchBible,
  language,
  updateBibleField,
  isLoading,
  fetchSenseDetails,
  removeCurrentSelection,
  getSearchHistory,
  getSearchResult,
}) => {
  const { t } = useTranslation();
  const sensesColumns = ["", t("helpers.utils.gloss"), t("helpers.utils.semanticdomain")];
  const subSensesColumns = ["", t("helpers.utils.gloss"), t("biblereading.search.phrasescompoundwords")];
  const subDomainsColumns = [t("helpers.utils.semanticdomain"), ""];

  const searchFields = [
    { label: t("biblereading.search.word"), value: "searchTerm" },
    { label: t("biblereading.search.searchedon"), value: "searchedOn" },
    { label: t("biblereading.search.searchedby"), value: "searchParamType" },
  ];

  const searchOptions = [
    { label: t("biblereading.search.greekorhebrew"), value: "Word" },
    { label: t("biblereading.search.english"), value: "Gloss" },
    { label: t("helpers.utils.semanticdomain"), value: "SemanticDomain" },
  ];
  const [showHistory, setShowHistory] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [searchBy, setSearchBy] = useState(null);
  const [searchFor, setSearchFor] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [searchField, setSearchField] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  const [senses, setSenses] = useState([]);
  const [subSenses, setSubSenses] = useState([]);
  const [senseColumns, setSenseColumns] = useState(sensesColumns);
  const [subSenseColumns, setSubSenseColumns] = useState(subSensesColumns);
  const [subDomainColumns, setDubDomainColumns] = useState(subDomainsColumns);



  useEffect(() => {
    const columns = senseColumns;
    const subColumns = subSenseColumns;
    const domainColumns = subDomainColumns;
    subColumns[0] = language === "Greek" ? t("header.greek") : t("header.hebrew");
    columns[0] = language === "Greek" ? t("header.greek") : t("header.hebrew");;
    domainColumns[1] = language === "Greek" ? t("header.greek") : t("header.hebrew");;
    setDubDomainColumns(domainColumns);
    setSenseColumns(columns);
    setSubSenseColumns(subColumns);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (loadData) {
      setLoadData(false);
      searchData();
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const searchData = () => {
    updateBibleField("isLoading", true);
    setSenses([]);
    setSubSenses([]);
    setSearchKey(searchFor);
    setSearchField(searchBy.value);
    removeCurrentSelection();
    searchBible(
      language,
      searchBy.value,
      searchBy && searchFor && searchBy.value === "Word"
        ? searchFor.label
        : searchFor.trim(),
      searchBy && searchFor && searchBy.value === "Word" ? searchFor.value : ""
    ).then((res) => {
      updateBibleField("isLoading", false);
      if (res.response && res.statusCode === 200) {
        if (res.response.senses && res.response.senses.length) {
          setSenses(res.response.senses);
        }
        if (
          res.response.subSenseDetails &&
          res.response.subSenseDetails.length
        ) {
          setSubSenses(res.response.subSenseDetails);
        }
      }
    });
  };

  const fetchSenses = (sense) => {
    if (sense.strongsNumber) {
      fetchSenseDetails(
        sense.strongsNumber,
        sense.senseFormDictionary,
        sense.senseID
      );
    }
  };

  const showSearchHistory = () => {
    setShowHistory(true);
    updateBibleField("isHistoryLoading", true);
    getSearchHistory(language).then((res) => {
      updateBibleField("isHistoryLoading", false);
      if (res.response && res.statusCode === 200) {
        setSearchHistory(res.response);
      }
    });
  };

  const getFieldValue = (data, field) => {
    switch (field) {
      case "searchParamType":
        return data[field].description;
      case "searchedOn":
        return moment(data[field]).format("DD MMM YYYY");
      default:
        return data[field];
    }
  };

  const closeHistoyModal = () => {
    setShowHistory(false);
    setSearchHistory([]);
  };

  const loadHistoryData = (data) => {
    if (data.searchParamType && data.searchParamType.description) {
      const searchParam = searchOptions.find(
        (option) => option.label === data.searchParamType.description
      );
      setSearchBy(searchParam);
    }
    setSearchFor(data.searchTerm);
    setLoadData(true);
    closeHistoyModal();
  };

  const searchWords = (input, callback) => {
    getSearchResult(input, language).then((res) => {
      let options = [];
      if (
        res &&
        res.response &&
        res.response.records &&
        res.response.records.length
      ) {
        options = res.response.records.map((word) => ({
          label: word.actualWord,
          value: word.strongsNumber,
        }));
      }
      callback(options);
    });
  };

  const renderSearchTerm = () => (
    <div className="d-flex flex-column filter-block">
      <label className="select-label">{t("biblereading.search.searchfor")} :</label>
      {searchBy && searchBy.value === "Word" ? (
        <AsyncSelect
          className="react-select"
          classNamePrefix="react-select"
          isClearable={true}
          placeholder = {t("biblereading.select")}
          onFocus={() => updateBibleField("removeListener", true)}
          onBlur={() => updateBibleField("removeListener", false)}
          onChange={setSearchFor}
          loadOptions={searchWords}
        />
      ) : (
        <input
          type="text"
          value={searchFor}
          onFocus={() => updateBibleField("removeListener", true)}
          onBlur={() => updateBibleField("removeListener", false)}
          onChange={(e) => setSearchFor(e.target.value)}
          className="form-control"
        />
      )}
    </div>
  );

  const renderFilters = () => (
    <div className="filter-list d-flex flex-wrap align-items-end">
      <div className="d-flex flex-column filter-block">
        <label className="select-label">{t("biblereading.search.searchby")} :</label>
        <Select
          value={searchBy}
          placeholder = {t("biblereading.select")}
          onChange={(value) => {
            setSearchBy(value);
            setSearchFor("");
          }}
          options={searchOptions}
          className="react-select"
          classNamePrefix="react-select"
        />
      </div>
      {renderSearchTerm()}
      <div className="search-box">
        <button
          className="btn search-btn pointer"
          disabled={
            (!searchBy || (searchBy && searchBy.value !== "Word")
              ? !searchFor.trim()
              : !searchFor) || !searchBy
          }
          onClick={searchData}
        >
          {t("helpers.utils.search.caps")}
        </button>
        <span className="history-link" onClick={showSearchHistory}>
          {t("biblereading.search.searchhistory")}
        </span>
      </div>
    </div>
  );

  const renderTitle = (title, columns) => (
    <>
      <label className="word-name">{title}</label>
      <div className="row column-title d-flex">
        {columns.map((item) => (
          <span key={item} className="col-md-4 title">
            {item}
          </span>
        ))}
      </div>
    </>
  );

  const renderSenseContent = (sense, index, fields) => (
    <div className="row result" key={`${index}${sense.strongsNumber}`}>
      <span
        className={`col-md-4 word ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"
          } ${sense.strongsNumber ? "" : "default"}`}
        onClick={() => fetchSenses(sense)}
      >
        {sense[fields[0]]}
      </span>
      <span className="col-md-4">{sense[fields[1]]}</span>
      <span className="col-md-4">{sense[fields[2]]}</span>
    </div>
  );

  const renderSenses = (senses) => (
    <div className="result-list">
      {renderTitle(
        searchField === "Word" ? searchKey.label : searchKey,
        senseColumns
      )}
      {senses.map((sense, index) =>
        renderSenseContent(sense, index, senseDataFields)
      )}
    </div>
  );

  const renderPhrases = () => (
    <>
      {renderTitle(t("biblereading.search.phrasescompoundwords"), subSenseColumns)}
      {subSenses[0].wordDetails &&
        subSenses[0].wordDetails.map((sense, index) =>
          renderSenseContent(sense, index, subSenseFields)
        )}
    </>
  );

  const renderSubDomains = () => (
    <>
      {renderTitle(t("biblereading.search.subdomains"), subDomainColumns)}
      {subSenses.map((sense, index) => (
        <div className="row result" key={`${index}${sense.semanticDomain}`}>
          <span className="col-md-4 ">{sense.semanticDomain}</span>
          {sense.wordDetails && sense.wordDetails.length > 0 && (
            <div className="col-md-8 flex-wrap">
              {sense.wordDetails.map((word, index) => (
                <React.Fragment key={`${index}${sense.strongsNumber}`}>
                  <span
                    className={` word ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"
                      } ${word.strongsNumber ? "" : "default"}`}
                    onClick={() => fetchSenses(word)}
                  >
                    {word.text}
                  </span>
                  <span className="separator">
                    {index + 1 === sense.wordDetails.length ? "" : ", "}
                  </span>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );

  const renderResults = () => (
    <div className="search-results">
      {senses.length > 0 && renderSenses(senses)}
      {subSenses.length > 0 &&
        subSenses[0].wordDetails &&
        subSenses[0].wordDetails.length > 0 && (
          <div className="result-list mt-4 mb-3">
            {searchField === "SemanticDomain"
              ? renderSubDomains()
              : renderPhrases()}
          </div>
        )}
    </div>
  );

  const renderModalBody = () => (
    <div>
      <label className="title">{t("biblereading.search.searchhistory")}</label>
      <i
        className="icon icon-close-2 close-icon"
        onClick={closeHistoyModal}
      ></i>
      {searchHistory.map((data, index) => (
        <div
          key={index}
          className="result-block d-flex"
          onClick={() => loadHistoryData(data)}
        >
          <i className="icon icon-history"></i>
          <div className="d-flex flex-fill flex-wrap row">
            {searchFields.map((field) => (
              <div
                className={`d-flex  flex-column field-block ${field.value === "searchedBy"
                  ? "col-md-12 searched-by"
                  : "col-md-6"
                  }`}
                key={field.label}
              >
                <label className="field-name">{field.label}</label>
                <span className="field-value">
                  {getFieldValue(data, field.value)}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="bible-search-container">
      {renderFilters()}
      {!isLoading && (
        <>
          {senses.length > 0 || subSenses.length > 0 ? (
            <Scrollbars>{renderResults()}</Scrollbars>
          ) : (
            <img src={noSearchData} alt="" />
          )}
        </>
      )}
      <SharedModal
        show={showHistory}
        modalBody={renderModalBody()}
        handleClose={() => { }}
        modalClass="no-header search-history-modal"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.sideMenu.language,
  isLoading: state.bible.isLoading,
});

const mapDispatchToProps = {
  searchBible,
  updateBibleField,
  getSearchHistory,
  getSearchResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBible);
