import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as Keycloak from "keycloak-js";
import { withRouter } from "react-router-dom";
import Loader from "../sharedComponents/Loader";
import { fullerAuth } from "./actions";
import { NotificationManager } from "react-notifications";
import { Card } from "react-bootstrap";
import logo from "../../assets/images/logocopy.png";

const KeycloakLogin = ({ history, fullerAuth }) => {
	const initOptions = {
		url: "https://test1-equipauth.fuller.edu/auth/",
		realm: "equip",
		clientId: "biblingo-test",
		onLoad: "login-required",
	};

	const fullerLogin = async (keycloak, courseId) => {
		const {
			idToken,
			idTokenParsed: { given_name: firstName, family_name: lastName, email },
		} = keycloak;
		const language = ["GK101", "GK102"].includes(courseId) ? "Greek" : "Hebrew";
		const creds = {
			token: idToken,
			courseID: courseId,
			email: email,
			firstName: firstName,
			lastName: lastName,
		};
		const { statusCode, response } = await fullerAuth(creds);
		if (statusCode === 200) history.push(`/home/${language}/Dashboard`);
		else {
			NotificationManager.error(response && response.message, "Error", 2000);
			history.push(`/login`);
		}
	};

	useEffect(() => {
		const keycloak = Keycloak(initOptions);
		const {
			location: { search },
		} = window;
		if (search && search.length) {
			const courseId = search.substring(1).split("=")[1];
			localStorage.setItem("courseId", courseId);
		}
		keycloak.init({ onLoad: initOptions.onLoad }).success((authenticated) => {
			if (keycloak) {
				if (authenticated) {
					const courseId = localStorage.getItem("courseId");
					fullerLogin(keycloak, courseId);
				}
			}
		});
	});
	return (
		<>
			<div className="d-flex w-100 login-wrapper">
				<Loader />
				<div className="login-bg-wrapper"></div>
				<div className="campus">
					<Card className="login-section">
						<img src={logo} className="logo-img floater" alt="logo" />
					</Card>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	auth: state.auth.auth,
});

const mapDispatchToProps = { fullerAuth };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeycloakLogin));
