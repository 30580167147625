import React from "react";
import { Carousel } from "react-bootstrap";

const ImgSider = ({ index, list, onSelect = () => {}, className }) => {
	return (
		<Carousel activeIndex={index} controls={false} onSelect={onSelect}>
			{list &&
				list.length > 0 &&
				list.map(
					(img) =>
						img.url && (
							<Carousel.Item key={img.url}>
								<img
									className={` ${className ? className : "d-block w-100 h-100"}`}
									src={img.url}
									alt={img.name}
								/>
							</Carousel.Item>
						)
				)}
		</Carousel>
	);
};

export default ImgSider;
