import {logOutUser, verifyUser as getToken} from "../utils/verifyUser";
import i18n from "i18next";
import moment from "moment-timezone";

 export const API_URL = "https://mwapp.biblingo.org/";
// export const API_URL = "https://localhost:5001/";

export default function fetchHandler({ url, method, actionType, fileUpload, body, secure = true, shouldDispatch = true }) {
	return async (dispatch) => {
		const token = getToken();
		let headersData = fileUpload
			? {}
			: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Culture: i18n.language ? i18n.language : "en",
			  };

		if (secure) {
			headersData = {
				...headersData,
				Authorization: `Bearer ${token}`,
				TimezoneOffset: moment().utcOffset(),
				LocalTime: new Date().toISOString(),
			};
		}
		const promise = fetch(`${API_URL}${url}`, {
			method,
			headers: headersData,
			body,
		});
		const response = await promise;
		return responseHandler(response, actionType, dispatch, shouldDispatch);
	};
}

async function responseHandler(response, actionType, dispatch, shouldDispatch) {
	let json;
	try {
		json = response.json && (await response.json());
	} catch (error) {
		console.log(`Response is not a json object`); // eslint-disable-line no-console
	}
	json = json || {};

	if (200 <= response.status && response.status < 300) {
		if (shouldDispatch) {
			dispatch({
				type: actionType,
				payload: json,
			});
		}
		return json;
	} else if (response.status === 401) {
		logOutUser();
	} else {
		return { ...json, hasError: true };
	}
}
