import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Question from "../../../assets/images/q.svg";
import Answer from "../../../assets/images/a.png";
import Close from "../../../assets/images/close.png";
import Loader from "../../sharedComponents/Loader";
import { getAllAnswers } from "./actions";
import SharedModal from "../../sharedComponents/Modal";

const QuestionCard = ({ questionDetails, getAllAnswers, answers }) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(null);
	const [loading, setLoading] = useState();

	const showAnswers = (id) => {
		if (id === show) {
			setShow(null);
		} else {
			setLoading(true);
			getAllAnswers(id).then((res) => {
				setShow(id);
				setLoading(false);
			});
		}
	};

	const renderQuestion = () => (	
		<div className="d-flex justify-content-between">
			<div className="d-flex">
				<div className="d-flex align-items-start mr-3">
					<img src={Question} alt="" />
				</div>

				<div>
					<div className="date-updates">
						{questionDetails.askedOn && (
							<span>{t("helpers.utils.learning.askquestion.askedon", { date: moment(questionDetails.askedOn).format(" DD MMM YYYY") })}</span>
						)}
						{questionDetails.lastUpdated &&
							<span> {t("helpers.utils.learning.askquestion.lastupdated", { date: questionDetails.lastUpdated })}</span>
						}
					</div>
					<p className="student-question mt-2">{questionDetails.title}</p>
					<div>
						{questionDetails.language && questionDetails.language.description && (
							<label className="student-qna-label rounded-pill mr-2">
								{questionDetails.language.description}
							</label>
						)}
						{questionDetails.questionType && questionDetails.questionType.description && (
							<label className="student-qna-label rounded-pill mr-2">
								{questionDetails.questionType.description}
							</label>
						)}
						{questionDetails.displayLessonNumber && (
							<label className="student-qna-label rounded-pill mr-2">
								{t("languagelearning.lessons.details.sidepanel.lesson", { lessonnumber: questionDetails.displayLessonNumber })}
							</label>
						)}
					</div>
				</div>
			</div>
			{show === null && (
				<div className="d-flex flex-column justify-content-center align-items-center">
					<label className="answer-count-label">{questionDetails.totalAnswers}</label>
					<span className="answers-title">{t("helpers.utils.learning.askquestion.answers")} </span>
				</div>
			)}
		</div>
	);
	const handleAnswerClose = (e) => {
		e.stopPropagation();
		setShow(null);
	};

	const renderModalBody = () => {
		return (
			<div className="border-0 modal-background">
				<div className="p-4 ">{renderQuestion()}</div>

				<div className="answers-head px-4 py-2">
					{answers && answers.length > 1 ? t("helpers.utils.answer.plural") : t("helpers.utils.answer.singular") }
				</div>
				{answers &&
					answers.map((item, index) => (
						<div key={index} className="d-flex answer-section-border">
							<div className="d-flex w-75 mt-3 px-4" key={index}>
								<div className="d-flex align-items-start mr-3">
									<img src={Answer} alt="" />
								</div>

								<div>
									<p className="mb-0 answered-by"> {item.answeredBy} </p>
									<span className="answered-on">
										{t("qa.addedanswreron", { date: moment(item.answeredOn).format(" DD MMM YYYY [at] h:mm A z") })}
									</span>
									<p className="mt-2 answer-section">{item.description}</p>
								</div>
							</div>
							{item.isVerified && <div className="best-answer student-answer mt-3">{t("helpers.utils.learning.askquestion.bestanswer")}</div>}
						</div>
					))}
			</div>
		);
	};

	const renderModalFooter = () => {
		return (
			<button className="btn-close" onClick={(e) => handleAnswerClose(e)}>
				<img src={Close} alt="" />
			</button>
		);
	};

	return (
		questionDetails && (
			<div className="px-4 student-qna-card cursor-pointer mb-4 " onClick={() => showAnswers(questionDetails.id)}>
				{loading && <Loader />}
				<Card>
					<Card.Body>{renderQuestion()}</Card.Body>
				</Card>
				<SharedModal
					show={show !== null && true}
					modalBody={renderModalBody()}
					modalFooter={renderModalFooter()}
					handleClose={() => setShow(null)}
					modalClass="panel-top-modal no-header"
					id="qa-details-modal"
				/>
			</div>
		)
	);
};

const mapStateToProps = (state) => ({
	answers: state.studentQnA.answersList,
});

const mapDispatchToProps = { getAllAnswers };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionCard));
