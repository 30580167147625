import React, { useState } from "react";
import { connect } from "react-redux";
import Pagination from "../sharedComponents/Pagination";
import { setWordDetails } from "../Submenu/actions";
import { NoContent } from "../sharedComponents/NoContent";
import "./lessonDetails.scss";
import Lesson from "./Lesson";
import Dictionary from "./Dictionary";
import LanguageLearning from "./LanguageLearning";
import Loader from "../sharedComponents/Loader";

const LessonDetails = ({ type, wordDetails, lessonDetails, wordCount, language, setWordDetails, menu }) => {
	const [activePage, setActivePage] = useState(1);
	const [loading, setLoading] = useState(false);

	const handlePageChange = (e) => {
		setLoading(true);
		setActivePage(e);
		setWordDetails(`Language=${language}&Index=${e - 1}`).then((res) => {
			setLoading(false);
		});
	};
	return (
		<div className="lesson-details">
			{menu.length > 0 && menu[0] && menu[0].title === "Language Learning" ? (
				<LanguageLearning />
			) : (
				<React.Fragment>
					{type === "dictionary" && (
						<span>
							{wordDetails && wordDetails.length > 0 ? (
								<span>
									<Dictionary wordDetails={wordDetails} />
									{wordDetails.length !== 1 && (
										<Pagination
											activePage={activePage}
											itemsCountPerPage={20}
											totalItemsCount={wordCount}
											pageRangeDisplayed={7}
											onChange={handlePageChange}
										/>
									)}
									{loading && <Loader />}
								</span>
							) : (
								<NoContent />
							)}
						</span>
					)}
					{type === "lessons" && (
						<span>{lessonDetails && lessonDetails.length > 0 ? <Lesson /> : <NoContent />}</span>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	wordDetails: state.subMenu.wordDetails,
	wordCount: state.subMenu.wordCount,
	lessonDetails: state.subMenu.lessonDetails,
	type: state.subMenu.type,
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setWordDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetails);
