import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

import { setSideMenu, sessionLogging } from "./actions";
import BiblingoLogo from "../../assets/images/biblingo-logo.png";
import DropdownMenus from "./DropdownMenus";
import MobileHeader from "../mobileHeader";
import { getUserDetails } from "../../utils/verifyUser";
import { useTranslation } from "react-i18next";
import "./sidemenu.scss";

const Sidemenu = ({ sidemenu, history, setSideMenu, isStudentMode, language, sessionLogging }) => {
	const { t } = useTranslation();
	const menus = [
		{
			label: t("dashboard.vocabulary.name"),
			icon: "icon-vocabulary",
			value: "Vocabulary",
		},
		{
			label: t("dashboard.filtervalues.languagelearning"),
			icon: "icon-grammar",
			value: "grammar",
		},
	];

	const languageLearningMenu = [
		{
			label: t("menu.lessons"),
			icon: "icon-vocabulary",
			value: "Language-Learning",
		},
		{
			label: t("languagelearning.fluencydrills.name"),
			icon: "icon-academic",
			value: "Fluency-Drills",
		},
		{
			label: t("languagelearning.readingcomprehension.name"),
			icon: "icon-bookmark",
			value: "Reading-Comprehension",
		},
	];

	const mediaMenu = [
		{
			label: "Images",
			icon: "icon-camera",
			value: "Images",
			url: "/Media-Gallery",
		},
		{
			label: "Audios",
			icon: "icon-speaker1",
			value: "Audios",
			url: "/Media-Gallery",
		},
		{
			label: "Videos",
			icon: "icon-player",
			value: "Videos",
			url: "/Media-Gallery",
		},
	];

	const userSubMenus = [
		{
			label: "Internal",
			icon: "icon-internal-user",
			value: "internal",
		},
		{
			label: "Student",
			icon: "icon-external-user",
			value: "student",
		},
	];

	const adminMenuList = [
		{
			subMenu: menus,
			title: "Greek",
			icon: "icon-greek-and-hebrew",
			url: `/home/Greek`,
			primary: true,
		},
		{
			subMenu: menus,
			title: "Hebrew",
			icon: "icon-greek-and-hebrew",
			url: `/home/Hebrew`,
		},
		{
			subMenu: userSubMenus,
			title: "User Management",
			icon: "icon-user-1",
			url: `/home/users`,
		},
		{
			subMenu: mediaMenu,
			title: "Media Gallery",
			icon: "icon-media-gallery1",
			url: `/Media-Gallery`,
		},
		{
			title: "Q&A",
			icon: "icon-question1",
			url: `/qa`,
		},
		{
			title: "Settings",
			icon: "icon-settings-2",
			url: `/settings`,
		},
	];

	const deckMenus = [
		{
			label: t("flashcarddeck.presetdeck.name"),
			icon: "icon-deck-icon",
			value: "deck/preset",
		},
		{
			label: t("flashcarddeck.wordsthatneedpractice.name"),
			icon: "icon-deck-icon",
			value: "deck/wordsNeedPractise",
		},
		{
			label: t("biblereading.sense.customdeck"),
			icon: "icon-deck-icon",
			value: "deck/custom",
		},
		{
			label: t("menu.flashcardsettings"),
			icon: "icon-deck-icon",
			value: "flashcard/settings",
		},
	];

	const studentMenu = [
		{
			subMenu: languageLearningMenu,
			title: t("dashboard.filtervalues.languagelearning"),
			icon: "icon-language",
			url: "/Home/Language-Learning",
			primary: true,
		},
		{
			title: t("biblereading.name"),
			icon: "icon-bible",
		},
		{
			subMenu: deckMenus,
			title: t("flashcarddeck.name"),
			icon: "icon-flashcard-deck",
			url: `/home/deck`,
		},
		{
			title: t("menu.alphabetlessons"),
			icon: "icon-media-gallery1",
		},
	];

	const studentMenuList = [
		...studentMenu,
		{
			title: t("menu.q&a"),
			icon: "icon-question1",
			url: "/home/qa",
		},
	];
	const adminAsStudentMenu = [...studentMenu];

	const rolesMenu = {
		Student: studentMenuList,
		Administrator: adminMenuList,
		adminAsStudent: adminAsStudentMenu,
		"Internal Teacher": adminMenuList,
		"External Teacher": adminMenuList,
	};

	const moduleList = {
		Lessons: 0,
		1: t("flashcarddeck.presetdeck.name"),
		1: t("biblereading.sense.customdeck"),
		2: t("flashcarddeck.wordsthatneedpractice.name"),
		3: t("biblereading.name"),
		4: t("menu.fluencydrill"),
	};
	const [show, showMenu] = useState(false);
	const [role, setUserRole] = useState("");

	useEffect(() => {
		if (isStudentMode) {
			setUserRole("adminAsStudent");
		} else {
			setUserRole(getUserDetails().role.description);
		}
	}, [isStudentMode]);

	const handleClick = () => {
		showMenu(!show);
	};

	const getUrl = (url, title) => {
		switch (title) {
			case t("dashboard.filtervalues.languagelearning"):
				return getUserDetails().languages.length > 1
					? `/home/${language}/Language-Learning`
					: `/Home/${getUserDetails().languages[0] === 0 ? "Hebrew" : "Greek"}/Language-Learning`;
			case t("biblereading.name"):
				return `/Home/${language}/bible-reading`;
			case t("menu.q&a"):
				return role !== "Student" ? url : `/home/${language}/qa`;
			case t("menu.alphabetlessons"):
				return `/Home/${language}/alphabet-lessons`;
			default:
				return url;
		}
	};

	const handleRedirection = (url, title) => {
		const link = getUrl(url, title);
		setSideMenu({
			menu: [t("home.name"), title],
		});
		handleClick();
		handleSessionLog(title);
		history.push(link);
	};

	const handleSessionLog = (title) => {
		const sessionId = localStorage.getItem("sessionId");
		sessionLogging({
			language: language === "Hebrew" ? 0 : 1,
			sessionId: sessionId ? sessionId : null,
			module: moduleList[title],
		});
	};

	const enableModule = (menu) => {
		const { isBibleReading, isLanguageLearning } = getUserDetails().modules;
		switch (menu.title) {
			case t("dashboard.filtervalues.languagelearning"):
				return isLanguageLearning;
			case t("biblereading.name"):
				return isBibleReading;
			default:
				return true;
		}
	};

	const renderMenuContent = (menuItem) =>
		menuItem.subMenu ? (
			<DropdownMenus
				menus={menuItem.subMenu}
				title={menuItem.title}
				iconName={menuItem.icon}
				url={
					menuItem.title === t("flashcarddeck.name") ||
					menuItem.title === t("dashboard.filtervalues.languagelearning")
						? `/home/${language}`
						: menuItem.url
				}
				handleClick={handleClick}
				menuChange={handleSessionLog}
			/>
		) : (
			<label className="title d-flex flex-column pointer" onClick={() => handleRedirection(menuItem.url, menuItem.title)}>
				<i className={`icon ${menuItem.icon}`}></i>
				<span className="menu-name">{menuItem.title}</span>
			</label>
		);

	return (
		<React.Fragment>
			<MobileHeader handleClick={handleClick} />
			<div className={`sidemenu ${show && "d-block"}`}>
				<Scrollbars>
					<ul>
						<li
							className={`bib-logo-lg ${getUserDetails().role.description === "Administrator" ? "" : "pointer"}`}
							onClick={() => {
								if (getUserDetails().role.description !== "Administrator") {
									history.push(`/home/${language}/Dashboard`);
								}
							}}>
							<img src={BiblingoLogo} alt="logo" className="w-100 bib-logo" />
						</li>
						{rolesMenu[role] &&
							rolesMenu[role].map((menuItem, index) => {
								// Removing Custom deck for Free users - 186866
								if (getUserDetails().subscriptionPlan === 4 && menuItem.title === "Flashcard Deck") {
									const index = menuItem.subMenu.findIndex((deck) => deck.label === "Custom Decks");
									if (index >= 0) menuItem.subMenu.splice(index, 1);
								}
								return (
									enableModule(menuItem) && (
										<React.Fragment key={index}>
											<li
												className={`p-0 ${
													sidemenu[1] && sidemenu[1] === menuItem.title ? "active" : ""
												}`}
												key={menuItem.title}
												style={menuItem.primary ? { marginTop: "8px" } : {}}>
												{renderMenuContent(menuItem)}
											</li>
										</React.Fragment>
									)
								);
							})}
					</ul>
				</Scrollbars>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	sidemenu: state.sideMenu.menu,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	sessionLogging,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidemenu));
