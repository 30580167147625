import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

import pageNotFoundImg from "../../assets/images/404.png";
import Header from "../header";

const PageNotFound = () => {
	const { t } = useTranslation();
	useEffect(() => {
		let urlParams = new URLSearchParams(window.location.search);
		let token = urlParams.get("token");
		if (token) {
			NotificationManager.error(t("login.notification.alreadyloggedin"), "", 5000);
		}
	}, []);

	return (
		<div className="page-not-found empty">
			<Header head="" />
			<div className="d-flex justify-content-center">
				<img src={pageNotFoundImg} alt="" />
			</div>
		</div>
	);
};

export default PageNotFound;
