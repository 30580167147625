import React, { useState, useEffect, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import SharedModal from "../../../sharedComponents/Modal";
import Lesson from "../../../../assets/images/lesson.png";
import Level from "../../../../assets/images/level.png";
import Vocabulary from "../../../../assets/images/vocabulary.png";
import Grammar from "../../../../assets/images/grammer.png";
import { getUserDetails } from "../../../../utils/verifyUser";
import { useTranslation } from "react-i18next";

const Modals = ({ lessonDetails, history, match, updateAcitivity, setIntroPlayng, loading, sidePanel, prevLesson }) => {
	const [show, setShow] = useState(null);
	const [videoCompleted, setVideoCompleted] = useState(false);
	const [lessonId, setLessonId] = useState(null);
	const { origin, courseID, courseLanguage } = getUserDetails();
	let courseName;
	if (courseID) {
		courseName = courseID.includes("GK") ? `Greek ${courseID.substring(2)}` : `Hebrew ${courseID.substring(2)}`;
	}
	const { t } = useTranslation();
	const redirectToDashboard = useCallback(() => {
		history.push(`/home/${match.params.language}/Language-Learning`);
		NotificationManager.success(t("languagelearning.lessons.details.completedmodal.completedsublesson"), "", 4000);
	}, [history, match]);

	useEffect(() => {
		if (lessonDetails) {
			lessonDetails.isCompleted
				? redirectToDashboard()
				: lessonDetails.isSubLessonCompleted && !lessonDetails.isLevelEnd
				? setShow("subLessonCompleted")
				: lessonDetails.isLevelCompleted
				? setShow("levelCompleted")
				: lessonDetails.isLessonCompleted && !lessonDetails.isSubLessonCompleted
				? setShow("lessonCompleted")
				: lessonDetails.isPhaseCompleted
				? setShow("phaseCompleted")
				: lessonDetails.isTopicCompleted
				? setShow("topicCompleted")
				: lessonDetails &&
				  lessonDetails.activity &&
				  lessonDetails.activity.showIntroduction &&
				  lessonDetails.activity.introduction &&
				  lessonDetails.activity.introduction.filePath
				? setShow("video")
				: setShow(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessonDetails, redirectToDashboard]);

	useEffect(() => {
		if (show !== "video") {
			setIntroPlayng(false);
		} else {
			setIntroPlayng(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	useEffect(() => {
		document.getElementById("videoIds") &&
			document.getElementById("videoIds").addEventListener("ended", videoEndHandler, false);

		if (
			window &&
			window.location &&
			window.location.search.slice(1).split("&")[0] &&
			window.location.search.slice(1).split("&")[0].split("=")[1]
		) {
			if (window.location.search.slice(1).split("&")[0].split("=")[0] === "lessonId") {
				setLessonId(window.location.search.slice(1).split("&")[0].split("=")[1]);
			} else {
				setLessonId(lessonDetails.lessonId);
			}
		}
	});

	const videoEndHandler = (e) => (document.getElementById("nextBtn").disabled = false);

	const handleNext = (label, lesson) => {
		setShow(null);
		if (label === "lessonCompleted" || label === "levelCompleted") {
			history.push(
				`/home/${match.params.language}/Language-Learning/${lesson.lessonNumber}/Vocabulary/${"Association"}?lessonId=${
					lesson.lessonId
				}`
			);
		} else {
			const phase = lesson.phase.description === "Vocabulary" ? "Grammar" : "FinalAct";
			history.push(
				`/home/${match.params.language}/Language-Learning/${lesson.lessonNumber}/${phase}/${
					lesson.stage === 6 ? "Final" : "Association"
				}?lessonId=${lesson.lessonId}&activityId=${lesson.activityId}&action=get`
			);
		}
	};

	const pauseVideo = (e, completed) => {
		if (completed && !videoCompleted) {
			setVideoCompleted(true);
		}
		document.getElementById("videoIds").pause();
	};

	const renderModalBody = () => {
		switch (show) {
			case "levelCompleted":
				return renderPopUp(
					"levelCompleted",
					Level,
					t("languagelearning.lessons.details.completedmodal.levelcompleted", { level: lessonDetails.level }),
					t("languagelearning.lessons.details.completedmodal.nextlevel")
				);

			case "lessonCompleted":
				return renderPopUp(
					"lessonCompleted",
					Lesson,
					t("languagelearning.lessons.details.completedmodal.completedmsg", {
						lesson: lessonDetails.displayLessonNumber,
					}),
					t("languagelearning.lessons.details.completedmodal.nextlesson")
				);

			case "subLessonCompleted":
				return renderPopUp(
					"lessonCompleted",
					Lesson,
					t("languagelearning.lessons.details.completedmodal.sublessoncompleted", {
						sublesson: lessonDetails.displayLessonNumber,
					}),
					t("languagelearning.lessons.details.completedmodal.nextlesson")
				);

			case "phaseCompleted":
				// Fix for lessonDetails going null when phase completed
				if (lessonDetails.phase === undefined && prevLesson.phase !== undefined) lessonDetails.phase = prevLesson.phase;
				return (
					lessonDetails.phase &&
					renderPopUp(
						"phaseCompleted",
						lessonDetails.phase.description === "Vocabulary" ? Vocabulary : Grammar,
						lessonDetails.phase.description === "Vocabulary"
							? t("helpers.utils.sidepanel.vocabulary")
							: lessonDetails.phase.description === "Grammar"
							? t("languagelearning.lessons.details.sidepanel.grammar")
							: t("languagelearning.lessons.details.sidepanel.finalact"),
						t("languagelearning.lessons.details.completedmodal.phasecompleted", {
							phase:
								lessonDetails.phase.description === "Vocabulary"
									? t("helpers.utils.grammar")
									: t("helpers.utils.application"),
						})
					)
				);

			case "topicCompleted":
				return renderPopUp(
					"topicCompleted",
					null,
					t("languagelearning.lessons.details.completedmodal.topiccompleted", { topic: lessonDetails.topic }),
					t("languagelearning.lessons.details.completedmodal.nexttopic")
				);

			case "video":
				return renderVideoPlayer();

			default:
				return null;
		}
	};

	const renderPopUp = (label, popupImage, popupHead, popupLink) =>
		lessonDetails && (
			<div className="text-center">
				{label === "topicCompleted" ? (
					<div className="topic-level mb-4">
						<label className="d-flex justify-content-center align-items-center">{lessonDetails.topic}</label>
					</div>
				) : (
					<img src={popupImage} alt=" " className="mb-4" />
				)}
				{label === "phaseCompleted" ? (
					<p className="popup-head">
						{t("languagelearning.lessons.details.completedmodal.completedmsg", {
							popuphead: popupHead,
						})}
					</p>
				) : (
					<p className="popup-head">{popupHead}</p>
				)}
				{/* temporary fix for sublesson completion popup msg */}
				{origin === "CampusEdu" && ["15.4", "31.4"].includes(lessonDetails.displayLessonNumber) ? (
					<>
						{courseName && (
							<p className="bonus-points">{`Congragulations. You have succesfully completed ${courseName}`}</p>
						)}
						<p className="bonus-points">
							{t("languagelearning.lessons.details.completedmodal.login")} &nbsp;
							{origin === "CampusEdu" && (
								<a className="popup-link" target="_blank" href="https://learn.stg.campusedu.tech/">
									{t("languagelearning.lessons.details.completedmodal.campus")}
								</a>
							)}
							{origin === "Fuller" && (
								<a className="popup-link" target="_blank" href="https://www.fuller.edu/">
									{t("languagelearning.lessons.details.completedmodal.fuller")}
								</a>
							)}
							&nbsp; {t("languagelearning.lessons.details.completedmodal.loginext")} &nbsp;
							<a className="popup-link" target="_blank" href="https://biblingo.org/">
								{t("languagelearning.lessons.details.completedmodal.biblingo")}
							</a>
						</p>
						{courseLanguage && (
							<div className="mt-3 d-flex justify-content-center">
								<Link
									className="link-popup"
									to={`/home/${courseLanguage === 0 ? "Hebrew" : "Greek"}/Dashboard`}>
									{t("languagelearning.readingcomprehension.details.completedmodal.gotodashboard")}
								</Link>
							</div>
						)}
					</>
				) : (
					<>
						{lessonDetails.badge && lessonDetails.badge.points && lessonDetails.badge.points.length > 0 && (
							<React.Fragment>
								<p className="bonus-title">
									{t("languagelearning.lessons.details.completedmodal.bonuspoints")}
								</p>
								{lessonDetails.badge.points.map((point, index) => (
									<p
										className={`bonus-points ${
											index + 1 === lessonDetails.badge.points.length ? "" : "m-0"
										}`}
										key={point.point}>
										{t("languagelearning.lessons.details.completedmodal.points", {
											point: point.point,
											description: point.description,
										})}
									</p>
								))}
							</React.Fragment>
						)}
						<div className="row">
							<p className="popup-link col-6" onClick={() => handleNext(label, lessonDetails)}>
								{t("languagelearning.lessons.details.completedmodal.continueto", {
									popuplink: popupLink,
								})}
							</p>

							<p
								className="popup-link col-6"
								onClick={() => history.push(`/home/${match.params.language}/Language-Learning`)}>
								{t("languagelearning.lessons.details.completedmodal.gotolanguagelearning")}
							</p>
						</div>
						{popupHead === "Grammar" && sidePanel.isFluencyDrillEnabled && sidePanel.isHavingFluencyDrill && (
							<p
								className="popup-link"
								onClick={() =>
									history.push(`/home/${match.params.language}/fluency-drill/learning/${lessonId}`)
								}>
								{t("languagelearning.lessons.details.completedmodal.gotofluencydrills")}
							</p>
						)}

						{popupHead !== "Grammar" &&
							sidePanel.isReadingComprehensionEnabled &&
							sidePanel.isHavingReadingComprehension && (
								<p
									className="popup-link"
									onClick={() =>
										history.push(
											`/home/${match.params.language}/reading-comprehension/learning/${lessonId}`
										)
									}>
									{t("languagelearning.lessons.details.completedmodal.gotoreadingcomprehension")}
								</p>
							)}
					</>
				)}
			</div>
		);

	const renderVideoPlayer = () => {
		return (
			<div>
				<p className="video-head mb-2">
					{t("languagelearning.lessons.details.videoplayermodal.lessonintroductionvideo")}
				</p>
				<video
					id="videoIds"
					width="100%"
					controls
					controlsList="nodownload"
					autoPlay
					onEnded={(e) => pauseVideo(e, true)}>
					<source
						src={
							lessonDetails &&
							lessonDetails.activity &&
							lessonDetails.activity.showIntroduction &&
							lessonDetails.activity.introduction &&
							lessonDetails.activity.introduction.filePath
						}
						type="video/mp4"
					/>
					{t("languagelearning.lessons.details.videoplayermodal.invalidvideotagmsg")}
				</video>
				<form className="text-right mt-2">
					<button
						type="button"
						className="btn popup-link text-right"
						id="nextBtn"
						name="register_btn"
						onClick={() => setShow(null)}>
						{t("languagelearning.lessons.details.videoplayermodal.startlesson")}
					</button>
				</form>
			</div>
		);
	};
	return (
		<React.Fragment>
			<SharedModal
				show={show !== null && true && !loading && lessonDetails}
				handleClose={() => setShow(null)}
				modalBody={lessonDetails ? renderModalBody() : null}
				modalClass={`${show === "video" ? "grammar-video intro-lesson-video no-header" : "success-popup"}`}
				backdrop="static"
			/>
		</React.Fragment>
	);
};

export default withRouter(Modals);
