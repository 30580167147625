import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import "./lineChart.scss";

const renderCustomizedTick = (props, coOrdinate, value) => {
	const { x, y, payload } = props;
	const xValue = coOrdinate === "y" ? x - value : x;
	const yValue = coOrdinate === "x" ? y + value : y;

	return (
		<text
			style={{ stroke: "#c2c2c2", fontSize: 13, fontWeight: "bolder" }}
			x={xValue}
			y={yValue}
			textAnchor="middle"
			dominantBaseline="hanging">
			{payload.value}
		</text>
	);
};

const CustomTooltip = ({ active, payload, label, units }) => {
	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip">
				<span>{label}</span>
				<br />
				<span className={payload[0].value > 0 ? "inc" : "dec"}>{Math.abs(payload[0].value)}</span>
				<span style={{ color: "#fff", marginLeft: 5 }}>{units}</span>
			</div>
		);
	}

	return null;
};

const LineChartComponent = ({ data, stroke, unit }) => {
	return (
		<ResponsiveContainer className="line-chart-container" width="100%" height={300}>
			<LineChart data={data}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" tick={(propsValue) => renderCustomizedTick(propsValue, "x", 15)} />
				<YAxis tick={(propsValue) => renderCustomizedTick(propsValue, "y", 30)} />
				<Tooltip content={<CustomTooltip units={unit} />} />
				<Line type="monotone" dataKey="pv" stroke={"#8884d8"} activeDot={{ r: 1 }} />
				<Line dataKey="value" stroke={stroke || "#82ca9d"} strokeWidth={2} />
			</LineChart>
		</ResponsiveContainer>
	);
};

export default LineChartComponent;
