import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setSideMenu } from "../../Sidemenu/actions";
import Loader from "../../sharedComponents/Loader";
import Header from "../../header";
import { getUserDetails } from "../../../utils/verifyUser";
import { updateField } from "../LanguageLearning/actions";
import { getTutorials } from "./actions";
import noData from "../../../assets/images/no-data.png";
import { NoContent } from "../../sharedComponents/NoContent";
import { useTranslation } from "react-i18next";
import "./alphabet.scss";

const AlphabetLessons = ({ history, setSideMenu, updateField, tutorials, getTutorials }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: t("menu.alphabetlessons"), path: "" }],
			language: location[2],
		});
		setLoading(true);
		getTutorials(location[2]).then(() => {
			setLoading(false);
		});
	}, [history, setSideMenu, updateField, getTutorials, t]);

	return (
		<div className="alphabet-videos">
			<Header enableLanguageSwitch={true} redirectionUrl="alphabet-lessons" head={t("menu.alphabetlessons")} />
			{loading && <Loader />}
			{!loading && (
				<div className="videos-list d-flex flex-wrap">
					{tutorials && tutorials.length > 0 ? (
						tutorials.map((video, index) => (
							<div className="tutorial" key={video.filePath}>
								<iframe
									src={video.filePath}
									key={index}
									frameBorder="0"
									allowFullScreen
									title={video.title}
									height="210px"
									width="285px"
								/>
								<label className="video-title">{video.title}</label>
							</div>
						))
					) : (
						<NoContent bgImage={noData} content={t("alphabetlesson.nolesson")} />
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	tutorials: state.alphabet.tutorials,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getTutorials,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlphabetLessons));
