import { PLACEMENT_TEST } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";

export const getTestDetails = (language) => {
	const fetchOptions = {
		url: `placement/get?language=${language === "Hebrew" ? 0 : 1}`,
		method: "GET",
		actionType: PLACEMENT_TEST.GET_TEST_DETAILS,
	};
	return fetchHandler(fetchOptions);
};

export const changeActivity = (id) => {
	const fetchOptions = {
		url: `placementtestactivity/next/${id}`,
		method: "POST",
		actionType: PLACEMENT_TEST.CHANGE_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};

export const verifyActivity = (data) => {
	const fetchOptions = {
		url: `placementtestactivity/verify`,
		method: "POST",
		body: JSON.stringify(data),
		actionType: PLACEMENT_TEST.VERIFY_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};

export const clearTestDetails = () => ({ type: PLACEMENT_TEST.CLEAR_ON_UNMOUNT });
