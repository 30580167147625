import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

import ProgressCircle from "./ProgressBar";
import { getUserDetails } from "../../../utils/verifyUser";
import { updateField } from "./actions";
import { setSideMenu } from "../../Sidemenu/actions";
import Loader from "../../sharedComponents/Loader";
import { getLevelDetails } from "./actions";
import CompletedImg from "../../../assets/images/Oval1.png";
import ActiveImg from "../../../assets/images/Oval2.png";
import InActiveImg from "../../../assets/images/oval3.png";
import ReactTooltip from "react-tooltip";
import Header from "../../header";
import UserDetailsForm from "../home/userDetailsForm";
import { sessionLogging } from "../../Sidemenu/actions";
import "./languageLearning.scss";
import { useTranslation } from "react-i18next";
const LanguageLearning = ({
	menu,
	setSideMenu,
	history,
	getLevelDetails,
	updateField,
	match,
	showPronunciationModal,
	language,
	sessionLogging,
}) => {
	const { t } = useTranslation();
	const [levelDetails, setLevelDetails] = useState({});
	const [loading, setloading] = useState(false);
	const [currentLevel, setCurrentLevel] = useState(null);
	const [levelNumber, setLevelNumber] = useState(1);
	const [currentLessonNumber, setCurrentLessonNumber] = useState(1);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		if (localStorage.getItem("showPronunciationModal")) {
			updateField("showPronunciationModal", true);
		}
	}, [updateField, match]);

	const closePronunciationModal = useCallback(() => {
		localStorage.removeItem("showPronunciationModal");
		updateField("showPronunciationModal", false);
	}, [updateField]);

	const handleLevelResponse = useCallback(
		(res) => {
			if (res.statusCode === 200) {
				setLevelDetails(res.response);
				const startLessonNumber =
					res.response &&
					res.response.levels &&
					res.response.levels[0] &&
					res.response.levels[0].lessons &&
					res.response.levels[0].lessons[0] &&
					res.response.levels[0].lessons[0].lessonNumber;
				setCurrentLessonNumber(res.response.completed + startLessonNumber);
				const latestLevel =
					res.response.levels &&
					res.response.levels.length > 0 &&
					res.response.levels.filter((item) => item.isCurrent)[0] &&
					res.response.levels.filter((item) => item.isCurrent)[0].level;
				setLevelNumber(latestLevel);
				setCurrentLevel(latestLevel);
			} else {
				NotificationManager.error(
					`${res.response && res.response.message ? res.response.message : t("helpers.utils.nodata")}`,
					"",
					3000
				);
				if (getUserDetails().languages && getUserDetails().languages.length > 1) {
					history.push("/home");
				} else {
					const sessionId = localStorage.getItem("sessionId");
					localStorage.removeItem("auth");
					localStorage.removeItem("settings");
					localStorage.removeItem("sessionId");
					// localStorage.removeItem("i18nextLng");
					sessionLogging({
						language: match.params.language === "Hebrew" ? 0 : 1,
						sessionId: sessionId ? sessionId : null,
						module: 6,
					});
					history.push("/login");
					if (localStorage.getItem("showPronunciationModal")) {
						closePronunciationModal();
					}
				}
			}
		},
		[history, closePronunciationModal]
	);

	useEffect(() => {
		setloading(true);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: location[3] === "Language-Learning" ? t("menu.languagelearning") : location[3], path: "" }],
			language: location[2],
		});
		getLevelDetails(match.params && match.params.language).then((res) => {
			setloading(false);
			handleLevelResponse(res);
		});
	}, [history, setSideMenu, getLevelDetails, match, handleLevelResponse]);

	const handleLessonClick = (lesson) => {
		if (match.params.language && lesson.lessonNumber <= currentLessonNumber) {
			history.push(
				`/home/${match.params.language}/Language-Learning/${lesson.lessonNumber}/Vocabulary/Association?lessonId=${lesson.id}`
			);
		}
	};

	const expandLevel = (e, level) => {
		e.stopPropagation();
		setCurrentLevel(currentLevel === level ? "" : level);
	};

	const getImageStatus = (stage) => {
		switch (stage) {
			case 0:
				return { image: InActiveImg, label: t(["helpers.utils.lessonstatus.notstarted"]) };
			case 1:
				return { image: ActiveImg, label: t(["helpers.utils.lessonstatus.inprogress"]) };
			case 2:
				return { image: CompletedImg, label: t(["helpers.utils.lessonstatus.completed"]) };
			default:
				return { image: InActiveImg, label: t(["helpers.utils.lessonstatus.notstarted"]) };
		}
	};

	const getLevelStatus = (item) => {
		return `<div class="d-flex flex-column text-left"> 
              <p>
                <img src=${getImageStatus(item.vocabularyStage.status).image} class="mr-2"/>
                ${t("dashboard.vocabulary.name")}  (${getImageStatus(item.vocabularyStage.status).label})
              </p>
              <p>
              <img src=${getImageStatus(item.grammarStage.status).image} class="mr-2"/>
			  ${t("dashboard.grammar.name")}   (${getImageStatus(item.grammarStage.status).label})
			  </p>
			  <p class="ml-4">
			    ${t("languagelearning.lessons.topic")} : ${item.topic}
			  </p>
              <p>
                <img src=${getImageStatus(item.finalActStage.status).image} 
                  class="mr-2" />
				  ${t("languagelearning.lessons.details.sidepanel.finalact")}    (${getImageStatus(item.finalActStage.status).label})
              </p>
            </div>`;
	};

	const getCircularValue = (status) => {
		switch (status) {
			case 0:
				return "#d6e2e8";
			case 1:
				return "#33BE5D";
			default:
				return "#1A7AFF";
		}
	};

	const getCompletedLevel = (topic) => {
		return `<div class="d-flex flex-column text-left"> 
              <p>
              ${t("languagelearning.lessons.retakemsg")}
			  </p>
			  <p class="d-flex justify-content-center">
			  ${t("languagelearning.lessons.topic")} : ${topic}
              </p>
            </div>`;
	};

	const getGrammarTopic = (topic) => {
		return `<div class="d-flex flex-column text-left"> 
              <p>
              ${topic}
              </p>
            </div>`;
	};

	const toFluencyDrills = (item) => (
		<span
			className={`icon-star-6 position-absolute ${!item.isFluencyDrillEnabled && "disable"}`}
			onClick={(e) => {
				if (item.isFluencyDrillEnabled) {
					e.stopPropagation();
					history.push(`/home/${language}/fluency-drill/learning/${item.id}`);
				}
			}}></span>
	);

	const toReadingComprehension = (item) => (
		<span
			className={`icon-bookmark position-absolute ${item.isReadingComprehensionEnabled && "enabled"}`}
			onClick={(e) => {
				if (item.isReadingComprehensionEnabled) {
					e.stopPropagation();
					history.push(`/home/${language}/reading-comprehension/learning/${item.id}`);
				}
			}}></span>
	);

	const getLevelContainers = (item, prevItem, level) => {
		if ((level !== levelNumber && item.status !== 2) || (item.status === 0 && prevItem && prevItem.status !== 2)) {
			return (
				<div
					className="level-container-inactive align-items-center level-wrappers position-relative"
					data-tip={getGrammarTopic(item.topic)}>
					{item.isHavingFluencyDrill && toFluencyDrills(item)}
					{item.isHavingReadingComprehension && toReadingComprehension(item)}
					{item.displayLessonNumber}
				</div>
			);
		} else if (
			item.status === 1 ||
			(item.status === 0 && level === levelNumber && (!prevItem || (prevItem && prevItem.status === 2)))
		) {
			return getInProgressLevel(item);
		} else {
			return (
				<div
					className="level-container-completed d-flex align-items-center justify-content-center level-wrappers position-relative"
					data-tip={getCompletedLevel(item.topic)}>
					{item.isHavingFluencyDrill && toFluencyDrills(item)}
					{item.isHavingReadingComprehension && toReadingComprehension(item)}
					{item.displayLessonNumber}
					<ReactTooltip html={true} type="light" data-border={true} className="tooltip-box" />
				</div>
			);
		}
	};

	const getInProgressLevel = (item) => (
		<div
			className="level-container-active d-flex align-items-center justify-content-center level-wrappers position-relative"
			data-tip={getLevelStatus(item)}>
			{item.isHavingFluencyDrill && toFluencyDrills(item)}
			{item.isHavingReadingComprehension && toReadingComprehension(item)}
			<ProgressCircle
				value={[
					{
						value: 33.33,
						color: getCircularValue(item.vocabularyStage.status),
					},
					{
						value: 33.33,
						color: getCircularValue(item.grammarStage.status),
					},
					{
						value: 33.33,
						color: getCircularValue(item.finalActStage.status),
					},
				]}
				strokeGap
				displayValue={item.displayLessonNumber}
			/>
			<ReactTooltip html={true} type="light" data-border={true} className="tooltip-box" />
		</div>
	);

	const renderLevelsComponent = (level) => {
		const value = [
			{
				value: 100 - level.percentCompleted,
				color: "#E6EBEE",
			},
		];
		if (level.percentCompleted !== 0) {
			value.push({
				value: level.percentCompleted,
				color: "#44bbf8",
			});
		}
		return (
			<div
				key={level.level}
				className={`student-level align-items-center ${level.status !== 0 && "level-active"} ${
					levelNumber === level.level && "active-level-container"
				}`}>
				<div className=" d-flex align-items-center justify-content-center usr-img">
					<ProgressCircle
						value={value}
						displayImg={
							level.avatar && level.avatar.filePath
								? level.avatar.filePath
								: "https://biblingo.s3-ap-southeast-2.amazonaws.com/assets/avatar/3.png"
						}
						className="progress-img-container"
					/>
				</div>
				<div className="level-number">{t("languagelearning.lessons.level.levelno", { level: level.level })}</div>
			</div>
		);
	};

	const renderLessonComponent = (lessons, level, index) => {
		return (
			<div
				key={index}
				className={` mb-2 lessons-container  ${level.level === currentLevel ? "expand" : ""}  ${
					level.level === levelNumber ? "" : "in-active-lesson"
				}`}>
				{renderLevelsComponent(level)}
				<div className="lesson-items flex-fill">
					{lessons &&
						lessons.length > 0 &&
						lessons.map((item, index) => (
							<div
								className={`level-container d-flex align-items-center ${
									levelNumber === item.level && "level-wrapper-active"
								} ${levelNumber !== item.level && "lessons-active-container"}`}
								key={index}
								onClick={(e) => {
									e.stopPropagation();
									handleLessonClick(item);
								}}>
								{getLevelContainers(item, lessons[index - 1], level.level)}
							</div>
						))}
				</div>
				{renderDropDownIcon(level)}
			</div>
		);
	};

	const renderDropDownIcon = (level) => (
		<div
			className="dropdown-icon"
			onClick={(e) => {
				expandLevel(e, level.level);
			}}>
			<i className={level.level === currentLevel ? "icon-down down-font" : "icon icon-angle-arrow-down"}></i>
		</div>
	);

	const renderLevelContainer = () => (
		<Card className="m-4">
			{levelDetails.levels && levelDetails.levels.length > 0 && (
				<>
					<Row className="pl-5 pr-5 pb-4 student-wrapper">
						{getUserDetails().role.description === "Student" && (
							<Col>
								<div className="pt-3 d-flex justify-content-end">
									<div
										onClick={() => {
											history.push(`/home/${language}/placement-test`);
										}}
										className="d-flex justify-content-center align-items-center text-right placement-test">
										<i className="icon icon-pages mr-2"></i>
										{t("languagelearning.lessons.takeplacementtest")}
									</div>
								</div>
							</Col>
						)}
						<Col lg={2} md={12} sm={12} className="mobile-level-wrapper p-0">
							{levelDetails.levels.map((level) => renderLevelsComponent(level))}
						</Col>
						<Col lg={12} md={12} sm={12} className="lesson-wrapper p-0">
							{levelDetails.levels.map((level, index) => renderLessonComponent(level.lessons, level, index))}
						</Col>
					</Row>
				</>
			)}
		</Card>
	);

	return (
		<div className="dashboard d-flex">
			<div className="empty">
				<Header menu={menu} history={history} head={t("menu.languagelearning")} enableLanguageSwitch={true} />
				<UserDetailsForm show={showPronunciationModal} handleClose={closePronunciationModal} />
				<div className="student-language-container">{renderLevelContainer()}</div>
				{loading && <Loader />}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	showPronunciationModal: state.studentLanguageLearning.showPronunciationModal,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	getLevelDetails,
	updateField,
	sessionLogging,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageLearning));
