import { LANGUAGE_LEARNING } from "./types";
const initialState = { file: {} };

export default function(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_LEARNING.UPLOAD_FILE:
      return {
        ...state,
        file: action.val
      };
    default:
      return state;
  }
}
