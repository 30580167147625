export const qaFields = [
  {
    label: "Quest ID",
    accessor: "id",
    width: 110
  },
  {
    label: "User",
    accessor: "askedBy"
  },
  {
    label: "Language",
    accessor: "language",
    filter: "select",
    filterField: "description"
  },
  {
    label: "Lesson No",
    accessor: "lessonNumber",
    width: 110
  },
  {
    label: "Question",
    accessor: "title",
    className: "text-truncate"
  },
  {
    label: "Date",
    accessor: "askedOn",
    filter: "date"
  },
  {
    label: "Status",
    accessor: "status",
    filter: "select",
    filterField: "description"
  },
  {
    label: "Action",
    headerClassName: "column-actions actions-header",
    width: 80,
    accessor: "Action",
    className: "column-actions",
    disableSort: true,
    disableFilter: true
  }
];

export const languages = [
  { label: "Hebrew", value: "Hebrew" },
  { label: "Greek", value: "Greek" }
];
