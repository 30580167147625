import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

export const filterList = ["Book", "Chapter", "Verse"];

export const searchFields = [
	{ label: "Word", value: "searchTerm" },
	{ label: "Searched On", value: "searchedOn" },
	{ label: "Searched By", value: "searchParamType" },
];

export const sensesColumns = ["", "Gloss", "Semantic Domain"];
export const subSensesColumns = ["", "Gloss", "Phrases / Compound words"];
export const subDomainsColumns = ["Semantic Domain", ""];

export const searchOptions = [
	{ label: "Greek/Hebrew", value: "Word" },
	{ label: "English", value: "Gloss" },
	{ label: "Semantic Domain", value: "SemanticDomain" },
];

export const senseDataFields = ["senseFormDictionary", "gloss", "semanticDomain"];
export const subSenseFields = ["text", "gloss", "complexFormType"];

export const wordStatuses = {
	0: "NotLearned",
	1: "SenseLearned",
	2: "MultipleSensesLearned",
	3: "AllSensesLearned",
	4: "Archived",
	5: "UnKnown",
};

export const progressCircle = [
	{
		value: 100,
		color: "#e9ecef",
	},
	{
		value: 0,
		color: "#44bbf8",
	},
];

export const grammaticalInfo = [
	{
		label: "Category",
		field: "category",
	},
	{
		label: "Grammatical Forms",
		field: "grammaticalForms",
	},
	{
		label: "Root",
		field: "greekRoot",
	},
	{
		label: "Root",
		field: "hebrewRoot",
	},
	{
		label: "Argument Realizations",
		field: "argumentRealizations",
	},
	{
		label: "Binyan",
		field: "binyan",
	},
	{
		label: "Gender",
		field: "gender",
	},
];

export const lexicalFields = [
	{
		label: "Gloss",
		field: "gloss",
	},
	{
		label: "Definition",
		field: "definition",
	},
	{
		label: "Semantic Domains",
		field: "semanticDomain",
	},
];

export const fields = [
	{
		label: "Con",
		field: "construct",
	},
	{
		label: "Dl",
		field: "dual",
	},
	{
		label: "Pl",
		field: "plural",
	},
	{
		label: "Dl Con",
		field: "dualConstruct",
	},
	{
		label: "Pl Con",
		field: "pluralConstruct",
	},
	{
		label: "Qtl",
		field: "qatal",
	},
	{
		label: "Wqtl",
		field: "weqatal",
	},
	{
		label: "Yqtl",
		field: "yiqtol",
	},
	{
		label: "Wyqtl",
		field: "wayyiqtol",
	},
	{
		label: "Part",
		field: "participle",
	},
	{
		label: "Inf Abs",
		field: "infinitiveAbsolute",
	},
	{
		label: "Inf Con",
		field: "infinitiveConstruct",
	},

	{
		label: "Impv",
		field: "imperative",
	},
	{
		label: "Jsv",
		field: "jussive",
	},
	{
		label: "Coh",
		field: "cohorative",
	},
	{
		label: "Adj Forms",
		field: "adjForms",
	},
	{
		label: "Gen",
		field: "genN",
	},
	{
		label: "PresMP",
		field: "presMP",
	},
	{
		label: "Impv",
		field: "imperfect",
	},
	{
		label: "ImpvMP",
		field: "imperfectMP",
	},
	{
		label: "Aor",
		field: "aorist",
	},
	{
		label: "AorMid",
		field: "aorMid",
	},
	{
		label: "AorPsv",
		field: "aorPasv",
	},
	{
		label: "Fut",
		field: "future",
	},
	{
		label: "FutMid",
		field: "futureMid",
	},
	{
		label: "FutPsv",
		field: "futurePasv",
	},
	{
		label: "Perf",
		field: "perfect",
	},
	{
		label: "PerfMid",
		field: "perfMid",
	},
	{
		label: "Adj Forms",
		field: "nomFNAdj",
	},
];

export const booksTabList = ["Books", "Chapters"];

export const bookSortOptions = [
	{ label: "Ascending", value: "Asc" },
	{ label: "Descending", value: "Desc" },
];

export const legendList = [
	{
		status: "NotLearned",
		label: "I have not begun learning any sense of the word",
	},
	{
		status: "SenseLearned",
		label: "I have begun learning at least one sense of the word",
	},
	{
		status: "MultipleSensesLearned",
		label: "I have begun learning all senses of the word",
	},
	{
		status: "Archived",
		label: "I know (or have archived) all senses of the word",
	},
];

export const shortcutKeysList = [
	{
		title: "l",
		label: "Listen to audio in senses",
	},
	{
		title: "f",
		label: "Flashcard: add word-sense to most recent custom deck",
	},
	{
		title: "a",
		label: "Archive/Unarchive sense",
	},
	{
		title: "v",
		label: "Upvote/Downvote word-sense for given verse",
	},
	{
		title: "shift + f",
		label: "Flashcard: add word-sense to new custom deck",
	},
	{
		title: "shift + a",
		label: "Archive/Unarchive all senses of word",
	},

	{
		title: "Up/down",
		label: "Scroll through senses (up moves left and down moves right)",
	},
	{
		title: "Numbers",
		label: (
			<span>
				Select specific word-sense by its number (e.g., sense <b>2</b>)
			</span>
		),
	},
	{
		title: "Shift+left/right arrow",
		label: "Select the previous/next word in biblical text",
	},
	{
		title: "Left/right arrow ",
		label: "Left/right arrow Select the previous/next word highlighted blue, in the biblical text",
	},
];

export const overlay = (body, title) => {
	return (
		<OverlayTrigger
			trigger="click"
			placement="top"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="info-popup">
					<Popover.Content>{body}</Popover.Content>
				</Popover>
			}>
			<label
				className="field-label m-0 position-absolute"
				style={{ bottom: 20, right: 5, color: "#000", fontSize: 14, cursor: "pointer" }}>
				{title}
			</label>
		</OverlayTrigger>
	);
};
