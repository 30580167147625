import { SUBMENU } from "./types";
const initialState = {
	file: {},
	lessons: [],
	words: [],
	lessonDetails: [],
	wordDetails: [],
	type: "dictionary",
	fileName: "",
	fileLoader: true,
	wordCount: 0,
	grammarDetails: [],
	lessonNumber: 1,
	lessonCount: 0,
	isDataFetching: false,
	searchText: "",
	isSearchDisable: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SUBMENU.UPLOAD_FILE:
			return {
				...state,
				fileName: action.payload.response && action.payload.response.fileName,
				fileLoader: false,
			};
		case SUBMENU.GET_ALL_LESSONS:
			return {
				...state,
				lessons: action.payload.response.lessons,
				fileName: action.payload.response.fileName,
				fileLoader: false,
			};
		case SUBMENU.SET_SEARCH_TEXT:
			return {
				...state,
				searchText: action.payload,
			};
		case SUBMENU.SET_IS_SEARCH_ENABLE:
			return {
				...state,
				isSearchDisable: action.payload,
			};
		case SUBMENU.GET_ALL_GRAMMAR:
			return {
				...state,
				lessons: action.payload.response.lessons,
				fileName: action.payload.response.fileName,
				fileLoader: false,
			};
		case SUBMENU.GET_LESSON_DETAILS:
			return {
				...state,
				lessonDetails: action.payload.response.records,
			};
		case SUBMENU.GET_ALL_WORDS:
			return {
				...state,
				words: action.payload.response.words.records,
				fileName: action.payload.response.fileName,
				wordCount: action.payload.response.words.count,
				fileLoader: false,
			};
		case SUBMENU.GET_GRAMMAR_DETAILS:
			return {
				...state,
				grammarDetails: action.payload.response.records,
				lessonCount: action.payload.response.count,
			};

		case SUBMENU.SET_LESSON_NUMBER:
			return {
				...state,
				lessonNumber: action.payload,
			};
		case SUBMENU.CHANGE_TYPE:
			return {
				...state,
				type: action.payload,
			};
		case SUBMENU.GET_WORD_DETAILS:
			return {
				...state,
				wordDetails: action.payload.response.records,
				wordCount: action.payload.response.count,
			};
		case SUBMENU.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val,
			};
		case SUBMENU.CLEAR_DATA:
			return {
				...state,
				lessonDetails: [],
				wordDetails: [],
				searchText: "",
				grammarDetails: [],
			};

		default:
			return state;
	}
};

export default reducer;
