import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfoDescription from "../../../sharedComponents/infoDescription";
import "./generateDeck.scss";

const GenerateDeck = ({
	generateDeck,
	updateGenerateDeck,
	clearFormTab,
	saveGenerateDeck,
	books,
	getChapters,
	getSemanticDomainEntries,
	setLoading,
	match,
}) => {
	const {
		language,
		groupName,
		deckName,
		sensesToInclude,
		startFromBookID,
		finishUpToBookID,
		fromChapter,
		toChapter,
		senseIDs,
		wordsPerDeck,
		frequency,
		bookChaptersFrom,
		bookChaptersTo,
		selectionType,
		senses,
	} = generateDeck;
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState(null);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	useEffect(() => {
		const checkFields = ["deckName", "groupName", "wordsPerDeck"];
		if (generateDeck.selectionType === "Bible Chapters") {
			const selectionFields = ["finishUpToBookID", "fromChapter", "startFromBookID", "toChapter"];
			if (selectionFields.every((field) => generateDeck[field]) && checkFields.every((field) => generateDeck[field])) {
				setButtonDisabled(false);
			} else {
				setButtonDisabled(true);
			}
		} else {
			if (senses.filter((sense) => sense.checked).length && checkFields.every((field) => generateDeck[field])) {
				setButtonDisabled(false);
			} else {
				setButtonDisabled(true);
			}
		}
	}, [generateDeck]);

	let langParam = match.params.language === "Hebrew" ? t("header.hebrew") : t("header.greek");
	const semanticHeaders = [
		{ name: `${langParam}`, value: "text" },
		{ name: t("helpers.utils.gloss"), value: "gloss" },
		{ name: t("helpers.utils.semanticdomain"), value: "semanticDomain" },
	];

	const updateField = async (field, value) => {
		updateGenerateDeck(field, value);
	};

	const getSenses = async () => {
		setLoading(true);
		if (searchTerm && searchTerm.trim().length) {
			const { response: senses } = await getSemanticDomainEntries(language, sensesToInclude, searchTerm.trim());
			senses.map((sense) => {
				sense.checked = false;
				return sense;
			});
			updateField("senses", senses);
			setLoading(false);
		}
	};

	const getSensesAdd = async () => {
		setLoading(true);
		if (searchTerm && searchTerm.trim().length) {
			const { response: newSenses } = await getSemanticDomainEntries(language, sensesToInclude, searchTerm.trim());
			updateField("senses", [...senses, ...newSenses]);
			setLoading(false);
		}
	};

	const handleSemanticSenses = async (checked, el, idx) => {
		senses[idx]["checked"] = checked;
		updateField("senses", senses);
	};

	const toggleBulkUpdate = (checked) => {
		if (checked) {
			bulkAdd();
		} else {
			bulkRemove();
		}
	};

	const bulkAdd = async () => {
		senses.map((sense) => {
			sense.checked = true;
			return sense;
		});
		updateField("senses", senses);
	};

	const bulkRemove = async () => {
		senses.map((sense) => {
			sense.checked = false;
			return sense;
		});
		updateField("senses", senses);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let payload = {
			language: language,
			groupName: groupName,
			deckName: deckName,
			sensesToInclude: sensesToInclude,
			wordsPerDeck: wordsPerDeck,
			frequency: frequency,
		};
		if (selectionType === "Bible Chapters") {
			payload = {
				...payload,
				startFromBookID: startFromBookID,
				finishUpToBookID: finishUpToBookID,
				fromChapter: fromChapter,
				toChapter: toChapter,
			};
		} else {
			payload = {
				...payload,
				senseIDs: senses
					.filter((sense) => sense.checked)
					.map((sense) => sense.senseID)
					.join(","),
			};
		}
		saveGenerateDeck(payload, selectionType);
	};

	return (
		<div className="generate-settings settings-tab">
			<Form>
				<Form.Row>
					<Col>
						<Form.Group controlId="groupName">
							<Form.Label>{t("flashcarddeck.flashcardsettings.groupname")}</Form.Label>
							<Form.Control
								type="text"
								placeholder={t("flashcarddeck.flashcardsettings.groupname")}
								value={groupName}
								onChange={(e) => updateField("groupName", e.target.value)}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="deckName">
							<Form.Label>{t("flashcarddeck.flashcardsettings.deckname")}</Form.Label>
							<Form.Control
								type="text"
								placeholder={t("flashcarddeck.flashcardsettings.deckname")}
								value={deckName}
								onChange={(e) => updateField("deckName", e.target.value)}
							/>
						</Form.Group>
					</Col>
				</Form.Row>
				<Form.Row>
					<Col>
						<Form.Group controlId="sensesToInclude">
							<Form.Label>{t("flashcarddeck.flashcardsettings.generatedecktab.sensestoinclude.name")}</Form.Label>
							<Form.Control
								as="select"
								onChange={(e) => updateField("sensesToInclude", parseInt(e.target.value))}
								value={sensesToInclude}>
								<option value={0}>
									{t("flashcarddeck.flashcardsettings.generatedecktab.sensestoinclude.options.firstsense")}
								</option>
								<option value={1}>
									{t("flashcarddeck.flashcardsettings.generatedecktab.sensestoinclude.options.firstunknown")}
								</option>
								<option value={2}>
									{t("flashcarddeck.flashcardsettings.generatedecktab.sensestoinclude.options.default")}
								</option>
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="selectionType">
							<Form.Label>{t("flashcarddeck.flashcardsettings.generatedecktab.selectrangefrom.name")}</Form.Label>
							<Form.Control
								as="select"
								value={selectionType}
								onChange={(e) => updateField("selectionType", e.target.value)}>
								<option value={"Bible Chapters"}>
									{t("flashcarddeck.flashcardsettings.generatedecktab.selectrangefrom.options.default")}
								</option>
								<option value={"Semantic Domain"}>{t("helpers.utils.semanticdomain")}</option>
							</Form.Control>
						</Form.Group>
					</Col>
				</Form.Row>
				{selectionType === "Bible Chapters" && (
					<div>
						<span>{t("flashcarddeck.flashcardsettings.generatedecktab.selectrange.name")}</span>
						<Form.Row>
							<Col>
								<Form.Group controlId="startFromBookID">
									<Form.Label>
										{t("flashcarddeck.flashcardsettings.generatedecktab.selectrange.sub")}
									</Form.Label>
									<Form.Control
										as="select"
										value={startFromBookID}
										onChange={(e) => {
											updateField("startFromBookID", parseInt(e.target.value));
										}}>
										<option></option>
										{books &&
											books.length &&
											books.map((book, index) => (
												<option key={index} value={book.id}>
													{book.fullName}
												</option>
											))}
									</Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="fromChapter">
									<Form.Label>
										{t("flashcarddeck.flashcardsettings.generatedecktab.selectchaptersfrom.name")}
									</Form.Label>
									<Form.Control
										as="select"
										value={fromChapter}
										onChange={(e) => updateField("fromChapter", parseInt(e.target.value))}>
										{bookChaptersFrom &&
											bookChaptersFrom.length &&
											bookChaptersFrom.map((chapter, index) => <option key={index}>{chapter}</option>)}
									</Form.Control>
								</Form.Group>
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Group controlId="finishUpToBookID">
									<Form.Label>{t("flashcarddeck.flashcardsettings.generatedecktab.selectbookto")}</Form.Label>
									<Form.Control
										as="select"
										value={finishUpToBookID}
										onChange={(e) => {
											updateField("finishUpToBookID", parseInt(e.target.value));
										}}>
										<option></option>
										{books &&
											books.length &&
											books.map((book, index) => (
												<option key={index} value={book.id}>
													{book.fullName}
												</option>
											))}
									</Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="toChapter">
									<Form.Label>
										{t("flashcarddeck.flashcardsettings.generatedecktab.selectchapterto")}
									</Form.Label>
									<Form.Control
										as="select"
										value={toChapter}
										onChange={(e) => updateField("toChapter", parseInt(e.target.value))}>
										{bookChaptersTo &&
											bookChaptersTo.length &&
											bookChaptersTo.map((chapter, index) => <option key={index}>{chapter}</option>)}
									</Form.Control>
								</Form.Group>
							</Col>
						</Form.Row>
					</div>
				)}
				{selectionType === "Semantic Domain" && (
					<div>
						<Form.Row>
							<Form.Group controlId="searchTerm">
								<Form.Label>{t("biblereading.search.searchfor")}</Form.Label>
								<div className="d-flex">
									<Form.Control
										className="mr-4 w-100"
										type="text"
										placeholder=""
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
									/>
									<Button
										className="mr-4 w-100"
										variant="primary"
										type="search"
										onClick={(e) => {
											e.preventDefault();
											getSenses();
										}}>
										{t("biblereading.search.name")}
									</Button>
									<Button
										className="mr-4 w-100"
										variant="secondary"
										type="search&add"
										onClick={(e) => {
											e.preventDefault();
											getSensesAdd();
										}}>
										{t("flashcarddeck.flashcardsettings.generatedecktab.search&add")}
									</Button>
								</div>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Card className="no-shadow" style={{ height: "100%", width: "100%" }}>
								<div className="table-wrapper">
									<Table className="senses-table-container mb-4">
										<thead>
											<tr className="spacer"></tr>
											<tr>
												<th className="ml-4" style={{ width: "40px" }}>
													{senses && senses.length > 0 && (
														<Form.Group>
															<Form.Check
																as={"input"}
																type={"checkbox"}
																onChange={(e) => {
																	toggleBulkUpdate(e.target.checked);
																}}
																checked={
																	senses.every((sense) => sense.checked) ? true : false
																}></Form.Check>
														</Form.Group>
													)}
												</th>
												{semanticHeaders.map((el, idx) => (
													<th className={"text-center"} key={idx}>
														{el.name}
													</th>
												))}
											</tr>
										</thead>
										{senses && senses.length > 0 ? (
											<tbody>
												{senses.map((el, idx) => (
													<React.Fragment key={idx}>
														<tr className="spacer"></tr>
														<tr className="column-table">
															<td>
																<Form.Group>
																	<Form.Check
																		as={"input"}
																		type={"checkbox"}
																		onChange={(e) => {
																			handleSemanticSenses(e.target.checked, el, idx);
																		}}
																		checked={el.checked ? true : undefined}></Form.Check>
																</Form.Group>
															</td>
															{semanticHeaders.map((item, idx) => (
																<td key={idx} className={"text-center"}>
																	{el[item.value]}
																</td>
															))}
														</tr>
														<tr className="spacer"></tr>
													</React.Fragment>
												))}
											</tbody>
										) : (
											<tbody>
												<tr className="spacer"></tr>
												<tr style={{ margin: "auto 0" }}>
													<td colSpan={4} style={{ textAlign: "center" }}>
														{t("helpers.utils.nodata")}
													</td>
												</tr>
											</tbody>
										)}
									</Table>
								</div>
							</Card>
						</Form.Row>
					</div>
				)}
				<div>
					<span>{t("flashcarddeck.flashcardsettings.generatedecktab.choosefrequency.name")}</span>
					<Form.Row>
						<Col>
							<Form.Group controlId="frequency">
								<Form.Label>
									{t("flashcarddeck.flashcardsettings.generatedecktab.choosefrequency.sub")}
								</Form.Label>
								<Form.Control
									type="number"
									placeholder=""
									value={frequency}
									onChange={(e) => updateField("frequency", parseInt(e.target.value))}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="wordsPerDeck">
								<Form.Label style={{ marginBottom: "0.5px" }}>
									{t("flashcarddeck.flashcardsettings.generatedecktab.numberofwordsperdeck.name")}
									<InfoDescription
										position={"left-top"}
										description={t(
											"flashcarddeck.flashcardsettings.generatedecktab.numberofwordsperdeck.infobtn"
										)}
									/>
								</Form.Label>
								<Form.Control
									type="number"
									placeholder=""
									value={wordsPerDeck}
									onChange={(e) => {
										if (e.target.value <= 25) updateField("wordsPerDeck", parseInt(e.target.value));
									}}
								/>
							</Form.Group>
						</Col>
					</Form.Row>
				</div>
				<Form.Row className="flex-row-reverse">
					<Button variant="primary" type="submit" disabled={buttonDisabled} onClick={(e) => handleSubmit(e)}>
						{t("flashcarddeck.flashcardsettings.generatedecktab.generate")}
					</Button>
					<Button className="mr-2" variant="secondary" type="cancel" onClick={(e) => clearFormTab("generateDeck", e)}>
						{t("helpers.utils.cancel")}
					</Button>
				</Form.Row>
			</Form>
		</div>
	);
};

export default GenerateDeck;
