export const USERS = {
  FETCH_USERS: "FETCH_USERS",
  FETCH_COUNTRY_LIST: "FETCH_COUNTRY_LIST",
  FETCH_USER_ROLES: "FETCH_USER_ROLES",
  FETCH_LEARNING_REASONS: "FETCH_LEARNING_REASONS",
  FETCH_LANGUAGE_PREFERENCE: "FETCH_LANGUAGE_PREFERENCE",
  CREATE_USER: "CREATE_USER",
  FETCH_GREEK_PRONOUNCATION: "FETCH_GREEK_PRONOUNCATION",
  UPDATE_FIELD: "UPDATE_FIELD",
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
  DELETE_USER: "DELETE_USER",
  CHANGE_STATUS: "CHANGE_STATUS",
  FETCH_HEBREW_PRONOUNCATION: "FETCH_HEBREW_PRONOUNCATION",
  RESUME_LESSON_PROGRESS: "RESUME_LESSON_PROGRESS",
  GET_ROLE: "GET_ROLE",
  FETCH_LESSONS: "FETCH_LESSONS",
  GET_CLASSROOM_DATA: "GET_CLASSROOM_DATA",
  GET_CLASSROOM_CODE: "GET_CLASSROOM_CODE",
  CREATE_CLASSROOM: "CREATE_CLASSROOM",
  UPDATE_CLASSROOM: "UPDATE_CLASSROOM",
  DELETE_CLASSROOM: "DELETE_CLASSROOM",
  FETCH_CULTURES:"FETCH_CULTURES"
};
