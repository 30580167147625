import React, { useState } from "react";
import { connect } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { withRouter, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { getUserDetails } from "../utils/verifyUser";
import SharedModal from "./sharedComponents/Modal";
import { updateField } from "./Students/LanguageLearning/actions";
import { endSessionLogging, sessionLogging } from "./Sidemenu/actions";
import { useTranslation } from "react-i18next";

const Header = ({
	menu,
	match,
	isStudentMode,
	head,
	history,
	updateField,
	enableLanguageSwitch,
	redirectionUrl,
	points,
	endSessionLogging,
	sessionLogging,
}) => {
	const { t } = useTranslation();
	const moduleList = {
		0: t("dashboard.filtervalues.languagelearning"),
		1: t("flashcarddeck.presetdeck.name"),
		1: t("biblereading.sense.customdeck"),
		2: t("flashcarddeck.wordsthatneedpractice.name"),
		3: t("dashboard.filtervalues.biblereading"),
		4: t("menu.fluencydrill"),
	};
	const [value, setValue] = useState(match && match.params ? match.params.language : "Greek");
	const [selectLanguage, setSelectLanguage] = useState(match && match.params ? match.params.language : "Greek");
	const [show, showModal] = useState(false);
	const [showInActive, setShowInactive] = useState(false);
	const userDetails = getUserDetails();
	const language = localStorage.getItem("i18nextLng");

	const handleLogout = async () => {
		const sessionId = localStorage.getItem("sessionId");
		await endSessionLogging({
			language: match.params.language === "Hebrew" ? 0 : 1,
			sessionId: sessionId ? sessionId : null,
			module: 6,
		});

		localStorage.removeItem("sessionId");
		// localStorage.removeItem("i18nextLng");
		localStorage.removeItem("auth");
		localStorage.removeItem("settings");
		if (userDetails.origin === "Biblingo") history.push("/login");
		else if (userDetails.origin === "CampusEdu") window.location.href = "https://learn.stg.campusedu.tech/";
		else if (userDetails.origin === "Fuller") window.location.href = "https://www.fuller.edu/";
	};

	const handleLanguageChange = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setSelectLanguage(e.target.value);
		showModal(true);
	};

	const renderModalBody = () => {
		return <div className="text-center">{t("header.languagemodal.languagechangeconfirm")}</div>;
	};

	const handleChange = () => {
		showModal(false);
		handleSessionLog(head);
		history.push(
			`/home/${selectLanguage}/${
				redirectionUrl
					? `${redirectionUrl}${match.params.id ? `/${match.params.id}` : ""}${
							history.location.search.length > 0 ? history.location.search : ""
					  }`
					: "Language-Learning"
			}`
		);
	};

	const handleClose = () => {
		showModal(false);
	};

	const renderModalFooter = () => {
		return (
			<div className="mb-2 change-modal-footer">
				<button className="btn btn-close" onClick={() => handleClose()}>
					{t("helpers.utils.no")}
				</button>
				<button className="btn btn-success mr-3" onClick={() => handleChange()}>
					{t("helpers.utils.yes")}
				</button>
			</div>
		);
	};

	const handleSessionLog = (title) => {
		const sessionId = localStorage.getItem("sessionId");
		sessionLogging({
			language: match.params.language === "Hebrew" ? 0 : 1,
			sessionId: sessionId ? sessionId : null,
			module: moduleList[title],
		});
	};

	const renderLanguageComponent = () => {
		return (
			getUserDetails().languages.length > 1 &&
			getUserDetails().origin === "Biblingo" &&
			(head === t("dashboard.filtervalues.languagelearning") || enableLanguageSwitch) && (
				<React.Fragment>
					<p className="language-p">{t("header.language")}</p>
					{language === "id" ? (
						<div className="btn-group btn-group-toggle language-toggle1" data-toggle="buttons">
							<label className={`${value === "Greek" && "active"} btn btn-language-toggle1`}>
								<input
									type="radio"
									name="options"
									value="Greek"
									onChange={handleLanguageChange}
									checked={value === "Greek"}
								/>

								{t("header.greek")}
							</label>
							<label
								className={`${
									value === "Hebrew" && "active"
								} btn btn-language-toggle1 position-relative header-toggle`}>
								<input
									type="radio"
									name="options"
									value="Hebrew"
									onChange={handleLanguageChange}
									checked={value === "Hebrew"}
								/>

								{t("header.hebrew")}
							</label>
						</div>
					) : (
						<div className="btn-group btn-group-toggle language-toggle" data-toggle="buttons">
							<label className={`${value === "Greek" && "active"} btn btn-language-toggle`}>
								<input
									type="radio"
									name="options"
									value="Greek"
									onChange={handleLanguageChange}
									checked={value === "Greek"}
								/>

								{t("header.greek")}
							</label>
							<label
								className={`${
									value === "Hebrew" && "active"
								} btn btn-language-toggle position-relative header-toggle`}>
								<input
									type="radio"
									name="options"
									value="Hebrew"
									onChange={handleLanguageChange}
									checked={value === "Hebrew"}
								/>

								{t("header.hebrew")}
							</label>
						</div>
					)}

					<SharedModal
						show={show}
						modalBody={renderModalBody()}
						modalFooter={renderModalFooter()}
						handleClose={() => showModal(false)}
						modalClass="no-header header-student"
					/>
				</React.Fragment>
			)
		);
	};

	const renderUserDetails = () => (
		<div className="d-flex flex-column dtl-section">
			<p className="d-flex align-items-center avatar-fullname">
				{userDetails.firstName} {userDetails.lastName}
			</p>
			{getUserDetails().role.description !== "Student" && (
				<Dropdown className="mode-switch" as="div">
					<Dropdown.Toggle as="div" className="d-flex align-items-center justify-content-end current-mode">
						<p>{isStudentMode ? "Student" : getUserDetails().role.description}</p>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={changeMode}>
							{isStudentMode ? getUserDetails().role.description : "Student"}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)}
		</div>
	);

	const changeMode = () => {
		updateField("isStudentMode", !isStudentMode);
		const studentUrl =
			getUserDetails().languages.length > 1
				? "/home"
				: `/Home/${getUserDetails().languages[0] === 0 ? "Hebrew" : "Greek"}/Language-Learning`;
		history.push(isStudentMode ? "/Home/Greek/Vocabulary" : studentUrl);
	};

	const resumeSession = async (event) => {
		const sessionId = localStorage.getItem("sessionId");
		sessionLogging({
			language: match.params.language === "Hebrew" ? 0 : 1,
			sessionId: sessionId ? sessionId : null,
			module: 6,
		});
		setShowInactive(false);
	};

	const handleOnIdle = async (event) => {
		const sessionId = localStorage.getItem("sessionId");
		await endSessionLogging({
			language: match.params.language === "Hebrew" ? 0 : 1,
			sessionId: sessionId ? sessionId : null,
			module: 6,
		});

		setShowInactive(true);
	};

	const idleTimer = useIdleTimer({
		timeout: 600000,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	const renderInactiveModalBody = () => {
		return (
			<div className="text-center">
				<span>{t("header.languagemodal.inactivemsg")}</span>
			</div>
		);
	};
	const renderInactiveModalFooter = () => {
		return (
			<div className="mb-2 change-modal-footer ">
				<button className="btn btn-close mr-3" onClick={() => handleLogout()}>
					{t("header.logout")}
				</button>
				<button className="btn btn-success mr-3" onClick={() => setShowInactive(false)}>
					{t("header.languagemodal.resumelearning")}
				</button>
			</div>
		);
	};
	return (
		<>
			<div className="row p-3 pt-2 mt-1 mr-0 header-container">
				<div className="col-md-5 col-sm-12 pl-4">
					<h1 className="header-top">{head ? head : menu[menu] ? menu[2].title : ""}</h1>
					<ul className="panel">
						<li className="navpanel-list d-flex">
							{menu.map((item, index) => (
								<React.Fragment key={item.title}>
									{menu.length - 1 === index ? (
										<p>
											{item.title} <span> &#187;</span>
										</p>
									) : (
										<p>
											<Link to={item.path} style={{ textDecoration: "none" }}>
												{item.title}
											</Link>
											<span> &#187;</span>
										</p>
									)}
								</React.Fragment>
							))}
						</li>
					</ul>
				</div>
				<div className="col-md-7 col-sm-12  justify-content-end align-items-center user-title">
					{(points || points === 0) && (
						<div className="d-flex justify-content-center align-items-center bible-points">
							<div className="star-5 pointer">
								<i className="icon-star-5"></i>
							</div>
							<div className="d-flex justify-content-center align-items-center bible-points-direction">
								<span className="bible-points-text">{t("header.readingpoints")}</span>
								<span className="points-color">{points}</span>
							</div>
						</div>
					)}
					{renderLanguageComponent()}
					{renderUserDetails()}
					<Dropdown className="users-section ml-2 user-name">
						<Dropdown.Toggle>
							<p>
								{userDetails.firstName ? userDetails.firstName.charAt(0) : ""}
								{userDetails.lastName ? userDetails.lastName.charAt(0) : ""}
							</p>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={() => history.push("/Profile")}>
								<i className="icon icon-user-2" /> {t("header.myprofile.name")}
							</Dropdown.Item>
							{match.params.language && userDetails.role.description !== "Administrator" && (
								<Dropdown.Item onClick={() => history.push(`/Home/${match.params.language}/Dashboard`)}>
									<i className="icon icon-speedometer-4" /> {t("header.mydashboard")}
								</Dropdown.Item>
							)}
							{getUserDetails().origin === "Biblingo" && (
								<Dropdown.Item onClick={() => history.push("/Change-Password")}>
									<i className="icon icon-key" /> {t("header.changepassword.name")}
								</Dropdown.Item>
							)}
							{userDetails.role.description !== "Student" && (
								<Dropdown.Item onClick={() => history.push("/sync")}>
									<i className="icon icon-sync" /> Sync Setting
								</Dropdown.Item>
							)}
							<Dropdown.Item onClick={handleLogout}>
								<i className="icon icon-logout" /> {t("header.logout")}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="border-header"></div>
			</div>
			<SharedModal
				show={showInActive}
				modalBody={renderInactiveModalBody()}
				modalFooter={renderInactiveModalFooter()}
				modalClass="no-header inactive-modal"
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
});

const mapDispatchToProps = {
	updateField,
	endSessionLogging,
	sessionLogging,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
