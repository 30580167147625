import React from "react";
import File from "../../assets/images/img-file.png";

export const NoContent = ({ bgImage, content }) => {
  return (
    <div className="content">
      <div className="file-image d-flex justify-content-center flex-column">
        <h2 className="mb-4">{content ? content : "No File Added"}</h2>
        <img src={bgImage ? bgImage : File} alt="file" />
      </div>
    </div>
  );
};
