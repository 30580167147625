import { STUDENT_QA } from "./types";
const initialState = {
  questionList: [],
  answersList: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case STUDENT_QA.GET_ALL_QUESTIONS:
      return {
        ...state,
        questionList:
          action.payload &&
          action.payload.response &&
          action.payload.response.records
      };
    case STUDENT_QA.GET_ALL_ANSWERS:
      return {
        ...state,
        answersList:
          action.payload &&
          action.payload.response &&
          action.payload.response.records
      };

    default:
      return state;
  }
}
