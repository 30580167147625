import { LANGUAGE_LEARNING } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export const uploadFile = creds => {
  const fetchOptions = {
    url: "upload",
    method: "POST",
    actionType: LANGUAGE_LEARNING.UPLOAD_FILE,
    body: JSON.stringify(creds),
    secure: false
  };

  return fetchHandler(fetchOptions);
};
