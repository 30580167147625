import { ALPHABET_LESSONS } from "./types";

const alphabetLessons = (state = {}, action) => {
  switch (action.type) {
    case ALPHABET_LESSONS.GET_TUTORIALS:
      return { ...state, tutorials: action.payload.response };
    default:
      return state;
  }
};

export default alphabetLessons;
