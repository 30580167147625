import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const RoundChartComponent = ({ data = [], chartColor = [] }) => {
	data.push({ name: "remaining", value: 100 - data[0].value });

	return (
		<ResponsiveContainer height={225}>
			<PieChart>
				<Pie data={data} innerRadius={70} outerRadius={80} fill="#8884d8" dataKey="value">
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={chartColor[index % chartColor.length]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
};

export default RoundChartComponent;
