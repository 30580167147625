import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Tabs, Tab, Dropdown } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { NotificationManager } from "react-notifications";
import { DictionaryFields} from "./helper";
import PlaceHolderImg from "../../assets/images/placeholder-image.png";
import ImageSlider from "../sharedComponents/ImageSlider";
import { useTranslation } from "react-i18next";

const Dictionary = ({ wordDetails, menu, language }) => {
	const [wordId, setWordId] = useState("");
	const [tabArray, setTabArray] = useState([]);
	const [audio, setAudio] = useState("");
	const [activeTab, setActiveTab] = useState(0);
	const [show, showModal] = useState(false);
	const [senseImages, setSenseImages] = useState([]);
	const {t} = useTranslation();

	const senseFields = [
		{
		  field: t("helpers.utils.gloss"),
		  value: "gloss"
		},
		{
		  field: t("helpers.utils.grammaticalinfo"),
		  value: "grammaticalInfo"
		},
		{
		  field: t("helpers.lexicalfields.semanticdomains"),
		  value: "semanticDomain"
		},
		{
		  field: "Encyclopedic info",
		  value: "encyclopediaInfo"
		},
		{
		  field: "Sense lesson",
		  value: "lessonNumber"
		},
		{
		  field: "Form",
		  value: "word"
		},
		{
		  field: "Picture",
		  value: "morpgologyTag"
		},
		{
		  field: "Binyan",
		  value: "binyan",
		  language: "Hebrew"
		},
		{
		  field: "Language Learning definition",
		  value: "languageLearningDefinition"
		},
		{
		  field: "Reversal entries",
		  value: "reversals"
		}
	  ];

	const handleDropItem = (word) => {
		setWordId(wordId === word.id ? "" : word.id);
		setActiveTab(0);
	};

	useEffect(() => {
		wordDetails.forEach((word) => {
			if (word.id === wordId) {
				setTabArray(word.senses);
			}
		});
	}, [wordDetails, wordId]);

	const getTabTitle = (i) => {
		return `Sense ${i + 1}`;
	};

	const handleToggle = (e) => {
		setAudio("");
	};

	const stopAllAudio = () => {
		const sounds = document.getElementsByTagName("audio");
		for (let i = 0; i < sounds.length; i++) {
			sounds[i].pause();
		}
	};

	const togglePlay = (pronunciation) => {
		if (pronunciation.filePath) {
			const audioData = pronunciation.fileName;
			const player = document.getElementById(audioData);
			if (player.paused) {
				stopAllAudio();
				setAudio(audioData);
				player.play();
			} else {
				player.pause();
				setAudio("");
				stopAllAudio();
			}
		} else {
			!(NotificationManager.listNotify && NotificationManager.listNotify.length > 0) &&
				NotificationManager.error(t("helpers.utils.notification.audionotavailablemsg"), "", 3000);
		}
	};

	const handlePrevTab = (word, id) => {
		document.getElementById(id).scrollLeft -= 100;
		setActiveTab((prevActiveTab) => {
			return prevActiveTab === 0 ? prevActiveTab : prevActiveTab - 1;
		});
	};

	const handleNextTab = (word, id) => {
		document.getElementById(id).scrollLeft += 100;
		setActiveTab((prevActiveTab) => {
			return prevActiveTab === word.senses.length - 1 ? prevActiveTab : prevActiveTab + 1;
		});
	};

	const getSensevalues = (value, fields) => {
		return fields.field === "Reversal entries" ? value.join(", ") : value;
	};

	const renderTabComponent = (senses) => {
		return senseFields.map(
			(fields, index) =>
				(!fields.language || fields.language === language) && (
					<Col lg={4} md={6} className="mb-4 pr-1 pl-2 flex-direction-column" key={index}>
						<div className="dict-head ml-3">{fields.field}</div>
						<div className="dict-detail ml-3">
							{senses[fields.value] ? getSensevalues(senses[fields.value], fields) : "--"}
						</div>
					</Col>
				)
		);
	};

	const renderSensesComponent = (example, idx) => {
		return (
			<Row key={idx}>
				<Col md={12} className="mb-4 pr-1 pl-2 flex-direction-column">
					<div className="dict-head ml-3">Example {idx + 1}</div>
					<div className={`dict-detail ml-3 ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
						{example.reference}
					</div>
				</Col>
			</Row>
		);
	};

	const renderVoiceComponent = (pronunciations) => {
		return (
			<Dropdown drop="right" onToggle={handleToggle}>
				<Dropdown.Toggle className="audio-dropdown position-relative audio-align">
					<div className="position-absolute audio-count">{pronunciations.length}</div>
					<i className="icon-audio" />
				</Dropdown.Toggle>
				<Dropdown.Menu className="list-audio">
					<ul className="pl-1">
						{pronunciations.map((pronunciation, index) => (
							<li key={index}>
								<div className="change-icon  d-flex">
									<span className="play-icon ">
										<i
											className={
												audio === pronunciation.fileName
													? "icon-pause-button pause-font"
													: "icon-Group-15"
											}
											onClick={() => togglePlay(pronunciation)}></i>
									</span>
									<span className="ml-2 audio-name">{pronunciation.fileName}</span>
									<audio
										src={pronunciation.filePath}
										id={pronunciation.fileName}
										className="d-none"
										onEnded={() => setAudio("")}
									/>
								</div>
							</li>
						))}
					</ul>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const renderDictionaryComponent = (word) => {
		return (
			<Row>
				{DictionaryFields.map(
					(item, index) =>
						(!item.language || item.language === language) && (
							<Col md={4} className="mb-3 pr-1 pl-2 flex-direction-column" key={index}>
								<div className="dict-head">
									<Col className="pr-1 pl-0 dict-head">{item.field}</Col>
								</div>
								<div className="dict-head">
									<Col className="mb-2 dict-detail pl-0">{renderFieldsComponent(word, item)}</Col>
								</div>
							</Col>
						)
				)}
			</Row>
		);
	};

	const renderFieldsComponent = (word, item) => {
		let value = "--";
		switch (item.field) {
			case "Components":
				value = word.components.length > 0 ? word.components[0] : value;
				return value;
			default:
				value = word[item.value] ? word[item.value] : value;
				return value;
		}
	};

	const getImageName = (sense) => {
		return sense.illustrations && sense.illustrations.length > 0 ? sense.illustrations[0].fileName : "--";
	};

	const getImageFileName = (sense) => {
		return sense.illustrations && sense.illustrations.length > 0 ? sense.illustrations[0].filePath : "--";
	};
	const renderImageComponent = (sense) => {
		return (
			<React.Fragment>
				<img
					src={
						sense.illustrations && sense.illustrations.length > 0 && sense.illustrations[0].filePath
							? sense.illustrations[0].filePath
							: PlaceHolderImg
					}
					alt="NoImage"
					className="h-100 w-100"
					onError={() => `this.src=${PlaceHolderImg};`}
				/>
				{sense.illustrations.length > 0 && (
					<div className="img-count" onClick={() => handleImageCount(sense)}>
						{sense.illustrations.length}
					</div>
				)}
			</React.Fragment>
		);
	};

	const handleImageCount = (sense) => {
		setSenseImages(sense.illustrations);
		handleClose();
	};

	const handleClose = () => {
		showModal(!show);
	};

	const handleSelect = (item) => {
		setActiveTab(parseInt(item));
	};

	const renderTabsViewComponent = (word) => {
		return (
			tabArray &&
			tabArray.length > 0 && (
				<div className="d-flex">
					<div className="slide-tab" onClick={() => handlePrevTab(word, `container-${word.id}`)}>
						<i className="icon-prev" disabled={tabArray.length === 0}></i>
					</div>
					<div className="custom-tab-container" id={`container-${word.id}`}>
						<Tabs className="sense-tabs" activeKey={activeTab} onSelect={handleSelect}>
							{tabArray &&
								tabArray.map((senses, i) => (
									<Tab eventKey={i} title={getTabTitle(i)} key={i}>
										<Row className="w-100">
											<div className="d-flex sense-tab-mobile">
												<div className="flex-direction-column sense-img text-center">
													<div className="dictionary-img position-relative">
														{renderImageComponent(senses)}
													</div>
													<div className="mb-2">
														<label data-tip={getImageFileName(senses)}>
															{getImageName(senses)}
														</label>
														<ReactTooltip />
													</div>
													{senses.pronunciations && senses.pronunciations.length > 0 && (
														<div>{renderVoiceComponent(senses.pronunciations)}</div>
													)}
												</div>
												<div>
													<Col md={12}>
														<Row>{renderTabComponent(senses)}</Row>
														{senses.examples &&
															senses.examples.map((example, idx) =>
																renderSensesComponent(example, idx)
															)}
													</Col>
												</div>
											</div>
										</Row>
									</Tab>
								))}
						</Tabs>
					</div>
					<div className="slide-tab" onClick={() => handleNextTab(word, `container-${word.id}`)}>
						<i className="icon-next" disabled={tabArray.length < 5}></i>
					</div>
				</div>
			)
		);
	};

	return (
		<React.Fragment>
			{wordDetails &&
				wordDetails.map((word, index) => (
					<Card className="card-lesson" key={index}>
						<Card.Body>
							<i className="arrow down"></i>
							<Row>
								<Col md={12} className="p-0">
									<div className="d-flex justify-content-between">
										<div
											className={`${
												word.id === wordId
													? "w-100 card-border-btm pb-2 mr-3 ml-3 position-relative"
													: "mr-3 ml-3 w-100 position-relative"
											}`}>
											<Card.Title
												className={`dictionary-title ${
													language === "Hebrew" ? "Hebrew-font" : "Greek-font"
												}`}>
												{word.text ? word.text : "--"}
											</Card.Title>
											<div
												className="dropdown-icon dictionary-dropdown"
												onClick={() => handleDropItem(word)}>
												<i
													className={
														word.id === wordId
															? "icon-down down-font"
															: "icon icon-angle-arrow-down"
													}></i>
											</div>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={12} className="d-flex p-2 flex-wrap">
									{wordId === word.id ? (
										<div className="w-100 px-3 fadeIn">
											{renderDictionaryComponent(word)}
											{renderTabsViewComponent(word)}
										</div>
									) : (
										word.tags &&
										word.tags.map((tag, i) => (
											<div key={i} className="m-1 tag-view">
												{tag}
											</div>
										))
									)}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				))}
			<ImageSlider senseImages={senseImages} handleClose={handleClose} showImg={show} />
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	//   updateField
};

export default connect(mapStateToProps, mapDispatchToProps)(Dictionary);
