import React, { useEffect, useState } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { resumeProgressFields } from "./helper";
import { FormField } from "../sharedComponents/formField";
import { required } from "../../utils/formValidators";
import { resumeLessonProgress, fetchLessons } from "./actions";
import { useTranslation } from "react-i18next";

const ResumeProgressModal = ({
	handleSubmit,
	handleClose,
	fetchLessons,
	show,
	selectedUser,
	resumeLessonProgress,
	initialize,
	language,
	lessonNumber,
}) => {
	const [updating, setUpdating] = useState(false);
	const [hebrewLessons, setHebrewLessons] = useState([]);
	const [greekLessons, setGreekLessons] = useState([]);
	const languages = [setHebrewLessons, setGreekLessons];
	const { t } = useTranslation();

	useEffect(() => {
		if (selectedUser) {
			initialize({ email: selectedUser.email });
			languages.forEach((lang, index) => {
				fetchLessons(selectedUser.id, index).then((data) => {
					const lessonList =
						data.response && data.response.length
							? data.response.map((lang) => ({
									label: lang.displayLessonNumber,
									value: lang.lessonNumber,
							  }))
							: [];
					lang(lessonList);
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser]);

	const getOptions = (field) => {
		switch (field) {
			case "language":
				return [
					{ label: "Hebrew", value: 0 },
					{ label: "Greek", value: 1 },
				];
			case "lessonNumber":
				if (language) {
					if (language.value === 0) {
						return hebrewLessons;
					} else {
						return greekLessons;
					}
				} else {
					return [];
				}
			default:
				return [];
		}
	};

	useEffect(() => {
		if (language && lessonNumber) {
			initialize({ email: selectedUser.email, language });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	const addUserDetails = (data) => {
		setUpdating(true);
		let payload = {
			userID: selectedUser ? selectedUser.id : "",
			language: data.language.value,
			lessonNumber: data.lessonNumber.value,
		};

		resumeLessonProgress(payload).then((res) => {
			setUpdating(false);
			if (res.statusCode === 200) {
				handleClose();
				initialize({});
				NotificationManager.success(t("user.progress.updatedsuccess"), "", 3000);
			} else {
				NotificationManager.error(
					`${
						res.response && res.response.validationErrors && res.response.validationErrors.length > 0
							? res.response.validationErrors[0].value
							: res.response && res.response.message
							? res.response.message
							: t("helpers.utils.notification.error")
					}`,
					"",
					3000
				);
			}
		});
	};

	const renderForm = () => (
		<form className="user-form">
			<div className="d-flex flex-column row">
				{resumeProgressFields.map((field, index) => (
					<Field
						key={field.label}
						name={field.name}
						type={field.type}
						disabled={field.disabled}
						integer={field.integer}
						formGroupClass="no-label col-md-12 m-0"
						defaultError={true}
						label={field.label}
						component={FormField}
						options={field.type === "select" ? getOptions(field.name) : null}
						validate={required}
					/>
				))}
			</div>
			<div className="form-footer d-flex justify-content-end mt-2">
				<button
					type="button"
					disabled={updating}
					className="btn btn-primary save-btn"
					onClick={handleSubmit((data) => addUserDetails(data))}>
					Update
				</button>
			</div>
		</form>
	);

	return (
		<Modal show={show} className="resume-progress-modal" centered backdrop="static">
			<Modal.Header>
				<Modal.Title>{t("helpers.utils.resumelesson.progress")}</Modal.Title>
				<div className="modal-close-btn" onClick={handleClose}>
					<i className={`icon icon-close `}></i>
				</div>
				<p></p>
			</Modal.Header>
			<Modal.Body>{renderForm()}</Modal.Body>
		</Modal>
	);
};

const selector = formValueSelector("ResumeProgressModal");

const mapStateToProps = (state) => ({
	language: selector(state, "language"),
	lessonNumber: selector(state, "lessonNumber"),
});

const mapDispatchToProps = {
	resumeLessonProgress,
	fetchLessons,
};

export default reduxForm({
	form: "ResumeProgressModal",
	destroyOnUnmount: true,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResumeProgressModal)));
