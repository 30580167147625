import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../sharedComponents/Loader";
import { ToggleButton } from "../sharedComponents/toggleButton";
import { setSideMenu } from "../Sidemenu/actions";
import { assetsMap, lessonMap, syncContent } from "./actions";
import "./Sync.scss";

const syncList = [
	{
		title: "Lesson Guide",
		urlPath: "lessonguides/map",
	},
	{
		title: "Bible Audio Files",
		urlPath: "bibleaudio/map",
	},
	{
		title: "Preset Decks",
		urlPath: "presetdeck/create",
		toggle: true,
	},
	{
		title: "Media Files",
		urlPath: "assets/map?IsBackgroundProcess=false",
		fileType: true,
	},
	{
		title: "Lesson topics",
		urlPath: "lesson/maptopics",
	},
];

const SyncMedia = ({ syncContent, assetsMap, lessonMap }) => {
	const [checkedValues, setCheckedValues] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		const timer = setTimeout(() => setLoading(false), 2000);
		return () => {
			clearTimeout(timer);
		};
	}, []);
	const handleCheck = (checked, title) => {
		setCheckedValues({ ...checkedValues, [title]: checked });
	};

	const handleSync = (path, value, title) => {
		setLoading(true);
		const action = title === "Media Files" ? assetsMap : title === "Lesson topics" ? lessonMap : syncContent;
		action(`${path}?language=${value ? 1 : 0}`)
			.then((res) => {
				if (res.statusCode === 200) {
					NotificationManager.success("Synced successfully", "", 3000);
					setLoading(false);
				} else {
					NotificationManager.error("Sync Failed", "Error", 2000);
					setLoading(false);
				}
			})
			.catch(() => {
				NotificationManager.error("Something Went Wrong", "Error", 2000);
				setLoading(false);
			});
	};

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="sync-container ">
					{syncList.map((el, idx) => (
						<Card key={idx} className="card-lesson">
							<Card.Body>
								<Row>
									<Col md={12} className="p-0">
										<div className="d-flex justify-content-between">
											<div className="mr-3 ml-3 w-100 position-relative d-flex justify-content-between align-items-center ">
												<Card.Title className="card-custom-title">{el.title}</Card.Title>
												<div className="d-flex align-items-center">
													<div className="toggle-container" style={el.fileType ? { width: 220 } : {}}>
														<span>{el.fileType ? "Grammar" : "Hebrew"}</span>
														<div>
															<ToggleButton
																onToggle={(e) => handleCheck(e.target.checked, el.title)}
																selected={checkedValues[el.title]}
															/>
														</div>
														<span>{el.fileType ? "Vocabulary" : "Greek"}</span>
													</div>
													<Button
														className="sync-button"
														variant="primary"
														onClick={() =>
															handleSync(el.urlPath, checkedValues[el.title], el.title)
														}>
														Sync
													</Button>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
				</div>
			)}
		</>
	);
};

const mapDispatchToProps = {
	setSideMenu,
	syncContent,
	assetsMap,
	lessonMap,
};

export default withRouter(connect(null, mapDispatchToProps)(SyncMedia));
