import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import Header from "../header";
import { setSideMenu } from "../Sidemenu/actions";
import { changePassword } from "../Auth/actions";
import { FormField } from "../sharedComponents/formField";
import SharedModal from "../sharedComponents/Modal";
import Loader from "../sharedComponents/Loader";
import { useTranslation } from "react-i18next";
import { required, IsValuesMatch, checkPasswordLength, checkSpecialCharacter } from "../../utils/formValidators";

import "../../App.scss";

const ChangePassword = ({ setSideMenu, history, menu, handleSubmit, changePassword }) => {
	const [updateValues, setUpdateValues] = useState({});
	const [show, setShow] = useState(false);
	const [loader, setLoader] = useState(false);
	const { t } = useTranslation();

	const changePasswordFields = [
		{
			name: "currentPassword",
			label: t("header.changepassword.currentpassword"),
			placeholder: t("header.changepassword.currentpassword"),
			validate: [required],
		},
		{
			name: "newPassword",
			label: t("header.changepassword.newpassword"),
			placeholder: t("header.changepassword.newpassword"),
			validate: [required, checkPasswordLength, checkSpecialCharacter],
		},
		{
			name: "confirmPassword",
			label: t("header.changepassword.confirmpassword"),
			placeholder: t("header.changepassword.confirmpassword"),
			validate: [required, IsValuesMatch],
		},
	];

	useEffect(() => {
		setSideMenu({
			menu: [t("home.name"), t("header.changepassword.name")],
			language: "",
		});
	}, [history, setSideMenu]);

	const updatepassword = (values) => {
		setShow(true);
		setUpdateValues(values);
	};

	const handleUpdatePassWord = () => {
		setLoader(true);
		changePassword(updateValues).then((res) => {
			if (res.statusCode === 200) {
				NotificationManager.success(t("header.changepassword.notification.success"), "", 3000);
				setLoader(false);
				setShow(false);
			} else {
				NotificationManager.error(res.response && res.response.message, t("helpers.utils.notification.error"), 2000);
				setLoader(false);
				setShow(false);
			}
		});
	};

	const renderModalBody = () => {
		return <div className="text-center">{t("header.changepassword.update.modal.prompt")}</div>;
	};

	const renderModalFooter = () => {
		return (
			<div className="text-center mb-4">
				<button className="btn-cancel mr-3" onClick={() => setShow(false)}>
					{t("helpers.utils.no")}
				</button>
				<button className="btn-upload rounded" onClick={() => handleUpdatePassWord()}>
					{t("helpers.utils.yes")}
				</button>
			</div>
		);
	};

	return (
		<div className="empty  users-container ">
			<Header menu={menu} head={t("header.changepassword.name")} history={history} />
			{loader && <Loader />}
			<div className="bg-settings">
				<div className="password-padding user-form-container position-relative ">
					<form name="ChangePasswordForm" onSubmit={handleSubmit(updatepassword)}>
						{changePasswordFields &&
							changePasswordFields.map((item, index) => (
								<Field
									name={item.name}
									type="password"
									label={item.label}
									formGroupClass="mt-2 col-lg-8 col-xs-12 p-0"
									placeholder={item.placeholder}
									component={FormField}
									validate={item.validate}
								/>
							))}
						<div className="col-8 d-flex justify-content-end pr-0">
							<button type="submit" className="btn-upload rounded btn-changepassword">
								{t("helpers.utils.update")}
							</button>
						</div>
					</form>
					<SharedModal
						show={show}
						modalBody={renderModalBody()}
						modalFooter={renderModalFooter()}
						handleClose={() => setShow(false)}
						modalClass="video-modal"
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
});

const mapDispatchToProps = {
	setSideMenu,
	changePassword,
};

export default reduxForm({
	form: "ChangePasswordForm",
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword)));
