import React, { useMemo, useEffect, useState } from "react";
import { Container, Card, Form, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Loader from "../../../sharedComponents/Loader";
import ReportTableComponent from "../ReportTable";
import {
	getLessonData,
	getReadingData,
	getLessons,
	getLessonEndDate,
	getPracticeData,
	createPlanAction,
	getBooks,
	getChapters,
	getVerse,
	getReadingEndDate,
	deletePlannerTaskId,
	deletePlannerPlanId,
} from "../action";
import VerticalModal from "../../../sharedComponents/VerticalModal/index";
import Create from "./Create";
import { useTranslation } from "react-i18next";

const INDEX = 0;
const SIZE = 4;

const Planner = ({
	match,
	getLessonData,
	planner,
	dispatch,
	getReadingData,
	getLessons,
	getLessonEndDate,
	getPracticeData,
	createPlanAction,
	getBooks,
	getChapters,
	getVerse,
	getReadingEndDate,
	deletePlannerTaskId,
	deletePlannerPlanId,
	currentTab,
}) => {
	const { t } = useTranslation();

	const modules = [
		{ label: t(["menu.customdecks"]), value: 0 },
		{ label: t(["menu.biblereading"]), value: 1 },
		{ label: t(["menu.fluencydrill"]), value: 2 },
		{ label: t(["menu.wordsthatneedpractice"]), value: 3 },
	];

	const initialState = {
		[t("dashboard.planner.newplan.lesson")]: {
			start: { value: "1.1", label: t("dashboard.planner.newplan.lessonlist", { number: `1.1` }) },
			end: { value: "1.1", label: t("dashboard.planner.newplan.lessonlist", { number: "1.1" }) },
			pace: { value: "1", label: "1" },
			daysOfWeek: [],
			startDate: undefined,
			endDate: null,
		},
		[t("dashboard.planner.newplan.reading")]: {
			daysOfWeek: [],
			startDate: undefined,
			endDate: null,
			pace: { value: "1", label: "1" },
		},
		[t("dashboard.planner.newplan.practice")]: {
			module: [],
			daysOfWeek: [],
			startDate: undefined,
			endDate: null,
		},
	};

	const { lessonData, listLesson, practiceData, readingData, bookList, mounted } = planner;
	const [showModal, setShowModal] = useState(false);
	const [isClosing, seIsClosing] = useState(false);
	const [option, setOption] = useState(t("dashboard.planner.newplan.lesson"));
	const [state, setState] = useState(initialState[t("dashboard.planner.newplan.lesson")]);
	const [startChapterList, setStartChapterList] = useState([]);
	const [toChapterList, setToChapterList] = useState([]);
	const [verseList, setVerseList] = useState([]);
	const [isValidated, setIsValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingListData, setLoadingListData] = useState(true);

	const getDaysValue = () => {
		const list = {};
		state.daysOfWeek && state.daysOfWeek.forEach((el) => (list[el] = true));
		return list;
	};

	const [practiceSectionItems, setPracticeSectionItems] = useState([
		{
			label: t(["dashboard.planner.newplan.selectmodulefrompractice"]),
			type: "select",
			size: 12,
			options: modules,
			key: "module",
			multiple: true,
			value: state.module,
		},
	]);

	const {
		params: { language },
	} = match;

	const practiceCustomPace = [
		{
			module: t(["menu.customdecks"]),
			label: t(["dashboard.planner.newplan.customdeckpace"]),
			size: 7,
			type: "number",
			unit: t(["dashboard.planner.newplan.newwordsperday"]),
			unitSize: 5,
			key: "pace",
		},
		{
			module: t(["menu.biblereading"]),
			label: t(["dashboard.planner.newplan.biblereadingpace"]),
			size: 7,
			type: "number",
			unit: t(["dashboard.planner.newplan.newversesperday"]),
			unitSize: 5,
			key: "pace",
		},
		{
			module: t(["menu.fluencydrill"]),
			label: t(["dashboard.planner.newplan.fluencydrillpace"]),
			size: 7,
			type: "number",
			unit: t(["dashboard.planner.newplan.newversesperday"]),
			unitSize: 5,
			key: "pace",
		},
		{
			module: t(["menu.wordsthatneedpractice"]),
			label: t(["dashboard.planner.newplan.wordspracticeperdaypace"]),
			size: 7,
			type: "number",
			unit: t(["dashboard.planner.newplan.wordspracticedperday"]),
			unitSize: 5,
			key: "pace",
		},
	];
	const getData = async () => {
		const { response: lessonData } = await getLessonData(language, INDEX, SIZE, false);
		const { response: readingData } = await getReadingData(language, INDEX, SIZE, false);
		const { response: practiceData } = await getPracticeData(language, INDEX, SIZE, false);
		const { response: lessonListing } = await getLessons(language);
		const { response: bookListing } = await getBooks(language === "Hebrew" ? "Old" : "New");
		// to get lesson number
		const listLesson = [];
		const bookList = [];
		for (let i = 0; i < lessonListing.length; i++) {
			listLesson.push({
				label: t("dashboard.planner.newplan.lessonlist", {
					number: `${lessonListing[i].mainLessonNumber}.${lessonListing[i].subLessonNumber}`,
				}),
				value: `${lessonListing[i].mainLessonNumber}.${lessonListing[i].subLessonNumber}`,
			});
		}
		for (let i = 0; i < bookListing.length; i++) {
			bookList.push({ label: bookListing[i].fullName, value: bookListing[i].id });
		}
		
		dispatch({ planner: { ...planner, lessonData, readingData, listLesson, practiceData, bookList, mounted: true } });
		setLoadingListData(false);
	};
	useEffect(() => {
		if (currentTab === t("dashboard.planner.name") && !mounted) {
			getData();
		}
	}, [currentTab]);

	const handleLessonDateChange = async (value) => {
		setLoading(true);
		const {
			response: { estimatedEndDate, message },
			statusCode,
		} = await getLessonEndDate(
			state.start.value,
			state.end.value,
			language === "Hebrew" ? 0 : 1,
			state.pace.value,
			state.daysOfWeek.join(","),
			`${moment(value).format("YYYY-MM-DD HH:mm:ss")}`,
			state.name
		);
		if (statusCode === 200) {
			setState({ ...state, endDate: new Date(estimatedEndDate) });
		} else {
			NotificationManager.error(message ? `${message}` : t("helpers.utils.notification.error"), "", 3000);
			setState({ ...state, startDate: null, endDate: null });
		}
		setLoading(false);
	};

	const handleReadingDateChange = async (value) => {
		setLoading(true);
		const {
			response: { estimatedEndDate, message },
			statusCode,
		} = await getReadingEndDate(
			state.testament,
			state.startFromBookID.value,
			state.fromChapter.value,
			state.upToBookId.value,
			state.toChapter.value,
			state.pace,
			state.daysOfWeek.join(","),
			moment(value).format("YYYY-MM-DD HH:mm:ss"),
			state.name,
			state.planType.value
		);
		if (statusCode === 200) {
			setState({ ...state, endDate: new Date(estimatedEndDate) });
		} else {
			NotificationManager.error(message ? `${message}` : t("helpers.utils.notification.error"), "", 3000);
			setState({ ...state, startDate: null, endDate: null });
		}
		setLoading(false);
	};

	useMemo(() => {
		if (state.name && state.daysOfWeek && state.daysOfWeek.length > 0 && state.startDate) {
			if (option === t("dashboard.planner.newplan.lesson") && state.end.value && state.start.value) {
				handleLessonDateChange(state.startDate);
			} else if (
				option === t("dashboard.planner.newplan.reading") &&
				state.startFromBookID.value &&
				state.fromChapter.value &&
				state.toChapter.value &&
				state.upToBookId.value
			) {
				handleReadingDateChange(state.startDate);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		state.start,
		state.end,
		state.startDate,
		state.startFromBookID,
		state.fromChapter,
		state.toChapter,
		state.upToBookId,
		state.startDate,
		state.daysOfWeek,
		state.name,
	]);

	useEffect(() => {
		let valid = false;
		if (state.daysOfWeek && state.daysOfWeek.length > 0 && state.startDate && state.name) {
			if (option === t("dashboard.planner.newplan.lesson") && state.start && state.end) {
				valid = true;
			} else if (
				option === t("dashboard.planner.newplan.reading") &&
				state.startFromBookID &&
				state.upToBookId &&
				state.fromChapter &&
				state.toChapter
			) {
				valid = true;
			} else if (option === t("dashboard.planner.newplan.practice") && state.endDate && state.module.length > 0) {
				let flag = true;
				state.module.forEach((el) => {
					if (!state[el.label]) {
						flag = false;
					}
				});
				valid = flag;
			}
		}
		setIsValidated(valid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, option]);

	const onClose = () => {
		seIsClosing(true);
		setTimeout(() => {
			setShowModal(false);
			seIsClosing(false);
			setOption(t("dashboard.planner.newplan.lesson"));
			setState(initialState[t("dashboard.planner.newplan.lesson")]);
		}, 400);
	};

	const handleOptionChange = async (value) => {
		if (value === t("dashboard.planner.newplan.reading")) {
			setLoading(true);
			const fromChapterList = [];
			const toChapterList = [];
			const fromChapter = await handleBookIdChange(bookList[0].value);
			const toChapter = await handleBookIdChange(bookList[0].value);
			for (let i = 0; i < fromChapter.length; i++) {
				fromChapterList.push({ label: fromChapter[i], value: fromChapter[i] });
			}
			for (let j = 0; j < toChapter.length; j++) {
				toChapterList.push({ label: fromChapter[j], value: fromChapter[j] });
			}
			setStartChapterList(fromChapterList);
			setToChapterList(toChapterList);
			setState({
				...initialState[value],
				name: state.name,
				startFromBookID: { label: bookList[0].label, value: bookList[0].value },
				upToBookId: { label: bookList[0].label, value: bookList[0].value },
				testament: language === "Hebrew" ? 0 : 1,
				fromChapter: { label: fromChapter[0], value: fromChapter[0] },
				toChapter: { label: toChapter[0], value: toChapter[0] },
			});
			setLoading(false);
		} else {
			setState({ ...initialState[value], name: state.name });
		}
	};
	const handleStartChange = (value) => {
		if (value > state.end.value) {
			setState({ ...state, start: value, end: value });
		} else {
			setState({ ...state, start: value });
		}
	};

	const handleDaysChange = (value) => {
		const cDaysOfWeek = [...state.daysOfWeek];
		if (cDaysOfWeek.includes(value)) {
			cDaysOfWeek.splice(cDaysOfWeek.indexOf(value), 1);
		} else {
			cDaysOfWeek.push(value);
		}
		setState({ ...state, daysOfWeek: cDaysOfWeek });
	};

	const handleBookIdChange = async (value) => {
		const {
			response: { chapters: chapterList },
		} = await getChapters(value);
		await handleChapterChange(value, chapterList[0]);
		return chapterList;
	};

	const handleChapterChange = async (bookId, chapterId) => {
		const {
			response: { verses: verseList },
		} = await getVerse(bookId, chapterId);

		const list = [];
		for (let i = 0; i < verseList.length && verseList.length; i++) {
			list && list.push({ label: verseList[i].verse, value: verseList[i].verse });
		}
		setVerseList(list);
	};

	const handleOnChange = async (name, value, paceModule) => {
		if (name === "start") {
			handleStartChange(value);
		} else if (name === "end") {
			setState({ ...state, end: value });
		} else if (name === "daysOfWeek") {
			handleDaysChange(value);
		} else if (name === "startFromBookId") {
			const list = [];
			setLoading(true);
			const fromChapter = await handleBookIdChange(value.value);
			setState({ ...state, startFromBookID: value, fromChapter: { label: fromChapter[0], value: fromChapter[0] } });
			for (let i = 0; i < fromChapter.length; i++) {
				list.push({ label: fromChapter[i], value: fromChapter[i] });
			}
			setStartChapterList(list);
			setLoading(false);
		} else if (name === "upToBookId") {
			const list = [];
			setLoading(true);
			const toChapter = await handleBookIdChange(value.value);
			for (let j = 0; j < toChapter.length; j++) {
				list.push({ label: toChapter[j], value: toChapter[j] });
			}
			setToChapterList(list);
			setState({ ...state, upToBookId: value, toChapter: { label: toChapter[0], value: toChapter[0] } });
			setLoading(false);
		} else if (option === t("dashboard.planner.newplan.lesson") && name === "pace") {
			setState({ ...state, pace: value, daysOfWeek: [] });
		} else if (name === "module") {
			const dynamicItems = practiceSectionItems.filter((item) => !item.module);
			value &&
				value.forEach((mod) => {
					const customPace = practiceCustomPace.filter((pace) => pace.module === mod.label)[0];
					dynamicItems.splice(1, 0, { ...customPace, value: state[mod.label] ? state[mod.label] : null });
				});
			setPracticeSectionItems(dynamicItems);
			setState({ ...state, [name]: value });
		} else if (paceModule) {
			setState({ ...state, [paceModule]: value });
		} else {
			setState({ ...state, [name]: value });
		}
	};

	const getFinishUptoLesson = () => {
		const copy = [...listLesson];
		if (state.start.value === "1.1") {
			return copy;
		}
		const pos = copy.map((el) => el.value).indexOf(state.start.value);
		return copy.splice(pos);
	};

	const getLessonPace = () => {
		const pace = [];
		const copy = [...listLesson];
		if (state.start.value === state.end.value) {
			return [{ label: 1, value: 1 }];
		}
		const startPos = copy.map((el) => el.value).indexOf(state.start.value);
		const endPos = copy.map((el) => el.value).indexOf(state.end.value);

		for (let i = 1; i <= endPos - startPos + 1; i++) {
			pace.push({ label: i, value: i });
		}
		return pace;
	};

	const getPathCreate = (value) => {
		if (value === t("dashboard.planner.newplan.lesson")) {
			return `planner/lesson/create`
		} else if (value === t("dashboard.planner.newplan.practice")) {
			return `planner/Practice/create`
		} else if (value === t("dashboard.planner.newplan.reading")) {
			return `planner/reading/create`
		}

	}

	const getBody = (value) => {
		if (value === t("dashboard.planner.newplan.lesson")) {
			return {
				start: state.start.value,
				end: state.end.value,
				name: state.name,
				language: language === "Hebrew" ? 0 : 1,
				pace: parseInt(state.pace.value),
				daysOfWeek: state.daysOfWeek.join(","),
				startDate: moment(state.startDate).format("YYYY-MM-DD"),
				endDate: moment(state.endDate).format("YYYY-MM-DD"),
			};
		}
		if (value === t("dashboard.planner.newplan.practice")) {
			return {
				planModules:
					state.module &&
					state.module.map((el) => ({
						moduleType: el.value,
						pace: parseInt(state[el.label]),
					})),
				name: state.name,
				Language: language === "Hebrew" ? 0 : 1,
				daysOfWeek: state.daysOfWeek.join(","),
				StartDate: moment(state.startDate).format("YYYY-MM-DD"),
				EndDate: moment(state.endDate).format("YYYY-MM-DD"),
			};
		}
		return {
			testament: state.testament,
			planName: state.name,
			startFromBookID: state.startFromBookID.value,
			fromChapter: state.fromChapter.value,
			finishUpToBookID: state.upToBookId.value,
			toChapter: state.toChapter.value,
			readingPlanType: state.planType.value,
			pace: parseInt(state.pace),
			days: state.daysOfWeek.join(","),
			startDate: moment(state.startDate).format("YYYY-MM-DD"),
			endDate: moment(state.endDate).format("YYYY-MM-DD"),
		};
	};
	const createPlan = async (e) => {
		e.preventDefault();
		setLoading(true);
		const url = getPathCreate(option);
		const body = getBody(option);
		const response = await createPlanAction(url, body);
		if (response.statusCode === 200) {
			NotificationManager.success(t("dashboard.planner.notifications.plancreated"), "", 3000);
			if (option === t("dashboard.planner.newplan.lesson")) {
				const { response: lessonData } = await getLessonData(language, INDEX, SIZE);
				dispatch({ planner: { ...planner, lessonData } });
			} else if (option === t("dashboard.planner.newplan.reading")) {
				const { response: readingData } = await getReadingData(language, INDEX, SIZE);
				dispatch({ planner: { ...planner, readingData } });
			} else if (option === t("dashboard.planner.newplan.practice")) {
				const { response: practiceData } = await getPracticeData(language, INDEX, SIZE);
				dispatch({ planner: { ...planner, practiceData } });
			}
			onClose();
		} else {
			NotificationManager.error(response.message, "", 3000);
		}
		handleOptionChange(t("dashboard.planner.newplan.lesson"));
		setLoading(false);
	};

	const deleteLessonTask = async (id) => {
		setLoading(true);
		const { response: res, statusCode } = await deletePlannerTaskId("lesson", id);
		if (statusCode === 200) {
			const { response: lessonData } = await getLessonData(language, INDEX, SIZE, false);
			dispatch({ planner: { ...planner, lessonData, mounted: true } });
			NotificationManager.success(t("dashboard.planner.notifications.taskdeletedsuccessfully"), "", 3000);
		} else {
			NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
		}
		setLoading(false);
	};

	const deleteReadingTask = async (id) => {
		setLoading(true);
		const { response: res, statusCode } = await deletePlannerTaskId("reading", id);
		if (statusCode === 200) {
			const { response: readingData } = await getReadingData(language, INDEX, SIZE, false);
			dispatch({ planner: { ...planner, readingData, mounted: true } });
			NotificationManager.success(t("dashboard.planner.notifications.taskdeletedsuccessfully"), "", 3000);
		} else {
			NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
		}
		setLoading(false);
	};

	const deletePracticeTask = async (id) => {
		setLoading(true);
		const { response: res, statusCode } = await deletePlannerTaskId("practice", id);
		if (statusCode === 200) {
			const { response: practiceData } = await getPracticeData(language, INDEX, SIZE, false);
			dispatch({ planner: { ...planner, practiceData, mounted: true } });
			NotificationManager.success(t("dashboard.planner.notifications.taskdeletedsuccessfully"), "", 3000);
		} else {
			NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
		}
		setLoading(false);
	};

	const deleteLessonPlan = async (id) => {
		setLoading(true);
		const { response: res, statusCode } = await deletePlannerPlanId("lesson", id);
		if (statusCode === 200) {
			const { response: lessonData } = await getLessonData(language, INDEX, SIZE, false);
			dispatch({ planner: { ...planner, lessonData, mounted: true } });
			NotificationManager.success(t("dashboard.planner.notifications.plandeletedsuccessfully"), "", 3000);
		} else {
			NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
		}
		setLoading(false);
	};

	const deleteReadingPlan = async (id) => {
		setLoading(true);
		const { response: res, statusCode } = await deletePlannerPlanId("reading", id);
		if (statusCode === 200) {
			const { response: readingData } = await getReadingData(language, INDEX, SIZE, false);
			dispatch({ planner: { ...planner, readingData, mounted: true } });
			NotificationManager.success(t("dashboard.planner.notifications.plandeletedsuccessfully"), "", 3000);
		} else {
			NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
		}
		setLoading(false);
	};

	const deletePracticePlan = async (id) => {
		setLoading(true);
		const { response: res, statusCode } = await deletePlannerPlanId("practice", id);
		if (statusCode === 200) {
			const { response: practiceData } = await getPracticeData(language, INDEX, SIZE, false);
			dispatch({ planner: { ...planner, practiceData, mounted: true } });
			NotificationManager.success(t("dashboard.planner.notifications.plandeletedsuccessfully"), "", 3000);
		} else {
			NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
		}
		setLoading(false);
	};

	const modalBody = () => (
		<div>
			<div className="mb-4" style={{ fontSize: 20, fontWeight: 700 }}>
				{t("dashboard.planner.newplan.createnewplan")}
			</div>
			<Form>
				<Row className="form-row">
					<Col xs={6}>
						<Form.Group controlId="ControlInput">
							<Form.Label> {t("dashboard.planner.newplan.enterplanname")} </Form.Label>
							<Form.Control
								type="text"
								onChange={(e) => handleOnChange("name", e.target.value)}
								value={state.name}
							/>
						</Form.Group>
					</Col>
					<Col xs={6}>
						<Form.Group controlId="ControlSelect">
							<Form.Label> {t("dashboard.planner.newplan.selectplantype")} </Form.Label>
							<Form.Control
								as="select"
								onChange={(e) => {
									setOption(e.target.value);
									handleOptionChange(e.target.value);
								}}
								style={{ textTransform: "capitalize" }}>
								<option> {t("dashboard.planner.newplan.lesson")} </option>
								<option> {t("dashboard.planner.newplan.reading")} </option>
								<option> {t("dashboard.planner.newplan.practice")} </option>
							</Form.Control>
						</Form.Group>
					</Col>
					{option === t("dashboard.planner.newplan.lesson") && (
						<Create
							onChange={handleOnChange}
							items={[
								{
									label: t("dashboard.planner.newplan.startinglesson"),
									type: "select",
									size: 6,
									options: listLesson,
									key: "start",
									prefix: t("dashboard.planner.newplan.lesson"),
									value: state.start,
								},
								{
									label: t("dashboard.planner.newplan.endinglesson"),
									type: "select",
									size: 6,
									options: getFinishUptoLesson(),
									key: "end",
									prefix: t("dashboard.planner.newplan.lesson"),
									value: state.end,
								},
								{
									label: t("dashboard.planner.newplan.learningpace"),
									type: "select",
									size: 6,
									options: getLessonPace(),
									unit: t("dashboard.planner.newplan.sublessonsperday"),
									unitSize: 6,
									key: "pace",
									value: state.pace,
								},
								{
									label: t("dashboard.planner.newplan.choosedaysforplan"),
									type: "checkbox",
									size: 12,
									key: "daysOfWeek",
									value: getDaysValue(),
								},
								{
									label: t("dashboard.planner.newplan.startdate"),
									type: "datePicker",
									size: 6,
									key: "startDate",
									value: state.startDate,
								},
								{
									label: t("dashboard.planner.newplan.enddate"),
									type: "text",
									size: 6,
									key: "endDate",
									value: state.endDate ? moment(state.endDate).format("MM/DD/YYYY") : "",
								},
							]}
						/>
					)}
					{option === t("dashboard.planner.newplan.reading") && (
						<Create
							onChange={handleOnChange}
							items={[
								{
									label: t("dashboard.planner.newplan.startingbook"),
									type: "select",
									size: 6,
									options: bookList,
									key: "startFromBookId",
									value: state.startFromBookID,
								},
								{
									label: t("dashboard.planner.newplan.selectchapter"),
									type: "select",
									size: 6,
									options: startChapterList,
									key: "fromChapter",
									value: state.fromChapter,
								},
								{
									label: t("dashboard.planner.newplan.endingbook"),
									type: "select",
									size: 6,
									options: bookList,
									key: "upToBookId",
									value: state.upToBookId,
								},
								{
									label: t("dashboard.planner.newplan.selectchapter"),
									type: "select",
									size: 6,
									options: toChapterList,
									key: "toChapter",
									value: state.toChapter,
								},
								{
									label: t("dashboard.planner.newplan.setpace"),
									type: "select",
									size: 4,
									options: [
										{ label: t("helpers.utils.chapters"), value: 1 },
										{ label: t("helpers.utils.verses"), value: 0 },
									],
									unit: "",
									unitSize: 2,
									key: "planType",
									value: state.planType,
								},
								{
									label: "",
									type: "number",
									size: 2,
									options: verseList,
									unit: `${state.planType !== undefined && state.planType.label ? state.planType.label : ""
										}${t("dashboard.planner.newplan.perday")}`,
									unitSize: 4,
									key: "pace",
									value: state.pace,
								},
								{
									label: t("dashboard.planner.newplan.choosedaysforplan"),
									type: "checkbox",
									size: 12,
									key: "daysOfWeek",
									value: getDaysValue(),
								},
								{
									label: t("dashboard.planner.newplan.startdate"),
									type: "datePicker",
									size: 6,
									key: "startDate",
									value: state.startDate,
								},
								{
									label: t("dashboard.planner.newplan.enddate"),
									type: "text",
									size: 6,
									key: "endDate",
									value: state.endDate ? moment(state.endDate).format("MM/DD/YYYY") : "",
								},
							]}
						/>
					)}
					{option === t("dashboard.planner.newplan.practice") && (
						<Create
							onChange={handleOnChange}
							items={[
								...practiceSectionItems,
								{
									label: t("dashboard.planner.newplan.choosedaysforplan"),
									type: "checkbox",
									size: 12,
									key: "daysOfWeek",
									value: getDaysValue(),
								},
								{
									label: t("dashboard.planner.newplan.startingdate"),
									type: "datePicker",
									size: 6,
									key: "startDate",
									value: state.startDate,
								},
								{
									label: t("dashboard.planner.newplan.endingdate"),
									type: "datePicker",
									size: 6,
									key: "endDate",
									value: state.endDate,
								},
							]}
						/>
					)}
					<Col>
						<div className="d-flex justify-content-end planner-btn-container">
							<button
								className="btn btn-primary submit-text mt-3 pl-4 pr-4"
								onClick={createPlan}
								disabled={!isValidated}>
								{t("dashboard.planner.newplan.create")}
							</button>
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	);
	return (
		<Container fluid className="planner-container">
			{loading && <Loader />}
			{showModal && (
				<VerticalModal show={showModal} onClose={onClose} isClosing={isClosing}>
					{modalBody()}
				</VerticalModal>
			)}
			{!loadingListData ? (
				<Card body className="no-shadow">
					<div className="mb-3">
						<div className="d-flex justify-content-between mb-3">
							<div className="title-heading">{t("dashboard.lessongoals")}</div>
							<button className="create-btn" onClick={() => setShowModal(true)}>
								{t("dashboard.planner.newplan.createnewplan")}
							</button>
						</div>
						<ReportTableComponent
							header={[
								{ label: t("dashboard.planner.planname"), key: "name"},
								{ label: t("dashboard.planner.nexttask"), key: "lesson", type: "taskLink" },
								{ label: t("dashboard.planner.taskdue"), key: "dueOn" },
								{ label: t("dashboard.planner.plancompletiondate"), key: "planCompletionDate" },
								{ label: t("helpers.utils.lessonstatus.completed"), key: "status", type: "status" },
								{ label: t("dashboard.planner.action"), key: "id", type: "action" },
							]}
							data={lessonData}
							taskDeleteAction={deleteLessonTask}
							planDeleteAction={deleteLessonPlan}
							planType={"lesson"}
							language={match.params.language}
						/>
						{lessonData && lessonData.count > 0 && (
							<div className="text-right mt-1">
								<Link to={`/home/${language}/Dashboard/planner/lesson`}>{t("helpers.utils.view")}</Link>
							</div>
						)}
					</div>
					<div className="mb-3">
						<div className="title-heading mb-3">{t("dashboard.readinggoals")}</div>
						<ReportTableComponent
							header={[
								{ label: t("dashboard.planner.planname"), key: "planName" },
								{ label: t("dashboard.planner.nexttask"), key: "task", type: "taskLink" },
								{ label: t("dashboard.planner.taskdue"), key: "dueOn"},
								{ label: t("dashboard.planner.plancompletiondate"), key: "planEndDate" },
								{ label: t("helpers.utils.lessonstatus.completed"), key: "status", type: "status" },
								{ label: t("dashboard.planner.action"), key: "id", type: "action" },
							]}
							data={readingData}
							taskDeleteAction={deleteReadingTask}
							planDeleteAction={deleteReadingPlan}
							planType={"reading"}
							language={match.params.language}
						/>
						{readingData && readingData.count > 0 && (
							<div className="text-right mt-1">
								<Link to={`/home/${language}/Dashboard/planner/reading`}>{t("helpers.utils.view")}</Link>
							</div>
						)}
					</div>
					<div className="mb-3">
						<div className="title-heading mb-3">{t("dashboard.practicegoals")}</div>
						<ReportTableComponent
							header={[
								{ label: t("dashboard.planner.planname"), key: "name"},
								{ label: t("dashboard.planner.nexttask"), key: "nextTask", type: "taskLink" },
								{ label: t("dashboard.planner.taskdue"), key: "dueOn"},
								{ label: t("dashboard.planner.plancompletiondate"), key: "planEndDate" },
								{ label: t("helpers.utils.lessonstatus.completed"), key: "status", type: "status" },
								{ label: t("dashboard.planner.action"), key: "id", type: "action" },
							]}
							data={practiceData}
							taskDeleteAction={deletePracticeTask}
							planDeleteAction={deletePracticePlan}
							planType={"practice"}
							language={match.params.language}
						/>
						{practiceData && practiceData.count > 0 && (
							<div className="text-right mt-1">
								<Link to={`/home/${language}/Dashboard/planner/practice`}>{t("helpers.utils.view")}</Link>
							</div>
						)}
					</div>
				</Card>
			) : (
				<SkeletonTheme color="#FFF">
					<Skeleton height={390} />
				</SkeletonTheme>
			)}
		</Container>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	getLessonData,
	getReadingData,
	getLessons,
	getLessonEndDate,
	getPracticeData,
	createPlanAction,
	getBooks,
	getChapters,
	getVerse,
	getReadingEndDate,
	deletePlannerTaskId,
	deletePlannerPlanId,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Planner));
