import React, { useEffect, useState } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { isEqual } from "lodash";

import { FormField } from "../sharedComponents/formField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { experienceList, languages } from "./helper";
import { required, PhoneNumber, email } from "../../utils/formValidators";
import Loader from "../sharedComponents/Loader";
import { useTranslation } from "react-i18next";

const UserForm = ({
	countryList,
	reasons,
	preferences,
	userRoles,
	handleSubmit,
	initialize,
	onCancel,
	addUser,
	teachingLanguage,
	subscribedLanguage,
	greekPronouncations,
	hebrewPronouncations,
	student,
	userDetails,
	updating,
	isEdit,
	userRole,
	isStudentMode,
}) => {
	const { t } = useTranslation();
	const [countries, setCountries] = useState([]);
	const [preferenceList, setPreferenceList] = useState([]);
	const [reasonList, setReasonList] = useState([]);
	const [rolesList, setRolesList] = useState([]);
	const [initialData, setInitialValues] = useState({});
	const [greekPronouncationList, setGreekPronouncationList] = useState([]);
	const [hebrewPronouncationList, setHebrewPronouncationList] = useState([]);

	const userCreationFields = [
		{
			label: t("header.myprofile.myprofileedit.main.firstname"),
			name: "firstName",
		},
		{
			label: t("header.myprofile.main.lastname"),
			name: "lastName",
		},
		{
			label: "Role type",
			name: "role",
			type: "select",
		},
		{
			label: t("header.myprofile.main.email"),
			name: "email",
			disabled: true,
		},
		{
			label: t("header.myprofile.main.contactnumber"),
			name: "phoneNumber",
		},
		{
			label: t("header.myprofile.main.universityororgaffiliation"),
			name: "universityOrOrganization",
		},
		{
			label: t("header.myprofile.main.country"),
			type: "select",
			name: "countryCode",
		},
		{
			label: t("header.myprofile.main.nativelanguagespoken"),
			name: "nativeLanguage",
		},
		{
			label: t("header.myprofile.myprofileedit.main.otherlanguagesspokenfluently"),
			name: "otherLanguages",
		},
		{
			label: "Which language are you teaching?",
			name: "teachingLanguage",
			type: "select",
		},
		{
			label: t("header.myprofile.main.whyareyoustudying"),
			name: "learningReasonID",
			type: "select",
		},
		{
			label: t("header.myprofile.main.subscribedlanguages"),
			name: "subscribedLanguage",
			type: "select",
			disabled: true,
		},
		{
			label: t("header.myprofile.main.yearsstudyinghebrew"),
			name: "experienceInSpeakingHebrew",
			type: "select",
		},
		{
			label: "Years of experience in teaching Hebrew",
			name: "experienceInTeachingHebrew",
			type: "select",
		},
		{
			label: t("header.myprofile.main.yearsstudyinggreek"),
			name: "experienceInSpeakingGreek",
			type: "select",
		},
		{
			label: "Years of experience in teaching Greek",
			name: "experienceInTeachingGreek",
			type: "select",
		},

		{
			label: t("header.myprofile.main.greekpronunciationtype"),
			name: "greekPronouncationType",
			type: "select",
		},
		{
			label: t("header.myprofile.main.hebrewpronunciationtype"),
			name: "hebrewPronouncationType",
			type: "select",
		},
	];

	useEffect(() => {
		let initialValues = {};
		userDetails &&
			userCreationFields.forEach((field) => {
				if (userDetails[field.name]) {
					if (field.type === "select") {
						initialValues[field.name] = {
							label: userDetails[field.name],
						};
					} else {
						initialValues[field.name] = userDetails[field.name];
					}
				}
			});
		if (userDetails) {
			if (userDetails.learningReason) {
				initialValues.learningReasonID = {
					label: userDetails.learningReason.reason,
					value: userDetails.learningReason.id,
				};
			}
			if (userDetails.country) {
				initialValues.countryCode = {
					label: userDetails.country.name,
					value: userDetails.country.code,
				};
			}
			if (userDetails.role) {
				initialValues.role = {
					label: userDetails.role.description,
					value: userDetails.role.role,
				};
			}
			const hebrew = preferences.find((lang) => lang.description === "Hebrew");
			const greek = preferences.find((lang) => lang.description === "Greek");
			if (userDetails.languages && userDetails.languages.length) {
				initialValues = setUserDetails(userDetails, hebrew, greek, initialValues);
			}
		}
		if (!isEqual(initialData, initialValues)) {
			setInitialValues(initialValues);
			initialize(initialValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		userDetails,
		initialize,
		preferences,
		countries,
		rolesList,
		reasonList,
		greekPronouncationList,
		hebrewPronouncationList,
		initialData,
	]);

	const setUserDetails = (userDetails, hebrew, greek, initialValues) => {
		const hebrewDetails = userDetails.languages.find((lang) => lang.language.language === (hebrew ? hebrew.value : ""));
		const greekDetails = userDetails.languages.find((lang) => lang.language.language === (greek ? greek.value : ""));
		if (hebrewDetails) {
			initialValues = setHebrewDetails(initialValues, hebrewDetails);
		}
		if (greekDetails) {
			initialValues = setGreekDetails(initialValues, greekDetails);
		}
		let selectedLang;
		if (userDetails.languages.length === 2) {
			selectedLang = { label: "Both" };
		} else {
			selectedLang = {
				label: hebrewDetails ? "Hebrew" : "Greek",
			};
		}
		initialValues = {
			...initialValues,
			subscribedLanguage: selectedLang,
			teachingLanguage: selectedLang,
		};
		return initialValues;
	};

	const setGreekDetails = (initialValues, greekDetails) => {
		initialValues = {
			...initialValues,
			experienceInSpeakingGreek: {
				label: greekDetails.experienceInSpeaking,
				value: greekDetails.experienceInSpeaking,
			},
			experienceInTeachingGreek: {
				label: greekDetails.experienceInTeaching,
				value: greekDetails.experienceInTeaching,
			},
			greekPronouncationType: {
				label: greekDetails.greekPronunciation ? greekDetails.greekPronunciation.description : "",
				value: greekDetails.greekPronunciation ? greekDetails.greekPronunciation.pronunciation : "",
			},
		};
		return initialValues;
	};

	const setHebrewDetails = (initialValues, hebrewDetails) => {
		initialValues = {
			...initialValues,
			experienceInSpeakingHebrew: {
				label: hebrewDetails.experienceInSpeaking,
				value: hebrewDetails.experienceInSpeaking,
			},
			experienceInTeachingHebrew: {
				label: hebrewDetails.experienceInTeaching,
				value: hebrewDetails.experienceInTeaching,
			},
			hebrewPronouncationType: {
				label: hebrewDetails.hebrewPronunciation ? hebrewDetails.hebrewPronunciation.description : "",
				value: hebrewDetails.hebrewPronunciation ? hebrewDetails.hebrewPronunciation.pronunciation : "",
			},
		};
		return initialValues;
	};

	useEffect(() => {
		setOptions(preferences, setPreferenceList, "description", "value");
	}, [preferences]);

	useEffect(() => {
		setOptions(userRoles, setRolesList, "description", "value");
	}, [userRoles]);

	useEffect(() => {
		setOptions(hebrewPronouncations, setHebrewPronouncationList, "description", "value");
	}, [hebrewPronouncations]);

	useEffect(() => {
		setOptions(greekPronouncations, setGreekPronouncationList, "description", "value");
	}, [greekPronouncations]);

	useEffect(() => {
		setOptions(countryList, setCountries, "name", "code");
	}, [countryList]);

	useEffect(() => {
		setOptions(reasons, setReasonList, "reason", "id");
	}, [reasons]);

	const setOptions = (list, setValue, label, value) => {
		if (list && list.length > 0) {
			const newList = list.map((field) => ({
				label: field[label],
				value: field[value],
			}));
			setValue(newList);
		}
	};

	const getOptions = (field) => {
		switch (field) {
			case "countryCode":
				return countries;
			case "learningReasonID":
				return reasonList;
			case "languagePreference":
				return preferenceList;
			case "teachingLanguage":
			case "subscribedLanguage":
				return languages;
			case "role":
				return rolesList;
			case "greekPronouncationType":
				return greekPronouncationList;
			case "hebrewPronouncationType":
				return hebrewPronouncationList;
			case "experienceInSpeakingGreek":
			case "experienceInSpeakingHebrew":
			case "experienceInTeachingHebrew":
			case "experienceInTeachingGreek":
				return experienceList;
			default:
				return [];
		}
	};

	const getValidations = (field) => {
		switch (field) {
			case "otherLanguages":
				return null;
			case "phoneNumber":
				return PhoneNumber;
			case "email":
				return [required, email];
			default:
				return required;
		}
	};

	const enableField = (field) => {
		switch (field) {
			case "experienceInTeachingGreek":
				return !student && teachingLanguage && teachingLanguage.label !== "Hebrew";
			case "teachingLanguage":
			case "role":
				return !student;
			case "experienceInTeachingHebrew":
				return !student && teachingLanguage && teachingLanguage.label !== "Greek";
			case "experienceInSpeakingHebrew":
				return (
					(!student && teachingLanguage && teachingLanguage.label !== "Greek") ||
					(student && subscribedLanguage && subscribedLanguage.label !== "Greek")
				);
			case "experienceInSpeakingGreek":
				return (
					(!student && teachingLanguage && teachingLanguage.label !== "Hebrew") ||
					(student && subscribedLanguage && subscribedLanguage.label !== "Hebrew")
				);
			case "learningReasonID":
			case "subscribedLanguage":
				return student;
			case "greekPronouncationType":
				return (
					(student && subscribedLanguage && subscribedLanguage.label !== "Hebrew") ||
					(isStudentMode && teachingLanguage && teachingLanguage.label !== "Hebrew")
				);
			case "hebrewPronouncationType":
				return (
					(student && subscribedLanguage && subscribedLanguage.label !== "Greek") ||
					(isStudentMode && teachingLanguage && teachingLanguage.label !== "Greek")
				);
			default:
				return true;
		}
	};

	const renderFormActions = () => (
		<div className="form-footer d-flex justify-content-end mt-2">
			<button className="btn btn-default" type="button" onClick={onCancel}>
				{t("helpers.utils.cancel")}
			</button>
			<button
				type="button"
				disabled={updating}
				className="btn btn-primary save-btn"
				onClick={handleSubmit((data) => addUser(data))}>
				{userDetails ? t("helpers.utils.update") : t("helpers.utils.save")}
			</button>
		</div>
	);

	const checkFieldReadability = (field) => {
		switch (field) {
			case "role":
				return isEdit && userRole !== "Administrator";
			case "subscribedLanguage":
				return isEdit;
			default:
				return false;
		}
	};
	const renderUserForm = () => (
		<form className="user-form">
			<div className="row">
				{userCreationFields.map((field, index) => {
					return (
						enableField(field.name) && (
							<Field
								key={field.label}
								name={field.name}
								type={field.type}
								formGroupClass="no-label  col-md-6"
								defaultError={true}
								// placeholder={field.label}
								label={field.label}
								component={FormField}
								options={field.type === "select" ? getOptions(field.name) : null}
								disabled={
									isEdit &&
									(userRole === "Student" || field.name === "email" || field.name === "subscribedLanguage") &&
									field.disabled
								}
								readOnly={checkFieldReadability(field.name)}
								validate={getValidations(field.name)}
							/>
						)
					);
				})}
			</div>
			{renderFormActions()}
		</form>
	);

	return (
		<div className="flex-fill list-wrapper">
			<div className="bg-settings"></div>
			<div className="user-form-container" style={{ width: `${isEdit && "60%"}` }}>
				{isEdit && !userDetails && <Loader />}
				<div className="top-icon d-flex justify-content-center mb-3">
					<label className="icon-round">
						<i className="icon icon-user-1"></i>
					</label>
				</div>
				{renderUserForm()}
			</div>
		</div>
	);
};
const selector = formValueSelector("UserForm");

const mapStateToProps = (state) => ({
	countryList: state.users.countryList,
	reasons: state.users.reasons,
	preferences: state.users.preferences,
	userRoles: state.users.userRoles,
	greekPronouncations: state.users.greekPronouncations,
	hebrewPronouncations: state.users.hebrewPronouncations,
	userDetails: state.users.userDetails,
	isStudentMode: state.studentLanguageLearning.isStudentMode,

	teachingLanguage: selector(state, "teachingLanguage"),
	subscribedLanguage: selector(state, "subscribedLanguage"),
});

export default reduxForm({
	form: "UserForm",
})(withRouter(connect(mapStateToProps)(UserForm)));
