import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { setSideMenu } from "../../../Sidemenu/actions";
import { getUserDetails } from "../../../../utils/verifyUser";
import { getLessonDetails, updateField, changeActivity, getStageDetails } from "../actions";
import SidePanel from "./SidePanel";
import Header from "../../../header";
import Modals from "./modals";
import Loader from "../../../sharedComponents/Loader";
import SharedModal from "../../../sharedComponents/Modal";
import { getSubView, getMainView } from "./helper";
import "./lessonDetails.scss";
import { useTranslation } from "react-i18next";
const Lessons = ({
	menu,
	language,
	history,
	setSideMenu,
	match,
	lessonDetails,
	updateField,
	changeActivity,
	skipActivity,
	getLessonDetails,
	isStudentMode,
	loading,
	getStageDetails,
	sidePanel,
	prevLesson,
}) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const [isIntroPlayng, setIntroPlayng] = useState(true);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
	}, [updateField]);

	useEffect(() => {
		const lessonId = new URLSearchParams(history.location.search).get("lessonId");
		const stageId = new URLSearchParams(history.location.search).get("stageId");
		const activityId = new URLSearchParams(history.location.search).get("activityId");

		if (activityId) {
			const action = new URLSearchParams(history.location.search).get("action");
			changeActivity(action, activityId).then((res) => {
				if (res.statusCode === 200) {
					if (skipActivity) {
						updateField("skipActivity", false);
					}
				} else {
					NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
				}
			});
		} else if (stageId) {
			getStageDetails(stageId, "");
		} else if (lessonId) {
			getLessonDetails(lessonId).then((res) => {
				if (res.statusCode !== 200) {
					history.push(`/home/${match.params.language}/Language-Learning`);
					NotificationManager.error(res.response ? res.response.message : "", "", 3000);
				}
			});
		}
		return () => {
			updateField("lessonDetails", null);
			updateField("sidePanel", {});
		};
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match, getLessonDetails, updateField]);
	const showBadgeNotifications = (lessonDetails) => {
		if (lessonDetails.badge.badge) {
			NotificationManager.success(
				t("languagelearning.lessons.details.notification.badgechangedmsg", { badge: `${lessonDetails.badge.badge}` }),
				"",
				3000
			);
		}
		if (
			lessonDetails.badge.points &&
			lessonDetails.badge.points.length > 0 &&
			!lessonDetails.isLessonCompleted &&
			!lessonDetails.isLevelCompleted
		) {
			NotificationManager.success(
				t("languagelearning.lessons.details.notification.badgegainedmsg", {
					point: `${lessonDetails.badge.points[0].point}`,
					description: `${lessonDetails.badge.points[0].description}`,
				}),
				"",
				3000
			);
		}
	};

	useEffect(() => {
		if (lessonDetails && lessonDetails.badge) {
			showBadgeNotifications(lessonDetails);
		}
	}, [lessonDetails]);

	useEffect(() => {
		const location = history.location.pathname.split("/");
		const lessonNumber = lessonDetails && lessonDetails.displayLessonNumber ? lessonDetails.displayLessonNumber : "";
		setSideMenu({
			menu: [
				{ title: t("menu.languagelearning"), path: `/home/${location[2]}/Language-Learning` },
				{ title: t("languagelearning.lessons.details.sidepanel.lesson", { lessonnumber: lessonNumber }), path: "" },
			],
			language: location[2],
		});
	}, [history, setSideMenu, match, lessonDetails, t]);

	const goTo = (id, lessonNumber, description, stageDescription, lessonId, action) =>
		history.push(
			`/home/${language}/Language-Learning/${lessonNumber}/${description}/${stageDescription}?lessonId=${lessonId}&activityId=${id}&action=${action}`
		);

	const updateAcitivity = (action, id) => {
		const { total, question, lessonNumber, activityId, lessonId, lessonStageId, stage } = lessonDetails;
		updateField("loading", true);
		changeActivity(action, id ? id : activityId).then(({ response, statusCode }) => {
			updateField("loading", false);
			if (statusCode === 200) {
				const { lessonStageId: nextStageId, stage: nextStage } = response;
				const phase = stage === 7 ? "FinalAct" : stage <= 2 ? "Vocabulary" : "Grammar";
				if (skipActivity) {
					updateField("skipActivity", false);
				}
				if (nextStageId !== lessonStageId && action === "next" && stage !== 6 && stage !== 2 && phase !== "FinalAct") {
					const stageDescription =
						nextStage === 0 || nextStage === 4 || nextStage === 1 || nextStage === 5 ? "Passive" : "Active";
					goTo(id ? id : activityId, lessonNumber, phase, stageDescription, lessonId, action);
				} else if (total === question && action === "next" && stage !== 6 && stage !== 2 && phase !== "FinalAct") {
					const stageDescription =
						nextStage === 0 || nextStage === 4 || nextStage === 1 || nextStage === 5 ? "Passive" : "Active";
					goTo(id ? id : activityId, lessonNumber, phase, stageDescription, lessonId, action);
				} else if (action === "backtoassociation" || action === "backtolearning") {
					goTo(id ? id : activityId, lessonNumber, phase, "Association", lessonId, action);
				}
			} else {
				NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	const renderLessonDetails = () => (
		<div className="right">
			<Header
				menu={menu}
				language={language}
				head={t("languagelearning.lessons.details.sidepanel.lesson", {
					lessonnumber: lessonDetails && lessonDetails.displayLessonNumber ? lessonDetails.displayLessonNumber : "",
				})}
				enableLanguageSwitch={true}
			/>
			{lessonDetails && (
				<div className={`word-details-container row ${lessonDetails && lessonDetails.stage !== 0 ? "pt-0" : ""}`}>
					{getMainView(lessonDetails, updateAcitivity, isIntroPlayng, loading)}
					{getSubView(lessonDetails, isStudentMode, updateAcitivity, loading)}
				</div>
			)}
		</div>
	);

	return (
		<div className={`lesson-details-container ${language}`}>
			<Modals
				setIntroPlayng={setIntroPlayng}
				lessonDetails={lessonDetails}
				updateField={updateField}
				updateAcitivity={updateAcitivity}
				loading={loading}
				sidePanel={sidePanel}
				prevLesson={prevLesson}
			/>
			<SharedModal
				show={show}
				modalBody={
					<video height="100%" width="100%" autoPlay controls>
						<source
							src={
								lessonDetails &&
								lessonDetails.activity &&
								lessonDetails.activity.introduction &&
								lessonDetails.activity.introduction.filePath
							}
						/>
					</video>
				}
				handleClose={() => setShow(false)}
			/>
			<div className="d-flex flex-fill">
				{(!lessonDetails || loading) && <Loader />}
				<SidePanel openVideo={() => setShow(true)} isStudentMode={isStudentMode} lessonDetails={lessonDetails} />
				{renderLessonDetails()}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
	lessonDetails: state.studentLanguageLearning.lessonDetails,
	prevLesson: state.studentLanguageLearning.prevLesson,
	loading: state.studentLanguageLearning.loading,
	skipActivity: state.studentLanguageLearning.skipActivity,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	sidePanel: state.studentLanguageLearning.sidePanel,
});

const mapDispatchToProps = {
	setSideMenu,
	getLessonDetails,
	updateField,
	changeActivity,
	getStageDetails,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lessons));
