import React from "react";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import "./pagination.scss";

const SetPagination = ({ activePage, itemsCountPerPage, totalItemsCount, pageRangeDisplayed, onChange }) => {
	const { t } = useTranslation();
	return (
		<div className="pagination-right">
			<div className="d-flex flex-row py-4 align-items-center">
				<Pagination
					activePage={activePage}
					itemsCountPerPage={itemsCountPerPage}
					totalItemsCount={totalItemsCount}
					pageRangeDisplayed={pageRangeDisplayed}
					onChange={onChange}
					itemClass="page-item"
					linkClass="page-link"
					nextPageText={t("helpers.utils.next")}
					prevPageText={t("helpers.utils.previous")}
					lastPageText={t("helpers.utils.next")}
					firstPageText={t("helpers.utils.previous")}
					hideFirstLastPages={true}
				/>
			</div>
		</div>
	);
};

export default SetPagination;
