export const dateValues = {
	0: { label: "Life Time", value: "LifeTime", localizationValue: "lifetime" },
	1: { label: "Today", value: "Today", localizationValue: "today" },
	2: { label: "Yesterday", value: "Yesterday", localizationValue: "yesterday" },
	3: { label: "Last 7 days", value: "ThisPastWeek", localizationValue: "lastsevendays" },
	4: { label: "Last 30 days", value: "ThisPastMonth", localizationValue: "lastthirtydays" },
	5: { label: "Last 6 months", value: "ThisPastSixMonths", localizationValue: "lastsixmonths" },
	6: { label: "Last 12 months", value: "ThisPastYear", localizationValue: "lasttwelvemonths" },
};

export const filterValues = {
	0: { label: "All", value: null, localizationValue: "all" },
	1: { label: "Language Learning", value: 0, localizationValue: "languagelearning" },
	2: { label: "Flashcard", value: 1, localizationValue: "flashcard" },
	3: { label: "Bible Reading", value: 3, localizationValue: "biblereading" },
};

export const getCollectivePath = (value) => {
	const arr = value.split("&");
	let path = "";
	for (let i = 1; i < arr.length; i++) {
		path = path + `&${arr[i]}`;
	}
	return path;
};

export const getPath = (
	url,
	language,
	filter,
	dateFilter,
	isPagingEnabled,
	index,
	size,
	studentId,
	sortBy,
	SortDirection,
	collectiveFilter
) =>
	`${url}${filter !== null ? `?module=${filter}&` : "?"}
	${language ? `Language=${language === "Hebrew" ? 0 : 1}&` : ""}
	${dateFilter ? `DateRange=${dateFilter}` : ""}
	${isPagingEnabled ? `&IsPagingEnabled=${true}&Index=${index}&size=${size}` : ""}
	${
		sortBy
			? `&sortBy=${sortBy}
	${SortDirection ? `&SortDirection=${SortDirection}` : ""}`
			: ""
	}
	${collectiveFilter ? getCollectivePath(collectiveFilter) : ""} `;
