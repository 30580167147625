import { MEDIA_GALLERY } from "./types";

const initialState = {
    gallery: [],
    count: 0
};

export default function(state = initialState, action) {
    switch (action.type) {
        case MEDIA_GALLERY.GET_MEDIA_GALLERY: {
            return {
                ...state,
                gallery: action.payload.response.records,
                count: action.payload.response.count
            };
        }

        default:
            return state;
    }
}
