import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Header from "../../header";
import ActivePhase from "../../sharedComponents/ActivePhase";
import Grammar from "../../sharedComponents/Grammar";
import SharedModal from "../../sharedComponents/Modal";
import Loader from "../../sharedComponents/Loader";

import { setSideMenu } from "../../Sidemenu/actions";
import { getUserDetails } from "../../../utils/verifyUser";
import { updateField } from "../LanguageLearning/actions";
import { getTestDetails, changeActivity, verifyActivity, clearTestDetails } from "./actions";
import testFailed from "../../../assets/images/test-failed.png";
import testPassed from "../../../assets/images/shutterstock.png";
import { useTranslation } from "react-i18next";
import "./placementTest.scss";

const PlacementTest = ({
	updateField,
	setSideMenu,
	history,
	menu,
	isStudentMode,
	getTestDetails,
	testDetails,
	language,
	changeActivity,
	verifyActivity,
	match,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
	}, [updateField]);

	useEffect(() => {
		updateStateField("loading", true);
		getTestDetails(match.params.language).then(() => {
			updateStateField("loading", false);
		});
		return () => {
			dispatch(clearTestDetails());
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [
				{ title: t("menu.languagelearning"), path: `/home/${language}/Language-Learning` },
				{ title: t("languagelearning.lessons.placementtest"), path: "" },
			],
			language: location[2],
		});
	}, [history, setSideMenu, language]);

	useEffect(() => {
		if (testDetails && testDetails.isLessonCompleted) {
			setShowModal(true);
		}

		if (testDetails && testDetails.isCompleted) {
			setShowModal(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [testDetails]);

	const updateStateField = (field, value) => {
		if (field === "loading") {
			setLoading(value);
		}
	};

	const updateActivity = () => {
		updateStateField("loading", true);
		changeActivity(testDetails.activityId).then((res) => {
			updateStateField("loading", false);
			if (res.statusCode === 801) {
				NotificationManager.error("", res.response.message, 3000);
			}
		});
	};

	const redirectTo = () => {
		dispatch(clearTestDetails());
		setShowModal(false);
		history.push(`/home/${language}/placement-test`);
	};

	const renderModalBody = () => {
		const { isTestPassed, lessonNumber, isCompleted } = testDetails;
		return (
			<>
				{testDetails && (
					<div className="text-center">
						<img
							src={isTestPassed || isCompleted ? testPassed : testFailed}
							alt=" "
							className={`${isTestPassed ? "mb-0" : "mb-3"}`}
						/>
						<div style={{ padding: "10px 0px", fontWeight: "bolder", fontSize: 20 }}>
							{isTestPassed || isCompleted
								? t("languagelearning.lessons.details.checktextpassedmodal.congratulations")
								: t("languagelearning.lessons.details.checktextpassedmodal.oops")}
						</div>
						{isTestPassed || isCompleted ? (
							<div className="passed">
								<p>
									{isCompleted
										? t("languagelearning.lessons.details.checktextpassedmodal.unlockedlessonsmsg")
										: t(
												"languagelearning.lessons.details.checktextpassedmodal.continuetaketesttounlocklesson",
												{ lessonnumber: lessonNumber + 1 }
										  )}
								</p>
							</div>
						) : (
							<>
								<p className="bonus-title">
									{t("languagelearning.lessons.details.checktextpassedmodal.atleast5correctansmsg")}
								</p>
								<p className="bonus-title">
									{t("languagelearning.lessons.details.checktextpassedmodal.retaketestconfirm")}
								</p>
							</>
						)}
						{!isCompleted && (
							<Button className={`mt-3 pop-up-btn ${!isTestPassed && "pl-4 pr-4"}`} onClick={redirectTo}>
								{testDetails.isTestPassed
									? t("languagelearning.lessons.details.checktextpassedmodal.continuetest")
									: t("languagelearning.lessons.details.checktextpassedmodal.retake")}
							</Button>
						)}
					</div>
				)}
				<div className="mt-3 d-flex justify-content-center">
					<Link className="link-popup" to={`/home/${language}/Language-Learning`}>
						{t("languagelearning.lessons.details.checktextpassedmodal.backtolanguagelearning")}
					</Link>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="dashboard d-flex placement-test-container empty">
				<div className="right">
					<Header
						menu={menu}
						history={history}
						head={t("languagelearning.lessons.placementtest")}
						enableLanguageSwitch
						redirectionUrl="placement-test"
					/>
					{loading && <Loader />}
					<div className="d-flex details-container row">
						{testDetails && (
							<>
								<Grammar
									updateAcitivity={updateActivity}
									lessonDetails={testDetails}
									notLanguageLearning
									isPlacementTest
								/>
								<ActivePhase
									lessonDetails={testDetails}
									isStudentMode={isStudentMode}
									notLanguageLearning
									updateAcitivity={updateActivity}
									updateField={() => {}}
									verifyActivity={verifyActivity}
								/>
							</>
						)}
					</div>
				</div>
				<SharedModal
					show={showModal}
					handleClose={() => setShowModal(null)}
					modalBody={testDetails && renderModalBody()}
					modalClass="test-result-popup"
					backdrop="static"
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	testDetails: state.placementTest.testDetails,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getTestDetails,
	changeActivity,
	verifyActivity,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlacementTest));
