export const BIBLE = {
  GET_BIBLE_DATA: "GET_BIBLE_DATA",
  UPDATE_FIELD: "UPDATE_FIELD",
  GET_VERSES: "GET_VERSES",
  GET_SENSES: "GET_SENSES",
  ARCHIVE_SENSE: "ARCHIVE_SENSE",
  SEARCH_BIBLE: "SEARCH_BIBLE",
  GET_CUSTOM_DECKS: "GET_CUSTOM_DECKS",
  CREATE_CUSTOM_DECK: "CREATE_CUSTOM_DECK",
  GET_SEARCH_HISTORY: "GET_SEARCH_HISTORY",
  GET_SEARCH_RESULTS: "GET_SEARCH_RESULTS",
  ADD_VOTE: "ADD_VOTE",
  UPDATE_BIBLE_FIELDS: "UPDATE_BIBLE_FIELDS",
  BULK_ARCHIVE: "BULK_ARCHIVE",
  GET_FREQUENCIES: "GET_FREQUENCIES",
  MARK_VERSE_READ: "MARK_VERSE_READ",
  SET_POINTS: "SET_POINTS",
};
