import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getSidePanel, getStageDetails, updateField, handleCollaspingSidePanel } from "../actions";
import LoaderImage from "../../../../assets/images/loader-image.png";
import scoreImg from "../../../../assets/images/score.png";
import ProgressCircle from "../ProgressBar";

const SidePanel = ({
	sidePanel,
	isStudentMode,
	getSidePanel,
	match,
	lessonDetails,
	getStageDetails,
	updateField,
	deckType,
	openVideo,
	history,
	language,
	handleCollaspingSidePanel,
	isSidePanelCollapsed,
}) => {
	const id = new URLSearchParams(history.location.search).get("lessonId") || (match && match.params.id) || null;
	const { t } = useTranslation();
	const [open, setOpen] = useState("");

	useEffect(() => {
		id && lessonDetails && getSidePanel(id, deckType);
	}, [getSidePanel, id, lessonDetails, deckType]);

	useEffect(() => {
		const { vocabulary, grammar } = sidePanel;
		if (sidePanel && vocabulary && vocabulary[2].description === "Completed" && grammar && grammar.length > 0) {
			setOpen(grammar[0].topic);
		}
	}, [sidePanel]);

	const renderSidePanel = () => {
		const vocabulary = [
			{
				name: t("dashboard.vocabulary.name"),
				submenu:
					sidePanel &&
					sidePanel.vocabulary &&
					sidePanel.vocabulary.map((item) => ({
						name: item.stage && item.stage.description,
						status: item.stage && item.status,
						isNavigatable: item.isNavigatable,
						id: item.id,
					})),
				status: sidePanel && sidePanel.vocabulary && sidePanel.vocabulary.status,
			},
		];
		const grammar = [
			{
				name: t("dashboard.grammar.name"),
				submenu:
					sidePanel &&
					sidePanel.grammar &&
					sidePanel.grammar.map((item) => ({
						name: `${item.topic}`,
						topicList:
							item.stages &&
							item.stages.map((i) => ({
								name: i.stage && i.stage.description,
								status: i.status,
								isNavigatable: i.isNavigatable,
								id: i.id,
							})),
					})),
				isFluencyDrillEnabled: sidePanel.isFluencyDrillEnabled,
				hasFluencyDrills: sidePanel.isHavingFluencyDrill,
			},
			{
				name: t("languagelearning.lessons.details.sidepanel.finalact"),
				...(sidePanel &&
					sidePanel.finalAct &&
					sidePanel.finalAct[0] && {
						status: sidePanel.finalAct[0].status,
						id: sidePanel.finalAct[0].id,
						isNavigatable: sidePanel.finalAct[0].isNavigatable,
					}),
				isReadingComprehensionEnabled: sidePanel.isReadingComprehensionEnabled,
				hasReadingComprehension: sidePanel.isHavingReadingComprehension,
			},
		];
		return deckType ? vocabulary : [...vocabulary, ...grammar];
	};

	const renderLevelOverView = () => {
		if ((sidePanel && sidePanel.level) || (lessonDetails && lessonDetails.points)) {
			let value = [];
			if (sidePanel && sidePanel.level) {
				value = [
					{
						value: 100 - sidePanel.level.percentCompleted,
						color: "#89a9b7",
					},
				];
				if (sidePanel.level.percentCompleted !== 0) {
					value.push({
						value: sidePanel.level.percentCompleted,
						color: "#44bbf8",
					});
				}
			}
			return (
				<div className={`level-overview d-flex ${isStudentMode ? "justify-content-center" : "justify-content-around"}`}>
					{sidePanel && sidePanel.level && (
						<div className="d-flex flex-column justify-content-center align-items-center">
							<ProgressCircle
								value={value}
								displayImg={LoaderImage}
								strokeSize={6}
								className="progress-img-container"
							/>
							<span>
								{deckType && deckType !== "lesson"
									? sidePanel.level.name
									: t("languagelearning.lessons.details.sidepanel.lesson", {
											lessonnumber: sidePanel.level.displayLessonNumber,
									  })}
							</span>
							<p className="m-0">
								{t("helpers.utils.sidepanel.percentagecompletion", {
									percentagevalue: sidePanel.level.percentCompleted,
								})}
							</p>
						</div>
					)}
					{lessonDetails && lessonDetails.points && !isStudentMode && (
						<div className="d-flex flex-column justify-content-center align-items-center">
							<ProgressCircle
								value={[
									{
										value: 100,
										color: "#89a9b7",
									},
								]}
								displayImg={scoreImg}
								strokeSize={6}
								className="progress-img-container"
							/>
							<span> {lessonDetails.points.badge}</span>
							<p className="m-0">
								{t("helpers.utils.sidepanel.points", {
									point: lessonDetails.points.points ? Math.round(lessonDetails.points.points) : 0,
								})}
							</p>
						</div>
					)}
				</div>
			);
		}
		return;
	};

	const getIconClassnames = (topic) => {
		return topic.status && topic.status === 2 ? (
			<i className="icon icon-tick icon-active mr-3 ml-1" />
		) : topic.status === 1 ? (
			<i className="icon icon-Oval icon-active mr-3 ml-1" />
		) : (
			<i className="icon icon-Oval icon-inactive mr-3 ml-1" />
		);
	};

	const checkIconStatus = (items) => {
		if (items && items.every((i) => i.status === 0)) {
			return <i className="icon icon-Oval icon-inactive mr-3 ml-1" />;
		} else if (items && items.every((i) => i.status === 2)) {
			return <i className="icon icon-tick icon-active mr-3 ml-1" />;
		} else {
			return <i className="icon icon-Oval icon-active mr-3 ml-1" />;
		}
	};

	const getStageName = (name) => {
		if (name === "Association") {
			return "Association";
		} else if (name === "Passive Knowledge") {
			return "Passive";
		}
		return "Active";
	};
	const getDetails = (stage, titleName) => {
		if (stage && stage.id && stage.isNavigatable) {
			if (match && match.params && match.params.deck) {
				updateField("loading", true);
				getStageDetails(stage.id, deckType).then(() => {
					updateField("loading", false);
				});
			} else {
				history.push(
					`/home/${language}/Language-Learning/${lessonDetails.lessonNumber}/${titleName.replace(
						" ",
						""
					)}/${getStageName(stage.name)}?lessonId=${lessonDetails.lessonId}&stageId=${stage.id}`
				);
			}
		}
	};

	const renderSubMenu = (topic, index, submenu, itemName) => (
		<li className="" key={index}>
			<div
				className={`d-flex align-items-center  mb-3 ${topic.topicList ? "pointer" : ""}`}
				onClick={() => setOpen(open === topic.name ? " " : topic.name)}
				aria-controls={topic.name}
				aria-expanded={open}>
				{topic.topicList ? checkIconStatus(topic.topicList) : getIconClassnames(topic)}
				<span
					className={`vocabulary-name ${topic.isNavigatable ? "pointer" : ""}`}
					onClick={() => getDetails(topic, itemName)}>
					{topic.name}
				</span>
			</div>
			{topic.topicList && (
				<Collapse in={open === topic.name}>
					<div className="pl-5" id={topic.name}>
						{topic.topicList &&
							topic.topicList.map((topicAssociation) => (
								<span
									key={topicAssociation.name}
									className={`pl d-flex mb-3 topic-levels ${
										topicAssociation.status === 1 && "topic-active"
									} ${topicAssociation.isNavigatable && "pointer"}`}
									onClick={() => getDetails(topicAssociation, itemName)}>
									{topicAssociation.name}
								</span>
							))}
					</div>
				</Collapse>
			)}
		</li>
	);

	const checkStatus = (items) => {
		if (items.every((i) => i.status === 0)) {
			return "menu-list position-relative";
		} else if (items.every((i) => i.status === 2)) {
			return "menu-list position-relative active-ul status-completed";
		} else if (items.some((i) => i.status === 1)) {
			return "menu-list position-relative active-ul";
		} else {
			return "menu-list position-relative";
		}
	};

	const getMenuClassName = (item) => {
		const name = item.name;
		switch (name) {
			case t("dashboard.vocabulary.name"): {
				if (item && item.submenu) {
					return checkStatus(item.submenu);
				}
				return "menu-list position-relative";
			}
			case t("dashboard.grammar.name"): {
				if (item && item.submenu) {
					let options = [];
					item.submenu.forEach((element) => {
						options = [...options, ...element.topicList];
					});
					return checkStatus(options);
				}
				return "menu-list position-relative";
			}
			case t("languagelearning.lessons.details.sidepanel.finalact"): {
				return checkStatus([item]);
			}
			default:
				return "menu-list position-relative ";
		}
	};

	const VideoButton = () => (
		<ul className="menu-list position-relative active-ul">
			<div className="d-flex align-items-center flex-column mb-4 pointer" onClick={openVideo}>
				<div className="d-flex justify-content-center align-items-center menu-index video-player-btn">
					<i className="icon icon-video-camera " />
				</div>
				<p className="m-0 " style={{ width: "120px", textAlign: "center" }}>
					{t("languagelearning.lessons.details.sidepanel.introductionvideo")}
				</p>
			</div>
		</ul>
	);

	const LessonGuideButton = () => (
		<a
			className="lesson-guide-btn"
			href={lessonDetails && lessonDetails.guide.filePath}
			target="_blank"
			rel="noopener noreferrer">
			<ul className="menu-list position-relative active-ul">
				<div className="d-flex align-items-center flex-column mb-4 pointer">
					<div className="d-flex justify-content-center align-items-center  menu-index video-player-btn">
						<i className="icon icon-dictionary-icon" />
					</div>
					<p className="m-0 " style={{ width: "90px", textAlign: "center" }}>
						{t("languagelearning.lessons.details.sidepanel.lessonguide")}
					</p>
				</div>
			</ul>
		</a>
	);

	const renderMenuSection = () =>
		renderSidePanel().map((item, index) => (
			<>
				<ul className={getMenuClassName(item)} key={index}>
					<div className="d-flex align-items-center mb-4">
						<div className="d-flex justify-content-center align-items-center menu-index">
							<span className="level-count">{index + 1}</span>
							<label className="level-status">
								<i className="icon icon-tick" />
							</label>
						</div>
						<p
							className={`m-0 ml-3 ${item.id && item.isNavigatable ? "pointer" : ""}`}
							onClick={() => getDetails(item, item.name)}>
							{item.name}
						</p>
					</div>
					{item.submenu && item.submenu.map((topic, index) => renderSubMenu(topic, index, item.submenu, item.name))}
				</ul>
				{item.name === t("languagelearning.lessons.details.sidepanel.grammar") ||
					("Tata Bahasa" && item.hasFluencyDrills && (
						<div
							className={`fluency-drill-link ${
								item.isFluencyDrillEnabled && "active"
							}  d-flex justify-content-start`}
							onClick={() => {
								if (item.isFluencyDrillEnabled) {
									history.push(
										`/home/${language}/fluency-drill/learning/${lessonDetails.lessonId}?stageId=${sidePanel.finalAct[0].id}`
									);
								}
							}}>
							{t("languagelearning.lessons.details.completedmodal.gotofluencydrills")}
						</div>
					))}

				{item.name === t("languagelearning.lessons.details.sidepanel.finalact") && item.hasReadingComprehension && (
					<div
						className={`reading-comprehension-link ${
							item.isReadingComprehensionEnabled && "active"
						}  d-flex justify-content-start`}
						onClick={() => {
							if (item.isReadingComprehensionEnabled) {
								history.push(
									`/home/${language}/reading-comprehension/learning/${lessonDetails.lessonId}?stageId=${sidePanel.finalAct[0].id}`
								);
							}
						}}>
						{t("languagelearning.lessons.details.completedmodal.gotoreadingcomprehension")}
					</div>
				)}
			</>
		));

	return (
		<div className={` side-panel ${isSidePanelCollapsed && "collapseSidePanel"} `}>
			{renderLevelOverView()}
			<div className="sidepanel-padding">
				<div className="d-flex justify-content-between side-panel-top-section">
					{lessonDetails &&
						lessonDetails.activity &&
						lessonDetails.activity.introduction &&
						lessonDetails.activity.introduction.filePath && <VideoButton />}
					{lessonDetails && lessonDetails.guide && lessonDetails.guide.filePath && <LessonGuideButton />}
				</div>
				{renderMenuSection()}
			</div>

			<button className="collapsingSidePanelBtn" onClick={() => handleCollaspingSidePanel()}>
				<i className="icon icon-menu" />
			</button>
		</div>
	);
};
const mapDispatchToProps = {
	getSidePanel,
	getStageDetails,
	updateField,
	handleCollaspingSidePanel,
};

const mapStateToProps = (state) => ({
	sidePanel: state.studentLanguageLearning.sidePanel,
	deckType: state.studentLanguageLearning.deckType,
	language: state.sideMenu.language,
	isSidePanelCollapsed: state.studentLanguageLearning.isSidePanelCollapsed,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidePanel));
