import { BIBLE } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";

export const getBibleData = (url) => {
	const fetchOptions = {
		url: `bible/${url}`,
		method: "GET",
		actionType: BIBLE.GET_BIBLE_DATA,
	};

	return fetchHandler(fetchOptions);
};

export const getSearchResult = (term, language) => {
	const fetchOptions = {
		url: `bible/words?SearchTerm=${term}&Language=${language}&IsPagingEnabled=false`,
		method: "GET",
		actionType: BIBLE.GET_SEARCH_RESULTS,
	};

	return fetchHandler(fetchOptions);
};

export const getSenses = (strongsNumber, language, SenseID, osisID) => {
	const fetchOptions = {
		url: `bible/word/senses?StrongsNumber=${strongsNumber}&Language=${language}${SenseID ? `&SenseID=${SenseID}` : ""}${
			osisID ? `&OsisID=${osisID}` : ""
		}`,
		method: "GET",
		actionType: BIBLE.GET_SENSES,
	};

	return fetchHandler(fetchOptions);
};

export const getVerseList = (strongsNumber, language, index = 0) => {
	const fetchOptions = {
		url: `bible/word/occurrences?StrongsNumber=${strongsNumber}&Language=${language}&Index=${index}`,
		method: "GET",
		actionType: BIBLE.GET_VERSES,
	};

	return fetchHandler(fetchOptions);
};

export const bulkArchiveWords = (language, frequency) => {
	const fetchOptions = {
		url: `bible/sense/bulkarchive?Language=${language}&Frequency=${frequency}`,
		method: "PUT",
		actionType: BIBLE.BULK_ARCHIVE,
	};

	return fetchHandler(fetchOptions);
};

export const getSearchHistory = (language) => {
	const fetchOptions = {
		url: `searchhistory/all?Language=${language}`,
		method: "GET",
		actionType: BIBLE.GET_SEARCH_HISTORY,
	};

	return fetchHandler(fetchOptions);
};

export const getCustomDecks = (language) => {
	const fetchOptions = {
		url: `customdeck/all?Language=${language}`,
		method: "GET",
		actionType: BIBLE.GET_CUSTOM_DECKS,
	};

	return fetchHandler(fetchOptions);
};

export const createCustomDecks = (payload) => {
	const fetchOptions = {
		url: `customdeck/addword`,
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: BIBLE.CREATE_CUSTOM_DECK,
	};

	return fetchHandler(fetchOptions);
};

export const addVote = (strongsNumber, isVoted, senseId, osisID, language) => {
	const fetchOptions = {
		url: `sense/${isVoted ? "down" : "up"}vote?StrongsNumber=${strongsNumber}&SenseID=${senseId}&Language=${language}${
			osisID ? `&OsisID=${osisID}` : ""
		}`,
		method: isVoted ? "DELETE" : "POST",
		actionType: BIBLE.GET_SENSES,
	};

	return fetchHandler(fetchOptions);
};

export const searchBible = (language, type, term, strongsNumber) => {
	const fetchOptions = {
		url: `bible/search?Language=${language}&SearchParamType=${type}&SearchTerm=${term}${
			strongsNumber ? `&StrongsNumber=${strongsNumber}` : ""
		}`,
		method: "GET",
		actionType: BIBLE.SEARCH_BIBLE,
	};

	return fetchHandler(fetchOptions);
};

export const archiveSense = (url, strongsNumber, osisID, language, method) => {
	const fetchOptions = {
		url: `bible/sense/${url}StrongsNumber=${strongsNumber}&Language=${language}${osisID ? `&OsisID=${osisID}` : ""}`,
		method,
		actionType: BIBLE.GET_SENSES,
	};

	return fetchHandler(fetchOptions);
};

export const updateBibleField = (field, value) => ({
	type: BIBLE.UPDATE_FIELD,
	field,
	value,
});

export const updateBibleFields = (data) => ({
	type: BIBLE.UPDATE_BIBLE_FIELDS,
	data,
});

export const getFrequencies = (language) => {
	const fetchOptions = {
		url: `bible/word/frequencies/?Language=${language}`,
		method: "GET",
		actionType: BIBLE.GET_FREQUENCIES,
	};

	return fetchHandler(fetchOptions);
};

export const markVerseRead = (bookId, chapter, from, to) => {
	const fetchOptions = {
		url: `bible/verses/markasread?BookId=${bookId}&Chapter=${chapter}&FromVerse=${from}&ToVerse=${to}`,
		method: "PUT",
		actionType: BIBLE.MARK_VERSE_READ,
	};

	return fetchHandler(fetchOptions);
};

export const unMarkVerseRead = (bookId, chapter, from, to) => {
	const fetchOptions = {
		url: `bible/verses/markasunread?BookId=${bookId}&Chapter=${chapter}&FromVerse=${from}&ToVerse=${to}`,
		method: "PUT",
		actionType: BIBLE.MARK_VERSE_READ,
	};

	return fetchHandler(fetchOptions);
};

export const setPoints = (points) => ({
	type: BIBLE.SET_POINTS,
	points,
});
