import React, { useState } from "react";
import PlaceHolderImg from "../../assets/images/placeholder-image.png";
import SharedModal from "./Modal";

const Lesson = ({ senseImages, showImg, handleClose }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handlePrevImage = () => {
    setSlideIndex(slideIndex - 1);
  };

  const handleNextImage = () => {
    setSlideIndex(slideIndex + 1);
  };

  const renderModalBody = () => {
    return (
      <div className="d-flex image-slider">
        {slideIndex !== 0 && (
          <i className="icon icon-prev m-auto p-2" onClick={handlePrevImage} />
        )}
        <img
          src={
            senseImages &&
            senseImages.length > 0 &&
            senseImages[slideIndex].filePath
              ? senseImages[slideIndex].filePath
              : PlaceHolderImg
          }
          alt="NoImage"
          className="sliderimg"
          onError={() => `this.src=${PlaceHolderImg};`}
        />
        {slideIndex + 1 !== senseImages.length && (
          <i
            className="icon icon-next m-auto p-2"
            onClick={handleNextImage}
          ></i>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <SharedModal
        show={showImg}
        handleClose={handleClose}
        modalBody={renderModalBody()}
        modalClass="zoom-image-wrapper"
        icon="icon-close-2"
      />
    </React.Fragment>
  );
};

export default Lesson;
