import { isEqual } from "lodash";

const bookReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_BOOKS":
      if (state[action.field].hasNext) {
        action.callBack(
          action.field,
          state[action.field].index + 1,
          state.selectedBook ? state.selectedBook.id : "",
          state.filter
        );
      }
      return { ...state };
    case "SET_FILTER":
      action.callBack(action.field, 0, "", action.value);
      return { ...state, filter: action.value, [action.field]: {} };
    case "SET_SELECTED_BOOK":
      return { ...state, selectedBook: action.payload };
    case "REMOVE_BOOK_DETAILS":
      return { ...state, [action.field]: action.value };
    case "SET_BOOKS":
      let bibleBooks = { ...action.payload };
      if (state[action.field].records && action.payload.records) {
        if (!isEqual(state.latestData, action.payload.records)) {
          bibleBooks.records = [
            ...state[action.field].records,
            ...action.payload.records,
          ];
        } else {
          bibleBooks.records = [...state[action.field].records];
        }
      }
      return {
        ...state,
        [action.field]: bibleBooks,
        latestData: action.payload.records,
      };
    default:
      return state;
  }
};

export default bookReducer;
