import { HOME } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";

export function updateField(field, val) {
  return {
    type: HOME.UPDATE_FIELD,
    field,
    val,
  };
}

export const getLanguageFileStatus = () => {
  const fetchOptions = {
    url: `language/status`,
    method: "GET",
    actionType: HOME.GET_FILE_STATUS,
  };

  return fetchHandler(fetchOptions);
};
export const setStudentPreference = (payload) => {
  const fetchOptions = {
    url: `student/preference`,
    method: "PUT",
    body: JSON.stringify(payload),
    actionType: HOME.SET_STUDENT_PREFERENCE,
  };

  return fetchHandler(fetchOptions);
};
