import { USERS } from "./types";
const initialState = {
	userRoles: [],
	preferences: [],
	addUser: false,
	isEdit: false,
	editProfile: false,
	userDetails: null,
	cultures: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case USERS.FETCH_USERS:
			return {
				...state,
				list: action.payload.response.records,
			};
		case USERS.FETCH_USER_DETAILS:
			return {
				...state,
				userDetails: action.payload.response,
			};
		case USERS.FETCH_COUNTRY_LIST:
			return {
				...state,
				countryList: action.payload.response,
			};
		case USERS.FETCH_LEARNING_REASONS:
			return {
				...state,
				reasons: action.payload.response,
			};
		case USERS.FETCH_LANGUAGE_PREFERENCE:
			return {
				...state,
				preferences: action.payload.response,
			};
		case USERS.FETCH_GREEK_PRONOUNCATION:
			return {
				...state,
				greekPronouncations: action.payload.response,
			};
		case USERS.FETCH_HEBREW_PRONOUNCATION:
			return {
				...state,
				hebrewPronouncations: action.payload.response,
			};
		case USERS.FETCH_USER_ROLES:
			return {
				...state,
				userRoles: action.payload.response,
			};
		case USERS.GET_CLASSROOM_DATA:
			return {
				...state,
				classRooms: action.payload.response,
			};
		case USERS.UPDATE_FIELD:
			return {
				...state,
				[action.field]: action.val,
			};
		case USERS.FETCH_CULTURES:
			return {
				...state,
				cultures: action.payload.response,
			};
		default:
			return state;
	}
}
