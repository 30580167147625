import React, { useReducer, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { Modal } from "react-bootstrap";

import Close from "../../assets/images/close.png";
import { changeType, setLessonNumber } from "../Submenu/actions";
import { fetchAnswers, postAnswer, updateAnswer, fetchQuestionsList } from "./actions";
import Loader from "../sharedComponents/Loader";
import { fieldUpdateReducer } from "../../utils/helper";
import SharedModal from "../sharedComponents/Modal";
import { useTranslation } from "react-i18next";

const QaDetails = ({
	show,
	close,
	questionDetails,
	answerList,
	postAnswer,
	fetchAnswers,
	fetchQuestionsList,
	updateAnswer,
	isAdmin,
	userId,
	changeType,
	setLessonNumber,
	history,
}) => {
	const initialState = {
		answerValue: "",
		postingAnswer: false,
		submitting: false,
		answerId: "",
		action: "",
		showModal: false,
	};
	const [state, dispatch] = useReducer(fieldUpdateReducer, initialState);
	const { answerValue, postingAnswer, answerId, showModal, action, submitting } = state;
	const textAreaRef = useRef(null);
	const {t} = useTranslation();

	const saveAnswer = () => {
		dispatch({ postingAnswer: true });
		const url = `answer/${answerId ? "edit" : "post"}`;
		const method = answerId ? "PUT" : "POST";
		const payload = {
			answer: answerValue,
		};
		if (answerId) {
			payload.answerId = answerId;
		} else {
			payload.questionId = questionDetails.id;
		}
		postAnswer(payload, url, method).then((res) => {
			dispatch({ postingAnswer: false, answerValue: "", answerId: "" });
			handleResponse(res, "submitted");
		});
	};

	const onModalClose = () => {
		dispatch({ showModal: false, answerId: "", action: "" });
	};

	const updateAnswerDetails = () => {
		dispatch({ submitting: true });
		const url = `answer/${action === "delete" ? "delete" : "verify"}/${answerId}`;
		const method = action === "delete" ? "DELETE" : "PUT";
		updateAnswer(url, method).then((res) => {
			dispatch({
				submitting: false,
				answerId: "",
				action: "",
				showModal: false,
			});
			handleResponse(res, action === "delete" ? "deleted" : "verified");
		});
	};

	const handleResponse = (res, notifyText) => {
		if (res.statusCode === 200) {
			fetchAnswers(questionDetails.id);
			fetchQuestionsList();
			NotificationManager.success(
				`${t("helpers.utils.answer.singular")} ${notifyText} ${t("helpers.utils.successfully")}`,
				"",
				3000
			);
		} else {
			NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
		}
	};

	const setAction = (action, id) => {
		dispatch({
			showModal: true,
			action: action,
			answerId: id,
		});
	};

	const editAnswer = (answer) => {
		if (textAreaRef.current) {
			textAreaRef.current.focus();
			textAreaRef.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
			});
		}
		dispatch({
			answerValue: answer.description,
			answerId: answer.id,
		});
	};

	const redirectToLesson = () => {
		if (questionDetails.language && questionDetails.questionType) {
			const sub = questionDetails.questionType.description === "Grammar" ? "grammar" : "vocabulary";
			changeType("lessons");
			setLessonNumber(questionDetails.lessonNumber);
			localStorage.setItem("redirectToLesson", true);
			history.push(`/home/${questionDetails.language.description}/${sub}`);
		}
	};

	const renderForm = () => (
		<div className="form-body d-flex flex-column">
			<label className="question-title">{questionDetails.title}</label>
			<textarea
				rows="5"
				placeholder={t("helpers.utils.typeyouranswer")}
				className="answer-input"
				ref={textAreaRef}
				onChange={(e) => dispatch({ answerValue: e.target.value })}
				value={answerValue}></textarea>
			<div className="d-flex justify-content-end">
				{answerValue && (
					<button className="btn btn-default" onClick={() => dispatch({ answerValue: "", answerId: "" })}>
						{t("helpers.utils.caps.clear")}
					</button>
				)}
				<button className="btn btn-primary" disabled={postingAnswer || !answerValue} onClick={() => saveAnswer()}>
					{answerId ? t("helpers.utils.update") : t("helpers.utils.submit")}
				</button>
			</div>
		</div>
	);

	const renderAnswerActions = (answer) => {
		const isEditable = isAdmin || (!answer.isVerified && answer.answeredByUserID === userId);
		const isDeletable = isAdmin || (!answer.isVerified && userId === answer.answeredByUserID);
		return (
			<div className="d-flex flex-column flex-fill align-items-center answer-actions">
				{answer.isVerified ? (
					<div className="best-answer">{t("helpers.utils.learning.askquestion.bestanswer")}</div>
				) : (
					<div
						className={`mark-best-answer ${isAdmin ? "pointer" : "not-allowed"}`}
						{...(isAdmin && { onClick: () => setAction("verify", answer.id) })}>
						{t("qa.markbestans")}
					</div>
				)}
				<div className="d-flex justify-content-center">
					<span
						className={`icon-round mr-3 ${isEditable ? "pointer" : "not-allowed"} `}
						{...(isEditable && { onClick: () => editAnswer(answer) })}>
						<i className="icon icon-write "></i>
					</span>
					<span
						className={`icon-round  ${isDeletable ? "pointer" : "not-allowed"}`}
						{...(isDeletable && {
							onClick: () => setAction("delete", answer.id),
						})}>
						<i className="icon icon-delete "></i>
					</span>
				</div>
			</div>
		);
	};

	const renderAnswerList = () => (
		<div className="answers-list">
			{answerList.map((answer) => (
				<div key={answer.id} className="answer-details d-flex">
					{answer.answeredBy && (
						<span className="logo">
							<i className="icon">{answer.answeredBy.charAt(0)}</i>
						</span>
					)}
					<div className="d-flex flex-column data-section">
						<label className="user-name">{answer.answeredBy}</label>
						{answer.createdOn && (
							<label className="aswered-time">
								{t("qa.addedanswreron", {
									date: moment(answer.createdOn).format(" DD MMM YYYY [at] h:mm A z"),
								})}
							</label>
						)}
						<p className="answer-value">{answer.description}</p>
					</div>
					{renderAnswerActions(answer)}
				</div>
			))}
		</div>
	);

	const renderModalBody = () => {
		return (
			<div className="text-center">
				<span>{action === "delete" ? t("qa.confirmdeleteans") : t("qa.confirmbestans")}</span>
			</div>
		);
	};

	const renderModalFooter = () => {
		return (
			<div className="mb-2  delete-modal-footer">
				<button className="btn btn-success mr-3" disabled={submitting} onClick={updateAnswerDetails}>
					{t("helpers.utils.yes")}
				</button>
				<button className="btn btn-danger " onClick={onModalClose}>
					{t("helpers.utils.no")}
				</button>
			</div>
		);
	};

	const renderTitleFields = () => (
		<div className="d-flex title-fields">
			<label className="mr-4 d-flex">
				{t("helpers.utils.lessonno")}
				<span className="lesson-number pointer" onClick={redirectToLesson}>
					{questionDetails.lessonNumber}
				</span>
			</label>
			{questionDetails.questionType && (
				<label className="mr-4 d-flex">
					{questionDetails.questionType.description === "Grammar" ? "Ordering Location" : "Strongs Number"}:
					<span className="ml-2">
						{questionDetails.questionType.description === "Grammar"
							? questionDetails.orderingLocation
							: questionDetails.strongsNumber}
					</span>
				</label>
			)}
			<label className="mr-4 d-flex">
				{t("helpers.utils.asked")} :
				<span className="ml-2">
					{questionDetails.askedOn ? moment(questionDetails.askedOn).format("DD MMM YYYY") : ""}
				</span>
			</label>
		</div>
	);

	return (
		<div>
			{show && !questionDetails && <Loader />}
			<SharedModal
				show={showModal}
				disableHeader={true}
				modalBody={renderModalBody()}
				modalFooter={renderModalFooter()}
				handleClose={onModalClose}
				modalClass="confirmation-modal panel-top-modal"
			/>
			<Modal show={show} onHide={close} id="qa-details-modal">
				{questionDetails && (
					<Modal.Body>
						<div className="header">
							<div className="d-flex">
								<span className="logo">
									<i className="icon icon-question-1"></i>
								</span>
								<div className="qa-form">
									{renderTitleFields()}
									{renderForm()}
								</div>
							</div>
						</div>
						<div className="body">
							{answerList && (
								<div className="answer-count">
									{answerList.length}
									{`${
										answerList.length > 1
											? t("helpers.utils.answer.plural")
											: t("helpers.utils.answer.singular")
									}`}
								</div>
							)}
							{answerList && answerList.length > 0 && renderAnswerList()}
						</div>
					</Modal.Body>
				)}
				<button className="btn-close" onClick={close}>
					<img src={Close} alt="" />
				</button>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	questionDetails: state.QA.questionDetails,
	answerList: state.QA.answerList,
});

const mapDispatchToProps = {
	postAnswer,
	fetchAnswers,
	updateAnswer,
	changeType,
	setLessonNumber,
	fetchQuestionsList,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QaDetails));
