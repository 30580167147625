import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import Header from "../../header";
import Loader from "../../sharedComponents/Loader";
import SharedModal from "../../sharedComponents/Modal";

import { setSideMenu } from "../../Sidemenu/actions";
import { getUserDetails } from "../../../utils/verifyUser";
import { updateField } from "../LanguageLearning/actions";
import { getDetails, changeActivity, verifyActivity } from "./actions";
import complete from "../../../assets/images/complete.png";

import { Card, Row, Col, Tabs, Tab, Form, Button, Popover, OverlayTrigger } from "react-bootstrap";
import { ToggleButton } from "../../sharedComponents/toggleButton";
import "./details.scss";
import { useTranslation } from "react-i18next";

const Details = ({
	updateField,
	setSideMenu,
	history,
	menu,
	isStudentMode,
	language,
	match,
	getDetails,
	changeActivity,
	verifyActivity,
}) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [lessonDetails, setLessonDetails] = useState();
	const [activeTab, setActiveTab] = useState(t('helpers.utils.learning.typing'));
	const [word, setAnswer] = useState("");
	const [answer, setMCQ] = useState("");
	const [focused, setFocused] = useState(false);
	const [audio, setAudio] = useState("");
	const [showText, setShowText] = useState(true);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
	}, [updateField]);

	useEffect(() => {
		updateStateField("loading", true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateStateField("loading", true);
		const url = match.params.from === "learning" ? "readingcomprehension" : "readingcomprehensiondeck";
		getDetails(url, match.params.id).then((res) => {
			if (res.statusCode === 200) {
				if (res.response) {
					setLessonDetails(res.response);
				}
			}
			updateStateField("loading", false);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (lessonDetails && (lessonDetails.isLessonCompleted || lessonDetails.isCompleted)) {
			setShowModal(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessonDetails]);

	useEffect(() => {
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: t(lessonDetails ? lessonDetails.name : "languagelearning.readingcomprehension.name"), path: `/home/${language}/Reading-comprehension` }],
			language: location[2],
		});
	}, [history, setSideMenu, lessonDetails, language, t]);

	const updateStateField = (field, value) => {
		if (field === "loading") {
			setLoading(value);
		}
	};

	const updateAnswer = (val) => {
		let isPrefferedLanguage = val ? false : true;
		if (val) {
			if (language === "Hebrew") {
				isPrefferedLanguage = /^[ \u0590-\u05FF\W|_ ]+$/.test(val);
			} else {
				isPrefferedLanguage = /^[ \u0370-\u03ff\u1f00-\u1fff\W|_ ]+$/.test(val);
			}
		}
		if (isPrefferedLanguage) {
			setAnswer(val);
		} else if (
			!(
				NotificationManager.listNotify &&
				NotificationManager.listNotify.length > 0 &&
				NotificationManager.listNotify.find(
					(msg) => msg.message === t("helpers.utils.notification.languageerr", { language: language })
				)
			)
		) {
			NotificationManager.error(t("helpers.utils.notification.languageerr", { language: language }), "");
		}
	};

	const updateActivity = async () => {
		updateStateField("loading", true);
		const url = match.params.from === "learning" ? "readingcomprehensionactivity" : "readingcomprehensiondeckactivity";
		const { activityId } = lessonDetails;

		const { response, statusCode } = await changeActivity(activityId, url);
		if (statusCode === 200) {
			setLessonDetails(response);
		}
		updateStateField("loading", false);
	};

	const renderModalBody = () => {
		const {
			location: { search },
		} = history;
		return (
			<>
				{lessonDetails && (
					<div className="text-center">
						<img src={complete} alt=" " className="mb-3" />
						<p className="bonus-title" style={{ fontSize: "14px" }}>
							{t("languagelearning.readingcomprehension.details.completedmodal.completedcomprehensionmsg")}
						</p>
						{match.params.from !== "learning" && (
							<p className="bonus-title" style={{ fontSize: "14px" }}>
								{t("languagelearning.readingcomprehension.details.completedmodal.percentagescoredmsg", {
									percentagescored: lessonDetails.percentageScored,
								})}
							</p>
						)}
					</div>
				)}

				<div className="mt-3 d-flex justify-content-center">
					{match.params.from === "learning" ? (
						<Link
							className="link-popup"
							to={`/home/${language}/Language-Learning/${lessonDetails.lessonNumber}/FinalAct/Active?lessonId=${
								lessonDetails.lessonId
							}${search ? `&stageId=${search.split("=")[1]}` : ""}`}>
							{t("languagelearning.readingcomprehension.details.completedmodal.backtolearning")}
						</Link>
					) : (
						<Link className="link-popup" to={`/home/${language}/Reading-comprehension`}>
							{t("languagelearning.readingcomprehension.details.completedmodal.backtoreadingcomprehension")}
						</Link>
					)}
				</div>

				{match.params.from === "learning" && (
					<div className="mt-3 d-flex justify-content-center">
						<Link
							className="link-popup"
							to={`/home/${language}/Language-Learning/${lessonDetails.nextLessonId}/Vocabulary/Association?lessonId=${lessonDetails.nextLessonId}`}>
							{t("languagelearning.readingcomprehension.details.completedmodal.continuetonextlesson")}
						</Link>
					</div>
				)}

				<div className="mt-3 d-flex justify-content-center">
					<Link className="link-popup" to={`/home/${language}/Dashboard`}>
						{t("languagelearning.readingcomprehension.details.completedmodal.gotodashboard")}
					</Link>
				</div>
			</>
		);
	};

	const stopAllAudio = () => {
		const sounds = document.getElementsByTagName("audio");
		for (let i = 0; i < sounds.length; i++) {
			sounds[i].pause();
		}
	};

	const togglePlay = (pronunciation) => {
		if (pronunciation.filePath) {
			const audioData = pronunciation.fileName;
			const player = document.getElementById(audioData);
			if (player.paused) {
				stopAllAudio();
				setAudio(audioData);
				player.play();
			} else {
				player.pause();
				setAudio("");
				stopAllAudio();
			}
		} else {
			!(NotificationManager.listNotify && NotificationManager.listNotify.length > 0) &&
				NotificationManager.error(t("helpers.utils.notification.audionotavailablemsg"), "", 3000);
		}
	};

	const renderVoiceComponent = (pronunciation) => {
		return (
			<div className="change-icon  d-flex">
				<Button
					onClick={() => togglePlay(pronunciation)}
					className={`audio-dropdown lesson-audio ${
						audio === pronunciation.fileName ? "is-playing" : "not-playing"
					}`}>
					<i className={"icon-audio"}></i>
				</Button>
				<audio
					src={pronunciation.filePath}
					id={pronunciation.fileName}
					className="d-none"
					onEnded={() => setAudio("")}
				/>
			</div>
		);
	};

	const toggleText = () => {
		setShowText(!showText);
	};

	const comprehensionBlock = () => {
		const { activity } = lessonDetails;
		const { grammar } = activity ? activity : {};
		const { defaultPronunciation, story, readingStoryTitle } = grammar ? grammar : {};

		return (
			<>
				<Row
					className={`p-4 ${language === "Hebrew" ? "hebrew-title" : ""}`}
					style={{ justifyContent: "space-between" }}>
					<div className="heading-wrapper d-flex">
						<div className={`comprehension-title mr-4 ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
							{readingStoryTitle}
						</div>
						<div className="comprehension-audio">
							{defaultPronunciation && renderVoiceComponent(defaultPronunciation)}
						</div>
					</div>
					<div className="text-toggle">
						<ToggleButton
							onToggle={toggleText}
							selected={showText}
							title={`${showText ? "Hide story" : "Show story"}`}
						/>
					</div>
				</Row>
				<Row className={`p-4 ${language === "Hebrew" ? "justify-content-end" : ""}`}>
					<div
						className={`w-100 comprehension-text custom-scroll ${
							language === "Hebrew" ? "Hebrew-font" : "Greek-font"
						} ${showText ? "" : "text-blur"}`}>
						{story &&
							story.map((s, idx) => {
								return <p key={idx}>{s}</p>;
							})}
					</div>
				</Row>
			</>
		);
	};

	const handleVerifyResponse = (res) => {
		if (res.response.isSuccess) {
			fetchNextActivity(2000);
			NotificationManager.success(t("helpers.utils.notification.correct"), "", 3000);
		} else {
			fetchNextActivity(2000);
			NotificationManager.error(t("helpers.utils.notification.wrong"), "", 3000);
		}
	};

	const fetchNextActivity = (time) => {
		setTimeout(() => {
			updateActivity();
		}, time);
	};

	const verifyAnswer = (option) => {
		updateField("loading", true);
		const { activityId } = lessonDetails;
		const url = match.params.from === "learning" ? "readingcomprehensionactivity" : "readingcomprehensiondeckactivity";
		const payload = {
			id: activityId,
			answer: activeTab === "Typing" ? word : answer,
			isWordBankUsed: false,
			isSpeakingUsed: false,
			IsPronunciationMistake: false,
			isSuccess: false,
			audioFile: null,
		};
		verifyActivity(payload, url).then((res) => {
			updateField("loading", false);
			if (res.statusCode === 200) {
				setAnswer("");
				setMCQ("");
				handleVerifyResponse(res);
			} else {
				NotificationManager.error(
					`${
						res && res.response && res.response.message
							? res.response.message
							: t("helpers.utils.notification.error")
					}`,
					"",
					3000
				);
			}
		});
	};

	const submitWord = (e, word) => {
		if (e.which === 13 && word) {
			e.preventDefault();
			verifyAnswer();
		}
	};

	const renderInputBox = () => {
		return (
			<Form>
				<Form.Group>
					<Form.Control
						className={`input-reading w-100 mt-1 ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}
						type="text"
						placeholder={t("languagelearning.readingcomprehension.details.enteranswer")}
						value={word}
						onKeyPress={(e) => submitWord(e, word)}
						onChange={(e) => updateAnswer(e.target.value)}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
					/>
				</Form.Group>
			</Form>
		);
	};

	const renderMultipleChoice = (options) => {
		return (
			<React.Fragment>
				<Form className="p-3">
					<Form.Row>
						<Form.Group>
							{options &&
								options.map((option, index) => {
									return (
										<div key={index} className="answer-block">
											<Form.Check
												className={`mt-1`}
												type="radio"
												id={`option-${index}`}
												name={"options"}
												checked={answer === option}
												onChange={() => {
													setMCQ(option);
												}}
											/>
											<Form.Label
												className={`${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}
												style={{ fontSize: "26px" }}>
												<span>{index + 1}</span>
												<span>.</span>
												<span>{option}</span>
											</Form.Label>
										</div>
									);
								})}
						</Form.Group>
					</Form.Row>
				</Form>
			</React.Fragment>
		);
	};

	const clearWord = () => {
		if (activeTab === "Typing") {
			setAnswer("");
		} else {
			setMCQ("");
		}
	};

	const renderActionButtons = () => (
		<div className="d-flex flex-column justify-content-end" style={{ height: "100%" }}>
			<div className="d-flex justify-content-end actions">
				{((activeTab === t("helpers.utils.learning.typing") && word) ||
					(activeTab === t("languagelearning.readingcomprehension.details.multiplechoice") &&
						answer &&
						answer.length > 0)) && (
					<button className="btn btn-default cancel-text" onClick={clearWord}>
						{t("helpers.utils.cancel")}
					</button>
				)}
				<button
					className="btn btn-primary submit-text mt-1"
					disabled={
						(activeTab === t("helpers.utils.learning.typing") && !word) ||
						(activeTab === t("languagelearning.readingcomprehension.details.multiplechoice") &&
							(!answer || answer.length === 0))
					}
					onClick={verifyAnswer}>
					{t("helpers.utils.submit")}
				</button>
			</div>
		</div>
	);

	const renderTabContent = (tab) => {
		const { options } = lessonDetails.activity ? lessonDetails.activity : {};
		const mcqOptions = options && options.map((option) => option.option);
		return (
			<React.Fragment>
				<Row className="answers-wrapper">
					<Col className={`p-0 pl-3`}>
						{tab === t("helpers.utils.learning.typing")
							? renderInputBox()
							: renderMultipleChoice(mcqOptions)}
					</Col>
					<Col className="p-0" style={{ marginTop: 2 }}>
						{renderActionButtons()}
					</Col>
				</Row>
			</React.Fragment>
		);
	};

	const renderGloss = (gloss) => {
		return (
			<OverlayTrigger
				trigger="click"
				placement="top"
				rootClose={true}
				key={"gloss"}
				overlay={
					<Popover id="popover-basic" className="lang-dtls-pop">
						<Popover.Content>
							<span
								dangerouslySetInnerHTML={{
									__html: gloss,
								}}></span>
						</Popover.Content>
					</Popover>
				}>
				<div className="d-flex align-items-center pointer">
					<span className="more-4 pointer">
						<i className={`icon-Group`}></i>
					</span>
				</div>
			</OverlayTrigger>
		);
	};

	const questionBlock = () => {
		const { activity, question, total } = lessonDetails;
		const { gloss, readingStoryQuestion, readingStoryImagePath, readingStoryImageLabel } =
			activity && activity.grammar ? activity.grammar : {};
		return (
			<>
				<div className="progress-block">
					{t("helpers.utils.learning.progress", { currentlesson: question, totallessons: total })}
				</div>
				<div className="image-wrapper">
					<img
						className="question-image"
						src={readingStoryImagePath && readingStoryImagePath}
						alt={readingStoryImageLabel && readingStoryImageLabel}
					/>
				</div>
				<div className={`question-text-block ${language}`}>
					<span className={`p-2 d-flex question-text ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
						<span>{question}</span>
						<span>.</span>
						<span>{readingStoryQuestion}</span>
					</span>
					{renderGloss(gloss)}
				</div>
				<div className={`active-phase ${language} position-relative`}>
					<Tabs className={`word-bank-tab`} activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
						<Tab
							eventKey={t("languagelearning.readingcomprehension.details.multiplechoice")}
							title={t("languagelearning.readingcomprehension.details.multiplechoice")}
							as="div">
							{renderTabContent(t("languagelearning.readingcomprehension.details.multiplechoice"))}
						</Tab>
						<Tab eventKey={t('helpers.utils.learning.typing')} title={t("helpers.utils.learning.typing")} as="div">
							{renderTabContent(t('helpers.utils.learning.typing'))}
						</Tab>
					</Tabs>
				</div>
			</>
		);
	};

	return (
		<div className="dashboard d-flex reading-comprehension-container ">
			<div className="empty">
				<Header
					menu={menu}
					history={history}
					head={t("languagelearning.readingcomprehension.name")}
					enableLanguageSwitch
					redirectionUrl="Reading Comprehension"
				/>
				{match.params.from !== "learning" ? (
					<div className="ml-3">
						<button
							className="btn btn-default list-redirect-btn"
							onClick={() => history.push(`/home/${language}/reading-comprehension`)}>
							<i className="icon icon-left-arrow-1"></i>
							{t("languagelearning.readingcomprehension.details.gotoreadingcomprehension")}
						</button>
					</div>
				) : (
					""
				)}
				{loading && <Loader />}
				{lessonDetails && (
					<Row className="content-wrapper">
						<Card>
							<Col lg={6} className="content-block comprehension-block">
								{comprehensionBlock()}
							</Col>
							<Col lg={6} className="content-block question-block">
								{questionBlock()}
							</Col>
						</Card>
					</Row>
				)}
			</div>
			<SharedModal
				show={showModal}
				handleClose={() => setShowModal(null)}
				modalBody={lessonDetails && renderModalBody()}
				modalClass="test-result-popup"
				backdrop="static"
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getDetails,
	changeActivity,
	verifyActivity,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
