import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

import { updateBibleField, bulkArchiveWords, getFrequencies } from "./actions";
import SharedModal from "../../sharedComponents/Modal";
import { useTranslation } from "react-i18next";

const BulkArchive = ({ updateBibleField, bulkArchiveWords, language, getFrequencies, archiveModal, setArchiveModal }) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [frequency, setFrequency] = useState("");
	const [frequencyList, setFrequencyList] = useState([]);
	const [wordCount, setWordCount] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		getFrequencies(language).then(({ statusCode, response }) => {
			if (statusCode === 200) {
				setFrequencyList(response);
			}
		});
	}, [getFrequencies, language]);

	const closeModal = () => {
		setArchiveModal(false);
		setShowConfirmationModal(false);
		setSubmitting(false);
		setFrequency("");
		updateBibleField("removeListener", false);
	};

	const archiveWords = () => {
		setSubmitting(true);
		bulkArchiveWords(language, frequency).then((res) => {
			if (res.statusCode === 200) {
				closeModal();
				updateBibleField("fetchBibleData", true);
				NotificationManager.success(t("biblereading.bulkarchivemodal.notification.successfullarchive"), "", 3000);
			}
		});
	};

	const onSubmit = () => {
		const { count } = frequencyList.find((element) => element.frequency >= frequency) || 0;
		setShowConfirmationModal(true);
		setWordCount(count);
	};

	const keyPress = (e) => {
		const badValues = [69, 107, 109, 187, 189, 110, 190, 38, 40];
		if (badValues.includes(e.keyCode)) {
			e.preventDefault();
		}
	};

	const renderModalBody = () => (
		<div className="bulk-archive-modal d-flex flex-column">
			<input
				type="number"
				value={frequency}
				className="frequency form-control"
				placeholder={t("biblereading.bulkarchivemodal.enterfrequency")}
				onChange={(e) => setFrequency(e.target.value)}
				onKeyDown={keyPress}
				onFocus={() => updateBibleField("removeListener", true)}
				onBlur={() => updateBibleField("removeListener", false)}
			/>
			<div className="d-flex justify-content-end">
				<button className="btn btn-default" onClick={closeModal}>
					{t("helpers.utils.cancel")}
				</button>
				<button
					className="btn btn-primary"
					disabled={!frequency || (frequency && (!Number(frequency) || isNaN(Number(frequency)))) || submitting}
					onClick={onSubmit}>
					{t("helpers.utils.submit")}
				</button>
			</div>
		</div>
	);

	const renderConfirmationModalBody = () => {
		return (
			<div className="text-center">
				{wordCount ? (
					<span>
						{t("biblereading.bulkarchivemodal.bulkarchiveconfirmation", {
							wordcount: `${wordCount}`,
							frequency: `${frequency}`,
						})}
					</span>
				) : (
					<span>{t("biblereading.bulkarchivemodal.bulkarchivemsg", { frequency: `${frequency}` })}</span>
				)}
			</div>
		);
	};

	const renderConfirmationModalFooter = () => {
		return (
			<div className="mb-2 delete-modal-footer">
				{wordCount ? (
					<>
						<button
							className="btn btn-cancel mr-3"
							onClick={() => setShowConfirmationModal(false)}
							disabled={submitting}>
							{t("helpers.utils.no")}
						</button>
						<button className="btn btn-upload" disabled={submitting} onClick={archiveWords}>
							{t("helpers.utils.yes")}
						</button>
					</>
				) : (
					<button
						className="btn btn-cancel mr-3"
						onClick={() => setShowConfirmationModal(false)}
						disabled={submitting}>
						{t("helpers.utils.close")}
					</button>
				)}
			</div>
		);
	};

	return (
		<React.Fragment>
			<SharedModal
				show={archiveModal}
				modalBody={renderModalBody()}
				handleClose={closeModal}
				modalClass="no-header bulk-archive-modal"
			/>
			<SharedModal
				show={showConfirmationModal}
				disableHeader={true}
				modalBody={renderConfirmationModalBody()}
				modalFooter={renderConfirmationModalFooter()}
				modalClass="confirmation-modal"
			/>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	bulkArchiveWords,
	updateBibleField,
	getFrequencies,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkArchive);
