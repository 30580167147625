import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Tabs, Tab, Dropdown } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { NotificationManager } from "react-notifications";
import Creatable from "react-select/creatable";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import noData from "../../../assets/images/no-data.png";
import { overlay } from "./helper";
import { AudioButton } from "../../sharedComponents/audioButton";
import { NoContent } from "../../sharedComponents/NoContent";
import { toggleAudioPlay } from "../../../utils/helper";
import SharedModal from "../../sharedComponents/Modal";
import Verses from "./verses";
import { updateBibleField, getSenses, archiveSense, getCustomDecks, createCustomDecks, addVote } from "./actions";

const Senses = ({
	senses,
	language,
	isSenseLoading,
	isLoading,
	updateBibleField,
	strongsNumber,
	osisID,
	getSenses,
	archiveSense,
	getCustomDecks,
	createCustomDecks,
	addVote,
	decks,
	removeListener,
	currentTab,
}) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(0);
	const [flashCardModal, setFlashCardModal] = useState(false);
	const [isAddingToDeck, setIsAddingToDeck] = useState(false);
	const [isArchiving, setIsArchiving] = useState(false);
	const [isAllArchiving, setIsAllArchiving] = useState(false);
	const [isVoting, setIsVoting] = useState(false);
	const [isDeckCreating, setIsDeckCreating] = useState(false);
	const [currentSense, setCurrentSense] = useState(null);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [isAudioPlaying, setIsAudioPlaying] = useState([]);
	const [audioSpeed, setAudioSpeed] = useState([]);
	const [sensesList, setSenseList] = useState([]);
	const [isArchived, setIsArchived] = useState(false);
	const [isVotingDisabled, setIsVotingDisabled] = useState(false);
	const [deckGroups, setDeckGroups] = useState([]);
	const [decksList, setDecksList] = useState([]);
	const [deckName, setDeckName] = useState(null);
	const [deckGroupName, setdeckGroupName] = useState(null);
	const [activeParentTab, setActiveParentTab] = useState(t("biblereading.sense.name"));
	const tabList = [t("biblereading.sense.name"), t("biblereading.reading.verse")];
    
	const groupList = deckGroups && deckGroups.map((deck,index) => ({ label: deck.label, value: index }));

	const fields = [
		{
			label: t("helpers.fields.construct"),
			field: "construct",
		},
		{
			label: t("helpers.fields.dual"),
			field: "dual",
		},
		{
			label: t("helpers.fields.plural"),
			field: "plural",
		},
		{
			label: t("helpers.fields.dualconstruct"),
			field: "dualConstruct",
		},
		{
			label: t("helpers.fields.pluralconstruct"),
			field: "pluralConstruct",
		},
		{
			label: t("helpers.fields.qatal"),
			field: "qatal",
		},
		{
			label: t("helpers.fields.weqatal"),
			field: "weqatal",
		},
		{
			label: t("helpers.fields.yiqtol"),
			field: "yiqtol",
		},
		{
			label: "Wyqtl",
			field: "wayyiqtol",
		},
		{
			label: "Part",
			field: "participle",
		},
		{
			label: "Inf Abs",
			field: "infinitiveAbsolute",
		},
		{
			label: "Inf Con",
			field: "infinitiveConstruct",
		},

		{
			label: "Impv",
			field: "imperative",
		},
		{
			label: "Jsv",
			field: "jussive",
		},
		{
			label: "Coh",
			field: "cohorative",
		},
		{
			label: "Adj Forms",
			field: "adjForms",
		},
		{
			label: "Gen",
			field: "genN",
		},
		{
			label: "PresMP",
			field: "presMP",
		},
		{
			label: "Impv",
			field: "imperfect",
		},
		{
			label: "ImpvMP",
			field: "imperfectMP",
		},
		{
			label: "Aor",
			field: "aorist",
		},
		{
			label: "AorMid",
			field: "aorMid",
		},
		{
			label: "AorPsv",
			field: "aorPasv",
		},
		{
			label: "Fut",
			field: "future",
		},
		{
			label: "FutMid",
			field: "futureMid",
		},
		{
			label: "FutPsv",
			field: "futurePasv",
		},
		{
			label: "Perf",
			field: "perfect",
		},
		{
			label: "PerfMid",
			field: "perfMid",
		},
		{
			label: "Adj Forms",
			field: "nomFNAdj",
		},
	];
	const grammaticalInfo = [
		{
			label: t("helpers.grammaticalinfo.category"),
			field: "category",
		},
		{
			label: t("helpers.grammaticalinfo.grammaticalforms"),
			field: "grammaticalForms",
		},
		{
			label: t("helpers.grammaticalinfo.root"),
			field: "greekRoot",
		},
		{
			label: t("helpers.grammaticalinfo.root"),
			field: "hebrewRoot",
		},
		{
			label: "Argument Realizations",
			field: "argumentRealizations",
		},
		{
			label: t("helpers.grammaticalinfo.binyan"),
			field: "binyan",
		},
		{
			label: t("helpers.grammaticalinfo.gender"),
			field: "gender",
		},
	];


	const lexicalFields = [
		{
			label: t("helpers.utils.gloss"),
			field: "gloss",
		},
		{
			label: t("helpers.utils.definition"),
			field: "definition",
		},
		{
			label: t("helpers.lexicalfields.semanticdomains"),
			field: "semanticDomain",
		},
	];
	useEffect(() => {
		if (senses && senses.response && senses.response.senses && senses.response.senses.length) {			
				getCustomDecks(language);
			const sense = senses.response.senses;
			setSenseList(sense);
			setIsArchived(senses.response.isArchived);
			setIsVotingDisabled(senses.response.isVotingDisabled);
			setCurrentTab(sense);
			const audioStatusArray = [...Array(sense.length).keys()].map(() => false);
			setIsAudioPlaying(audioStatusArray);
			const audioSpeedArray = [...Array(sense.length).keys()].map(() => 1);
			setAudioSpeed(audioSpeedArray);
		} else {
			setSenseList([]);
			setIsArchived(false);
		}
		setShowConfirmationModal(false);
		setFlashCardModal(false);
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [senses]);

	useEffect(() => {
		if (isAllArchiving || isArchiving || isVoting) {
			updateBibleField("isWaitingResponse", true);
		} else {
			updateBibleField("isWaitingResponse", false);
		}
	}, [isArchiving, isAllArchiving, isVoting, updateBibleField]);

	const setCurrentTab = (senses) => {
		let tab = 0;
		if (currentSense) {
			const index = senses.findIndex((sense) => sense.senseID === currentSense);
			tab = index ? index : 0;
			setCurrentSense(null);
		} else {
			const index = senses.findIndex((sense) => sense.ishighlighted);
			tab = index ? index : 0;
		}
		setActiveTab(tab);
	};

	useEffect(() => {
		if (decks && decks.response) {
			const groups = decks.response.length ? decks.response.map((deck) => ({ label: deck.group })) : [];
			setDeckGroups(groups);
		}
	}, [decks]);

	useEffect(() => {
		if (sensesList.length && !flashCardModal && !removeListener) {
			document.addEventListener("keydown", onKeyDown);
		} else if (flashCardModal || removeListener) {
			document.removeEventListener("keydown", onKeyDown);
		}
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		sensesList,
		activeTab,
		isAudioPlaying,
		flashCardModal,
		showConfirmationModal,
		isArchiving,
		isAllArchiving,
		isVoting,
		isAddingToDeck,
		removeListener,
		currentTab,
	]);

	const onKeyDown = (e) => {
		const keyCode = e.keyCode;
		if (!isLoading && !isSenseLoading) {
			if (keyCode === 76) {
				playAudio();
			} else if (e.shiftKey && keyCode === 70) {
				setFlashCardModal(true);
			} else if (keyCode === 70 && decks && decks.response && decks.response.length > 0) {
				setShowConfirmationModal("addToRecentDeck");
			} else if (e.shiftKey && keyCode === 65 && !isAllArchiving) {
				archiveWord("archive", true);
			} else if (keyCode === 65 && !isArchiving) {
				archiveWord("archive", false);
			} else if ((keyCode > 48 && keyCode < 58) || (keyCode > 96 && keyCode < 106)) {
				openSenseTab(keyCode);
			} else if (keyCode === 86 && !isVoting && currentTab !== "Search") {
				archiveWord("upVote");
			} else if (keyCode === 38 || keyCode === 40) {
				e.preventDefault();
				const action = keyCode === 38 ? "prev" : "next";
				setTab(action);
			} else if (keyCode === 13 && showConfirmationModal && !isAddingToDeck) {
				archiveWord();
			}
		}
	};

	const openSenseTab = (keyCode) => {
		let tabNumber = 0;
		if (keyCode > 48 && keyCode < 58) {
			tabNumber = keyCode - 49;
		} else {
			tabNumber = keyCode - 97;
		}
		if (tabNumber < sensesList.length) {
			setTab(tabNumber);
		}
	};

	const playAudio = () => {
		if (
			!isAudioPlaying[activeTab] &&
			sensesList[activeTab] &&
			sensesList[activeTab].pronunciations &&
			sensesList[activeTab].pronunciations.length > 0 &&
			sensesList[activeTab].pronunciations[0].filePath
		) {
			toggleAudio(true, activeTab);
		}
	};

	const toggleAudio = (isPlay, tabNumber) => {
		const setAudioPlay = (play) => {
			const playStatus = [...isAudioPlaying];
			playStatus[tabNumber] = play ? true : false;
			setIsAudioPlaying(playStatus);
		};
		if (isPlay) {
			toggleAudioPlay(`sense ${tabNumber}`, setAudioPlay, audioSpeed[tabNumber]);
		} else {
			setAudioPlay(false);
		}
	};

	const changeAudioSpeed = (speed, tabNumber) => {
		const speedList = [...audioSpeed];
		speedList[tabNumber] = speed;
		setAudioSpeed(speedList);
	};

	const setTab = (tab) => {
		let currentTab = tab;
		if (tab === "prev") {
			currentTab = activeTab === 0 ? activeTab : activeTab - 1;
		} else if (tab === "next") {
			currentTab = activeTab === sensesList.length - 1 ? activeTab : activeTab + 1;
		}
		const selectedTab = document.querySelectorAll(`[data-rb-event-key="${currentTab}"]`);
		selectedTab[0].scrollIntoView({
			behavior: "smooth",
			block: "nearest",
		});
		setActiveTab(currentTab);
	};

	const handleClose = () => {
		setDeckName(null);
		setdeckGroupName(null);
		setFlashCardModal(false);
	};

	const setSubmitingStatus = (type, value) => {
		switch (type) {
			case "archive":
				setIsArchiving(value);
				break;
			case "archiveAll":
				setIsAllArchiving(value);
				break;
			case "upVote":
				setIsVoting(value);
				break;
			default:
				setIsAddingToDeck(value);
				break;
		}
	};

	const archiveWord = (action, archiveAll) => {
		const actionType = action === "archive" && archiveAll ? "archiveAll" : action;
		setSubmitingStatus(actionType, true);
		setCurrentSense(sensesList[activeTab].senseID);
		if (action === "archive") {
			archiveWordSense(archiveAll, actionType);
		} else if (action === "upVote") {
			addVote(strongsNumber, sensesList[activeTab].isVoted, sensesList[activeTab].senseID, osisID, language).then((res) =>
				handleSenseResponse(res, `Sense ${sensesList[activeTab].isVoted ? "down" : "up"}voted`, actionType)
			);
		} else {
			if (decks && decks.response && decks.response.length) {
				const recentDeck = decks.response[0];
				const payload = {
					group: recentDeck.group || "",
					name: recentDeck.names && recentDeck.names.length ? recentDeck.names[0] : "",
					senseID: sensesList[activeTab] ? sensesList[activeTab].senseID : "",
					language: language === "Hebrew" ? 0 : 1,
				};
				addToCustomDeck(payload);
			} else {
				NotificationManager.error(t("biblereading.sense.notification.nocustomdeckerrmsg"), "", 3000);
				setIsAddingToDeck(false);
			}
		}
	};

	const archiveWordSense = (archiveAll, actionType) => {
		let url = "";
		let method = "";
		if (archiveAll) {
			method = isArchived ? "DELETE" : "PUT";
			url = `${isArchived ? "un" : ""}archiveall?`;
		} else {
			method = sensesList[activeTab].isArchived ? "DELETE" : "PUT";
			url = `${sensesList[activeTab].isArchived ? "un" : ""}archive?SenseID=${sensesList[activeTab].senseID}&`;
		}
		archiveSense(url, strongsNumber, osisID, language, method).then((res) =>
			handleSenseResponse(
				res,
				`${archiveAll ? t("biblereading.senses.allsenses") : t("biblereading.sense.name")} ${(archiveAll && isArchived) || (!archiveAll && sensesList[activeTab].isArchived) ? t("biblereading.sense.unarchive") : t("biblereading.sense.archive")
				}`
				,
				actionType
			)
		);
	};

	const handleSenseResponse = (res, text, actionType) => {
		if (res.statusCode === 200) {
			setSubmitingStatus(actionType, false);
			updateBibleField("fetchBibleData", true);
			NotificationManager.success(t("biblereading.sense.notification.successfullmsg", { text: text }), "", 3000);
		}
	};

	const onDeckChange = (value) => {
		setdeckGroupName(value);
		if (!value.__isNew__ && decks && decks.response && decks.response.length) {
			const group = decks.response.find((item) => item.group === value.label);
			if (group && group.names && group.names.length) {
				const names = group.names.map((name) => ({ label: name }));
				const filteredNames = sensesList[activeTab].decks
					? names.filter((el) => !sensesList[activeTab].decks.map((ele) => ele.name).includes(el.label))
					: names;
				setDecksList(filteredNames);
			}
		}
	};

	const createDeck = () => {
		setCurrentSense(sensesList[activeTab].senseID);
		const payload = {
			group: deckGroupName ? deckGroupName.label : "",
			name: deckName ? deckName.label : "",
			senseID: sensesList[activeTab] ? sensesList[activeTab].senseID : "",
			language: language === "Hebrew" ? 0 : 1,
		};
		setIsDeckCreating(true);
		addToCustomDeck(payload);
	};

	const addToCustomDeck = (payload) => {
		createCustomDecks(payload).then((res) => {
			if (res.statusCode === 200) {
				updateBibleField("fetchBibleData", true);
				getSenses(strongsNumber, language, osisID ? "" : sensesList[activeTab].senseID, osisID).then(() => {
					handleClose();
					setIsAddingToDeck(false);
					setFlashCardModal(false);
					setIsDeckCreating(false);
					setShowConfirmationModal(false);
					NotificationManager.success(t("biblereading.sense.notification.successfullyaddedsensemsg"), "", 3000);
				});
			} else {
				NotificationManager.error(res.response ? res.response.message : t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	const renderModalBody = () => {
		return (
			<div>
				<div className=" d-flex justify-content-center relative">
					<label className="title">{t("biblereading.sense.addtocustomdeck")}</label>
					<span className="close-btn pointer" onClick={handleClose}>
						<i className="icon icon-close "></i>
					</span>
				</div>
				<div className="d-flex flex-column mt-2">
					<label className="field-label">{t("biblereading.bibleindex.modal.selectoraddcustomdeckgroup")}</label>
					<Creatable
						noOptionsMessage={() => t("helpers.utils.nooptions")}
						loadingMessage={() => t("helpers.utils.learning.askquestion.loading")}
						placeholder={t("biblereading.select")}
						value={deckGroupName && deckGroupName}
						onChange={(e) => {
							setdeckGroupName(e);
						}}
						options={groupList && groupList}
						className="react-select"
						classNamePrefix="react-select"
					/>

				</div>
				<div className="d-flex flex-column mt-2">
					<label className="field-label">{t("biblereading.bibleindex.modal.selectoraddcustomdeckname")}</label>
					<Creatable
						noOptionsMessage={() =>t("helpers.utils.nooptions")}
						loadingMessage={t("helpers.utils.learning.askquestion.loading")}
						placeholder={t("biblereading.select")}
						value={deckName && deckName}
						onChange={(e) => {setDeckName(e);}}
						options={deckName && deckName}
						className="react-select"
						classNamePrefix="react-select"
					/>
				</div>
				<div className="mt-3 d-flex justify-content-end">
					<button
						className="btn btn-primary"
						disabled={isDeckCreating || !deckName || !deckGroupName}
						onClick={createDeck}>
						{t("helpers.utils.add")}
					</button>
				</div>
			</div>
		);
	};

	const renderTopSection = (sense, tabNumber) => (
		<div className="d-flex justify-content-between mt-2 align-items-center">
			<div className="d-flex">
				<span className={`sense-name ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
					{sense.senseFormDictionary}
				</span>
				{sense.pronunciations && sense.pronunciations.length > 0 && sense.pronunciations[0].filePath && (
					<AudioButton
						url={sense.pronunciations[0].filePath}
						id={`sense ${tabNumber}`}
						isAudioPlaying={isAudioPlaying[tabNumber]}
						audioSpeed={audioSpeed[tabNumber]}
						onSpeedChange={(speed) => changeAudioSpeed(speed, tabNumber)}
						togglePlay={(type) => toggleAudio(type, tabNumber)}
					/>
				)}
			</div>
			<div className="d-flex align-items-center">
				<span
					className={`archive-sense ${isArchiving ? "is-archiving" : ""}`}
					{...(!isArchiving && {
						onClick: () => archiveWord("archive", false),
					})}>
					{sense.isArchived ? t("biblereading.sense.unarchive") : t("biblereading.sense.archive")}
				</span>
				<Dropdown>
					<Dropdown.Toggle className="btn btn-default btn-sense">+ {t("biblereading.sense.addto")}</Dropdown.Toggle>
					<Dropdown.Menu className="custom-deck-drop">
						<Dropdown.Item onClick={() => setFlashCardModal(true)}>{t("menu.customdecks")}</Dropdown.Item>
						{decks && decks.response && decks.response.length > 0 && (
							<Dropdown.Item onClick={() => setShowConfirmationModal("addtorecentdeck")}>
								{t("biblereading.sense.mostrecentcustomdeck")}
							</Dropdown.Item>
						)}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>
	);

	const getVales = (values) => {
		const list = [];

		fields.forEach((el) => {
			if (values[el.field]) {
				list.push({ label: el.label, value: values[el.field] });
			}
		});

		if (list.length > 0) {
			return (
				<div key="form" className="d-flex flex-column field-details">
					<label className="field-name">{t("helpers.grammaticalinfo.grammaticalforms")}</label>
					<span className={`field-value  d-flex flex-wrap`}>
						{list.map((el, index) => (
							<div key={index} className="inline-block">
								<span>{el.label}</span>.
								<span className={`${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} `}>
									{`${el.value}${index !== list.length - 1 ? "," : ""} `}&nbsp;
								</span>
							</div>
						))}
					</span>
				</div>
			);
		}

		return null;
	};

	const renderTabContent = (sense, tabNumber) => (
		<div className="senses-details">
			{renderTopSection(sense, tabNumber)}
			<div className="position-relative">
				{sense.picSource &&
					overlay(
						<div>
							<span>{sense.picSource}</span>
						</div>,
						t("biblereading.senses.overlay.imgsource")
					)}
				{sense.illustrations && sense.illustrations.length > 0 && sense.illustrations[0].filePath ? (
					<img src={sense.illustrations[0].filePath} className="senses-img" alt="" />
				) : (
					<div className="sense-bg">{sense.gloss || ""}</div>
				)}
			</div>

			<Row className="d-flex ml-0 mr-0">
				<Col xs={6}>
					<div className="title-sense-info mb-3">{t("biblereading.sense.lexicalinformation")}</div>
					{lexicalFields.map(
						(field) =>
							sense[field.field] && (
								<div key={field.label} className="d-flex flex-column field-details">
									<label className="field-name">{field.label}</label>
									<span
										className={`field-value ${field.field === "definition"
											? `${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`
											: ""
											}`}>
										{sense[field.field]}
									</span>
								</div>
							)
					)}
					{sense.culturalInfo && (
						<div className="d-flex flex-column field-details">
							<label className="field-name">{t("biblereading.sense.culturalinfo")}</label>
							<div className="d-flex flex-column ex-wrapper">
								<span
									className={`ref `}
									dangerouslySetInnerHTML={{
										__html: sense.culturalInfo,
									}}></span>
							</div>
						</div>
					)}
					{sense.examples && sense.examples.length > 0 && (
						<div className="d-flex flex-column field-details">
							<label className="field-name">{t("languagelearning.lessons.details.examples")}</label>
							{sense.examples.map((example) => (
								<div className="d-flex flex-column ex-wrapper" key={example.verse}>
									<label className="verse">{example.verse}</label>
									<span
										className={`ref  ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} ${language === "Hebrew" ? "d-rtl" : ""
											}`}
										dangerouslySetInnerHTML={{
											__html: example.reference,
										}}></span>
								</div>
							))}
						</div>
					)}
				</Col>
				<Col xs={6}>
					<div className="title-sense-info mb-3">{t("helpers.grammaticalinfo.grammaticalforms")}</div>
					{grammaticalInfo.map(
						(field) =>
							sense[field.field] &&
							(field.field === "grammaticalForms" ? (
								getVales(sense[field.field])
							) : (
								<div key={field.label} className="d-flex flex-column field-details">
									<label className="field-name">{field.label}</label>
									<span
										className={`field-value ${field.field === "argumentRealizations" ||
											field.field === "hebrewRoot" ||
											field.field === "greekRoot"
											? `${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`
											: ""
											}`}>
										{sense[field.field]}
									</span>
								</div>
							))
					)}
				</Col>
			</Row>

			{!isVotingDisabled && (
				<div className="like-count">
					<i
						className={`icon icon-thumbs-up ${isVoting ? "is-archiving" : ""} ${sense.isVoted ? "disable" : "enable"
							}`}
						{...(!isVoting && { onClick: () => archiveWord("upVote") })}></i>
					<label>
						{sense.votes} {sense.votes > 1 ? t("biblereading.sense.vote.plural") : t("biblereading.sense.vote")}
					</label>
				</div>
			)}
		</div>
	);

	const renderSenses = () => (
		<div className="d-flex relative">
			{sensesList.length > 1 && (
				<div className="tab-prev pointer" onClick={() => setTab("prev")}>
					<i className="icon-prev"></i>
				</div>
			)}
			<div className="senses-tab-container">
				<Tabs
					className={`senses-tab ${sensesList.length > 1 ? "multiple" : ""}`}
					activeKey={activeTab}
					onSelect={(tab) => setTab(parseInt(tab))}>
					{sensesList.map((sense, index) => (
						<Tab
							key={index}
							eventKey={index}
							title={
								<div className="d-flex justify-content-between no-wrap">
									{t("biblereading.sense.sense", { tabnumber: index + 1 })}
									{sense && sense.contentType !== 0 && (
										<>
											{sense.contentType === 3 ? (
												<>
													<span className="tab-icon">
														<i className="icon icon-audio"></i>
													</span>
													<span className="tab-icon">
														<i className="icon icon-image-4"></i>
													</span>
												</>
											) : (
												<span className="tab-icon">
													<i
														className={`icon ${sense.contentType === 1 ? "icon-audio" : "icon-image-4"
															}`}></i>
												</span>
											)}
										</>
									)}
								</div>
							}
							as="div">
							{renderTabContent(sense, index)}
						</Tab>
					))}
				</Tabs>
			</div>
			{sensesList.length > 1 && (
				<div className="tab-next pointer" onClick={() => setTab("next")}>
					<i className="icon-next"></i>
				</div>
			)}
		</div>
	);

	const renderContent = (tab) =>
		tab === t("biblereading.sense.name") ? (
			sensesList.length > 0 ? (
				<>
					{renderSenses()}
					<div className="d-flex justify-content-end mt-3">
						<button style={{ width: '14%', wordWrap: 'normal' }}
							className={`btn btn-default btn-sense archive ${isAllArchiving ? "archiving" : ""}`}
							{...(!isAllArchiving && {
								onClick: () => archiveWord("archive", true),
							})}>
							{isArchived ? t("biblereading.sense.unarchiveall") : t("biblereading.sense.archiveall")}
						</button>
					</div>
				</>
			) : (
				<NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
			)
		) : (
			<Verses />
		);

	const isSenseAlreadyAdded = () => {
		const recentDeck = (decks && decks.response && decks.response.length && decks.response[0]) || undefined;
		if (recentDeck && sensesList && sensesList[activeTab] && sensesList[activeTab].decks) {
			return sensesList[activeTab].decks.map((el) => el.name).includes(recentDeck.names[0]);
		}
		return false;
	};

	const renderConfirmationModalBody = () => {
		return (
			<div className="text-center">
				{isSenseAlreadyAdded() ? (
					<span>{t("biblereading.bibleindex.modal.wordalreadypresentmsg")}</span>
				) : (
					<span>{t("biblereading.bibleindex.modal.wordaddtorecentdeckconfirm")}</span>
				)}
			</div>
		);
	};

	const renderConfirmationModalFooter = () => {
		return (
			<div className="mb-2 delete-modal-footer">
				{isSenseAlreadyAdded() ? (
					<button
						className="btn btn-cancel mr-3"
						onClick={() => setShowConfirmationModal(false)}
						disabled={isAddingToDeck}>
						{t("helpers.utils.close")}
					</button>
				) : (
					<>
						<button
							className="btn btn-cancel mr-3"
							onClick={() => setShowConfirmationModal(false)}
							disabled={isAddingToDeck}>
							{t("helpers.utils.no")}
						</button>
						<button className="btn btn-upload" disabled={isAddingToDeck} onClick={archiveWord}>
							{t("helpers.utils.yes")}
						</button>
					</>
				)}
			</div>
		);
	};

	return (
		<div className="senses-container ">
			<i className="icon icon-close-2 close-icon" onClick={() => updateBibleField("removeSelection", true)}></i>
			<Tabs className="default-tabs" activeKey={activeParentTab} onSelect={(tab) => setActiveParentTab(tab)}>
				{tabList.map((tabName) => (
					<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
						<Scrollbars>{isSenseLoading ? null : renderContent(tabName)}</Scrollbars>
					</Tab>
				))}
			</Tabs>
			<SharedModal
				show={showConfirmationModal}
				disableHeader={true}
				modalBody={renderConfirmationModalBody()}
				modalFooter={renderConfirmationModalFooter()}
				handleClose={() => setShowConfirmationModal(false)}
				modalClass="confirmation-modal"
			/>
			<SharedModal
				show={flashCardModal}
				modalBody={renderModalBody()}
				handleClose={() => { }}
				modalClass="no-header add-flashcard-modal"
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	senses: state.bible.senses,
	language: state.sideMenu.language,
	isSenseLoading: state.bible.isSenseLoading,
	isLoading: state.bible.isLoading,
	removeSelection: state.bible.removeSelection,
	strongsNumber: state.bible.strongsNumber,
	osisID: state.bible.osisID,
	decks: state.bible.decks,
	removeListener: state.bible.removeListener,
	currentTab: state.bible.currentTab,
});

const mapDispatchToProps = {
	updateBibleField,
	getSenses,
	archiveSense,
	getCustomDecks,
	createCustomDecks,
	addVote,
};

export default connect(mapStateToProps, mapDispatchToProps)(Senses);
