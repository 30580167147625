import React, { useReducer, useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { verifyActivity, updateField } from "../actions";
import { fieldUpdateReducer } from "../../../../utils/helper";
import { playAudioHelper } from "./helper";
import AudioSettingsList from "./audioSettings";
import grammarSymbolGlossary from "../../../../assets/files/Grammar_Symbols_Glossary.pdf";
import { togglePlay } from "./helper";
import { useTranslation } from "react-i18next";
const ExampleList = ({
	lessonDetails,
	verifyActivity,
	updateField,
	updateAcitivity,
	isStudentMode,
	language,
	deckType,
	audioSpeedList,
}) => {
	const initialState = {
		audioUrl: null,
		audioSpeed: null,
		isAttempted: false,
		isSuccess: false,
		options: [],
		optionName: "",
		correctOption: "",
		isAudioPlaying: false,
	};
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(fieldUpdateReducer, initialState);
	const [isAudioCLick, setIsAudioClick] = useState(false);
	const { audioUrl, audioSpeed, isAttempted, options, optionName, isSuccess, correctOption, isAudioPlaying } = state;

	useEffect(() => {
		if (lessonDetails && lessonDetails.activity && lessonDetails.activity.options) {
			dispatch({
				options: lessonDetails.activity.options,
				optionName: "",
				correctOption: "",
				isAttempted: false,
			});
			if (isStudentMode) {
				updateField("skipActivity", true);
				updateField("disableNext", false);
			} else {
				updateField("disableNext", true);
			}
		}
		return () => {
			updateField("skipActivity", false);
		};
	}, [lessonDetails, updateField, isStudentMode]);

	useEffect(() => {
		if (audioUrl) {
			const player = document.getElementById("word-audio");
			if (audioSpeed) {
				player.playbackRate = audioSpeed;
			} else {
				player.playbackRate = 1;
			}
			const playPromise = player.play();

			if (playPromise !== undefined) {
				playPromise.catch((error) => {
					dispatch({ audioUrl: "" });
					if (isAttempted) {
						fetchNextActivity(0);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audioUrl]);

	const dtlsFields = [
		{
			title: t("helpers.utils.gloss"),
			icon: "icon-paragraph",
			field: "gloss",
		},
		{
			title: t("helpers.utils.definition"),
			icon: "icon-definition",
			field: "definition",
		},
		{
			title: t("languagelearning.lessons.details.examples"),
			icon: "icon-example",
			field: "examples",
		},
	];

	useEffect(() => {
		const { stage } = lessonDetails;
		if (stage === 1 || stage === 5) {
			document.addEventListener("keydown", onKeyDown);
		}
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessonDetails, isAttempted]);

	const onKeyDown = (e) => {
		const keyCode = e.keyCode;
		const {
			stage,
			activity: { options },
		} = lessonDetails;
		if ((stage === 1 || stage === 5) && !isAttempted) {
			if (keyCode === 49 || keyCode === 97) {
				if (options[0]) {
					verifyOption(options[0]);
				}
			} else if (keyCode === 50 || keyCode === 98) {
				if (options[1]) {
					verifyOption(options[1]);
				}
			}
			if (keyCode === 51 || keyCode === 99) {
				if (options[2]) {
					verifyOption(options[2]);
				}
			}
			if (keyCode === 52 || keyCode === 100) {
				if (options[3]) {
					verifyOption(options[3]);
				}
			}
			if (keyCode === 53 || keyCode === 101) {
				if (options[4]) {
					verifyOption(options[4]);
				}
			}
			if (keyCode === 54 || keyCode === 102) {
				if (options[5]) {
					verifyOption(options[5]);
				}
			}
			if (keyCode === 55 || keyCode === 103) {
				if (options[6]) {
					verifyOption(options[6]);
				}
			}
			if (keyCode === 56 || keyCode === 104) {
				if (options[7]) {
					verifyOption(options[7]);
				}
			}
		}
	};

	const playAudio = (url, speed = 1, shouldPlay) => {
		const audioFinalUrl = url !== audioUrl || shouldPlay ? url : null;
		playAudioHelper(audioFinalUrl, speed, dispatch);
	};

	const enterFunction = useCallback(
		(event) => {
			if (event.keyCode === 13 && isAttempted) {
				updateAcitivity("next");
				dispatch({ isAttempted: false });
			}
		},
		[updateAcitivity, isAttempted]
	);

	useEffect(() => {
		document.addEventListener("keydown", enterFunction, false);
		return () => {
			document.removeEventListener("keydown", enterFunction, false);
		};
	}, [enterFunction]);

	const verifyOption = (option) => {
		updateField("loading", true);
		dispatch({ audioUrl: "" });
		verifyActivity(
			{
				id: lessonDetails.activityId,
				answer: option.option,
			},
			deckType
		).then((res) => {
			updateField("loading", false);
			setIsAudioClick(false);
			if (res.statusCode === 200) {
				dispatch({ optionName: option.option, isAttempted: true });
				if (res.response.isSuccess) {
					dispatch({ isSuccess: true });
					if (res.response.pronunciation && res.response.pronunciation.filePath) {
						playAudio(res.response.pronunciation.filePath, 1, true);
					} else {
						fetchNextActivity(3000);
					}
					NotificationManager.success(t("languagelearning.lessons.details.notification.correctoption"), "", 3000);
				} else {
					const correctAnswers = res.response.correctAnswers;
					if (correctAnswers && correctAnswers.length > 0) {
						dispatch({ correctOption: correctAnswers[0] });
					}
					NotificationManager.error(t("languagelearning.lessons.details.notification.wrongoption"), "", 3000);
					updateField("disableNext", false);
					dispatch({ isSuccess: false });
				}
			} else {
				NotificationManager.error(
					`${
						res && res.response && res.response.message
							? res.response.message
							: t("helpers.utils.notification.error")
					}`,
					"",
					3000
				);
			}
		});
	};

	const fetchNextActivity = (time) => {
		setTimeout(() => {
			updateAcitivity("next");
		}, time);
	};

	const getPrononciationUrl = (option) => {
		const url = option.pronunciation && option.pronunciation.filePath ? option.pronunciation.filePath : null;
		return url !== audioUrl ? url : null;
	};

	const setSpeedHandlerFunction = (option, from) => {
		const audioSpeed = audioSpeedList.filter((val) => val.audioId === option.pronunciation.fileName);
		const speed = audioSpeed && audioSpeed[0] ? audioSpeed[0]["speed"] : 1;
		if (from) {
			togglePlay(dispatch, speed);
		} else {
			playAudio(option.pronunciation.filePath, speed);
		}
	};

	const renderAudioIcon = (option) => (
		<div
			className={`change-icon d-flex ${
				option.pronunciation &&
				option.pronunciation.filePath &&
				option.pronunciation &&
				option.pronunciation.filePath === audioUrl
					? "is-playing"
					: ""
			}`}>
			<span className="play-icon d-flex align-items-center">
				<i
					className={`pointer icon-audio ${
						option.pronunciation && option.pronunciation.filePath ? "" : "not-allowed"
					}`}
					{...(option.pronunciation &&
						option.pronunciation.filePath && {
							onClick: (e) => {
								e.stopPropagation();
								setIsAudioClick(true);
								setSpeedHandlerFunction(option);
							},
						})}></i>
				&nbsp;
				<AudioSettingsList
					audioId={option.pronunciation ? option.pronunciation.fileName : ""}
					utilizer="exampleList"
					url={getPrononciationUrl(option)}
				/>
			</span>
		</div>
	);

	const renderGlossaryButton = () => (
		<div className="d-flex justify-content-end">
			<a
				target="_blank"
				rel="noopener noreferrer"
				title="Grammar_Symbols_Glossary"
				href={grammarSymbolGlossary}
				className="btn btn-default glosary-btn">
				{t("helpers.utils.learning.grammarsymbolglossary")}
			</a>
		</div>
	);

	const renderOptions = () => (
		<OverlayTrigger
			placement="bottom"
			trigger="click"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="popover-options">
					<Popover.Content>{renderGlossaryButton()}</Popover.Content>
				</Popover>
			}>
			<div className="more-4-icon-icon">
				<i className="icon-more-4"></i>
			</div>
		</OverlayTrigger>
	);

	const renderAudio = (details, alignTop) =>
		details &&
		details.defaultPronunciation &&
		details.defaultPronunciation.filePath && (
			<React.Fragment>
				<div className={`mr-2 d-flex justify-content-end h-100 "align-items-center dropright ml-2`}>
					<button
						type="button"
						onClick={() => setSpeedHandlerFunction(details, true)}
						className={`audio-dropdown position-relative lesson-audio dropdown-toggle btn btn-primary ${
							isAudioPlaying ? "is-playing" : ""
						}`}>
						<i className="icon-audio"></i>
					</button>
					<AudioSettingsList audioId={details.id} utilizer="wordDetails" />
				</div>
				<audio
					src={details.defaultPronunciation.filePath}
					id="pro-audio"
					className="d-none"
					onEnded={() => dispatch({ isAudioPlaying: false })}
				/>
			</React.Fragment>
		);

	const renderGloss = (dtl, wordDetails, language) => {
		return (
			<OverlayTrigger
				trigger="click"
				placement="top"
				rootClose={true}
				key={dtl.title}
				overlay={
					<Popover id="popover-basic" className="lang-dtls-pop">
						<Popover.Content>
							<span
								dangerouslySetInnerHTML={{
									__html: wordDetails[dtl.field],
								}}></span>
						</Popover.Content>
					</Popover>
				}>
				<div className="d-flex align-items-center pointer">
					<span className="round-background-overlay">
						<i className={`icon-Group`}></i>
					</span>
				</div>
			</OverlayTrigger>
		);
	};

	return (
		<div className="examples-list   position-relative pt-2 pb-1">
			<div className=" d-flex align-items-center justify-content-end mr-3">
				<label className="progress-label mr-2" style={{ margin: "auto 0" }}>
					{t("helpers.utils.learning.progress", {
						currentlesson: lessonDetails.question,
						totallessons: lessonDetails.total,
					})}
				</label>
				{lessonDetails.activity &&
					renderGloss(dtlsFields[0], lessonDetails.activity.grammar || lessonDetails.activity.word, language)}
				{renderOptions()}
			</div>
			{audioUrl && (
				<audio
					src={audioUrl}
					id="word-audio"
					className="d-none"
					onEnded={() => {
						dispatch({ audioUrl: "" });
						if (isAttempted && !isAudioCLick) {
							fetchNextActivity(0);
							setIsAudioClick(false);
						}
					}}></audio>
			)}
			<div className="audio-list row m-0">
				{options.length > 0 &&
					options.map((option, index) => (
						<div className="option-wrapper  p-0 pointer" key={`${option.option}${index}`}>
							<div
								className={`options d-flex ${
									optionName === option.option ? (isSuccess ? "correct" : "incorrect") : ""
								} ${option.option === correctOption && !isSuccess ? "correct" : ""}`}>
								<div className={`index-number ${isAttempted ? "pointer-none" : ""}`}>{index + 1}</div>
								<span
									className={`option-name ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} ${
										isAttempted ? "pointer-none" : ""
									}`}
									onClick={() => verifyOption(option)}>
									{option.option}
								</span>
								{renderAudioIcon(option)}
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	language: state.sideMenu.language,
	deckType: state.studentLanguageLearning.deckType,
	audioSpeedList: state.studentLanguageLearning.audioSpeedList,
});

const mapDispatchToProps = {
	verifyActivity,
	updateField,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExampleList);
