import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { capitalize } from "./utils/helper";
import { API_URL } from "./utils/fetchResponseHandler";

const options = {
	// conditional path loading with ternary  ...({loadPath : "https://biblingotesting-mw.fingent.net/localization/resources/all?Name={{lng}}"}),
	//The below is the API URL for fetching translations from the back-end
	loadPath: `${API_URL}localization/resources/all?Name={{lng}}`,
	allowMultiLoading: false,
	crossDomain: false,
	withCredentials: false,
	requestOptions: {
		mode: "cors",
		credentials: "same-origin",
		cache: "default",
	},
	//Parsing the API response to the required format
	parse: function (data) {
		const parsedData = JSON.parse(data).response;
		return parsedData;
	},
};
i18n.use(initReactI18next) // passes i18n down to react-i18next
	.use(HttpBackend)
	.use(LanguageDetector)
	.init(
		{
			fallbackLng: "en",
			ignoreJSONStructure: true,
			keySeparator: false,
			supportedLngs: ["en", "es", "fr", "ru", "ar", "hi", "id", "pt"],
			interpolation: {
				escapeValue: false, // react already safes from xss
				format: function (value, format, lng) {
					switch (format) {
						case "capitalise":
							return capitalize(value);
						default:
							return value;
					}
				},
			},
			react: {
				useSuspense: true,
			},
			backend: options,
		},
		(error) => {
			error && console.error("i18err==>", error);
		}
	);

export default i18n;
