import { STUDENT_LANGUAGE_LEARNING } from "./types";
import { FLASH_CARD_DECK } from "../flashCardDeck/types";
import fetchHandler from "../../../utils/fetchResponseHandler";

export const getLevelDetails = (value) => {
	const fetchOptions = {
		url: `lesson/overview?Language=${value}`,
		method: "GET",
		actionType: STUDENT_LANGUAGE_LEARNING.GET_LEVEL_DETAILS,
	};

	return fetchHandler(fetchOptions);
};

export const getSidePanel = (id, isDeck) => {
	const fetchOptions = {
		url: `${isDeck ? "deck" : "lesson"}/status/${id}`,
		method: "GET",
		actionType: STUDENT_LANGUAGE_LEARNING.GET_SIDE_PANEL,
	};

	return fetchHandler(fetchOptions);
};

export const getLessonDetails = (id) => {
	const fetchOptions = {
		url: `lesson/get/${id}`,
		method: "GET",
		actionType: STUDENT_LANGUAGE_LEARNING.GET_LESSON_DETAILS,
	};

	return fetchHandler(fetchOptions);
};

export const getStageDetails = (id, isDeck) => {
	const fetchOptions = {
		url: `${isDeck ? "deckstage" : "lessonstage"}/get/${id}`,
		method: "GET",
		actionType: isDeck ? FLASH_CARD_DECK.GET_DECK_DETAILS : STUDENT_LANGUAGE_LEARNING.GET_STAGE_DETAILS,
	};

	return fetchHandler(fetchOptions);
};

export const changeActivity = (action, id, isDeck) => {
	const fetchOptions = {
		url: `${isDeck ? "deckactivity" : "activity"}/${action}/${id}`,
		method: action === "get" ? "GET" : "POST",
		actionType: isDeck ? FLASH_CARD_DECK.GET_DECK_DETAILS : STUDENT_LANGUAGE_LEARNING.CHANGE_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};

export const verifyActivity = (data, isDeck) => {
	const fetchOptions = {
		url: `${isDeck ? "deckactivity" : "activity"}/verify`,
		method: "POST",
		body: JSON.stringify(data),
		actionType: STUDENT_LANGUAGE_LEARNING.VERIFY_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};

export const askQuestion = (data) => {
	const fetchOptions = {
		url: `question/ask`,
		method: "POST",
		body: JSON.stringify(data),
		actionType: STUDENT_LANGUAGE_LEARNING.ASK_QUESTION,
	};

	return fetchHandler(fetchOptions);
};

export function updateField(field, val) {
	return {
		type: STUDENT_LANGUAGE_LEARNING.UPDATE_FIELD,
		field,
		val,
	};
}

export function setAudioSpeed(audioSpeedList) {
	return {
		type: STUDENT_LANGUAGE_LEARNING.SET_AUDIO_SPEED,
		audioSpeedList,
	};
}

export const getAllQuestions = (language, RefID, OrderingLocation, LessonNumber) => {
	const fetchOptions = {
		url: `question/all?Language=${language}&HasAnswers=${true}&ShowOnlyForClass=${false}&ShowAll=${true}
		${RefID !== null ? `&RefID=${RefID}` : ""}
		${OrderingLocation !== null ? `&OrderingLocation=${OrderingLocation}` : ""}
		${LessonNumber !== null ? `&LessonNumber=${LessonNumber}` : ""}`,
		method: "GET",
		actionType: STUDENT_LANGUAGE_LEARNING.GET_QUESTIONS,
	};
	return fetchHandler(fetchOptions);
};

export const getQuestionAnswer = (id) => {
	const fetchOptions = {
		url: `answer/all?questionID=${id}`,
		method: "GET",
		actionType: STUDENT_LANGUAGE_LEARNING.GET_ANSWER,
	};

	return fetchHandler(fetchOptions);
};

export const setPractice = (payload) => {
	const fetchOptions = {
		url: `learningsettings/change`,
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: STUDENT_LANGUAGE_LEARNING.SET_PRACTICE,
	};

	return fetchHandler(fetchOptions);
};

export const getPractice = (language) => {
	const fetchOptions = {
		url: `learningSettings/get?language=${language === "Hebrew" ? 0 : 1}`,
		method: "GET",
		actionType: STUDENT_LANGUAGE_LEARNING.GET_PRACTICE,
	};

	return fetchHandler(fetchOptions);
};

export function handleCollaspingSidePanel() {
	return {
		type: STUDENT_LANGUAGE_LEARNING.HANDLE_SIDEPANEL,
	};
}
