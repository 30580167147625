import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Card, Row, Col, Container } from "react-bootstrap";
import ReportTableComponent from "../ReportTable";
import { Link } from "react-router-dom";
import RoundChart from "../../../sharedComponents/RoundChart";
import LineChart from "../../../sharedComponents/LineChart";
import DateFilter from "../../../sharedComponents/DateFilter";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import InfoDescription from "../../../sharedComponents/infoDescription";

import {
	getReadingOverview,
	getReadingLineChart,
	getReadingPieChart,
	getReadingReportChapters,
	getReadingReportComprehension,
} from "../action";
import { dateValues } from "../helper";
import { getUserDetails } from "../../../../utils/verifyUser";

import { useTranslation } from "react-i18next";

const Reading = ({
	match,
	getReadingOverview,
	getReadingLineChart,
	getReadingPieChart,
	getReadingReportChapters,
	getReadingReportComprehension,
	dispatch,
	reading,
	currentTab,
	studentId,
	collectiveFilter,
}) => {
	const {
		chaptersOverview,
		booksOverview,
		booksLineChart,
		chaptersLineChart,
		booksPieChart,
		wordsPieChart,
		chaptersPieChart,
		chaptersReport,
		comprehensionReport,
		mounted,
	} = reading;

	const [overviewDate, setOverviewDate] = useState(dateValues[0]);
	const [bibleProgressDate, setBibleProgressDate] = useState(dateValues[0]);
	const [chaptersDate, setChaptersDate] = useState(dateValues[0]);
	const [booksDate, setBooksDate] = useState(dateValues[0]);

	const [overviewLoading, setOverviewLoading] = useState(true);
	const [bibleProgressLoading, setBibleProgressLoading] = useState(true);
	const [bookChapterChartLoading, setBookChapterChartLoading] = useState(true);
	const [bookLineChartLoading, setBookLineChartLoading] = useState(true);
	const [reportsLoading, setReportsLoading] = useState(true);
	const userDetails = getUserDetails();

	const onFilterChangeOverview = async (dateValue) => {
		const { response: chaptersOverview } = await getReadingOverview(
			match.params.language,
			"chapters",
			dateValue,
			studentId,
			collectiveFilter
		);
		const { response: booksOverview } = await getReadingOverview(
			match.params.language,
			"Books",
			dateValue,
			studentId,
			collectiveFilter
		);
		return {
			chaptersOverview,
			booksOverview,
		};
	};

	const onFilterPieChartChange = async (dateValue) => {
		const { response: booksPieChart } = await getReadingPieChart(
			match.params.language,
			"bookscompleted",
			dateValue,
			studentId,
			collectiveFilter
		);
		const { response: wordsPieChart } = await getReadingPieChart(
			match.params.language,
			"wordslearned",
			dateValue,
			studentId,
			collectiveFilter
		);
		const { response: chaptersPieChart } = await getReadingPieChart(
			match.params.language,
			"chapterscompleted",
			dateValue,
			studentId,
			collectiveFilter
		);
		return {
			booksPieChart,
			wordsPieChart,
			chaptersPieChart,
		};
	};

	const onLineChartFilter = async (dateValue, graph) => {
		const { response: chaptersLineChart } = await getReadingLineChart(
			match.params.language,
			graph,
			dateValue,
			studentId,
			collectiveFilter
		);
		return {
			response: chaptersLineChart,
		};
	};

	const getData = async () => {
		const { chaptersOverview, booksOverview } = await onFilterChangeOverview(dateValues[0].value);
		const { booksPieChart, wordsPieChart, chaptersPieChart } = await onFilterPieChartChange(dateValues[0].value);
		const { response: chaptersLineChart } = await onLineChartFilter(dateValues[0].value, "chapterscompleted");
		const { response: booksLineChart } = await onLineChartFilter(dateValues[0].value, "bookscompleted");

		const { response: chaptersReport } = await getReadingReportChapters(
			match.params.language,
			dateValues[0].value,
			4,
			0,
			studentId
		);

		const { response: comprehensionReport } = await getReadingReportComprehension(
			match.params.language,
			dateValues[0].value,
			4,
			0,
			studentId
		);

		dispatch({
			reading: {
				...reading,
				chaptersOverview,
				booksOverview,
				booksLineChart,
				chaptersLineChart,
				booksPieChart,
				wordsPieChart,
				chaptersPieChart,
				chaptersReport,
				comprehensionReport,
				mounted: true,
			},
		});
		setBookChapterChartLoading(false);
		setBookLineChartLoading(false);
		setOverviewLoading(false);
		setBibleProgressLoading(false);
		setReportsLoading(false);
	};

	useEffect(() => {
		if (currentTab === t("dashboard.reading.name") && !mounted) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	const handleOnFilterChangeOverview = async (value) => {
		setOverviewLoading(true);
		setOverviewDate(dateValues[value]);
		const overView = await onFilterChangeOverview(dateValues[value].value);
		dispatch({
			reading: {
				...reading,
				...overView,
			},
		});
		setOverviewLoading(false);
	};

	const handleOnFilterPieChartChange = async (value) => {
		setBibleProgressLoading(true);
		setBibleProgressDate(dateValues[value]);
		const { booksPieChart, wordsPieChart, chaptersPieChart } = await onFilterPieChartChange(dateValues[value].value);
		dispatch({
			reading: {
				...reading,
				booksPieChart,
				wordsPieChart,
				chaptersPieChart,
			},
		});
		setBibleProgressLoading(false);
	};

	const handleOnChapterLineChartFilter = async (value) => {
		setBookChapterChartLoading(true);
		setChaptersDate(dateValues[value]);
		const { response: chaptersLineChart } = await onLineChartFilter(dateValues[value].value, "chapterscompleted");
		dispatch({
			reading: {
				...reading,
				chaptersLineChart,
			},
		});
		setBookChapterChartLoading(false);
	};

	const handleOnBookLineChartFilter = async (value) => {
		setBookLineChartLoading(true);
		setBibleProgressDate(dateValues[value]);
		const { response: booksLineChart } = await onLineChartFilter(dateValues[value].value, "bookscompleted");
		dispatch({
			reading: {
				...reading,
				booksLineChart,
			},
		});
		setBookLineChartLoading(false);
	};
	const { t } = useTranslation();
	return (
		<Container fluid className="reading-container">
			{userDetails.origin === "Biblingo" && (
				<Row>
					<Col className="card-col mt-3" xs={12} lg={4}>
						{!overviewLoading ? (
							<Card className="overView  no-shadow" body>
								<div className="title-wrapper">
									<div className="title-heading">{t(`dashboard.overview`)}</div>
									<div className="filter-wrapper d-flex">
										<DateFilter
											dataset={dateValues}
											placeholder="Select Date"
											onSelectCB={(value) => {
												setOverviewDate(dateValues[value]);
												handleOnFilterChangeOverview(value);
											}}
											activeFilter={overviewDate}
										/>
									</div>
								</div>
								<div className="d-flex justify-content-around align-items-center">
									<Card body className="overview-cards equalize p-2" xs={12} lg={5}>
										<div className="p-2 icon-box bookmark">
											<i className="icon-checklist-1"></i>
										</div>
										<div className="mt-3">
											<span className="number-text">
												{chaptersOverview && chaptersOverview.numberOfChaptersCompleted}
											</span>
										</div>
										<div className="m-2 text-center center-text">
											<span className="d-block">{t(`dashboard.reading.overview.chapterscompleted`)}</span>
										</div>
										<div className="m-2">
											{chaptersOverview && (
												<span className={`percentage ${chaptersOverview.isIncrease ? "inc" : "dec"}`}>
													{chaptersOverview.percentageChange !== 0
														? `${chaptersOverview.isIncrease ? "+" : "-"} ${Math.abs(
																chaptersOverview.percentageChange
														  )} %`
														: ""}
												</span>
											)}
										</div>
									</Card>
									<Card body className="overview-cards equalize p-2" xs={12} lg={5}>
										<div className="p-2 icon-box abc-block">
											<i className="icon-academic-1"></i>
										</div>
										<div className="mt-3">
											<span className="number-text">
												{booksOverview && booksOverview.numberOfBooksCompleted}
											</span>
										</div>
										<div className="m-2 text-center center-text">
											<span>{t(`dashboard.reading.overview.bookscompleted`)}</span>
										</div>
										<div className="m-2">
											{booksOverview && (
												<span className={`percentage ${booksOverview.isIncrease ? "inc" : "dec"}`}>
													{booksOverview.percentageChange !== 0
														? `${booksOverview.isIncrease ? "+" : "-"} ${Math.abs(
																booksOverview.percentageChange
														  )} %`
														: ""}
												</span>
											)}
										</div>
									</Card>
								</div>
							</Card>
						) : (
							<SkeletonTheme color="#FFF">
								<Skeleton height={335} />
							</SkeletonTheme>
						)}
					</Col>
					<Col className="card-col mt-3" xs={12} lg={8}>
						{!bibleProgressLoading ? (
							<Card body className="days-spent  no-shadow">
								<div className="title-wrapper d-flex">
									<div className="title-heading">{t(`dashboard.reading.bibleprogress.name`)}</div>
									<div className="filter-wrapper d-flex">
										<DateFilter
											dataset={dateValues}
											placeholder="Select Date"
											onSelectCB={(value) => {
												setBibleProgressDate(dateValues[value]);
												handleOnFilterPieChartChange(value);
											}}
											activeFilter={bibleProgressDate}
										/>
									</div>
								</div>
								<div className="round-chart" style={{ margin: "7px 0px" }}>
									<Row>
										<Col className="card-col" xs={12} lg={4}>
											<div className="d-relative">
												<RoundChart data={[wordsPieChart]} chartColor={["#FF69B4", "#FCD7F4"]} />
												<div className="chart-percentage">
													{wordsPieChart && wordsPieChart.value && `${wordsPieChart.value}`}%
												</div>
											</div>
											<div className="chart-title">
												{t(`dashboard.reading.bibleprogress.wordslearned`)}
												<InfoDescription
													position={"left-top"}
													description={t(`dashboard.reading.bibleprogress.wordslearnedinfo`)}
												/>
											</div>
										</Col>
										<Col className="card-col" xs={12} lg={4}>
											<div className="d-relative">
												<RoundChart data={[chaptersPieChart]} chartColor={["#0088FE", "#bbddfa"]} />
												<div className="chart-percentage">
													{chaptersPieChart && chaptersPieChart.value && `${chaptersPieChart.value}`}%
												</div>
											</div>
											<div className="chart-title">
												{t(`dashboard.reading.bibleprogress.chapterscompleted`)}
												<InfoDescription
													position={"left-top"}
													description={t(`dashboard.reading.bibleprogress.chapterscompletedinfo`)}
												/>
											</div>
										</Col>
										<Col className="card-col" xs={12} lg={4}>
											<div className="d-relative">
												<RoundChart data={[booksPieChart]} chartColor={["#00C49F", "#b7f7eb"]} />
												<div className="chart-percentage">
													{booksPieChart && booksPieChart.value && `${booksPieChart.value}`}%
												</div>
											</div>
											<div className="chart-title">
												{t(`dashboard.reading.bibleprogress.bookscompleted`)}
												<InfoDescription
													position={"left-top"}
													description={t(`dashboard.reading.bibleprogress.bookscompletedinfo`)}
												/>
											</div>
										</Col>
									</Row>
								</div>
							</Card>
						) : (
							<SkeletonTheme color="#FFF">
								<Skeleton height={335} />
							</SkeletonTheme>
						)}
					</Col>
				</Row>
			)}
			{studentId !== "Collective" && (
				<Row className="mt-3">
					<Col className="card-col" xs={12}>
						{!reportsLoading ? (
							<Card className="no-shadow reports" body>
								<Row>
									{userDetails.origin === "Biblingo" && (
										<Col xs={12} lg={6}>
											<div className="title-heading mb-2">
												{t(`dashboard.reading.biblechaptersprogress.name`)}
												<InfoDescription
													position={"right-bottom"}
													description={t(`dashboard.reading.biblechaptersprogress.info`)}
												/>
											</div>
											<ReportTableComponent
												header={[
													{ label: t([`helpers.utils.chapter`]), key: "chapter" },
													{
														label: t([`dashboard.reports.reading.percentagereadingcompletion`]),
														key: "percentage",
														type: "percentage",
													},
													{
														label: t([`dashboard.reports.reading.datecompleted`]),
														key: "dateCompleted",
														type: "date",
													},
												]}
												data={chaptersReport}
											/>
											{chaptersReport && chaptersReport.count > 0 && (
												<div className="text-right mt-1">
													<Link
														to={`/home/${
															match.params.language
														}/Dashboard/reports/Reading/bible-reading${
															studentId ? `/${studentId}` : ""
														}`}>
														{t(`helpers.utils.viewdetails`)}
													</Link>
												</div>
											)}
										</Col>
									)}
									<Col xs={12} lg={userDetails.origin === "Biblingo" ? 6 : 12}>
										<div className="title-heading mb-2">
											{t(`dashboard.reading.readingcomprehensioncompleted.name`)}
											<InfoDescription
												position={"right-bottom"}
												description={t(`dashboard.reading.readingcomprehensioncompleted.info`)}
											/>
										</div>
										<ReportTableComponent
											header={[
												{ label: t([`dashboard.reports.reading.questions`]), key: "question" },
												{
													label: t([`dashboard.reports.reading.incorrectfrequency`]),
													key: "inCorrectFrequency",
												},
												{
													label: t([`dashboard.reports.reading.datecompleted`]),
													key: "dateCompleted",
													type: "date",
												},
											]}
											data={comprehensionReport}
										/>
										{comprehensionReport && comprehensionReport.count > 0 && (
											<div className="text-right mt-1">
												<Link
													to={`/home/${
														match.params.language
													}/Dashboard/Reports/Reading/reading-comprehension${
														studentId ? `/${studentId}` : ""
													}`}>
													{t(`helpers.utils.viewdetails`)}
												</Link>
											</div>
										)}
									</Col>
								</Row>
							</Card>
						) : (
							<SkeletonTheme color="#f6f8ff">
								<Skeleton height={200} />
							</SkeletonTheme>
						)}
					</Col>
				</Row>
			)}
			{userDetails.origin === "Biblingo" && (
				<Row className="mt-3">
					<Col className="card-col" xs={12} lg={6}>
						{!bookChapterChartLoading ? (
							<Card className="no-shadow " body>
								<div className="title-wrapper d-flex">
									<div className="title-heading">
										{t(`dashboard.reading.numberofbiblechapterscompleted.name`)}
										<InfoDescription
											position={"right-bottom"}
											description={t(`dashboard.reading.numberofbiblechapterscompleted.info`)}
										/>
									</div>
									<div className="filter-wrapper d-flex">
										<DateFilter
											dataset={dateValues}
											placeholder="Select Date"
											onSelectCB={(value) => {
												setChaptersDate(dateValues[value]);
												handleOnChapterLineChartFilter(value);
											}}
											activeFilter={chaptersDate}
										/>
									</div>
								</div>
								{chaptersLineChart.length > 0 ? (
									<LineChart data={chaptersLineChart} />
								) : (
									<div className="text-center" style={{ margin: "147px 0px" }}>
										{t(`helpers.utils.nodata`)}
									</div>
								)}
							</Card>
						) : (
							<SkeletonTheme color="#FFF">
								<Skeleton height={390} />
							</SkeletonTheme>
						)}
					</Col>
					<Col className="card-col" xs={12} lg={6}>
						{!bookLineChartLoading ? (
							<Card className="no-shadow" body>
								<div className="title-wrapper d-flex">
									<div className="title-heading">
										{t(`dashboard.reading.numberofbiblebookscompleted.name`)}
										<InfoDescription
											position={"right-bottom"}
											description={t(`dashboard.reading.numberofbiblebookscompleted.info`)}
										/>
									</div>
									<div className="filter-wrapper d-flex">
										<DateFilter
											dataset={dateValues}
											placeholder="Select Date"
											onSelectCB={(value) => {
												setBooksDate(dateValues[value]);
												handleOnBookLineChartFilter(value);
											}}
											activeFilter={booksDate}
										/>
									</div>
								</div>
								{booksLineChart.length > 0 ? (
									<LineChart data={booksLineChart} />
								) : (
									<div className="text-center" style={{ margin: "147px 0px" }}>
										{t(`helpers.utils.nodata`)}
									</div>
								)}
							</Card>
						) : (
							<SkeletonTheme color="#FFF">
								<Skeleton height={390} />
							</SkeletonTheme>
						)}
					</Col>
				</Row>
			)}
		</Container>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	getReadingOverview,
	getReadingLineChart,
	getReadingPieChart,
	getReadingReportChapters,
	getReadingReportComprehension,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reading));
