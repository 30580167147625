import React, { useRef, useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../sharedComponents/Loader";
import { useInfiniteScroll } from "../../customHooks/infiniteScroll";
import { NoContent } from "../../sharedComponents/NoContent";
import noData from "../../../assets/images/no-data.png";
import { getVerseList, updateBibleField } from "./actions";

const Verses = ({
  getVerseList,
  verses,
  strongsNumber,
  language,
  currentWord,
  updateBibleField,
  isVerseLoading,
}) => {
  const {t} = useTranslation();
  const verseReducer = (state, action) => {
    switch (action.type) {
      case "FETCH_VERSES":
        if (state.list.hasNext && action.strongsNumber) {
          action.updateBibleField("isVerseLoading", true);
          action.callBack(
            action.strongsNumber,
            action.language,
            state.list.index + 1
          );
        }
        return { ...state };
      case "SET_VERSES":
        let verses = { ...action.payload };
        verses.records = [
          ...(state.list.records ? state.list.records : []),
          ...action.payload.records,
        ];
        return {
          ...state,
          list: verses,
        };
      case "REMOVE_LIST":
        return { list: {} };
      default:
        return state;
    }
  };

  let bottomBoundaryRef = useRef(null);
  const [verse, verseDispatch] = useReducer(verseReducer, { list: {} });

  useEffect(() => {
    if (
      verses &&
      verses.response &&
      verses.response.records &&
      verses.response.records.length
    ) {
      verseDispatch({
        type: "SET_VERSES",
        payload: verses.response,
      });
    } else if (!verses) {
      verseDispatch({
        type: "REMOVE_LIST",
      });
    }
  }, [verses]);

  const onScroll = () => {
    verseDispatch({
      type: "FETCH_VERSES",
      callBack: getVerseList,
      strongsNumber,
      updateBibleField,
      language,
    });
  };

  useInfiniteScroll(bottomBoundaryRef, onScroll);

  const fetchVerseDetails = (verse) => {
    updateBibleField("fetchBibleData", true);
    updateBibleField("selectedVerse", verse);
  };

  return (
    <>
      {verse.list.records && verse.list.records.length > 0 ? (
        <div className={`verse-list ${language}`}>
          <label className="title">
            {verse.list.count > 1 ? t("biblereading.verses.results") : t("helpers.utils.result.singular")}
          </label>
          {verse.list.records.map((verse, index) => (
            <div className="verses " key={`${index}${verse.verse}`}>
              <label
                className="verse-name"
                onClick={() => fetchVerseDetails(verse)}
              >
                {verse.book} {verse.chapter}:{verse.verse}
              </label>
              <div className="d-flex flex-wrap verse-words">
                {verse.words &&
                  verse.words.length > 0 &&
                  verse.words.map((word, index) => (
                    <span
                      key={`${word.text}${index}`}
                      className={`verse-value ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"
                        } ${word.isHighlighted ? "highlight" : ""} ${word.isAppend ? "append" : ""
                        }`}
                    >
                      {word.text}
                    </span>
                  ))}
              </div>
            </div>
          ))}
        </div>
      ) : isVerseLoading ? null : (
        <NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
      )}
      {isVerseLoading && <Loader />}
      <div id="page-bottom-boundary" ref={bottomBoundaryRef}></div>
    </>
  );
};

const mapStateToProps = (state) => ({
  verses: state.bible.verses,
  strongsNumber: state.bible.strongsNumber,
  currentWord: state.bible.currentWord,
  isVerseLoading: state.bible.isVerseLoading,
  language: state.sideMenu.language,
});

const mapDispatchToProps = {
  getVerseList,
  updateBibleField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Verses);
