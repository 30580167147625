import React, { useEffect, useRef, useState } from "react";
import { Card, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../sharedComponents/Loader";
import SharedModal from "../sharedComponents/Modal";
import NoData from "../sharedComponents/NoData";
import { ToggleButton } from "../sharedComponents/toggleButton";
import { bulkUpload, fetchAllCultures } from "./actions";
import "./LangSetting.scss";

const LanguageSetting = ({ cultures, fetchAllCultures, bulkUpload }) => {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(null);
	const [activeCulture, setActiveCulture] = useState([]);
	const [culture, setCulture] = useState(cultures);
	const [temp, setTemp] = useState();
	const inputFileRef = useRef(null);
	const overlayRef = useRef(null);
	const [view, setView] = useState(true);
	const modules = [
		{ id: 0, title: "Language Learning", flag: 0 },
		{ id: 1, title: "Flashcard Deck", flag: 0 },
		{ id: 2, title: "Bible Reading", flag: 0 },
	];
	useEffect(() => {
		const fetchInitData = async () => {
			setLoading(true);
			await fetchAllCultures();
			setLoading(false);
		};
		if (cultures.length === 0) {
			fetchInitData();
		}
		setCulture(cultures);
	}, [cultures, fetchAllCultures, culture, view]);

	const onBtnClick = (e, el) => {
		inputFileRef.current.click();
		setActiveCulture(el.id);
	};

	const handleChange = async (e) => {
		setLoading(true);
		const module = modules.map((mod) => mod.id);
		const formData = new FormData();
		formData.append("uploadedFile", e.target.files[0]);
		const { response, statusCode } = await bulkUpload(activeCulture, module, formData);
		setLoading(false);
		setActiveCulture(null);
		if (statusCode === 200) NotificationManager.success("Uploaded file successfully", "", 2000);
		else NotificationManager.error(response.message, "", 2000);
	};

	const confirmationModalBody = () => (
		<>
			Are you sure you want to delete the file {temp && temp.file ? temp.file.name : ""}&nbsp; from{" "}
			{temp && temp.displayName} language ?
		</>
	);

	const confirmationModalFooter = () => {
		const removeFile = () => {
			setCulture((prevState) => {
				prevState.map((val) => {
					if (val.id === temp.id) {
						return delete val.file;
					}
					return val;
				});
			});
			setShow(null);
			let a = culture.find((val) => {
				if (typeof val["file"] !== "undefined") {
					setView(false);
				} else setView(true);
			});
		};
		return (
			<>
				<button
					className="btn-cancel"
					onClick={(e) => {
						e.preventDefault();
						setShow(null);
					}}>
					No
				</button>
				<button className="btn-upload" onClick={removeFile}>
					Yes
				</button>
			</>
		);
	};
	const handleClose = () => setShow(null);

	const onSubmitFinal = () => {
		setLoading(true);
		const submit = Promise.all(
			culture.map((parent) => {
				if (parent.hasOwnProperty("file")) {
					const formData = new FormData();
					formData.append("uploadedFile", parent.file);
					const modules = parent.module
						.filter((module) => module.flag)
						.map((val) => val.id)
						.join(",");
					return new Promise((resolve, reject) => {
						resolve(bulkUpload(parent.id, modules, formData));
					});
				}
			})
		)
			.then((res) => {
				setLoading(false);
				if (res.statusCode === 200) {
					NotificationManager.success("File Uploaded Successfully.", "", 3000);
				} else {
					setLoading(false);
					NotificationManager.error(
						res.statusCode === 400
							? res.response.validationErrors &&
									res.response.validationErrors[0] &&
									res.response.validationErrors[0].value
							: res.response && res.response.message,
						"Error occured. please try again",
						2000
					);
				}
			})
			.catch((err) => console.log("err>>>>", err));
	};
	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="lang-container">
					<div className="d-flex pl-4 pr-4 py-4 justify-content-end">
						<button className="btn  add-btn-Lng" disabled={true}>
							<span>+</span> Add Language
						</button>
					</div>
					{culture && culture.length ? (
						<>
							{culture.map((el) => (
								<Card key={el.id} className="m-3 card-lesson">
									<Card.Body>
										<Row className="pl-1">
											<Col md={12} className="p-0">
												<div className="d-flex justify-content-between align-items-center ">
													<div className="circle" />
													<div
														className="flex-grow-1 bd-highlight pointer"
														style={{ color: "#072f40" }}>
														{el.displayName}
													</div>
													<button
														className="card-internal-btn btn"
														onClick={(e) => {
															onBtnClick(e, el);
														}}>
														UI Labels
														<i className="icon icon-upload pl-3" />
													</button>
													<input
														type="file"
														accept=".csv"
														ref={inputFileRef}
														onChange={handleChange}
														id="file-upload"
													/>
												</div>
											</Col>
										</Row>
										<div className="d-flex flex-row justify-content-end pr-5 mr-5 pt-2">
											<span
												className="pr-5 mr-5"
												style={{
													color: "#68808B",
													display: "block",
												}}>
												{el.file && el.file.name ? (
													<>
														{el.file.name}
														<i
															className="icon icon-close-2 pointer"
															onClick={() => {
																setTemp(el);
																setShow("ConfirmationModal");
															}}
														/>
														<SharedModal
															show={show === "ConfirmationModal" ? true : false}
															handleClose={handleClose}
															modalBody={confirmationModalBody()}
															modalFooter={confirmationModalFooter()}
															modalClass="confirm-modal"
														/>
													</>
												) : (
													""
												)}
											</span>
										</div>
									</Card.Body>
								</Card>
							))}
						</>
					) : (
						<NoData />
					)}
				</div>
			)}
		</>
	);
};
const mapStateToProps = (state) => ({
	cultures: state.Settings.cultures || [],
});

const mapDispatchToProps = {
	fetchAllCultures,
	bulkUpload,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageSetting));
