import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";

import { updateField } from "../Students/LanguageLearning/actions";
import Submenu from "../Submenu";
import LessonDetails from "../LessonDetails";
import Header from "../header";
import { setSideMenu } from "../Sidemenu/actions";
import { uploadFile } from "./actions";
import "./languageLearning.scss";
import {useTranslation} from 'react-i18next'

const LanguageLearning = ({ menu, language, setSideMenu, history, updateField }) => {
	const {t} = useTranslation();
	const location = useLocation().pathname.split("/");
	useEffect(() => {
		const location = history.location.pathname.split("/");
		updateField("isStudentMode", false);
		setSideMenu({
			menu: [
				{
					title: location[3] === "Language-Learning" || location[3] === "grammar" ? t("dashboard.filtervalues.languagelearning") : location[3],
					path: "",
				},
			],
			language: location[2],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, setSideMenu]);
	

	return (
		<div className="dashboard d-flex">
			<Submenu />
			<div className="empty">
				<Header
					menu={menu}
					language={language}
					history={history}
					head={location[3] === "Language-Learning" || location[3] === "grammar" ? t("dashboard.filtervalues.languagelearning") : location[3]}
				/>
				<LessonDetails />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	uploadFile,
	setSideMenu,
	updateField,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageLearning));
