import React from "react";

const ProgressCircle = ({
  value = 0,
  id = "",
  className = "",
  size = 100,
  displayValue,
  displayImg,
  strokeGap,
  strokeSize,
  rotateCircle,
  displayComponent,
}) => {
  const processedData = [];
  value.forEach((item, index) => {
    const prevValue = processedData[index - 1];
    processedData.push({
      ...item,
      startFrom: prevValue ? prevValue.startFrom + prevValue.value : 0,
    });
  });

  let strokeWidth = displayImg ? 4 : 6;
  let raduis = size / 2 - strokeWidth;
  let strokeDasharray = Math.PI * (raduis * 2);
  let cxcy = size / 2;

  const renderArch = (item, index, prevItem = null) => {
    const strokeDashoffset = ((100 - item.value) / 100) * strokeDasharray;
    const rotateValue = prevItem ? prevItem.value * 3.6 : 0;
    const startFromValue = prevItem ? prevItem.startFrom * 3.6 : 0;
    const strokeDashWidth = strokeGap ? strokeWidth + 3 : strokeWidth;
    return (
      <circle
        key={index}
        r={raduis}
        cx={cxcy}
        cy={cxcy}
        stroke={item.color}
        strokeWidth={strokeSize ? strokeSize : strokeWidth}
        fill="transparent"
        strokeDasharray={
          strokeDasharray -
          (item.value === 100 || item.value === 0 ? 0 : strokeDashWidth)
        }
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        transform={`rotate(${
          rotateValue + startFromValue - (rotateCircle ? 90 : 0)
        } ${cxcy} ${cxcy})`}
      />
    );
  };

  return (
    <div className={`progresscircle-wrap ${className}`} id={id}>
      <div
        className="progresscircle-container"
        style={{ perspective: "1000px" }}
      >
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="xMinYMin meet"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          {...(rotateCircle && { style: { transform: "rotateY(180deg)" } })}
        >
          <circle
            r={raduis}
            cx={cxcy}
            cy={cxcy}
            strokeWidth={strokeWidth}
            fill="transparent"
            strokeDasharray={strokeDasharray}
            strokeDashoffset="0"
            className="bg-circle"
          />

          {processedData.map((item, index) =>
            renderArch(item, index, processedData[index - 1])
          )}
        </svg>
        {displayImg ? (
          <img src={displayImg} alt="img" className="progress-img" />
        ) : (
          <div className="progresscircle-value">
            {displayComponent ? displayValue() : <span>{displayValue}</span>}
          </div>
        )}
      </div>
    </div>
  );
};
export default ProgressCircle;
