import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import "./sidemenu.scss";
import { setSideMenu } from "./actions";
import { setLessonNumber, getIntroVideo, clearData } from "../Submenu/actions";
import { updateField } from "../Users/actions";
import { getUserDetails } from "../../utils/verifyUser";
import { useTranslation } from "react-i18next";

const DropdownMenus = ({
	menus,
	title,
	setSideMenu,
	updateField,
	sideMenu,
	history,
	iconName,
	url,
	setLessonNumber,
	handleClick,
	clearData,
	menuChange,
}) => {
	const {t} = useTranslation();
	const overlayRef = useRef(null);

	const handleDropdown = (item) => {
		overlayRef && overlayRef.current && overlayRef.current.hide();
		setLessonNumber(1);
		setSideMenu({
			menu: [{ title: item.label === "Language-Learning" ? t("dashboard.filtervalues.languagelearning") : item.label }],
			language: title,
		});
		if (title === "User Management") {
			updateField("addUser", false);
		}
		handleClick();
		clearData();
		history.push(`${url}/${item.value}`);
		menuChange(item.label);
	};

	const isSubMenuActive = (item) => {
		switch (title) {
			case "Hebrew":
			case "Greek":
				return sideMenu[1] && sideMenu[1] === title && item.label === sideMenu[2] ? "item-active" : "";
			default:
				return sideMenu[2] === item.label ? "item-active" : "";
		}
	};

	const getClassName = () => {
		const { isFlashCardDeck } = getUserDetails().modules;
		if (title === t("flashcarddeck.name") && !isFlashCardDeck) {
			return "";
		} else {
			return title;
		}
	};

	return (
		<>
		<OverlayTrigger
			trigger="click"
			placement="right"
			className="sidemenu-dropdown"
			rootClose={true}
			// ref={overlayRef}
			overlay={
				<Popover
					id="popover-basic"
					className={`sidemenu-popover ${url && url.split("/").pop()} ${getClassName()} fadeIn`}>
					<Popover.Content>
						{menus &&
							menus.length > 0 &&
							menus.map((item, index) => (
								<button key={index} className={isSubMenuActive(item)} onClick={() => handleDropdown(item)}>
									<span>
										<i className={`icon ${item.icon}`}></i>
										<br />
										<p className="m-0">{item.label}</p>
									</span>
								</button>
							))}
					</Popover.Content>
				</Popover>
			}>
			<div className="sidemenu-dropdown">
				<button className="dropdown-toggle btn btn-primary">
					<i className={`icon ${iconName}`}></i>
					<span className="break-text">{title}</span>
				</button>
			</div>
		</OverlayTrigger>
		</>
	);
};

const mapStateToProps = (state) => ({
	sideMenu: state.sideMenu.menu,
});

const mapDispatchToProps = {
	setSideMenu,
	setLessonNumber,
	getIntroVideo,
	updateField,
	clearData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownMenus));
