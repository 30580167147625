import { SIDEMENU } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export function setSideMenu(payload) {
	return {
		type: SIDEMENU.SET_MENU,
		payload,
	};
}

export const sessionLogging = (data) => {
	const fetchOptions = {
		url: "session/log",
		method: "POST",
		body: JSON.stringify(data),
		actionType: SIDEMENU.SESSION_LOG,
	};

	return fetchHandler(fetchOptions);
};

export const endSessionLogging = (data) => {
	const fetchOptions = {
		url: "session/log",
		method: "POST",
		body: JSON.stringify(data),
		actionType: SIDEMENU.SESSION_LOG_END,
	};

	return fetchHandler(fetchOptions);
};
