import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import Header from "../../header";
import ActivePhase from "../../sharedComponents/ActivePhase";
import Grammar from "../../sharedComponents/Grammar";
import Loader from "../../sharedComponents/Loader";
import SharedModal from "../../sharedComponents/Modal";

import { setSideMenu } from "../../Sidemenu/actions";
import { getUserDetails } from "../../../utils/verifyUser";
import { updateField } from "../LanguageLearning/actions";
import { getDetails, changeActivity, verifyActivity } from "./actions";
import complete from "../../../assets/images/complete.png";
import { useTranslation } from "react-i18next";
import "./details.scss";

const Details = ({
	updateField,
	setSideMenu,
	history,
	menu,
	isStudentMode,
	getDetails,
	language,
	changeActivity,
	verifyActivity,
	match,
}) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [lessonDetails, setLessonDetails] = useState();
	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
	}, [updateField]);

	useEffect(() => {
		updateStateField("loading", true);
		const url = match.params.from === "learning" ? "fluencydrill" : "fluencydrilldeck";
		getDetails(url, match.params.id).then((res) => {
			if (res.statusCode === 200) {
				if (res.response) {
					setLessonDetails(res.response);
				}
			}
			updateStateField("loading", false);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (lessonDetails && lessonDetails.isLessonCompleted) {
			setShowModal(true);
		}

		if (lessonDetails && lessonDetails.isCompleted) {
			setShowModal(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessonDetails]);

	useEffect(() => {
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: t("languagelearning.fluencydrills.name"), path: `/home/${language}/Fluency-drills` }],
			language: location[2],
		});
	}, [history, setSideMenu, language, t]);

	const updateStateField = (field, value) => {
		if (field === "loading") {
			setLoading(value);
		}
	};

	const updateActivity = () => {
		const url = match.params.from === "learning" ? "fluencydrillactivity" : "fluencydrilldeckactivity";
		updateStateField("loading", true);
		changeActivity(lessonDetails.activityId, url).then((res) => {
			updateStateField("loading", false);
			if (res.statusCode === 200) {
				if (res.response.isCompleted) {
					setLessonDetails({
						...lessonDetails,
						isCompleted: res.response.isCompleted,
						percentageScored: res.response.percentageScored,
					});
				} else {
					setLessonDetails(res.response);
				}
			} else if (res.statusCode === 801) {
				NotificationManager.error("", res.response.message, 3000);
			}
		});
	};

	const renderModalBody = () => {
		const {
			location: { search },
		} = history;
		return (
			<>
				{lessonDetails && (
					<div className="text-center">
						<img src={complete} alt=" " className="mb-3" />
						<p className="bonus-title">
							{t("languagelearning.fluencydrills.details.completedmodal.completeddrillmsg")}
						</p>
						{match.params.from !== "learning" && (
							<p className="bonus-title">
								{t("languagelearning.fluencydrills.details.completedmodal.percentagescoredmsg", {
									percentagescored: lessonDetails.percentageScored,
								})}
							</p>
						)}
					</div>
				)}
				<div className="mt-3 d-flex justify-content-center">
					{match.params.from === "learning" ? (
						<Link
							className="link-popup"
							to={`/home/${language}/Language-Learning/${lessonDetails.lessonNumber}/FinalAct/Active?lessonId=${
								lessonDetails.lessonId
							}${search ? `&stageId=${search.split("=")[1]}` : ""}`}>
							{t("languagelearning.fluencydrills.details.completedmodal.backtolearning")}
						</Link>
					) : (
						<Link className="link-popup" to={`/home/${language}/Fluency-Drills`}>
							{t("languagelearning.fluencydrills.details.completedmodal.backtofluencydrills")}
						</Link>
					)}
				</div>
			</>
		);
	};

	return (
		<>
			<div className="dashboard d-flex fluency-drills-container empty">
				<div className="right">
					<Header
						menu={menu}
						history={history}
						head={t("languagelearning.fluencydrills.name")}
						enableLanguageSwitch
						redirectionUrl="Fluency-drills"
					/>
					{loading && <Loader />}
					<div className="d-flex details-container row">
						{lessonDetails && (
							<>
								<Grammar
									updateAcitivity={updateActivity}
									lessonDetails={lessonDetails}
									notLanguageLearning
									isFluencyDrill
									loading={loading}
								/>
								<ActivePhase
									lessonDetails={lessonDetails}
									isStudentMode={isStudentMode}
									notLanguageLearning
									updateAcitivity={updateActivity}
									updateField={() => {}}
									verifyActivity={(request) => {
										const url =
											match.params.from === "learning"
												? "fluencydrillactivity"
												: "fluencydrilldeckactivity";
										return verifyActivity(request, url);
									}}
									isFluencyDrill
								/>
							</>
						)}
					</div>
				</div>
				<SharedModal
					show={showModal}
					handleClose={() => setShowModal(null)}
					modalBody={lessonDetails && renderModalBody()}
					modalClass="test-result-popup"
					backdrop="static"
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getDetails,
	changeActivity,
	verifyActivity,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
