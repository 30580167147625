import { FLUENCY_DRILL } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";
import i18n from "../../../i18n";

export const getTabData = (tabName, language, searchTerm, deckType) => {
	let path = "";
	if (tabName === i18n.t("languagelearning.fluencydrills.lessonparadigm")) {
		path = "/forlesson";
	} else if (tabName === i18n.t("languagelearning.fluencydrills.paradigmneedpractice")) {
		path = "/forpractice";
	} else {
		path = "/forothers";
	}
	const fetchOptions = {
		url: `fluencydrilldeck${path}?language=${language === "Hebrew" ? 0 : 1}${
			searchTerm ? `&SearchTerm=${searchTerm}` : ""
		}${deckType === 1 || deckType === 0 ? `&decktype=${deckType}` : ""}`,
		method: "GET",
		actionType: FLUENCY_DRILL.GET_TAB_CONTENT,
	};
	return fetchHandler(fetchOptions);
};

export const setQuestions = (data) => {
	const fetchOptions = {
		url: `FluencyDrillSettings/change`,
		method: "PUT",
		body: JSON.stringify(data),
		actionType: FLUENCY_DRILL.CHANGE,
	};
	return fetchHandler(fetchOptions);
};

export const getDetails = (url, id) => {
	const fetchOptions = {
		url: `${url}/get/${id}`,
		method: "GET",
		actionType: FLUENCY_DRILL.GET_DETAILS,
	};
	return fetchHandler(fetchOptions);
};

export const changeActivity = (id, url) => {
	const fetchOptions = {
		url: `${url}/next/${id}`,
		method: "POST",
		actionType: FLUENCY_DRILL.CHANGE_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};

export const verifyActivity = (data, url) => {
	const fetchOptions = {
		url: `${url}/verify`,
		method: "POST",
		body: JSON.stringify(data),
		actionType: FLUENCY_DRILL.VERIFY_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};

export const archieDeck = (id) => {
	const fetchOptions = {
		url: `fluencydrilldeck/archive/${id}`,
		method: "PUT",
		actionType: FLUENCY_DRILL.ARCHIVE,
	};
	return fetchHandler(fetchOptions);
};

export const unArchieDeck = (id) => {
	const fetchOptions = {
		url: `fluencydrilldeck/unarchive/${id}`,
		method: "PUT",
		actionType: FLUENCY_DRILL.UNARCHIVE,
	};
	return fetchHandler(fetchOptions);
};

export const getSettings = (language) => {
	const fetchOptions = {
		url: `fluencydrillsettings/get?language=${language === "Hebrew" ? 0 : 1}`,
		method: "GET",
		actionType: FLUENCY_DRILL.GET_SETTINGS,
	};
	return fetchHandler(fetchOptions);
};
