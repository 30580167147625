import i18n from "../../../i18n";
export const deckList = {
  lesson: {
    menu: i18n.t("menu.lessondecks"),
    rediectionUrl: "deck/lesson",
  },
  preset: {
    menu: i18n.t("flashcarddeck.presetdeck.name"),
    rediectionUrl: "deck/preset",
  },
  wordsNeedPractise: {
    menu: i18n.t("flashcarddeck.wordsthatneedpractice.name"),
    rediectionUrl: "deck/wordsNeedPractise",
  },
  custom: {
    menu: i18n.t("menu.customdecks"),
    rediectionUrl: "deck/custom",
  },
};
