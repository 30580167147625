import React, { useState } from "react";
import { NotificationManager } from "react-notifications";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import Upload from "../../assets/images/uploadimage.png";
import { IsValidFileName } from "../../utils/formValidators";
import { useTranslation } from "react-i18next";

const MediaDropZone = ({
	uploadFormat,
	uploadedFileList,
	setUploadedFileList,
	inputWithFilesContent,
	isImageGallery,
	isVedioGallery,
	isAudioGallery,
	dropzoneClass,
	fileTimeOut,
	setFileTimeOut,
	fileSize,
	maxFiles,
	isCsvFile,
}) => {
	const [sizeTimeOut, setSizeTimeOut] = useState(null);
	const { t } = useTranslation();
	const handleChangeStatus = ({ file }, status) => {
		if (status === "done") {
			let validFileName = IsValidFileName(file && file.name);
			if (!validFileName) {
				setUploadedFileList([...uploadedFileList, file]);
			}
		}
		if (status === "removed") {
			uploadedFileList && uploadedFileList.filter((item) => item.name !== file.name);
		}
		if (status === "rejected_file_type") {
			clearTimeout(fileTimeOut);
			setFileTimeOut(
				setTimeout(() => NotificationManager.error(t("helpers.gallery.invalidfile") + " !!!", "", 2000), 1000)
			);
		}

		if (status === "error_file_size") {
			NotificationManager.error(
				t("helpers.utils.filesize.greatererr", {
					filename: `${file.name}`,
					filesize: `${Math.round(fileSize * 0.00000095367432)}`,
				}) + ` MB !!!`,
				"",
				2000
			);
		}

		if (status === "rejected_max_files") {
			clearTimeout(sizeTimeOut);
			setSizeTimeOut(
				setTimeout(
					() =>
						NotificationManager.error(
							t("helpers.utils.filelimiterr", { maxfiles: `${maxFiles}` }) + `!!!`,
							"",
							2000
						),
					1000
				)
			);
		}
	};

	const handleValidate = ({ file }) => {
		IsValidFileName(file && file.name) &&
			NotificationManager.error(t("helpers.utils.invalidfilenameformat.filename", { filename: `${file && file.name}` }), "", 3000);
		return IsValidFileName(file && file.name);
	};

	return (
		<div className={`h-100 w-100 ${dropzoneClass}`}>
			<Dropzone
				onChangeStatus={handleChangeStatus}
				accept={uploadFormat}
				inputWithFilesContent={inputWithFilesContent}
				maxSizeBytes={fileSize}
				maxFiles={maxFiles}
				validate={({ file }) => handleValidate({ file })}
				inputContent={(files, extra) =>
					extra.reject ? (
						isImageGallery ? (
							t("helpers.gallery.uploadimage")
						) : isVedioGallery ? (
							t("helpers.gallery.uploadvideoonly")
						) : isAudioGallery ? (
							t("helpers.gallery.uploadaudioonly")
						) : isCsvFile ? (
							"Upload CSV files only"
						) : (
							t("helpers.gallery.invalidfile")
						)
					) : (
						<div key={files}>
							<img src={Upload} className="mb-2" alt="" />
							<p> {t("helpers.utils.dropfile.clicktoupload")}</p>
						</div>
					)
				}
				styles={{
					dropzoneReject: {
						borderColor: "red",
						backgroundColor: "#DAA",
					},
					inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
				}}
			/>
		</div>
	);
};

export default MediaDropZone;
