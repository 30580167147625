import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";

const DateFilterComponent = ({ dataset, placeholder = "Select an Option", onSelectCB, activeFilter }) => {
	const onDateSelect = (e) => {
		onSelectCB(e);
	};

	const { t } = useTranslation();

	return (
		<Dropdown onSelect={onDateSelect}>
			<DropdownButton
				title={activeFilter ? t(`dashboard.datefilter.${activeFilter.localizationValue}`) : placeholder}
				className="date-filter">
				{dataset &&
					Object.keys(dataset).length &&
					Object.keys(dataset).map((data, index) => {
						return (
							<Dropdown.Item key={index} active={dataset[data].value === activeFilter.value} eventKey={data}>
								{t(`dashboard.datefilter.${dataset[data].localizationValue}`)}
							</Dropdown.Item>
						);
					})}
			</DropdownButton>
		</Dropdown>
	);
};

export default DateFilterComponent;
