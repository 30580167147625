import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Card, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { withRouter, Link } from "react-router-dom";

import { FormField } from "../sharedComponents/formField";
import { required, email } from "../../utils/formValidators";
import { forgotPassword } from "./actions";
import logo from "../../assets/images/logocopy.png";

import "./auth.scss";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ handleSubmit, forgotPassword, history }) => {
	const [forgotStatus, checkForgotStatus] = useState(false);
	const { t } = useTranslation();

	const handlePassword = (values) => {
		checkForgotStatus(true);
		const resetPasswordLinkURL = `${window.location.origin}/resetpassword`;
		forgotPassword({ ...values, resetPasswordLinkURL }).then((res) => {
			if (res.statusCode === 200) {
				NotificationManager.success(t("forgotpassword.notification.checkmail"), "", 3000);
				history.push("/login");
			} else {
				NotificationManager.error(res.response && res.response.message, t("helpers.utils.notification.error"), 2000);
				setTimeout(() => {
					checkForgotStatus(false);
				}, 2000);
			}
		});
	};

	return (
		<div className="d-flex w-100 login-wrapper">
			<div className="login-bg-wrapper"></div>
			<form name="LoginForm" className="login-form " onSubmit={handleSubmit(handlePassword)}>
				<Card className="p-md-5 login-section ">
					<img src={logo} className="logo-img" alt="logo" />
					<p className="forgot-intro-text mb-4 ">{t("login.authpagesforgotpasstitle")}</p>
					<p className="">{t("login.authpagesforgotpasssubtitle")}</p>
					<Field
						name="email"
						type="text"
						formGroupClass="no-label mt-2"
						placeholder={t("login.authpage.enteremail")}
						component={FormField}
						validate={[required, email]}
					/>

					<Button type="submit" className="rounded-lg border-0 mt-3 mb-3 login-btn" disabled={forgotStatus}>
						{t("login.authpagesresettitle")}
					</Button>
					<div className="d-flex justify-content-end my-3">
						<span>
							<Link to="/login" className="forgot-link mt-3 mb-3">
								{`< ${t("login.authpagesbackloginbtn")}`}
							</Link>
						</span>
					</div>
				</Card>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth.auth,
});

const mapDispatchToProps = {
	forgotPassword,
};

export default reduxForm({
	form: "LoginForm",
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)));
