import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import { getUserDetails } from "../../../../utils/verifyUser";
import { useTranslation } from "react-i18next";

const ShareDeck = ({ shareDeck, updateShareDeck, clearFormTab, saveShareDeck, groups }) => {
	const { t } = useTranslation();
	const { language, groupId, deckId, classCode, email, shareOption, decks, classCodes } = shareDeck;
	const updateField = (field, value) => {
		updateShareDeck(field, value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const payload = {
			deckID: deckId,
			language: language,
		};
		saveShareDeck(payload, shareOption);
	};

	return (
		<div className="group-settings settings-tab">
			<Form>
				<Form.Row>
					<Col>
						<Form.Group controlId="groupName">
							<Form.Label>{t("flashcarddeck.flashcardsettings.groupname")}</Form.Label>
							<Form.Control
								as="select"
								value={groupId}
								onChange={(e) => {
									updateField("groupId", parseInt(e.target.value));
								}}>
								<option></option>
								{groups &&
									groups.length &&
									groups.map((group, index) => (
										<option key={index} value={group.groupID}>
											{group.group}
										</option>
									))}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="deckID">
							<Form.Label>{t("flashcarddeck.flashcardsettings.deckname")}</Form.Label>
							<Form.Control
								as="select"
								value={deckId}
								onChange={(e) => {
									updateField("deckId", parseInt(e.target.value));
								}}>
								<option></option>
								{decks &&
									decks.length &&
									decks.map((deck, index) => (
										<option key={index} value={deck.id}>
											{deck.name}
										</option>
									))}
							</Form.Control>
						</Form.Group>
					</Col>
				</Form.Row>
				<Form.Row>
					<Col>
						<Form.Group controlId="shareOption">
							<Form.Label>{t("flashcarddeck.flashcardsettings.sharedecktab.shareoption.name")}</Form.Label>
							<Form.Control
								as="select"
								value={shareOption}
								onChange={(e) => {
									updateField("shareOption", e.target.value);
								}}>
								<option></option>

								{getUserDetails().origin === "Biblingo" && (
									<option value="classCode">
										{t(
											"flashcarddeck.flashcardsettings.sharedecktab.shareoption.options.classroomcode.name"
										)}
									</option>
								)}
								<option value="email">
									{t(
										"flashcarddeck.flashcardsettings.sharedecktab.shareoption.options.classroomcode.email.name"
									)}
								</option>
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						{shareOption && (
							<Form.Group controlId="shareData">
								{shareOption === "email" ? (
									<>
										<Form.Label>
											{t(
												"flashcarddeck.flashcardsettings.sharedecktab.shareoption.options.classroomcode.email.enteremail"
											)}
										</Form.Label>
										<Form.Control
											type={"email"}
											placeholder=""
											value={email}
											onChange={(e) => updateField("email", e.target.value)}
										/>
									</>
								) : (
									<>
										<Form.Label>
											{t(
												"flashcarddeck.flashcardsettings.sharedecktab.shareoption.options.classroomcode.enterclassroomcode.name"
											)}
										</Form.Label>
										<Form.Control
											as="select"
											value={classCode}
											onChange={(e) => {
												updateField("classCode", e.target.value);
											}}>
											<option></option>
											{classCodes &&
												classCodes.length &&
												classCodes.map((code, index) => (
													<option key={index} value={code.code}>
														{code.code}
													</option>
												))}
										</Form.Control>
									</>
								)}
							</Form.Group>
						)}
					</Col>
				</Form.Row>
				<Form.Row className="flex-row-reverse">
					<Button variant="primary" type="submit" disabled={!(classCode || email)} onClick={(e) => handleSubmit(e)}>
						{t("flashcarddeck.flashcardsettings.sharedecktab.send")}
					</Button>
					<Button className="mr-2" variant="secondary" type="cancel" onClick={(e) => clearFormTab("shareDeck", e)}>
						{t("helpers.utils.cancel")}
					</Button>
				</Form.Row>
			</Form>
		</div>
	);
};

export default ShareDeck;
