export const userFormFields = [
  {
    label: "University or organization affiliation",
    name: "universityOrOrganization",
  },
  {
    label: "Native language spoken",
    name: "nativeLanguage",
  },
  {
    label: "Other languages spoken fluently",
    name: "otherLanguages",
  },
  {
    label: "Why are you studying?",
    name: "whyAreYouStudying",
    type: "select",
  },
  {
    label: "Years studying Hebrew",
    name: "yearsStudyingHebrew",
    type: "select",
  },
  {
    label: "Years studying Greek",
    name: "yearsStudyingGreek",
    type: "select",
  },
  {
    label: "Greek pronunciation type",
    name: "greekPronouncationType",
    type: "select",
    showIcon: true,
  },
  {
    label: "Hebrew pronunciation type",
    name: "hebrewPronouncationType",
    type: "select",
    showIcon: true,
  },
];

export const hebrewPronunciationDetails = [
  {
    label: "Modern Hebrew",
    description:
      "Choose this if you think you might also want to speak Modern Hebrew.",
  },
  {
    label: "Biblical Hebrew",
    description:
      "Choose this for a reconstructed Biblical (Masoretic) pronunciation in which the pronunciation correlates more with the spelling, making it easier to learn.",
  },
];

export const greekPronunciationDetails = [
  {
    label: "Modern Greek",
    description:
      "Choose this if you think you might also want to speak Modern Greek.",
  },
  {
    label: "High Koine",
    description:
      "Choose this for a historical reconstruction used at the Polis Institute in Jerusalem—a pronunciation which correlates more with the spelling, making it easier to learn.",
  },
  {
    label: "Koine",
    description:
      "Choose this for a historical reconstruction used at the Biblical Language Center that attempts to accurately reproduce the pronunciation of the first century by some speakers of Koine Greek.",
  },
  {
    label: "Erasmian",
    description:
      "Choose this for the most widely used pronunciation of Koine Greek in academic contexts.",
  },
];
