import React, { useEffect, useReducer, useState } from "react";
import { Button, Card, Container, Form, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AsyncSelect from "react-select/async";
import { getUserDetails } from "../../../../utils/verifyUser";
import Header from "../../../header";
import Loader from "../../../sharedComponents/Loader";
import SharedModal from "../../../sharedComponents/Modal";
import { setSideMenu } from "../../../Sidemenu/actions";
import { updateField } from "../../LanguageLearning/actions";
import DisplayReview from "./displayReview.js";
import EditDeck from "./editDeck.js";
import EditGroup from "./editGroup.js";
import GenerateDeck from "./generateDeck.js";
import "./settings.scss";
import ShareDeck from "./shareDeck.js";
import FileBase64 from "./uploadBase64.js";
import {
	getDisplaySettings,
	editDisplaySettings,
	generateDeckByBible,
	generateDeckBySemantic,
	addWordintoDeck,
	getWords,
	getGroups,
	moveDeckToNewGroup,
	deleteDeckbyID,
	deleteGroupbyID,
	deleteWordbyID,
	getDecks,
	getDeckDetails,
	getBooks,
	getChapters,
	getSemanticDomainEntries,
	deckShare,
	getSearchResult,
	wordsSearch,
	updateMediaToWord,
	getClassCode,
	createCustomDecks,
} from "../actions";

const Settings = ({
	menu,
	history,
	updateField,
	setSideMenu,
	match,
	settings,
	getDisplaySettings,
	editDisplaySettings,
	generateDeckByBible,
	generateDeckBySemantic,
	addWordintoDeck,
	getWords,
	getGroups,
	moveDeckToNewGroup,
	deleteDeckbyID,
	deleteGroupbyID,
	deleteWordbyID,
	getDecks,
	getDeckDetails,
	getBooks,
	getChapters,
	getSemanticDomainEntries,
	deckShare,
	getSearchResult,
	wordsSearch,
	updateMediaToWord,
	getClassCode,
	createCustomDecks,
}) => {
	const reducer = (state, action) => {
		return { ...state, ...action };
	};

	const initialState = {
		displayReview: {
			language: match.params.language === "Hebrew" ? 0 : 1,
			defaultDisplayField: "",
			wordsPerDeck: "",
			initialStageOfReview: "",
			displayArchivedWords: false,
		},
		generateDeck: {
			language: match.params.language === "Hebrew" ? 0 : 1,
			groupName: null,
			deckName: null,
			sensesToInclude: 2,
			startFromBookID: 0,
			finishUpToBookID: 0,
			fromChapter: 0,
			toChapter: 0,
			senseIDs: null,
			wordsPerDeck: null,
			frequency: null,
			bookChaptersFrom: [],
			bookChaptersTo: [],
			selectionType: "Bible Chapters",
			senses: [],
		},
		editDeck: {
			language: match.params.language === "Hebrew" ? 0 : 1,
			groupId: "",
			deckId: "",
			words: [],
			wordId: "",
			newWord: {},
		},
		editGroup: {
			language: match.params.language === "Hebrew" ? 0 : 1,
			fromGroupId: null,
			decks: [],
			toGroupId: null,
			deckId: null,
		},
		shareDeck: {
			language: match.params.language === "Hebrew" ? 0 : 1,
			groupId: "",
			deckId: "",
			classCode: "",
			email: "",
			shareOption: "",
		},
	};
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(reducer, initialState);
	const [loading, setLoading] = useState(false);
	const [groups, setGroups] = useState([]);
	const [books, setBooks] = useState([]);
	const [activeTab, setActiveTab] = useState(t("flashcarddeck.flashcardsettings.display&reviewtab.name"));
	const [showDeckModalMove, setShowDeckModalMove] = useState(false);
	const [showDeckModalDelete, setShowDeckModalDelete] = useState(false);
	const [showGroupModalDelete, setShowGroupModalDelete] = useState(false);
	const [showWordModalDelete, setShowWordModalDelete] = useState(false);
	const [searchTerm, setSearchTerm] = useState({});
	const isBibleReading = getUserDetails().modules.isBibleReading;

	let tabsList = [t("flashcarddeck.flashcardsettings.display&reviewtab.name")];
	// Removing Custom deck for Free users - 186866
	const userTabList = [
		t("flashcarddeck.flashcardsettings.editdecktab.name"),
		t("flashcarddeck.flashcardsettings.editgrouptab.editgroup"),
		t("flashcarddeck.flashcardsettings.sharedecktab.name"),
	];
	if (getUserDetails().origin === "Biblingo")
		userTabList.splice(0, 0, t("flashcarddeck.flashcardsettings.generatedecktab.name"));
	if (getUserDetails().subscriptionPlan !== 4) tabsList = tabsList.concat(userTabList);

	const { displayReview, generateDeck, editDeck, editGroup, shareDeck } = state;

	const resetGroups = async (tab) => {
		const { response: groups } = await getGroups(match.params.language === "Hebrew" ? 0 : 1, tab);
		setGroups(groups);
	};

	const getData = async () => {
		setLoading(true);
		const { response: settings } = await getDisplaySettings(match.params.language === "Hebrew" ? 0 : 1);
		if (settings && settings !== null) {
			const updateData = {
				...displayReview,
				...settings,
			};
			dispatch({ displayReview: updateData });
		}

		const { response: books } = await getBooks(match.params.language === "Hebrew" ? "old" : "new");
		setBooks(books);
		resetGroups();

		setLoading(false);
	};

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [
				{ title: t("dashboard.name"), path: `/Home/${match.params.language}/Dashboard` },
				{ title: t("menu.flashcardsettings"), path: "" },
			],
			language: location[2],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
		getData();
	}, []);

	const createDeck = async (group, deck) => {
		setLoading(true);
		const payload = {
			group: group.label,
			name: deck,
			language: match.params.language === "Hebrew" ? 0 : 1,
		};

		const { response, statusCode } = await createCustomDecks(payload);
		if (statusCode === 200) {
			setLoading(false);
			const { response: groups } = await getGroups(match.params.language === "Hebrew" ? 0 : 1);
			const currentGroup = groups && groups.find((item) => item.group === group.label);
			setGroups(groups);
			const customDeck = { deck: deck, groupId: currentGroup.groupID };
			updateEditDeck("groupId", currentGroup.groupID, customDeck);
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.newcustomdeck"), "", 3000);
		} else {
			setLoading(false);
			NotificationManager.error(response.message ? response.message : t("helpers.utils.notification.error"), "", 3000);
		}
	};

	const clearFormTab = (tab, e) => {
		if (e) {
			e.preventDefault();
		}
		switch (tab) {
			case "displayReview":
				dispatch({
					displayReview: {
						language: match.params.language === "Hebrew" ? 0 : 1,
						defaultDisplayField: "",
						wordsPerDeck: "",
						initialStageOfReview: "",
						displayArchivedWords: false,
					},
				});
				break;
			case "generateDeck":
				dispatch({
					generateDeck: {
						language: match.params.language === "Hebrew" ? 0 : 1,
						groupName: null,
						deckName: null,
						sensesToInclude: 2,
						startFromBookID: 0,
						finishUpToBookID: 0,
						fromChapter: 0,
						toChapter: 0,
						senseIDs: null,
						wordsPerDeck: null,
						frequency: null,
						bookChaptersFrom: [],
						bookChaptersTo: [],
						selectionType: "Bible Chapters",
						senses: [],
					},
				});
				break;
			case "editDeck":
				dispatch({
					editDeck: {
						language: match.params.language === "Hebrew" ? 0 : 1,
						groupId: "",
						deckId: "",
						words: [],
						wordId: "",
						newWord: {},
					},
				});
				break;
			case "editGroup":
				dispatch({
					editGroup: {
						language: match.params.language === "Hebrew" ? 0 : 1,
						fromGroupId: null,
						decks: [],
						toGroupId: null,
						deckId: null,
					},
				});
				break;
			case "shareDeck":
				dispatch({
					shareDeck: {
						language: match.params.language === "Hebrew" ? 0 : 1,
						groupId: "",
						deckId: "",
						classCode: "",
						email: "",
						shareOption: "",
					},
				});
				break;
			default:
				throw new Error();
		}
	};

	const updateDisplayReview = (field, value) => {
		const updateData = {
			...displayReview,
			[field]: value,
		};
		dispatch({ displayReview: updateData });
	};

	const updateGenerateDeck = async (field, value) => {
		const updateData = {
			...generateDeck,
			[field]: value,
		};

		if (field === "startFromBookID") {
			setLoading(true);
			const { response: chapters } = await getChapters(value);
			updateData["bookChaptersFrom"] = chapters.chapters;
			updateData["fromChapter"] = 1;
			setLoading(false);
		}
		if (field === "finishUpToBookID") {
			setLoading(true);
			const { response: chapters } = await getChapters(value);
			updateData["bookChaptersTo"] = chapters.chapters;
			updateData["toChapter"] = 1;
			setLoading(false);
		}

		if (field === "selectionType") {
			if (value === "Bible Chapters") {
				updateData["senseIDs"] = null;
			} else {
				updateData["bookChaptersFrom"] = [];
				updateData["bookChaptersTo"] = [];
				updateData["toChapter"] = 0;
				updateData["fromChapter"] = 0;
				updateData["startFromBookID"] = 0;
				updateData["finishUpToBookID"] = 0;
			}
		}

		dispatch({ generateDeck: updateData });
	};

	const updateEditDeck = async (field, value, customDeck) => {
		const { newWord } = editDeck;
		const updateData = {
			...editDeck,
		};

		if (field === "groupId") {
			setSearchTerm({});
			const { response: decks } = await getDecks(match.params.language === "Hebrew" ? 0 : 1, value);
			if (customDeck) {
				const currentDeck = decks && decks.find((item) => item.name === customDeck.deck);
				customDeck["deckList"] = decks;
				updateData["deckId"] = currentDeck.id;
				updateData["words"] = [];
				updateEditDeck("deckId", currentDeck.id, customDeck);
			} else {
				updateData["deckId"] = "";
			}
			updateData["words"] = [];
			updateData["decks"] = decks;
			updateData[field] = value;
			setLoading(false);
		} else if (field === "deckId") {
			setSearchTerm({});
			const { response: words } = await getWords(value);
			if (customDeck) {
				updateData["deckId"] = value;
				updateData["groupId"] = customDeck.groupId;
				updateData["decks"] = customDeck.deckList;
			} else {
				updateData[field] = value;
			}
			updateData["words"] = words;
			setLoading(false);
		} else if (field === "gloss") {
			newWord["gloss"] = value;
			updateData["newWord"] = newWord;
		} else if (field === "definition") {
			newWord["definition"] = value;
			updateData["newWord"] = newWord;
		} else if (field === "examplesRef") {
			newWord.example.reference = value;
			updateData["newWord"] = newWord;
		} else if (field === "examplesVer") {
			newWord.example.verse = value;
			updateData["newWord"] = newWord;
		} else if (field === "newWord") {
			updateData.newWord = value;
		} else if (field === "audio") {
			newWord.audio.fileName = value.name;
			newWord.audio.file = value;
			updateData["newWord"] = newWord;
		} else if (field === "image") {
			newWord.illustration.fileName = value.name;
			newWord.illustration.file = value;
			updateData["newWord"] = newWord;
		} else {
			updateData[field] = value;
		}

		dispatch({ editDeck: updateData });
	};

	const updateEditGroup = async (field, value) => {
		const updateData = {
			...editGroup,
			[field]: value,
		};
		if (field === "fromGroupId") {
			setLoading(true);
			const { response: decks } = await getDecks(match.params.language === "Hebrew" ? 0 : 1, value);
			updateData["decks"] = decks;
			setLoading(false);
		}
		dispatch({ editGroup: updateData });
	};

	const updateShareDeck = async (field, value) => {
		const updateData = {
			...shareDeck,
			[field]: value,
		};
		if (field === "groupId") {
			setLoading(true);
			const { response: decks } = await getDecks(match.params.language === "Hebrew" ? 0 : 1, value);
			updateData["decks"] = decks;
			setLoading(false);
		} else if (field === "shareOption") {
			setLoading(true);
			const { response: codes } = await getClassCode(match.params.language === "Hebrew" ? 0 : 1);
			updateData["classCodes"] = codes;
			setLoading(false);
		} else {
			updateData[field] = value;
		}
		dispatch({ shareDeck: updateData });
	};

	const changeTab = async (tab) => {
		setLoading(true);
		setActiveTab(tab);
		resetGroups(tab);
		setLoading(false);
	};

	const saveDisplayReview = async (payload) => {
		setLoading(true);
		const { response, statusCode } = await editDisplaySettings(payload);
		setLoading(false);
		if (statusCode === 200) {
			const updateData = {
				...displayReview,
				...response,
			};
			dispatch({ displayReview: updateData });
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.savedisplayreview"), "", 3000);
		} else {
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}
	};

	const saveGenerateDeck = async (payload, selectionType) => {
		setLoading(true);
		if (selectionType === "Bible Chapters") {
			const { response, statusCode } = await generateDeckByBible(payload);
			setLoading(false);
			const { numberOfDecks, numberOfwords } = response;
			if (statusCode === 200) {
				if (numberOfDecks === 0 || numberOfwords === 0) {
					NotificationManager.error(
						t("flashcarddeck.flashcardsettings.notification.savegeneratedeckerror"),
						"",
						3000
					);
				} else {
					NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.savegeneratedeck"), "", 3000);
				}
				clearFormTab("generateDeck");
			} else {
				response && response.message && NotificationManager.error(response.message, "", 3000);
			}
		} else {
			const { response, statusCode } = await generateDeckBySemantic(payload);
			setLoading(false);
			const { numberOfDecks, numberOfwords } = response;
			if (statusCode === 200) {
				if (numberOfDecks === 0 || numberOfwords === 0) {
					NotificationManager.error(
						t("flashcarddeck.flashcardsettings.notification.savegeneratedeckerror"),
						"",
						3000
					);
				} else {
					NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.savegeneratedeck"), "", 3000);
				}
				clearFormTab("generateDeck");
			} else {
				response && response.message && NotificationManager.error(response.message, "", 3000);
			}
		}
	};

	const saveShareDeck = async (payload, shareOption) => {
		setLoading(true);
		payload["shareDeckByType"] = shareOption === "classCode" ? 0 : 1;
		payload["shareDeckByParam"] = shareOption === "classCode" ? shareDeck.classCode : shareDeck.email;
		const { response, statusCode } = await deckShare(payload);
		setLoading(false);
		if (statusCode === 200) {
			clearFormTab("shareDeck");
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.savesharedeck"), "", 3000);
		} else {
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}
	};

	const moveDeckConfirm = async () => {
		setLoading(true);
		const payload = {
			language: match.params.language === "Hebrew" ? 0 : 1,
			currentGroupID: editGroup.fromGroupId,
			newGroupID: editGroup.toGroupId,
			deckID: editGroup.deckId,
		};
		const { response, statusCode } = await moveDeckToNewGroup(payload);
		if (statusCode === 200) {
			const updateData = {
				...editGroup,
				decks: editGroup.decks.filter((deck) => deck.id !== editGroup.deckId),
				toGroupId: "",
			};
			dispatch({ editGroup: updateData });
			setShowDeckModalMove(false);
			setLoading(false);
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.movedeckconfirm"), "", 3000);
		} else {
			setLoading(false);
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}
	};

	const deleteGroupConfirm = async () => {
		setLoading(true);
		const { response, statusCode } = await deleteGroupbyID(editGroup.fromGroupId);
		setShowGroupModalDelete(false);
		if (statusCode === 200) {
			const updateData = {
				...editGroup,
				decks: [],
				toGroupId: "",
				fromGroupId: "",
			};
			dispatch({ editGroup: updateData });
			setLoading(false);
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.deletegroupconfirm"), "", 3000);
		} else {
			setLoading(false);
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}
	};

	const deleteDeckConfirm = async () => {
		setLoading(true);
		const { response, statusCode } = await deleteDeckbyID(editGroup.deckId);
		setShowDeckModalDelete(false);
		if (statusCode === 200) {
			const updateData = {
				...editGroup,
				toGroupId: "",
			};
			const { response: decks } = await getDecks(match.params.language === "Hebrew" ? 0 : 1, editGroup.fromGroupId);
			updateData["decks"] = decks;
			dispatch({ editGroup: updateData });
			setLoading(false);
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.deletedeckconfirm"), "", 3000);
		} else {
			setLoading(false);
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}
	};

	const deleteWordConfirm = async () => {
		setLoading(true);
		const { response, statusCode } = await deleteWordbyID(editDeck.wordId);
		setShowWordModalDelete(false);
		if (statusCode === 200) {
			const updateData = {
				...editDeck,
				wordId: "",
			};
			const { response: words } = await getWords(editDeck.deckId);
			updateData["words"] = words;
			dispatch({ editDeck: updateData });
			setLoading(false);
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.deletewordconfirm"), "", 3000);
		} else {
			setLoading(false);
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}
	};

	const renderModalHead = (head) => {
		let title;
		switch (head) {
			case "moveDeck":
				title = t("flashcarddeck.flashcardsettings.modalhead.movedeck");
				break;
			case "deleteDeck":
				title = t("flashcarddeck.flashcardsettings.modalhead.deletedeck");
				break;
			case "deleteGroup":
				title = t("flashcarddeck.flashcardsettings.modalhead.deletegroup");
				break;
			case "deleteWord":
				title = t("flashcarddeck.flashcardsettings.modalhead.deleteword");
				break;
			default:
				break;
		}
		return (
			<div className="video-head">
				<p className="text-center">{title}</p>
			</div>
		);
	};

	const renderModalBody = (head) => {
		switch (head) {
			case "moveDeck":
				const moveGroups = groups.filter((group) => group.groupID !== editGroup.fromGroupId && !group.isShared);
				return (
					<Form.Group controlId="moveGroup" className="p-4">
						<Form.Label>{t("flashcarddeck.flashcardsettings.modalbody.movedeck")}</Form.Label>
						<Form.Control
							as="select"
							value={editGroup.toGroupId}
							onChange={(e) => {
								updateEditGroup("toGroupId", parseInt(e.target.value));
							}}>
							<option></option>
							{moveGroups &&
								moveGroups.length &&
								moveGroups.map((group, index) => (
									<option key={index} value={group.groupID}>
										{group.group}
									</option>
								))}
						</Form.Control>
					</Form.Group>
				);
			case "deleteDeck":
				return (
					<div className="text-center">
						<p className="mt-2 addvideo-bottom">{t("flashcarddeck.flashcardsettings.modalbody.deletedeck")}</p>
					</div>
				);
			case "deleteGroup":
				return (
					<div className="text-center">
						<p className="mt-2 addvideo-bottom">{t("flashcarddeck.flashcardsettings.modalbody.deletegroup")}</p>
					</div>
				);
			case "deleteWord":
				return (
					<div className="text-center">
						<p className="mt-2 addvideo-bottom">{t("flashcarddeck.flashcardsettings.modalbody.deleteword")}</p>
					</div>
				);
			default:
				break;
		}
	};

	const renderModalFooter = (head) => {
		if (head === "deleteDeck") {
			return (
				<div className="text-center mb-4 d-flex">
					<Button variant="secondary mr-2" onClick={() => setShowDeckModalDelete(false)}>
						{t("helpers.utils.cancel")}
					</Button>
					<Button variant="primary" onClick={() => deleteDeckConfirm()}>
						{t("helpers.utils.delete")}
					</Button>
				</div>
			);
		} else if (head === "moveDeck") {
			return (
				<div className="text-center mb-4 d-flex">
					<Button variant="secondary mr-2" onClick={() => setShowDeckModalMove(false)}>
						{t("helpers.utils.cancel")}
					</Button>
					<Button variant="primary" disabled={editGroup.toGroupId === null} onClick={() => moveDeckConfirm()}>
						{t("helpers.utils.move")}
					</Button>
				</div>
			);
		} else if (head === "deleteGroup") {
			return (
				<div className="text-center mb-4 d-flex">
					<Button variant="secondary mr-2" onClick={() => setShowGroupModalDelete(false)}>
						{t("helpers.utils.cancel")}
					</Button>
					<Button variant="primary" onClick={() => deleteGroupConfirm()}>
						{t("helpers.utils.delete")}
					</Button>
				</div>
			);
		} else if (head === "deleteWord") {
			return (
				<div className="text-center mb-4 d-flex">
					<Button variant="secondary mr-2" onClick={() => setShowWordModalDelete(false)}>
						{t("helpers.utils.cancel")}
					</Button>
					<Button variant="primary" onClick={() => deleteWordConfirm()}>
						{t("helpers.utils.delete")}
					</Button>
				</div>
			);
		}
	};

	const toggleDeckMoveModal = () => {
		setShowDeckModalMove(!showDeckModalMove);
	};

	const toggleDeckDeleteModal = () => {
		setShowDeckModalDelete(!showDeckModalDelete);
	};

	const toggleGroupDeleteModal = () => {
		setShowGroupModalDelete(!showGroupModalDelete);
	};

	const toggleWordDeleteModal = () => {
		setShowWordModalDelete(!showWordModalDelete);
	};

	const getModal = (tab, id) => {
		switch (tab) {
			case "moveDeck":
				return (
					<SharedModal
						show={showDeckModalMove}
						modalHead={renderModalHead("moveDeck")}
						modalBody={renderModalBody("moveDeck")}
						modalFooter={renderModalFooter("moveDeck")}
						handleClose={toggleDeckMoveModal}
						modalClass="settings-modal"
						id={1}
					/>
				);
			case "deleteDeck":
				return (
					<SharedModal
						show={showDeckModalDelete}
						modalHead={renderModalHead("deleteDeck")}
						modalBody={renderModalBody("deleteDeck")}
						modalFooter={renderModalFooter("deleteDeck")}
						handleClose={toggleDeckDeleteModal}
						modalClass="settings-modal"
						id={2}
					/>
				);
			case "deleteGroup":
				return (
					<SharedModal
						show={showGroupModalDelete}
						modalHead={renderModalHead("deleteGroup")}
						modalBody={renderModalBody("deleteGroup")}
						modalFooter={renderModalFooter("deleteGroup")}
						handleClose={toggleGroupDeleteModal}
						modalClass="settings-modal"
						id={3}
					/>
				);
			case "deleteWord":
				return (
					<SharedModal
						show={showWordModalDelete}
						modalHead={renderModalHead("deleteWord")}
						modalBody={renderModalBody("deleteWord")}
						modalFooter={renderModalFooter("deleteWord")}
						handleClose={toggleWordDeleteModal}
						modalClass="settings-modal"
						id={4}
					/>
				);
			default:
				break;
		}
	};

	const selectWord = async (searchTerm, callback) => {
		const { response: records } = await wordsSearch(match.params.language, searchTerm);
		let options = [];
		if (records && records.length) {
			options = records.map((word) => ({
				...word,
				label: word.word,
				value: word.entryRefId,
			}));
		} else {
			options = [
				{
					label: searchTerm,
					value: "",
				},
			];
		}
		callback(options);
	};

	const getFileName = (pathName) => {
		const pathArr = pathName.split("/");
		const len = pathArr.length - 1;
		if (len > 0) {
			return pathArr[len].split(".")[0];
		} else {
			return "";
		}
	};

	const searchWord = async () => {
		if (editDeck.words && editDeck.words.length < 25) {
			if (searchTerm && searchTerm.entryRefId) {
				const newWord = {
					...searchTerm,
					deckId: editDeck.deckId,
					text: searchTerm.label,
					audio: {
						fileName: searchTerm.audioFilePath && getFileName(searchTerm.audioFilePath),
						file: searchTerm.audioFilePath,
					},
					illustration: {
						fileName: searchTerm.illustrationFilePath && getFileName(searchTerm.illustrationFilePath),
						file: searchTerm.illustrationFilePath,
					},
				};
				const updateData = {
					...editDeck,
					newWord: newWord,
				};
				dispatch({ editDeck: updateData });
			} else {
				const newWord = {
					deckId: editDeck.deckId,
					text: searchTerm.label,
					gloss: "",
					definition: "",
					audio: {
						fileName: "",
						file: "",
					},
					illustration: {
						fileName: "",
						file: "",
					},
					example: {
						reference: "",
						verse: "",
					},
					senseID: null,
					entryRefId: null,
				};
				const updateData = {
					...editDeck,
					newWord: newWord,
				};
				dispatch({ editDeck: updateData });
			}
		} else {
			NotificationManager.error(t("flashcarddeck.flashcardsettings.notification.searchworderror"), "", 3000);
		}
	};

	const asyncSearch = () => {
		return (
			<div className="d-flex word-search">
				<AsyncSelect
					noOptionsMessage={() => t("helpers.utils.nooptions")}
					loadingMessage={() => t("helpers.utils.learning.askquestion.loading")}
					className="react-select"
					classNamePrefix="react-select"
					isClearable={true}
					placeholder={t("biblereading.select")}
					onChange={setSearchTerm}
					loadOptions={selectWord}
				/>
				<Button
					varinat="primary"
					disabled={!searchTerm || !searchTerm.label || editDeck.groupId.length === 0 || editDeck.deckId.length === 0}
					onClick={searchWord}>
					{t("flashcarddeck.flashcardsettings.generatedecktab.search&add")}
				</Button>
			</div>
		);
	};

	const newWordMediaUpload = (file, index, type) => {
		const { newWord } = editDeck;
		const updateData = {
			...editDeck,
		};
		if (file) {
			if (type === "audio") {
				const audio = {
					fileName: file.name,
					file: file.base64.split(",")[1],
				};
				newWord["audio"] = audio;
				updateData["newWord"] = newWord;
				dispatch({ editDeck: updateData });
			} else {
				const illustration = {
					fileName: file.name,
					file: file.base64.split(",")[1],
				};
				newWord["illustration"] = illustration;
				updateData["newWord"] = newWord;
				dispatch({ editDeck: updateData });
			}
		}
	};

	const getUploadComponent = (type, callbackFn = newWordMediaUpload, index) => {
		if (type === "audio") {
			return <FileBase64 acceptType={".wav,.mp3"} uploadCallBack={callbackFn} type={"audio"} index={index} />;
		} else {
			return <FileBase64 acceptType={".jpg,.jpeg,.png"} uploadCallBack={callbackFn} type={"image"} index={index} />;
		}
	};

	const addNewWordDeck = async () => {
		const updateData = {
			...editDeck,
		};
		setLoading(true);
		const { newWord } = editDeck;

		if (newWord.entryRefId) {
			if (newWord && newWord.illustrationFilePath !== null) {
				delete newWord.illustration;
			} else {
				delete newWord.illustrationFilePath;
			}
			if (newWord && newWord.audioFilePath !== null) {
				delete newWord.audio;
			} else {
				delete newWord.audioFilePath;
			}
		}

		const { response, statusCode } = await addWordintoDeck(newWord);
		if (statusCode === 200) {
			const { response: words } = await getWords(editDeck.deckId);
			updateData["words"] = words;
			NotificationManager.success(
				`${
					editDeck.newWord && editDeck.newWord.senseID && editDeck.newWord.senseID.length
						? t("flashcarddeck.flashcardsettings.notification.wordadded")
						: t("flashcarddeck.flashcardsettings.notification.newwordadded")
				}`,
				"",
				3000
			);
			updateData["newWord"] = {};
		} else {
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}

		dispatch({ editDeck: updateData });
		setLoading(false);
	};

	const uploadMediaToWord = async (payload) => {
		const updateData = {
			...editDeck,
		};
		setLoading(true);
		const { response, statusCode } = await updateMediaToWord(payload);
		if (statusCode === 200) {
			const { response: words } = await getWords(editDeck.deckId);
			updateData["words"] = words;
			NotificationManager.success(t("flashcarddeck.flashcardsettings.notification.updatedmedia"), "", 3000);
		} else {
			response && response.message && NotificationManager.error(response.message, "", 3000);
		}
		dispatch({ editDeck: updateData });
		setLoading(false);
	};

	const getActiveSettings = () => {
		switch (activeTab) {
			case t("flashcarddeck.flashcardsettings.display&reviewtab.name"):
				return (
					<DisplayReview
						displayReview={displayReview}
						updateDisplayReview={updateDisplayReview}
						clearFormTab={clearFormTab}
						saveDisplayReview={saveDisplayReview}
					/>
				);
			case t("flashcarddeck.flashcardsettings.generatedecktab.name"):
				return (
					<GenerateDeck
						generateDeck={generateDeck}
						updateGenerateDeck={updateGenerateDeck}
						clearFormTab={clearFormTab}
						saveGenerateDeck={saveGenerateDeck}
						books={books}
						getChapters={getChapters}
						setLoading={setLoading}
						getSemanticDomainEntries={getSemanticDomainEntries}
						match={match}
					/>
				);
			case t("flashcarddeck.flashcardsettings.editdecktab.name"):
				return (
					<EditDeck
						groups={groups}
						clearFormTab={clearFormTab}
						updateEditDeck={updateEditDeck}
						editDeck={editDeck}
						setShowWordModalDelete={setShowWordModalDelete}
						asyncSearch={asyncSearch}
						getUploadComponent={getUploadComponent}
						addNewWordDeck={addNewWordDeck}
						uploadMediaToWord={uploadMediaToWord}
						setSearchTerm={setSearchTerm}
						createDeck={createDeck}
					/>
				);
			case t("flashcarddeck.flashcardsettings.editgrouptab.editgroup"):
				return (
					<EditGroup
						editGroup={editGroup}
						updateEditGroup={updateEditGroup}
						clearFormTab={clearFormTab}
						groups={groups}
						deleteDeckbyID={deleteDeckbyID}
						moveDeckToNewGroup={moveDeckToNewGroup}
						deleteGroupbyID={deleteGroupbyID}
						setLoading={setLoading}
						setShowGroupModalDelete={setShowGroupModalDelete}
						setShowDeckModalMove={setShowDeckModalMove}
						setShowDeckModalDelete={setShowDeckModalDelete}
					/>
				);
			case t("flashcarddeck.flashcardsettings.sharedecktab.name"):
				return (
					<ShareDeck
						shareDeck={shareDeck}
						updateShareDeck={updateShareDeck}
						clearFormTab={clearFormTab}
						saveShareDeck={saveShareDeck}
						groups={groups}
					/>
				);
			default:
				break;
		}
	};
	const getTabContent = (tabName) => {
		return (
			<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
				<Container fluid style={{ paddingLeft: 10, paddingRight: 10, height: "100%" }}>
					<Card className="no-shadow settings-wrapper" style={{ height: "100%" }}>
						{getActiveSettings()}
						{["moveDeck", "deleteDeck", "deleteGroup"].map((modal, index) => getModal(modal, index))}
						<SharedModal
							show={showWordModalDelete}
							modalHead={renderModalHead("deleteWord")}
							modalBody={renderModalBody("deleteWord")}
							modalFooter={renderModalFooter("deleteWord")}
							handleClose={toggleWordDeleteModal}
							modalClass="settings-modal"
						/>
					</Card>
				</Container>
			</Tab>
		);
	};

	return (
		<div className="settings-container empty">
			<Header
				menu={menu}
				language={match.params.language}
				history={history}
				head={t("menu.flashcardsettings")}
				enableLanguageSwitch
				redirectionUrl="Dashboard"
			/>
			{loading && <Loader />}
			<div className="flashcard-settings">
				{!loading && (
					<Tabs
						className="default-tabs"
						activeKey={activeTab}
						onSelect={(tab) => {
							changeTab(tab);
						}}>
						{isBibleReading
							? tabsList.map((tabName, index) => getTabContent(tabName, index))
							: tabsList
									.filter(
										(tabName, index) =>
											tabName !== t("flashcarddeck.flashcardsettings.generatedecktab.name")
									)
									.map((item) => getTabContent(item))}
					</Tabs>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({ menu: state.sideMenu.menu });
const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getDisplaySettings,
	editDisplaySettings,
	generateDeckByBible,
	generateDeckBySemantic,
	addWordintoDeck,
	getWords,
	getGroups,
	moveDeckToNewGroup,
	deleteDeckbyID,
	deleteGroupbyID,
	deleteWordbyID,
	getDecks,
	getDeckDetails,
	getBooks,
	getChapters,
	getSemanticDomainEntries,
	deckShare,
	getSearchResult,
	wordsSearch,
	updateMediaToWord,
	getClassCode,
	createCustomDecks,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
