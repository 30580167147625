import React from "react";
import Modal from "react-bootstrap/Modal";

const SharedModal = ({
	handleClose,
	show,
	modalHead,
	modalBody,
	modalClass,
	modalBodyClass,
	modalFooter,
	id,
	icon,
	centered = false,
	disableHeader = false,
	backdrop,
	disableRebound,
}) => {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			animation={false}
			className={`${!disableRebound && "rebound"} ${modalClass}`}
			centered
			backdrop={backdrop}
			id={id}>
			{!disableHeader && (
				<Modal.Header>
					<Modal.Title> {modalHead} </Modal.Title>
					<div className="modal-close-btn" onClick={handleClose}>
						<i className={`icon ${icon ? icon : "icon-close"} `}></i>
					</div>
				</Modal.Header>
			)}
			<Modal.Body className={modalBodyClass}>{modalBody}</Modal.Body>
			{modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
		</Modal>
	);
};

export default SharedModal;
