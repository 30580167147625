import { USERS } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export const fetchLearningReason = () => {
	const fetchOptions = {
		url: `reasons/all`,
		method: "GET",
		actionType: USERS.FETCH_LEARNING_REASONS,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export function updateField(field, val) {
	return {
		type: USERS.UPDATE_FIELD,
		field,
		val,
	};
}

export const deleteUser = (url) => {
	const fetchOptions = {
		url: url,
		method: "DELETE",
		actionType: USERS.DELETE_USER,
	};

	return fetchHandler(fetchOptions);
};

export const resumeLessonProgress = (payload) => {
	const fetchOptions = {
		url: "lesson/progress",
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: USERS.RESUME_LESSON_PROGRESS,
	};

	return fetchHandler(fetchOptions);
};

export const changeUserStatus = (payload) => {
	const fetchOptions = {
		url: "user/status",
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: USERS.CHANGE_STATUS,
	};

	return fetchHandler(fetchOptions);
};

export const getUserRole = () => {
	const fetchOptions = {
		url: "user/profile",
		method: "GET",
		actionType: USERS.GET_ROLE,
	};

	return fetchHandler(fetchOptions);
};

export const fetchUsers = (url) => {
	const fetchOptions = {
		url: url,
		method: "GET",
		actionType: USERS.FETCH_USERS,
	};

	return fetchHandler(fetchOptions);
};

export const fetchLessons = (id, lang) => {
	const fetchOptions = {
		url: `student/lessons?UserId=${id}&Language=${lang}`,
		method: "GET",
		actionType: USERS.FETCH_LESSONS,
	};

	return fetchHandler(fetchOptions);
};

export const fetchUserDetails = (id, student) => {
	const fetchOptions = {
		url: `${student ? "student" : "internaluser"}/get/${id}`,
		method: "GET",
		actionType: USERS.FETCH_USER_DETAILS,
	};

	return fetchHandler(fetchOptions);
};

export const fetchlanguagePreference = () => {
	const fetchOptions = {
		url: `ENUM/all?Type=Language`,
		method: "GET",
		actionType: USERS.FETCH_LANGUAGE_PREFERENCE,
	};

	return fetchHandler(fetchOptions);
};

export const fetchCountryList = () => {
	const fetchOptions = {
		url: `countries/all`,
		method: "GET",
		actionType: USERS.FETCH_COUNTRY_LIST,
	};

	return fetchHandler(fetchOptions);
};

export const fetchUserRoles = () => {
	const fetchOptions = {
		url: `ENUM/all?Type=InternalUserRole`,
		method: "GET",
		actionType: USERS.FETCH_USER_ROLES,
	};

	return fetchHandler(fetchOptions);
};

export const fetchGreekPronouncations = () => {
	const fetchOptions = {
		url: `ENUM/all?Type=GreekPronunciationType`,
		method: "GET",
		actionType: USERS.FETCH_GREEK_PRONOUNCATION,
	};

	return fetchHandler(fetchOptions);
};

export const fetchHebrewPronouncations = () => {
	const fetchOptions = {
		url: `ENUM/all?Type=HebrewPronunciationType`,
		method: "GET",
		actionType: USERS.FETCH_HEBREW_PRONOUNCATION,
	};

	return fetchHandler(fetchOptions);
};

export const createUser = (url, method, payload) => {
	const fetchOptions = {
		url: url,
		method: method,
		body: JSON.stringify(payload),
		actionType: USERS.CREATE_USER,
	};

	return fetchHandler(fetchOptions);
};

export const getClassroomData = (url) => {
	const fetchOptions = {
		url: `classroom/${url}`,
		method: "GET",
		actionType: USERS.GET_CLASSROOM_DATA,
	};

	return fetchHandler(fetchOptions);
};

export const getClassroomCode = () => {
	const fetchOptions = {
		url: `classroom/getcode`,
		method: "GET",
		actionType: USERS.GET_CLASSROOM_CODE,
	};

	return fetchHandler(fetchOptions);
};

export const createClassroom = (payload) => {
	const fetchOptions = {
		url: `classroom/create`,
		method: "POST",
		body: JSON.stringify(payload),
		actionType: USERS.CREATE_CLASSROOM,
	};

	return fetchHandler(fetchOptions);
};

export const updateClassroom = (payload, url) => {
	const fetchOptions = {
		url: `classroom/${url}`,
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: USERS.UPDATE_CLASSROOM,
	};

	return fetchHandler(fetchOptions);
};

export const deleteClassroom = (id, url) => {
	const fetchOptions = {
		url: `classroom/${url}?id=${id}`,
		method: "DELETE",
		actionType: USERS.DELETE_CLASSROOM,
	};

	return fetchHandler(fetchOptions);
};
export const toggleSpeaking = (id, language, isRecordingAllowed) => {
	const fetchOptions = {
		url: `student/speaking/update`,
		method: "PUT",
		body: JSON.stringify({ id, language, isRecordingAllowed }),
		actionType: USERS.CREATE_CLASSROOM,
	};

	return fetchHandler(fetchOptions);
};


export const getALlCultures = () => {
	const fetchOptions = {
		url: `localization/cultures/all`,
		method: "GET",
		actionType: USERS.FETCH_CULTURES,
	};

	return fetchHandler(fetchOptions);
};