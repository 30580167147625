import { FLASH_CARD_DECK } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";

export const getDeckList = (language, deck, group, isCustomDeck, isGenerated, isShared) => {
	const fetchOptions = {
		url: `${deck === "WordsNeedPractise" ? "practicedeck" : "deck"}/overview?Language=${language}&DeckType=${deck}
		${group ? `&Group=${group.trim()}` : ""} 
		${typeof isCustomDeck !== undefined && isCustomDeck !== undefined ? `&IsCustomDeck=${isCustomDeck}` : ""} 
		${typeof isGenerated !== undefined && isGenerated !== undefined ? `&IsGenerated=${isGenerated}` : ""} ${
			typeof isShared !== undefined && isShared !== undefined ? `&IsShared=${isShared}` : ""
		}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_DECK_LIST,
	};

	return fetchHandler(fetchOptions);
};

export const getDeckDetails = (id, isRestart) => {
	const fetchOptions = {
		url: `deck/get?Id=${id}&IsRestart=${isRestart}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_DECK_DETAILS,
	};

	return fetchHandler(fetchOptions);
};

export const getDeckGroups = (Language, DeckType) => {
	const fetchOptions = {
		url: `deck/groups?Language=${Language}&DeckType=${DeckType}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_DECK_GROUPS,
	};

	return fetchHandler(fetchOptions);
};

export const archiveword = (id) => {
	const fetchOptions = {
		url: `practicedeck/archive/${id}`,
		method: "DELETE",
		actionType: FLASH_CARD_DECK.ARCHIVE_WORD,
	};

	return fetchHandler(fetchOptions);
};

export const updateDeckField = (field, value) => {
	return {
		type: FLASH_CARD_DECK.UPDATE_DECK_FIELD,
		field,
		value,
	};
};

export const reset = () => {
	return {
		type: FLASH_CARD_DECK.RESET,
	};
};

//////////////////Settings APIs/////////////////////

export const getDisplaySettings = (Language) => {
	const fetchOptions = {
		url: `flashcarddecksettings/get?Language=${Language}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_SETTINGS,
	};
	return fetchHandler(fetchOptions);
};

export const getGroups = (language, tab) => {
	const fetchOptions = {
		url: `flashcarddecksettings/groups?Language=${language}${
			tab && ["Share Deck", "Edit Group"].includes(tab) ? "&DisplaySharedGroups=true" : "&DisplaySharedGroups=false"
		}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_GROUPS,
	};
	return fetchHandler(fetchOptions);
};

export const getDecks = (language, groupID) => {
	const fetchOptions = {
		url: `flashcarddecksettings/decks?Language=${language}&GroupID=${groupID}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_DECKS,
	};
	return fetchHandler(fetchOptions);
};

export const getWords = (id) => {
	const fetchOptions = {
		url: `flashcarddecksettings/words?id=${id}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_WORDS,
	};
	return fetchHandler(fetchOptions);
};

export const getSemanticDomainEntries = (language, SensesToInclude, SearchTerm) => {
	const fetchOptions = {
		url: `flashcarddecksettings/semanticdomainentries?Language=${language}&SensesToInclude=${SensesToInclude}&SearchTerm=${SearchTerm}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_SEMANTIC_DOMAIN_ENTRIES,
	};
	return fetchHandler(fetchOptions);
};

export const addWordintoDeck = (payload) => {
	const fetchOptions = {
		url: `flashcarddecksettings/word/add`,
		method: "POST",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.ADD_WORDS,
	};
	return fetchHandler(fetchOptions);
};

export const generateDeckByBible = (payload) => {
	const fetchOptions = {
		url: `flashcarddecksettings/deck/generatebybiblebook`,
		method: "POST",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.ADD_DECKS,
	};
	return fetchHandler(fetchOptions);
};

export const generateDeckBySemantic = (payload) => {
	const fetchOptions = {
		url: `flashcarddecksettings/deck/generatebysemanticdomain`,
		method: "POST",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.ADD_DECKS,
	};
	return fetchHandler(fetchOptions);
};

export const getBooks = (testament) => {
	const fetchOptions = {
		url: `bible/books?Testament=${testament}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_BOOK_LIST,
	};
	return fetchHandler(fetchOptions);
};

export const getChapters = (id) => {
	const fetchOptions = {
		url: `bible/chapters?BookId=${id}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_CHAPTER_LIST,
	};
	return fetchHandler(fetchOptions);
};

export const editDisplaySettings = (payload) => {
	const fetchOptions = {
		url: "flashcarddecksettings/change",
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.CHANGE_SETTINGS,
	};
	return fetchHandler(fetchOptions);
};

export const moveDeckToNewGroup = (payload) => {
	const fetchOptions = {
		url: "flashcarddecksettings/deck/move",
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.MOVE_DECK,
	};
	return fetchHandler(fetchOptions);
};

export const deckShare = (payload) => {
	const fetchOptions = {
		url: "flashcarddecksettings/deck/share",
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.SHARE_DECK,
	};
	return fetchHandler(fetchOptions);
};

export const deleteGroupbyID = (id) => {
	const fetchOptions = {
		url: `flashcarddecksettings/group/delete/${id}`,
		method: "DELETE",
		actionType: FLASH_CARD_DECK.DELETE_GROUP,
	};
	return fetchHandler(fetchOptions);
};

export const deleteDeckbyID = (id) => {
	const fetchOptions = {
		url: `flashcarddecksettings/deck/delete/${id}`,
		method: "DELETE",
		actionType: FLASH_CARD_DECK.DELETE_DECK,
	};
	return fetchHandler(fetchOptions);
};

export const deleteWordbyID = (id) => {
	const fetchOptions = {
		url: `flashcarddecksettings/word/delete/${id}`,
		method: "DELETE",
		actionType: FLASH_CARD_DECK.DELETE_WORD,
	};
	return fetchHandler(fetchOptions);
};

export const getSearchResult = (searchTerm, language) => {
	const fetchOptions = {
		url: `bible/words?SearchTerm=${searchTerm}&Language=${language}&IsPagingEnabled=false`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_SEARCH_RESULTS,
	};
	return fetchHandler(fetchOptions);
};

export const wordsSearch = (language, term) => {
	const fetchOptions = {
		url: `flashcarddecksettings/words/search?Language=${language}&SearchTerm=${term}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.SEARCH_WORD,
	};

	return fetchHandler(fetchOptions);
};

export const updateMediaToWord = (payload) => {
	const fetchOptions = {
		url: "flashcarddecksettings/word/update",
		method: "PUT",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.UPDATE_WORD,
	};
	return fetchHandler(fetchOptions);
};

export const getClassCode = (language) => {
	const fetchOptions = {
		url: `classroom/getforstudent?Language=${language}`,
		method: "GET",
		actionType: FLASH_CARD_DECK.GET_SEARCH_RESULTS,
	};
	return fetchHandler(fetchOptions);
};

export const createCustomDecks = (payload) => {
	const fetchOptions = {
		url: `flashcarddecksettings/deck/add`,
		method: "POST",
		body: JSON.stringify(payload),
		actionType: FLASH_CARD_DECK.CREATE_CUSTOM_DECK,
	};

	return fetchHandler(fetchOptions);
};
