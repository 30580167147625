import React, { useState, useEffect, useRef, createRef } from "react";
import { connect } from "react-redux";
import { Card, Col, Row, Dropdown, Button, Media } from "react-bootstrap";
import Pagination from "../sharedComponents/Pagination";
import ReactTooltip from "react-tooltip";
import { range } from "lodash";
import { NotificationManager } from "react-notifications";
import { GrammarFields, GrammarSenseFields, senseArrayFields } from "./helper";
import Loader from "../sharedComponents/Loader";
import ImgAddVideo from "../../assets/images/lang-add-video.png";
import {
	getIntroVideo,
	getMapIntro,
	getVideoFiles,
	getEnumValues,
	getGrammarDetails,
	deleteIntroVideo,
} from "../Submenu/actions";
import SharedModal from "../sharedComponents/Modal";
import PlaceHolderImg from "../../assets/images/placeholder-image.png";
import AddVideo from "../../assets/images/add-video.png";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../utils/verifyUser";
import { useTranslation } from "react-i18next";

const LanguageLearning = ({
	menu,
	grammarDetails,
	getIntroVideo,
	getMapIntro,
	lessonNumber,
	getVideoFiles,
	getEnumValues,
	lessonCount,
	getGrammarDetails,
	deleteIntroVideo,
	language,
}) => {
	const [lessonId, setlessonId] = useState("");
	const [audio, setAudio] = useState("");
	const [show, showDropdown] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [videoList, setVideoList] = useState([]);
	const [selectedVideo, selectVideo] = useState("");
	const [loading, setLoading] = useState(false);
	const [videoModal, setVideoModal] = useState(false);
	const [introVideo, setIntroVideo] = useState({});
	const [introVideos, setIntroVideos] = useState([]);
	const [lessonEnumValue, setLessonEnumValue] = useState({});
	const [activePage, setActivePage] = useState(1);
	const [deleteVideo, setDeleteVideoModal] = useState(false);
	const [culture, setCulture] = useState(null);
	const { t } = useTranslation();

	const videoRef = useRef([]);

	const videoLessonRef = useRef(null);
	const modalRef = useRef(null);

	const userRole = getUserDetails() && getUserDetails().role && getUserDetails().role.description;
	useEffect(() => {
		setLoading(true);
		getEnumValues(`Type=Language`).then((res) => {
			if (res.response) {
				const languageVar = res.response.filter((item) => item.description === language);
				setLessonEnumValue(languageVar);
			}
		});
		getIntroVideo(`LessonNumber=${lessonNumber}&Language=${language}`).then((res) => {
			if (res.statusCode === 200) {
				setIntroVideos(res.response);
				if (videoRef.current.length !== res.response.length) {
					// add or remove refs
					videoRef.current = Array(res.response.length)
						.fill()
						.map((_, i) => videoRef.current[i] || createRef());
				}
			} else {
				setIntroVideos([]);
			}
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getIntroVideo, lessonNumber, menu, getEnumValues]);

	const handleDropItem = (lesson) => {
		setlessonId(lessonId === lesson.id ? "" : lesson.id);
	};

	const renderFieldsComponent = (word, item) => {
		let value = "--";
		switch (item.value) {
			case "pP3":
				value =
					word.pP3.length > 0
						? word.pP3[0].symbols.length > 0 && word.pP3[0].symbols.map((item, index) => item.symbol).join()
						: value;
				return value;
			case "picture2PP3":
				value =
					word.picture2PP3.length > 0 && word.picture2PP3[0].symbols.length > 0
						? word.picture2PP3[0].symbols.map((item) => item.symbol).join()
						: value;

				return value;
			default:
				value = word[item.value] ? word[item.value] : value;
				return value;
		}
	};

	const renderLessonComponent = (lesson) => {
		return (
			<React.Fragment>
				<Row className="d-flex ">
					{lesson &&
						GrammarFields.map(
							(fields, index) =>
								(!fields.language || fields.language === language) && (
									<Col md={6} lg={4} className="flex-direction-column mb-3" key={index}>
										<div className="dict-head mt-2">{fields.field}</div>
										<div
											className={`dict-detail ${
												fields.field === "Lexeme form"
													? language === "Hebrew"
														? "Hebrew-font"
														: "Greek-font"
													: ""
											}`}>
											{lesson[fields.value] ? lesson[fields.value] : "--"}
										</div>
									</Col>
								)
						)}
				</Row>
				{renderSensesComponent(lesson)}
			</React.Fragment>
		);
	};

	const getDictionaryDetails = (lesson, fields, i) => {
		if (lesson[fields.value] && lesson[fields.value][i] && lesson[fields.value][i].symbols) {
			return lesson[fields.value][i].symbols.map((item, indx) => (
				<React.Fragment key={indx}>
					<ReactTooltip />
					<span data-tip={item.fileName}>{item.symbol}</span>
					{indx < lesson[fields.value][i].symbols.length - 1 && " , "}
				</React.Fragment>
			));
		}
		return "--";
	};

	const renderSenseArrayFields = (lesson, fields) => {
		return fields.field === "Correct answer"
			? range(10).map((x, i) => (
					<Col md={4} className="flex-direction-column mb-3" key={i}>
						<div className="dict-head mt-2">
							{fields.field} {i + 1}
						</div>
						<div className={`dict-detail ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
							{(lesson[fields.value] && lesson[fields.value][i]) || "--"}
						</div>
					</Col>
			  ))
			: range(5).map((x, i) => (
					<Col md={4} className="flex-direction-column mb-3" key={i}>
						<div className="dict-head mt-2">
							{fields.field} {i + 1}
						</div>
						<div className="dict-detail">{getDictionaryDetails(lesson, fields, i)}</div>
					</Col>
			  ));
	};

	const renderSensesComponent = (lesson) => {
		return (
			<Row className="d-flex">
				<Col md={12} className="mb-1 mt-3">
					Sense 1
				</Col>
				{GrammarSenseFields.map(
					(fields, index) =>
						(!fields.language || fields.language === language) &&
						(senseArrayFields.includes(fields.value) ? (
							renderSenseArrayFields(lesson, fields)
						) : (
							<Col md={4} className="flex-direction-column mb-3" key={index}>
								<div className="dict-head mt-2">{fields.field}</div>
								<div className="dict-detail">{renderFieldsComponent(lesson, fields)}</div>
							</Col>
						))
				)}
			</Row>
		);
	};

	const handleToggle = (e, lesson) => {
		const sounds = document.getElementsByTagName("audio");
		for (let i = 0; i < sounds.length; i++) {
			sounds[i].pause();
		}
		showDropdown(e ? lesson.id : "");
		setAudio("");
	};

	const stopAllAudio = () => {
		const sounds = document.getElementsByTagName("audio");
		for (let i = 0; i < sounds.length; i++) {
			sounds[i].pause();
		}
	};

	const togglePlay = (pronunciation) => {
		const audioData = pronunciation.fileName;
		if (pronunciation.filePath) {
			const player = document.getElementById(audioData);
			if (player.paused) {
				stopAllAudio();
				setAudio(audioData);
				player.play();
			} else {
				setAudio("");
				player.pause();
				stopAllAudio();
			}
		} else {
			!(NotificationManager.listNotify && NotificationManager.listNotify.length > 0) &&
				NotificationManager.error(t("helpers.utils.notification.audionotavailablemsg"), "", 3000);
		}
	};

	const renderVoiceComponent = (lesson) => {
		return (
			lesson.pronunciations &&
			lesson.pronunciations.length > 0 && (
				<Dropdown drop="right" onToggle={(e) => handleToggle(e, lesson)} show={show === lesson.id} className="mr-2">
					<Dropdown.Toggle className="audio-dropdown position-relative lesson-audio">
						<div className="position-absolute audio-count">{lesson.pronunciations.length}</div>
						<i className="icon-audio" />
					</Dropdown.Toggle>
					<Dropdown.Menu className="list-audio">
						<ul className="pl-1">
							{lesson.pronunciations.map((pronunciation, index) => (
								<li key={index}>
									<div className="change-icon  d-flex">
										<span className="play-icon ">
											<i
												className={
													audio === pronunciation.fileName
														? "icon-pause-button pause-font"
														: "icon-Group-15"
												}
												onClick={() => togglePlay(pronunciation)}></i>
										</span>
										<span className="ml-2 audio-name">{pronunciation.fileName}</span>
										<audio
											src={pronunciation.filePath}
											id={pronunciation.fileName}
											className="d-none"
											onEnded={() => setAudio("")}
										/>
									</div>
								</li>
							))}
						</ul>
					</Dropdown.Menu>
				</Dropdown>
			)
		);
	};

	const openModal = () => {
		setShowModal(!showModal);
	};

	const handleEdit = (video) => {
		setIntroVideo(video);
		setCulture(video.cultureCode);
		openModal();
		selectVideo(video.filePath);
		setSearchValue(video.fileName);
		if (video.fileName) {
			getVideoFiles(`MediaType=Video&FileName=${video.fileName}`).then((res) => {
				if (res && res.response) {
					setVideoList(res.response.records);
				}
			});
		}
	};

	const handleDelete = (video) => {
		setIntroVideo(video);
		setCulture(video.cultureCode);
		setDeleteVideoModal(true);
	};

	const deleteLessonVideo = () => {
		setLoading(true);
		deleteIntroVideo(`Language=${language}&LessonNumber=${lessonNumber}&Culture=${culture}`).then((res) => {
			getIntroVideo(`LessonNumber=${lessonNumber}&Language=${language}`).then((res) => {
				if (res.statusCode === 200) {
					setIntroVideos(res.response);
				} else {
					setIntroVideos([]);
				}
				setDeleteVideoModal(false);
				setIntroVideo({});
				setLoading(false);
			});
		});
	};

	const handleVideoPlayer = (video) => {
		setIntroVideo(video);
		setVideoModal(true);
	};

	const confirmationModalBody = () => (
		<React.Fragment>
			{t("helpers.utils.deleteprompt")}
			<b>{` ${introVideo.fileName}`}</b>
		</React.Fragment>
	);

	const confirmationModalFooter = () => (
		<React.Fragment>
			<button className="btn-cancel" onClick={() => setDeleteVideoModal(false)}>
				{t("helpers.utils.no")}
			</button>
			<button className="btn-upload" onClick={() => deleteLessonVideo()}>
				{t("helpers.utils.yes")}
			</button>
		</React.Fragment>
	);

	const capture = (video) => {
		try {
			const w = 200;
			const h = 200;
			const canvas = document.createElement("canvas");
			canvas.width = w;
			canvas.height = h;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(video, 0, 0, w, h);
			return canvas && canvas.toDataURL();
			// eslint-disable-next-line no-empty
		} catch {}
	};

	const handleChange = (e) => {
		setSearchValue(e.target.value);
		if (e.target.value) {
			getVideoFiles(`MediaType=Video&FileName=${e.target.value}`).then((res) => {
				if (res && res.response) {
					setVideoList(res.response.records);
				}
			});
		} else {
			setVideoList([]);
		}
	};

	const handleRadioChange = (e, file) => {
		if (e.target.checked) {
			selectVideo(file.filePath);
		}
	};

	const clearData = () => {
		setSearchValue("");
		setVideoList([]);
	};

	const handleAddVideo = () => {
		setSearchValue("");
		setVideoList([]);
		setShowModal(!showModal);
		setLoading(true);

		const payload = {
			language: lessonEnumValue[0].value,
			lessonNumber: lessonNumber,
			files: [
				{
					culture: culture,
					filePath: selectedVideo,
				},
			],
		};
		getMapIntro(payload).then((response) => {
			if (response.response) {
				getIntroVideo(`LessonNumber=${lessonNumber}&Language=${language}`).then((res) => {
					if (res.statusCode === 200) {
						setIntroVideos(res.response);
						document.getElementById("myVideo").load();
					} else {
						setIntroVideos([]);
					}
					setLoading(false);
				});
			}
		});
	};

	const renderAddVideoComponent = () => {
		return (
			introVideos &&
			introVideos.length &&
			introVideos.map((video, index) => (
				<Card className="card-lesson" key={index}>
					<Card.Body>
						{video && video.filePath ? (
							<div className="d-flex align-items-center">
								<div className="intro-video-wrapper position-relative ">
									<video
										ref={videoRef.current[index]}
										width="100%"
										height="100%"
										onLoadedData={() => capture(videoRef.current[index])}
										id="myVideo">
										<source src={video.filePath ? video.filePath : PlaceHolderImg} type="video/mp4" />
									</video>
									<Button
										className="play-btn"
										onClick={() => {
											handleVideoPlayer(video);
										}}>
										<i className="icon icon-Group-15" />
									</Button>
								</div>
								<div className="d-flex justify-content-between ml-3 w-100">
									<div>
										<Card.Title className="p-0">
											{t("languagelearning.lessonsintrovideo")} {` for ${video.cultureName}`}
										</Card.Title>
										<div className="dict-head mt-2">{t("languagelearning.lessonsvideoname")}</div>
										<div className="dict-detail dict-video-name">{video.fileName}</div>
									</div>
									{userRole === "Administrator" && (
										<div className="d-flex">
											<div
												className="dropdown-icon edit-icon mr-2"
												onClick={() => {
													handleEdit(video);
												}}>
												<i className="icon icon-write1"></i>
											</div>
											<div
												className="dropdown-icon edit-icon"
												onClick={() => {
													handleDelete(video);
												}}>
												<i className="icon icon-delete"></i>
											</div>
										</div>
									)}
								</div>
							</div>
						) : (
							<div className="d-flex add-video-container">
								<div className="pl-4 add-video-img">
									<img src={ImgAddVideo} alt="add video" />
								</div>
								<div className="add-video ml-5">
									<h4>
										{t("languagelearning.lessons.details.videoplayermodal.lessonintroductionvideo")}{" "}
										{` for ${video.cultureName}`}
									</h4>
									<p className="add-video-text">
										{"Add the lesson introduction video by clicking on "}
										<b>{t("helpers.gallery.addvideo")} </b>
										{t("languagelearning.lessonsbtnblo")}
									</p>
									<Button
										variant="primary"
										className="add-video-button"
										onClick={() => {
											setIntroVideo(video);
											setCulture(video.cultureCode);
											openModal();
										}}
										disabled={userRole !== "Administrator"}>
										{t("helpers.gallery.addvideo")}
									</Button>
								</div>
							</div>
						)}
					</Card.Body>
				</Card>
			))
		);
	};

	const renderVideoModal = () => {
		return (
			<React.Fragment>
				{videoList.length > 0 && (
					<div className="video-list mt-2">
						<ul className="list-unstyled">
							{videoList.length > 0 &&
								videoList.map((file, index) => (
									<Media as="li" key={index}>
										<div className="modal-video-wrapper position-relative">
											<video
												ref={modalRef}
												width="100%"
												height="100%"
												onLoadedData={() => capture(modalRef.current)}
												id="myVideo">
												<source src={file.filePath ? file.filePath : PlaceHolderImg} type="video/mp4" />
											</video>
										</div>
										<Media.Body className="d-flex justify-content-between ">
											<span>
												<p>{file.fileName}</p>
											</span>
											<span>
												<label className="container">
													<input
														type="radio"
														onChange={(e) => handleRadioChange(e, file)}
														checked={selectedVideo === file.filePath}
														name="radio"
													/>
													<span className="checkmark"></span>
												</label>
											</span>
										</Media.Body>
									</Media>
								))}
						</ul>
					</div>
				)}
			</React.Fragment>
		);
	};

	const renderSearchComponent = () => {
		return (
			<div className="search-modal">
				<i className="icon icon-search1"></i>
				<input
					type="text"
					className="search-input"
					placeholder={t("helpers.utils.searchhere")}
					onChange={handleChange}
					value={searchValue}
				/>
				<button className="clear-btn" disabled={!searchValue} onClick={clearData}>
					{t("helpers.utils.clear")}
				</button>
			</div>
		);
	};

	const renderModalBody = () => {
		return (
			<div className="text-center">
				<img src={AddVideo} alt="add-video" />
				<p className="mt-2 addvideo-bottom">{t("languagelearning.lessonssearchvideomsg")}</p>
				<div className="modal-list w-100">
					{renderSearchComponent()}
					{renderVideoModal()}
					{videoList.length > 0 && (
						<div className="w-100 d-flex justify-content-between">
							<button className="add-video-footer" disabled={!selectedVideo} onClick={handleAddVideo}>
								{t("helpers.gallery.deletevideo")}
							</button>
							<button className="add-video-footer text-right" disabled={!selectedVideo} onClick={handleAddVideo}>
								{t("helpers.gallery.addvideo")}
							</button>
						</div>
					)}
				</div>
			</div>
		);
	};

	const renderModalHead = () => {
		return (
			<div className="video-head">
				<h4 className="text-center">{t("helpers.gallery.addvideo")}</h4>
			</div>
		);
	};

	const renderModalFooter = () => {
		return (
			<div className="text-center mb-4">
				<p>{`If you can't find a video, please upload in Media Gallery.`}</p>
				<p>
					<Link to="/Media-Gallery/Videos">{t("languagelearning.lessonsgotomedialink")}</Link>
				</p>
			</div>
		);
	};

	const videoModalBody = () => (
		<div>
			<video controls>
				<source src={introVideo.filePath} type="video/mp4" />
			</video>
		</div>
	);

	const handlePageChange = (e) => {
		setLoading(true);
		setActivePage(e);
		getGrammarDetails(`Language=${language}&Index=${e - 1}&Lesson=${lessonNumber}`).then((res) => {
			setLoading(false);
		});
	};

	return (
		<span>
			<h1 className="header-topic ml-4 font-weight-bold">
				{t("languagelearning.lessons.details.completedmodal.lessoncompleted", { lesson: `${lessonNumber}` })}
			</h1>
			{renderAddVideoComponent()}
			{grammarDetails &&
				grammarDetails.length > 0 &&
				grammarDetails.map((lesson, index) => (
					<Card className="card-lesson" key={index}>
						<Card.Body>
							<div className="d-flex grammar-details">
								<div className="lesson-img d-flex ">
									{lesson.pictureCrossRefFilePath ? (
										<div className="video-lesson position-relative">
											{lesson.pictureCrossRefMediaType === 2 ? (
												<video
													ref={videoLessonRef}
													width="100%"
													height="100%"
													onLoadedData={() => capture(videoLessonRef.current)}
													id="myVideo"
													src={lesson.pictureCrossRefFilePath}></video>
											) : (
												<img alt="" className="w-100 h-100" src={lesson.pictureCrossRefFilePath}></img>
											)}
										</div>
									) : (
										<img className="video-lesson-img" src={PlaceHolderImg} alt="No Video" />
									)}
									<p className="d-block d-sm-none ml-3">
										<b>{lesson.orderLocation}</b>
										<br />
										<span>{lesson.pictureCrossRefFileName ? lesson.pictureCrossRefFileName : "--"}</span>
									</p>
								</div>

								<div className="w-100 ml-4">
									<div className="d-flex justify-content-between mb-1">
										<div className="d-none d-sm-block">
											<Card.Title className="mb-0">{lesson.orderLocation}</Card.Title>
											<div
												className={`dict-head mb-1 ${
													language === "Hebrew" ? "Hebrew-font" : "Greek-font"
												}`}>
												{lesson.text}
											</div>
											{lesson.pictureCrossRefFileName && (
												<div className="dict-head mb-1">{lesson.pictureCrossRefFileName}</div>
											)}
										</div>
										<div className="d-flex voice-component">
											{renderVoiceComponent(lesson)}
											<span className="dropdown-icon" onClick={() => handleDropItem(lesson)}>
												<i
													className={
														lesson.id === lessonId
															? "icon-down down-font"
															: "icon icon-angle-arrow-down"
													}></i>
											</span>
										</div>
									</div>
									<div className="d-flex flex-wrap">
										{lessonId === lesson.id ? (
											<div className="w-100  card-border fadeIn">{renderLessonComponent(lesson)}</div>
										) : (
											lesson.tags &&
											lesson.tags.map((tag, i) => (
												<div key={i} className="m-1 tag-view">
													{tag}
												</div>
											))
										)}
									</div>
								</div>
							</div>
						</Card.Body>
					</Card>
				))}
			<SharedModal
				show={showModal}
				modalHead={renderModalHead()}
				modalBody={renderModalBody()}
				modalFooter={renderModalFooter()}
				handleClose={openModal}
				modalClass="video-modal"
			/>
			<SharedModal
				show={videoModal}
				modalBody={videoModalBody()}
				handleClose={() => setVideoModal(false)}
				modalClass="video-wrapper"
			/>
			{lessonCount > 20 && (
				<Pagination
					activePage={activePage}
					itemsCountPerPage={20}
					totalItemsCount={lessonCount}
					pageRangeDisplayed={7}
					onChange={handlePageChange}
				/>
			)}
			<SharedModal
				show={deleteVideo}
				handleClose={() => setDeleteVideoModal(false)}
				modalBody={confirmationModalBody()}
				modalFooter={confirmationModalFooter()}
				modalClass="confirm-modal"
			/>

			{loading && <Loader />}
		</span>
	);
};

const mapStateToProps = (state) => ({
	lessonDetails: state.subMenu.lessonDetails,
	menu: state.sideMenu.menu,
	grammarDetails: state.subMenu.grammarDetails,
	lessonNumber: state.subMenu.lessonNumber,
	lessonCount: state.subMenu.lessonCount,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	getIntroVideo,
	getMapIntro,
	getVideoFiles,
	getEnumValues,
	getGrammarDetails,
	deleteIntroVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageLearning);
