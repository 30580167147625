import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Row, Col, Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { NotificationManager } from "react-notifications";

import InfoDescription from "../../../sharedComponents/infoDescription";
import PieChart from "../../../sharedComponents/PieChart";
import LineChart from "../../../sharedComponents/LineChart";
import FilterDropDown from "../../../sharedComponents/FilterDropdown";
import DateFilter from "../../../sharedComponents/DateFilter";
import AudioLibrary from "../../../../assets/images/audio-library.png";
import ReportTableComponent from "../ReportTable";

import { getOverView, getChart, getWordsInLanguageLearning, getWordsLearned } from "../action";
import { dateValues, filterValues, getCollectivePath } from "../helper";
import Helper from "../helper";
import { overviewUrls, collectiveOverviewUrls, chartUrls, collectiveChartUrls } from "../types";
import { useTranslation } from "react-i18next";

const INDEX = 0;
const SIZE = 5;
const Vocabulary = ({
	match,
	getWordsInLanguageLearning,
	getOverView,
	getChart,
	getWordsLearned,
	dispatch,
	vocabulary,
	currentTab,
	studentId,
	collectiveFilter,
}) => {
	const { words, proficiency, proficiencyChart, wordsLearningStage, wordsLearnedChart, wordsLearned, mounted } = vocabulary;
	const { t } = useTranslation();
	const [proficiencyFilter, setProficiencyFilter] = useState(filterValues[0]);
	const [proficiencyDate, setProficiencyDate] = useState(dateValues[0]);
	const [overviewFilter, setOverviewFilter] = useState(dateValues[0]);
	const [wordsFilter, setWordFilter] = useState(filterValues[0]);
	const [wordsDate, setWordDate] = useState(dateValues[0]);

	const [overviewLoading, setOverviewLoading] = useState(true);
	const [proficiencyLoading, setProficiencyLoading] = useState(true);
	const [wordsLoading, setWordsLoading] = useState(true);
	const [reportLoading, setReportLoading] = useState(true);
	const wordSenseMapping = {
		multipleSensesLearnedWords: t(["dashboard.vocabulary.wordsineachlearningstage.wordswithmultiplesenseslearned"]),
		allSenseLearnedWords: t(["dashboard.vocabulary.wordsineachlearningstage.wordswithallsenseslearned"]),
		wordsWithAllSensesArchived: t(["dashboard.vocabulary.wordsineachlearningstage.wordswithallsensesarchived"]),
		singleSenseLearnedWords: t(["dashboard.vocabulary.wordsineachlearningstage.wordswithsinglesenselearned"]),
	};

	const { noOfWordsLearnedOverview, vocabularyProficiency } =
		studentId === "Collective" ? collectiveOverviewUrls : overviewUrls;
	const { vocabularyChartProficiency, noOfWordsLearned } = studentId === "Collective" ? collectiveChartUrls : chartUrls;

	const errorMessage = (status, message) => {
		if (!status === 200) {
			NotificationManager.error(`${message}`, "", 3000);
		}
	};

	const getData = async () => {
		const { response: words } = await getOverView(
			`${noOfWordsLearnedOverview}?Language=${match.params.language === "Hebrew" ? 0 : 1}&DateRange=${
				dateValues[0].value
			}${studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""}${
				collectiveFilter ? getCollectivePath(collectiveFilter) : ""
			}`
		);

		const { response: proficiency } = await getOverView(
			`${vocabularyProficiency}?Language=${match.params.language === "Hebrew" ? 0 : 1}&DateRange=${dateValues[0].value}${
				studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""
			}${collectiveFilter ? getCollectivePath(collectiveFilter) : ""}`
		);
		const { response: proficiencyChart } = await getChart(
			`${vocabularyChartProficiency}${
				filterValues[0].value !== null ? `?module=${filterValues[0].value}&` : "?"
			}Language=${match.params.language === "Hebrew" ? 0 : 1}&DateRange=${dateValues[0].value}${
				studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""
			}${collectiveFilter ? getCollectivePath(collectiveFilter) : ""}`
		);
		const { response: wordsLearnedChart } = await getChart(
			`${noOfWordsLearned}${filterValues[0].value !== null ? `?module=${filterValues[0].value}&` : "?"}Language=${
				match.params.language === "Hebrew" ? 0 : 1
			}&DateRange=${dateValues[0].value}${studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""}${
				collectiveFilter ? getCollectivePath(collectiveFilter) : ""
			}`
		);

		let wordsLearned = null;
		let wordsLearnedStatus = null;
		let wordsLearningStage = null;
		let statusCode = null;

		if (studentId !== "Collective") {
			const { response: responseWordsLearned, statusCode: wordsLearnedStatusWordsLearned } = await getWordsLearned(
				match.params.language,
				dateValues[0].value,
				INDEX,
				SIZE,
				studentId
			);
			const { response: responseWordsLearningStage, statusCode: responseStatusCode } = await getWordsInLanguageLearning(
				match.params.language,
				studentId
			);
			wordsLearned = responseWordsLearned;
			wordsLearnedStatus = wordsLearnedStatusWordsLearned;
			wordsLearningStage = responseWordsLearningStage;
			statusCode = responseStatusCode;
		}
		errorMessage(wordsLearnedStatus, "vocabulary");
		errorMessage(statusCode, "vocabulary");

		dispatch({
			vocabulary: {
				...vocabulary,
				words,
				proficiency,
				proficiencyChart,
				wordsLearned: wordsLearnedStatus === 200 ? wordsLearned : null,
				wordsLearningStage: statusCode === 200 ? wordsLearningStage : null,
				wordsLearnedChart,
				mounted: true,
			},
		});
		setOverviewLoading(false);
		setProficiencyLoading(false);
		setWordsLoading(false);
		setReportLoading(false);
	};

	useEffect(() => {
		if (currentTab === t("dashboard.vocabulary.name") && !mounted) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	const getFilterData = async (filterOption) => {
		switch (filterOption) {
			case "overview":
				const { response: words } = await getOverView(
					`${noOfWordsLearnedOverview}?Language=${match.params.language === "Hebrew" ? 0 : 1}&DateRange=${
						overviewFilter.value
					}${studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""}${
						collectiveFilter ? getCollectivePath(collectiveFilter) : ""
					}`
				);
				const { response: proficiency } = await getOverView(
					`${vocabularyProficiency}?Language=${match.params.language === "Hebrew" ? 0 : 1}&DateRange=${
						overviewFilter.value
					}${studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""}${
						collectiveFilter ? getCollectivePath(collectiveFilter) : ""
					}`
				);
				dispatch({
					vocabulary: { ...vocabulary, words, proficiency },
				});
				setOverviewLoading(false);
				break;
			case "proficiencyChart":
				const { response: proficiencyChart } = await getChart(
					`${vocabularyChartProficiency}${
						proficiencyFilter.value !== null ? `?module=${proficiencyFilter.value}&` : "?"
					}Language=${match.params.language === "Hebrew" ? 0 : 1}&DateRange=${proficiencyDate.value}${
						studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""
					}${collectiveFilter ? getCollectivePath(collectiveFilter) : ""}`
				);
				dispatch({
					vocabulary: { ...vocabulary, proficiencyChart },
				});
				setProficiencyLoading(false);
				break;
			case "wordsLearned":
				const { response: wordsLearnedChart } = await getChart(
					`${noOfWordsLearned}${wordsFilter.value !== null ? `?module=${wordsFilter.value}&` : "?"}Language=${
						match.params.language === "Hebrew" ? 0 : 1
					}&DateRange=${wordsDate.value}${studentId && studentId !== "Collective" ? `&Id=${[studentId]}` : ""}${
						collectiveFilter ? getCollectivePath(collectiveFilter) : ""
					}`
				);

				dispatch({
					vocabulary: { ...vocabulary, wordsLearnedChart },
				});
				setWordsLoading(false);
				break;
			default:
				break;
		}
	};

	useMemo(() => {
		if (currentTab === t("dashboard.vocabulary.name")) {
			setProficiencyLoading(true);
			getFilterData("proficiencyChart");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [proficiencyFilter, proficiencyDate]);

	useMemo(() => {
		if (currentTab === t("dashboard.vocabulary.name")) {
			setOverviewLoading(true);
			getFilterData("overview");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [overviewFilter]);

	useMemo(() => {
		if (currentTab === t("dashboard.vocabulary.name")) {
			setWordsLoading(true);
			getFilterData("wordsLearned");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wordsFilter, wordsDate]);

	return (
		<Container fluid className="vocabulary-container">
			<Row>
				<Col className="card-col mt-3" xs={12} lg={4}>
					{!overviewLoading ? (
						<Card className="overView  no-shadow" body>
							<div className="title-wrapper">
								<div className="title-heading">{t("dashboard.overview")}</div>
								<div className="filter-wrapper d-flex">
									<DateFilter
										dataset={dateValues}
										placeholder="Select Date"
										onSelectCB={(e) => {
											setOverviewFilter(dateValues[e]);
										}}
										activeFilter={overviewFilter}
									/>
								</div>
							</div>
							<div className="d-flex justify-content-around align-items-center">
								<Card body className="overview-cards p-2  equalize" xs={12} lg={5}>
									<div className="p-2 icon-box bookmark">
										<i className="icon-book-4"></i>
									</div>
									<div className="mt-3">
										<span className="number-text">{words && words.numberOfWordsLearned}</span>
									</div>
									<div className="m-2 text-center center-text text-wrap">
										{t("dashboard.vocabulary.wordslearned.name")}
									</div>
									<div className="m-2">
										{words && (
											<span className={`percentage ${words.isIncrease ? "inc" : "dec"}`}>
												{words.percentageChange !== 0
													? `${words.isIncrease ? "+" : "-"} ${Math.abs(words.percentageChange)} %`
													: ""}
											</span>
										)}
									</div>
								</Card>
								<Card body className="overview-cards p-2 equalize" xs={12} lg={5}>
									<div className="p-2 icon-box abc-block">
										<i className="icon-abc-block"></i>
									</div>
									{proficiency && (
										<>
											<div className="mt-3">
												<span className="number-text">
													{proficiency && proficiency.proficiencyPercentage}%
												</span>
											</div>
											<div className="m-2 text-center center-text">
												{t("dashboard.vocabulary.vocabularyproficiency.name")}
											</div>
											<div className="m-2">
												<span
													className={`percentage ${
														proficiency && proficiency.isIncrease ? "inc" : "dec"
													}`}>
													{proficiency.percentageChange !== 0 &&
														`${proficiency.isIncrease ? "+" : "-"}`}
													{`${Math.abs(proficiency.percentageChange)}`}%
												</span>
											</div>
										</>
									)}
								</Card>
							</div>
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={330} />
						</SkeletonTheme>
					)}
				</Col>
				<Col className="card-col mt-3" xs={12} lg={5}>
					{!proficiencyLoading ? (
						<Card body className="days-spent  no-shadow">
							<div className="proficieny-wrapper d-flex">
								<div className="title-heading">
									{t("dashboard.vocabulary.vocabularyproficiency.name")}
									<InfoDescription
										position={"right-bottom"}
										description={t("dashboard.vocabulary.vocabularyproficiency.info")}
									/>
								</div>
								<div className="filter-wrapper d-flex">
									<FilterDropDown
										dataset={{ 0: filterValues[0], 1: filterValues[1], 2: filterValues[2] }}
										onSelectCB={(e) => {
											setProficiencyFilter(filterValues[e]);
										}}
										activeFilter={proficiencyFilter}
									/>
									<DateFilter
										dataset={dateValues}
										placeholder="Select Date"
										onSelectCB={(e) => {
											setProficiencyDate(dateValues[e]);
										}}
										activeFilter={proficiencyDate}
									/>
								</div>
							</div>
							<div className="d-flex">
								<PieChart
									data={
										(proficiencyChart.length > 0 &&
											proficiencyChart.filter(
												(el) =>
													el.name === "Number Of Correct Answers" ||
													el.name === "Number Of InCorrect Answers"
											)) ||
										[]
									}
								/>
								<div className="d-flex justify-content-center align-items-center flex-column m-auto">
									{proficiencyChart && (
										<>
											<span className="days-spent-number">
												{proficiencyChart.length > 0 &&
													proficiencyChart.filter(
														(el) => el.name === "Percentage Of Correct Answers"
													)[0] &&
													proficiencyChart.filter(
														(el) => el.name === "Percentage Of Correct Answers"
													)[0].value}
												%
											</span>
											<span className="days-spent-text mt-3">{t("helpers.utils.correct")}</span>
											<span
												className={`mt-3 days-spent-percentage ${
													proficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
														.value < 0
														? "percentage  dec"
														: "percentage  inc"
												}`}>
												{proficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
													.value > 0 && "+ "}
												{proficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
													.value < 0 && "- "}
												{Math.abs(
													proficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
														.value
												)}
												%
											</span>
										</>
									)}
								</div>
							</div>
							<div className="d-flex justify-content-between">
								<div className="d-flex  align-items-center">
									<span className="blue-dot"></span>
									<span className="legend-text">
										{t("dashboard.vocabulary.vocabularyproficiency.legendcorrectanswer", {
											correctanswers:
												proficiencyChart.length > 0 &&
												proficiencyChart.filter((el) => el.name === "Number Of Correct Answers")[0] &&
												proficiencyChart.filter((el) => el.name === "Number Of Correct Answers")[0]
													.value,
											correctanswerspercentage:
												proficiencyChart.length > 0 &&
												proficiencyChart.filter(
													(el) => el.name === "Percentage Of Correct Answers"
												)[0]
													? proficiencyChart.filter(
															(el) => el.name === "Percentage Of Correct Answers"
													  )[0].value
													: 0,
										})}
									</span>
								</div>
								<div className="d-flex align-items-center">
									<span className="green-dot"></span>
									<span className="legend-text">
										{t("dashboard.vocabulary.vocabularyproficiency.legendincorrectanswer", {
											incorrectanswers:
												proficiencyChart.length > 0 &&
												proficiencyChart.filter((el) => el.name === "Number Of InCorrect Answers")[0] &&
												proficiencyChart.filter((el) => el.name === "Number Of InCorrect Answers")[0]
													.value,
											incorrectanswerspercentage:
												proficiencyChart.length > 0 &&
												proficiencyChart.filter(
													(el) => el.name === "Percentage Of InCorrect Answers"
												)[0]
													? proficiencyChart.filter(
															(el) => el.name === "Percentage Of InCorrect Answers"
													  )[0].value
													: 0,
										})}
									</span>
								</div>
							</div>
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={330} />
						</SkeletonTheme>
					)}
				</Col>
				{studentId !== "Collective" && (
					<Col className="card-col" xs={12} lg={3}>
						<Card className="audio-library mt-3 p-3 no-shadow" body>
							<img className="mt-2" src={AudioLibrary} alt="audio-library" />
							<div className="title-heading">{t("dashboard.audiolibrary.vocabularyaudiolibrary")}</div>
							<div className="m-3 text-center center-text">
								<span className="">{t("dashboard.audiolibrary.reviewaudiorecordings")}</span>
								<Link
									to={`/home/${match.params.language}/dashboard/AudioLibrary/Vocabulary${
										studentId ? `/${studentId}` : ""
									}`}
									className="view-details mt-4 d-block">
									{t("helpers.utils.viewdetails")}
								</Link>
							</div>
						</Card>
					</Col>
				)}
			</Row>
			{studentId !== "Collective" && (
				<Row className="mt-4 m-0 p-2 words-learned-wrapper">
					<Col className="card-col">
						{!reportLoading ? (
							<Card className="no-shadow card-transparent" body>
								<Row>
									<Col xs={8}>
										<>
											<div className="title-heading mb-2">
												{t("dashboard.vocabulary.wordslearned.name")}
												<InfoDescription
													position={"right-bottom"}
													description={t("dashboard.vocabulary.wordslearned.info")}
												/>
											</div>
											<ReportTableComponent
												header={[
													{
														label: t(["dashboard.reports.vocabulary.wordslearned"]),
														key: "wordLearned",
													},
													{ label: t(["helpers.utils.gloss"]), key: "gloss" },
													{
														label: t(["dashboard.reports.vocabulary.percentagecorrect"]),
														key: ["timesIncorrect", "correctPercentage"],
														textCenter: true,
														type: "nested",
													},
													{
														label: t(["dashboard.reports.vocabulary.incorrectfrequency"]),
														key: ["timesIncorrect", "incorrectAttempts"],
														textCenter: true,
														type: "nested",
													},
													{
														label: t(["helpers.utils.stage"]),
														key: ["stage", "currentStage"],
														type: "nested",
														textCenter: true,
													},
												]}
												data={wordsLearned}
											/>
											{wordsLearned && wordsLearned.count > 4 && (
												<div className="text-right mt-1">
													<Link
														to={`/home/${
															match.params.language
														}/Dashboard/Reports/Vocabulary/words-learned${
															studentId ? `/${studentId}` : ""
														}`}>
														{t("helpers.utils.viewdetails")}
													</Link>
												</div>
											)}
										</>
									</Col>
									<Col xs={4}>
										<Card className="no-shadow card-transparent" body>
											<div className="title-heading">
												{t("dashboard.vocabulary.wordsineachlearningstage.name")}
												<InfoDescription
													position={"left-bottom"}
													description={t("dashboard.vocabulary.wordsineachlearningstage.info")}
												/>
											</div>
											<div>
												{wordsLearningStage &&
													Object.keys(wordsLearningStage).map((stage, index) => {
														return (
															<Card className="overview-cards p-2 flex-row" key={index} xs={12}>
																<span className="p-2 m-2 icon-box words-box">
																	<i className="icon-book-6-word"></i>
																</span>
																<div className="mt-3 d-flex word-stage">
																	<div className="mr-4 word-stage-title">
																		{wordSenseMapping[stage]}
																	</div>
																	<div className="number-text mr-4 mt-2">
																		{wordsLearningStage[stage]}
																	</div>
																</div>
															</Card>
														);
													})}
											</div>
										</Card>
									</Col>
								</Row>
							</Card>
						) : (
							<SkeletonTheme color="#f6f8ff">
								<Skeleton height={200} />
							</SkeletonTheme>
						)}
					</Col>
				</Row>
			)}
			<Row className="pb-3 words-learned-chart">
				<Col className="card-col mt-3" xs={12}>
					{!wordsLoading ? (
						<Card className="no-shadow d-flex" body>
							<div className="mb-4 title-wrapper">
								<div className="title-heading">
									{t("dashboard.vocabulary.numberofwordslearned.name")}
									<InfoDescription
										position={"right-bottom"}
										description={t("dashboard.vocabulary.numberofwordslearned.info")}
									/>
								</div>
								<div className="filter-wrapper d-flex">
									<FilterDropDown
										dataset={{ 0: filterValues[0], 1: filterValues[1], 2: filterValues[2] }}
										onSelectCB={(e) => {
											setWordFilter(filterValues[e]);
										}}
										activeFilter={wordsFilter}
									/>
									<DateFilter
										dataset={dateValues}
										placeholder="Select Date"
										onSelectCB={(e) => {
											setWordDate(dateValues[e]);
										}}
										activeFilter={wordsDate}
									/>
								</div>
							</div>
							{wordsLearnedChart && wordsLearnedChart.length > 0 ? (
								<LineChart data={wordsLearnedChart} stroke="#f983cb" />
							) : (
								<div className="text-center" style={{ margin: "147px 0px" }}>
									{t("helpers.utils.nodata")}
								</div>
							)}{" "}
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={390} />
						</SkeletonTheme>
					)}
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	getOverView,
	getChart,
	getWordsInLanguageLearning,
	getWordsLearned,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vocabulary));
