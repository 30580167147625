import jwtDecode from "jwt-decode";

export const getAuth = () => JSON.parse(localStorage.getItem("auth"));

export const verifyUser = (auth) => {
	if (auth) {
		return auth;
	} else {
		auth = getAuth();
	}

	if (auth && auth.response && auth.response.token) {
		return auth.response.token;
	}
	return false;
};

export const getUserDetails = (auth) => {
	if (auth) {
		return auth;
	} else {
		auth = getAuth();
	}
	if (auth && auth.response) {
		return auth.response;
	}

	return false;
};

const isExpired = (token) => {
	const tokenExpiry = jwtDecode(token).exp;
	if (Date.now() / 1000 > tokenExpiry) {
		return true;
	} else {
		return false;
	}
};

export const reloadAfterTokenExpiry = () => {
	const auth = getAuth();
	if (auth && auth.response && auth.response.token) {
		const tokenExpiry = jwtDecode(auth.response.token).exp;
		setTimeout(() => {
			window.location.reload();
		}, (tokenExpiry - Date.now() / 1000) * 1000);
	}
};

export const isLoggedIn = (auth) => {
	if (auth) {
		return auth;
	} else {
		auth = getAuth();
	}

	if (auth && auth.response && auth.response.token && !isExpired(auth.response.token)) {
		return true;
	}
	return false;
};

export const logOutUser = () => {
	localStorage.removeItem("auth");
	window.location.replace("/login");
}
