import { QA } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export const fetchQuestionsList = () => {
  const fetchOptions = {
    url: `question/all?IsPagingEnabled=false`,
    method: "GET",
    actionType: QA.FETCH_QUESTIONS
  };

  return fetchHandler(fetchOptions);
};

export const fetchQuestionDetails = id => {
  const fetchOptions = {
    url: `question/get/${id}`,
    method: "GET",
    actionType: QA.FETCH_QUESTION_DETAILS
  };

  return fetchHandler(fetchOptions);
};

export const fetchAnswers = id => {
  const fetchOptions = {
    url: `answer/all?QuestionId=${id}&SortBy=IsVerified&SortDirection=Desc&IsPagingEnabled=false`,
    method: "GET",
    actionType: QA.FETCH_ANSWERS
  };

  return fetchHandler(fetchOptions);
};

export const postAnswer = (payload, url, method) => {
  const fetchOptions = {
    url: url,
    method: method,
    body: JSON.stringify(payload),
    actionType: QA.POST_ANSWER
  };

  return fetchHandler(fetchOptions);
};

export const updateAnswer = (url, method) => {
  const fetchOptions = {
    url: url,
    method: method,
    actionType: QA.UPDATE_ANSWER
  };

  return fetchHandler(fetchOptions);
};

export const fetchQuestionStatuses = () => {
  const fetchOptions = {
    url: `ENUM/all?Type=QAStatus`,
    method: "GET",
    actionType: QA.FETCH_QUESTION_STATUSES
  };

  return fetchHandler(fetchOptions);
};

export const deleteQuestion = id => {
  const fetchOptions = {
    url: `question/delete/${id}`,
    method: "DELETE",
    actionType: QA.DELETE_QUESTION
  };

  return fetchHandler(fetchOptions);
};

export function updateField(field, val) {
  return {
    type: QA.UPDATE_FIELD,
    field,
    val
  };
}
