import { ALPHABET_LESSONS } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";

export const getTutorials = (language) => {
  const fetchOptions = {
    url: `tutorial/all?Language=${language}`,
    method: "GET",
    actionType: ALPHABET_LESSONS.GET_TUTORIALS,
  };

  return fetchHandler(fetchOptions);
};
