import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Keycloak from "keycloak-js";
import { withRouter } from "react-router-dom";
import Loader from "../sharedComponents/Loader";
import { fullerAuth } from "./actions";
import { NotificationManager } from "react-notifications";
import { Button, Card } from "react-bootstrap";
import logo from "../../assets/images/logocopy.png";
import { getUserDetails } from "../../utils/verifyUser";

const FullerLogin = ({ history, fullerAuth, authObj }) => {
	const { loading, setLoading } = useState(true);
	const { user, setUser } = useState({});

	const initOptions = {
		url: "https://equip-auth.fuller.edu/auth/",
		realm: "equip",
		clientId: "biblingo-app",
		onLoad: "login-required",
	};

	const fullerLogin = async (keycloak, courseId, target) => {
		const {
			idToken,
			idTokenParsed: { email },
		} = keycloak;
		const creds = {
			token: idToken,
			courseID: courseId,
			email: email,
		};
		const { statusCode, response } = await fullerAuth(creds);
		if (statusCode === 200) {
			if (target === "desktop") {
				window.location.href = `BiblingoURL:\\\\app.biblingo.org?email=${authObj.email}&courseid=${authObj.courseID}&token=${idToken}`;
				setLoading(false);
			} else {
				history.push(`/home/${authObj.courseLanguage === 1 ? "Greek" : "Hebrew"}/Dashboard`);
			}
		} else {
			NotificationManager.error(response && response.message, "Error", 2000);
			history.push(`/login`);
		}

		return null;
	};

	useEffect(() => {
		if (authObj === undefined) {
			const keycloak = Keycloak(initOptions);
			const {
				location: { search },
			} = window;
			const [courseID, target] = search
				.substring(1)
				.split("&")
				.map((val) => (val.split("=")[0] = val.split("=")[1]));
			keycloak.init({ onLoad: initOptions.onLoad }).success((authenticated) => {
				if (keycloak) {
					if (authenticated) {
						fullerLogin(keycloak, courseID, target);
					}
				}
			});
		}
	}, []);

	return (
		<>
			<div className="d-flex w-100 login-wrapper">
				<div className="login-bg-wrapper"></div>
				{loading ? (
					<Loader />
				) : (
					<div className="campus">
						<Card className="p-md-5 login-section">
							<img src={logo} className="logo-img floater" alt="logo" />
							{window &&
								window.location &&
								window.location.search &&
								window.location.search.substring(1).split("&")[1].split("=")[1] === "desktop" &&
								authObj && (
									<>
										<p className="mb-3 login-wecome-text">{`Launch Desktop client by clicking Launch Desktop Client Button.`}</p>
										<Button
											href={`BiblingoURL:\\\\app.biblingo.org?email=${authObj?.email}&courseid=${authObj?.courseID}&token=${authObj?.token}`}
											className="mb-3 rounded-lg border-0 mt-3 login-btn">
											Launch Desktop Client
										</Button>
									</>
								)}
							{window &&
								window.location &&
								window.location.search &&
								window.location.search.substring(1).split("&")[1].split("=")[1] === "web" &&
								authObj && (
									<>
										<p className="mb-3 login-wecome-text">{`If you want to open Biblingo on browser, click on Launch Web Application.`}</p>

										<Button
											href={`/home/${authObj.courseLanguage === 1 ? "Greek" : "Hebrew"}/Dashboard`}
											className="mb-3 rounded-lg border-0 mt-3 login-btn">
											Launch Web Application
										</Button>
									</>
								)}
						</Card>
					</div>
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	auth: state.auth.auth,
	authObj: state.auth.authObj,
});

const mapDispatchToProps = { fullerAuth };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FullerLogin));
