export const QA = {
  FETCH_QUESTIONS: "FETCH_QUESTIONS",
  FETCH_QUESTION_DETAILS: "FETCH_QUESTION_DETAILS",
  FETCH_ANSWERS: "FETCH_ANSWERS",
  POST_ANSWER: "POST_ANSWER",
  DELETE_QUESTION: "DELETE_QUESTION",
  UPDATE_FIELD: "UPDATE_FIELD",
  FETCH_QUESTION_STATUSES: "FETCH_QUESTION_STATUSES",
  UPDATE_ANSWER: "UPDATE_ANSWER"
};
