import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import logo from "../../assets/images/logocopy.png";
import { required } from "../../utils/formValidators";
import { getUserDetails } from "../../utils/verifyUser";
import { FormField } from "../sharedComponents/formField";
import { updateField } from "../Students/LanguageLearning/actions";
import { sendLoginCredentials } from "./actions";
import "./auth.scss";

const Login = ({ handleSubmit, sendLoginCredentials, history, updateField }) => {
	const { i18n, t } = useTranslation();
	const [loginStatus, checkLoginStatus] = useState(false);

	const handleLogin = (values) => {
		NotificationManager.listNotify.forEach((n) => NotificationManager.remove({ id: n.id }));
		checkLoginStatus(true);
		sendLoginCredentials(values).then((res) => {

			checkLoginStatus(false);
			if (res.statusCode === 200) {
				if (res.response.role.description === "Student") {
					const i18nextLngStudent = localStorage.getItem("i18nextLng-student")
					localStorage.setItem("i18nextLng", i18nextLngStudent)
					i18n.changeLanguage(i18nextLngStudent);
				}
				else {
					localStorage.setItem("i18nextLng", "en");
					i18n.changeLanguage("en");
				}
				NotificationManager.success(t("login.notification.loginsuccess"), "", 3000);
				updateField("isStudentMode", false);
				localStorage.removeItem("showPronunciationModal");
				changeRoute();
			} else if (res.statusCode === 810 || res.statusCode === 710) {
				NotificationManager.warning(expiryNotification(), "", 6000);
			} else {
				NotificationManager.error(
					res.statusCode === 400
						? res.response.validationErrors &&
						res.response.validationErrors[0] &&
						res.response.validationErrors[0].value
						: res.response && res.response.message,
					"",
					2000
				);
			}
		});
	};

	const expiryNotification = () => (
		<p className="m-0 expiry-warning">
			{t("login.authpagesexpirenote")}{" "}
			<a href="https://biblingo.org/manage-account/" target="_blank" rel="noopener noreferrer">
				biblingo.org
			</a>
		</p>
	);

	const changeRoute = () => {
		if (getUserDetails().role.description === "Student") {
			const languages = getUserDetails().languages;
			if (
				getUserDetails().whyAreYouStudying ||
				getUserDetails().nativeLanguage ||
				getUserDetails().universityOrOrganization ||
				(languages.includes(0) && getUserDetails().yearsStudyingHebrew) ||
				(languages.includes(1) && getUserDetails().yearsStudyingGreek)
			) {
				localStorage.setItem("showPronunciationModal", true);
			}
			if (languages.length > 1) {
				history.push("/home");
			} else {
				const language = languages[0] === 0 ? "Hebrew" : "Greek";
				const url = `/Home/${language}/Dashboard`;
				history.push(url);
			}
		} else {
			history.push("/Home/Greek/Vocabulary");
		}
	};
	const loginFields = [
		{
			type: "text",
			placeholder: i18n.t("login.authpage.enteremail"),
			validate: [required],
			name: "username"
		},
		{
			type: "password",
			placeholder: i18n.t("helpers.utils.password"),
			validate: [required],
			name: "password"
		}
	];

	return (
		<div className="d-flex w-100 login-wrapper">
			<div className="login-bg-wrapper"></div>
			<form name="LoginForm" className="login-form " onSubmit={handleSubmit(handleLogin)}>
				<Card className="p-md-5 login-section ">
					<img src={logo} className="logo-img floater" alt="logo" />
					<p className="login-intro-text">{t("login.authpageslogintitle")}</p>
					<p className="mb-3 login-wecome-text">{t("login.authpagesloginsubtitle")}</p>
					{loginFields.map((field, index) => (
						<Field
							key={index}
							name={field.name}
							type={field.type}
							formGroupClass="no-label mt-3"
							placeholder={field.placeholder}
							component={FormField}
							validate={field.validate}
						/>
					))}

					<Button type="submit" className="rounded-lg border-0 mt-3 login-btn" disabled={loginStatus}>
						{t("login.authpageslogintitle")}
					</Button>
					<div className="d-flex justify-content-end my-3">
						<span>
							<Link to="/forgot-password" className="forgot-link ">
								{t("login.authpagesforgotpasslink")}
							</Link>
						</span>
					</div>
				</Card>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth.auth,
});

const mapDispatchToProps = {
	sendLoginCredentials,
	updateField,
};

export default reduxForm({
	form: "LoginForm",
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
