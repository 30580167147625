import React, { useRef, useEffect, useCallback, useReducer, useState } from "react";
import { Popover, Overlay, OverlayTrigger, Tabs, Tab, Form, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import PreviousQA from "./PreviousQA";
import { ToggleButton } from "../../../sharedComponents/toggleButton";
import Recording from "../../../sharedComponents/ActivePhase/recording";

import keyboarTextInput from "../../../../assets/files/keyboardTextInput.pdf";
import AudioSettingsList from "./audioSettings";

import { updateField, askQuestion, verifyActivity, getAllQuestions, getQuestionAnswer, setPractice } from "../actions";
import { fieldUpdateReducer } from "../../../../utils/helper";
import grammarSymbolGlossary from "../../../../assets/files/Grammar_Symbols_Glossary.pdf";
import { togglePlay } from "./helper";
import { useTranslation } from "react-i18next";

const ActivePhase = ({
	updateField,
	askQuestion,
	lessonDetails,
	verifyActivity,
	language,
	isStudentMode,
	updateAcitivity,
	deckType,
	isPlacementTest,
	getAllQuestions,
	getQuestionAnswer,
	notLanguageLearning,
	setPractice,
	audioSpeedList,
}) => {
	const initialState = {
		isAttempted: false,
		showSpellingModal: false,
		audioUrl: null,
		wordsList: [],
		word: "",
		wordToPractice: "",
		question: "",
		errorCount: 0,
		activeTab: "typing",
		wordBankList: [],
		wordBankValue: [],
		speakingOption: null,
		byteArray: "",
		showAnswer: false,
		practice: JSON.parse(localStorage.getItem("settings"))[language].practicePopup,
		spellingError: false,
		isAudioPlaying: false,
	};

	const [state, dispatch] = useReducer(fieldUpdateReducer, initialState);
	const { t } = useTranslation();
	const {
		isAttempted,
		question,
		word,
		wordToPractice,
		showSpellingModal,
		wordsList,
		audioUrl,
		errorCount,
		activeTab,
		wordBankList,
		wordBankValue,
		practice,
		speakingOption,
		byteArray,
		showAnswer,
		spellingError,
		isAudioPlaying,
	} = state;
	const popRef = useRef(null);
	const textAreaRef = useRef(null);
	const wordBankRef = useRef(null);
	const speakingRef = useRef(null);

	const [showPreviousQA, setShowPreviousQA] = useState(false);
	const [records, setRecords] = useState([]);
	const [loading, setLoading] = useState(false);
	const [focused, setFocused] = useState(false);

	const dtlsFields = [
		{
			title: t("helpers.utils.gloss"),
			icon: "icon-paragraph",
			field: "gloss",
		},
		{
			title: t("helpers.utils.definition"),
			icon: "icon-definition",
			field: "definition",
		},
		{
			title: t("languagelearning.lessons.details.examples"),
			icon: "icon-example",
			field: "examples",
		},
	];

	useEffect(() => {
		if (lessonDetails) {
			if (isStudentMode) {
				updateField("skipActivity", true);
				updateField("disableNext", false);
			} else {
				updateField("disableNext", true);
			}
			dispatch({
				isAttempted: false,
				word: "",
				wordBankValue: [],
				wordToPractice: "",
				question: "",
				errorCount: 0,
				spellingError: false,
			});
		}
		return () => {
			updateField("skipActivity", false);
		};
	}, [lessonDetails, updateField, isStudentMode]);

	useEffect(() => {
		if (lessonDetails && lessonDetails.activity && lessonDetails.activity.wordBank) {
			dispatch({
				wordBankList: lessonDetails.activity.wordBank.map((word, index) => ({
					word: word,
					id: index,
				})),
			});
		} else {
			dispatch({
				wordBankList: [],
			});
		}
		if (
			((lessonDetails && lessonDetails.activity.grammar && !lessonDetails.activity.grammar.isSpeakingEnabled) ||
				(lessonDetails && lessonDetails.activity.word && !lessonDetails.activity.word.isSpeakingEnabled)) &&
			activeTab === "speaking"
		) {
			dispatch({ activeTab: "typing" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessonDetails, updateField, isStudentMode]);

	useEffect(() => {
		if (audioUrl) {
			const player = document.getElementById("word-audio");
			const playPromise = player.play();

			if (playPromise !== undefined) {
				playPromise.catch((error) => {
					dispatch({ audioUrl: "" });
					if (!showSpellingModal) {
						fetchNextActivity(0);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audioUrl]);

	const enterFunction = useCallback(
		(event) => {
			if (event.keyCode === 13 || event.keyCode === 39) {
				if (showSpellingModal) {
					dispatch({ showSpellingModal: false, word: "", isAttempted: false });
					updateAcitivity("next");
				} else if (activeTab === "wordBank" && wordBankValue.length > 0) {
					verifyAnswer();
				} else if (activeTab === "speaking" && speakingOption && byteArray) {
					verifyAnswer();
				}
			} else if (event.keyCode === 49 && activeTab === "speaking") {
				dispatch({ speakingOption: "1" });
			} else if (event.keyCode === 50 && activeTab === "speaking") {
				dispatch({ speakingOption: "2" });
			} else if (event.keyCode === 51 && activeTab === "speaking") {
				dispatch({ speakingOption: "3" });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateAcitivity, showSpellingModal, wordBankValue, activeTab, speakingOption, byteArray, focused]
	);

	useEffect(() => {
		document.addEventListener("keydown", enterFunction, false);
		return () => {
			document.removeEventListener("keydown", enterFunction, false);
		};
	}, [enterFunction]);

	const submitQuestion = () => {
		updateField("loading", true);
		askQuestion({
			activityID: lessonDetails.activityId,
			title: question.trim(),
			description: question,
			learningMode: deckType ? 1 : 0,
		}).then((res) => {
			dispatch({ question: "" });
			updateField("loading", false);
			updateField("skipActivity", true);
			updateField("disableNext", false);
			if (res.statusCode === 200) {
				popRef && popRef.current && popRef.current.handleHide();
				NotificationManager.success(t("languagelearning.lessons.details.notification.successfullquest"), "", 3000);
			} else {
				NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	const verifyAnswer = (option) => {
		dispatch({ isAttempted: true });
		updateField("loading", true);
		verifyActivity(
			activeTab === "speaking"
				? {
						id: lessonDetails.activityId,
						answer: "",
						isWordBankUsed: false,
						isSpeakingUsed: true,
						IsPronunciationMistake: speakingOption === "3",
						isSuccess: speakingOption === "1" || speakingOption === "2",
						audioFile: {
							fileName: "speakingAudio.mp3",
							file: byteArray,
						},
				  }
				: {
						id: lessonDetails.activityId,
						answer: activeTab === "typing" ? word : wordBankValue.map((word) => word.word).join(" "),
				  },
			deckType
		).then((res) => {
			updateField("loading", false);
			if (res.statusCode === 200) {
				handleVerifyResponse(res);
			} else {
				NotificationManager.error(
					`${
						res && res.response && res.response.message
							? res.response.message
							: t("helpers.utils.notification.error")
					}`,
					"",
					3000
				);
			}
			dispatch({ speakingOption: null });
		});
	};

	const handleVerifyResponse = (res) => {
		if (res.response.isSuccess) {
			if (res.response.isSpellingMistake && res.response.result.length) {
				if (res.response.pronunciation && res.response.pronunciation.filePath) {
					dispatch({ audioUrl: res.response.pronunciation.filePath });
				}
				dispatch({
					showSpellingModal: true,
					wordsList: res.response.result,
					spellingError: true,
				});
			} else {
				if (res.response.pronunciation && res.response.pronunciation.filePath) {
					if (practice) {
						dispatch({
							showSpellingModal: true,
							wordsList: res.response.result
								? res.response.result
								: [{ order: 0, word: res.response.correctAnswers[0] }],
						});
					} else {
						dispatch({ audioUrl: res.response.pronunciation.filePath });
					}
				} else {
					if (practice) {
						dispatch({
							showSpellingModal: true,
							wordsList: res.response.result,
						});
					} else {
						fetchNextActivity(2000);
					}
				}
				NotificationManager.success(t("helpers.utils.notification.correct"), "", 3000);
			}
		} else {
			if (res.response.correctAnswers.length) {
				dispatch({
					showSpellingModal: true,
					wordsList: res.response.correctAnswers,
					errorCount: 1,
				});
			}
			NotificationManager.error(t("helpers.utils.notification.wrong"), "", 3000);
		}
	};

	const fetchNextActivity = (time) => {
		setTimeout(() => {
			updateAcitivity("next");
		}, time);
	};

	const setAnswer = (e, field) => {
		const val = e.target.value;
		let isPrefferedLanguage = val ? false : true;
		if (!(isAttempted && field === "word")) {
			if (val) {
				if (language === "Hebrew") {
					isPrefferedLanguage = /^[ \u0590-\u05FF\W|_ ]+$/.test(val);
				} else {
					isPrefferedLanguage = /^[ \u0370-\u03ff\u1f00-\u1fff\W|_ ]+$/.test(val);
				}
			}
			if (isPrefferedLanguage) {
				dispatch({ [field]: val });
			} else if (
				!(
					NotificationManager.listNotify &&
					NotificationManager.listNotify.length > 0 &&
					NotificationManager.listNotify.find(
						(msg) => msg.message === t("helpers.utils.notification.languageerr", { language: language })
					)
				)
			) {
				NotificationManager.error(t("helpers.utils.notification.languageerr", { language: language }), "");
			}
		}
	};

	const setWordBank = (word) => {
		if (!wordBankValue.includes(word) && !isAttempted) {
			const newList = wordBankValue;
			newList.push(word);
			dispatch({ wordBankValue: newList });
		}
	};

	const updateWordBank = (word) => {
		if (!isAttempted) {
			dispatch({
				wordBankValue: wordBankValue.filter((item) => JSON.stringify(item) !== JSON.stringify(word)),
			});
		}
	};

	const clearWord = () => {
		if (activeTab === "typing") {
			dispatch({ word: "" });
		} else {
			dispatch({ wordBankValue: [] });
		}
	};

	const renderQuestionModal = () => (
		<OverlayTrigger
			trigger="click"
			placement="top"
			rootClose={true}
			ref={popRef}
			overlay={
				<Popover id="popover-basic" className="ask-questions-modal">
					<Popover.Title as="h3"></Popover.Title>
					<Popover.Content>
						<div className="top-section d-flex justify-content-between qstn-popover">
							<h6>{t("helpers.utils.learning.askquestion.askquestion")}</h6>
							<span
								onClick={() => {
									document.body.click();
								}}>
								<i className="icon icon-close pointer"></i>
							</span>
						</div>
						<textarea
							rows="3"
							className="w-100"
							placeholder={t("helpers.utils.learning.askquestion.typequestion")}
							value={question}
							onChange={(e) => dispatch({ question: e.target.value })}></textarea>
						<div className="d-flex justify-content-end mt-2 align-items-center">
							<span
								className="mr-3 previous-qa"
								onClick={() => {
									setShowPreviousQA(true);
									setLoading(true);
									const {
										activity: { grammar, word },lessonNumber
									} = lessonDetails;
									let entryRefId = null;
									let orderLocation = null;
									if (grammar) {
										entryRefId = grammar.entryRefId;
										orderLocation = grammar.orderLocation;
									} else if (word) {
										entryRefId = word.entryRefId;
									}
									getAllQuestions(language, entryRefId, orderLocation,lessonNumber).then(async (res) => {
										setRecords(res.response.records);
										setLoading(false);
									});
									document.body.click();
								}}>
								{t("helpers.utils.learning.askquestion.viewpreviousq&a")}
							</span>
							<button
								disabled={!question.trim()}
								className="btn btn-primary submit-qstns"
								onClick={submitQuestion}>
								{t("helpers.utils.submit")}
							</button>
						</div>
					</Popover.Content>
				</Popover>
			}>
			<div className="more-4 pointer">
				<i className="icon-question2"></i>
			</div>
		</OverlayTrigger>
	);

	const getReverse = (wordsList) => {
		const words = [...wordsList];
		words.sort((a, b) => a.order - b.order);
		return words;
	};

	const renderModalContent = () => (
		<div className={`d-flex flex-wrap ${language === "Hebrew" ? "d-rtl" : ""}`}>
			{errorCount > 0 ? (
				<p className={`m-0 mr-2 word  correct ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} `}>
					{wordsList[0]}
				</p>
			) : (
				getReverse(wordsList).map((word) => (
					<p
						key={word.word}
						className={`m-0 mr-2 word  ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} ${
							word.isSpellingMistake ? "wrong" : "correct"
						}`}>
						{word.word}
					</p>
				))
			)}
		</div>
	);

	const renderSpellingMistakeModal = () => {
		return (
			<Overlay
				show={showSpellingModal}
				target={
					activeTab === "typing"
						? textAreaRef.current
						: activeTab === "speaking"
						? speakingRef.current
						: wordBankRef.current
				}
				placement="top">
				<Popover
					id="popover-contained"
					className={`spelling-mistake-modal ${errorCount > 0 ? "error-correction" : ""} ${
						spellingError ? "spellingMistake" : ""
					}`}>
					<Popover.Content>
						{(practice || spellingError || errorCount > 0) && (
							<>
								<div>
									<Recording
										practice
										defaultPronunciation={
											(lessonDetails.activity.word &&
												lessonDetails.activity.word.defaultPronunciation &&
												lessonDetails.activity.word.defaultPronunciation.filePath) ||
											(lessonDetails.activity.grammar &&
												lessonDetails.activity.grammar.defaultPronunciation &&
												lessonDetails.activity.grammar.defaultPronunciation.filePath)
										}
										isPractice={spellingError || errorCount > 0}
										focused={focused}
									/>
									<div className="d-flex justify-content-end align-items-center">
										<span>{t("helpers.utils.practicemodal.showanswer")}</span>
										<ToggleButton
											onToggle={(e) => {
												dispatch({ showAnswer: e.target.checked });
											}}
											selected={showAnswer}
										/>
									</div>
								</div>
								{showAnswer && <>{renderModalContent()} </>}
							</>
						)}
						<div className="d-flex justify-content-end align-items-center mt-2">
							<input
								className={`w-100 mr-2 ${language === "Hebrew" ? "Hebrew-font d-rtl" : "Greek-font"}`}
								placeholder={t("helpers.utils.practicemodal.practicetyping")}
								value={wordToPractice}
								onChange={(e) => setAnswer(e, "wordToPractice")}
								style={{ border: "1px solid rgb(118, 118, 118)" }}
								onFocus={() => setFocused(true)}
								onBlur={() => setFocused(false)}
							/>
							<i
								className="icon icon-left-arrow-1"
								onClick={() => {
									dispatch({ showSpellingModal: false, word: "", showAnswer: false });
									fetchNextActivity(0);
								}}></i>
						</div>
					</Popover.Content>
				</Popover>
			</Overlay>
		);
	};

	const renderActionButtons = () => (
		<div className="d-flex flex-column justify-content-end" style={{ height: "100%" }}>
			<div className="d-flex justify-content-end actions">
				{((activeTab === "typing" && word) || (activeTab === "wordBank" && wordBankValue.length > 0)) && !isAttempted && (
					<button className="btn btn-default cancel-text" onClick={clearWord}>
						{t("helpers.utils.clear")}
					</button>
				)}
				<button
					className="btn btn-primary submit-text mt-1"
					disabled={
						isAttempted ||
						(activeTab === "typing" && !word) ||
						(activeTab === "wordBank" && !wordBankValue.length > 0) ||
						(activeTab === "speaking" && (!speakingOption || !byteArray))
					}
					onClick={verifyAnswer}>
					{t("helpers.utils.submit")}
				</button>
			</div>
		</div>
	);

	const submitWord = (e, word) => {
		if (e.which === 13 && word && !isAttempted) {
			verifyAnswer();
		}
	};

	const renderInputBox = () => (
		<React.Fragment>
			<input
				className={`w-100 mt-1 ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} ${
					showSpellingModal ? "question-answer" : ""
				} `}
				placeholder={t("helpers.utils.learning.enterword")}
				rows="3"
				ref={textAreaRef}
				value={word}
				onKeyPress={(e) => submitWord(e, word)}
				onChange={(e) => setAnswer(e, "word")}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
		</React.Fragment>
	);

	const renderSelectedList = (fontClass) => (
		<div className={`selected-list  d-flex flex-wrap  ${showSpellingModal ? "question-answer" : ""}`} ref={wordBankRef}>
			{wordBankValue.length > 0 &&
				wordBankValue.map((word) => (
					<span key={word.id} className={`word ${fontClass}`}>
						{word.word}
						{!isAttempted && (
							<i className="icon icon-close-2 close-word pointer" onClick={() => updateWordBank(word)}></i>
						)}
					</span>
				))}
		</div>
	);

	const renderWordBank = () => {
		const fontClass = language === "Hebrew" ? "Hebrew-font" : "Greek-font";
		return (
			<div className={`word-bank ${language}`}>
				{renderSelectedList(fontClass)}
				<div className="words-list d-flex flex-wrap">
					{wordBankList.length > 0 &&
						wordBankList.map((word) => (
							<span
								key={word.id}
								className={`word ${fontClass} ${wordBankValue.includes(word) ? "disabled" : "pointer"}`}
								onClick={() => setWordBank(word)}>
								{word.word}
							</span>
						))}
				</div>
			</div>
		);
	};

	const renderSpeaking = () => (
		<div className="speaking-container mt-3 d-flex" ref={speakingRef}>
			<div className="recordings-container d-flex">
				<Recording
					dispatch={dispatch}
					defaultPronunciation={
						(lessonDetails.activity.word &&
							lessonDetails.activity.word.defaultPronunciation &&
							lessonDetails.activity.word.defaultPronunciation.filePath) ||
						(lessonDetails.activity.grammar &&
							lessonDetails.activity.grammar.defaultPronunciation &&
							lessonDetails.activity.grammar.defaultPronunciation.filePath)
					}
					lessonDetails={lessonDetails}
					isPractice={showSpellingModal || spellingError || errorCount > 0}
					activeTab={activeTab}
				/>
			</div>
			<div className="recording-options ml-1">
				<fieldset>
					<Form.Group
						className="d-flex flex-column mb-0"
						onChange={(e) => dispatch({ speakingOption: e.target.value })}>
						<Form.Check
							className="d-flex align-items-center ml-3 mb-2"
							type="radio"
							label={t("helpers.utils.learning.speaking.withgoodpronunciation")}
							name="formHorizontalRadios"
							id="formHorizontalRadios1"
							value={1}
							checked={speakingOption === "1"}
							onChange={() => {}}
						/>
						<Form.Check
							className="d-flex align-items-center ml-3 mb-2"
							type="radio"
							label={t("helpers.utils.learning.speaking.withpronunciationmistakes")}
							name="formHorizontalRadios"
							id="formHorizontalRadios2"
							value={2}
							checked={speakingOption === "2"}
							onChange={() => {}}
						/>
						<Form.Check
							className="d-flex align-items-center ml-3 mb-2"
							type="radio"
							label={t("helpers.utils.learning.speaking.incorrect")}
							name="formHorizontalRadios"
							id="formHorizontalRadios3"
							value={3}
							checked={speakingOption === "3"}
							onChange={() => {}}
						/>
					</Form.Group>
				</fieldset>
			</div>
		</div>
	);

	const renderTabContent = (tab) => (
		<React.Fragment>
			<Row style={{ padding: "0px 16px" }}>
				<Col className={`p-0 ${language === "Hebrew" && tab !== "speaking" ? "d-rtl" : ""}`} lg={9} xs={8}>
					{tab === "typing" ? renderInputBox() : tab === "wordBank" ? renderWordBank() : renderSpeaking()}
				</Col>
				<Col lg={3} xs={4} className="p-0" style={{ marginTop: 2 }}>
					{renderActionButtons()}
				</Col>
			</Row>
		</React.Fragment>
	);

	const checkSpeakingEnabled = () => {
		if (
			lessonDetails &&
			lessonDetails.activity &&
			lessonDetails.activity.word &&
			lessonDetails.activity.word.isSpeakingEnabled
		) {
			return true;
		} else if (
			lessonDetails &&
			lessonDetails.activity &&
			lessonDetails.activity.grammar &&
			lessonDetails.activity.grammar.isSpeakingEnabled
		) {
			return true;
		}
		return false;
	};

	const renderGlossaryButton = () => (
		<div className="d-flex">
			<a
				target="_blank"
				rel="noopener noreferrer"
				title="Grammar_Symbols_Glossary"
				href={grammarSymbolGlossary}
				className="btn btn-default glosary-btn p-0">
				{t("helpers.utils.learning.grammarsymbolglossary")}
			</a>
		</div>
	);

	const renderOptions = () => (
		<OverlayTrigger
			placement="bottom"
			trigger="click"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="popover-options move-popover">
					<Popover.Content>
						<div className="d-flex align-items-center">
							<span>{t("helpers.utils.learning.practicepopup")}</span>
							<ToggleButton
								onToggle={(e) => {
									dispatch({ practice: e.target.checked });
									setPractice({
										language: language === "Hebrew" ? 0 : 1,
										isPracticePopupEnabled: e.target.checked,
									}).then((res) => {
										const settings = JSON.parse(localStorage.getItem("settings"));
										settings[language].practicePopup = res.response.isPracticePopupEnabled;
										localStorage.setItem("settings", JSON.stringify(settings));
									});
								}}
								selected={practice}
							/>
						</div>
						{renderGlossaryButton()}
						<div>
							<span className="keyboard-info">
								<a
									className="pointer btn btn-default glosary-btn p-0"
									href={keyboarTextInput}
									target="_blank"
									rel="noopener noreferrer">
									{t("helpers.utils.learning.keyboardlayout")}
								</a>
							</span>
						</div>
					</Popover.Content>
				</Popover>
			}>
			<div className="more-4 pointer">
				<i className="icon-more-4"></i>
			</div>
		</OverlayTrigger>
	);

	const setSpeedHandlerFunction = (grammar) => {
		const audioSpeed = audioSpeedList.filter((val) => val.audioId === grammar.id);
		const speed = audioSpeed && audioSpeed[0] ? audioSpeed[0]["speed"] : 1;
		togglePlay(dispatch, speed);
	};

	const renderAudio = (details, alignTop) =>
		details &&
		details.defaultPronunciation &&
		details.defaultPronunciation.filePath && (
			<React.Fragment>
				<div className={`mr-2 d-flex justify-content-end h-100 "align-items-center dropright ml-2`}>
					<button
						type="button"
						onClick={() => setSpeedHandlerFunction(details)}
						className={`audio-dropdown position-relative lesson-audio dropdown-toggle btn btn-primary ${
							isAudioPlaying ? "is-playing" : ""
						}`}>
						<i className="icon-audio"></i>
					</button>
					<AudioSettingsList audioId={details.id} utilizer="wordDetails" />
				</div>
				<audio
					src={details.defaultPronunciation.filePath}
					id="pro-audio"
					className="d-none"
					onEnded={() => dispatch({ isAudioPlaying: false })}
				/>
			</React.Fragment>
		);

	const renderGloss = (dtl, wordDetails, language) => {
		return (
			<OverlayTrigger
				trigger="click"
				placement="top"
				rootClose={true}
				key={dtl.title}
				overlay={
					<Popover id="popover-basic" className="lang-dtls-pop">
						<Popover.Content>
							<span
								dangerouslySetInnerHTML={{
									__html: wordDetails[dtl.field],
								}}></span>
						</Popover.Content>
					</Popover>
				}>
				<div className="d-flex align-items-center pointer">
					<span className="round-background-overlay">
						<i className={`icon-Group`}></i>
					</span>
				</div>
			</OverlayTrigger>
		);
	};

	return (
		<div className={`active-phase ${language} position-relative`}>
			{audioUrl && (
				<audio
					src={audioUrl}
					id="word-audio"
					className="d-none"
					onEnded={() => {
						dispatch({ audioUrl: "" });
						if (!showSpellingModal && isAttempted) {
							fetchNextActivity(0);
						}
					}}></audio>
			)}
			{renderSpellingMistakeModal()}
			{showPreviousQA ? (
				<PreviousQA
					records={records}
					getQuestionAnswer={getQuestionAnswer}
					loading={loading}
					close={() => setShowPreviousQA(false)}
				/>
			) : (
				<>
					<Tabs
						className="word-bank-tab"
						activeKey={activeTab}
						{...(!isAttempted && {
							onSelect: (tab) => dispatch({ activeTab: tab }),
						})}>
						<Tab eventKey="typing" title={t("helpers.utils.learning.typing")} as="div">
							{renderTabContent("typing")}
						</Tab>
						<Tab eventKey="wordBank" title={t("helpers.utils.learning.wordbank")} as="div">
							{renderTabContent("wordBank")}
						</Tab>
						{checkSpeakingEnabled() && (
							<Tab eventKey="speaking" title={t("helpers.utils.learning.speaking.name")} as="div">
								{renderTabContent("speaking")}
							</Tab>
						)}
					</Tabs>
					<div className="practice-pop position-absolute d-flex align-items-center">
						<label className="progress-label mr-2" style={{ margin: "auto 0" }}>
							{t("helpers.utils.learning.progress", {
								currentlesson: lessonDetails.question,
								totallessons: lessonDetails.total,
							})}
						</label>
						{lessonDetails.activity &&
							renderGloss(dtlsFields[0], lessonDetails.activity.grammar || lessonDetails.activity.word, language)}
						{renderQuestionModal()}
						{renderOptions()}
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	language: state.sideMenu.language,
	deckType: state.studentLanguageLearning.deckType,
	audioSpeedList: state.studentLanguageLearning.audioSpeedList,
});

const mapDispatchToProps = {
	updateField,
	askQuestion,
	verifyActivity,
	getAllQuestions,
	getQuestionAnswer,
	setPractice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivePhase);
