import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { NotificationManager } from "react-notifications";
import { Translation } from "react-i18next";
import ThumbnailList from "./thumbnailList";
import ExampleList from "./exampleList";
import ActivePhase from "./activePhase";
import WordDetails from "./wordDetails";
import Grammar from "./grammar";

export const dtlsFields = [
	{
		title: "Gloss",
		icon: "icon-paragraph",
		field: "gloss",
	},
	{
		title: "Definition",
		icon: "icon-definition",
		field: "definition",
	},
	{
		title: "Example",
		icon: "icon-example",
		field: "examples",
	},
];

export const renderOverlay = (dtl, wordDetails, language) => {
	return (
		<OverlayTrigger
			trigger="click"
			placement="top"
			rootClose={true}
			key={dtl.title}
			overlay={
				<Popover id="popover-basic" className="lang-dtls-pop">
					<Popover.Content>
						{dtl.field === "examples" ? (
							wordDetails[dtl.field].map((word, index) => (
								<div className="d-flex flex-column mb-2" key={`${word.vers}${index}`}>
									<span
										className={language === "Hebrew" ? "Hebrew-font" : "Greek-font"}
										dangerouslySetInnerHTML={{
											__html: word.reference,
										}}></span>
									<span className="verse">({word.verse})</span>
								</div>
							))
						) : (
							<span
								dangerouslySetInnerHTML={{
									__html: wordDetails[dtl.field],
								}}></span>
						)}
					</Popover.Content>
				</Popover>
			}>
			<div className="d-flex align-items-center mr-3 pointer">
				<span className="round-background mr-2">
					<i className={`icon ${dtl.icon}`}></i>
				</span>
				<label className="field-label m-0"> {dtl.title}</label>
			</div>
		</OverlayTrigger>
	);
};

export const overlay = (body, icon, title) => {
	return (
		<OverlayTrigger
			trigger="click"
			placement="top"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="info-popup">
					<Popover.Content>{body}</Popover.Content>
				</Popover>
			}>
			<div className="d-flex  flex-column align-items-center mr-3 pointer ">
				<span className="round-background mr-2" style={{ width: 40, height: 40 }}>
					<i className={icon}></i>
				</span>
				<label className="field-label m-0"> {title}</label>
			</div>
		</OverlayTrigger>
	);
};

export const renderActionButtons = (lessonDetails, disableNext, isAssociation, skipActivity, updateAcitivity) => {
	const nextActivity = () => {
		if (isAssociation) {
			const {
				activity: { thumbnails },
			} = lessonDetails;
			const index = thumbnails.map((el) => el.isCurrent).indexOf(true);
			if (thumbnails.length - 1 === index || thumbnails[index].status === 1) {
				updateAcitivity(skipActivity ? "skip" : "next");
			} else {
				updateAcitivity("get", thumbnails[index + 1].activityId);
			}
		} else {
			updateAcitivity(skipActivity ? "skip" : "next");
		}
	};
	return (
		<Translation>
			{(t) => (
				<div
					className={`actions ${
						lessonDetails && lessonDetails.hasPrevious && lessonDetails.isBackEnabled
							? "justify-content-between"
							: "justify-content-end"
					}`}>
					{lessonDetails && lessonDetails.hasPrevious && lessonDetails.isBackEnabled && (
						<label className="action-title pointer" onClick={() => updateAcitivity("previous")}>
							<i className="icon icon-prev mr-2"></i>
							{t("helpers.utils.previous")}
						</label>
					)}
					{(!disableNext || isAssociation) && (
						<label className="action-title pointer" onClick={() => nextActivity()}>
							{t("helpers.utils.next")}
							<i className="icon icon-next ml-2"></i>
						</label>
					)}
				</div>
			)}
		</Translation>
	);
};

export const renderNavigationSection = (lessonDetails, isAssociation, updateAcitivity, deckType, dispatch) => (
	<div
		className={`d-flex navigation-section ${
			lessonDetails.isBackToAssociationEnabled ||
			lessonDetails.isBackToLearningEnabled ||
			(deckType === "wordsNeedPractise" && isAssociation)
				? "justify-content-between align-items-center"
				: "justify-content-end"
		}`}>
		<div className="d-flex justify-content-start">
			{deckType === "wordsNeedPractise" && isAssociation && (
				<label className="pointer lesson-nav-btn" onClick={() => dispatch({ showModal: true })} data-tip="Archive word">
					<i className="icon icon-archive" />
					<ReactTooltip />
				</label>
			)}
		</div>
	</div>
);

export const getSubView = (lessonDetails, isStudentMode, updateAcitivity) => {
	switch (lessonDetails.stage) {
		case 0:
			return <ThumbnailList lessonDetails={lessonDetails} updateAcitivity={updateAcitivity} />;
		case 1:
		case 5:
			return (
				<ExampleList lessonDetails={lessonDetails} updateAcitivity={updateAcitivity} isStudentMode={isStudentMode} />
			);
		case 2:
		case 6:
		case 7:
			return (
				<ActivePhase lessonDetails={lessonDetails} updateAcitivity={updateAcitivity} isStudentMode={isStudentMode} />
			);
		default:
			return null;
	}
};

export const getMainView = (lessonDetails, updateAcitivity, isIntroPlayng, loading) => {
	switch (lessonDetails.stage) {
		case 0:
		case 1:
		case 2:
			return (
				<WordDetails
					isIntroPlayng={isIntroPlayng}
					updateAcitivity={updateAcitivity}
					lessonDetails={lessonDetails}
					loading={loading}
				/>
			);
		default:
			return <Grammar updateAcitivity={updateAcitivity} lessonDetails={lessonDetails} />;
	}
};

export const showBadgeNotifications = (lessonDetails, t) => {
	if (lessonDetails.badge.badge) {
		NotificationManager.success(
			t("languagelearning.lessons.details.notification.badgechangedmsg", { badge: `${lessonDetails.badge.badge}` }),
			"",
			3000
		);
	}
	if (
		lessonDetails.badge.points &&
		lessonDetails.badge.points.length > 0 &&
		!lessonDetails.isLessonCompleted &&
		!lessonDetails.isLevelCompleted
	) {
		NotificationManager.success(
			t("languagelearning.lessons.details.notification.badgegainedmsg", {
				point: `${lessonDetails.badge.points[0].point}`,
				description: `${lessonDetails.badge.points[0].description}`,
			}),
			"",
			3000
		);
	}
};

export const togglePlay = (dispatch, speed = 1) => {
	const player = document.getElementById("pro-audio");
	if (player.duration) {
		if (player.paused) {
			dispatch({ isAudioPlaying: true });
			player.playbackRate = speed;
			const playPromise = player.play();
			if (playPromise !== undefined) {
				playPromise.then().catch(() => {
					dispatch({ isAudioPlaying: false });
				});
			}
		} else {
			dispatch({ isAudioPlaying: false });
			player.pause();
		}
	}
};

export const playAudioHelper = (url, speed = null, dispatch) => {
	dispatch({ audioUrl: url });
	dispatch({ audioSpeed: speed });
};

export const grammaticalInfo = [
	{
		label: "Category",
		field: "category",
	},
	{
		label: "Grammatical Forms",
		field: "grammaticalForms",
	},
	{
		label: "Root",
		field: "greekRoot",
	},
	{
		label: "Root",
		field: "hebrewRoot",
	},
	{
		label: "Argument Realizations",
		field: "argumentRealizations",
	},
	{
		label: "Binyan",
		field: "binyan",
	},
	{
		label: "Gender",
		field: "gender",
	},
];

export const lexicalFields = [
	{
		label: "Gloss",
		field: "gloss",
	},
	{
		label: "Definition",
		field: "definition",
	},
	{
		label: "Semantic Domains",
		field: "semanticDomain",
	},
	{
		label: "Cultural Info",
		field: "culturalInfo",
	},
];

export const fields = [
	{
		label: "Con",
		field: "construct",
	},
	{
		label: "Dl",
		field: "dual",
	},
	{
		label: "Pl",
		field: "plural",
	},
	{
		label: "Dl Con",
		field: "dualConstruct",
	},
	{
		label: "Pl Con",
		field: "pluralConstruct",
	},
	{
		label: "Qtl",
		field: "qatal",
	},
	{
		label: "Wqtl",
		field: "weqatal",
	},
	{
		label: "Yqtl",
		field: "yiqtol",
	},
	{
		label: "Wyqtl",
		field: "wayyiqtol",
	},
	{
		label: "Part",
		field: "participle",
	},
	{
		label: "Inf Abs",
		field: "infinitiveAbsolute",
	},
	{
		label: "Inf Con",
		field: "infinitiveConstruct",
	},

	{
		label: "Impv",
		field: "imperative",
	},
	{
		label: "Jsv",
		field: "jussive",
	},
	{
		label: "Coh",
		field: "cohortative",
	},
	{
		label: "Adj Forms",
		field: "adjForms",
	},
	{
		label: "Gen",
		field: "genN",
	},
	{
		label: "PresMP",
		field: "presMP",
	},
	{
		label: "Impv",
		field: "imperfect",
	},
	{
		label: "ImpvMP",
		field: "imperfectMP",
	},
	{
		label: "Aor",
		field: "aorist",
	},
	{
		label: "AorMid",
		field: "aorMid",
	},
	{
		label: "AorPsv",
		field: "aorPasv",
	},
	{
		label: "Fut",
		field: "future",
	},
	{
		label: "FutMid",
		field: "futureMid",
	},
	{
		label: "FutPsv",
		field: "futurePasv",
	},
	{
		label: "Perf",
		field: "perfect",
	},
	{
		label: "PerfMid",
		field: "perfMid",
	},
	{
		label: "Adj Forms",
		field: "nomFNAdj",
	},
];
