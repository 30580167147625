import { MEDIA_GALLERY } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export const getMediaGallery = value => {
  const fetchOptions = {
    url: `media/all?MediaType=${value}&IgnoreCache=true`,
    method: "GET",
    actionType: MEDIA_GALLERY.GET_MEDIA_GALLERY
  };

  return fetchHandler(fetchOptions);
};

export const deleteMedia = value => {
  const fetchOptions = {
    url: `media/delete`,
    method: "DELETE",
    body: JSON.stringify(value),
    actionType: MEDIA_GALLERY.DELETE_MEDIA
  };
  return fetchHandler(fetchOptions);
};

export const mediaRename = value => {
  const fetchOptions = {
    url: `media/rename`,
    method: "PUT",
    body: JSON.stringify(value),
    actionType: MEDIA_GALLERY.RENAME_MEDIA
  };
  return fetchHandler(fetchOptions);
};

export const uploadMedia = value => {
  const fetchOptions = {
    url: `media/upload`,
    method: "POST",
    body: value,
    actionType: MEDIA_GALLERY.UPLOAD_MEDIA,
    fileUpload: true
  };
  return fetchHandler(fetchOptions);
};

export const assetsMap = () => {
  const fetchOptions = {
    url: `assets/map?IsBackgroundProcess=false`,
    method: "PUT",
    actionType: MEDIA_GALLERY.ASSET_MAP
  };
  return fetchHandler(fetchOptions);
};
