import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FileBase64 = ({ uploadCallBack, acceptType, type, index }) => {
	const { t } = useTranslation();
	const inputAudioRef = useRef(null);
	const inputImageRef = useRef(null);

	const handleChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		// Convert the file to base64 text
		reader.readAsDataURL(file);

		reader.onprogress = (e) => { };
		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object
			const fileInfo = {
				name: file.name,
				type: file.type,
				size: Math.round(file.size / 1000) + " kB",
				base64: reader.result,
				file: file,
			};
			uploadCallBack(fileInfo, index, type);
		};
	};
	
	if (type === "audio") {
		return (
			
			<Button  variant="primary ml-3" style={{width: '150px',display: 'inline-block'}} onClick={() => inputAudioRef.current.click()}>
				{t("helpers.utils.uploadaudio")}
				<input
					ref={inputAudioRef}
					type="file"
					style={{ display: "none" }}
					accept={acceptType}
					onChange={(e) => {
						handleChange(e);
					}}
					multiple={false}
				/>
			</Button>
		);
	} else {
		return (
			<Button variant="primary ml-3" style={{width: '150px',display: 'inline-block'}} onClick={() => inputImageRef.current.click()}>
				{t("helpers.utils.uploadimage")}
				<input
					ref={inputImageRef}
					type="file"
					style={{ display: "none" }}
					accept={acceptType}
					onChange={(e) => {
						handleChange(e);
					}}
					multiple={false}
				/>
			</Button>
		);
	}
};

export default FileBase64;
