import React, { useEffect, useState } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { FormField } from "../../sharedComponents/formField";
import { userFormFields, hebrewPronunciationDetails, greekPronunciationDetails } from "./helper";
import { experienceList } from "../../Users/helper";
import { fetchLearningReason, fetchGreekPronouncations, fetchHebrewPronouncations } from "../../Users/actions";
import { setStudentPreference } from "./actions";
import { required } from "../../../utils/formValidators";
import { getUserDetails } from "../../../utils/verifyUser";
import topBorder from "../../../assets/images/top-border.png";
import infoIcon from "../../../assets/images/info.svg";
import userIcon from "../../../assets/images/person.svg";
import { useTranslation } from "react-i18next";

const UserDetailsForm = ({
	fetchLearningReason,
	fetchGreekPronouncations,
	fetchHebrewPronouncations,
	reasons,
	handleSubmit,
	handleClose,
	show,
	greekPronouncations,
	hebrewPronouncations,
	setStudentPreference,
}) => {
	const [reasonList, setReasonList] = useState([]);
	const [pronunciationList, setPronunciationList] = useState([]);
	const [isHebrew, setIsHebrew] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [greekPronouncationList, setGreekPronouncationList] = useState([]);
	const [hebrewPronouncationList, setHebrewPronouncationList] = useState([]);
  const {t} = useTranslation();

	useEffect(() => {
		if (show) {
			fetchLearningReason();
			fetchGreekPronouncations();
			fetchHebrewPronouncations();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	useEffect(() => {
		setOptions(hebrewPronouncations, setHebrewPronouncationList, "description", "value");
	}, [hebrewPronouncations]);

	useEffect(() => {
		setOptions(greekPronouncations, setGreekPronouncationList, "description", "value");
	}, [greekPronouncations]);

	useEffect(() => {
		setOptions(reasons, setReasonList, "reason", "id");
	}, [reasons]);

	const setOptions = (list, setValue, label, value) => {
		if (list && list.length > 0) {
			const newList = list.map((field) => ({
				label: field[label],
				value: field[value],
			}));
			setValue(newList);
		}
	};

	const getOptions = (field) => {
		switch (field) {
			case "whyAreYouStudying":
				return reasonList;
			case "greekPronouncationType":
				return greekPronouncationList;
			case "hebrewPronouncationType":
				return hebrewPronouncationList;
			case "yearsStudyingGreek":
			case "yearsStudyingHebrew":
				return experienceList;
			default:
				return [];
		}
	};

	const getValidations = (field) => {
		switch (field) {
			case "otherLanguages":
				return null;
			default:
				return required;
		}
	};

	const enableField = (field) => {
		const userDetails = getUserDetails() ? getUserDetails() : {};
		const languages = getUserDetails().languages;
		switch (field) {
			case "hebrewPronouncationType":
			case "yearsStudyingHebrew":
				return languages.includes(0) && userDetails.yearsStudyingHebrew;
			case "greekPronouncationType":
			case "yearsStudyingGreek":
				return languages.includes(1) && userDetails.yearsStudyingGreek;
			default:
				return userDetails[field];
		}
	};

	const addUserDetails = (data) => {
		setUpdating(true);
		const getValue = (field) => (data[field] ? data[field].value : null);
		let payload = {
			universityOrOrganization: data.universityOrOrganization ? data.universityOrOrganization : null,
			nativeLanguage: data.nativeLanguage ? data.nativeLanguage : null,
			otherLanguages: data.otherLanguages ? data.otherLanguages : null,
			learningReasonID: getValue("whyAreYouStudying"),
			experienceInGreek: getValue("yearsStudyingGreek"),
			experienceInHebrew: getValue("yearsStudyingHebrew"),
			hebrewPronunciation: getValue("hebrewPronouncationType"),
			greekPronunciation: getValue("greekPronouncationType"),
		};
		Object.keys(payload).forEach((key) => payload[key] === null && delete payload[key]);
		setStudentPreference(payload).then((res) => {
			setUpdating(false);
			handleClose();
			if (res.statusCode === 200) {
				NotificationManager.success(t("helpers.utils.userpreference.successupdate"), "", 3000);
			} else {
				NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	const onInfoIconClick = (field) => {
		if (field === "greekPronouncationType") {
			setPronunciationList(greekPronunciationDetails);
			setIsHebrew(false);
		} else {
			setPronunciationList(hebrewPronunciationDetails);
			setIsHebrew(true);
		}
	};

	const renderForm = () => (
		<form className="user-form">
			<div className="row">
				{userFormFields.map(
					(field, index) =>
						enableField(field.name) && (
							<Field
								key={field.label}
								name={field.name}
								type={field.type}
								formGroupClass="no-label  col-md-6 m-0"
								defaultError={true}
								label={field.label}
								component={FormField}
								labelIcon={
									field.showIcon ? (
										<img
											src={infoIcon}
											className="ml-1 pointer"
											alt=""
											onClick={() => onInfoIconClick(field.name)}
										/>
									) : null
								}
								options={field.type === "select" ? getOptions(field.name) : null}
								validate={getValidations(field.name)}
							/>
						)
				)}
			</div>
			<div className="form-footer d-flex justify-content-end mt-2">
				<button
					type="button"
					disabled={updating}
					className="btn btn-primary save-btn"
					onClick={handleSubmit((data) => addUserDetails(data))}>
					{t("flashcarddeck.flashcardsettings.display&reviewtab.save")}
				</button>
			</div>
		</form>
	);

	return (
		<Modal show={show} className="userdtls-modal" centered backdrop="static">
			<Modal.Body>
				<div className="flex-fill list-wrapper">
					<div className="user-form-container">
						<img src={topBorder} className="top-border" alt="" />
						<div className="top-icon d-flex justify-content-center mb-3">
							<label className="icon-round">
								<img src={userIcon} alt="" />
							</label>
						</div>
						<div className="text-center info-title">Additional Information</div>
						{renderForm()}
						{pronunciationList && pronunciationList.length > 0 && (
							<div className="pronunciation-list">
								<i className="icon icon-close pointer" onClick={() => setPronunciationList([])} />
								{pronunciationList.map((pronun) => (
									<div className="d-flex">
										<span className={`pro-title ${isHebrew ? "hebrew" : ""}`}>{pronun.label}</span>
										<span className="pro-description">{pronun.description}</span>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};
const selector = formValueSelector("UserDetailsForm");

const mapStateToProps = (state) => ({
	reasons: state.users.reasons,
	greekPronouncations: state.users.greekPronouncations,
	hebrewPronouncations: state.users.hebrewPronouncations,
	teachingLanguage: selector(state, "teachingLanguage"),
	subscribedLanguage: selector(state, "subscribedLanguage"),
});

const mapDispatchToProps = {
	fetchLearningReason,
	fetchGreekPronouncations,
	fetchHebrewPronouncations,
	setStudentPreference,
};

export default reduxForm({
	form: "UserDetailsForm",
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm)));
