import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactTooltip from "react-tooltip";
import { NotificationManager } from "react-notifications";

import { IsValidFileName } from "../../utils/formValidators";
import { getUserDetails } from "../../utils/verifyUser";
import { useTranslation } from "react-i18next";

const ImageCard = ({
  isEdit,
  setIsEdit,
  imageName,
  setImageName,
  setDeleteItem,
  handleImageRename,
  isEditing,
  setIsEditing,
  cardData,
}) => {
  const {t} = useTranslation();
  const filePath = cardData.filePath;
  const fileKey = cardData.fileKey;
  const fileName = cardData.fileName;
  const userRole =
    getUserDetails() &&
    getUserDetails().role &&
    getUserDetails().role.description;

  const handleSave = (fileKey, imageName) => {
    setIsEdit(false);
    handleImageRename(fileKey, imageName, filePath);
  };

  const handleChange = (e) =>
    !IsValidFileName(e.target.value)
      ? setImageName(e.target.value)
      : NotificationManager.error(t("helpers.utils.invalidfilenameformat"), "", 2000);

  const toggleIsEdit = (value) => {
    setIsEdit(value);
    setIsEditing(value);
  };

  return (
    <React.Fragment>
      {isEdit ? (
        <div className="d-flex ">
          <input
            value={imageName}
            className="flex-grow-1"
            onChange={(e) => handleChange(e)}
          />
          <span className="" onClick={() => handleSave(fileKey, imageName)}>
            <span className="icon-success">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </span>
          </span>
        </div>
      ) : (
        <React.Fragment>
          <p className="card-text" data-tip={fileName}>
            {fileName}
          </p>
          <ReactTooltip />
          {!isEditing && userRole === "Administrator" && (
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="icon icon-more" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => toggleIsEdit(true)}>
                  <span>
                    <i className="icon icon-write mr-2" />
                    {t("helpers.utils.rename")}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setDeleteItem(filePath)}>
                  <span>
                    <i className="icon icon-delete mr-2" />
                    {t("helpers.utils.delete")}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ImageCard;
