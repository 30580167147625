import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import home from "./components/home/reducer";
import auth from "./components/Auth/reducer";
import languageLearning from "./components/LanguageLearning/reducer";
import subMenu from "./components/Submenu/reducer";
import sideMenu from "./components/Sidemenu/reducer";
import mediaGallery from "./components/MediaGallery/reducer";
import users from "./components/Users/reducer";
import QA from "./components/QA/reducer";
import studentQnA from "./components/Students/QnA/reducer";
import studentLanguageLearning from "./components/Students/LanguageLearning/reducer";
import studentHome from "./components/Students/home/reducer";
import deck from "./components/Students/flashCardDeck/reducer";
import bible from "./components/Students/BibleReading/reducer";
import alphabet from "./components/Students/AlphabetLessons/reducer";
import placementTest from "./components/Students/PlacementTest/reducer";
// import language from "./components/Locale/reducer";
import Settings from './components/Settings/reducer'

const rootReducer = combineReducers({
	home,
	form: formReducer,
	auth,
	languageLearning,
	subMenu,
	sideMenu,
	users,
	QA,
	mediaGallery,
	studentLanguageLearning,
	studentQnA,
	studentHome,
	deck,
	bible,
	alphabet,
	placementTest,
	Settings
	// language
});

export default rootReducer;
