import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { withTranslation } from "react-i18next";
import { ProtectedRoute } from "./route";
import Login from "./components/Auth";
import ForgotPassword from "./components/Auth/forgotPassword";
import CampusLogin from "./components/Auth/campusLogin";
import ResetPassword from "./components/Auth/resetPassword";
import KeycloakLogin from "./components/Auth/keycloakLogin";
import DesktopLauncher from "./components/Auth/desktopLauncher";
import FullerLogin from "./components/Auth/fullerLogin";
import { isLoggedIn } from "./utils/verifyUser";
import "./App.scss";
import "./animations.scss";

function App() {
	return (
		<div className="main-wrapper d-flex">
			{isLoggedIn() && window && window.location && !["/campus", "/fuller-login"].includes(window.location.pathname) ? (
				<ProtectedRoute />
			) : (
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/campus" component={CampusLogin} />
					<Route exact path="/forgot-password" component={ForgotPassword} />
					<Route exact path="/resetpassword" component={ResetPassword} />
					<Route exact path="/keycloak-login" component={KeycloakLogin} />
					<Route exact path="/keycloak-desktoplogin" component={DesktopLauncher} />
					<Route exact path="/fuller-login" component={FullerLogin} />

					<Redirect to="/login" />
				</Switch>
			)}
			<NotificationContainer />
		</div>
	);
}

export default withTranslation()(withRouter(App));
