import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SharedModal from "../../../sharedComponents/Modal";
import Lesson from "../../../../assets/images/lesson.png";
import { useTranslation } from "react-i18next";
const CompletionModal = ({ deckDetails, history, match, updateDeckField, updateAcitivity, deck, reset }) => {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (deckDetails) {
			deckDetails.isLessonCompleted || deckDetails.isCompleted ? setShow(true) : setShow(false);
		}
	}, [deckDetails]);

	const renderModalBody = () =>
		deckDetails && (
			<div className="text-center">
				<img src={Lesson} alt=" " className="mb-4" />
				<p className="popup-head">
					{`${deck === "lesson" ? t("helpers.utils.lesson") : ""}${
						deck === "wordsNeedPractise" ? "" : deckDetails.name ? deckDetails.name : ""
					}`}{" "}
					{t("helpers.utils.lessonstatus.completed")}
				</p>
				<div className="row">
					<p
						className="col align-self-center popup-link"
						onClick={() => history.push(`/home/${match.params.language}/deck/${deck}`)}>
						{deck === "wordsNeedPractise"
							? t("flashcarddeck.wordsthatneedpractice.oncompletionmodal.backtowordsneedpractice")
							: deck === "preset"
							? t("flashcarddeck.wordsthatneedpractice.oncompletionmodal.backtopresetdeck")
							: t("flashcarddeck.wordsthatneedpractice.oncompletionmodal.backtocustomdeck")}
					</p>
				</div>
				<div className="row">
					{deckDetails.nextDeckId > 0 && (
						<p
							onClick={() =>
								history.push(`/home/${match.params.language}/deck-learning/${deck}/${deckDetails.nextDeckId}`)
							}
							className="col align-self-center popup-link">
							{t("flashcarddeck.wordsthatneedpractice.oncompletionmodal.continuetonext")}
						</p>
					)}
					<p
						className="col align-self-center popup-link"
						onClick={() => history.push(`/home/${match.params.language}/Dashboard`)}>
						{t("flashcarddeck.wordsthatneedpractice.oncompletionmodal.continuetodashboard")}
					</p>
				</div>
			</div>
		);

	return (
		<React.Fragment>
			<SharedModal
				show={show}
				handleClose={() => {
					setShow(false);
				}}
				modalBody={renderModalBody()}
				modalClass="success-popup"
			/>
		</React.Fragment>
	);
};

export default withRouter(CompletionModal);
