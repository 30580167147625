import React, { useEffect, useState, useCallback } from "react";
import useRecorder from "../../../hooks/useRecorder";
import { useTranslation } from "react-i18next";
const Recording = ({ practice, dispatch, defaultPronunciation, lessonDetails, isPractice, focused, activeTab }) => {
	const { t } = useTranslation();
	const [audioURL, isRecording, byteArray, startRecording, stopRecording, setAudioURL] = useRecorder();
	const [isRecordingPlaying, setIsRecordingPlaying] = useState(false);
	const [isAnswerPlaying, setIsAnswerPlaying] = useState(false);

	const hearAnswer = (playId, stopId) => {
		if (defaultPronunciation && !isRecording) {
			const player = document.getElementById(stopId);
			player.pause();
			player.currentTime = 0;
			setIsRecordingPlaying(false);
			togglePlay(playId);
		}
	};

	const hearAudio = (playId, stopId) => {
		if (audioURL && !isRecording) {
			const player = document.getElementById(stopId);
			player.pause();
			player.currentTime = 0;
			setIsAnswerPlaying(false);
			togglePlay(playId);
		}
	};

	useEffect(() => {
		setAudioURL("");
		stopPlaying("audio-recorder", setIsRecordingPlaying);
		stopPlaying("correct-audio", setIsAnswerPlaying);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPractice]);

	const enterFunction = useCallback(
		(event) => {
			if (!focused) {
				if (event.shiftKey && event.keyCode === 76) {
					if (practice) {
						hearAudio("practice-audio-recorder", "practice-correct-audio");
					} else {
						hearAudio("audio-recorder", "correct-audio");
					}
				} else if (event.keyCode === 76) {
					if (practice) {
						hearAnswer("practice-correct-audio", "practice-audio-recorder");
					} else {
						if (audioURL) {
							hearAnswer("correct-audio", "audio-recorder");
						}
					}
				} else if (event.keyCode === 82) {
					if (practice) {
						if (isRecording) {
							stopRecording();
						} else {
							startRecording();
							stopPlaying("practice-audio-recorder", setIsRecordingPlaying);
							stopPlaying("practice-correct-audio", setIsAnswerPlaying);
						}
					} else {
						if (isRecording) {
							stopRecording();
						} else {
							startRecording();
							stopPlaying("audio-recorder", setIsRecordingPlaying);
							stopPlaying("correct-audio", setIsAnswerPlaying);
						}
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[defaultPronunciation, audioURL, isRecording, focused]
	);

	useEffect(() => {
		const practicePopOver = document.getElementsByClassName("spelling-mistake-modal");

		if (
			practicePopOver &&
			practicePopOver[0] &&
			practicePopOver[0].classList &&
			practicePopOver[0].classList.contains("show")
		) {
			document.removeEventListener("keydown", enterFunction, false);
		} else if (activeTab && activeTab === "speaking") {
			document.addEventListener("keydown", enterFunction, false);
		}
		return () => {
			document.removeEventListener("keydown", enterFunction, false);
		};
	}, [activeTab, enterFunction]);

	const togglePlay = (id) => {
		const player = document.getElementById(id);
		if (player.paused) {
			if (id === "audio-recorder" || id === "practice-audio-recorder") {
				setIsRecordingPlaying(true);
			} else {
				setIsAnswerPlaying(true);
			}
			player.play();
		} else {
			if (id === "audio-recorder" || id === "practice-audio-recorder") {
				setIsRecordingPlaying(false);
			} else {
				setIsAnswerPlaying(false);
			}
			player.pause();
			player.currentTime = 0;
		}
	};

	useEffect(() => {
		setAudioURL("");
		if (dispatch) {
			dispatch({ byteArray: null });
		}
	}, [lessonDetails, setAudioURL, isRecording, dispatch]);

	useEffect(() => {
		if (dispatch && !practice) {
			dispatch({ byteArray });
		}
	}, [byteArray, dispatch, practice]);

	const stopPlaying = (id, setPlayingStop) => {
		const player = document.getElementById(id);
		if (player) {
			player.pause();
			player.currentTime = 0;
			setPlayingStop(false);
		}
	};

	const speakingBody = () => (
		<>
			<div className="recording-icon">
				<div
					className={`mic-icon pointer`}
					onClick={() => {
						if (isRecording) {
							stopRecording();
						} else {
							startRecording();
							stopPlaying("audio-recorder", setIsRecordingPlaying);
							stopPlaying("correct-audio", setIsAnswerPlaying);
						}
					}}>
					<span className="icon-Group-21">
						<span className={`path1 ${isRecording && !isPractice && "recording"}`}></span>
						<span className="path2"></span>
						<span className="path3"></span>
						<span className="path4"></span>
						<span className="path5"></span>
						<span className="path6"></span>
					</span>
				</div>
			</div>
			<div
				className={`hear-recording d-flex align-items-center pl-3 pr-3  ${!audioURL ? "disable" : "pointer"}`}
				onClick={() => {
					hearAudio("audio-recorder", "correct-audio");
				}}>
				<div className={`audio-icon ${isRecordingPlaying && !isPractice && "isPlaying"}`}>
					<span className="icon-audio"></span>
				</div>
				<span className="record-label">{t("helpers.utils.learning.speaking.hearrecording")}</span>
			</div>
			<span className="divider"></span>
			<div
				className={`hear-answer d-flex align-items-center pl-3 pr-3 ${!defaultPronunciation || !audioURL ? "disable" : "pointer"
					}`}
				onClick={() => {
					if (audioURL) {
						hearAnswer("correct-audio", "audio-recorder");
					}
				}}>
				<div className={`audio-icon ${isAnswerPlaying && !isPractice && "isPlaying"}`}>
					<span className="icon-audio"></span>
				</div>
				<span className="record-label">{t("helpers.utils.learning.speaking.hearanswer")}</span>
			</div>
		</>
	);

	const practiceBody = () => (
		<>
			<div className="recording-icon m-0 d-flex align-items-center">
				<div
					className={`mic-icon pointer`}
					onClick={() => {
						if (isRecording) {
							stopRecording();
						} else {
							startRecording();
							stopPlaying("practice-audio-recorder", setIsRecordingPlaying);
							stopPlaying("practice-correct-audio", setIsAnswerPlaying);
						}
					}}>
					<span className="icon-Group-21" style={{ fontSize: 45 }}>
						<span className={`path1 ${isRecording && "recording"}`}></span>
						<span className="path2"></span>
						<span className="path3"></span>
						<span className="path4"></span>
						<span className="path5"></span>
						<span className="path6"></span>
					</span>
				</div>
				<span className="ml-2">{t("helpers.utils.practicemodal.practicespeaking")}</span>
			</div>
			<div className="d-flex align-items-center justify-content-between mt-3 mb-3">
				<div
					className={`hear-recording d-flex align-items-center  ${!audioURL ? "disable" : "pointer"}`}
					onClick={() => {
						hearAudio("practice-audio-recorder", "practice-correct-audio");
					}}>
					<div className={`audio-icon ${isRecordingPlaying && "isPlaying"}`}>
						<span className="icon-audio"></span>
					</div>
					<span className="record-label">
						{t("helpers.utils.practicemodal.hearrecording")}
					</span>
				</div>
				<span className="divider"></span>
				<div
					className={`hear-answer d-flex align-items-center pointer`}
					onClick={() => {
						hearAnswer("practice-correct-audio", "practice-audio-recorder");
					}}>
					<div className={`audio-icon ${isAnswerPlaying && "isPlaying"}`}>
						<span className="icon-audio"></span>
					</div>
					<span className="record-label">{t("helpers.utils.practicemodal.hearanswer")}	</span>
				</div>
			</div>
		</>
	);

	return (
		<>
			<audio
				src={audioURL}
				id={`${practice ? "practice-" : ""}audio-recorder`}
				className="d-none"
				onEnded={() => setIsRecordingPlaying(false)}
			/>
			<audio
				src={defaultPronunciation}
				id={`${practice ? "practice-" : ""}correct-audio`}
				className="d-none"
				onEnded={() => setIsAnswerPlaying(false)}
			/>
			{practice ? practiceBody() : speakingBody()}
		</>
	);
};

export default Recording;
