import moment from "moment";
import React, { useState, useEffect } from "react";
import { Table, Popover, OverlayTrigger, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SharedModal from "../../sharedComponents/Modal";

const ReportTable = ({ header, data, sortBy, sortValue, setSort, taskDeleteAction, planDeleteAction, planType, language }) => {
	const { t } = useTranslation();
	const [showDeletePlan, setShowDeletePlan] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [deletePlanid, setDeletePlanId] = useState(false);
	const [deletePlanName, setDeletePlanName] = useState(false);

	useEffect(() => {
		if (document.getElementById("popover-delete-plan") !== null) {
			if (document.getElementById("popover-delete-plan").classList.contains("show"))
				document.getElementById("popover-delete-plan").classList.remove("show");
			else document.getElementById("popover-delete-plan").classList.add("show");
		}
	}, [showDeletePopup]);

	const getValues = (type, item, key) => {
		const value = item[key];
		switch (type) {
			case "name":
				return item.planName;
			case "date":
				return moment(value).format("DD MMM YYYY");
			case "status":
				return (
					<div style={{ textAlign: "left" }}>
						{value === 2 ? (
							<span className="icon-check-2">
								<span className="path1"></span>
								<span className="path2"></span>
							</span>
						) : value === 1 ? (
							t("helpers.utils.lessonstatus.inprogress")
						) : (
							t("helpers.utils.lessonstatus.notstarted")
						)}
					</div>
				);
			case "nested":
				return `${item[key[0]] && (item[key[0]][key[1]] || item[key[0]][key[1]] === 0) ? item[key[0]][key[1]] : ""}`;
			case "percentage":
				return `${value} %`;
			case "action":
				return (
					<OverlayTrigger
						placement="bottom"
						trigger={"click"}
						rootClose={true}
						overlay={
							<Popover id="popover-delete-plan" className="popover-options">
								<Popover.Content style={{ minHeight: "65px" }}>
									<span
										className="delete-action"
										style={{ cursor: "pointer" }}
										onClick={() => {
											taskDeleteAction(item["taskID"]);
											setShowDeletePopup(!showDeletePopup);
										}}>
										<i className="icon icon-delete"></i> {t("dashboard.planner.deletetask")}
									</span>
									<span
										className="delete-action"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setShowDeletePlan(true);
											setShowDeletePopup(!showDeletePopup);
											setDeletePlanId(item["planID"]);
											setDeletePlanName(
												planType === t("dashboard.planner.newplan.lesson")
													? item["planName"]
													: item["name"]
											);
										}}>
										<i className="icon icon-delete"></i> {t("dashboard.planner.deleteplan")}
									</span>
								</Popover.Content>
							</Popover>
						}>
						<div className="more-4" onClick={() => setShowDeletePopup(true)}>
							<i className="icon-more-4"></i>
						</div>
					</OverlayTrigger>
				);
			case "taskLink":
				if (planType === "lesson") {
					if (item.isLessonLocked) {
						return <a href={`/home/${language}/Language-Learning`}>{value}</a>;
					} else {
						return (
							<a
								href={`/home/${language}/Language-Learning/${item["lesson"]}/Vocabulary/Association?lessonId=${item["lessonID"]}`}>
								{value}
							</a>
						);
					}
				} else if (planType === "reading") {
					return (
						<a href={`/home/${language}/bible-reading?bookId=${item["bookId"]}&chapterId=${item["chapter"]}`}>
							{value}
						</a>
					);
				} else if (value) {
					if (value.includes("Verses")) {
						return <a href={`/home/${language}/bible-reading`}>{value}</a>;
					} else if (value.includes("New Words")) {
						return <a href={`/home/${language}/deck/custom`}>{value}</a>;
					} else if (value.includes("Paradigms")) {
						return <a href={`/home/${language}/Fluency-Drills`}>{value}</a>;
					} else if (value.includes("Practice Words")) {
						return <a href={`/home/${language}/deck/wordsNeedPractise`}>{value}</a>;
					}
				}
				break;
			default:
				return value;
		}
	};

	const toggleDeletePlan = () => {
		setShowDeletePlan(!showDeletePlan);
	};

	return (
		<Table className="reports-table-container mb-0" style={data && data.records.length === 0 ? { height: "100%" } : {}}>
			<thead>
				<tr className="spacer"></tr>
				<tr>
					{header.map((el, idx) => (
						<th className={` ${el.textCenter ? "" : ""}`} key={idx}>
							<div className="d-flex">
								{el.label}
								{el.sort && (
									<div
										className="d-flex flex-fill flex-column sort-icons pointer"
										onClick={() => {
											let dir = "Asc";
											if (el.sort === sortValue) {
												dir = "Dec";
												setSort(null);
											} else {
												setSort(el.sort);
											}
											sortBy(el.sort, dir);
										}}>
										<i className="icon icon-drop-down-arrow rotate-dropdown" />
										<i className="icon icon-drop-down-arrow" />
									</div>
								)}
							</div>
						</th>
					))}
				</tr>
			</thead>
			{data && data.records.length > 0 ? (
				<tbody>
					{data.records.map((el, idx) => (
						<React.Fragment key={idx}>
							<tr className="spacer"></tr>
							<tr className="column-table">
								{header.map((item, idx) => (
									<td key={idx} className={` ${item.textCenter ? " " : ""}`}>
										{getValues(item.type, el, item.key)}
									</td>
								))}
							</tr>
							<tr className="spacer"></tr>
						</React.Fragment>
					))}
				</tbody>
			) : (
				<tbody>
					<tr className="spacer"></tr>
					<tr style={{ margin: "auto 0" }}>
						<td colSpan={header.length} style={{ textAlign: "center" }}>
							{t("helpers.utils.nodata")}
						</td>
					</tr>
				</tbody>
			)}
			<SharedModal
				show={showDeletePlan}
				modalHead={
					<div className="video-head">
						<p className="text-center">{t("dashboard.planner.deleteplan")}</p>
					</div>
				}
				modalBody={
					<div className="text-center">
						<p className="mt-2 addvideo-bottom">
							{t("dashboard.planner.deleteplanconfirm", { planname: deletePlanName })}
						</p>
					</div>
				}
				modalFooter={
					<div className="text-center mb-4 d-flex">
						<Button variant="secondary mr-2" onClick={() => setShowDeletePlan(false)}>
							{t("helpers.utils.cancel")}
						</Button>
						<Button
							variant="primary"
							onClick={() => {
								planDeleteAction(deletePlanid);
								toggleDeletePlan();
							}}>
							{t("helpers.utils.delete")}
						</Button>
					</div>
				}
				handleClose={toggleDeletePlan}
				modalClass="settings-modal"
				id={1}
			/>
		</Table>
	);
};
export default ReportTable;
