import React, { useEffect, useState } from "react";
import { Card, Container, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Header from "../header";
import Loader from "../sharedComponents/Loader";
import { setSideMenu } from "../Sidemenu/actions";
import SyncMedia from "../SyncMedia";
import LanguageSetting from "./languageSetting";
import "./settings.scss";

const Settings = ({ setSideMenu, history }) => {
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState("Sync Setting");
	const tabList = ["Sync Setting", "Language setting"];

	useEffect(() => {
		const location = history.location.pathname.split("/");
		setLoading(true);
		setSideMenu({
			menu: [
				{ title: "home", path: "" },
				{ title: location[1], path: "" },
			],
		});
		setLoading(false);
	}, [setLoading, setSideMenu]);

	const changeTab = async (tab) => {
		setLoading(true);
		setActiveTab(tab);
		setLoading(false);
	};

	const getActiveSettings = () => {
		switch (activeTab) {
			case "Language setting":
				return <LanguageSetting />;
			case "Sync Setting":
				return <SyncMedia />;
			default:
				break;
		}
	};

	return (
		<div className="settings-container empty">
			<Header head="Settings" history={history} />
			<div className="admin-settings">
				{loading ? (
					<Loader />
				) : (
					<Tabs
						className="default-tabs"
						activeKey={activeTab}
						onSelect={(tab) => {
							changeTab(tab);
						}}>
						{tabList.map((tabName) => (
							<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
								<Container fluid style={{ paddingLeft: 10, paddingRight: 10, height: "100%" }}>
									<Card className="no-shadow settings-wrapper" style={{ height: "100%" }}>
										{getActiveSettings()}
									</Card>
								</Container>
							</Tab>
						))}
					</Tabs>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	setSideMenu,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
