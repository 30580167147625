import { DASHBOARD } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";
import { getCollectivePath } from "./helper";

export const getStreakData = (studentId) => {
	const fetchOptions = {
		url:
			studentId === "Collective"
				? `collectivedashboard/streak`
				: `dashboard/streak${studentId && studentId !== "Collective" ? `?Id=${studentId}` : ""}`,
		method: "GET",
		actionType: DASHBOARD.GET_STREAK_DATA,
	};

	return fetchHandler(fetchOptions);
};

export const getGoalData = (language) => {
	const fetchOptions = {
		url: `dashboard/goal?Language=${language === "Hebrew" ? 0 : 1}`,
		method: "GET",
		actionType: DASHBOARD.GET_GOAL_DATA,
	};

	return fetchHandler(fetchOptions);
};

export const getOverView = (url) => {
	const fetchOptions = {
		url,
		method: "GET",
		actionType: DASHBOARD.GET_OVERVIEW,
	};

	return fetchHandler(fetchOptions);
};

export const getChart = (url) => {
	const fetchOptions = {
		url,
		method: "GET",
		actionType: DASHBOARD.GET_CHART,
	};

	return fetchHandler(fetchOptions);
};

export const getWordsInLanguageLearning = (language, studentId) => {
	const fetchOptions = {
		url: `vocabulary/getwordsineachlearningstage?Language=${language === "Hebrew" ? 0 : 1}${
			studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""
		}`,
		method: "GET",
		actionType: DASHBOARD.GET_WORDS_IN_LANGUAGE_LEARNING,
	};

	return fetchHandler(fetchOptions);
};

export const getWordsLearned = (language, filter, index, size, studentId) => {
	const fetchOptions = {
		url: `vocabulary/reports/getwordslearned?Language=${
			language === "Hebrew" ? 0 : 1
		}&DateRange=${filter}&Index=${index}&Size=${size}${studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""}`,
		method: "GET",
		actionType: DASHBOARD.GET_WORDS_LEARNED,
	};

	return fetchHandler(fetchOptions);
};

export const getReports = (url) => {
	const fetchOptions = {
		url,
		method: "GET",
		actionType: DASHBOARD.GET_REPORTS,
	};
	return fetchHandler(fetchOptions);
};

export const getLessonData = (language, index, size, viewDetails) => {
	const fetchOptions = {
		url: `planner/lesson/all?Language=${
			language === "Hebrew" ? 0 : 1
		}&IsPagingEnabled=true&Index=${index}&Size=${size}&SortBy=DueOn&SortDirection=Asc&IsViewDetails=${viewDetails}`,
		method: "GET",
		actionType: DASHBOARD.GET_LESSON_DATA,
	};

	return fetchHandler(fetchOptions);
};

export const getReadingData = (language, index, size, viewDetails) => {
	const fetchOptions = {
		url: `planner/reading/all?Language=${
			language === "Hebrew" ? 0 : 1
		}&IsPagingEnabled=true&Index=${index}&Size=${size}&SortBy=DueOn&SortDirection=Asc&IsViewDetails=${viewDetails}`,
		method: "GET",
		actionType: DASHBOARD.GET_READING_DATA,
	};

	return fetchHandler(fetchOptions);
};

export const getPracticeData = (language, index, size, viewDetails) => {
	const fetchOptions = {
		url: `planner/practice/all?Language=${
			language === "Hebrew" ? 0 : 1
		}&IsPagingEnabled=true&Index=${index}&Size=${size}&SortBy=DueOn&SortDirection=Asc&IsViewDetails=${viewDetails}`,
		method: "GET",
		actionType: DASHBOARD.GET_PRACTICE_DATA,
	};

	return fetchHandler(fetchOptions);
};

export const getLessons = (language) => {
	const fetchOptions = {
		url: `lesson/foruser?Language=${language === "Hebrew" ? 0 : 1}`,
		method: "GET",
		actionType: DASHBOARD.GET_LESSON_LIST,
	};

	return fetchHandler(fetchOptions);
};

export const getLessonEndDate = (start, end, language, pace, days, startDate, name) => {
	const fetchOptions = {
		url: `planner/lesson/estimatedenddate?Start=${start}&End=${end}&Language=${language}&Pace=${pace}&Days=${days}&StartDate=${startDate}&Name=${name}`,
		method: "GET",
		actionType: DASHBOARD.GET_LESSON_ESTIMATED_DATE,
	};

	return fetchHandler(fetchOptions);
};

export const getReadingEndDate = (
	testament,
	fromBookId,
	fromChapter,
	toBookId,
	toChapter,
	pace,
	days,
	startDate,
	name,
	planType
) => {
	const fetchOptions = {
		url: `planner/reading/getestimateddate?Testament=${testament}&FromBookId=${fromBookId}&FromChapter=${fromChapter}&ToBookId=${toBookId}&ToChapter=${toChapter}&pace=${pace}&Days=${days}&StartDate=${startDate}&Name=${name}&ReadingPlanType=${planType}`,
		method: "GET",
		actionType: DASHBOARD.GET_READING_ESTIMATED_DATE,
	};

	return fetchHandler(fetchOptions);
};

export const createPlanAction = (url, data) => {
	const fetchOptions = {
		url,
		method: "POST",
		body: JSON.stringify(data),
		actionType: DASHBOARD.CREATE_PLAN,
	};

	return fetchHandler(fetchOptions);
};

export const getBooks = (testament) => {
	const fetchOptions = {
		url: `bible/books?Testament=${testament}`,
		method: "GET",
		actionType: DASHBOARD.GET_BOOK_LIST,
	};

	return fetchHandler(fetchOptions);
};

export const getChapters = (id) => {
	const fetchOptions = {
		url: `bible/chapters?BookId=${id}`,
		method: "GET",
		actionType: DASHBOARD.GET_CHAPTER_LIST,
	};

	return fetchHandler(fetchOptions);
};

export const getVerse = (id, chapter) => {
	const fetchOptions = {
		url: `bible/verses?BookId=${id}&Chapter=${chapter}`,
		method: "GET",
		actionType: DASHBOARD.GET_VERSE_LIST,
	};

	return fetchHandler(fetchOptions);
};

//////////////////////////READING TAB ACTIONS////////////////////////////

export const getReadingOverview = (language, filter, datefilter, studentId, collectiveFilter) => {
	const fetchOptions = {
		url: `${studentId === "Collective" ? "collectivedashboard/readingoverview" : "reading/overview"}/${filter}?Language=${
			language === "Hebrew" ? 0 : 1
		}&DateRange=${datefilter}${studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""}${
			collectiveFilter ? getCollectivePath(collectiveFilter) : ""
		}`,
		method: "GET",
		actionType: DASHBOARD.GET_READING_OVERVIEW,
	};

	return fetchHandler(fetchOptions);
};

export const getReadingLineChart = (language, filter, datefilter, studentId, collectiveFilter) => {
	const fetchOptions = {
		url: `${studentId === "Collective" ? "collectivedashboard/readinglinechart" : "reading/linechart"}/${filter}?Language=${
			language === "Hebrew" ? 0 : 1
		}&DateRange=${datefilter}${studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""}${
			collectiveFilter ? getCollectivePath(collectiveFilter) : ""
		}`,
		method: "GET",
		actionType: DASHBOARD.GET_READING_LINE_CHART,
	};

	return fetchHandler(fetchOptions);
};

export const getReadingPieChart = (language, filter, datefilter, studentId, collectiveFilter) => {
	const fetchOptions = {
		url: `${
			studentId === "Collective" ? "collectivedashboard/readingroundchart" : "reading/roundchart"
		}/${filter}?Language=${language === "Hebrew" ? 0 : 1}&DateRange=${datefilter}${
			studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""
		}${collectiveFilter ? getCollectivePath(collectiveFilter) : ""}`,
		method: "GET",
		actionType: DASHBOARD.GET_READING_PIE_CHART,
	};

	return fetchHandler(fetchOptions);
};

export const getReadingReportChapters = (language, datefilter, size, index, studentId) => {
	const fetchOptions = {
		url: `reading/report/chapterscompleted?Language=${
			language === "Hebrew" ? 0 : 1
		}&DateRange=${datefilter}&Size=${size}&Index=${index}${
			studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""
		}`,
		method: "GET",
		actionType: DASHBOARD.GET_READING_REPORT,
	};

	return fetchHandler(fetchOptions);
};

export const getReadingReportComprehension = (language, datefilter, size, index, studentId) => {
	const fetchOptions = {
		url: `reading/report/readingcomprehension?Language=${
			language === "Hebrew" ? 0 : 1
		}&DateRange=${datefilter}&Size=${size}&Index=${index}${
			studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""
		}`,
		method: "GET",
		actionType: DASHBOARD.GET_READING_REPORT,
	};

	return fetchHandler(fetchOptions);
};

export const getRecordings = (language, learningSection, size, index, studentId) => {
	const fetchOptions = {
		url: `dashboard/recordings?LearningSection=${learningSection}&Language=${language}&IsPagingEnabled=true&Size=${size}&Index=${index}${
			studentId && studentId !== "Collective" ? `&Id=${studentId}` : ""
		}`,
		method: "GET",
		actionType: DASHBOARD.GET_RECORDINGS,
	};

	return fetchHandler(fetchOptions);
};

export const deletePlannerTaskId = (type, id) => {
	const fetchOptions = {
		url: `planner/${type}/task/delete?id=${id}`,
		method: "DELETE",
		actionType: DASHBOARD.DELETE_PLANNER_TASK,
	};

	return fetchHandler(fetchOptions);
};

export const deletePlannerPlanId = (type, id) => {
	const fetchOptions = {
		url: `planner/${type}/plan/delete?id=${id}`,
		method: "DELETE",
		actionType: DASHBOARD.DELETE_PLANNER_PLAN,
	};

	return fetchHandler(fetchOptions);
};
