import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { audioSpeedsList } from "../../utils/helper";

export const AudioButton = ({
  url,
  id,
  isAudioPlaying,
  audioSpeed,
  onSpeedChange,
  togglePlay,
}) => {
  const renderAudioSpeed = () =>
    audioSpeedsList.map((speed, index) => {
      return (
        <label key={index} className="container">
          <input
            type="radio"
            name="audio_setting"
            checked={speed === audioSpeed}
            value={speed}
            onChange={() => {
              onSpeedChange(speed);
            }}
          />
          <span className="checkmark checkmark-settings pointer"></span>
          <span className="">{speed}x</span>
        </label>
      );
    });

  const renderSpeedSettings = () => (
    <OverlayTrigger
      trigger="click"
      placement="top"
      rootClose={true}
      overlay={
        <Popover id="popover-basic" className="audio-settings-pop">
          <Popover.Content>
            <div className="d-flex justify-content-end ">
              <span onClick={() => document.body.click()}>
                <i className="icon icon-close pointer"></i>
              </span>
            </div>
            {renderAudioSpeed()}
          </Popover.Content>
        </Popover>
      }
    >
      <button
        type="button"
        className="pointer audio-dropdown common-audio-btn position-relative lesson-audio dropdown-toggle btn speed-setting"
      >
        <i className="icon-settings-2"></i>
      </button>
    </OverlayTrigger>
  );

  return (
    <div className="d-flex">
      <button
        type="button"
        className={`audio-dropdown common-audio-btn  dropdown-toggle position-relative lesson-audio btn ${
          isAudioPlaying ? "is-playing" : ""
        }`}
        onClick={() => togglePlay(true)}
      >
        <i className="icon-audio"></i>
      </button>
      {renderSpeedSettings()}
      <audio
        src={url}
        id={id}
        className="d-none"
        onEnded={() => togglePlay(false)}
      />
    </div>
  );
};
