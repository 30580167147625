import React from "react";
import Intercom from "react-intercom";
import { getUserDetails } from "../../utils/verifyUser";

// const appID = process.env.REACT_APP_INTERCOM_APPID
const appID = "ac22jcja";

const Messenger = () => {
  const { firstName, lastName, email, id: user_id } = getUserDetails();

  const name = `${firstName} ${lastName}`;

  const user = {
    name,
    email,
    user_id,
  };

  return <Intercom appID={`${appID}`} {...user} />;
};

export default Messenger;
