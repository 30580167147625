import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Container, Card } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

import { getUserDetails } from "../../../utils/verifyUser";
import { updateField } from "../LanguageLearning/actions";
import Loader from "../../sharedComponents/Loader";
import { setSideMenu } from "../../Sidemenu/actions";
import Header from "../../header";
import "./deck.scss";
import { getDeckList, updateDeckField, getDeckGroups } from "./actions";
import noData from "../../../assets/images/no-data.png";
import { NoContent } from "../../sharedComponents/NoContent";
import SharedModal from "../../sharedComponents/Modal";
import { capitalize } from "../../../utils/helper";
import { useTranslation } from "react-i18next";

const FlashCardDeck = ({
	getDeckList,
	setSideMenu,
	updateDeckField,
	updateField,
	getDeckGroups,
	deckDetails,
	deckGroups,
	history,
	match,
}) => {
	const { t } = useTranslation();
	const deckList = {
		lesson: {
			menu: t("menu.lessondecks"),
			rediectionUrl: "deck/lesson",
		},
		preset: {
			menu: t("flashcarddeck.presetdeck.name"),
			rediectionUrl: "deck/preset",
		},
		wordsNeedPractise: {
			menu: t("flashcarddeck.wordsthatneedpractice.name"),
			rediectionUrl: "deck/wordsNeedPractise",
		},
		custom: {
			menu: t("biblereading.sense.customdeck"),
			rediectionUrl: "deck/custom",
		},
	};

	const [loading, setloading] = useState(false);
	const [show, showModal] = useState(false);
	const [tileView, setTileView] = useState(true);
	const [deckId, setDeckId] = useState("");
	const [selectedDeckId, setselectedDeckId] = useState("");
	const [deck, updateDeck] = useState("");
	const tabsList = [t("flashcarddeck.customdecks.userdecks"), t("flashcarddeck.customdecks.shareddeck")];
	const [activeTab, setActiveTab] = useState(t("flashcarddeck.customdecks.userdecks"));
	const [customDecks, setCustomDecks] = useState([]);
	const [sharedDecks, setSharedDecks] = useState([]);

	const getGroupIdFromUrl = (path) => {
		const dUrl = decodeURI(path)
			.slice(1)
			.split("&")
			.filter((param) => param.includes("groupId"))[0]
			.split("=")[1];
		if (dUrl && dUrl.length > 0) return dUrl;
		else return null;
	};

	useEffect(() => {
		if (deck === "custom") {
			setCustomDecks(deckGroups ? deckGroups.filter((deck) => !deck.isShared) : []);
			setSharedDecks(deckGroups ? deckGroups.filter((deck) => deck.isShared) : []);
		}
		const { search } = window && window.location;
		if (search && search.length > 1 && deckGroups && getGroupIdFromUrl(search)) {
			const group = deckGroups.filter((deck) => deck.group === getGroupIdFromUrl(search));
			group && group[0] && fetchGroupDetails(group[0]);
		}
	}, [deckGroups]);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		const { isBibleReading } = getUserDetails().modules;
		updateField("isStudentMode", isStudentMode);
		updateDeck(match.params.deck);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params.deck]);

	useEffect(() => {
		if (deck && deckList[deck]) {
			const location = history.location.pathname.split("/");
			setloading(true);
			setSideMenu({
				menu: [{ title: deckList[deck].menu, path: `/home/${location[2]}/deck/${location[4]}` }],
				language: location[2],
			});
			if (deck === "preset" || deck === "custom") {
				getDeckGroups(match.params.language, capitalize(deck)).then(() => {
					setloading(false);
				});
			} else {
				getDeckList(match.params.language, capitalize(deck)).then(() => {
					setloading(false);
				});
			}
		}
		return () => {
			updateDeckField("data", null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deck, match.params.language]);

	const redirectToLearning = (item) => {
		if (item && item.status === 1 && deck !== "wordsNeedPractise") {
			showModal(true);
			setselectedDeckId(item.id);
		} else {
			if (item.words && item.words.length)
				history.push(`/home/${match.params.language}/deck-learning/${deck}/${item.id}`);
			else NotificationManager.error(t("helpers.notification.addwords.prompt"), "", 3000);
		}
	};
	const expandDeck = (e, item) => {
		e.stopPropagation();
		setDeckId(deckId === item.id ? "" : item.id);
	};

	const resumeLearning = () => {
		showModal(false);
		history.push(`/home/${match.params.language}/deck-learning/${deck}/${selectedDeckId}`);
	};

	const handleClose = () => {
		showModal(false);
		updateDeckField("isRestart", true);
		history.push(`/home/${match.params.language}/deck-learning/${deck}/${selectedDeckId}`);
	};

	const renderModalBody = () => {
		return (
			<div className="text-center">
				<i className="icon icon-close-2 close-icon" onClick={() => showModal(false)}></i>
				<span>{t("flashcarddeck.modalvalues.resume")}</span>
			</div>
		);
	};

	const renderModalFooter = () => {
		return (
			<div className="mb-2 change-modal-footer">
				<button className="btn btn-close" onClick={() => handleClose()}>
					{t("flashcarddeck.modalvalues.startover-btn")}
				</button>
				<button className="btn btn-success mr-3" onClick={() => resumeLearning()}>
					{t("flashcarddeck.modalvalues.resume-btn")}
				</button>
			</div>
		);
	};

	const renderPrimarySection = (item, disabeIcon) => (
		<div className="d-flex justify-content-between align-items-center">
			<div className="d-flex">
				<div className="icon-round">
					<i className="icon icon-flashcard-deck" />
				</div>
				<p className="lesson-number m-0">
					{deck === "lesson" ? t("helpers.utils.lesson") : ""} {item.name}
				</p>
			</div>
			<div className="d-flex">
				{item.isSharedByUser && item.sharedByUser.trim().length > 0 && (
					<div className="sharedBy-wrapper">
						{t("flashcarddeck.sharedby", { sharedby: item.sharedByUser.trim() })}
					</div>
				)}
				{typeof item.status !== "undefined" && (
					<div style={{ textAlign: "left" }} className="p-2">
						{item.status === 2 ? (
							<span className="icon-check-2" title={t("helpers.utils.lessonstatus.completed")}>
								<span className="path1"></span>
								<span className="path2"></span>
							</span>
						) : item.status === 1 ? (
							<span className="icon-clock-5" title={t("helpers.utils.lessonstatus.inprogress")}>
								<span className="path1"></span>
								<span className="path2"></span>
							</span>
						) : (
							<span className="icon-block" title={t("helpers.utils.lessonstatus.notstarted")}>
								<span className="path1"></span>
								<span className="path2"></span>
							</span>
						)}
					</div>
				)}
				{!disabeIcon && (
					<div
						className="dropdown-icon dictionary-dropdown"
						onClick={(e) => {
							expandDeck(e, item);
						}}>
						<i className={item.id === deckId ? "icon-down down-font" : "icon icon-angle-arrow-down"}></i>
					</div>
				)}
			</div>
		</div>
	);

	const renderWordsList = (item, removePadding) => (
		<div className={`d-flex word-list ${removePadding ? "p-0" : ""}`}>
			{item.words.map((word) => (
				<div key={word} className={`word ${match.params.language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
					{word}
				</div>
			))}
		</div>
	);

	const renderWordsNeedPractice = (data) => {
		const wordsStack = data && data.length > 0 ? data[0] : null;
		return (
			<div className="deck-list px-4">
				{wordsStack ? (
					<div className={`deck-item mb-3  expand words-np`}>
						{renderPrimarySection(wordsStack, true)}
						{wordsStack.stacks &&
							wordsStack.stacks.length > 0 &&
							wordsStack.stacks.map(
								(stack, index) =>
									stack.words &&
									stack.words.length > 0 && (
										<div
											className={`words-need-practice ${index === 0 ? "primary" : ""}`}
											{...(index === 0 && {
												onClick: () => redirectToLearning(stack),
											})}>
											{renderWordsList(stack, true)}
										</div>
									)
							)}
					</div>
				) : (
					<NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
				)}
			</div>
		);
	};

	const fetchGroupDetails = (group) => {
		setloading(true);
		setSideMenu({
			menu: [
				{ title: deckList[deck].menu, path: `/home/${match.params.language}/deck/${match.params.deck}` },
				{ title: group.group, path: "" },
			],
			language: match.params.language,
		});
		if (deck === "custom") {
			getDeckList(
				match.params.language,
				capitalize(deck),
				group.group,
				group.isCustomDeck,
				group.isGenerated,
				group.isShared
			).then(() => {
				setTileView(false);
				setloading(false);
			});
		} else {
			getDeckList(match.params.language, capitalize(deck), group).then(() => {
				setTileView(false);
				setloading(false);
			});
		}
	};

	const backToPresetDeck = () => {
		setSideMenu({
			menu: [{ title: deckList[deck].menu, path: "" }],
			language: match.params.language,
		});
		setTileView(true);
	};

	const renderPresentTileView = () => (
		<div className="tile-view">
			{deckGroups && deckGroups.length > 0 ? (
				deckGroups.map((deckGroup) => (
					<div className="deck-group" key={deckGroup.displayGroup} onClick={() => fetchGroupDetails(deckGroup.group)}>
						<span className="icon icon-folder">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
						</span>
						<span className="group-name">{deckGroup.displayGroup}</span>
					</div>
				))
			) : (
				<NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
			)}
		</div>
	);
	const getActiveGroups = (tab) => {
		if (tab === t("flashcarddeck.customdecks.userdecks")) {
			return customDecks && customDecks.length > 0 ? (
				customDecks.map((deckGroup) => (
					<div
						className="deck-group custom-wrapper"
						key={deckGroup.group}
						onClick={() => fetchGroupDetails(deckGroup)}>
						<span className="icon icon-folder">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
						</span>
						<span className="group-name">{deckGroup.group}</span>
					</div>
				))
			) : (
				<NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
			);
		} else if (tab === t("flashcarddeck.customdecks.shareddeck")) {
			return sharedDecks && sharedDecks.length > 0 ? (
				sharedDecks.map((deckGroup) => (
					<div
						className="deck-group shared-wrapper"
						key={deckGroup.group}
						onClick={() => fetchGroupDetails(deckGroup)}>
						<span className="icon icon-folder">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
						</span>
						<span className="group-name">{deckGroup.group}</span>
					</div>
				))
			) : (
				<NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
			);
		}
	};
	const renderTileView = () => {
		return (
			<Tabs
				className="default-tabs"
				activeKey={activeTab}
				onSelect={(tab) => {
					setActiveTab(tab);
				}}>
				{tabsList.map((tabName, index) => {
					return (
						<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
							<Container fluid style={{ paddingLeft: 10, paddingRight: 10, height: "100%" }}>
								<Card className="no-shadow settings-wrapper" style={{ height: "100%" }}>
									<div className="tile-view">{getActiveGroups(tabName)}</div>
								</Card>
							</Container>
						</Tab>
					);
				})}
			</Tabs>
		);
	};

	const renderSettings = () => {
		return <div className="settings-view">{t("flashcarddeck.settings")}</div>;
	};
	return (
		<div className="deck-container">
			<Header
				enableLanguageSwitch={true}
				redirectionUrl={deck && deckList[deck] ? deckList[deck].rediectionUrl : ""}
				head={deckList && deckList[deck] && deckList[deck].menu}
			/>
			{!tileView && (deck === "preset" || deck === "custom") && (
				<div className="nav-link" onClick={backToPresetDeck}>
					<i className="icon icon-left-arrow-1"></i>
					{t("flashcarddeck.backtodeck")}
				</div>
			)}
			{loading ? (
				<Loader />
			) : deck === "settings" ? (
				renderSettings()
			) : tileView && deck === "custom" ? (
				<div className="customdeck-wrapper">{renderTileView()}</div>
			) : tileView && deck === "preset" ? (
				renderPresentTileView()
			) : deck === "wordsNeedPractise" ? (
				renderWordsNeedPractice(deckDetails)
			) : (
				!tileView && (
					<div className="deck-list  px-4">
						{deckDetails && deckDetails.length > 0 ? (
							deckDetails.map((item) => (
								<div
									className={`deck-item mb-3 pointer ${deckId === item.id ? "expand" : ""}`}
									key={item.id}
									onClick={() => redirectToLearning(item)}>
									{renderPrimarySection(item, item.words.length === 0)}
									{item.words && item.words.length > 0 && renderWordsList(item)}
								</div>
							))
						) : (
							<NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
						)}
					</div>
				)
			)}
			<SharedModal
				show={show}
				modalBody={renderModalBody()}
				modalFooter={renderModalFooter()}
				handleClose={() => {}}
				modalClass="no-header header-student resume-modal"
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	deckDetails: state.deck.data,
	deckGroups: state.deck.deckGroups,
});

const mapDispatchToProps = {
	getDeckList,
	setSideMenu,
	updateDeckField,
	getDeckGroups,
	updateField,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlashCardDeck));
