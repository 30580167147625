import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../sharedComponents/Loader";
import {
	changeType,
	getAllGrammar,
	getAllLessons,
	getAllWords,
	getGrammarDetails,
	setIsSearchDisable,
	setLessonDetails,
	setLessonNumber,
	setWordDetails,
	updateField
} from "./actions";
import "./submenu.scss";

const ListDictionaryLessons = ({
	type,
	getAllLessons,
	getAllWords,
	words,
	lessons,
	language,
	setLessonDetails,
	setWordDetails,
	menu,
	changeType,
	getGrammarDetails,
	setLessonNumber,
	lessonNumber,
	wordDetails,
	handleSubMenu,
	getAllGrammar,
	updateField,
	searchText,
	history,
	setIsSearchDisable,
	isSearchDisable,
}) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setIsSearchDisable(true)
		const pathname = history.location.pathname.split("/");
		if (menu.length > 0 && menu[0] && menu[0].title === "Vocabulary" && type === "dictionary" && !localStorage.getItem("redirectToLesson")) {
			updateField("isDataFetching", true);
			getAllWords(`language=${pathname[2]}&IsPagingEnabled=false&Text=${searchText}`).then((res) => {
				setIsSearchDisable(false)
			})
			setWordDetails(`Language=${pathname[2]}`).then((res) => {
				updateField("isDataFetching", false);
			})
		}
		if (menu.length > 0 && menu[0] && menu[0].title === "Language Learning") {
			updateField("isDataFetching", true);
			changeType("lessons");
			getAllGrammar(`language=${pathname[2]}&Text=${searchText}`).then((res) => {
				setIsSearchDisable(false)
			})
			getGrammarDetails(`Language=${language}&Lesson=${lessonNumber}`).then((res) => {
				updateField("isDataFetching", false);
			});
		}
		if (menu.length > 0 && menu[0] && menu[0].title === "Vocabulary" && type === "lessons") {
			updateField("isDataFetching", true);
			if (localStorage.getItem("redirectToLesson")) {
				localStorage.removeItem("redirectToLesson");
			}
			getAllLessons(`language=${pathname[2]}&Text=${searchText}`).then((res) => {
				setIsSearchDisable(false)
			});
			setLessonDetails(`Language=${pathname[2]}&Lesson=${lessonNumber}`).then((res) => {
				updateField("isDataFetching", false);
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText, type, menu])

	const getDetails = (item) => {
		setLoading(true);
		handleSubMenu(type);
		if (menu.length > 0 && menu[0] && menu[0].title === "Language Learning") {
			setLessonNumber(item);
			getGrammarDetails(`Language=${language}&Lesson=${item}`).then((res) => {
				setLoading(false);
			});
		} else {
			if (type === "lessons") {
				setLessonDetails(`Lesson=${item}&Language=${language}`).then((res) => {
					setLoading(false);
				});
				setLessonNumber(item);
			} else {
				setWordDetails(`Text=${item}&Language=${language}`).then((res) => {
					setLoading(false);
				});
			}
		}
	};

	return (
		<div>
			{isSearchDisable ? <span className='search-loading'>Loading...</span> : type === "lessons" ? (lessons && lessons.length === 0) ? <span className='no-data'> No results found </span> :
				lessons.map((item, index) => (
					<div
						key={index}
						className={`${lessonNumber === item
							? " py-3 text-center list-item d-flex  align-items-center item-lesson-active"
							: "py-3 text-center list-item d-flex  align-items-center"
							} `}
						onClick={() => getDetails(item)}>
						<div className="list-icon">
							<i className="icon icon-dictionary-icon"></i>
						</div>
						Lesson {item}
					</div>
				))
				: (words && words.length === 0) ? <span className='no-data'> No results found</span> : words.map((item, index) => (
					<div
						key={index}
						className={` ${wordDetails && wordDetails.length === 1 && wordDetails[0].text === item
							? "py-3 text-center list-item d-flex  align-items-center item-lesson-active"
							: "py-3 text-center list-item d-flex  align-items-center"
							}  ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}
						onClick={() => getDetails(item)}>
						<div className="list-icon">
							<i className="icon icon-dictionary-icon"></i>
						</div>
						{item}
					</div>
				))}
			{loading && <Loader />}
		</div>
	);
};

const mapStateToProps = (state) => ({
	words: state.subMenu.words,
	lessons: state.subMenu.lessons,
	language: state.sideMenu.language,
	menu: state.sideMenu.menu,
	type: state.subMenu.type,
	lessonNumber: state.subMenu.lessonNumber,
	wordDetails: state.subMenu.wordDetails,
	searchText: state.subMenu.searchText,
	isSearchDisable: state.subMenu.isSearchDisable,
	isDataFetching: state.subMenu.isDataFetching
});

const mapDispatchToProps = {
	getAllLessons,
	getAllWords,
	setLessonDetails,
	setWordDetails,
	getGrammarDetails,
	changeType,
	setLessonNumber,
	getAllGrammar,
	updateField,
	setIsSearchDisable
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDictionaryLessons);
