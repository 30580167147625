import React, { useEffect, useState } from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import noData from "../../../assets/images/no-data.png";
import { getUserDetails } from "../../../utils/verifyUser";
import Header from "../../header";
import Loader from "../../sharedComponents/Loader";
import { NoContent } from "../../sharedComponents/NoContent";
import { setSideMenu } from "../../Sidemenu/actions";
import { updateField } from "../LanguageLearning/actions";
import { getTabData } from "./actions";
import "./readingComprehension.scss";

const ReadingComprehension = ({ setSideMenu, history, updateField, language, match, getTabData, menu }) => {
	const { t } = useTranslation();
	const tabHeader = [
		t("languagelearning.readingcomprehension.lessonreading"),
		t("languagelearning.readingcomprehension.generalreading"),
	];
	const [activeTab, setActiveTab] = useState(tabHeader[0]);
	const [currentLevel, setCurrentLevel] = useState(null);
	const [tabContent, setTabContent] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		getTabContent(activeTab);
		return () => {
			setTabContent([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: t("languagelearning.readingcomprehension.name"), path: "" }],
			language: location[2],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location]);

	const handleDropItem = (level) => {
		setCurrentLevel(currentLevel === level ? "" : level);
	};

	const redirectToReadingComprehensionLesson = (id) => history.push(`/home/${language}/reading-comprehension/deck/${id}`);

	const title = (name) => (
		<Card.Title className="pointer">
			<span className="circle"></span>
			<span className="header">{name}</span>
		</Card.Title>
	);

	const lessonReadingBody = (el) => {
		return (
			<Card key={el.lessonNumber} className="m-3 tab-card card-lesson">
				<Row className="pl-2 pr-2">
					<i className="arrow down"></i>
					<Col md={12} className="p-0">
						<div
							className="card-border-btm d-flex justify-content-between align-items-center mr-3 ml-3 pt-2 pb-2"
							onClick={() => {
								handleDropItem(el.lessonNumber);
							}}>
							{title(t("languagelearning.readingcomprehension.lessonnumber", { lessonnumber: el.lessonNumber }))}
							<div className="dropdown-icon dictionary-dropdown">
								<i
									className={
										currentLevel === el.lessonNumber ? "icon-down down-font" : "icon icon-angle-arrow-down"
									}></i>
							</div>
						</div>
					</Col>
				</Row>
				<Row className="ml-1 mr-1">
					{currentLevel === el.lessonNumber &&
						el.decks.map((ele, index) => (
							<Col md={4} key={ele.id} className="p-0">
								<Card
									className="m-2 tab-card card-lesson pointer"
									onClick={() => redirectToReadingComprehensionLesson(ele.id)}>
									<Card.Body className="d-flex flex-column p-2 position-relative">
										<span>
											{t("languagelearning.readingcomprehension.lesson_sublesson", {
												lessonnumber: el.lessonNumber,
												sublessonnumber: ele.subLessonNumber,
											})}
										</span>
										<span>{ele.name}</span>
										{typeof ele.status !== "undefined" && (
											<div className="p-2 position-absolute" style={{ top: "0px", right: "0px" }}>
												{ele.status === 2 ? (
													<span
														className="icon-check-2"
														title={t("helpers.utils.lessonstatus.completed")}>
														<span className="path1"></span>
														<span className="path2"></span>
													</span>
												) : ele.status === 1 ? (
													<span
														className="icon-clock-5"
														title={t("helpers.utils.lessonstatus.inprogress")}>
														<span className="path1"></span>
														<span className="path2"></span>
													</span>
												) : (
													<span
														className="icon-block"
														title={t("helpers.utils.lessonstatus.notstarted")}>
														<span className="path1"></span>
														<span className="path2"></span>
													</span>
												)}
											</div>
										)}
									</Card.Body>
								</Card>
							</Col>
						))}
				</Row>
			</Card>
		);
	};

	const generalReading = (content) => {
		let isArchivedData = false;
		return (
			<>
				{content.map((el) => (
					<>
						{!el.isArchived ? (
							<Card key={el.lessonNumber} className="m-3 tab-card card-lesson">
								<Row className="pl-2 pr-2">
									<Col md={12} className="p-1">
										<div
											className="d-block mr-3 ml-3 pt-2 pb-2 pointer"
											onClick={() => redirectToReadingComprehensionLesson(el.id)}>
											{title(el.name)}
											{typeof el.status !== "undefined" && (
												<div className="p-2 position-absolute" style={{ top: "3px", right: "40px" }}>
													{el.status === 2 ? (
														<span
															className="icon-check-2"
															title={t("helpers.utils.lessonstatus.completed")}>
															<span className="path1"></span>
															<span className="path2"></span>
														</span>
													) : el.status === 1 ? (
														<span
															className="icon-clock-5"
															title={t("helpers.utils.lessonstatus.inprogress")}>
															<span className="path1"></span>
															<span className="path2"></span>
														</span>
													) : (
														<span
															className="icon-block"
															title={t("helpers.utils.lessonstatus.notstarted")}>
															<span className="path1"></span>
															<span className="path2"></span>
														</span>
													)}
												</div>
											)}
										</div>
									</Col>
								</Row>
							</Card>
						) : (
							(isArchivedData = true)
						)}
					</>
				))}

				{isArchivedData && (
					<Card className="m-3 tab-card card-lesson">
						<Row className="pl-2 pr-2">
							<i className="arrow down"></i>
							<Col md={12} className="p-0">
								<div
									className="card-border-btm d-flex justify-content-between align-items-center mr-3 ml-3 pt-2 pb-2"
									onClick={() => {
										handleDropItem(t("helpers.utils.archived"));
									}}>
									{title(t("helpers.utils.capitalize", { value: "archived" }))}
									<div className="dropdown-icon dictionary-dropdown">
										<i className={currentLevel ? "icon-down down-font" : "icon icon-angle-arrow-down"}></i>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="ml-1 mr-1">
							{currentLevel === t("helpers.utils.archived") &&
								content.map((el) => (
									<>
										{el.isArchived && (
											<Col md={4} key={el.id} className="p-0">
												<Card
													className="m-2 tab-card card-lesson pointer"
													onClick={() => redirectToReadingComprehensionLesson(el.id)}>
													<Card.Body className="d-flex flex-column p-2 position-relative">
														<span className="name-tag">{el.name}</span>
													</Card.Body>
												</Card>
											</Col>
										)}
									</>
								))}
						</Row>
					</Card>
				)}
			</>
		);
	};

	const forPractice = (el) => (
		<Card key={el.lessonNumber} className="m-3 tab-card card-lesson">
			<Row className="pl-2 pr-2">
				<i className="arrow down"></i>
				<Col md={12} className="p-0">
					<div
						className="card-border-btm d-flex justify-content-between align-items-center mr-3 ml-3 pt-2 pb-2"
						onClick={() => {
							handleDropItem(t("dashboard.planner.newplan.practice"));
						}}>
						{title(t("languagelearning.fluencydrills.paradigmsforpractice"))}
						<div className="dropdown-icon dictionary-dropdown">
							<i className={currentLevel ? "icon-down down-font" : "icon icon-angle-arrow-down"}></i>
						</div>
					</div>
				</Col>
			</Row>
			<Row className="ml-1 mr-1">
				{currentLevel === t("dashboard.planner.newplan.practice") &&
					el.map((ele) => (
						<Col md={4} key={ele.id} className="p-0">
							<Card
								className="m-2 tab-card card-lesson pointer"
								onClick={() => redirectToReadingComprehensionLesson(ele.id)}>
								<Card.Body className="d-flex flex-column p-2 position-relative">
									{ele.mainLessonNumber && ele.subLessonNumber && (
										<span>{t("languagelearning.fluencydrills.lesson_sublesson",{lessonnumber:`${ele.mainLessonNumber}`,sublessonnumber:`${ele.subLessonNumber}`})}</span>
									)}
									<span>{ele.name}</span>
								</Card.Body>
							</Card>
						</Col>
					))}
			</Row>
		</Card>
	);

	const renderTabComponent = () => (
		<>
			<Scrollbars style={{ height: "75vh" }}>
				{tabContent.length > 0
					? activeTab === t('languagelearning.readingcomprehension.generalreading')
						? generalReading(tabContent)
						: tabContent.map((el, index) => {
							if (activeTab === tabHeader[0]) {
								return lessonReadingBody(el);
							}
							return null;
						})
					: !loading && <NoContent bgImage={noData} content={t("helpers.utils.nodata")} />}
			</Scrollbars>
		</>
	);

	const getTabContent = (tab, search) => {
		setLoading(true);
		getTabData(tab, match.params.language, search).then((res) => {
			if (res.statusCode === 200) {
				setTabContent(res.response);
				setLoading(false);
			}
		});
	};

	const onSelectTab = (tab) => {
		setActiveTab(tab);
		setTabContent([]);
		getTabContent(tab);
		setSearchTerm("");
	};

	return (
		<div className="reading-comprehension empty">
			{loading && <Loader />}
			<Header
				enableLanguageSwitch={true}
				redirectionUrl="Reading-Comprehension"
				head={t("languagelearning.readingcomprehension.name")}
			/>
			<div className="tab-wrapper">
				<div className="position-relative">
					<div className="position-absolute reading-comprehension-settings d-flex">
						<div className="search-container">
							<i className="icon icon-search" />
							<input
								className="input-search"
								type="text"
								value={searchTerm}
								placeholder={t("languagelearning.readingcomprehension.searchhere")}
								onChange={(e) => {
									setSearchTerm(e.target.value);
								}}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										getTabContent(activeTab, e.target.value);
									}
								}}
							/>
						</div>
					</div>
				</div>
				<Tabs className="default-tabs" activeKey={activeTab} onSelect={onSelectTab}>
					{tabHeader.map((tabName) => (
						<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
							{renderTabComponent()}
						</Tab>
					))}
				</Tabs>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getTabData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReadingComprehension));
