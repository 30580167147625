export const userListFields = [
  {
    label: "Name",
    accessor: "firstName",
  },
  {
    label: "Role Type",
    accessor: "role",
    width: 300,
    filter: "select",
    isMulti: true,
  },
  {
    label: "Subscribed Language",
    accessor: "learningLanguages",
    width: 205,
    filter: "select",
    isMulti: true,
  },
  {
    label: "Current Lesson",
    accessor: "currentLesson",
  },
  {
    label: "University or Organization",
    accessor: "universityOrOrganization",
    width: 210,
  },
  {
    label: "Email Address",
    accessor: "email",
    width: 160,
  },
  {
    label: "ClassRoom Codes",
    accessor: "classRoomCodes",
    width: 150,
  },
  {
    label: "Action",
    headerClassName: "column-actions actions-header",
    width: 150,
    accessor: "Action",
    className: "column-actions",
    disableSort: true,
    disableFilter: true,
  },
];

export const userDetailsFields = [
  {
    label: "University or Organization affiliation",
    accessor: "universityOrOrganization",
  },
  {
    label: "Country",
    accessor: "country",
  },
  {
    label: "Native language spoken",
    accessor: "nativeLanguage",
  },
  {
    label: "Other languages spoken fluently",
    accessor: "otherLanguages",
  },
  {
    label: "Which language are you teaching?",
    accessor: "teachingLanguage",
  },
  {
    label: "Years of experience in teaching Greek",
    accessor: "teachingGreek",
  },
  {
    label: "Years of experience in teaching Hebrew",
    accessor: "teachingHebrew",
  },

  {
    label: "Why are you studying?",
    accessor: "learningReason",
  },
  {
    label: "Subscribed language",
    accessor: "subscribedLanguage",
  },
  {
    label: "Current Lesson",
    accessor: "currentLesson",
  },
  {
    label: "Greek pronunciation type",
    accessor: "greekPronouncationType",
  },
  {
    label: "Hebrew pronunciation type",
    accessor: "hebrewPronouncationType",
  },

  {
    label: "Years studying Greek",
    accessor: "speakingGreek",
  },
  {
    label: "Years studying Hebrew",
    accessor: "speakingHebrew",
  },
  {
    label: "Classroom Codes",
    accessor: "classRoomCodes",
  },
];

export const resumeProgressFields = [
  {
    label: "Email Id",
    name: "email",
    type: "text",
    disabled: true,
  },
  {
    label: "Language",
    name: "language",
    type: "select",
  },
  {
    label: "Lesson Number",
    name: "lessonNumber",
    type: "select",
    integer: true,
  },
];

export const experienceList = [
  { label: "<1", value: "<1" },
  { label: "<2", value: "<2" },
  { label: "<3", value: "<3" },
  { label: "<4", value: "<4" },
  { label: "<5", value: "<5" },
  { label: "<6", value: "<6" },
  { label: "<7", value: "<7" },
  { label: "<8", value: "<8" },
  { label: "<9", value: "<9" },
  { label: "<10", value: "<10" },
  { label: "10+", value: "10+" },
];

export const languages = [
  { label: "Hebrew", value: "Hebrew" },
  { label: "Greek", value: "Greek" },
  { label: "Both", value: ["Hebrew", "Greek"] },
];

export const userCreationFields = [
  {
    label: "First name",
    name: "firstName",
  },
  {
    label: "Last name",
    name: "lastName",
  },
  {
    label: "Role type",
    name: "role",
    type: "select",
  },
  {
    label: "Email address",
    name: "email",
    disabled: true,
  },
  {
    label: "Contact number",
    name: "phoneNumber",
  },
  {
    label: "University or organization affiliation",
    name: "universityOrOrganization",
  },
  {
    label: "Country",
    type: "select",
    name: "countryCode",
  },
  {
    label: "Native language spoken",
    name: "nativeLanguage",
  },
  {
    label: "Other languages spoken fluently",
    name: "otherLanguages",
  },
  {
    label: "Which language are you teaching?",
    name: "teachingLanguage",
    type: "select",
  },
  {
    label: "Why are you studying?",
    name: "learningReasonID",
    type: "select",
  },
  {
    label: "Subscribed language",
    name: "subscribedLanguage",
    type: "select",
    disabled: true,
  },
  {
    label: "Years studying Hebrew",
    name: "experienceInSpeakingHebrew",
    type: "select",
  },
  {
    label: "Years of experience in teaching Hebrew",
    name: "experienceInTeachingHebrew",
    type: "select",
  },
  {
    label: "Years studying Greek",
    name: "experienceInSpeakingGreek",
    type: "select",
  },
  {
    label: "Years of experience in teaching Greek",
    name: "experienceInTeachingGreek",
    type: "select",
  },

  {
    label: "Greek pronunciation type",
    name: "greekPronouncationType",
    type: "select",
  },
  {
    label: "Hebrew pronunciation type",
    name: "hebrewPronouncationType",
    type: "select",
  },
];
