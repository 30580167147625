import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Tabs, Tab, Container, Card } from "react-bootstrap";
import { getUserDetails } from "../../../../utils/verifyUser";
import { updateField } from "../../LanguageLearning/actions";
import { setSideMenu } from "../../../Sidemenu/actions";
import ReportTableComponent from "../ReportTable";
import { getReports } from "../action";
import { dateValues, getPath } from "../helper";

import { reportsUrls } from "../types";
import Pagination from "../../../sharedComponents/Pagination";

import Header from "../../../header";
import "./reports.scss";
import Loader from "../../../sharedComponents/Loader";
import { useTranslation } from "react-i18next";

const SIZE = 12;

const Reports = ({ menu, history, updateField, setSideMenu, getReports, match }) => {
	const { t } = useTranslation();
	const tabsList = {
		Grammar: [t("dashboard.reports.grammar.grammartopic"), t("menu.fluencydrill")],
		Reading: [t("biblereading.name"), t("languagelearning.readingcomprehension.name")],
		Vocabulary: [t("dashboard.reports.vocabulary.wordslearned")],
	};

	const {
		fluencyDrillReports,
		topicsLearnedReports,
		chaptersCompletedReports,
		comprehesionReadingReports,
		wordsLearnedReports,
	} = reportsUrls;

	const titleHeaders = {
		Grammar: [
			[
				{ label: t("dashboard.reports.grammar.topicslearned"), key: "topic", sort: "Topic" },
				{ key: "inCorrectFrequency", label: t("dashboard.reports.grammar.timesincorrect"), sort: "InCorrectFrequency" },
				{ key: "subLesson", label: t("dashboard.reports.grammar.lessonnumber"), sort: "SubLesson" },
				{
					key: "firstEncounter",
					label: t("dashboard.reports.grammar.firstencounter"),
					type: "date",
					sort: "FirstEncounter",
				},
			],
			[
				{ label: t("dashboard.reports.grammar.paradigm"), key: "paradigm", sort: "Paradigm" },
				{ key: "timesIncorrect", label: t("dashboard.reports.grammar.timesincorrect"), sort: "TimesIncorrect" },
				{
					label: t("helpers.utils.stage"),
					key: ["stage", "currentStage"],
					type: "nested",
					textCenter: true,
					sort: "Stage",
				},
				{
					key: "firstEncounter",
					label: t("dashboard.reports.grammar.firstencounter"),
					type: "date",
					sort: "FirstEncounter",
				},
			],
		],

		Reading: [
			[
				{ label: t("dashboard.reports.reading.chapter"), key: "chapter", sort: "Book" },
				{
					label: t("dashboard.reports.reading.percentagereadingcompletion"),
					key: "percentage",
					sort: "Percentage",
					type: "percentage",
				},
				{
					label: t("dashboard.reports.reading.datecompleted"),
					key: "dateCompleted",
					type: "date",
					sort: "DateCompleted",
				},
			],
			[
				{ label: t("dashboard.reports.reading.questions"), key: "question", sort: "Question" },
				{
					label: t("dashboard.reports.vocabulary.incorrectfrequency"),
					key: "inCorrectFrequency",
					sort: "InCorrectFrequency",
				},
				{
					label: t("dashboard.reports.reading.datecompleted"),
					key: "dateCompleted",
					type: "date",
					sort: "DateCompleted",
				},
			],
		],

		Vocabulary: [
			[
				{ label: t("dashboard.reports.vocabulary.wordslearned"), key: "wordLearned", sort: "Words Learned" },
				{ label: t("helpers.utils.gloss"), key: "gloss", sort: "Gloss" },
				{
					label: t("dashboard.reports.reading.incorrectfrequency"),
					key: ["timesIncorrect", "incorrectAttempts"],
					textCenter: true,
					type: "nested",
					sort: "Incorrect Attempts",
				},
				{
					label: t("dashboard.reports.vocabulary.percentagecorrect"),
					key: ["timesIncorrect", "correctPercentage"],
					textCenter: true,
					type: "nested",
					sort: "Correct Percentage",
				},
				{
					label: t("helpers.utils.stage"),
					key: ["stage", "currentStage"],
					type: "nested",
					textCenter: true,
					sort: "Stage",
				},
			],
		],
	};

	const studentId = match.params.id;

	const [pageNumber, setPageNumber] = useState(0);
	const [tabs, setTabs] = useState([]);
	const [activeTab, setActiveTab] = useState(t("dashboard.reports.grammar.grammartopic"));
	const [tableData, setTableData] = useState();
	const [index, setIndex] = useState(0);
	const [loading, setLoading] = useState(false);
	const [header, setHeader] = useState([]);
	const [sortValue, setSort] = useState("");

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [
				{
					title: t("dashboard.name"),
					path: studentId
						? `/Home/${match.params.language}/Dashboard/${studentId}`
						: `/Home/${match.params.language}/Dashboard`,
				},
				{ title: t("dashboard.reports.name"), path: "" },
			],
			language: location[2],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTabs(tabsList[match.params.from]);
		if (match.params.report === "topics-learned") {
			setActiveTab(t("dashboard.reports.grammar.grammartopic"));
			setHeader(titleHeaders["Grammar"]);
		} else if (match.params.report === "fluency-drills") {
			setActiveTab(t("menu.fluencydrill"));
			setHeader(titleHeaders["Grammar"]);
		} else if (match.params.report === "bible-reading") {
			setActiveTab(t("biblereading.name"));
			setHeader(titleHeaders["Reading"]);
		} else if (match.params.report === "reading-comprehension") {
			setActiveTab(t("languagelearning.readingcomprehension.name"));
			setHeader(titleHeaders["Reading"]);
		} else if (match.params.report === "words-learned") {
			setActiveTab(t("dashboard.reports.vocabulary.wordslearned"));
			setHeader(titleHeaders["Vocabulary"]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = async (report, pageNumber, sortBy, SortDirection) => {
		setLoading(true);
		if (report === "topics-learned") {
			const { response: topicsLearnedReport } = await getReports(
				getPath(
					topicsLearnedReports,
					match.params.language,
					null,
					dateValues[0].value,
					true,
					pageNumber,
					SIZE,
					studentId,
					sortBy,
					SortDirection
				)
			);
			setTableData(topicsLearnedReport);
		} else if (report === "fluency-drills") {
			setActiveTab(t("menu.fluencydrill"));
			const { response: fluencyDrillReport } = await getReports(
				getPath(
					fluencyDrillReports,
					match.params.language,
					null,
					dateValues[0].value,
					true,
					pageNumber,
					SIZE,
					studentId,
					sortBy,
					SortDirection
				)
			);
			setTableData(fluencyDrillReport);
		} else if (report === "bible-reading") {
			setActiveTab(t("biblereading.name"));
			const { response: chaptersCompletedReport } = await getReports(
				getPath(
					chaptersCompletedReports,
					match.params.language,
					null,
					dateValues[0].value,
					true,
					pageNumber,
					SIZE,
					studentId,
					sortBy,
					SortDirection
				)
			);
			setTableData(chaptersCompletedReport);
		} else if (report === "reading-comprehension") {
			setActiveTab(t("languagelearning.readingcomprehension.name"));
			const { response: comprehesionReadingReport } = await getReports(
				getPath(
					comprehesionReadingReports,
					match.params.language,
					null,
					dateValues[0].value,
					true,
					pageNumber,
					SIZE,
					studentId,
					sortBy,
					SortDirection
				)
			);
			setTableData(comprehesionReadingReport);
		} else if (report === "words-learned") {
			setActiveTab(t("dashboard.reports.vocabulary.wordslearned"));
			const { response: wordsLearnedReport } = await getReports(
				getPath(
					wordsLearnedReports,
					match.params.language,
					null,
					dateValues[0].value,
					true,
					pageNumber,
					SIZE,
					studentId,
					sortBy,
					SortDirection
				)
			);
			setTableData(wordsLearnedReport);
		}
		setLoading(false);
	};

	const changeTab = async (tab) => {
		setLoading(true);
		let url = "";
		if (tab === t("dashboard.reports.grammar.grammartopic")) {
			url = topicsLearnedReports;
		} else if (tab === t("menu.fluencydrill")) {
			url = fluencyDrillReports;
		} else if (tab === t("biblereading.name")) {
			url = chaptersCompletedReports;
		} else if (tab === t("languagelearning.readingcomprehension.name")) {
			url = comprehesionReadingReports;
		} else if (tab === t("dashboard.reports.vocabulary.wordslearned")) {
			url = wordsLearnedReports;
		}
		const { response: tableData } = await getReports(
			getPath(url, match.params.language, null, dateValues[0].value, true, index, SIZE)
		);
		setTableData(tableData);
		setLoading(false);
	};

	useEffect(() => {
		getData(match.params.report, pageNumber);
	}, []);

	const sortBy = (value, dir) => {
		getData(match.params.report, pageNumber, value, dir);
	};

	return (
		<div className="reports-container empty">
			<Header
				menu={menu}
				language={match.params.language}
				history={history}
				head={t("dashboard.name")}
				enableLanguageSwitch
				redirectionUrl="Dashboard"
			/>
			{loading && <Loader />}
			<div className="report-content">
				{tableData && !loading && (
					<Tabs
						className="default-tabs"
						activeKey={activeTab}
						onSelect={(tab) => {
							setActiveTab(tab);
							changeTab(tab);
							setIndex(0);
						}}>
						{tabs.map((tabName, index) => (
							<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
								<Container fluid style={{ paddingLeft: 10, paddingRight: 10, height: "100%" }}>
									<Card className="" style={{ height: "100%" }}>
										<div className="table-wrapper">
											<ReportTableComponent
												header={header[index]}
												data={tableData}
												sortBy={sortBy}
												sortValue={sortValue}
												setSort={setSort}
											/>
										</div>
									</Card>

									{tableData && tableData.count > 0 && (
										<Pagination
											activePage={pageNumber + 1}
											itemsCountPerPage={10}
											totalItemsCount={tableData.count}
											pageRangeDisplayed={7}
											onChange={(pageNumber) => {
												setPageNumber(pageNumber - 1);
												getData(match.params.report, pageNumber - 1);
											}}
										/>
									)}
								</Container>
							</Tab>
						))}
					</Tabs>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({ menu: state.sideMenu.menu });
const mapDispatchToProps = { setSideMenu, updateField, getReports };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));
