import { AUTH } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export const sendLoginCredentials = (creds) => {
	const fetchOptions = {
		url: "token",
		method: "POST",
		actionType: AUTH.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(creds),
		secure: false,
	};

	return fetchHandler(fetchOptions);
};

export const forgotPassword = (creds) => {
	const fetchOptions = {
		url: "user/forgotpassword",
		method: "PUT",
		actionType: AUTH.FORGOT_PASSWORD,
		body: JSON.stringify(creds),
		secure: false,
	};

	return fetchHandler(fetchOptions);
};

export const resetPassword = (creds) => {
	const fetchOptions = {
		url: "user/resetpassword",
		method: "PUT",
		actionType: AUTH.RESET_PASSWORD,
		body: JSON.stringify(creds),
		secure: false,
	};

	return fetchHandler(fetchOptions);
};

export const changePassword = (creds) => {
	const fetchOptions = {
		url: "user/changepassword",
		method: "PUT",
		actionType: AUTH.CHANGE_PASSWORD,
		body: JSON.stringify(creds),
		secure: true,
	};

	return fetchHandler(fetchOptions);
};
export const validateToken = (id, token) => {
	const fetchOptions = {
		url: `token/validate/${id}/${token}`,
		method: "GET",
		actionType: AUTH.VALIDATE_TOKEN,
		secure: false,
		shouldDispatch: false,
	};

	return fetchHandler(fetchOptions);
};

export const campusAuthStatus = (creds) => {
	const fetchOptions = {
		url: "campusedu/auth/status",
		method: "POST",
		actionType: AUTH.SEND_LOGIN_CREDENTIALS,
	};
	return fetchHandler(fetchOptions);
};

export const fullerAuth = (creds) => {
	const fetchOptions = {
		url: "fuller/token",
		method: "POST",
		actionType: AUTH.SEND_LOGIN_CREDENTIALS,
		body: JSON.stringify(creds),
	};
	return fetchHandler(fetchOptions);
};
