export const FLUENCY_DRILL = {
	GET_TAB_CONTENT: "FLUENCY_GET_TAB_CONTENT",
	CHANGE_ACTIVITY: "FLUENCY_CHANGE_ACTIVITY",
	VERIFY_ACTIVITY: "FLUENCY_VERIFY_ACTIVITY",
	GET_DETAILS: "FLUENCY_GET_DETAILS",
	CHANGE: "FLUENCY_CHANGE",
	ARCHIVE: "FLUENCY_ARCHIVE_DECK",
	UNARCHIVE: "FLUENCY_UNARCHIVE_DECK",
	GET_SETTINGS: "GET_SETTINGS",
};
