import { useEffect, useState } from "react";

const useRecorder = () => {
	const [audioURL, setAudioURL] = useState("");
	const [isRecording, setIsRecording] = useState(false);
	const [recorder, setRecorder] = useState(null);
	const [byteArray, setByteArray] = useState(null);

	useEffect(() => {
		// Lazily obtain recorder first time we're recording.
		if (recorder === null) {
			if (isRecording) {
				requestRecorder().then(setRecorder, console.error);
			}
			return;
		}
		// Manage recorder state.
		if (recorder) {
			if (isRecording) {
				recorder.start();
			} else if (recorder.state !== "inactive") {
				recorder.stop();
				recorder.stream.getTracks().forEach((track) => track.stop());
			}
		}

		const toBase64 = (file) =>
			new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});

		// Obtain the audio when ready.
		const handleData = async (e) => {
			toBase64(e.data).then((res) => setByteArray(res.split(",")[1]));
			setAudioURL(URL.createObjectURL(e.data));
		};

		recorder.addEventListener("dataavailable", handleData);
		return () => recorder.removeEventListener("dataavailable", handleData);
	}, [recorder, isRecording]);

	const startRecording = () => {
		setRecorder(null);
		setIsRecording(true);
	};

	const stopRecording = () => {
		setIsRecording(false);
	};

	return [audioURL, isRecording, byteArray, startRecording, stopRecording, setAudioURL];
};

async function requestRecorder() {
	const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
	return new MediaRecorder(stream);
}
export default useRecorder;
