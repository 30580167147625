import React from "react";
import { Modal } from "react-bootstrap";
import "./modal.scss";

const VerticalModal = ({ show, onClose, isClosing, children }) => {
	return (
		<>
			<Modal
				size="lg"
				className={`${isClosing ? "slideOut" : "slideIn"} `}
				animation={false}
				show={show}
				onHide={onClose}
				id="vertical-modal"
				backdrop="static">
				<div className="position-absolute close-icon" onClick={onClose}>
					<span className="icon-close-2"></span>
				</div>
				<Modal.Body style={{ overflow: "scroll" }}>{children}</Modal.Body>
			</Modal>
		</>
	);
};

export default VerticalModal;
