import { QA } from "./types";

const initalState = {
  qaStatusList: []
};

export default function(state = initalState, action) {
  switch (action.type) {
    case QA.FETCH_QUESTIONS:
      return { ...state, questionsList: action.payload.response.records };
    case QA.FETCH_QUESTION_DETAILS:
      return { ...state, questionDetails: action.payload.response };
    case QA.FETCH_ANSWERS:
      return { ...state, answerList: action.payload.response.records };
    case QA.FETCH_QUESTION_STATUSES:
      return { ...state, qaStatusList: action.payload.response };
    case QA.UPDATE_FIELD:
      return {
        ...state,
        [action.field]: action.val
      };
    default:
      return state;
  }
}
