import React from "react";
import NoSearch from "../../assets/images/no-search.svg";

const NoData = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <img src={NoSearch} alt="" />
        </div>
    );
};

export default NoData;
