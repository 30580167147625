import React, { useState, useEffect, useRef } from "react";

import ImageCard from "../MediaGallery/imageCard";
import VideoCard from "../MediaGallery/videoCard";
import AudioCard from "../MediaGallery/audioCard";
import { Button } from "react-bootstrap";
import PlaceHolder from "../../assets/images/placeholder-image.png";
import { getUserDetails } from '../../utils/verifyUser';

const MediaCard = ({
  isImageGallery,
  isVideoGallery,
  isAudioGallery,
  cardData,
  setShow,
  isEditing,
  setDeleteItem,
  setIsEditing,
  handleImageRename,
  handleVideoPlayer,
  handleAudioPlayer,
  selectAll,
  deleteItemList,
  setDeleteItemList
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const filePath = cardData.filePath;
  const fileName = cardData.fileName;
  const userRole =
  getUserDetails () &&
  getUserDetails ().role &&
  getUserDetails ().role.description;


  const [imageName, setImageName] = useState(fileName);
  const videoRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    selectRef.current.checked = selectAll ? true : false;
  }, [selectAll]);

  const renderImageOverlay = () => (
    <div className="overlay-wrapper">
      <button
        className="btn-circle d-flex justify-content-center align-items-center border-0  mr-3"
        onClick={() => setShow(filePath)}
      >
        <i className="icon icon-zoom " />
      </button>
    </div>
  );

  const renderCheckBox = () => (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        ref={selectRef}
        onChange={() => handleSelection(cardData)}
      />
      <span className={ userRole === "Administrator" ? "tickmark" :"d-none"}></span>
    </label>
  );

  function capture(video) {
    var w = 100;
    var h = 100;
    var canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, w, h);
    return canvas.toDataURL();
  }

  const handleSelection = cardData => {
    if (selectRef.current.checked === true) {
      setDeleteItemList([...deleteItemList, cardData]);
    } else {
      setDeleteItemList(deleteItemList.filter(item => item !== cardData));
    }
  };

  const renderVideoCard = () => (
    <div className="video-wrapper position-relative h-100 w-100 bg-paceholder">
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        id="video"
        onLoadedData={() => capture(videoRef.current)}
      >
        <source src={filePath} type="video/mp4" />
      </video>
      {renderCheckBox()}
      <Button className="play-btn" onClick={() => handleVideoPlayer(filePath)}>
        <i className="icon icon-Group-15" />
      </Button>
    </div>
  );

  return (
    <div className="card mb-4">
      {isImageGallery && (
        <div className="media-image-wrapper position-relative">
          {renderImageOverlay()}
          {renderCheckBox()}

          <div className="bg-white h-100">
            <img
              src={filePath ? filePath : PlaceHolder}
              className="card-img-top w-100"
              alt=""
            />
          </div>
        </div>
      )}
      {isVideoGallery && (
        <div className="media-image-wrapper position-relative">
          {renderVideoCard()}
        </div>
      )}

      <div className="card-body">
        {isImageGallery && (
          <ImageCard
            setDeleteItem={setDeleteItem}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            cardData={cardData}
            handleImageRename={handleImageRename}
            isEdit={isEdit}
            imageName={imageName}
            setImageName={setImageName}
            setIsEdit={setIsEdit}
          />
        )}
        {isVideoGallery && (
          <VideoCard
            cardData={cardData}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setIsEditing={setIsEditing}
            imageName={imageName}
            setImageName={setImageName}
            isEditing={isEditing}
            setDeleteItem={setDeleteItem}
            handleImageRename={handleImageRename}
          />
        )}
        {isAudioGallery && (
          <div className="audio-checkbox">
            {!isEdit && renderCheckBox()}
            <AudioCard
              cardData={cardData}
              isEditing={isEditing}
              isEdit={isEdit}
              imageName={imageName}
              setIsEdit={setIsEdit}
              setIsEditing={setIsEditing}
              setDeleteItem={setDeleteItem}
              setImageName={setImageName}
              handleImageRename={handleImageRename}
              handleAudioPlayer={handleAudioPlayer}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaCard;
