import React, { useEffect, useReducer, useRef } from "react";
import { connect } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import SharedModal from "../../../sharedComponents/Modal";
import { fieldUpdateReducer } from "../../../../utils/helper";
import AudioSettingsList from "./audioSettings";
import grammarSymbolGlossary from "../../../../assets/files/Grammar_Symbols_Glossary.pdf";
import { renderActionButtons, togglePlay } from "./helper";
import "./grammar.scss";

const Grammar = ({ lessonDetails, skipActivity, disableNext, language, updateAcitivity, audioSpeedList }) => {
	const initialState = {
		grammar: {},
		isAssociation: false,
		isAudioPlaying: false,
		showModal: false,
		initialAutoPlay: true,
		videoUrl: "",
		primaryGrammarList: [],
		secondaryGrammarList: [],
		selectedGrammarList: [],
	};
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(fieldUpdateReducer, initialState);
	const {
		grammar,
		isAssociation,
		showModal,
		videoUrl,
		isAudioPlaying,
		initialAutoPlay,
		primaryGrammarList,
		secondaryGrammarList,
		selectedGrammarList,
	} = state;
	const secondaryVideoRef = useRef(null);

	
	const dtlsFields = [
		{
			title: t("helpers.utils.gloss"),
			icon: "icon-paragraph",
			field: "gloss",
		},
		{
			title: t("helpers.utils.definition"),
			icon: "icon-definition",
			field: "definition",
		},
		{
			title: t("languagelearning.lessons.details.examples"),
			icon: "icon-example",
			field: "examples",
		},
	];

	useEffect(() => {
		if (lessonDetails && lessonDetails.activity) {
			if (lessonDetails.activity.grammar) {
				dispatch({
					grammar: lessonDetails.activity.grammar,
					initialAutoPlay: true,
				});
			}
		}
		if (lessonDetails && lessonDetails.stage === 4) {
			dispatch({ isAssociation: true });
		} else {
			dispatch({ isAssociation: false });
		}
	}, [lessonDetails]);

	useEffect(() => {
		if (grammar) {
			let primaryGrammarList = [];
			let secondaryGrammarList = [];
			if (grammar.primarySymbols && grammar.primarySymbols.length > 0) {
				primaryGrammarList = grammar.primarySymbols.filter((primary) => primary.filePath);
			}
			if (grammar.secondarySymbols && grammar.secondarySymbols.length > 0) {
				secondaryGrammarList = grammar.secondarySymbols.filter((secondary) => secondary.filePath);
			}
			dispatch({ primaryGrammarList, secondaryGrammarList });
		}
	}, [grammar]);

	useEffect(() => {
		const { stage } = lessonDetails;
		if (stage === 4 || stage === 5) {
			document.addEventListener("keydown", onKeyDown);
		}
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessonDetails, disableNext]);

	const onKeyDown = (e) => {
		const keyCode = e.keyCode;
		const { stage } = lessonDetails;
		if (stage === 4) {
			if (keyCode === 37) {
				//left
				updateAcitivity("previous");
			} else if (keyCode === 39) {
				//right
				updateAcitivity(skipActivity ? "skip" : "next");
			} else if (keyCode === 76) {
				// play audio
				setSpeedHandlerFunction();
			}
		} else if (stage === 5) {
			if (keyCode === 39 && !disableNext) {
				//right
				updateAcitivity(skipActivity ? "skip" : "next");
			} else if (keyCode === 76) {
				// play audio
				setSpeedHandlerFunction();
			}
		}
	};

	const renderSpekerImages = () => {
		const isAddressee = grammar.addressee && grammar.addressee.filePath ? grammar.addressee.filePath : null;
		return (
			<div className="d-flex justify-content-center">
				<div className="speakers d-flex">
					{grammar.speaker &&
						grammar.speaker.filePath &&
						renderImg(grammar.speaker.filePath, isAddressee ? "mr-2" : "")}
					{isAddressee && renderImg(isAddressee)}
				</div>
			</div>
		);
	};

	const renderGrammarImages = (list, containerClass, childClass) => (
		<div className={`${containerClass} p-0`}>
			<div className={`grammar-list h-100 ${childClass ? childClass : ""}`}>
				<div className={`d-flex symbol-array ${language === "Hebrew" ? "d-rtl" : ""}`}>
					{list.map((img, index) => (
						<OverlayTrigger
							key={index}
							trigger="click"
							placement="auto"
							rootClose={true}
							overlay={
								<Popover id="popover-positioned-left" className="info-popup">
									<Popover.Content>
										<div className="d-flex flex-column  pr-2 pl-2" style={{ width: 420 }}>
											<span style={{ fontSize: 15, textTransform: "capitalize" }}>{img.label}</span>
											<div className="d-flex justify-content-center mb-2">
												<Image src={img.filePath} style={{ height: 160 }} />
											</div>
										</div>
									</Popover.Content>
								</Popover>
							}>
							<div className="img-wrap pointer">
								<img src={img.filePath} alt="" />
							</div>
						</OverlayTrigger>
					))}
				</div>
			</div>
		</div>
	);

	const renderGrammarSymbols = () => (
		<div className={`row grammar-symbols d-flex ${language === "Hebrew" && "d-rtl"}`}>
			{primaryGrammarList.length > 0 && renderGrammarImages(primaryGrammarList, "col-md-6", "mr-4")}
			{secondaryGrammarList.length > 0 && renderGrammarImages(secondaryGrammarList, "col-md-6", "ml-4")}
		</div>
	);

	const renderSymbolIcon = (list) => (
		<div className="symbol-icon">
			<span className="round-background" onClick={() => dispatch({ showModal: "grammar", selectedGrammarList: list })}>
				<span className="icon icon-example"></span>
			</span>
			<label className="smbl-txt">{t("helpers.utils.learning.grammarsymbol")}</label>
		</div>
	);

	const handleVideoEnd = (primary) => {
		if (primary && initialAutoPlay && secondaryVideoRef && secondaryVideoRef.current) {
			dispatch({ initialAutoPlay: false });
			secondaryVideoRef.current.play();
		}
	};

	const AutoplaySecondaryVideo = () => {
		if (
			grammar &&
			grammar.primaryIllustration &&
			grammar.primaryIllustration.mediaType === 2 &&
			grammar.primaryIllustration.filePath
		) {
			return false;
		} else {
			return true;
		}
	};

	const renderGrammarVideo = (illustration, list, primary) => (
		<div className="relative single-item">
			{illustration.mediaType === 1 ? (
				<img className="d-block grammar-video" alt="" src={illustration.filePath}></img>
			) : (
				<>
					<video
						className="d-block grammar-video"
						src={illustration.filePath}
						onPlay={() => (!primary ? dispatch({ initialAutoPlay: false }) : null)}
						onEnded={() => handleVideoEnd(primary)}
						ref={primary ? null : secondaryVideoRef}
						controls
						controlsList="nodownload"
						autoPlay={primary ? true : AutoplaySecondaryVideo()}></video>
				</>
			)}
			{list && list.length > 0 && renderSymbolIcon(list)}
		</div>
	);

	const renderImg = (img, containerClass) => (
		<div className={`round-background ${containerClass ? containerClass : ""}`}>
			<img src={img} alt="" />
		</div>
	);

	const renderVideoDetails = () => (
		<div className={`d-flex justify-content-center video-dtls ${language === "Hebrew" && "flex-row-reverse"}`}>
			{grammar.primaryIllustration &&
				grammar.primaryIllustration.filePath &&
				renderGrammarVideo(grammar.primaryIllustration, primaryGrammarList, true)}
			{grammar.primaryIllustration &&
				grammar.primaryIllustration.filePath &&
				grammar.secondaryIllustration &&
				grammar.secondaryIllustration.filePath &&
				grammar.relationShip && <div className="inter-link">{renderImg(grammar.relationShip.filePath)}</div>}
			{grammar.secondaryIllustration &&
				grammar.secondaryIllustration.filePath &&
				renderGrammarVideo(grammar.secondaryIllustration, secondaryGrammarList, false)}
			{!(grammar.secondaryIllustration && grammar.secondaryIllustration.filePath) &&
				!(grammar.primaryIllustration && grammar.primaryIllustration.filePath) && (
					<div className="no-video">
						<p>{t("languagelearning.lessons.details.novideo")}</p>
					</div>
				)}
		</div>
	);

	const renderGlossaryButton = () => (
		<div className="d-flex justify-content-end">
			<a
				target="_blank"
				rel="noopener noreferrer"
				title="Grammar_Symbols_Glossary"
				href={grammarSymbolGlossary}
				className="btn btn-default glosary-btn">
				{t("helpers.utils.learning.grammarsymbolglossary")}
			</a>
		</div>
	);

	const grammarModalBody = () => (
		<React.Fragment>
			<div className="row grammar-symbols">{renderGrammarImages(selectedGrammarList, "col-md-12")}</div>
			{renderGlossaryButton()}
		</React.Fragment>
	);

	const videoModalBody = () => (
		<div>
			<video controls controlsList="nofullscreen nodownload" src={videoUrl}></video>
		</div>
	);

	const renderTopSection = () => (
		<div className="top-section d-flex mb-2">
			<label className={`title m-0 flex-fill ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
				{grammar.text}
			</label>
			{renderAudio(false, true)}
		</div>
	);

	const setSpeedHandlerFunction = () => {
		const audioSpeed = audioSpeedList.filter((val) => val.audioId === grammar.id);
		const speed = audioSpeed && audioSpeed[0] ? audioSpeed[0]["speed"] : 1;
		togglePlay(dispatch, speed);
	};

	const renderAudio = (alignTop, isAssociation) =>
		grammar &&
		grammar.defaultPronunciation &&
		grammar.defaultPronunciation.filePath && (
			<React.Fragment>
				<div className={`mr-2 d-flex justify-content-end h-100 ${alignTop ? "" : "align-items-center"} dropright `}>
					<button
						type="button"
						onClick={setSpeedHandlerFunction}
						className={`audio-dropdown position-relative lesson-audio dropdown-toggle btn btn-primary ${
							isAudioPlaying ? "is-playing" : ""
						}`}>
						<i className="icon-audio"></i>
					</button>
					<AudioSettingsList audioId={grammar.id} utilizer="wordDetails" />
				</div>
				<audio
					src={grammar.defaultPronunciation.filePath}
					id="pro-audio"
					className="d-none"
					onEnded={() => dispatch({ isAudioPlaying: false })}
					onLoadedData={() => {
						if (isAssociation) {
							setSpeedHandlerFunction();
						}
					}}
				/>
			</React.Fragment>
		);

	const renderGloss = (dtl, wordDetails, language) => {
		return (
			<OverlayTrigger
				trigger="click"
				placement="top"
				rootClose={true}
				key={dtl.title}
				overlay={
					<Popover id="popover-basic" className="lang-dtls-pop">
						<Popover.Content>
							<span
								dangerouslySetInnerHTML={{
									__html: wordDetails[dtl.field],
								}}></span>
						</Popover.Content>
					</Popover>
				}>
				<div className="d-flex align-items-center pointer">
					<span className="round-background-overlay">
						<i className={`icon-Group`}></i>
					</span>
				</div>
			</OverlayTrigger>
		);
	};

	const renderOptions = () => (
		<OverlayTrigger
			placement="bottom"
			trigger="click"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="popover-options">
					<Popover.Content>{renderGlossaryButton()}</Popover.Content>
				</Popover>
			}>
			<div className="more-4-icon-icon  pointer">
				<i className="icon-more-4"></i>
			</div>
		</OverlayTrigger>
	);
	return (
		<React.Fragment>
			<div className={`grammar-container flex-fill h-100 ${isAssociation ? "association-phase" : ""}`}>
				<SharedModal
					modalClass={showModal === "grammar" ? "grammar-symbol-modal" : "video-wrapper"}
					show={showModal}
					handleClose={() => dispatch({ showModal: false })}
					modalBody={showModal === "grammar" ? grammarModalBody() : videoModalBody()}
				/>
				{isAssociation && renderTopSection()}
				{renderVideoDetails()}
				{renderActionButtons(lessonDetails, disableNext, isAssociation, skipActivity, updateAcitivity)}
				{((grammar.speaker && grammar.speaker.filePath) || (grammar.addressee && grammar.addressee.filePath)) &&
					renderSpekerImages()}
				{(primaryGrammarList.length > 0 || secondaryGrammarList.length > 0) && renderGrammarSymbols()}
				{isAssociation && (
					<div className="d-flex w-100 justify-content-end mt-1 align-items-center">
						<label className="progress-label mb-0 mt-2">
							{t("helpers.utils.learning.progress", {
								currentlesson: lessonDetails.question,
								totallessons: lessonDetails.total,
							})}
						</label>
						<div className="mt-1 ml-2">
							{renderGloss(
								dtlsFields[0],
								lessonDetails.activity.grammar || lessonDetails.activity.word,
								language
							)}
						</div>
						<div className="mt-1 mr-2">{renderOptions()}</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	disableNext: state.studentLanguageLearning.disableNext,
	skipActivity: state.studentLanguageLearning.skipActivity,
	language: state.sideMenu.language,
	audioSpeedList: state.studentLanguageLearning.audioSpeedList,
});

export default connect(mapStateToProps)(Grammar);
