import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import InfoDescription from "../../../sharedComponents/infoDescription";
import PieChart from "../../../sharedComponents/PieChart";
import LineChart from "../../../sharedComponents/LineChart";
import DateFilter from "../../../sharedComponents/DateFilter";
import FilterDropDown from "../../../sharedComponents/FilterDropdown";

import { getOverView, getChart } from "../action";
import { dateValues, filterValues, getPath } from "../helper";
import { overviewUrls, chartUrls, collectiveOverviewUrls, collectiveChartUrls } from "../types";
import { useTranslation } from "react-i18next";
import { getUserDetails } from "../../../../utils/verifyUser";

const Activity = ({ getOverView, getChart, activity, dispatch, match, currentTab, studentId, collectiveFilter }) => {
	const [overviewFilter, setOverviewFilter] = useState(dateValues[0]);
	const [daysSpentFilter, setDaysSpentFilter] = useState(dateValues[0]);
	const [daysSpentByFilter, setDaysSpentByFilter] = useState(filterValues[0]);
	const [timeSpentDate, setTimeSpentDate] = useState(dateValues[0]);
	const [pointsDate, setPointsDate] = useState(dateValues[0]);
	const [sessionDate, setSessionDate] = useState(dateValues[0]);
	const [pointsFilter, setPointsFilter] = useState(filterValues[0]);
	const [timeSpentFilter, setTimeSpentFilter] = useState(filterValues[0]);
	const [sessionFilter, setSessionFilter] = useState(filterValues[0]);
	const [overviewLoading, setOverviewLoading] = useState(true);
	const [daySpentLoading, setDaySpentLoading] = useState(true);
	const [timSpentLoading, setTimSpentLoading] = useState(true);
	const [pointsLoading, setPointsLoading] = useState(true);
	const [sessionLoading, setSessionLoading] = useState(true);
	const { t } = useTranslation();
	const { timeSpent, days, points, sessions, learningChart, sessionChart, pointChart, daysSpentLearningChart, mounted } =
		activity;
	const userDetails = getUserDetails();
	if (["CampusEdu", "Fuller"].includes(userDetails.origin)) delete filterValues[3];
	const {
		params: { language },
	} = match;

	const { timeSpentLearning, daysSpentLearning, numberOfSessions, pointsAccumulated } =
		studentId === "Collective" ? collectiveOverviewUrls : overviewUrls;

	const {
		learningTime,
		sessionsChartUrl,
		pointsCharts,
		daysSpentLearningCharts,
		daysSpentFlashCardsCharts,
		daysSpentBibleReadingCharts,
	} = studentId === "Collective" ? collectiveChartUrls : chartUrls;

	const getData = async () => {
		const overView = await onFilterChangeOverview(dateValues[0].value);
		const learningChart = await onFilterChangeTimeSpentChart(dateValues[0].value, filterValues[0].value);
		const sessionChart = await onFilterChangeSessionChart(dateValues[0].value, filterValues[0].value);
		const pointChart = await onFilterChangePointChart(dateValues[0].value, filterValues[0].value);
		const daysSpentLearningChart = await onFilterChangeDaysSpentLearningChart(dateValues[0].value, filterValues[0].value);
		dispatch({
			activity: {
				...activity,
				...overView,
				learningChart,
				sessionChart,
				pointChart,
				daysSpentLearningChart,
				mounted: true,
			},
		});
		setTimSpentLoading(false);
		setSessionLoading(false);
		setPointsLoading(false);
		setDaySpentLoading(false);
		setOverviewLoading(false);
	};

	const onFilterChangeTimeSpentChart = async (dateValue, filterValue) => {
		const { response: learningChart } = await getChart(
			getPath(learningTime, language, filterValue, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return learningChart;
	};
	const onFilterChangeSessionChart = async (dateValue, filterValue) => {
		const { response: sessionChart } = await getChart(
			getPath(
				sessionsChartUrl,
				language,
				filterValue,
				dateValue,
				null,
				null,
				null,
				studentId,
				null,
				null,
				collectiveFilter
			)
		);
		return sessionChart;
	};
	const onFilterChangePointChart = async (dateValue, filterValue) => {
		const { response: pointChart } = await getChart(
			getPath(pointsCharts, language, filterValue, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return pointChart;
	};
	const onFilterChangeDaysSpentLearningChart = async (dateValue, filterValue) => {
		let url = daysSpentLearningCharts;
		if (filterValue === "flashcard") {
			url = daysSpentFlashCardsCharts;
		} else if (filterValue === "biblereading") {
			url = daysSpentBibleReadingCharts;
		}
		const { response: daysSpentLearningChart } = await getChart(
			getPath(url, language, filterValue, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return daysSpentLearningChart;
	};

	const onFilterChangeOverview = async (dateValue) => {
		const { response: timeSpent } = await getOverView(
			getPath(timeSpentLearning, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		const { response: days } = await getOverView(
			getPath(daysSpentLearning, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		const { response: sessions } = await getOverView(
			getPath(numberOfSessions, null, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		const { response: points } = await getOverView(
			getPath(pointsAccumulated, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return {
			timeSpent,
			days,
			points,
			sessions,
		};
	};
	useEffect(() => {
		if (currentTab === t("dashboard.activity.name") && !mounted) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	const handleOnChangeOverViewFilterChange = async (value) => {
		setOverviewLoading(true);
		setOverviewFilter(dateValues[value]);
		const overView = await onFilterChangeOverview(dateValues[value].value);
		dispatch({
			activity: {
				...activity,
				...overView,
			},
		});
		setOverviewLoading(false);
	};

	const handleOnChangeDaysSpentDateChange = async (value, filter) => {
		setDaySpentLoading(true);
		let dateFilter = daysSpentFilter.value;
		let filterValue = daysSpentByFilter.value;
		if (filter === "date") {
			setDaysSpentFilter(dateValues[value]);
			dateFilter = dateValues[value].value;
		} else {
			setDaysSpentByFilter(filterValues[value]);
			filterValue = filterValues[value].value;
		}
		const daysSpentLearningChart = await onFilterChangeDaysSpentLearningChart(dateFilter, filterValue);
		dispatch({
			activity: {
				...activity,
				daysSpentLearningChart,
			},
		});
		setDaySpentLoading(false);
	};
	const handleOnChangeTimeSpendFilterChange = async (value, filter) => {
		setTimSpentLoading(true);
		let dateFilter = timeSpentDate.value;
		let filterValue = timeSpentFilter.value;
		if (filter === "date") {
			setTimeSpentDate(dateValues[value]);
			dateFilter = dateValues[value].value;
		} else {
			setTimeSpentFilter(filterValues[value]);
			filterValue = filterValues[value].value;
		}
		const learningChart = await onFilterChangeTimeSpentChart(dateFilter, filterValue);
		dispatch({
			activity: {
				...activity,
				learningChart,
			},
		});
		setTimSpentLoading(false);
	};
	const handleOnChangePointsFilterChange = async (value, filter) => {
		setPointsLoading(true);
		let dateFilter = pointsDate.value;
		let filterValue = pointsFilter.value;
		if (filter === "date") {
			setPointsDate(dateValues[value]);
			dateFilter = dateValues[value].value;
		} else {
			setPointsFilter(filterValues[value]);
			filterValue = filterValues[value].value;
		}
		const pointChart = await onFilterChangePointChart(dateFilter, filterValue);
		dispatch({
			activity: {
				...activity,
				pointChart,
			},
		});
		setPointsLoading(false);
	};
	const handleOnChangeSessionFilterChange = async (value, filter) => {
		setSessionLoading(true);
		let dateFilter = sessionDate.value;
		let filterValue = sessionFilter.value;
		if (filter === "date") {
			setSessionDate(dateValues[value]);
			dateFilter = dateValues[value].value;
		} else {
			setSessionFilter(filterValues[value]);
			filterValue = filterValues[value].value;
		}
		const sessionChart = await onFilterChangeSessionChart(dateFilter, filterValue);
		dispatch({
			activity: {
				...activity,
				sessionChart,
			},
		});
		setSessionLoading(false);
	};

	return (
		<Container fluid className="activity-container">
			<Row>
				<Col className="card-col mt-3">
					{!overviewLoading ? (
						<Card className="overView  no-shadow " body>
							<div className="d-flex justify-content-between align-items-center">
								<span className="title-heading mb-2">{t("dashboard.overview")}</span>
								<DateFilter
									dataset={dateValues}
									placeholder="Select Date"
									onSelectCB={handleOnChangeOverViewFilterChange}
									activeFilter={overviewFilter}
								/>
							</div>
							{timeSpent && days && sessions && points ? (
								<div className="d-flex justify-content-between align-items-center">
									<Card body className="overview-cards equalize">
										<div className="p-2 icon-box clock">
											<i className="icon-clock-5"></i>
										</div>
										<div className="mt-4" style={{ width: "140px", textAlign: "center" }}>
											<span className="number-text">
												{timeSpent && overviewFilter.value === t("dashboard.datefilter.lifetime")
													? Math.floor(timeSpent.totalTimeTaken.totalHours)
													: Math.floor(timeSpent.totalTimeTaken.totalMinutes)}
											</span>
											<span className="small-text">
												{overviewFilter.value === t("dashboard.datefilter.lifetime")
													? " " + t("dashboard.activity.overview.hour")
													: " " + t("dashboard.activity.overview.min")}
											</span>
										</div>
										<div className="m-2 text-center center-text">
											<span>{t("dashboard.activity.timespentlearning.name")}</span>
										</div>
										<div className="m-2">
											{timeSpent && (
												<span className={`percentage ${timeSpent.isIncrease ? "inc" : "dec"}`}>
													{timeSpent.percentageChange !== 0
														? `${timeSpent.isIncrease ? "+" : "-"} ${Math.abs(
																timeSpent.percentageChange
														  )} %`
														: ""}
												</span>
											)}
										</div>
									</Card>
									<Card body className="overview-cards equalize">
										<div className="p-2 icon-box calendar">
											<i className="icon-calendar-3"></i>
										</div>
										<div className="mt-4">
											<span className="number-text">{days.numberOfDaysSpent}</span>
										</div>
										<div className="m-2 text-center center-text">
											<span>{t("dashboard.activity.overview.daysspentlearning")}</span>
										</div>
										<div className="m-2">
											{days && (
												<span className={`percentage ${days.isIncrease ? "inc" : "dec"}`}>
													{days.percentageChange !== 0
														? `${days.isIncrease ? "+" : "-"} ${Math.abs(days.percentageChange)} %`
														: ""}
												</span>
											)}
										</div>
									</Card>
									<Card body className="overview-cards equalize">
										<div className="p-2 icon-box bookmark">
											<i className="icon-book-2"></i>
										</div>
										<div className="mt-4">
											<span className="number-text">{sessions.numberOfSessions}</span>
										</div>
										<div className="m-2 text-center center-text">
											<span>{t("dashboard.activity.overview.numberoflogins")}</span>
										</div>
										<div className="m-2">
											{sessions && (
												<span className={`percentage ${sessions.isIncrease ? "inc" : "dec"}`}>
													{sessions.percentageChange !== 0
														? `${sessions.isIncrease ? "+" : "-"} ${Math.abs(
																sessions.percentageChange
														  )} %`
														: ""}
												</span>
											)}
										</div>
									</Card>
									<Card body className="overview-cards equalize">
										<div className="p-2 icon-box star">
											<i className="icon-star-4"></i>
										</div>
										<div className="mt-4">
											<span className="number-text">{points.pointsAccumulated}</span>
										</div>
										<div className="m-2 text-center center-text">
											<span>{t("dashboard.activity.overview.pointsaccumulated")}</span>
										</div>
										<div className="m-2 ">
											{points && (
												<span className={`percentage ${points.isIncrease ? "inc" : "dec"}`}>
													{points.percentageChange !== 0
														? `${points.isIncrease ? "+" : "-"} ${Math.abs(
																points.percentageChange
														  )} %`
														: ""}
												</span>
											)}
										</div>
									</Card>
								</div>
							) : (
								<div className="text-center" style={{ margin: "254px 0px" }}></div>
							)}
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={330} />
						</SkeletonTheme>
					)}
				</Col>
				<Col className="card-col mt-3">
					{!daySpentLoading ? (
						<Card body className="days-spent  no-shadow">
							<Row>
								<div className="d-flex justify-content-between align-items-center">
									<Col>
										<div className="row title-heading mb-2 pr-2 pl-2">
											{t("dashboard.activity.daysspentlearning.name")}
											<InfoDescription
												position={"right-bottom"}
												description={t("dashboard.activity.daysspentlearning.info")}
											/>
										</div>
									</Col>
									<Col>
										<div className="d-flex">
											<FilterDropDown
												dataset={filterValues}
												onSelectCB={(value) => handleOnChangeDaysSpentDateChange(value, "filer")}
												activeFilter={daysSpentByFilter}
											/>
											<DateFilter
												dataset={dateValues}
												placeholder="Select Date"
												onSelectCB={(value) => handleOnChangeDaysSpentDateChange(value, "date")}
												activeFilter={daysSpentFilter}
											/>
										</div>
									</Col>
								</div>
							</Row>
							<div className="d-flex">
								<PieChart
									data={daysSpentLearningChart.filter(
										(el) => el.name === "Offline Days" || el.name === "Online Days"
									)}
								/>
								<div className="d-flex justify-content-center align-items-center flex-column m-auto">
									{daysSpentLearningChart && (
										<>
											<span className="days-spent-number">
												{daysSpentLearningChart.filter((el) => el.name === "Total Days")[0].value}
											</span>
											<span className="days-spent-text mt-3">
												{t("dashboard.activity.daysspentlearning.totaldays")}{" "}
											</span>
											<span
												className={`mt-3 days-spent-percentage`}
												style={
													daysSpentLearningChart.filter(
														(el) => el.name === "Total Days Percentage Change"
													)[0].value >= 0
														? { color: "#00c158" }
														: { color: "#fc162b" }
												}>
												{daysSpentLearningChart.filter(
													(el) => el.name === "Total Days Percentage Change"
												)[0].value > 0 && "+ "}
												{daysSpentLearningChart.filter(
													(el) => el.name === "Total Days Percentage Change"
												)[0].value < 0 && "- "}
												{Math.abs(
													daysSpentLearningChart.filter(
														(el) => el.name === "Total Days Percentage Change"
													)[0].value
												)}
												%
											</span>
										</>
									)}
								</div>
							</div>
							<div className="d-flex justify-content-between">
								<div className="d-flex  align-items-center">
									<span className="blue-dot"></span>
									<span className="legend-text">
										{t("dashboard.activity.daysspentlearning.legendonline", {
											onlinedays: daysSpentLearningChart.filter((el) => el.name === "Online Days")[0]
												.value,
											onlinepercentage: daysSpentLearningChart.filter(
												(el) => el.name === "Online Percentage"
											)[0].value,
										})}
									</span>
								</div>

								<div className="d-flex d-flex  align-items-center">
									<span className="green-dot"></span>
									<span className="legend-text">
										{t("dashboard.activity.daysspentlearning.legendoffline", {
											offlinedays: daysSpentLearningChart.filter((el) => el.name === "Offline Days")[0]
												.value,
											offlinepercentage: daysSpentLearningChart.filter(
												(el) => el.name === "Offline Percentage"
											)[0].value,
										})}
									</span>
								</div>
							</div>
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={330} />
						</SkeletonTheme>
					)}
				</Col>
			</Row>
			<Row>
				<Col className="card-col mt-3 " xs={12} lg={6}>
					{!timSpentLoading ? (
						<Card body className="no-shadow">
							<div className="d-flex justify-content-between mb-3 align-items-center">
								<div className="title-heading">
									{t("dashboard.activity.timespentlearning.name")}
									<InfoDescription
										position={"right-bottom"}
										description={t("dashboard.activity.timespentlearning.info")}
									/>
								</div>
								<div className="d-flex">
									<FilterDropDown
										dataset={filterValues}
										onSelectCB={(value) => handleOnChangeTimeSpendFilterChange(value, "filer")}
										activeFilter={timeSpentFilter}
									/>
									<DateFilter
										dataset={dateValues}
										placeholder="Select Date"
										onSelectCB={(value) => handleOnChangeTimeSpendFilterChange(value, "date")}
										activeFilter={timeSpentDate}
									/>
								</div>
							</div>
							{learningChart.length > 0 ? (
								<LineChart
									data={learningChart}
									stroke="#4a9efe"
									unit={
										timeSpentDate.value === "LifeTime" ||
										timeSpentDate.value === "ThisPastSixMonths" ||
										timeSpentDate.value === "ThisPastYear"
											? "hr"
											: "Min"
									}
								/>
							) : (
								<div className="text-center" style={{ margin: "147px 0px" }}>
									{t("helpers.utils.nodata")}
								</div>
							)}
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={390} />
						</SkeletonTheme>
					)}
				</Col>
				<Col className="card-col mt-3 " xs={12} lg={6}>
					{!pointsLoading ? (
						<Card body className="no-shadow">
							<div className="d-flex justify-content-between mb-3 align-items-center">
								<div className="title-heading">
									{t("dashboard.activity.pointsaccumulated.name")}
									<InfoDescription
										position={"right-bottom"}
										description={t("dashboard.activity.pointsaccumulated.info")}
									/>
								</div>
								<div className="d-flex">
									<FilterDropDown
										dataset={filterValues}
										onSelectCB={(value) => handleOnChangePointsFilterChange(value, "filter")}
										activeFilter={pointsFilter}
									/>
									<DateFilter
										dataset={dateValues}
										placeholder="Select Date"
										onSelectCB={(value) => handleOnChangePointsFilterChange(value, "date")}
										activeFilter={pointsDate}
									/>
								</div>
							</div>
							{pointChart.length > 0 || false ? (
								<LineChart data={pointChart} stroke="#f983cb" unit="points" />
							) : (
								<div className="text-center" style={{ margin: "147px 0px" }}>
									{t("helpers.utils.nodata")}
								</div>
							)}
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={390} />
						</SkeletonTheme>
					)}
				</Col>
			</Row>
			<Row>
				<Col className="card-col mt-3 mb-3">
					{!sessionLoading ? (
						<Card body className="no-shadow">
							<div className="d-flex justify-content-between mb-3 align-items-center">
								<div className="title-heading">
									{t("dashboard.activity.numberofsessions.name")}
									<InfoDescription
										position={"right-bottom"}
										description={t("dashboard.activity.numberofsessions.info")}
									/>
								</div>
								<div className="d-flex">
									<FilterDropDown
										dataset={filterValues}
										onSelectCB={(value) => handleOnChangeSessionFilterChange(value, "filter")}
										activeFilter={sessionFilter}
									/>
									<DateFilter
										dataset={dateValues}
										placeholder="Select Date"
										onSelectCB={(value) => handleOnChangeSessionFilterChange(value, "date")}
										activeFilter={sessionDate}
									/>
								</div>
							</div>
							{sessionChart.length > 0 ? (
								<LineChart data={sessionChart} stroke="#ab62d8" />
							) : (
								<div className="text-center" style={{ margin: "147px 0px" }}>
									{t("helpers.utils.nodata")}
								</div>
							)}
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={390} />
						</SkeletonTheme>
					)}
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	getOverView,
	getChart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));
