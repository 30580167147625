import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const FilterComponent = ({ dataset, onSelectCB, activeFilter }) => {
	const onFilterSelect = (e) => {
		onSelectCB(e);
	};

	const { t } = useTranslation();

	return (
		<Dropdown onSelect={onFilterSelect}>
			<Dropdown.Toggle className="icon-filter mr-2" id="filter-dropdown" />
			<Dropdown.Menu>
				{dataset &&
					Object.keys(dataset).length &&
					Object.keys(dataset).map((data, index) => {
						return (
							<Dropdown.Item key={index} active={dataset[data].value === activeFilter.value} eventKey={data}>
								{t(`dashboard.filtervalues.${dataset[data].localizationValue}`)}
							</Dropdown.Item>
						);
					})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default FilterComponent;
