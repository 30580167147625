import React, { useState, useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserDetails } from "../../utils/verifyUser";
import Loader from "../sharedComponents/Loader";
import SharedModal from "../sharedComponents/Modal";
import {
	changeType,
	getAllGrammar,
	getAllLessons,
	getAllWords,
	getGrammarDetails,
	setIsSearchDisable,
	setLessonDetails,
	setLessonNumber,
	setSearchText,
	setWordDetails,
	updateField,
	uploadDictionaryFile,
	uploadLanguageFile,
} from "./actions";
import ListDictionaryLessons from "./ListDictionaryLessons";
import "./submenu.scss";
import UploadFailModal from "./uploadFailModal";
import { useTranslation } from "react-i18next";

const Submenu = ({
	uploadDictionaryFile,
	uploadLanguageFile,
	changeType,
	fileName,
	menu,
	history,
	type,
	setWordDetails,
	getAllWords,
	getGrammarDetails,
	isDataFetching,
	getAllGrammar,
	setSearchText,
	searchText,
	isSearchDisable,
	language,
	wordDetails,
	grammarDetails,
	lessonDetails,
	fileLoader,
	match,
}) => {
	const [show, showModal] = useState(false);
	const [message, setMessage] = useState("");
	const [loader, setLoader] = useState(false);
	const [entryFailedModal, showEntryFailedModal] = useState(false);
	const [failedEntries, setFaiedEntries] = useState([]);
	const [activeTab, setActiveTab] = useState(null);
	const [searchTimeout, setSearchTimeout] = useState(null);
	const { t } = useTranslation();

	const userRole = getUserDetails() && getUserDetails().role && getUserDetails().role.description;
	const searchInput = useRef(null);
	if (!isSearchDisable && searchInput && searchInput.current) searchInput.current.focus();

	const handleChange = (e) => {
		setLoader(true);
		const location = match.path.split("/");
		const formData = new FormData();
		formData.append("uploadedFile", e.target.files[0]);
		if (location[3] === "vocabulary") {
			uploadDictionaryFile(formData, language).then((res) => {
				if (res && res.hasError) {
					setMessage(
						res.statusCode === 400
							? res.response.validationErrors &&
									res.response.validationErrors[0] &&
									res.response.validationErrors[0].value
							: res.response && res.response.message
					);

					showModal(true);
				} else {
					getAllWords(`language=${match.params.language}&IsPagingEnabled=false`);
					setWordDetails(`Language=${match.params.language}`);
					checkResponse(res);
				}
				setLoader(false);
			});
		} else {
			uploadLanguageFile(formData, language).then((res) => {
				if (res && res.hasError) {
					setMessage(
						res.statusCode === 400
							? res.response.validationErrors &&
									res.response.validationErrors[0] &&
									res.response.validationErrors[0].value
							: res.response && res.response.message
					);
					showModal(true);
				} else {
					getAllGrammar(`language=${match.params.language}`);
					getGrammarDetails(`Language=${match.params.language}&Lesson=1`);
					checkResponse(res);
				}
				setLoader(false);
			});
		}
	};

	const checkResponse = (res) => {
		if (res.response.failedEntries.length > 0) {
			showEntryFailedModal(true);
			localStorage.setItem("failedEntries", JSON.stringify(res.response.failedEntries));
			localStorage.setItem("menu", JSON.stringify(menu));
			localStorage.setItem("history", JSON.stringify(history));
			setFaiedEntries(res.response.failedEntries);
		} else {
			localStorage.removeItem("failedEntries");
			localStorage.removeItem("menu");
			localStorage.removeItem("history");
			NotificationManager.success(t("helpers.utils.uploadsuccesful"), "", 3000);
		}
	};

	const onClose = () => {
		showModal(false);
	};

	const handleUpload = (e) => {
		document.getElementById("file-upload").click();
		showModal(false);
	};

	const handleSelect = (item) => {
		setActiveTab((prevActiveTab) => (activeTab === null || activeTab !== item ? item : null));
		setWordDetails(`Language=${language}`);
		changeType(item);
	};

	const handleClose = () => {
		showEntryFailedModal(false);
		setFaiedEntries([]);
	};

	const renderModalBody = () => {
		return (
			<div className="text-center entry-failed-modal">
				<span className="icon-success">
					{[...Array(3)].map((x, i) => (
						<span key={i} className={`icon-success path${i + 1}`}></span>
					))}
				</span>
				<h4 className="entry-modal-head">{t("helpers.gallery.uploadsuccess")}</h4>
				<div className="error text-left modal-background">
					<div className="mb-3">{t("helpers.utils.notification.entriesfailed")}</div>
					{failedEntries &&
						failedEntries.map(
							(entry, index) =>
								index < 2 && (
									<p key={index} className="mb-1">
										{index + 1}. {entry}
									</p>
								)
						)}
					<div className="text-center">
						<a href="/entry-failed" target="blank">
							{t("helpers.utils.viewall")}
						</a>
					</div>
				</div>
			</div>
		);
	};

	const getClassName = (tab) => {
		if (!fileName) {
			return "d-none";
		} else if (tab === type && activeTab === null) {
			return "d-none d-md-block d-lg-block";
		} else if (activeTab === null) {
			return "d-none";
		} else {
			return "";
		}
	};

	const handleSubMenu = (type) => {
		setActiveTab(null);
	};

	const handleSearchChange = (e) => {
		const value = e.target.value;
		if (value && value.length > 0) {
			setSearchText(value);
		} else {
			setSearchText("");
		}
	};

	return (
		<div className="lessons">
			<div
				className={`justify-content-between align-items-center search-container ${
					activeTab === null ? "d-none d-md-flex d-lg-flex" : "d-flex"
				}`}>
				<input
					ref={searchInput}
					type="text"
					className="search-input"
					placeholder={t("helpers.utils.searchhere")}
					onChange={handleSearchChange}
					value={searchText}
					disabled={isSearchDisable}
				/>
				<i className="icon icon-magnifying-glass-2"></i>
			</div>

			{!isDataFetching && (
				<label htmlFor="file-upload" className="btn btn-primary upload d-flex justify-content-between">
					<span>{fileName ? fileName : "Upload Xml File"}</span>
					<i className="icon icon-upload"></i>
				</label>
			)}
			<input
				type="file"
				placeholder="Upload Xml File"
				className="btn btn-primary upload"
				onChange={handleChange}
				id="file-upload"
				disabled={userRole !== "Administrator"}
			/>
			<Tabs onSelect={handleSelect} className={!fileName ? "d-none" : "dictionary-tab"} activeKey={type}>
				{menu[0] && menu[0].title !== "Language Learning" && (
					<Tab eventKey="dictionary" title={t("helpers.utils.dictionary")} className={getClassName("dictionary")}>
						<Scrollbars>
							<ListDictionaryLessons handleSubMenu={handleSubMenu} history={history} />
						</Scrollbars>
					</Tab>
				)}
				<Tab eventKey="lessons" title={t("menu.lessons")} className={getClassName("lessons")}>
					<Scrollbars>
						<ListDictionaryLessons handleSubMenu={handleSubMenu} history={history} />
					</Scrollbars>
				</Tab>
			</Tabs>
			{loader && <Loader />}
			{(menu[0] && menu[0].title === "Vocabulary"
				? wordDetails && wordDetails.length === 0 && lessonDetails && lessonDetails.length === 0
				: grammarDetails && grammarDetails.length === 0) &&
				fileLoader && <Loader />}

			<UploadFailModal show={show} message={message} onClose={onClose} handleUpload={handleUpload} />

			<SharedModal
				show={entryFailedModal}
				handleClose={handleClose}
				modalBody={renderModalBody()}
				modalClass="entryfailed"
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	fileName: state.subMenu.fileName,
	menu: state.sideMenu.menu,
	isDataFetching: state.subMenu.isDataFetching,
	type: state.subMenu.type,
	searchText: state.subMenu.searchText,
	isSearchDisable: state.subMenu.isSearchDisable,
	language: state.sideMenu.language,
	wordDetails: state.subMenu.wordDetails,
	lessonDetails: state.subMenu.lessonDetails,
	grammarDetails: state.subMenu.grammarDetails,
	fileLoader: state.subMenu.fileLoader,
	lessonNumber: state.subMenu.lessonNumber,
});

const mapDispatchToProps = {
	uploadDictionaryFile,
	uploadLanguageFile,
	changeType,
	setWordDetails,
	getAllWords,
	getGrammarDetails,
	getAllLessons,
	getAllGrammar,
	setSearchText,
	setIsSearchDisable,
	updateField,
	setLessonNumber,
	setLessonDetails,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Submenu));
