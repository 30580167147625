import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactTooltip from "react-tooltip";
import { NotificationManager } from "react-notifications";

import { IsValidFileName } from "../../utils/formValidators";
import { getUserDetails } from "../../utils/verifyUser";
import { useTranslation } from "react-i18next";

const VideoCard = ({
	isEdit,
	setIsEdit,
	cardData,
	imageName,
	setImageName,
	isEditing,
	setIsEditing,
	setDeleteItem,
	handleImageRename,
}) => {
	const filePath = cardData.filePath;
	const fileKey = cardData.fileKey;
	const fileName = cardData.fileName;
	const { t } = useTranslation();

	const userRole = getUserDetails() && getUserDetails().role && getUserDetails().role.description;

	const toggleIsEdit = (value) => {
		setIsEdit(value);
		setIsEditing(value);
	};
	const handleSave = (fileKey, imageName) => {
		setIsEdit(false);
		handleImageRename(fileKey, imageName, filePath);
	};

	const handleChange = (e) =>
		!IsValidFileName(e.target.value)
			? setImageName(e.target.value)
			: NotificationManager.error(t("helpers.utils.invalidfilenameformat"), "", 2000);

	return (
		<React.Fragment>
			{isEdit ? (
				<div className="d-flex ">
					<input value={imageName} className="flex-grow-1" onChange={(e) => handleChange(e)} />
					<span className="" onClick={() => handleSave(fileKey, imageName)}>
						<span className="icon-success">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
						</span>
					</span>
				</div>
			) : (
				<div>
					<p className="card-text" data-tip={fileName}>
						{fileName}
					</p>
					<ReactTooltip />
					{!isEditing && userRole === "Administrator" && (
						<Dropdown>
							<Dropdown.Toggle variant="success" id="dropdown-basic">
								<i className="icon icon-more" />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item onClick={() => toggleIsEdit(true)}>
									<span>
										<i className="icon icon-write mr-2" />
										{t("helpers.utils.rename")}
									</span>
								</Dropdown.Item>
								<Dropdown.Item onClick={() => setDeleteItem(filePath)}>
									<span>
										<i className="icon icon-delete mr-2" />
										{t("helpers.utils.delete")}
									</span>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export default VideoCard;
