export const FLASH_CARD_DECK = {
	GET_DECK_DETAILS: "GET_DECK_DETAILS",
	GET_DECK_LIST: "GET_DECK_LIST",
	UPDATE_DECK_FIELD: "UPDATE_DECK_FIELD",
	ARCHIVE_WORD: "ARCHIVE_WORD",
	GET_DECK_GROUPS: "ARCHIVE_WORD",
	RESET: "FLASH_CARD_REST",
	GET_SETTINGS: "GET_SETTINGS",
	GET_BOOK_LIST: "GET_BOOK_LIST",
	GET_CHAPTER_LIST: "GET_CHAPTER_LIST",
	SEARCH_WORD: "SEARCH_WORD",
	CHANGE_SETTINGS: "CHANGE_SETTINGS",
	GENERATE_DECK: "GENERATE_DECK",
	MOVE_DECK: "MOVE_DECK",
	SHARE_DECK: "SHARE_DECK",
	DELETE_DECK: "DELETE_DECK",
	DELETE_GROUP: "DELETE_GROUP",
	DELETE_WORD: "DELETE_WORD",
	GET_GROUPS: "GET_GROUPS",
	GET_DECKS: "GET_DECKS",
	GET_WORDS: "GET_WORDS",
	GET_SEMANTIC_DOMAIN_ENTRIES: "GET_SEMANTIC_DOMAIN_ENTRIES",
	ADD_WORDS: "ADD_WORDS",
	ADD_DECKS: "ADD_DECKS",
	GET_SEARCH_RESULTS: "GET_SEARCH_RESULTS",
	UPDATE_WORD: "UPDATE_WORD",
	CREATE_CUSTOM_DECK: "CREATE_CUSTOM_DECK"
};
