import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { getOverView, getChart, getReports } from "../action";
import { dateValues, getPath } from "../helper";
import Helper from "../helper";
import { overviewUrls, chartUrls, reportsUrls, collectiveOverviewUrls, collectiveChartUrls } from "../types";
import PieChart from "../../../sharedComponents/PieChart";
import LineChart from "../../../sharedComponents/LineChart";
import ReportTableComponent from "../ReportTable";
import AudioLibrary from "../../../../assets/images/audio-library.png";
import DateFilter from "../../../sharedComponents/DateFilter";
import InfoDescription from "../../../sharedComponents/infoDescription";
import { useTranslation } from "react-i18next";

const Grammar = ({ grammar, dispatch, match, getOverView, getChart, getReports, currentTab, studentId, collectiveFilter }) => {
	const [overviewFilter, setOverviewFilter] = useState(dateValues[0]);
	const [proficiencyFilter, setProficiencyFilter] = useState(dateValues[0]);
	const [topicsFilter, setTopicsFilter] = useState(dateValues[0]);
	const [sentenceFilter, setSentenceFilter] = useState(dateValues[0]);
	const [overviewLoading, setOverviewLoading] = useState(true);
	const [proficiencyLoading, setProficiencyLoading] = useState(true);
	const [topicsLoading, setTopicsLoading] = useState(true);
	const [sentenceLoading, setSentenceLoading] = useState(true);
	const [reportLoading, setReportLoading] = useState(true);
	const {
		topicsOverview,
		sentenceOverview,
		ProficiencyChart,
		sentenceLearnedChart,
		topicsChart,
		fluencyDrillReport,
		topicsLearnedReport,
		mounted,
	} = grammar;
	const {
		params: { language },
	} = match;
	const { topics, sentence } = studentId === "Collective" ? collectiveOverviewUrls : overviewUrls;
	const { topicsCharts, sentenceCharts, proficiencyCharts } =
		studentId && studentId === "Collective" ? collectiveChartUrls : chartUrls;
	const { fluencyDrillReports, topicsLearnedReports } = reportsUrls;

	const getData = async () => {
		const overView = await onFilterChangeOverview(dateValues[0].value);
		const topicsChart = await onFilterChangeTopicChart(dateValues[0].value);
		const sentenceLearnedChart = await onFilterChangeSentenceChart(dateValues[0].value);
		const ProficiencyChart = await onFilterChangeProficiency(dateValues[0].value);
		const { response: fluencyDrillReport } = await getReports(
			getPath(
				fluencyDrillReports,
				language,
				null,
				dateValues[0].value,
				true,
				0,
				4,
				studentId,
				null,
				null,
				collectiveFilter
			)
		);
		const { response: topicsLearnedReport } = await getReports(
			getPath(
				topicsLearnedReports,
				language,
				null,
				dateValues[0].value,
				true,
				0,
				4,
				studentId,
				null,
				null,
				collectiveFilter
			)
		);

		dispatch({
			grammar: {
				...grammar,
				...overView,
				topicsChart,
				sentenceLearnedChart,
				fluencyDrillReport,
				topicsLearnedReport,
				ProficiencyChart,
				mounted: true,
			},
		});
		setOverviewLoading(false);
		setProficiencyLoading(false);
		setTopicsLoading(false);
		setSentenceLoading(false);
		setReportLoading(false);
	};

	const onFilterChangeOverview = async (dateValue) => {
		const { response: topicsOverview } = await getOverView(
			getPath(topics, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		const { response: sentenceOverview } = await getOverView(
			getPath(sentence, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return {
			topicsOverview,
			sentenceOverview,
		};
	};

	const onFilterChangeProficiency = async (dateValue) => {
		const { response: ProficiencyChart } = await getChart(
			getPath(proficiencyCharts, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return ProficiencyChart;
	};
	const onFilterChangeTopicChart = async (dateValue) => {
		const { response: topicsChart } = await getChart(
			getPath(topicsCharts, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return topicsChart;
	};
	const onFilterChangeSentenceChart = async (dateValue) => {
		const { response: sentenceLearnedChart } = await getChart(
			getPath(sentenceCharts, language, null, dateValue, null, null, null, studentId, null, null, collectiveFilter)
		);
		return sentenceLearnedChart;
	};

	useEffect(() => {
		if (currentTab === t("dashboard.grammar.name") && !mounted) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTab]);

	const handleOnChangeOverViewFilterChange = async (value) => {
		setOverviewLoading(true);
		setOverviewFilter(dateValues[value]);
		const overView = await onFilterChangeOverview(dateValues[value].value);
		dispatch({
			grammar: {
				...grammar,
				...overView,
			},
		});
		setOverviewLoading(false);
	};

	const handleOnChangeProficiencyDateChange = async (value) => {
		setProficiencyLoading(true);
		setProficiencyFilter(dateValues[value]);
		const ProficiencyChart = await onFilterChangeProficiency(dateValues[value].value);
		dispatch({
			grammar: {
				...grammar,
				ProficiencyChart,
			},
		});
		setProficiencyLoading(false);
		setSentenceLoading(false);
	};
	const handleOnChangeTopicsDateChange = async (value) => {
		setTopicsLoading(true);
		setTopicsFilter(dateValues[value]);
		const topicsChart = await onFilterChangeTopicChart(dateValues[value].value);
		dispatch({
			grammar: {
				...grammar,
				topicsChart,
			},
		});
		setTopicsLoading(false);
	};
	const handleOnChangeSentenceChange = async (value) => {
		setSentenceLoading(true);
		setSentenceFilter(dateValues[value]);
		const sentenceLearnedChart = await onFilterChangeSentenceChart(dateValues[value].value);
		dispatch({
			grammar: {
				...grammar,
				sentenceLearnedChart,
			},
		});
		setSentenceLoading(false);
	};

	const ReportTable = () => {
		return (
			<Row>
				<Col xs={12} lg={6} className="mb-5">
					<div className="title-heading mb-2">
						{t(`dashboard.grammar.grammartopicslearned.name`)}
						<InfoDescription
							position={"right-bottom"}
							description={t(`dashboard.grammar.grammartopicslearned.info`)}
						/>
					</div>
					<ReportTableComponent
						header={[
							{ key: "topic", label: t(["dashboard.reports.grammar.topicslearned"]) },
							{ key: "inCorrectFrequency", label: t(["dashboard.reports.grammar.timesincorrect"]) },
							{ key: "subLesson", label: t(["dashboard.reports.grammar.lessonnumber"]) },
							{ key: "firstEncounter", label: t(["dashboard.reports.grammar.firstencounter"]),type:"date" },
						]}
						data={topicsLearnedReport}
					/>
					{topicsLearnedReport && topicsLearnedReport.count > 4 && (
						<div className="text-right mt-1">
							<Link
								to={`/home/${language}/dashboard/reports/Grammar/topics-learned${
									studentId ? `/${studentId}` : ""
								}`}>
								{t(`helpers.utils.viewdetails`)}
							</Link>
						</div>
					)}
				</Col>
				<Col xs={12} lg={6} className="mb-4">
					<div className="title-heading mb-2">
						{t(`dashboard.grammar.fluencydrills.name`)}
						<InfoDescription position={"right-bottom"} description={t(`dashboard.grammar.fluencydrills.info`)} />
					</div>
					<ReportTableComponent
						header={[
							{ key: "paradigm", label: t(["dashboard.reports.grammar.paradigm"]) },
							{ key: "timesIncorrect", label: t(["dashboard.reports.grammar.timesincorrect"]) },
							{
								label: t([`helpers.utils.stage`]),
								key: ["stage", "currentStage"],
								type: "nested",
								textCenter: true,
							},
							{ key: "firstEncounter", label: t([`dashboard.reports.grammar.firstencounter`]), type: "date" },
						]}
						data={fluencyDrillReport}
					/>
					{fluencyDrillReport && fluencyDrillReport.count > 4 && (
						<div className="text-right mt-1">
							<Link
								to={`/home/${language}/Dashboard/Reports/Grammar/fluency-drills${
									studentId ? `/${studentId}` : ""
								}`}>
								{t(`helpers.utils.viewdetails`)}
							</Link>
						</div>
					)}
				</Col>
			</Row>
		);
	};
	const { t } = useTranslation();
	return (
		<Container fluid className="activity-container">
			<Row>
				<Col className="card-col mt-3">
					{!overviewLoading ? (
						<Card className="overView  no-shadow" body>
							<div className="d-flex justify-content-between align-items-center">
								<span className="title-heading mb-2">{t(`dashboard.overview`)}</span>
								<DateFilter
									dataset={dateValues}
									placeholder="Select Date"
									onSelectCB={handleOnChangeOverViewFilterChange}
									activeFilter={overviewFilter}
								/>
							</div>
							<div className="d-flex justify-content-around align-items-center">
								<Card body className="overview-cards p-2 equalize">
									<div className="p-2 icon-box bookmark">
										<i className="icon-book-2"></i>
									</div>
									<div className="mt-4">
										<span className="number-text">
											{topicsOverview && topicsOverview.numberOfTopicsLearned}
										</span>
									</div>
									<div className="m-2 text-center center-text">
										<span>{t(`dashboard.grammar.overview.topicslearned`)}</span>
									</div>
									<div className="m-2">
										{topicsOverview && (
											<span className={`percentage ${topicsOverview.isIncrease ? "inc" : "dec"}`}>
												{topicsOverview.percentageChange !== 0
													? `${topicsOverview.isIncrease ? "+" : "-"} ${Math.abs(
															topicsOverview.percentageChange
													  )} %`
													: ""}
											</span>
										)}
									</div>
								</Card>
								<Card body className="overview-cards p-2 equalize">
									<div className="p-2 icon-box star">
										<i className="icon-star-4"></i>
									</div>
									<div className="mt-4">
										<span className="number-text">
											{sentenceOverview && sentenceOverview.numberOfSentencesPracticed}
										</span>
									</div>
									<div className="m-2 text-center center-text">
										<span>{t(`dashboard.grammar.overview.sentencepracticed`)}</span>
									</div>
									<div className="m-2 ">
										{sentenceOverview && (
											<span className={`percentage ${sentenceOverview.isIncrease ? "inc" : "dec"}`}>
												{sentenceOverview.percentageChange !== 0
													? `${sentenceOverview.isIncrease ? "+" : "-"} ${Math.abs(
															sentenceOverview.percentageChange
													  )} %`
													: ""}
											</span>
										)}
									</div>
								</Card>
							</div>
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={330} />
						</SkeletonTheme>
					)}
				</Col>
				<Col className="card-col mt-3">
					{!proficiencyLoading ? (
						<Card body className="days-spent  no-shadow">
							<div className="d-flex justify-content-between align-items-center">
								<div className="title-heading">
									{t("dashboard.grammar.grammarproficiency.name")}
									<InfoDescription
										position={"right-bottom"}
										description={t(`dashboard.grammar.grammarproficiency.info`)}
									/>
								</div>
								<DateFilter
									dataset={dateValues}
									placeholder="Select Date"
									onSelectCB={handleOnChangeProficiencyDateChange}
									activeFilter={proficiencyFilter}
								/>
							</div>
							<div className="d-flex	">
								<PieChart
									data={ProficiencyChart.filter(
										(el) => el.name === "Correct Answers" || el.name === "InCorrect Answers"
									)}
								/>
								<div className="d-flex justify-content-center align-items-center flex-column m-auto">
									{ProficiencyChart && (
										<>
											<span className="days-spent-number">
												{ProficiencyChart.filter((el) => el.name === "Correct Answers")[0].value}
											</span>
											<span className="days-spent-text mt-3">{t(`helpers.utils.correct`)} </span>
											<span
												className={`mt-3 days-spent-percentage ${
													ProficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
														.value < 0
														? "percentage  dec"
														: "percentage  inc"
												}`}>
												{ProficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
													.value > 0 && "+ "}
												{ProficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
													.value < 0 && "- "}
												{Math.abs(
													ProficiencyChart.filter((el) => el.name === "Correct Percentage Change")[0]
														.value
												)}
												%
											</span>
										</>
									)}
								</div>
							</div>
							<div className="d-flex justify-content-between">
								<div className="d-flex  align-items-center">
									<span className="blue-dot"></span>
									<span className="legend-text">
										{t("dashboard.grammar.grammarproficiency.legendcorrectgrammar", {
											correctgrammar: ProficiencyChart.filter((el) => el.name === "Correct Answers")[0]
												.value,
											correctgrammarpercentage: ProficiencyChart.filter(
												(el) => el.name === "Correct Answer Percentage"
											)[0].value,
										})}
									</span>
								</div>
								<div className="d-flex d-flex  align-items-center">
									<span className="green-dot"></span>
									<span className="legend-text">
										{t("dashboard.grammar.grammarproficiency.legendincorrectgrammar", {
											incorrectgrammar: ProficiencyChart.filter(
												(el) => el.name === "InCorrect Answers"
											)[0].value,
											incorrectgrammarpercentage: ProficiencyChart.filter(
												(el) => el.name === "InCorrect Answer Percentage"
											)[0].value,
										})}
									</span>
								</div>
							</div>
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={330} />
						</SkeletonTheme>
					)}
				</Col>
				{studentId !== "Collective" && (
					<Col className="card-col col-sm-12 col-md-12 col-lg-3 mt-3">
						<Card className="audio-library  p-3 no-shadow" body>
							<img className="mt-2" src={AudioLibrary} alt="audio-library" />
							<div className="title-heading">{t(`dashboard.audiolibrary.grammaraudiolibrary`)}</div>
							<div className="m-3 text-center center-text">
								<span className="">{t(`dashboard.audiolibrary.reviewaudiorecordings`)}</span>
								<Link
									to={`/home/${match.params.language}/dashboard/AudioLibrary/Grammar${
										studentId ? `/${studentId}` : ""
									}`}
									className="view-details mt-4 d-block">
									{t(`helpers.utils.viewdetails`)}
								</Link>
							</div>
						</Card>
					</Col>
				)}
			</Row>
			{studentId !== "Collective" && (
				<Row>
					<Col className="card-col mt-3">
						{!reportLoading ? (
							<Card body className="no-shadow   reports">
								{ReportTable()}
							</Card>
						) : (
							<SkeletonTheme color="#f6f8ff">
								<Skeleton height={200} />
							</SkeletonTheme>
						)}
					</Col>
				</Row>
			)}
			<Row>
				<Col className="card-col mt-3 mb-3 " xs={12} lg={6}>
					{!sentenceLoading ? (
						<Card body className="no-shadow">
							<div className="d-flex justify-content-between align-items-center mb-2">
								<div className="title-heading ">
									{t(`dashboard.grammar.numberofsentencespracticed.name`)}
									<InfoDescription
										position={"right-bottom"}
										description={t(`dashboard.grammar.numberofsentencespracticed.info`)}
									/>
								</div>
								<DateFilter
									dataset={dateValues}
									placeholder="Select Date"
									onSelectCB={handleOnChangeSentenceChange}
									activeFilter={sentenceFilter}
								/>
							</div>
							{sentenceLearnedChart.length > 0 ? (
								<LineChart data={sentenceLearnedChart} stroke="#4a9efe" />
							) : (
								<div className="text-center" style={{ margin: "147px 0px" }}>
									{t(`helpers.utils.nodata`)}
								</div>
							)}
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={390} />
						</SkeletonTheme>
					)}
				</Col>
				<Col className="card-col mt-3 mb-3 " xs={12} lg={6}>
					{!topicsLoading ? (
						<Card body className="no-shadow">
							<div className="d-flex justify-content-between align-items-center mb-2">
								<div className="title-heading ">
									{t(`dashboard.grammar.numberofgrammartopicslearned.name`)}
									<InfoDescription
										position={"right-bottom"}
										description={t(`dashboard.grammar.numberofgrammartopicslearned.info`)}
									/>
								</div>
								<DateFilter
									dataset={dateValues}
									placeholder="Select Date"
									onSelectCB={handleOnChangeTopicsDateChange}
									activeFilter={topicsFilter}
								/>
							</div>
							{topicsChart.length > 0 ? (
								<LineChart data={topicsChart} stroke="#f983cb" />
							) : (
								<div className="text-center" style={{ margin: "147px 0px" }}>
									{t(`helpers.utils.nodata`)}
								</div>
							)}
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={390} />
						</SkeletonTheme>
					)}
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	getOverView,
	getChart,
	getReports,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Grammar));
