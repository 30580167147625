import React from "react";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import InfoDescription from "../../sharedComponents/infoDescription";
import { Card, Row, Col, Container } from "react-bootstrap";
import { getUserDetails } from "../../../utils/verifyUser";
import { withTranslation } from "react-i18next";

const planType = {
	0: "Lesson Goals",
	1: "Reading Goals",
	2: "Practice Goals",
};
const TopInfoSection = ({ streakData, goalData, studentId, t, history, language }) => {
	const name = getUserDetails().firstName;
	const redirectToPlan = (plan) => {
		switch (plan.planType) {
			case 0:
				history.push(`/Home/${language}/Dashboard/planner/lesson`);
				break;
			case 1:
				history.push(`/Home/${language}/Dashboard/planner/reading`);
				break;
			case 2:
				history.push(`/Home/${language}/Dashboard/planner/practice`);
				break;
			default:
				break;
		}
	};

	return (
		<Container className="top-info-container" fluid>
			<Row>
				<Col className="card-col welcome" xs={12} lg={6}>
					{goalData ? (
						<Card body>
							<>
								<div className="welcome-container">
									<h5 className="welcome-header">{t("dashboard.welcometitle", { username: `${name}` })}</h5>
									<span className="welcome-text">
										{t("dashboard.welcomemsg", {
											numberoftasks: `${goalData.numberOfTasks}`,
											percentageoflearning: `${goalData.percentageOfLearning}`,
										})}
									</span>
								</div>
								<div className="welcome-image"></div>
							</>
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={160} />
						</SkeletonTheme>
					)}
				</Col>
				<Col className="card-col streak" xs={6} lg={3}>
					{streakData ? (
						<Card body>
							<div className="streak-data">
								<div>
									<div className="mb-1 mt-4">
										<span className="streak-days mr-2">{streakData.currentStreakInDays}</span>
										<span className="streak-text">{t("dashboard.days")}</span>
									</div>
									<div>
										<span className="streak-text">{t("dashboard.currentstreak")}</span>
									</div>
								</div>
								<div className="small-box mt-3">
									<span className={`small-box-text ${streakData.isCurrentPercentageIncrease ? "" : "dec"}`}>
										{`${streakData.isCurrentPercentageIncrease ? "+" : "-"}${Math.abs(
											streakData.currentStreakPercentage
										)}`}
										%
									</span>
								</div>
							</div>
							<InfoDescription position={"left-bottom"} description={t("dashboard.currentstreakdescription")} />
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={160} />
						</SkeletonTheme>
					)}
				</Col>
				<Col className="card-col streak" xs={6} lg={3}>
					{streakData ? (
						<Card body>
							<div className="streak-data">
								<div>
									<div className="mb-1 mt-4">
										<span className="streak-days mr-2">{streakData.longestStreakInDays}</span>
										<span className="streak-text">{t("dashboard.days")}</span>
									</div>
									<div>
										<span className="streak-text ">{t("dashboard.longeststreak")}</span>
									</div>
								</div>
								<div className="small-box mt-3">
									<span className={`small-box-text ${streakData.isLongestPercentageIncrease ? "" : "dec"}`}>
										{`${streakData.isLongestPercentageIncrease ? "+" : "-"}${Math.abs(
											streakData.longestStreakPercentage
										)}`}
										%
									</span>
								</div>
							</div>
							<InfoDescription position={"left-bottom"} description={t("dashboard.longeststreakdescription")} />
						</Card>
					) : (
						<SkeletonTheme color="#FFF">
							<Skeleton height={160} />
						</SkeletonTheme>
					)}
				</Col>
				{!studentId && (
					<Col className="card-col mt-2" xs={12}>
						{goalData ? (
							<Card body className="notification-section">
								<span className="title mb-2">{t("dashboard.notification")}</span>
								{goalData && goalData.planDetails && goalData.planDetails.length > 0 ? (
									<Row>
										{goalData &&
											goalData.planDetails &&
											goalData.planDetails.slice(0, 3).map((el, idx) => (
												<Col
													xs={12}
													lg={4}
													key={idx}
													className="pl-0 pr-0 mb-2"
													style={{ cursor: "pointer" }}
													onClick={() => redirectToPlan(el)}>
													<div className="lesson-notification d-flex p-2  ml-2 mr-2">
														<div className="date-section">
															<span className="date-text">{moment(el.date).format("DD")}</span>
															<span className="day-text">{moment(el.date).format("ddd")}</span>
														</div>
														<div className="d-flex flex-column ml-2 mr-1">
															<span style={{ fontSizeL: 15, margin: "5px 0px" }}>{el.task}</span>
															<div style={{ fontSize: 13, color: "rgba(7,47,64,0.79)" }}>
																<span>{planType[el.planType]}</span>
															</div>
														</div>
													</div>
												</Col>
											))}
									</Row>
								) : (
									<div className="d-flex justify-content-center">{t("helpers.utils.nodata")}</div>
								)}
							</Card>
						) : (
							<SkeletonTheme color="#FFF">
								<Skeleton height={100} />
							</SkeletonTheme>
						)}
					</Col>
				)}
			</Row>
		</Container>
	);
};

export default withTranslation()(TopInfoSection);
