import React, { useState } from "react";
import { Button, Card, Col, Form, OverlayTrigger, Popover, Table } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import SharedModal from "../../../sharedComponents/Modal";
import "./settings.scss";

const EditDeck = ({
	editDeck,
	updateEditDeck,
	clearFormTab,
	groups,
	setShowWordModalDelete,
	getSearchResult,
	asyncSearch,
	getUploadComponent,
	addNewWordDeck,
	uploadMediaToWord,
	setSearchTerm,
	createDeck,
}) => {
	const { t } = useTranslation();
	const { language, groupId, deckId, words, decks, newWord } = editDeck;
	const [addNewWord, setAddNewWord] = useState(newWord && newWord.text && newWord.text.length ? true : false);
	const [showModal, setShowModal] = useState(false);
	const [deckName, setDeckName] = useState(null);
	const [deckGroupName, setdeckGroupName] = useState(null);
	const groupList = groups && groups.map((deck) => ({ label: deck.group, value: deck.groupID }));
	const updateField = (field, value) => {
		updateEditDeck(field, value);
	};

	const deleteWord = (index) => {
		setShowWordModalDelete(true);
		updateField("wordId", words[index].id);
	};

	const saveWord = (e) => {
		e.preventDefault();
		addNewWordDeck();
	};

	const removeWord = (e) => {
		e.preventDefault();
		updateField("newWord", {});
		setSearchTerm({});
	};

	const uploadAudio = (file, idx) => {
		const payload = {
			id: words[idx].id,
			mediaType: 3,
			file: {
				fileName: file.name,
				file: file.base64.split(",")[1],
			},
		};
		uploadMediaToWord(payload);
	};

	const uploadImage = (file, idx) => {
		const payload = {
			id: words[idx].id,
			mediaType: 1,
			file: {
				fileName: file.name,
				file: file.base64.split(",")[1],
			},
		};
		uploadMediaToWord(payload);
	};

	const AudioButton = (audioPath) => {
		return (
			<>
				{audioPath && audioPath.audioPath && (
					<>
						<audio
							className="settings-audio word-audio"
							src={audioPath.audioPath}
							controls
							controlsList="nodownload"
						/>
					</>
				)}
			</>
		);
	};

	const onCreateDeck = (e) => {
		e.preventDefault();
		createDeck(deckGroupName, deckName);
		handleClose();
	};

	const handleClose = () => {
		setdeckGroupName(null);
		setDeckName(null);
		setShowModal(false);
	};

	const renderModalBody = () => {
		return (
			<form>
				<div className=" d-flex justify-content-center relative">
					<label className="title">{t("biblereading.sense.addtocustomdeck")}</label>
					<span className="close-btn pointer" onClick={handleClose}>
						<i className="icon icon-close "></i>
					</span>
				</div>
				<div className="d-flex flex-column mt-2">
					<label className="field-label">{t("biblereading.bibleindex.modal.selectoraddcustomdeckgroup")}</label>
					<Creatable
						noOptionsMessage={() => t("helpers.utils.nooptions")}
						loadingMessage={() => t("helpers.utils.learning.askquestion.loading")}
						placeholder={t("biblereading.select")}
						value={deckGroupName ? deckGroupName : t("helpers.utils.nooptions")}
						onChange={(e) => {
							setdeckGroupName(e);
						}}
						options={groupList && groupList}
						className="react-select"
						classNamePrefix="react-select"
					/>
				</div>
				<div className="d-flex flex-column mt-2">
					<label className="field-label">{t("flashcarddeck.flashcardsettings.deckname")}</label>
					<input
						className="form-control"
						type="text"
						value={deckName}
						placeholder={t("flashcarddeck.flashcardsettings.deckname")}
						onChange={(e) => setDeckName(e.target.value)}
					/>
				</div>
				<div className="mt-3 d-flex justify-content-end">
					<button className="btn btn-primary" disabled={!deckName || !deckGroupName} onClick={(e) => onCreateDeck(e)}>
						{t("dashboard.planner.newplan.create")}
					</button>
				</div>
			</form>
		);
	};
	const getValue = (item, key, idx) => {
		switch (key) {
			case "text":
			case "gloss":
			case "definition":
				return item[key];
			case "examples":
				const innerText = item[key].map((value, index) => (
					<span key={index}>
						<p style={{ textAlign: `${language === 1 ? "left" : "right"}` }}>{ReactHtmlParser(value.reference)}</p>
						<p style={{ textAlign: "right", marginRight: "1rem" }}>{ReactHtmlParser(value.verse)}</p>
					</span>
				));
				return <div className="example-text">{innerText}</div>;
			case "upload":
				let innerAudio;
				let innerImage;

				if (item["audioFilePath"]) {
					innerAudio = (
						<OverlayTrigger
							trigger="click"
							placement="auto"
							rootClose={true}
							overlay={
								<Popover id="popover-positioned-left" className="info-popup">
									<Popover.Content>
										<div className="d-flex flex-column" style={{ width: 300, height: "auto" }}>
											<div className="d-flex justify-content-center mb-2">
												<AudioButton audioPath={item["audioFilePath"]} />
											</div>
										</div>
									</Popover.Content>
								</Popover>
							}>
							<div className="img-wrap pointer">
								<i className="icon icon-audio" />
							</div>
						</OverlayTrigger>
					);
				} else {
					innerAudio = getUploadComponent("audio", uploadAudio, idx);
				}
				if (item["illustrationFilePath"]) {
					innerImage = (
						<OverlayTrigger
							trigger="click"
							placement="auto"
							rootClose={true}
							overlay={
								<Popover id="popover-positioned-left" className="info-popup">
									<Popover.Content>
										<div className="d-flex flex-column" style={{ width: 420 }}>
											<div className="d-flex justify-content-center mb-2">
												<Image src={item["illustrationFilePath"]} style={{ height: 160 }} />
											</div>
										</div>
									</Popover.Content>
								</Popover>
							}>
							<div className="img-wrap pointer">
								<i className="icon icon-image-4" />
							</div>
						</OverlayTrigger>
					);
				} else {
					innerImage = getUploadComponent("image", uploadImage, idx);
				}
				return (
					<div className="upload-wrapper d-flex">
						{innerAudio}
						{innerImage}
					</div>
				);
			default:
				break;
		}
	};

	const deckWordsHeader = [
		{ name: t("flashcarddeck.flashcardsettings.editdecktab.originallanguage"), value: "text" },
		{ name: t("helpers.utils.gloss"), value: "gloss" },
		{ name: t("helpers.utils.definition"), value: "definition" },
		{ name: t("flashcarddeck.flashcardsettings.editdecktab.example"), value: "examples" },
		{ name: t("flashcarddeck.flashcardsettings.editdecktab.uploadmedia"), value: "upload" },
	];

	return (
		<div className="deck-settings settings-tab">
			<Form>
				<div className="d-flex mb-2" style={{ flexDirection: "row-reverse" }}>
					<Button
						variant="primary"
						onClick={() => {
							setShowModal(true);
						}}>
						{t("flashcarddeck.flashcardsettings.editdecktab.createnewcustomdeck")}
					</Button>
				</div>
				<Form.Row>
					<Col>
						<Form.Group controlId="groupName">
							<Form.Label>{t("flashcarddeck.flashcardsettings.groupname")}</Form.Label>
							<Form.Control
								as="select"
								value={groupId}
								onChange={(e) => {
									updateField("groupId", parseInt(e.target.value));
								}}>
								<option></option>
								{groups &&
									groups.length &&
									groups.map((group, index) => (
										<option key={index} value={group.groupID}>
											{group.group}
										</option>
									))}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="deckName">
							<Form.Label>{t("flashcarddeck.flashcardsettings.editgrouptab.deckname")}</Form.Label>
							<Form.Control
								as="select"
								value={deckId}
								onChange={(e) => {
									updateField("deckId", parseInt(e.target.value));
								}}>
								<option></option>
								{decks &&
									decks.length &&
									decks.map((deck, index) => (
										<option key={index} value={deck.id}>
											{deck.name}
										</option>
									))}
							</Form.Control>
						</Form.Group>
					</Col>
				</Form.Row>
				{addNewWord && (!newWord.text || newWord.text.length === 0) && (
					<div className="d-flex my-4" style={{ paddingBottom: "2px" }}>
						{asyncSearch()}
					</div>
				)}
				{groupId && deckId && words.length < 25 && !addNewWord ? (
					<div className="d-flex my-4" style={{ flexDirection: "row-reverse" }}>
						<Button
							variant="primary"
							onClick={(e) => {
								setAddNewWord(true);
							}}>
							+ {t("helpers.utils.addanotherword")}
						</Button>
					</div>
				) : null}
				<Form.Row>
					<Card className="no-shadow" style={{ height: "100%", width: "100%" }}>
						<div className="table-wrapper">
							<Table className="senses-table-container mb-0">
								<thead>
									<tr className="spacer"></tr>
									<tr>
										{deckWordsHeader.map((el, idx) => (
											<th className={"text-center"} key={idx}>
												{el.name}
											</th>
										))}
										<th className="text-center">{t("dashboard.planner.action")}</th>
									</tr>
								</thead>
								<tbody style={{ height: "400px" }}>
									{(words && words.length > 0) || Object.keys(newWord).length > 0 ? (
										<>
											{words.map((el, idx) => (
												<React.Fragment key={idx}>
													<tr className="spacer"></tr>
													<tr className="column-table">
														{deckWordsHeader.map((item, index) => (
															<td
																key={el[item.id]}
																className={"text-center"}
																style={{ verticalAlign: "middle" }}>
																{getValue(el, item.value, idx)}
															</td>
														))}
														<td className="text-center" style={{ verticalAlign: "middle" }}>
															<i className="icon icon-delete" onClick={() => deleteWord(idx)}></i>
														</td>
													</tr>
													<tr className="spacer"></tr>
												</React.Fragment>
											))}
										</>
									) : (
										<>
											<tr className="spacer"></tr>
											<tr style={{ margin: "auto 0" }}>
												<td colSpan={6} style={{ textAlign: "center" }}>
													{t("helpers.utils.nodata")}
												</td>
											</tr>
										</>
									)}
									{newWord && newWord.text && (
										<tr className="column-table">
											<td className="text-center pt-3">{newWord.text}</td>
											<td className="text-center">
												{newWord.entryRefId ? (
													newWord.gloss
												) : (
													<div className="d-flex">
														<input
															className="form-control gloss-input col-xs-4"
															type="text"
															placeholder={t(
																"flashcarddeck.flashcardsettings.editdecktab.entergloss"
															)}
															value={newWord.gloss}
															onChange={(e) => updateField("gloss", e.target.value)}
														/>
													</div>
												)}
											</td>
											<td className="text-center">
												{newWord.entryRefId ? (
													newWord.definition
												) : (
													<div className="d-flex">
														<input
															className="form-control"
															type="text"
															placeholder={t(
																"flashcarddeck.flashcardsettings.editdecktab.enterdefinition"
															)}
															value={newWord.definition}
															onChange={(e) => updateField("definition", e.target.value)}
														/>
													</div>
												)}
											</td>
											<td className="text-center">
												{newWord.entryRefId ? (
													<div className="example-text">
														{newWord.examples &&
															newWord.examples.map((value, index) => (
																<span key={index}>
																	<p
																		style={{
																			textAlign: `${language === 1 ? "left" : "right"}`,
																		}}>
																		{ReactHtmlParser(value.reference)}
																	</p>
																	<p style={{ textAlign: "right", marginRight: "1rem" }}>
																		{ReactHtmlParser(value.verse)}
																	</p>
																</span>
															))}
													</div>
												) : (
													<div className="d-block">
														<input
															className="form-control"
															type="text"
															placeholder={t(
																"flashcarddeck.flashcardsettings.editdecktab.enterreference"
															)}
															value={newWord.example.reference && newWord.example.reference}
															onChange={(e) => updateField("examplesRef", e.target.value)}
															style={{ marginBottom: "5px" }}
														/>
														<input
															className="form-control "
															type="text"
															placeholder={t(
																"flashcarddeck.flashcardsettings.editdecktab.enterverse"
															)}
															value={newWord.example.reference && newWord.example.verse}
															onChange={(e) => updateField("examplesVer", e.target.value)}
														/>
													</div>
												)}
											</td>
											<td className="text-center d-flex mr-2" style={{ justifyContent: "space-evenly" }}>
												{newWord.audio && newWord.audio.fileName ? (
													<OverlayTrigger
														trigger="click"
														placement="auto"
														rootClose={true}
														overlay={
															<Popover id="popover-positioned-left" className="info-popup">
																<Popover.Content>
																	<div
																		className="d-flex flex-column  pr-2 pl-2"
																		style={{ width: 420 }}>
																		<div className="d-flex justify-content-center mb-2">
																			<AudioButton audioPath={newWord.audio.file} />
																		</div>
																	</div>
																</Popover.Content>
															</Popover>
														}>
														<div className="img-wrap pointer">
															<i className="icon icon-audio" />
														</div>
													</OverlayTrigger>
												) : (
													getUploadComponent("audio")
												)}

												{newWord.illustration && newWord.illustration.fileName ? (
													<OverlayTrigger
														trigger="click"
														placement="auto"
														rootClose={true}
														overlay={
															<Popover id="popover-positioned-left" className="info-popup">
																<Popover.Content>
																	<div className="d-flex flex-column" style={{ width: 420 }}>
																		<div className="d-flex justify-content-center mb-2">
																			<Image
																				src={newWord.illustration.file}
																				style={{ height: 160 }}
																			/>
																		</div>
																	</div>
																</Popover.Content>
															</Popover>
														}>
														<div className="img-wrap pointer">
															<i className="icon icon-image-4" />
														</div>
													</OverlayTrigger>
												) : (
													getUploadComponent("image")
												)}
											</td>
											<td className="text-center" style={{ padding: "7px" }}>
												<Button
													variant="secondary"
													style={{ maxHeight: "35px", marginRight: "1rem", marginBottom: "5px" }}
													onClick={(e) => saveWord(e)}>
													{t("flashcarddeck.flashcardsettings.display&reviewtab.save")}
												</Button>
												<Button
													variant="danger"
													style={{ maxHeight: "35px", marginRight: "1rem", marginBottom: "5px" }}
													onClick={(e) => removeWord(e)}>
													{t("helpers.utils.cancel")}
												</Button>
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
					</Card>
				</Form.Row>
				<SharedModal
					show={showModal}
					modalBody={renderModalBody()}
					handleClose={() => {
						setShowModal(false);
					}}
					modalClass="no-header add-flashcard-modal"
				/>
			</Form>
		</div>
	);
};

export default EditDeck;
