import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

export const dtlsFields = [
	{
		title: "Gloss",
		icon: "icon-paragraph",
		field: "gloss",
	},
	{
		title: "Definition",
		icon: "icon-definition",
		field: "definition",
	},
	{
		title: "Example",
		icon: "icon-example",
		field: "examples",
	},
];

export const renderOverlay = (dtl, wordDetails, language) => {
	return (
		<OverlayTrigger
			trigger="click"
			placement="top"
			rootClose={true}
			key={dtl.title}
			overlay={
				<Popover id="popover-basic" className="lang-dtls-pop">
					<Popover.Content>
						{dtl.field === "examples" ? (
							wordDetails[dtl.field].map((word, index) => (
								<div className="d-flex flex-column mb-2" key={`${word.vers}${index}`}>
									<span
										className={language === "Hebrew" ? "Hebrew-font" : "Greek-font"}
										dangerouslySetInnerHTML={{
											__html: word.reference,
										}}></span>
									<span className="verse">({word.verse})</span>
								</div>
							))
						) : (
							<span
								dangerouslySetInnerHTML={{
									__html: wordDetails[dtl.field],
								}}></span>
						)}
					</Popover.Content>
				</Popover>
			}>
			<div className="d-flex flex-column align-items-center mr-3 pointer">
				<span className="round-background">
					<i className={`icon ${dtl.icon}`}></i>
				</span>
				<label className="field-label"> {dtl.title}</label>
			</div>
		</OverlayTrigger>
	);
};

export const renderActionButtons = (lessonDetails, disableNext, isAssociation, skipActivity, updateAcitivity) => (
	<div
		className={`actions ${
			lessonDetails && lessonDetails.hasPrevious && lessonDetails.isBackEnabled
				? "justify-content-between"
				: "justify-content-end"
		}`}>
		{lessonDetails && lessonDetails.hasPrevious && lessonDetails.isBackEnabled && (
			<label className="action-title pointer" onClick={() => updateAcitivity("previous")}>
				<i className="icon icon-prev mr-2"></i>Prev
			</label>
		)}
		{(!disableNext || isAssociation) && (
			<label className="action-title pointer" onClick={() => updateAcitivity(skipActivity ? "skip" : "next")}>
				Next
				<i className="icon icon-next ml-2"></i>
			</label>
		)}
	</div>
);

export const renderNavigationSection = (lessonDetails, isAssociation, updateAcitivity, deckType, dispatch) => (
	<div
		className={`d-flex navigation-section ${
			lessonDetails.isBackToAssociationEnabled ||
			lessonDetails.isBackToLearningEnabled ||
			(deckType === "wordsNeedPractise" && isAssociation)
				? "justify-content-between align-items-center"
				: "justify-content-end"
		}`}>
		<div className="d-flex justify-content-start">
			{lessonDetails.isBackToAssociationEnabled && (
				<label
					className="pointer lesson-nav-btn mr-2"
					onClick={() => updateAcitivity("backtoassociation")}
					data-tip="Back to Association">
					<i className="icon icon-left-arrow-1" />
					<ReactTooltip />
				</label>
			)}
			{lessonDetails.isBackToLearningEnabled && (
				<label
					className="pointer lesson-nav-btn mr-2"
					onClick={() => updateAcitivity("backtolearning")}
					data-tip="Resume Learning">
					<i className="icon icon-pause-play" />
					<ReactTooltip />
				</label>
			)}
			{deckType === "wordsNeedPractise" && isAssociation && (
				<label className="pointer lesson-nav-btn" onClick={() => dispatch({ showModal: true })} data-tip="Archive word">
					<i className="icon icon-archive" />
					<ReactTooltip />
				</label>
			)}
		</div>

		{!isAssociation && (
			<label className="progress-label">
				Progress: {lessonDetails.question} out of {lessonDetails.total}
			</label>
		)}
	</div>
);

export const togglePlay = (dispatch, speed = 1) => {
	const player = document.getElementById("pro-audio");
	if (player.duration) {
		if (player.paused) {
			dispatch({ isAudioPlaying: true });
			player.playbackRate = speed;
			const playPromise = player.play();
			if (playPromise !== undefined) {
				playPromise.then().catch(() => {
					dispatch({ isAudioPlaying: false });
				});
			}
		} else {
			dispatch({ isAudioPlaying: false });
			player.pause();
		}
	}
};
