import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown, Navbar } from "react-bootstrap";
import { getUserDetails } from "../utils/verifyUser";
import { sessionLogging } from "./Sidemenu/actions";

import BiblingoLogo from "../assets/images/biblingo-logo.png";

const MobileHeader = ({ handleClick, history, match, sessionLogging }) => {
	const params = history.location.pathname.split("/");
	const [value, setValue] = useState(params && params.length > 0 && params[2] ? params[2] : "Greek");
	const userDetails = getUserDetails();

	const handleLogout = () => {
		localStorage.removeItem("auth");
		localStorage.removeItem("settings");
		localStorage.removeItem("sessionId");
		// localStorage.removeItem("i18nextLng");
		const sessionId = localStorage.getItem("sessionId");
		sessionLogging({
			language: match.params.language === "Hebrew" ? 0 : 1,
			sessionId: sessionId ? sessionId : null,
			module: 6,
		});
		if (userDetails.origin === "Biblingo") history.push("/login");
		else if (userDetails.origin === "CampusEdu") window.location.href = "https://learn.stg.campusedu.tech/";
		else if (userDetails.origin === "Fuller") window.location.href = "https://www.fuller.edu/";
	};
	const handleChange = (e) => {
		setValue(e.target.value);
		history.push(`/home/${e.target.value}/Language-Learning`);
	};

	const renderLanguageComponent = () => {
		return (
			getUserDetails().role.description === "Student" &&
			getUserDetails().languages.length > 1 &&
			getUserDetails().origin === "Biblingo" && (
				<React.Fragment>
					<div className="btn-group btn-group-toggle language-toggle" data-toggle="buttons">
						<label className={`${value === "Greek" && "active"} btn btn-language-toggle`}>
							<input
								type="radio"
								name="options"
								value="Greek"
								onChange={handleChange}
								checked={value === "Greek"}
							/>
							Greek
						</label>
						<label
							className={`${
								value === "Hebrew" && "active"
							} btn btn-language-toggle position-relative header-toggle`}>
							<input
								type="radio"
								name="options"
								value="Hebrew"
								onChange={handleChange}
								checked={value === "Hebrew"}
							/>
							Hebrew
						</label>
					</div>
				</React.Fragment>
			)
		);
	};

	return (
		<div className="row m-0 w-100 justify-content-between mobile-header">
			<div className="logo-container">
				<img src={BiblingoLogo} alt="logo" className="w-100 bib-logo" />
			</div>
			<div className="d-flex justify-content-end align-items-center user-title">
				<Navbar expand="lg">
					<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleClick} />
				</Navbar>
				{renderLanguageComponent()}
				<Dropdown className="users-section ml-2 user-name">
					<Dropdown.Toggle>
						<p className="usermenu">
							{userDetails.firstName ? userDetails.firstName.charAt(0) : ""}
							{userDetails.lastName ? userDetails.lastName.charAt(0) : ""}
						</p>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item onClick={() => history.push("/Profile")}>
							<i className="icon icon-user-2 mr-2" /> My Profile
						</Dropdown.Item>
						{getUserDetails().origin === "Biblingo" && (
							<Dropdown.Item onClick={() => history.push("/Change-Password")}>
								<i className="icon icon-key mr-2" /> Change Password
							</Dropdown.Item>
						)}
						<Dropdown.Item onClick={handleLogout}>
							<i className="icon icon-logout mr-2" /> Log out
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
			<div className="border-header"></div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	active: state.home.active,
});

const mapDispatchToProps = {
	sessionLogging,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeader));
