import React, { useEffect, useState, useReducer } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import Header from "../../header";
import Activity from "./Activity";
import Reading from "./Reading";
import Grammar from "./Grammar";
import Vocabulary from "./Vocabulary";
import Planner from "./Planner";
import { getStreakData, getGoalData } from "./action";
import { updateField } from "../LanguageLearning/actions";
import { setSideMenu } from "../../Sidemenu/actions";
import { getUserDetails } from "../../../utils/verifyUser";
import { fieldUpdateReducer } from "../../../utils/helper";
import TopInfoSection from "./TopInfoSection";
import "./dashboard.scss";
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n"

const initialState = {
	streak: null,
	goal: null,
	activity: {
		timeSpent: null,
		days: null,
		points: null,
		sessions: null,
		learningChart: [],
		sessionChart: [],
		pointChart: [],
		daysSpentLearningChart: [],
	},
	grammar: {
		topicsOverview: null,
		sentenceOverview: null,
		ProficiencyCart: null,
		topicsChart: [],
		sentenceLearnedChart: [],
		fluencyDrillReport: null,
		topicsLearnedReport: null,
	},
	planner: {
		lessonData: null,
		ReadingData: null,
		listLesson: [],
		practiceData: null,
		bookList: [],
	},
	vocabulary: {
		words: null,
		proficiency: null,
		proficiencyChart: [],
		wordsLearned: null,
		wordsLearningStage: null,
	},
	reading: {
		chaptersOverview: null,
		booksOverview: null,
		booksLineChart: [],
		chaptersLineChart: [],
		booksPieChart: [],
		wordsPieChart: [],
		chaptersPieChart: [],
		chaptersReport: null,
		comprehensionReport: null,
	},
};

const Dashboard = ({ history, menu, language, setSideMenu, getStreakData, getGoalData, match }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(t("dashboard.activity.name"));
	const [state, dispatch] = useReducer(fieldUpdateReducer, initialState);
	const { streak, goal, activity, vocabulary, planner, grammar, reading } = state;
	const studentId = match.params.id;
	const collectiveFilter =
		studentId === "Collective" ? (history.location.search.length > 0 ? history.location.search : null) : null;
	const tabList = [t("dashboard.activity.name"), t("dashboard.vocabulary.name"), t("dashboard.grammar.name"), t("dashboard.reading.name"), t("dashboard.planner.name")];
	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title:t("dashboard.name") , path: "" }],
			language: location[2],
		});
	}, []);

	const getData = async () => {
		if (studentId !== "Collective") {
			const { response: streakData } = await getStreakData(studentId);
			const { response: goalData } = await getGoalData(match.params.language);
			dispatch({ streak: streakData, goal: goalData });
		}
	};

	useEffect(() => {
		getData();
		return () => {
			localStorage.removeItem("collectiveFilter");
		};
	}, []);

	const renderTabContent = (tabName) => {
		switch (tabName) {
			case i18n.t("dashboard.activity.name") :
				return (
					<Activity
						activity={activity}
						dispatch={dispatch}
						currentTab={activeTab}
						studentId={studentId}
						collectiveFilter={collectiveFilter}
					/>
				);
			case i18n.t("dashboard.vocabulary.name") : 
				return (
					<Vocabulary
						vocabulary={vocabulary}
						dispatch={dispatch}
						currentTab={activeTab}
						studentId={studentId}
						collectiveFilter={collectiveFilter}
					/>
				);
			case i18n.t("dashboard.grammar.name") :
				return (
					<Grammar
						grammar={grammar}
						dispatch={dispatch}
						currentTab={activeTab}
						studentId={studentId}
						collectiveFilter={collectiveFilter}
					/>
				);
			case i18n.t("dashboard.reading.name") : 
				return (
					<Reading
						reading={reading}
						dispatch={dispatch}
						currentTab={activeTab}
						studentId={studentId}
						collectiveFilter={collectiveFilter}
					/>
				);
			case i18n.t("dashboard.planner.name") : 
				return (
					<Planner
						planner={planner}
						dispatch={dispatch}
						currentTab={activeTab}
						studentId={studentId}
						collectiveFilter={collectiveFilter}
					/>
				);
			default:
				return;
		}
	};

	return (
		<div className="dashboard-container empty">
			<Header
				menu={menu}
				language={language}
				history={history}
				head= {t("dashboard.name")}
				enableLanguageSwitch
				redirectionUrl="Dashboard"
			/>
			<div className="dashboard-content">
				{studentId !== "Collective" && (
					<TopInfoSection
						streakData={streak}
						goalData={goal}
						studentId={studentId}
						history={history}
						language={match.params.language}
					/>
				)}{" "}
				<Tabs
					className="default-tabs"
					activeKey={activeTab}
					onSelect={(tab) => {
						setActiveTab(tab);
					}}>
					{tabList.map((tabName) => {
						if (studentId && tabName === t("dashboard.planner.name")) {
							return null;
						}
						return (
							<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
								{renderTabContent(tabName)}
							</Tab>
						);
					})}
				</Tabs>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	setSideMenu,
	getStreakData,
	getGoalData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
