import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { setSideMenu } from "../../../Sidemenu/actions";
import { getUserDetails } from "../../../../utils/verifyUser";
import { updateField, changeActivity } from "../../LanguageLearning/actions";
import { getDeckDetails, updateDeckField, reset } from "../actions";
import SidePanel from "../../LanguageLearning/LessonDetails/SidePanel";
import Header from "../../../header";
import Loader from "../../../sharedComponents/Loader";
import { getSubView, getMainView} from "../../LanguageLearning/LessonDetails/helper";
import CompletionModal from "./completionModal";
import "../../LanguageLearning/LessonDetails/lessonDetails.scss";
import { deckList } from "../helper";
import { useTranslation } from "react-i18next";

const DeckLearning = ({
	language,
	history,
	setSideMenu,
	match,
	deckDetails,
	updateField,
	changeActivity,
	skipActivity,
	isStudentMode,
	loading,
	getDeckDetails,
	updateDeckField,
	isRestart,
	enableCall,
	reset,
}) => {
	const { t } = useTranslation();
	const [deck, updateDeck] = useState("");
	const [decks] = useState({
		lesson: t("menu.lessondecks.singular"),
		preset: t("menu.presetdeck.singular"),
		custom: t("biblereading.sense.customdeck"),
		wordsNeedPractise: t("flashcarddeck.wordsthatneedpractice.name"),
	});

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const { isBibleReading } = getUserDetails().modules;
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateField]);

	useEffect(() => {
		updateDeck(match.params.deck);
		updateField("deckType", match.params.deck);
		return () => {
			updateField("deckType", "");
		};
	}, [match.params.deck, updateField]);

	useEffect(() => {
		const id = (match && match.params && match.params.id) || null;
		if (enableCall && id) {
			getDeckDetails(id, isRestart).then(() => {
				if (isRestart) {
					updateDeckField("isRestart", false);
				}
			});
		} else if (!enableCall) {
			updateDeckField("enableCall", true);
		}
		return () => {
			updateDeckField("deckDetails", null);
			updateField("sidePanel", {});
			updateField("points", null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match, getDeckDetails, updateField, updateDeckField]);

	 const showBadgeNotifications = (lessonDetails) => {
		if (lessonDetails.badge.badge) {
			NotificationManager.success(
				t("languagelearning.lessons.details.notification.badgechangedmsg", { badge: `${lessonDetails.badge.badge}` }),
				"",
				3000
			);
		}
		if (
			lessonDetails.badge.points &&
			lessonDetails.badge.points.length > 0 &&
			!lessonDetails.isLessonCompleted &&
			!lessonDetails.isLevelCompleted
		) {
			NotificationManager.success(
				t("languagelearning.lessons.details.notification.badgegainedmsg", {
					point: `${lessonDetails.badge.points[0].point}`,
					description: `${lessonDetails.badge.points[0].description}`,
				}),
				"",
				3000
			);
		}
	};
	useEffect(() => {
		if (deckDetails && deckDetails.badge) {
			showBadgeNotifications(deckDetails);
		}
	}, [deckDetails]);

	useEffect(() => {
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu:
				deckDetails && deck && decks[deck]
					? [
							{ title: decks[deck], path: `/home/${location[2]}/deck/${deck}` },
							{ title: deckDetails && deckDetails.name },
					  ]
					: [],
			language: location[2],
		});
	}, [history, setSideMenu, match, deck, decks, deckDetails]);

	const updateAcitivity = (action, id) => {
		updateField("loading", true);
		changeActivity(action, id ? id : deckDetails.activityId, true).then((res) => {
			updateField("loading", false);
			if (res.statusCode === 200) {
				if (skipActivity) {
					updateField("skipActivity", false);
				}
			} else {
				NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	return (
		<div className={`lesson-details-container ${language}`}>
			<CompletionModal
				deck={deck}
				deckDetails={deckDetails}
				updateAcitivity={updateAcitivity}
				updateDeckField={updateDeckField}
				reset={reset}
			/>
			<div className="d-flex flex-fill">
				{(!deckDetails || loading) && <Loader />}
				<SidePanel isStudentMode={isStudentMode} lessonDetails={deckDetails} />
				<div className="right">
					<Header
						head={`${deck === "lesson" ? t("helpers.utils.lesson") : ""}${deckDetails && deckDetails.name ? deckDetails.name : ""}`}
						enableLanguageSwitch={true}
						redirectionUrl={deck ? deckList[deck].rediectionUrl : ""}
					/>
					{deckDetails && (
						<div className="d-flex word-details-container row">
							{getMainView(deckDetails, updateAcitivity)}
							{getSubView(deckDetails, isStudentMode, updateAcitivity)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	language: state.sideMenu.language,
	deckDetails: state.deck.deckDetails,
	isRestart: state.deck.isRestart,
	enableCall: state.deck.enableCall,
	loading: state.studentLanguageLearning.loading,
	skipActivity: state.studentLanguageLearning.skipActivity,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	changeActivity,
	getDeckDetails,
	updateDeckField,
	reset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeckLearning));
