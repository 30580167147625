import fetchHandler from "../../utils/fetchResponseHandler";
import { SETTINGS } from "./types";

export const fetchAllCultures = () => {
	const fetchOptions = {
		url: `/localization/cultures/all`,
		method: "GET",
		actionType: SETTINGS.FETCH_ALL_CULTURES,
	};
	return fetchHandler(fetchOptions);
};

export const createCulture = (value) => {
	const fetchOptions = {
		url: `/localization/culture/create`,
		method: "POST",
		body: value,
		actionType: SETTINGS.CREATE_CULTURE,
		fileUpload: true,
	};
	return fetchHandler(fetchOptions);
};

export const bulkUpload = (id, modules, body) => {
	const fetchOptions = {
		url: `localization/resources/bulkupload?id=${id}${modules ? `&modules=${modules}` : ""}`,
		method: "POST",
		body: body,
		actionType: SETTINGS.BULK_UPLOAD,
		fileUpload: true,
	};
	return fetchHandler(fetchOptions);
};

export const deleteCulture = (value) => {
	const fetchOptions = {
		url: `/localization/culture/delete`,
		method: "DELETE",
		body: JSON.stringify(value),
		actionType: SETTINGS.DELETE_CULTURE,
	};
	return fetchHandler(fetchOptions);
};

export const getResource = (value) => {
	const fetchOptions = {
		url: `/localization/resources/all?Name=${value}`,
		method: "GET",
		actionType: SETTINGS.GET_RESOURCE,
	};
	return fetchHandler(fetchOptions);
};
