import React, { useEffect } from "react";
import { connect } from "react-redux";
import { campusAuthStatus } from "./actions";
import Loader from "../sharedComponents/Loader";
import { NotificationManager } from "react-notifications";

const CampusLogin = ({ history, location, campusAuthStatus }) => {
	useEffect(() => {
		const paramsArr = location.search
			.slice(1)
			.split("&")
			.map((el) => el.split("=")[1]);
		const [token, origin, language] = paramsArr;
		localStorage.removeItem("sessionId");
		localStorage.removeItem("auth");
		localStorage.removeItem("settings");
		localStorage.setItem(
			"auth",
			JSON.stringify({
				response: {
					token: token,
				},
			})
		);
		campusAuthStatus().then(({ statusCode, response }) => {
			if (statusCode === 200) history.push(`/home/${language}/Dashboard`);
			else {
				NotificationManager.error(response && response.message, "Error", 2000);
				history.push(`/login`);
			}
		});
	});

	return <div className="campus">{<Loader />}</div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	campusAuthStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampusLogin);
