import React from "react";
import Select from "react-select";
import moment from "moment";

export const renderColumn = (field, renderCell, getOptions) => {
  return {
    Header: () =>
      field.disableSort ? (
        <b className="col-header">{field.label}</b>
      ) : (
        <React.Fragment>
          <span className="sort-btn d-flex">
            <b className="col-header">{field.label}</b>
            <div className="d-flex flex-fill flex-column sort-icons">
              <i className="icon icon-drop-down-arrow rotate-dropdown" />
              <i className="icon icon-drop-down-arrow" />
            </div>
          </span>
        </React.Fragment>
      ),
    id: field.accessor,
    width: field.width,
    accessor: field.accessor,
    Cell: props => (
      <div data-label={field.label} className="table-cell">
        {renderCell(field, props)}
      </div>
    ),
    sortMethod:
      field.accessor === "caseNumber"
        ? numberSortMethod
        : field.accessor === "status"
        ? objectSortMethod
        : null,
    sortable: !field.disableSort,
    filterable: !field.disableFilter,
    Filter: ({ filter, onChange }) =>
      renderFilter(field, filter, onChange, getOptions),
    filterMethod: (filter, row) => renderFilterMethod(filter, row, field),
    className: field.className ? field.className : "",
    headerClassName: field.headerClassName ? field.headerClassName : ""
  };
};

const numberSortMethod = (a, b) => {
  let x = a.substr(2);
  let y = b.substr(2);
  if (x.length === y.length) {
    return x > y ? 1 : -1;
  }
  return x.length > y.length ? 1 : -1;
};

const objectSortMethod = (a, b) => {
  let x = a && a.description ? a.description.toLowerCase() : "";
  let y = b && b.description ? b.description.toLowerCase() : "";
  return x > y ? 1 : x < y ? -1 : 0;
};

const renderFilterMethod = (filter, row, field) => {
  switch (field.filter) {
    case "select":
      if (field.isMulti) {
        if (filter.value && filter.value.length > 0) {
          const isMatch = row[filter.id]
            ? filter.value.some(selected => {
                const fieldValue = String(row[filter.id]).toLowerCase();
                if (selected.label === "Both") {
                  const expectedValues = ["hebrew,greek", "greek,hebrew"];
                  return expectedValues.some(val => fieldValue.includes(val));
                } else {
                  return fieldValue.includes(selected.label.toLowerCase());
                }
              })
            : false;
          return isMatch;
        }
        return true;
      } else {
        return row[filter.id]
          ? filter.value
            ? `${
                field.filterField
                  ? String(row[filter.id][field.filterField])
                  : String(row[filter.id])
              }`.toLowerCase() === filter.value.label.toLowerCase()
            : true
          : false;
      }
    case "date":
      return row[filter.id]
        ? String(moment(row[filter.id]).format("DD MMM YYYY"))
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        : false;
    default:
      return row[filter.id]
        ? String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        : false;
  }
};

const renderFilter = (field, filter, onChange, getOptions) => {
  switch (field.filter) {
    case "select":
      return selectFilter(field, onChange, getOptions, filter);
    default:
      return defaultFilter(field, filter, onChange);
  }
};

const selectFilter = (field, onChange, getOptions, filter) => {
  return (
    <div className="">
      <Select
        value={filter ? filter.value : null}
        placeholder={field.label}
        options={getOptions(field)}
        isClearable
        className="react-select"
        classNamePrefix="react-select"
        isMulti={field.isMulti}
        onChange={onChange}
      />
    </div>
  );
};

const defaultFilter = (field, filter, onChange) => (
  <input
    type="text"
    className="form-control"
    placeholder={field.label}
    style={{
      width: "100%"
    }}
    value={filter ? filter.value : ""}
    onChange={event => onChange(event.target.value)}
    onKeyPress={event => {
      if (event.keyCode === 13 || event.which === 13) {
        event.preventDefault();
      }
    }}
  />
);
