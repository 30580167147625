import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import Sidemenu from "./components/Sidemenu";
import LanguageLearning from "./components/LanguageLearning";
import ImageGalleryListing from "./components/MediaGallery/ImageGalleryListing";
import UserProfile from "./components/UserProfile/index";
import VideoGallery from "./components/MediaGallery/videoGallery";
import AudioGallery from "./components/MediaGallery/audioGallery";
import Users from "./components/Users";
import EntryFailed from "./components/Submenu/EntryFailed";
import ChangePassword from "./components/Auth/changePassword";
import QA from "./components/QA";
import PageNotFound from "./components/pageNotFound";
import StudentlanguageLearning from "./components/Students/LanguageLearning";
import StudentQnA from "./components/Students/QnA/index";
import AlphabetLessons from "./components/Students/AlphabetLessons";
import LessonDeatils from "./components/Students/LanguageLearning/LessonDetails";
import FlashCardDeck from "./components/Students/flashCardDeck";
import DeckLearning from "./components/Students/flashCardDeck/deckLearning";
import BibleReading from "./components/Students/BibleReading";
import StudentHome from "./components/Students/home/studentHome";
import Messenger from "./components/sharedComponents/Messenger";
import Sync from "./components/SyncMedia";
import PlacementTest from "./components/Students/PlacementTest";
import FluencyDrills from "./components/Students/FluencyDrills";
import Details from "./components/Students/FluencyDrills/Details";
import Dashboard from "./components/Students/Dashboard";
import Reports from "./components/Students/Dashboard/Reports";
import FlashCardSettings from "./components/Students/flashCardDeck/Settings";
import AudioLibrary from "./components/Students/Dashboard/AudioLibrary";
import Planner from "./components/Students/Dashboard/PlannerReport";
import { reloadAfterTokenExpiry, getUserDetails } from "./utils/verifyUser";
import { accessBasedUsersList } from "./utils/routingHelper";
import "./App.scss";
import ReadingComprehension from "./components/Students/ReadingComprehension";
import ReadingComprehensionDetails from "./components/Students/ReadingComprehension/Details";
import Setting from "./components/Settings";
import { initGTM } from "./utils/gtmConfig";

export const ProtectedRoute = () => {
	const { User, Student, Manager, LearningAllowedUser, ReadingAllowedUser } = accessBasedUsersList;
	const isHome =
		window.location.pathname === "/home" ||
		window.location.pathname === "/campus" ||
		window.location.pathname === "/fuller-login";
	const isStudent = getUserDetails() && getUserDetails().role && getUserDetails().role.description === "Student";
	const biblingoUser = getUserDetails() && getUserDetails().origin === "Biblingo";
	const courseLanguage = getUserDetails() && getUserDetails().courseLanguage === 0 ? "Hebrew" : "Greek";

	useEffect(() => {
		reloadAfterTokenExpiry();
	}, []);
	initGTM();
	return (
		<React.Fragment>
			{!isHome && <Sidemenu />}
			{isStudent && <Messenger />}
			<div className={`${isHome ? "w-100" : "content-wrapper d-flex"} `}>
				<Switch>
					<Route
						exact
						path={["/home/:language/vocabulary", "/home/:language/grammar"]}
						component={Manager(LanguageLearning)}
					/>

					<Route exact path="/Media-Gallery/Images" component={Manager(ImageGalleryListing)} />
					<Route exact path="/Media-Gallery/Videos" component={Manager(VideoGallery)} />
					<Route exact path="/Media-Gallery/Audios" component={Manager(AudioGallery)} />
					<Route exact path="/Change-Password" component={User(ChangePassword)} />
					<Route
						exact
						path="/home/:language/Language-Learning"
						component={LearningAllowedUser(StudentlanguageLearning)}
					/>
					<Route
						path="/home/:language/Language-Learning/:lesson/:phase/:stage"
						component={LearningAllowedUser(LessonDeatils)}
					/>
					<Route exact path="/home/:language/Fluency-drills" component={LearningAllowedUser(FluencyDrills)} />
					<Route exact path={"/home/:language/Fluency-drill/:from/:id"} component={LearningAllowedUser(Details)} />
					<Route
						exact
						path="/home/:language/Reading-comprehension"
						component={LearningAllowedUser(ReadingComprehension)}
					/>
					<Route
						exact
						path="/home/:language/Reading-comprehension/:from/:id"
						component={LearningAllowedUser(ReadingComprehensionDetails)}
					/>

					<Route exact path="/home/:language/placement-test" component={Student(PlacementTest)} />
					<Route exact path="/home/:language/flashcard/settings/" component={FlashCardSettings} />
					<Route exact path="/home/:language/deck/:deck" component={User(FlashCardDeck)} />
					<Route exact path="/home/:language/deck-learning/:deck/:id" component={User(DeckLearning)} />
					<Route
						exact
						path={["/home/:language/bible-reading", "/home/:language/bible-reading/books"]}
						component={ReadingAllowedUser(BibleReading)}
					/>
					<Route
						exact
						path={["/home/:language/dashboard", "/home/:language/dashboard/:id"]}
						component={User(Dashboard)}
					/>
					<Route
						exact
						path={[
							"/home/:language/dashboard/AudioLibrary/:from",
							"/home/:language/dashboard/AudioLibrary/:from/:id",
						]}
						component={User(AudioLibrary)}
					/>
					<Route
						exact
						path={[
							"/home/:language/dashboard/Reports/:from/:report/",
							"/home/:language/dashboard/Reports/:from/:report/:id",
						]}
						component={User(Reports)}
					/>
					<Route exact path="/home/:language/dashboard/planner/:goals/" component={User(Planner)} />
					<Route exact path="/home/:language/qa" component={Student(StudentQnA)} />
					<Route exact path="/home/:language/alphabet-lessons" component={User(AlphabetLessons)} />
					<Route exact path="/qa" component={Manager(QA)} />
					<Route exact path="/Settings" component={Manager(Setting)} />
					<Route exact path="/sync" component={Manager(Sync)} />
					<Route exact path="/home/users/:userType" component={User(Users)} />
					<Route exact path="/Profile" component={User(UserProfile)} />
					<Route
						exact
						path="/home"
						component={getUserDetails().origin === "Biblingo" ? User(StudentHome) : User(PageNotFound)}
					/>
					<Route exact path="/entry-failed" component={Manager(EntryFailed)} />
					<Route exact path="/resetpassword" component={User(PageNotFound)} />
					<Redirect
						to={
							biblingoUser
								? isStudent
									? "/home"
									: "/Home/Greek/Vocabulary"
								: `/home/${courseLanguage}/dashboard`
						}
					/>
				</Switch>
			</div>
		</React.Fragment>
	);
};
