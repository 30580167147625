import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfoDescription from "../../../sharedComponents/infoDescription";

const DisplayReview = ({ displayReview, updateDisplayReview, clearFormTab, saveDisplayReview }) => {
	const { t } = useTranslation();
	const { language, defaultDisplayField, wordsPerDeck, initialStageOfReview, displayArchivedWords } = displayReview;
	const updateField = (field, value) => {
		updateDisplayReview(field, value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		saveDisplayReview(displayReview);
	};

	return (
		<div className="display-settings settings-tab">
			<Form>
				<Form.Row>
					<Col>
						<Form.Group controlId="defaultDisplayField">
							<Form.Label>{t("flashcarddeck.flashcardsettings.display&reviewtab.defaultdisplaysubtab.name")}</Form.Label>
							<Form.Control
								as="select"
								value={defaultDisplayField}
								onChange={(e) => updateField("defaultDisplayField", parseInt(e.target.value))}>
								<option></option>
								<option value={0}>{t("helpers.utils.gloss")}</option>
								<option value={1}>{t("helpers.utils.definition")}</option>
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="displayArchivedWords">
							<Form.Label>{t("flashcarddeck.flashcardsettings.display&reviewtab.displayarchived.name")}</Form.Label>
							<div>
								<Form.Check
									inline
									type="radio"
									label={t("helpers.utils.yes")}
									id="displayArchivedWords"
									name="displayArchivedWords"
									value={displayArchivedWords ? true : false}
									checked={displayArchivedWords ? true : false}
									onChange={(e) => updateField("displayArchivedWords", true)}
								/>
								<Form.Check
									inline
									type="radio"
									label={t("helpers.utils.no")}
									id="displayArchivedWords"
									name="displayArchivedWords"
									value={!displayArchivedWords ? true : false}
									checked={!displayArchivedWords ? true : false}
									onChange={(e) => updateField("displayArchivedWords", false)}
								/>
							</div>
						</Form.Group>
					</Col>
				</Form.Row>
				<Form.Row>
					<Col>
						<Form.Group controlId="initialStageOfReview">
							<Form.Label>{t("flashcarddeck.flashcardsettings.display&reviewtab.initialstagereviewsubtab.name")}</Form.Label>
							<Form.Control
								as="select"
								value={initialStageOfReview}
								onChange={(e) => {
									updateField("initialStageOfReview", parseInt(e.target.value));
								}}>
								<option></option>
								<option value={0}>
									{t("flashcarddeck.flashcardsettings.display&reviewtab.initialstagereviewsubtab.association")}
								</option>
								<option value={1}>
									{t("flashcarddeck.flashcardsettings.display&reviewtab.initialstagereviewsubtab.passiveeasy")}
								</option>
								<option value={2}>
									{t("flashcarddeck.flashcardsettings.display&reviewtab.initialstagereviewsubtab.passivehard")}
								</option>
								<option value={3}>
									{t("flashcarddeck.flashcardsettings.display&reviewtab.initialstagereviewsubtab.active")}
								</option>
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="wordsPerDeck">
							<Form.Label style={{ marginBottom: "0.5px" }}>
								{t("flashcarddeck.flashcardsettings.display&reviewtab.numberofwordsperdeck.name")}
								<InfoDescription
									position={"left-top"}
									description={t("flashcarddeck.flashcardsettings.display&reviewtab.numberofwordsperdeck.infobtn.value")}
								/>
							</Form.Label>
							<Form.Control
								max={25}
								type="number"
								placeholder=""
								value={wordsPerDeck}
								onChange={(e) => {
									if (e.target.value <= 25) updateField("wordsPerDeck", parseInt(e.target.value));
								}}
							/>
						</Form.Group>
					</Col>
				</Form.Row>
				<Form.Row className="flex-row-reverse">
					<Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
						{t("flashcarddeck.flashcardsettings.display&reviewtab.save")}
					</Button>
					<Button
						className="mr-2"
						variant="secondary"
						type="cancel"
						onClick={(e) => clearFormTab("displayReview", e)}>
						{t("helpers.utils.cancel")}
					</Button>
				</Form.Row>
			</Form>
		</div>
	);
};

export default DisplayReview;
