export function fieldUpdateReducer(state, action) {
	return {
		...state,
		...action,
	};
}

export const capitalize = (word) => {
	if (typeof word !== "string") {
		return "";
	}
	return word.charAt(0).toUpperCase() + word.slice(1);
};

export const camelcase = (word) => {
	if (typeof word !== "string") {
		return "";
	}
	return word.charAt(0).toLowerCase() + word.slice(1).replace(" ", "");
};

export const audioSpeedsList = [0.65, 1];

export const toggleAudioPlay = (audioId, toggleAudio, speed = 1) => {
	const player = document.getElementById(audioId);
	if (player.duration) {
		if (player.paused) {
			toggleAudio(true);
			player.playbackRate = speed;
			const playPromise = player.play();
			if (playPromise !== undefined) {
				playPromise.then().catch(() => {
					toggleAudio(false);
				});
			}
		} else {
			toggleAudio(false);
			player.pause();
		}
	}
};

export const languages = [
	{ label: "English", lng: "en" },
	{ label: "Spanish", lng: "es" },
	{ label: "French", lng: "fr" },
	{ label: "Russian", lng: "ru" },
	{ label: "Arabic", lng: "ar" },
];
