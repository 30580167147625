import { HOME } from "./types";
const initialState = { active: false, fileStatus: null };

export default function(state = initialState, action) {
  switch (action.type) {
    case HOME.UPDATE_FIELD:
      return {
        ...state,
        [action.field]: action.val
      };
    case HOME.GET_FILE_STATUS:
      return {
        ...state,
        fileStatus: action.payload.response
      };
    default:
      return state;
  }
}
