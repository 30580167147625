import React, { useState } from "react";
import { connect } from "react-redux";
import { Card, Col, Row, Dropdown } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { LessonFields, lessonSenses } from "./helper";
import { changeType } from "../Submenu/actions";
import PlaceHolderImg from "../../assets/images/placeholder-image.png";
import LanguageLearning from "./LanguageLearning";
import ImageSlider from "../sharedComponents/ImageSlider";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

const Lesson = ({ lessonDetails, menu, lessonNumber, changeType, language }) => {
	const [lessonId, setlessonId] = useState("");
	const [audio, setAudio] = useState("");
	const [show, showDropdown] = useState("");
	const [showImg, showModal] = useState(false);
	const [senseImages, setSenseImages] = useState([]);
	const {t} = useTranslation();

	const handleDropItem = (lesson) => {
		setlessonId(lessonId === lesson.id ? "" : lesson.id);
	};

	const renderLessonComponent = (lesson) => {
		const primarySense = lesson.senses.filter((lessn) => lessn.isPrimary);
		const lessonArray = primarySense.length > 0 ? primarySense[0] : {};
		return (
			<React.Fragment>
				<Row className="d-flex ">
					{lessonArray &&
						LessonFields.map(
							(fields, index) =>
								(!fields.language || fields.language === language) && (
									<Col md={6} lg={4} className="flex-direction-column mb-3" key={index}>
										<div className="dict-head mt-2">{fields.field}</div>
										<div className={`dict-detail ${fields.class}`}>
											{lesson[fields.value] ? lesson[fields.value] : "--"}
										</div>
									</Col>
								)
						)}
				</Row>
				<Row className="d-flex">
					<Col md={12} className="mb-1 mt-1">
						Sense
					</Col>
					{lessonArray &&
						lessonSenses.map(
							(fields, index) =>
								(!fields.language || fields.language === language) && (
									<Col md={4} className="flex-direction-column" key={index}>
										<div className="dict-head mt-2">{fields.field}</div>
										<div
											className={`dict-detail ${
												fields.field === "Definition"
													? language === "Hebrew"
														? "Hebrew-font"
														: "Greek-font"
													: ""
											}`}>
											{lessonArray[fields.value] ? lessonArray[fields.value] : "--"}
										</div>
									</Col>
								)
						)}
				</Row>
				{lessonArray.examples && renderExampleComponent(lessonArray.examples)}
			</React.Fragment>
		);
	};

	const renderExampleComponent = (examples) => {
		return (
			<span>
				{examples.map((example, idx) => (
					<Row key={idx}>
						<Col md={12} className="mb-3 pl-3 mt-2 flex-direction-column">
							<div className="dict-head">Example {idx + 1}</div>
							<div className={`dict-detail ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
								{example.reference} ( {example.verse} )
							</div>
						</Col>
					</Row>
				))}
			</span>
		);
	};

	const handleToggle = (e, lesson) => {
		stopAllAudio();
		showDropdown(e ? lesson.id : "");
		setAudio("");
	};

	const togglePlay = (pronunciation) => {
		const audioData = pronunciation.fileName;
		if (pronunciation.filePath) {
			const player = document.getElementById(audioData);
			if (player.paused) {
				stopAllAudio();
				setAudio(audioData);
				player.play();
			} else {
				setAudio("");
				player.pause();
				stopAllAudio();
			}
		} else {
			!(NotificationManager.listNotify && NotificationManager.listNotify.length > 0) &&
				NotificationManager.error(t("helpers.utils.notification.audionotavailablemsg"), "", 3000);
		}
	};

	const stopAllAudio = () => {
		const sounds = document.getElementsByTagName("audio");
		for (let i = 0; i < sounds.length; i++) {
			sounds[i].pause();
		}
	};

	const renderVoiceComponent = (lesson) => {
		const primarySense = lesson.senses.filter((lessn) => lessn.isPrimary);
		const lessonArray = primarySense.length > 0 ? primarySense[0] : {};
		return (
			lessonArray.pronunciations &&
			lessonArray.pronunciations.length > 0 && (
				<Dropdown drop="right" onToggle={(e) => handleToggle(e, lesson)} show={show === lesson.id} className="mr-2">
					<Dropdown.Toggle className="audio-dropdown position-relative lesson-audio">
						<div className="position-absolute audio-count">{lessonArray.pronunciations.length}</div>
						<i className="icon-audio" />
					</Dropdown.Toggle>
					<Dropdown.Menu className="list-audio">
						<ul className="pl-1">
							{lessonArray.pronunciations.map((pronunciation, index) => (
								<li key={index}>
									<div className="change-icon  d-flex">
										<span className="play-icon ">
											<i
												className={
													audio === pronunciation.fileName
														? "icon-pause-button pause-font"
														: "icon-Group-15"
												}
												onClick={() => togglePlay(pronunciation)}></i>
										</span>
										<span className="ml-2 audio-name">{pronunciation.fileName}</span>
										<audio
											src={pronunciation.filePath}
											id={pronunciation.fileName}
											className="d-none"
											onEnded={() => setAudio("")}
										/>
									</div>
								</li>
							))}
						</ul>
					</Dropdown.Menu>
				</Dropdown>
			)
		);
	};

	const handleImageCount = (lesson) => {
		setSenseImages(lesson.illustrations);
		handleClose();
	};

	const handleClose = () => {
		showModal(!showImg);
	};

	const renderImageComponent = (lesson) => {
		const primarySense = lesson.senses.filter((lessn) => lessn.isPrimary);
		const lessonArray = primarySense.length > 0 ? primarySense[0] : {};

		return (
			<div className="lesson-img-wrapper position-relative">
				<img
					src={
						lessonArray &&
						lessonArray.illustrations &&
						lessonArray.illustrations.length > 0 &&
						lessonArray.illustrations[0].filePath
							? lessonArray.illustrations[0].filePath
							: PlaceHolderImg
					}
					alt="NoImage"
				/>
				{lessonArray.illustrations &&
					lessonArray.illustrations.length > 0 &&
					lessonArray.illustrations.map((item, index) => {
						return item.filePath ? (
							<div className="img-count" key={index} onClick={() => handleImageCount(lessonArray)}>
								{lessonArray.illustrations.length}
							</div>
						) : null;
					})}
				<p className="d-block d-sm-none ml-3">
					<b>{lesson.text}</b>
					<br />
					<span>{getImageName(lesson)}</span>
				</p>
			</div>
		);
	};

	const getImageName = (lesson) => {
		const primarySense = lesson.senses.filter((lessn) => lessn.isPrimary);
		const lessonArray = primarySense.length > 0 ? primarySense[0] : {};

		return lessonArray.illustrations && lessonArray.illustrations.length > 0 ? lessonArray.illustrations[0].fileName : "--";
	};

	const getImageFileName = (lesson) => {
		const primarySense = lesson.senses.filter((lessn) => lessn.isPrimary);
		const lessonArray = primarySense.length > 0 ? primarySense[0] : {};

		return lessonArray.illustrations && lessonArray.illustrations.length > 0 ? lessonArray.illustrations[0].caption : "--";
	};

	return (
		<span>
			<h1 className="header-topic ml-4 font-weight-bold">{t("languagelearning.lessons.details.completedmodal.lessoncompleted",{lesson:lessonNumber})}</h1>
			{menu[0].title === "Language Learning" ? (
				<LanguageLearning />
			) : (
				lessonDetails &&
				lessonDetails.length > 0 &&
				lessonDetails.map((lesson, index) => (
					<Card className="card-lesson" key={index}>
						<Card.Body>
							<div className="d-flex lesson-direction">
								<div className="lesson-img">{renderImageComponent(lesson)}</div>
								<div className="ml-4 w-100">
									<div className="d-flex justify-content-between mb-2">
										<div className="w-100 card-head	d-none d-sm-block">
											<Card.Title
												className={`mb-1 ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
												{lesson.text}
											</Card.Title>
											<label className="label-img">
												<span data-tip={getImageFileName(lesson)}>{getImageName(lesson)}</span>
											</label>
											<ReactTooltip />
										</div>
										<div className="d-flex lesson-voice">
											{renderVoiceComponent(lesson)}
											<span className="dropdown-icon" onClick={() => handleDropItem(lesson)}>
												<i
													className={
														lesson.id === lessonId
															? "icon-down down-font"
															: "icon icon-angle-arrow-down"
													}></i>
											</span>
										</div>
									</div>
									<div className="d-flex flex-wrap">
										{lessonId === lesson.id ? (
											<div className="w-100  card-border fadeIn">{renderLessonComponent(lesson)}</div>
										) : (
											lesson.tags &&
											lesson.tags.map((tag, i) => (
												<div key={i} className="m-1 tag-view">
													{tag}
												</div>
											))
										)}
									</div>
								</div>
							</div>
						</Card.Body>
					</Card>
				))
			)}

			<ImageSlider senseImages={senseImages} handleClose={handleClose} showImg={showImg} />
		</span>
	);
};

const mapStateToProps = (state) => ({
	lessonDetails: state.subMenu.lessonDetails,
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
	lessonNumber: state.subMenu.lessonNumber,
});

const mapDispatchToProps = {
	changeType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Lesson);
