import { STUDENT_LANGUAGE_LEARNING } from "./types";
const initialState = {
	active: false,
	isStudentMode: false,
	showPronunciationModal: false,
	sidePanel: {},
	lessonDetails: null,
	prevLesson: {},
	loading: false,
	disableNext: false,
	skipActivity: false,
	deckType: "",
	audioSpeedList: [],
	isSidePanelCollapsed: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case STUDENT_LANGUAGE_LEARNING.UPDATE_FIELD:
			// Fix for lessonDetails going null when phase completed
			if (action.field === "lessonDetails" && action.val === null) {
				return {
					...state,
					prevLesson: state.lessonDetails,
					[action.field]: action.val,
				};
			}
			return {
				...state,
				[action.field]: action.val,
			};
		case STUDENT_LANGUAGE_LEARNING.GET_SIDE_PANEL:
			return {
				...state,
				sidePanel: action.payload.response,
			};
		case STUDENT_LANGUAGE_LEARNING.GET_LESSON_DETAILS:
		case STUDENT_LANGUAGE_LEARNING.GET_STAGE_DETAILS:
			return {
				...state,
				lessonDetails: action.payload.response,
			};
		case STUDENT_LANGUAGE_LEARNING.CHANGE_ACTIVITY:
			const lessonData =
				action.payload.response &&
				(action.payload.response.isCompleted ||
					action.payload.response.isLessonCompleted ||
					action.payload.response.isLevelCompleted ||
					action.payload.response.isTopicCompleted ||
					action.payload.response.isPhaseCompleted ||
					action.payload.response.isSubLessonCompleted)
					? {
							...state.lessonDetails,
							activityId: action.payload.response.activityId,
							lessonId: action.payload.response.lessonId,
							lessonNumber: action.payload.response.lessonNumber,
							badge: action.payload.response.badge,
							isCompleted: action.payload.response.isCompleted,
							isLessonCompleted: action.payload.response.isLessonCompleted,
							isLevelCompleted: action.payload.response.isLevelCompleted,
							isTopicCompleted: action.payload.response.isTopicCompleted,
							isPhaseCompleted: action.payload.response.isPhaseCompleted,
							isSubLessonCompleted: action.payload.response.isSubLessonCompleted,
							isLevelEnd: action.payload.response.isLevelEnd,
							nextLessonId: action.payload.response.nextLessonId,
					  }
					: action.payload.response;
			return {
				...state,
				lessonDetails: lessonData,
			};
		case STUDENT_LANGUAGE_LEARNING.SET_AUDIO_SPEED:
			return {
				...state,
				audioSpeedList: action.audioSpeedList,
			};
		case STUDENT_LANGUAGE_LEARNING.HANDLE_SIDEPANEL:
			return {
				...state,
				isSidePanelCollapsed: !state.isSidePanelCollapsed,
			};
		default:
			return state;
	}
}
