import React from "react";

import PageNotFound from "../components/pageNotFound";
import { getUserDetails } from "./verifyUser";

const Authorization = (allowedRoles) => (WrappedComponent) => () => {
  if (allowedRoles.includes(getUserDetails().role.description)) {
    return <WrappedComponent />;
  } else {
    return <PageNotFound />;
  }
};

const moduleAuthorization = (isAllowed) => (WrappedComponent) => () => {
  if (getUserDetails().modules[isAllowed]) {
    return <WrappedComponent />;
  } else {
    return <PageNotFound />;
  }
};

export const accessBasedUsersList = {
  User: Authorization([
    "Student",
    "Administrator",
    "Internal Teacher",
    "External Teacher",
  ]),
  Manager: Authorization([
    "Administrator",
    "Internal Teacher",
    "External Teacher",
  ]),
  Student: Authorization(["Student"]),
  deckAllowedUser: moduleAuthorization("isFlashCardDeck"),
  LearningAllowedUser: moduleAuthorization("isLanguageLearning"),
  ReadingAllowedUser: moduleAuthorization("isBibleReading"),
};
