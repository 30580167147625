import { STUDENT_QA } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";

export const getAllQuestions = id => {
  const fetchOptions = {
    url: `question/all?AskedByUserId=${id}`,
    method: "GET",
    actionType: STUDENT_QA.GET_ALL_QUESTIONS,
    secure: true
  };

  return fetchHandler(fetchOptions);
};

export const getAllAnswers = id => {
  const fetchOptions = {
    url: `answer/all?QuestionId=${id}&IsPagingEnabled=false&SortBy=IsVerified&SortDirection=Desc`,
    method: "GET",
    actionType: STUDENT_QA.GET_ALL_ANSWERS,
    secure: true
  };
  return fetchHandler(fetchOptions);
};
