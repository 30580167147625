import { SYNC } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export const syncContent = (url) => {
	const fetchOptions = {
		url,
		method: "POST",
		actionType: SYNC.SYNC_CONTENT,
	};

	return fetchHandler(fetchOptions);
};

export const assetsMap = (path, FileType) => {
	const fetchOptions = {
		url: `assets/map?IsBackgroundProcess=false&FileType=${FileType ? 1 : 0}`,
		method: "PUT",
		actionType: SYNC.ASSET_MAP,
	};
	return fetchHandler(fetchOptions);
};

export const lessonMap = (path) => {
	const fetchOptions = {
		url: path,
		method: "PUT",
		actionType: SYNC.TOPIC_MAP,
	};
	return fetchHandler(fetchOptions);
};
