import React, { useEffect, useState, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
const ThumbnailList = ({ lessonDetails, updateAcitivity }) => {
  const {t}=useTranslation();
  const [thumbnailList, setThumbnailList] = useState([]);
  const thumbnailRef = useRef(null);

  useEffect(() => {
    if (
      lessonDetails &&
      lessonDetails.activity &&
      lessonDetails.activity.thumbnails &&
      lessonDetails.activity.thumbnails.length > 0
    ) {
      const list = lessonDetails.activity.thumbnails.map((file) => ({
        url: file.filePath,
        label: file.label,
        status: file.status,
        isCurrent: file.isCurrent,
        activityId: file.activityId,
        isNavigatable: file.isNavigatable,
      }));
      setThumbnailList(list);
    }
  }, [lessonDetails]);

  useEffect(() => {
    if (thumbnailRef && thumbnailRef.current && thumbnailList.length > 0) {
      thumbnailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [thumbnailRef, thumbnailList]);

  return (
    <div className="thumbnail-container thumbnail-width relative">
      {lessonDetails && (
        <label className="progress-count">
          {t("helpers.utils.learning.progress",{currentlesson:lessonDetails.question ,totallessons:lessonDetails.total})}
        </label>
      )}
      <Scrollbars renderTrackHorizontal={() => <div className="d-none" />}>
        <div className="thumbnail-list">
          <div className="row m-0">
            {thumbnailList.length > 0 &&
              thumbnailList.map((file, index) => (
                <div
                  key={`${file.label}${index}`}
                  className={`word-data p-1 col-lg-6  col-md-6 col-xs-6 col-sm-3 ${
                    file.isCurrent ? "current" : ""
                  } ${file.isNavigatable ? "pointer" : ""}`}
                  {...(file.isCurrent && { ref: thumbnailRef })}
                  {...(file.isNavigatable && {
                    onClick: () => updateAcitivity("get", file.activityId),
                  })}
                >
                  {file.status === 2 && (
                    <span className="icon-tick-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </span>
                  )}
                  {file.url ? (
                    <img className="thumbnail" src={file.url} alt="" />
                  ) : (
                    <span className="thumbnail file-label">{file.label}</span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default ThumbnailList;
