import React from "react";
import { Form, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

const CheckBox = ({ value, label, onChange, checked }) => (
	<div className="mr-3 d-flex justify-content-between align-items-center mb-2">
		<input type="checkbox" value={value} onChange={onChange} checked={checked ? true : false} className="mr-2 daysOfWeek" />
		<label className="mb-0"> {label}</label>
	</div>
);

const Create = ({ items, onChange }) => {
	const { t } = useTranslation();
	const selectComponent = (field, key) => (
		<React.Fragment key={key}>
			<Col xs={field.size}>
				<Form.Group controlId={`ControlSelect-${key}`}>
					<Form.Label>{field.label}</Form.Label>
					<Select
						placeholder=""
						onChange={(value) => onChange(field.key, value)}
						options={field.options || []}
						isMulti={field.multiple}
						value={field.value}
					/>
				</Form.Group>
			</Col>
			{field.unit && (
				<Col xs={field.unitSize}>
					<div style={{ marginTop: 38, fontSize: 15 }}>{field.unit}</div>
				</Col>
			)}
		</React.Fragment>
	);

	const checkBoxList = (field, key) => (
		<Col xs={field.size} key={key}>
			<Form.Group id="formGridCheckbox">
				<Form.Label>{field.label}</Form.Label>
				<div className="checkbox-group">
					<CheckBox value={1} label={t("dashboard.planner.newplan.mon")} checked={field.value[1]} onChange={() => onChange(field.key, 1)} />
					<CheckBox value={2} label={t("dashboard.planner.newplan.tue")} checked={field.value[2]} onChange={() => onChange(field.key, 2)} />
					<CheckBox value={3} label={t("dashboard.planner.newplan.wed")} checked={field.value[3]} onChange={() => onChange(field.key, 3)} />
					<CheckBox value={4} label={t("dashboard.planner.newplan.thu")} checked={field.value[4]} onChange={() => onChange(field.key, 4)} />
					<CheckBox value={5} label={t("dashboard.planner.newplan.fri")} checked={field.value[5]} onChange={() => onChange(field.key, 5)} />
					<CheckBox value={6} label={t("dashboard.planner.newplan.sat")} checked={field.value[6]} onChange={() => onChange(field.key, 6)} />
					<CheckBox value={0} label={t("dashboard.planner.newplan.sun")} checked={field.value[0]} onChange={() => onChange(field.key, 0)} />
				</div>
			</Form.Group>
		</Col>
	);

	const dateComponent = (field, key) => (
		<Col xs={field.size} key={key}>
			<Form.Label>{field.label}</Form.Label>
			<DatePicker
				selected={field.value}
				onChange={(date) => onChange(field.key, date)}
				className="form-control"
				minDate={moment().toDate()}
			/>
		</Col>
	);

	const textComponent = (field, key) => (
		<React.Fragment key={key}>
			<Col xs={field.size}>
				<Form.Group controlId={`ControlText-${key}`}>
					<Form.Label>{field.label}</Form.Label>
					<Form.Control type="text" value={field.value} onChange={(e) => onChange(field.key, e.target.value)} />
				</Form.Group>
			</Col>
			{field.unit && (
				<Col xs={field.unitSize}>
					<div style={{ marginTop: 38, fontSize: 15 }}>{field.unit}</div>
				</Col>
			)}
		</React.Fragment>
	);

	const numberComponent = (field, key) => {
		return (
			<React.Fragment key={field.label}>
				<Col xs={field.size}>
					<Form.Group controlId={`ControlText-${key}`}>
						{field.label && field.label.length ? (
							<Form.Label>{field.label}</Form.Label>
						) : (
							<Form.Label>&nbsp;</Form.Label>
						)}
						<Form.Control
							as="input"
							type="number"
							value={field.value}
							max={field.options && field.options.length}
							onKeyUp={(e) => (e.target.value = Math.floor(e.target.value))}
							onChange={(e) => {
								if (field.options && e.target.value <= field.options.length) {
									onChange(field.key, Math.floor(e.target.value));
								} else {
									onChange(field.key, Math.floor(e.target.value), field.module);
								}
							}}
						/>
					</Form.Group>
				</Col>
				{field.unit && (
					<Col xs={field.unitSize}>
						<div style={{ marginTop: 38, fontSize: 15 }}>{field.unit}</div>
					</Col>
				)}
			</React.Fragment>
		);
	};

	return (
		<>
			{items.map((el, idx) => {
				switch (el.type) {
					case "select":
						return selectComponent(el, idx);
					case "checkbox":
						return checkBoxList(el, idx);
					case "datePicker":
						return dateComponent(el, idx);
					case "text":
						return textComponent(el, idx);
					case "number":
						return numberComponent(el, idx);
					default:
						return null;
				}
			})}
		</>
	);
};

export default Create;
