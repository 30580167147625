import { PLACEMENT_TEST } from "./types";

const initialState = {
	testDetails: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case PLACEMENT_TEST.GET_TEST_DETAILS:
			return { ...state, testDetails: action.payload.response };
		case PLACEMENT_TEST.CHANGE_ACTIVITY:
			const date =
				action.payload.response && (action.payload.response.isLessonCompleted || action.payload.response.isTestPassed)
					? {
							...state.testDetails,
							activityId: action.payload.response.activityId,
							lessonId: action.payload.response.lessonId,
							badge: action.payload.response.badge,
							isCompleted: action.payload.response.isCompleted,
							isLessonCompleted: action.payload.response.isLessonCompleted,
							isTestPassed: action.payload.response.isTestPassed,
					  }
					: action.payload.response;
			return {
				...state,
				testDetails: date,
			};
		case PLACEMENT_TEST.CLEAR_ON_UNMOUNT:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
