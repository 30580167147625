import moment from "moment";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../App.scss";
import { getUserDetails } from "../../utils/verifyUser";
import Header from "../header";
import { setSideMenu } from "../Sidemenu/actions";
import { updateField as updateStudentMode } from "../Students/LanguageLearning/actions";
import { fetchUserDetails, getALlCultures, getClassroomData, updateField } from "../Users/actions";
import ClassRooms from "./classRooms";
import "./UserProfile.scss";

const UserProfile = ({
	setSideMenu,
	fetchUserDetails,
	updateField,
	updateStudentMode,
	history,
	menu,
	initialValues,
	isStudentMode,
	getClassroomData,
	getALlCultures,
	cultures,
	match,
}) => {
	const { i18n, t } = useTranslation();
	const userDetails = getUserDetails();
	const userId = userDetails && userDetails.id;
	const userRole = userDetails.role.description;
	const languages = initialValues && initialValues.languages;
	const i18nextLng = localStorage.getItem("i18nextLng");

	const handleLangChange = (data) => {
		i18n.changeLanguage(data.name);
		localStorage.setItem("i18nextLng", data.name);
		localStorage.setItem("i18nextLng-student", data.name);
		NotificationManager.success(t("header.myprofile.languagechange", { language: `${data.displayName}` }), "", 3000);
		switch (getUserDetails().origin) {
			case "Biblingo":
				history.push(`/home`);
				break;
			case "Fuller":
				history.push(`/dashboard`);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		setSideMenu({
			menu: [t("home.name"), t("header.myprofile.name")],
			language: "",
		});
	}, [history, setSideMenu, t]);

	useEffect(() => {
		const url = userRole === "Student" ? "getforstudent" : "getforteacher";
		getClassroomData(url);
	}, [userRole, getClassroomData]);

	useEffect(() => {
		if (sessionStorage.getItem("isStudentMode") && !isStudentMode) {
			updateStudentMode("isStudentMode", true);
		} else if (isStudentMode && !sessionStorage.getItem("isStudentMode")) {
			sessionStorage.setItem("isStudentMode", true);
		}
		return () => {
			sessionStorage.removeItem("isStudentMode");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isStudentMode]);

	useEffect(() => {
		if (userId) {
			userRole === "Student" ? fetchUserDetails(userId, "Student") : fetchUserDetails(userId);
		}
	}, [userId, userRole, fetchUserDetails]);

	useEffect(() => {
		if (userRole === "Student") {
			getALlCultures();
		}
	}, [getALlCultures, userRole]);

	const renderInternalUserDetails =
		languages &&
		languages.length > 0 &&
		languages.map((item) => {
			let lang =
				item.language && item.language.description && item.language.description === "Hebrew"
					? t("header.hebrew")
					: t("header.greek");
			return [
				{
					name: t("header.myprofile.main.yearsstudyinglang", { language: `${lang}` }),
					value: item.experienceInSpeaking,
				},
				{
					name: t("header.myprofile.main.yearsteaching", { language: `${lang}` }),
					value: item.experienceInTeaching,
				},
			];
		});

	const renderLanguageTeaching = (
		<div className="d-flex justify-content-start mb-4 col-lg-6 col-xs-12 flex-column p-0">
			<label className="name mr-4">Which language you are teaching?</label>
			{languages && languages.length > 1 ? (
				<label className="values">Both</label>
			) : (
				languages &&
				languages.map((item, index) => (
					<label className="values" key={index}>
						{item.language.description}
					</label>
				))
			)}
		</div>
	);

	const renderStudentDetails =
		languages &&
		languages.length > 0 &&
		languages.map((item) => {
			let lang =
				item.language && item.language.description && item.language.description === "Hebrew"
					? t("header.hebrew")
					: t("header.greek");
			return [
				{
					name: t("header.myprofile.main.yearsstudyinglang", { language: `${lang}` }),
					value: item.experienceInSpeaking,
				},
			];
		});

	const renderPronounciationTypes =
		languages &&
		languages.length > 0 &&
		languages.map((item) => {
			if (languages.length > 1) {
				const hebrewLanguage = languages.find((lang) => lang.language.description === "Hebrew");
				const greekLanguage = languages.find((lang) => lang.language.description === "Greek");
				return [
					{ name: t("header.myprofile.main.subscribedlanguages"), value: "Both" },
					{
						name: t("header.myprofile.main.hebrewpronunciationtype"),
						value: hebrewLanguage.hebrewPronunciation ? hebrewLanguage.hebrewPronunciation.description : "",
					},
					{
						name: t("header.myprofile.main.greekpronunciationtype"),
						value: greekLanguage.greekPronunciation ? greekLanguage.greekPronunciation.description : "",
					},
				];
			}
			return [
				{
					name: t("header.myprofile.main.subscribedlanguages"),
					value: item.language.description,
				},
				{
					name:
						item.language.description === "Greek"
							? t("header.myprofile.main.greekpronunciationtype")
							: t("header.myprofile.main.hebrewpronunciationtype"),
					value:
						item.language.description === "Greek"
							? item.greekPronunciation
								? item.greekPronunciation.description
								: ""
							: item.hebrewPronunciation
							? item.hebrewPronunciation.description
							: "",
				},
			];
		});

	const pronounciationTypes = () => {
		return (
			renderPronounciationTypes &&
			renderPronounciationTypes[0].map((item, index) => (
				<div key={index} className="d-flex justify-content-start mb-4 col-lg-6 col-xs-12 flex-column p-0">
					<label className="name mr-4">{item.name}</label>
					<label className="values">{item.value ? item.value : "-"}</label>
				</div>
			))
		);
	};

	const learningReason = (
		<div className="d-flex justify-content-start mb-4 col-lg-6 col-xs-12 flex-column p-0">
			<label className="name mr-4"> {t("header.myprofile.main.whyareyoustudying")}</label>
			<label className="values">
				{initialValues && initialValues.learningReason && initialValues.learningReason.reason}
			</label>
		</div>
	);

	const renderDate = (label, value) => (
		<p className="date-container">
			<label className="date-title">{label}</label>
			<label className="date-date ">{moment(value).format("DD/MM/YYYY")}</label>
		</p>
	);

	const planDetails = (plan) => (
		<div className="user-plan-container">
			<div className="d-flex flex-column">
				<label className="plan-name">{t("header.myprofile.plan.name")}</label>
				<p className="plan-title ">{plan.languageSubscribed}</p>
				<label className="plan-type ">{plan.subscriptionPlan}</label>
				<label className="plan-duration mb-4">{plan.subscriptionType}</label>
				{plan.startDate && renderDate(t("header.myprofile.main.purchasedate"), plan.startDate)}
				{plan.expiry && renderDate(t("header.myprofile.main.expiresat"), plan.expiry)}
			</div>
			{userDetails.origin === "Biblingo" && (
				<button type="button" className="btn btn-primary save-btn">
					<a
						href={
							plan.subscriptionPlan === "Free"
								? "https://biblingo.org/pricing/"
								: "https://biblingo.org/manage-account/"
						}
						target="_blank"
						rel="noopener noreferrer">
						{t("header.myprofile.plan.sub2.updateplan")}
					</a>
				</button>
			)}
		</div>
	);

	const userProfileDetails = [
		{ name: t("header.myprofile.myprofileedit.main.firstname"), value: initialValues && initialValues.firstName },
		{ name: t("header.myprofile.main.lastname"), value: initialValues && initialValues.lastName },
		{
			name: t("header.myprofile.main.email"),
			value: initialValues && initialValues.email,
			disabled: true,
		},
		{
			name: "Role",
			value: initialValues && initialValues.role && initialValues.role.description,
		},

		{
			name: t("header.myprofile.main.country"),
			value: initialValues && initialValues.country && initialValues.country.name,
		},
		{
			name: t("header.myprofile.main.contactnumber"),
			value: initialValues && initialValues.phoneNumber,
		},
		{
			name: t("header.myprofile.main.universityororgaffiliation"),
			value: initialValues && initialValues.universityOrOrganization,
		},
		{
			name: t("header.myprofile.main.nativelanguagespoken"),
			value: initialValues && initialValues.nativeLanguage,
		},
		{
			name: t("header.myprofile.myprofileedit.main.otherlanguagesspokenfluently"),
			value: initialValues && initialValues.otherLanguages,
		},
	];

	const editUser = () => {
		updateField("addUser", true);
		updateField("isEdit", true);
		updateField("editProfile", true);
		history.push(`/home/users/${userRole === "Student" ? "student" : "internaluser"}`);
	};

	const renderUserData = () =>
		userRole === "Student" ? (
			<React.Fragment>
				{learningReason}
				{pronounciationTypes()}
				{renderStudentDetails &&
					renderStudentDetails.map((item) =>
						item.map((i, index) => (
							<div className="d-flex justify-content-start mb-4 col-lg-6 col-xs-12 flex-column p-0" key={index}>
								<label className="name mr-4">{i.name}</label>
								<label className="values">{i.value ? i.value : "- "}</label>
							</div>
						))
					)}
			</React.Fragment>
		) : (
			<React.Fragment>
				{renderLanguageTeaching}
				{renderInternalUserDetails &&
					renderInternalUserDetails.map((item) =>
						item.map((i, index) => (
							<div className="d-flex justify-content-start mb-4 col-lg-6 col-xs-12 flex-column p-0" key={index}>
								<label className="name mr-4">{i.name}</label>
								<label className="values">{i.value ? i.value : "- "}</label>
							</div>
						))
					)}
				{isStudentMode && pronounciationTypes()}
			</React.Fragment>
		);

	return (
		<div className="empty  users-container profile">
			<Header menu={menu} head={t("header.myprofile.name")} history={history} />
			<div className="bg-settings remove-pointer-event"></div>
			<div className="flex-container">
				<div className="flex-child main">
					<div className="user-form-container position-relative mb-0">
						<div className="user-profile-container d-flex flex-wrap">
							<button
								className="btn rounded-circle d-flex justify-content-center align-items-center"
								onClick={editUser}>
								<i className="icon icon-write1"></i>
							</button>
							{userProfileDetails &&
								userProfileDetails.map(
									(item, index) =>
										!(userRole === "Student" && item.name === "Role") && (
											<div
												className="d-flex justify-content-start mb-4 col-lg-6 col-xs-12 flex-column p-0"
												key={index}>
												<label className="name mr-4">{item.name}</label>
												<label className="values">{item.value ? item.value : "- "}</label>
											</div>
										)
								)}
							{renderUserData()}
						</div>
					</div>
					{(userRole === "Student" || userRole === "External Teacher") && (
						<ClassRooms role={userRole} user={initialValues} />
					)}
				</div>

				<div className="flex-child remaining">
					<div className="col">
						{userRole === "Student" &&
							initialValues &&
							initialValues.subscriptionDetails &&
							initialValues.subscriptionDetails.subscriptionPlan &&
							planDetails(initialValues.subscriptionDetails)}

						{["Biblingo", "Fuller"].includes(userDetails.origin) && userRole === "Student" && (
							<div>
								<Card className="choose-language-container">
									<div>
										<span className="icon-container-left">
											{" "}
											<i className="icon-languages" aria-hidden="true"></i>{" "}
										</span>
										<span className="choose-language-label">
											{" "}
											{t("header.myprofile.plan.sub2.selectlanguage")}
										</span>
										<span className="icon-container-right">
											<i className="icon-angle-arrow-down" aria-hidden="true"></i>
										</span>
									</div>
								</Card>

								<Card className="culture-container">
									<Card.Body>
										<ListGroup className="list-group-flush">
											{cultures.map((item, index) => (
												<ListGroup.Item
													key={index}
													action
													variant="light"
													as="li"
													className="culture-list"
													onClick={() => handleLangChange(item)}>
													<div className="culture-label row">
														<div>
															<span
																className={
																	i18nextLng && i18nextLng === item.name
																		? "language-icon--fixed-selected"
																		: "language-icon--fixed"
																}>
																<small className="language-badge">
																	{item.displayName.charAt(0)}
																</small>
															</span>
														</div>
														<div
															className={
																i18nextLng && i18nextLng === item.name
																	? "culture-name-selected"
																	: "culture-name"
															}>
															{item.displayName}
														</div>
													</div>
												</ListGroup.Item>
											))}{" "}
										</ListGroup>
									</Card.Body>
								</Card>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	initialValues: state.users.userDetails,
	isStudentMode: state.studentLanguageLearning.isStudentMode,
	cultures: state.users.cultures,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
	fetchUserDetails,
	updateStudentMode,
	getClassroomData,
	getALlCultures,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
