import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Card, Button } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { FormField } from "../sharedComponents/formField";
import {
    required,
    IsValuesMatch,
    checkPasswordLength,
    checkSpecialCharacter
} from "../../utils/formValidators";

import logo from "../../assets/images/logocopy.png";
import { resetPassword, validateToken } from "./actions";

import "./auth.scss";
import { useTranslation } from "react-i18next";

const ResetPassword = ({ handleSubmit, resetPassword, history, validateToken }) => {
	const [token, setToken] = useState("");
	const [id, setId] = useState("");
	const [resetStatus, checkResetStatus] = useState(false);
	const { t } = useTranslation();
	const resetPasswordFields = [
		{
			type: "password",
			placeholder: t("header.changepassword.newpassword"),
			validate: [required, checkPasswordLength],
			name: "newPassword"
		},
		{
			type: "password",
			placeholder: t("header.changepassword.confirmpassword"),
			validate: [required, IsValuesMatch],
			name: "confirmPassword"
		}
	];

	useEffect(() => {
		if (history.location.search) {
			const urlParams = new URLSearchParams(history.location.search);
			setToken(urlParams.get("token"));
			setId(urlParams.get("id"));
			id &&
				token &&
				validateToken(id, token).then((res) => {
					if (res.statusCode !== 200) {
						NotificationManager.error(res.response && res.response.message, "", 3000);
						checkResetStatus(true);
					}
				});
		}
	}, [id, token, validateToken, history]);

	const handlePassword = (values) => {
		checkResetStatus(true);
		resetPassword({ ...values, token, id }).then((res) => {
			if (res.statusCode === 200) {
				NotificationManager.success(t("resetpassword.notification.success"), "", 3000);
				history.push("/login");
			} else {
				NotificationManager.error(
					res.statusCode === 400
						? res.response.validationErrors &&
								res.response.validationErrors[0] &&
								res.response.validationErrors[0].value
						: res.response && res.response.message,
					"Error",
					2000
				);
				setTimeout(() => {
					checkResetStatus(false);
				}, 2000);
			}
		});
	};

	return (
		<div className="d-flex w-100 login-wrapper">
			<div className="login-bg-wrapper"></div>
			<form name="LoginForm" className="login-form " onSubmit={handleSubmit(handlePassword)}>
				<Card className="p-md-5 login-section">
					<img src={logo} className="logo-img" alt="logo" />

					<p className="forgot-intro-text mb-4">{t("login.authpagesresetbtn")}</p>
					{resetPasswordFields.map((field, index) => (
						<Field
							name={field.name}
							type={field.type}
							formGroupClass="no-label mt-3"
							placeholder={field.placeholder}
							component={FormField}
							validate={field.validate}
						/>
					))}

					<Button type="submit" className="rounded-lg border-0 mt-3 mb-3" disabled={resetStatus}>
						{t("login.authpagesresettitle")}
					</Button>
					<div className="d-flex justify-content-end my-3">
						<span>
							<Link to="/login" className="forgot-link mt-3 mb-3">
								{`< ${t("login.authpagesbackloginbtn")}`}
							</Link>
						</span>
					</div>
				</Card>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth.auth,
});

const mapDispatchToProps = {
	resetPassword,
	validateToken,
};

export default reduxForm({
	form: "LoginForm",
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));
