import { SUBMENU } from "./types";
import fetchHandler from "../../utils/fetchResponseHandler";

export const uploadDictionaryFile = (creds, language) => {
	const fetchOptions = {
		url: `vocabulary/upload?language=${language}`,
		method: "POST",
		actionType: SUBMENU.UPLOAD_FILE,
		body: creds,
		fileUpload: true,
	};

	return fetchHandler(fetchOptions);
};

export const uploadLanguageFile = (creds, language) => {
	const fetchOptions = {
		url: `grammar/upload?language=${language}`,
		method: "POST",
		actionType: SUBMENU.UPLOAD_FILE,
		body: creds,
		secure: true,
		fileUpload: true,
	};

	return fetchHandler(fetchOptions);
};

export const getAllLessons = (creds) => {
	const fetchOptions = {
		url: `vocabulary/lessons?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_ALL_LESSONS,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const getAllWords = (creds) => {
	const fetchOptions = {
		url: `vocabulary/list?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_ALL_WORDS,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const setLessonDetails = (creds) => {
	const fetchOptions = {
		url: `vocabulary/all?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_LESSON_DETAILS,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const setWordDetails = (creds) => {
	const fetchOptions = {
		url: `vocabulary/all?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_WORD_DETAILS,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const getGrammarDetails = (creds) => {
	const fetchOptions = {
		url: `grammar/all?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_GRAMMAR_DETAILS,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export function updateField(field, val) {
	return {
		type: SUBMENU.UPDATE_FIELD,
		field,
		val,
	};
}

export const getAllGrammar = (creds) => {
	const fetchOptions = {
		url: `grammar/lessons?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_ALL_GRAMMAR,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const getIntroVideo = (creds) => {
	const fetchOptions = {
		url: `grammar/l10n/intro?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_INTRO_VIDEO,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const deleteIntroVideo = (creds) => {
	const fetchOptions = {
		url: `grammar/deleteintro?${creds}`,
		method: "DELETE",
		actionType: SUBMENU.DELETE_INTRO_VIDEO,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const getMapIntro = (creds) => {
	const fetchOptions = {
		url: `grammar/l10n/mapintro`,
		method: "PUT",
		body: JSON.stringify(creds),
		actionType: SUBMENU.GET_MAP_INTRO,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const getVideoFiles = (creds) => {
	const fetchOptions = {
		url: `media/all?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_VIDEO_FILES,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export const getEnumValues = (creds) => {
	const fetchOptions = {
		url: `ENUM/all?${creds}`,
		method: "GET",
		actionType: SUBMENU.GET_ENUM_VALUES,
		secure: true,
	};

	return fetchHandler(fetchOptions);
};

export function changeType(payload) {
	return {
		type: SUBMENU.CHANGE_TYPE,
		payload,
	};
}
export function setSearchText(payload) {
	return {
		type: SUBMENU.SET_SEARCH_TEXT,
		payload,
	};
}

export function setIsSearchDisable(payload) {
	return {
		type: SUBMENU.SET_IS_SEARCH_ENABLE,
		payload,
	};
}
export function setLessonNumber(payload) {
	return {
		type: SUBMENU.SET_LESSON_NUMBER,
		payload,
	};
}

export function clearData() {
	return {
		type: SUBMENU.CLEAR_DATA,
	};
}
