import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import Loader from "./components/sharedComponents/Loader";

import "./polyfills";
import "./i18n";

ReactDOM.render(
	<Suspense fallback={<Loader />}>
		<Provider store={store}>
			<BrowserRouter basename={"/"}>
				<App />
			</BrowserRouter>
		</Provider>
	</Suspense>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
