import React from "react";
import { connect } from "react-redux";
import { setAudioSpeed } from "../actions";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { audioSpeedsList } from "../../../../utils/helper";

const AudioSettingsList = ({ audioId, setAudioSpeed, audioSpeedList, utilizer, url }) => {
	const checkedRadioButtonSelection = (item) => {
		const currentAudioSpeed = audioId && audioSpeedList ? audioSpeedList.filter((val) => val.audioId === audioId) : 1;
		if (currentAudioSpeed && currentAudioSpeed[0] && parseFloat(currentAudioSpeed[0].speed) === parseFloat(item)) {
			return true;
		} else if (currentAudioSpeed && !currentAudioSpeed[0] && parseFloat(item) === 1) {
			return true;
		} else {
			return false;
		}
	};

	const onChangeSpeedEventHandler = (e) => {
		const audioSpeed = audioSpeedList.filter((val) => val.audioId !== audioId);
		const speedArray = [...audioSpeed, { audioId: audioId, speed: e.target.value }];
		setAudioSpeed(speedArray);
	};

	const getRadioButtonHtml = () =>
		audioSpeedsList.map((item, index) => {
			return (
				<label key={index} className="container">
					<input
						type="radio"
						name="audio_setting"
						checked={checkedRadioButtonSelection(item)}
						value={item}
						onChange={(e) => {
							onChangeSpeedEventHandler(e);
						}}
					/>
					<span className="checkmark checkmark-settings pointer"></span>
					<span className="">{item}x</span>
				</label>
			);
		});

	const renderSpeedSettings = () =>
		utilizer === "wordDetails" ? (
			<button
				type="button"
				className="pointer audio-dropdown position-relative lesson-audio dropdown-toggle btn btn-primary">
				<i className="icon-settings-2"></i>
			</button>
		) : (
			<i className={` icon-settings-2 ${url ? "" : "not-allowed"}`}></i>
		);

	return audioId ? (
		<OverlayTrigger
			trigger="click"
			placement="top"
			rootClose={true}
			overlay={
				<Popover id="popover-basic" className="audio-settings-pop">
					<Popover.Content>
						<div className="d-flex justify-content-end ">
							<span onClick={() => document.body.click()}>
								<i className="icon icon-close pointer"></i>
							</span>
						</div>
						{getRadioButtonHtml()}
					</Popover.Content>
				</Popover>
			}>
			{renderSpeedSettings()}
		</OverlayTrigger>
	) : (
		renderSpeedSettings()
	);
};

const mapStateToProps = (state) => ({
	audioSpeedList: state.studentLanguageLearning.audioSpeedList,
});

const mapDispatchToProps = {
	setAudioSpeed,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioSettingsList);
