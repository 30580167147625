import React from "react";
import { Card, Form, Button, Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EditGroup = ({
	editGroup,
	updateEditGroup,
	clearFormTab,
	groups,
	deleteDeckbyID,
	moveDeckToNewGroup,
	deleteGroupbyID,
	setLoading,
	setShowGroupModalDelete,
	setShowDeckModalMove,
	setShowDeckModalDelete,
}) => {
	const { t } = useTranslation();
	const { language, fromGroupId, decks } = editGroup;

	const updateField = (field, value) => {
		updateEditGroup(field, value);
	};

	return (
		<div className="group-settings settings-tab">
			<Form>
				<Form.Row>
					<Col>
						<Form.Group controlId="groupName" className="d-flex">
							<Form.Label className="m-2 mr-4">{t("flashcarddeck.flashcardsettings.groupname")}</Form.Label>
							<Form.Control
								as="select"
								style={{ width: "20%" }}
								value={fromGroupId}
								onChange={(e) => {
									updateField("fromGroupId", parseInt(e.target.value));
								}}>
								<option></option>
								{groups &&
									groups.length &&
									groups.map((group, index) => (
										<option key={index} value={group.groupID}>
											{group.group}
										</option>
									))}
							</Form.Control>
							{fromGroupId && (
								<i
									onClick={() => setShowGroupModalDelete(true)}
									className="icon icon-delete p-2 group-delete-icon"
									title={t("flashcarddeck.flashcardsettings.modalhead.deletegroup")}></i>
							)}
						</Form.Group>
					</Col>
				</Form.Row>
				<Form.Row>
					<Card className="no-shadow" style={{ height: "100%", width: "100%" }}>
						<div className="table-wrapper">
							<Table className="senses-table-container mb-0">
								<thead>
									<tr className="spacer"></tr>
									<tr>
										<th className={"text-center"}>
											{t("flashcarddeck.flashcardsettings.editgrouptab.deckname")}
										</th>
										<th className={"text-center"}>
											{t("flashcarddeck.flashcardsettings.editdecktab.action")}
										</th>
									</tr>
								</thead>
								{decks && decks.length > 0 ? (
									<tbody>
										{decks.map((deck, idx) => (
											<React.Fragment key={idx}>
												<tr className="spacer"></tr>
												<tr className="column-table text-center">
													<td>{deck.name}</td>
													<td>
														{groups && groups.length && (
															<Button
																variant="primary mr-2"
																disabled={deck.isSharedByUser}
																title={t("flashcarddeck.flashcardsettings.editdecktab.moveshareddeckalert")}
																onClick={() => {
																	setShowDeckModalMove(true);
																	updateField("deckId", deck.id);
																}}>
																{t("flashcarddeck.flashcardsettings.modalhead.movedeck")}
															</Button>
														)}
														<Button
															variant="secondary"
															onClick={() => {
																setShowDeckModalDelete(true);
																updateField("deckId", deck.id);
															}}>
															{t("helpers.utils.delete")}
														</Button>
													</td>
												</tr>
												<tr className="spacer"></tr>
											</React.Fragment>
										))}
									</tbody>
								) : (
									<tbody>
										<tr className="spacer"></tr>
										<tr style={{ margin: "auto 0" }}>
											<td colSpan={2} style={{ textAlign: "center" }}>
												{t("helpers.utils.nodata")}
											</td>
										</tr>
									</tbody>
								)}
							</Table>
						</div>
					</Card>
				</Form.Row>
			</Form>
		</div>
	);
};

export default EditGroup;
