import { READING_COMPREHENSION } from "./types";
import fetchHandler from "../../../utils/fetchResponseHandler";
import i18n from '../../../i18n';
export const getTabData = (tabName, language, searchTerm) => {
	let path = "";
	if (tabName === i18n.t("languagelearning.readingcomprehension.lessonreading")) {
		path = "/forlesson";
	} else {
		path = "/forothers";
	}
	const fetchOptions = {
		url: `readingcomprehensiondeck${path}?language=${language === "Hebrew" ? 0 : 1}${
			searchTerm ? `&SearchTerm=${searchTerm}` : ""
		}`,
		method: "GET",
		actionType: READING_COMPREHENSION.GET_TAB_CONTENT,
	};
	return fetchHandler(fetchOptions);
};

export const getDetails = (url, id) => {
	const fetchOptions = {
		url: `${url}/get/${id}`,
		method: "GET",
		actionType: READING_COMPREHENSION.GET_DETAILS,
	};
	return fetchHandler(fetchOptions);
};

export const changeActivity = (id, url) => {
	const fetchOptions = {
		url: `${url}/next/${id}`,
		method: "POST",
		actionType: READING_COMPREHENSION.CHANGE_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};

export const verifyActivity = (data, url) => {
	const fetchOptions = {
		url: `${url}/verify`,
		method: "POST",
		body: JSON.stringify(data),
		actionType: READING_COMPREHENSION.VERIFY_ACTIVITY,
	};

	return fetchHandler(fetchOptions);
};
