import { FLASH_CARD_DECK } from "./types";
const initialState = {
	isRestart: false,
	enableCall: true,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case FLASH_CARD_DECK.GET_DECK_LIST:
			return {
				...state,
				data: action.payload.response,
			};
		case FLASH_CARD_DECK.GET_DECK_GROUPS:
			return {
				...state,
				deckGroups: action.payload.response,
			};
		case FLASH_CARD_DECK.GET_DECK_DETAILS:
			const lessonData =
				action.payload.response && (action.payload.response.isLessonCompleted || action.payload.response.isCompleted)
					? {
							...state.deckDetails,
							activityId: action.payload.response.activityId,
							lessonId: action.payload.response.lessonId,
							badge: action.payload.response.badge,
							isLessonCompleted: action.payload.response.isLessonCompleted,
							isCompleted: action.payload.response.isCompleted,
							nextDeckId: action.payload.response.nextDeckId,
					  }
					: action.payload.response;
			return {
				...state,
				deckDetails: lessonData,
			};
		case FLASH_CARD_DECK.UPDATE_DECK_FIELD:
			return {
				...state,
				[action.field]: action.value,
			};
		case FLASH_CARD_DECK.RESET:
			return initialState;
		default:
			return state;
	}
}
export default reducer;
