export const DASHBOARD = {
	GET_STREAK_DATA: "GET_STREAK_DATA",
	GET_GOAL_DATA: "GET_GOAL_DATA",
	GET_OVERVIEW: "GET_OVERVIEW",
	GET_CHART: "GET_CHART_DASHBOARD",
	GET_WORDS_IN_LANGUAGE_LEARNING: "GET_WORDS_IN_LANGUAGE_LEARNING",
	GET_WORDS_LEARNED: "GET_WORDS_LEARNED",
	GET_REPORTS: "GET_REPORTS_DASHBOARD",
	GET_LESSON_DATA: "GET_LESSON_DATA",
	GET_READING_DATA: "GET_READING_DATA",
	GET_LESSON_LIST: "GET_LESSON_LIST_PLANNER",
	GET_LESSON_ESTIMATED_DATE: "GET_LESSON_ESTIMATED_DATE",
	GET_READING_OVERVIEW: "GET_READING_OVERVIEW",
	GET_READING_LINE_CHART: "GET_READING_LINE_CHART",
	GET_READING_PIE_CHART: "GET_READING_PIE_CHART",
	GET_READING_REPORT: "GET_READING_REPORT",
	GET_RECORDINGS: "GET_RECORDINGS",
	GET_PRACTICE_DATA: "GET_PRACTICE_DATA",
	CREATE_PLAN: "CREATE_PLAN",
	GET_BOOK_LIST: "GET_BOOK_LIST",
	GET_CHAPTER_LIST: "GET_CHAPTER_LIST",
	GET_VERSE_LIST: "GET_VERSE_LIST",
	GET_READING_ESTIMATED_DATE: "GET_READING_ESTIMATED_DATE",
	DELETE_PLANNER_TASK: "DELETE_PLANNER_TASK",
	DELETE_PLANNER_PLAN: "DELETE_PLANNER_PLAN",
};

export const overviewUrls = {
	noOfWordsLearnedOverview: "vocabulary/overview/numberofwordslearned",
	vocabularyProficiency: "vocabulary/overview/proficiency",
	timeSpentLearning: "activity/overview/timespent",
	daysSpentLearning: "activity/overview/days",
	numberOfSessions: "activity/overview/sessions",
	pointsAccumulated: "activity/overview/points",
	topics: "grammar/overview/topics",
	sentence: "grammar/overview/sentences",
	grammarProficiency: "grammar/overview/proficiency",
};
export const chartUrls = {
	noOfWordsLearned: "vocabulary/chart/numberofwordslearned",
	vocabularyChartProficiency: "vocabulary/chart/proficiency",
	learningTime: "activity/chart/timespentlearning",
	sessionsChartUrl: "activity/chart/numberofsessions",
	pointsCharts: "activity/chart/points",
	daysSpentLearningCharts: "activity/chart/daysspent",
	daysSpentFlashCardsCharts: "activity/chart/daysspent",
	daysSpentBibleReadingCharts: "activity/chart/daysspent",
	topicsCharts: "grammar/chart/topics",
	sentenceCharts: "grammar/chart/sentences",
	proficiencyCharts: "grammar/chart/proficiency",
};

export const reportsUrls = {
	fluencyDrillReports: "grammar/report/fluencydrill",
	topicsLearnedReports: "grammar/report/topicslearned",
	chaptersCompletedReports: "reading/report/chapterscompleted",
	comprehesionReadingReports: "reading/report/readingcomprehension",
	wordsLearnedReports: "vocabulary/reports/getwordslearned",
};

// collective urls

export const collectiveOverviewUrls = {
	noOfWordsLearnedOverview: "collectivedashboard/vocabularyoverview/numberofwordslearned",
	vocabularyProficiency: "collectivedashboard/vocabularyoverview/proficiency",
	timeSpentLearning: "collectivedashboard/activityoverview/timespent",
	daysSpentLearning: "collectivedashboard/activityoverview/daysspent",
	numberOfSessions: "collectivedashboard/activityoverview/numberofsession",
	pointsAccumulated: "collectivedashboard/activityoverview/pointsaccumulated",
	topics: "/collectivedashboard/grammaroverview/topics",
	sentence: "collectivedashboard/grammaroverview/sentences",
	grammarProficiency: "collectivedashboard/grammaroverview/proficiency",
};

export const collectiveChartUrls = {
	noOfWordsLearned: "collectivedashboard/vocabularylinechart/numberofwordslearned/",
	vocabularyChartProficiency: "collectivedashboard/vocabularyroundchart/proficiency/",
	learningTime: "collectivedashboard/activitylinechart/learningtime",
	sessionsChartUrl: "collectivedashboard/activitylinechart/sessions",
	pointsCharts: "collectivedashboard/activitylinechart/points",
	daysSpentLearningCharts: "collectivedashboard/activityroundchart/daysspentbylanguagelearning",
	daysSpentFlashCardsCharts: "collectivedashboard/activityroundchart/daysspentbyflashcard",
	daysSpentBibleReadingCharts: "collectivedashboard/activityroundchart/daysspentbybiblereading",
	topicsCharts: "collectivedashboard/grammarlinechart/topics",
	sentenceCharts: "collectivedashboard/grammarlinechart/sentences",
	proficiencyCharts: "collectivedashboard/grammarroundchart/proficiency",
};
