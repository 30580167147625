import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserDetails } from "../../../utils/verifyUser";
import Header from "../../header";
import Loader from "../../sharedComponents/Loader";
import { setSideMenu } from "../../Sidemenu/actions";
import { updateField } from "../LanguageLearning/actions";
import "./bible.scss";
import BibleWords from "./bibleWords";
import BookList from "./bookList";
import Senses from "./senses";
import { useTranslation } from "react-i18next";

const BibleReading = ({
	setSideMenu,
	history,
	isLoading,
	isSenseLoading,
	isVerseLoading,
	isHistoryLoading,
	isWaitingResponse,
	language,
	updateField,
	points,
	loadingChapter,
}) => {
	const { t } = useTranslation();
	const [showSenses, setShowSenses] = useState(false);
	const [bibleView, setBibleView] = useState("");
	const [searchParams, setSearchParams] = useState(null);

	useEffect(() => {
		if (window && window.location && window.location.search)
			setSearchParams(
				window.location.search
					.slice(1)
					.split("&")
					.map((item) => item.split("=")[1])
			);
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: t("biblereading.name"), path: "" }],
			language: location[2],
		});
		setBibleView(location[4] ? location[4] : "reading");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location]);

	const renderBibleData = () => {
		switch (bibleView) {
			case "reading":
				return bibleReadingView;
			case "books":
				return <BookList />;
			default:
				return null;
		}
	};

	const bibleReadingView = (
		<div className="d-flex flex-column h-100">
			<div className="d-flex justify-content-start">
				<button
					className="btn btn-default list-redirect-btn"
					onClick={() => history.push(`/home/${language}/bible-reading/books`)}>
					<i className="icon icon-left-arrow-1"></i>
					{t("biblereading.gotobibleindex")}
				</button>
			</div>
			<div className="d-flex reading-wrapper">
				<BibleWords
					toggleSensesView={setShowSenses}
					showSenses={showSenses}
					searchParams={
						searchParams && {
							bookId: parseInt(searchParams[0]),
							chapter: parseInt(searchParams[1]),
						}
					}
				/>
				{showSenses && <Senses />}
			</div>
		</div>
	);

	return (
		<div className="bible-reading-container">
			<Header enableLanguageSwitch={true} redirectionUrl="bible-reading" head={t("biblereading.name")} points={points} />
			{(isLoading || isSenseLoading || isVerseLoading || isHistoryLoading || isWaitingResponse || loadingChapter) && (
				<Loader />
			)}
			<div className="bible-data-container px-4">{renderBibleData()}</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isLoading: state.bible.isLoading,
	isSenseLoading: state.bible.isSenseLoading,
	isVerseLoading: state.bible.isVerseLoading,
	isHistoryLoading: state.bible.isHistoryLoading,
	isWaitingResponse: state.bible.isWaitingResponse,
	language: state.sideMenu.language,
	points: state.bible.points,
	loadingChapter: state.bible.loadingChapter,
});

const mapDispatchToProps = {
	setSideMenu,
	updateField,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BibleReading));
