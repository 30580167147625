import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Card } from "react-bootstrap";

import AudioLibraryComponent from "../AudioLibraryTable";
import Loader from "../../../sharedComponents/Loader";
import { updateField } from "../../LanguageLearning/actions";
import { setSideMenu } from "../../../Sidemenu/actions";
import { getUserDetails } from "../../../../utils/verifyUser";
import { getRecordings } from "../action";
import Header from "../../../header";
import Pagination from "../../../sharedComponents/Pagination";
import { useTranslation } from "react-i18next";
import "./audioLibrary.scss";

const SIZE = 10;

const AudioLibrary = ({ menu, history, setSideMenu, match, getRecordings, updateField }) => {
	const { t } = useTranslation();
	const [activePage, setActivePage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState();
	const studentId = match.params.id;

	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [
				{
					title: t("dashboard.name"),
					path: studentId
						? `/Home/${match.params.language}/Dashboard${studentId}`
						: `/Home/${match.params.language}/Dashboard`,
				},
				{ title: t("dashboard.audiolibrary.name"), path: "" },
			],
			language: location[2],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const titleHeaders = [
		{ label: t(["dashboard.vocabulary.overview.wordslearned"]), key: "word" },
		{ label: t(["dashboard.audiolibrary.correctorincorrect"]), key: "value" },
		{
			label:
				match.params.from === "Grammar"
					? t(["dashboard.audiolibrary.locationordering"])
					: t(["dashboard.audiolibrary.strongsnumber"]),
			key: "strongsNumber",
		},
		{ label: t(["dashboard.audiolibrary.recording"]), key: "filePath", type: "audio" },
		{ label: t(["dashboard.audiolibrary.original"]), key: "originalFilePath", type: "audio" },
	];
	const getData = async (pageNumber) => {
		setLoading(true);
		const { response: audioLibraryData } = await getRecordings(
			match.params.language === "Hebrew" ? 0 : 1,
			match.params.from,
			SIZE,
			pageNumber,
			studentId
		);
		if (audioLibraryData && audioLibraryData.records && audioLibraryData.records.length) {
			audioLibraryData.records.map((rec) => {
				if (rec.isCorrect) {
					rec.value = t("helpers.utils.correct");
				} else {
					rec.value = t("helpers.utils.incorrect");
				}
				return rec;
			});
		}
		setTableData(audioLibraryData);
		setLoading(false);
	};

	useEffect(() => {
		getData(activePage);
	}, []);

	const headerVal = (headerSelect) => {
		switch (headerSelect) {
			case "Vocabulary": {
				return `${t("dashboard.audiolibrary.vocabularyaudiolibrary")}`;
			}
			case "Grammar": {
				return `${t("dashboard.audiolibrary.grammaraudiolibrary")}`;
			}
			default: {
				return `${headerSelect + " " + t("dashboard.audiolibrary.name")}`;
			}
		}
	};

	return (
		<div className="audio-library-wrapper empty">
			<Header
				menu={menu}
				language={match.params.language}
				history={history}
				head={headerVal(match.params.from)}
				enableLanguageSwitch
				redirectionUrl="Dashboard"
			/>
			<div className="audio-library-container">
				{loading && <Loader />}
				<Container fluid style={{ paddingLeft: 10, paddingRight: 10, height: "100%" }}>
					{tableData && (
						<Card className="no-shadow" style={{ height: "100%" }}>
							<div className="table-wrapper">
								<>
									<AudioLibraryComponent header={titleHeaders} data={tableData} />
									{tableData.records && tableData.records.length > 0 && (
										<Pagination
											activePage={activePage + 1}
											itemsCountPerPage={SIZE}
											totalItemsCount={tableData.count}
											pageRangeDisplayed={7}
											onChange={(pageNumber) => {
												setActivePage(pageNumber - 1);
												getData(pageNumber - 1, SIZE);
											}}
										/>
									)}
								</>
							</div>
						</Card>
					)}
				</Container>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({ menu: state.sideMenu.menu });
const mapDispatchToProps = { setSideMenu, getRecordings, updateField };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudioLibrary));
