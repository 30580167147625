import { AUTH } from "./types";
const initialState = { auth: null };

function reducer(state = initialState, action) {
	switch (action.type) {
		case AUTH.SEND_LOGIN_CREDENTIALS: {
			const authObj = {
				...state,
				...action.payload,
			};
			const {
				response: {
					fluencyDrillSettings: {
						greekFluencyDrillSettings: { numberOfQuestionsForGreek },
						hebrewFluencyDrillSettings: { numberOfQuestionsForHebrew },
					},
					learningSettings: {
						greekLearningSettings: { isGreekPracticePopupEnabled },
						hebrewLearningSettings: { isHebrewPracticePopupEnabled },
					},
				},
			} = authObj;

			const settings = {
				Greek: {
					numberOfQuestionsFluencyDrills: numberOfQuestionsForGreek,
					practicePopup: isGreekPracticePopupEnabled,
				},
				Hebrew: {
					numberOfQuestionsFluencyDrills: numberOfQuestionsForHebrew,
					practicePopup: isHebrewPracticePopupEnabled,
				},
			};
			localStorage.setItem("auth", JSON.stringify(authObj));
			localStorage.setItem("settings", JSON.stringify(settings));

			return {
				...state,
				authObj: authObj.response,
			};
		}
		default:
			return state;
	}
}
export default reducer;
