import { BIBLE } from "./types";

const initialState = {
	isLoading: false,
	isSenseLoading: false,
	isVerseLoading: false,
	isHistoryLoading: false,
	isWaitingResponse: false,
	strongsNumber: null,
	currentWord: null,
	verses: null,
	selectedBook: null,
	removeSelection: false,
	fetchBibleData: false,
	disableScroll: false,
	removeListener: false,
	currentNodeIndex: null,
	selectedVerse: null,
	currentTab: "",
	points: 0,
	loadingChapter: false,
};

const BibleReducer = (state = initialState, action) => {
	switch (action.type) {
		case BIBLE.UPDATE_FIELD:
			return { ...state, [action.field]: action.value };
		case BIBLE.UPDATE_BIBLE_FIELDS:
			return { ...state, ...action.data };
		case BIBLE.GET_CUSTOM_DECKS:
			return { ...state, decks: action.payload };
		case BIBLE.GET_SENSES:
			return { ...state, senses: action.payload, isSenseLoading: false };
		case BIBLE.GET_VERSES:
			return { ...state, verses: action.payload, isVerseLoading: false };
		case BIBLE.SET_POINTS:
			return { ...state, points: action.points };
		default:
			return state;
	}
};

export default BibleReducer;
