import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "../../utils/formValidators";
import { FormField } from "../sharedComponents/formField";
import { createClassroom, getClassroomCode, getClassroomData, updateClassroom } from "../Users/actions";
import { addClassRoomFields } from "./helper";


const AddClassModal = ({
  handleSubmit,
  handleClose,
  show,
  initialize,
  language,
  code,
  name,
  role,
  selectedClass,
  user,
  createClassroom,
  getClassroomData,
  getClassroomCode,
  updateClassroom,
}) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    if (selectedClass) {
      initialize({
        name: selectedClass.name,
        code: selectedClass.code,
        language: { label: selectedClass.language },
      });
    } else {
      initialize({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass, show]);

  useEffect(() => {
    let roleBasedFields = [...addClassRoomFields];
    if (role === "Student") {
      roleBasedFields = [
        {
          label: t("header.myprofile.sub.addclass.modal.sub"),
          name: "code",
          type: "text",
        },
      ];
    } else {
      roleBasedFields = [...addClassRoomFields];
    }
    setFormFields(roleBasedFields);
  }, [role]);

  useEffect(() => {
    if (
      (role !== "Student" && name && language && language.label) ||
      (role === "Student" && code)
    ) {
      setUpdating(false);
    } else {
      setUpdating(true);
    }
  }, [language, code, name, role]);

  const getOptions = (field) => {
    if (field === "language" && user && user.languages) {
      if (user.languages.length > 1) {
        return [
          { label: "Hebrew", value: 0 },
          { label: "Greek", value: 1 },
        ];
      } else if (user.languages && user.languages[0].language.language === 0) {
        return [{ label: "Hebrew", value: 0 }];
      } else if (user.languages && user.languages[0].language.language === 1) {
        return [{ label: "Greek", value: 1 }];
      } else return [];
    } else return [];
  };

  const addUserDetails = (data) => {
    setUpdating(true);
    let payload = {};
    let url = "";
    let submitData = updateClassroom;
    if (role === "Student") {
      payload.code = data.code;
      url = "mapstudent";
    } else {
      payload.name = data.name;
      if (selectedClass) {
        payload.id = selectedClass.id;
        url = "edit";
      } else {
        payload.language = data.language.value;
        submitData = createClassroom;
      }
    }

    submitData(payload, url).then((res) => {
      handleResponse(res);
    });
  };

  const handleResponse = (res) => {
    setUpdating(false);
    if (res.statusCode === 200) {
      handleClose();
      initialize({});
      const url = role === "Student" ? "getforstudent" : "getforteacher";
      getClassroomData(url);
      let act = role === "Student" || selectedClass ? t("helpers.utils.updated") : t("helpers.utils.created")
      NotificationManager.success(
        t("flashcarddeck.flashcardsettings.sharedecktab.shareoption.options.classroom.update",{value : `${act}`}),
        "",
        3000
        );
    } else {
      NotificationManager.error(
        `${res.response && res.response.message ? res.response.message : t("helpers.utils.notification.error")
        }`,
        "",
        3000
      );
    }
  };

  const onModalClose = () => {
    handleClose();
    initialize({});
  };

  const disableField = (field) => {
    if (role !== "Student" && field.name === "language" && selectedClass) {
      return true;
    }
    return false;
  };

  const renderForm = () => (
    <form className="user-form">
      <div className="d-flex flex-column row">
        {formFields.map((field) => (
          <Field
            key={field.label}
            name={field.name}
            type={field.type}
            disabled={disableField(field)}
            readOnly={disableField(field)}
            formGroupClass="no-label col-md-12 mb-1"
            defaultError={true}
            label={field.label}
            component={FormField}
            options={field.type === "select" ? getOptions(field.name) : null}
            validate={required}
            showError={false}
          />
        ))}
      </div>
      <div className="form-footer d-flex justify-content-end mt-2">
        <button
          type="button"
          disabled={updating}
          className="btn btn-primary save-btn"
          onClick={handleSubmit((data) => addUserDetails(data))}
        >
          {selectedClass ? t("helpers.utils.update") : t("helpers.utils.add")}
        </button>
      </div>
    </form>
  );

  return (
    <Modal
      show={show}
      className="resume-progress-modal add-class-modal"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title> {t("header.myprofile.sub.addclass.modal.name")} </Modal.Title>
        <div className="modal-close-btn" onClick={onModalClose}>
          <i className={`icon icon-close `}></i>
        </div>
        <p></p>
      </Modal.Header>
      <Modal.Body>{renderForm()}</Modal.Body>
    </Modal>
  );
};

const selector = formValueSelector("AddClassModal");

const mapStateToProps = (state) => ({
  language: selector(state, "language"),
  code: selector(state, "code"),
  name: selector(state, "name"),
});

const mapDispatchToProps = {
  createClassroom,
  getClassroomData,
  getClassroomCode,
  updateClassroom,
};

export default reduxForm({
  form: "AddClassModal",
  destroyOnUnmount: true,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(AddClassModal)));
