export const addClassRoomFields = [
  {
    label: "Classroom Name",
    name: "name",
    type: "text",
  },
  {
    label: "Classroom Language",
    name: "language",
    type: "select",
  },
];

export const dummyClassList = [
  {
    name: "test",
    teacherName: "teacher1",
    code: "asdkj12h",
    language: "Hebrew",
    id: 123,
  },
  {
    name: "tesseft",
    teacherName: "teacher2",
    code: "assdfdkj12h",
    language: "Greek",
    id: 1234,
  },
];

export const classRoomFields = [
  { label: "Teacher Name", field: "teacher" },
  { label: "Classroom Name", field: "name" },
  { label: "Classroom Code", field: "code" },
  { label: "Classroom Language", field: "language" },
];
