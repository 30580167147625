import React from "react";
import { useTranslation } from "react-i18next";
import "./infoDescription.scss";

const InfoDescription = ({ description, position = "right-bottom" }) => {
	const { t } = useTranslation();
	return (
		<div className={"infotooltip"}>
			<i className="icon icon-info" style={{ fontSize: "12px" }}></i>
			<span style={{ display: "" }} className={`tooltiptext ${position}`}>
				{description === "No data" ? t("helpers.utils.nodata") : description}
			</span>
		</div>
	);
};

export default InfoDescription;
