import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../header";

const EntriesFailed = () => {
  const history = localStorage.getItem("history");
  const menu = localStorage.getItem("menu");
  const failedEntries = localStorage.getItem("failedEntries");

  return (
    <div className="dashboard d-flex">
      <div className="empty">
        <Header
          menu={menu ? JSON.parse(menu) : []}
          language={menu ? JSON.parse(menu)[1] : ""}
          history={history ? JSON.parse(history) : ""}
        />
        {failedEntries && (
          <ul className="list-unstyled p-4">
            {JSON.parse(failedEntries) &&
              JSON.parse(failedEntries).map((entry, index) => (
                <li key={index} className="error mb-2">
                  {index + 1}. {entry}
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default withRouter(EntriesFailed);
