import i18n from "../i18n";

export const required = (value) => {
	return (value && typeof value !== "object" && typeof value !== "number" && value.trim()) ||
		(value && typeof value === "object" && value.length === undefined) ||
		(value && typeof value === "object" && value.length !== undefined && value.length > 0) ||
		typeof value === "number"
		? undefined
		: i18n.t("helpers.utils.validation.required");
};

export const number = (value) => {
	return value && isNaN(Number(value)) ? i18n.t("helpers.utils.validation.mustbeanumber") : undefined;
};

export const email = (value) => {
	return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)
		? i18n.t("helpers.utils.validation.invalidemail")
		: undefined;
};

export const checkPasswordLength = (value) => {
	return value && value.length < 6 ? i18n.t("helpers.utils.validation.passlength") : undefined;
};

export const checkSpecialCharacter = (value) => {
	return !/^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])/.test(value) ? i18n.t("helpers.utils.validation.passinfo") : undefined;
};

export const IsValuesMatch = (value, allValues) => {
	if (value !== allValues.newPassword) {
		return i18n.t("helpers.utils.validation.passmismatch");
	}
	return undefined;
};

const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
export const PhoneNumber = (elementValue) => {
	return elementValue && !phoneNumberPattern.test(elementValue)
		? i18n.t("helpers.utils.validation.invalidphonenumber")
		: undefined;
};

export const IsValidFileName = (value) => {
	return !/^[ A-Za-z0-9_@./!_.*'()-]*$/.test(value) ? true : false;
};
