import React, { useEffect, useReducer } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { NotificationManager } from "react-notifications";

import {
	fetchQuestionsList,
	fetchQuestionDetails,
	fetchAnswers,
	postAnswer,
	fetchQuestionStatuses,
	deleteQuestion,
	updateField,
} from "./actions";
import Header from "../header";
import { qaFields, languages } from "./helper";
import { Table } from "../sharedComponents/table";
import { setSideMenu } from "../Sidemenu/actions";
import "./qa.scss";
import { renderColumn } from "../../utils/tableHelper";
import { fieldUpdateReducer } from "../../utils/helper";
import SharedModal from "../sharedComponents/Modal";
import QaDetails from "./qaDetails";
import { getUserDetails } from "../../utils/verifyUser";
import { useTranslation } from "react-i18next";

const QA = ({
	setSideMenu,
	fetchQuestionsList,
	questionsList,
	qaStatusList,
	fetchQuestionDetails,
	fetchAnswers,
	postAnswer,
	fetchQuestionStatuses,
	deleteQuestion,
	updateField,
}) => {
	const initialState = {
		showFilter: false,
		showModal: false,
		saving: false,
		showDetails: false,
		isAdmin: false,
		userId: "",
		selctedQuestion: null,
		qaListingFields: [],
	};
	const [state, dispatch] = useReducer(fieldUpdateReducer, initialState);
	const { showFilter, showModal, saving, selctedQuestion, showDetails, qaListingFields, isAdmin, userId } = state;
	const { t } = useTranslation();

	useEffect(() => {
		const userDetails = getUserDetails();
		const isAdmin = userDetails && userDetails.role && userDetails.role.role === 0;
		const userId = userDetails && userDetails.id;
		const qaListingFields = [...qaFields.slice(0, isAdmin ? 8 : 7)];
		dispatch({ qaListingFields, isAdmin, userId });
	}, []);

	useEffect(() => {
		setSideMenu({
			menu: [{ title: t("menu.q&a"), path: "" }],
		});
		fetchQuestionsList();
		fetchQuestionStatuses();
	}, [setSideMenu, fetchQuestionsList, fetchQuestionStatuses]);

	const getOptions = (field) => {
		let options = [];
		if (field.accessor === "language") {
			options = [...languages];
		} else if (field.accessor === "status") {
			options = qaStatusList.map((status) => ({
				value: status.value,
				label: status.description,
			}));
		}
		return options;
	};

	const cellProps = (state, rowInfo, column) => {
		return {
			className: `${column.id} expanded pointer`,
			onClick: (event) => {
				if (column.id !== "Action") {
					dispatch({ showDetails: true });
					fetchQuestionDetails(rowInfo.original.id);
					fetchAnswers(rowInfo.original.id);
				}
			},
		};
	};

	const onModalClose = () => {
		dispatch({ showModal: false, selctedQuestion: null });
	};

	const deleteSelctedQuestion = () => {
		dispatch({ saving: true });
		deleteQuestion(selctedQuestion.id).then((res) => {
			onModalClose();
			dispatch({ saving: false });
			if (res.statusCode === 200) {
				fetchQuestionsList();
				NotificationManager.success(t("qa.qndelsuccess"), "", 3000);
			} else {
				NotificationManager.error(t("helpers.utils.notification.error"), "", 3000);
			}
		});
	};

	const renderCell = (field, props) => {
		switch (field.accessor) {
			case "status":
				return props.value && props.value.description ? (
					<span className={`${props.value.description.toLowerCase()} d-flex align-items-center`}>
						{props.value.description}
					</span>
				) : (
					"-"
				);
			case "askedOn":
				return props.value ? moment(props.value).format("DD MMM YYYY") : "-";
			case "language":
				return props.value && props.value.description ? props.value.description : "-";
			case "Action":
				return renderActions(props.original);
			default:
				return props.value ? props.value : " - ";
		}
	};

	const columns = [...qaListingFields.map((field) => renderColumn(field, renderCell, getOptions))];

	const renderActions = (data) => {
		return (
			<div className="action-field">
				<i
					title="Delete"
					className="icon icon-garbage pointer ml-4"
					onClick={() => {
						dispatch({ selctedQuestion: data, showModal: true });
					}}></i>
			</div>
		);
	};

	const renderModalBody = () => {
		return (
			<div className="text-center">
				<span>{t("qa.confirmdeleteqn")}</span>
			</div>
		);
	};

	const renderModalFooter = () => {
		return (
			<div className="mb-2 delete-modal-footer">
				<button className="btn btn-success mr-3" disabled={saving} onClick={deleteSelctedQuestion}>
					{t("helpers.utils.yes")}
				</button>
				<button disabled={saving} className="btn btn-danger " onClick={onModalClose}>
					{t("helpers.utils.no")}
				</button>
			</div>
		);
	};

	return (
		<div className="qa-container empty d-flex flex-column">
			<SharedModal
				show={showModal}
				disableHeader={true}
				modalBody={renderModalBody()}
				modalFooter={renderModalFooter()}
				handleClose={onModalClose}
				modalClass="confirmation-modal qa-list-modal"
			/>
			<QaDetails
				show={showDetails}
				close={() => {
					dispatch({ showDetails: false });
					updateField("questionDetails", null);
					updateField("answerList", null);
				}}
				isAdmin={isAdmin}
				userId={userId}
			/>
			<Header head="Q&A" />
			<div className={`px-4 qa-table mt-3 mb-3 flex-fill ${!isAdmin ? "no-action" : ""}`}>
				<div className="d-flex justify-content-end">
					<button
						className="btn btn-primary add-user filter-btn mb-2"
						onClick={() => dispatch({ showFilter: !showFilter })}>
						{t("helpers.utils.filter")}
					</button>
				</div>
				<Table
					data={questionsList}
					columns={columns}
					cellProps={cellProps}
					rowGroupProps={() => ({
						className: `expanded`,
					})}
					theadFilterProps={() => ({
						className: `${showFilter ? "show" : "hide"}`,
					})}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	questionsList: state.QA.questionsList,
	qaStatusList: state.QA.qaStatusList,
	questionDetails: state.QA.questionDetails,
});

const mapDispatchToProps = {
	setSideMenu,
	fetchQuestionsList,
	fetchQuestionDetails,
	fetchAnswers,
	postAnswer,
	deleteQuestion,
	fetchQuestionStatuses,
	updateField,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QA));
