import React from "react";
import Select from "react-select";

export const FormField = ({
  input,
  placeholder,
  label,
  formGroupClass = "",
  min,
  max,
  step,
  type,
  autoFocus,
  disabled = false,
  showError = true,
  options,
  isMulti,
  readOnly,
  menuPlacement,
  defaultError,
  labelIcon,
  integer,
  meta: { touched, error, warning },
}) => {
  const props = type === "number" ? { min, max, step } : {};
  switch (type) {
    case "select":
      return selectField(
        formGroupClass,
        input,
        options,
        isMulti,
        touched,
        error,
        warning,
        readOnly,
        menuPlacement,
        placeholder,
        defaultError,
        label,
        labelIcon,
        showError
      );
    default:
      return (
        <div className={`form-group ${formGroupClass} `}>
          {label && (
            <label className="field-label" htmlFor="">
              {label}
            </label>
          )}
          <input
            {...input}
            autoComplete="off"
            autoFocus={autoFocus ? true : false}
            className="form-control"
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            onKeyDown={(e) => keyPress(e, type, integer)}
            {...props}
          />
          {showError && setError(defaultError, touched, error)}
        </div>
      );
  }
};

const keyPress = (e, fieldType, integer) => {
  const badValues = [69, 107, 109, 187, 189];
  const decimalValues = [110, 190];

  if (integer && decimalValues.includes(e.keyCode)) {
    e.preventDefault();
  }
  if (fieldType === "number" && badValues.includes(e.keyCode)) {
    e.preventDefault();
  }
};

const selectField = (
  formGroupClass,
  input,
  options,
  isMulti,
  touched,
  error,
  warning,
  readOnly,
  menuPlacement,
  placeholder,
  defaultError,
  label,
  labelIcon,
  showError
) => {
  return (
    <div className={`form-group ${formGroupClass} `}>
      {label && (
        <label className="field-label" htmlFor="">
          {label}
          {labelIcon && labelIcon}
        </label>
      )}
      <Select
        value={input.value ? input.value : null}
        options={options}
        onChange={input.onChange}
        isMulti={isMulti}
        isDisabled={readOnly}
        placeholder={placeholder}
        menuPlacement={menuPlacement ? menuPlacement : "auto"}
        className="react-select"
        classNamePrefix="react-select"
      />
      {showError && setError(defaultError, touched, error)}
    </div>
  );
};

const setError = (defaultError, touched, error) => {
  return defaultError ? (
    <span
      className="error bounceIn"
      dangerouslySetInnerHTML={{
        __html: touched && error ? error : `&nbsp;`,
      }}
    />
  ) : (
    touched && error && <span className="error bounceIn">{error}</span>
  );
};
