import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import upload from "../../assets/images/modal-icon.png";
const UploadFailModal = ({
  title,
  modalBody,
  modalFooter,
  handleClose,
  show = false,
  className,
  message,
  centered = false,
  onClose,
  handleUpload
}) => {
  return (
    <Modal
      onHide={onClose}
      show={show}
      className={className}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <div className="upload-failed">
          <img src={upload} alt="upload" />
        </div>
        <h3 className="upload-modal-text mb-2">Failed To Upload the File</h3>
        <div className="error">Warning: {message}</div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button
          className="modal-footer mb-4"
          variant="success"
          onClick={handleUpload}
        >
          Re-upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFailModal;
