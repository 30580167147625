import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#0088FE", "#00C49F"];

const PieChartComponent = ({ data }) => {
	return (
		<ResponsiveContainer width="50%" height={233}>
			<PieChart>
				<Pie data={data} innerRadius={60} outerRadius={80} fill="#8884d8" dataKey="value">
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
};

export default PieChartComponent;
