import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { fieldUpdateReducer } from "../../../../utils/helper";
import ImgSider from "../../../sharedComponents/carousel";
import AudioSettingsList from "./audioSettings";
import SharedModal from "../../../sharedComponents/Modal";
import { archiveword } from "../../flashCardDeck/actions";
import { renderActionButtons, renderNavigationSection, togglePlay, overlay, fields } from "./helper";

const WordDetails = ({
	lessonDetails,
	updateAcitivity,
	disableNext,
	language,
	deckType,
	skipActivity,
	audioSpeedList,
	archiveword,
	isIntroPlayng,
	loading,
}) => {
	const initialState = {
		activeIndex: 0,
		wordDetails: {},
		imageList: [],
		isAssociation: false,
		isAudioPlaying: false,
		showModal: false,
		submitting: false,
		isAudioPlayed: false,
		isAudioLoaded: false,
	};
	const [state, dispatch] = useReducer(fieldUpdateReducer, initialState);
	const {
		activeIndex,
		wordDetails,
		imageList,
		isAssociation,
		isAudioPlaying,
		showModal,
		submitting,
		isAudioPlayed,
		isAudioLoaded,
	} = state;
	const { t } = useTranslation();

	const grammaticalInfo = [
		{
			label: t("helpers.grammaticalinfo.category"),
			field: "category",
		},
		{
			label: t("helpers.grammaticalinfo.grammaticalforms"),
			field: "grammaticalForms",
		},
		{
			label: t("helpers.grammaticalinfo.root"),
			field: "greekRoot",
		},
		{
			label: t("helpers.grammaticalinfo.root"),
			field: "hebrewRoot",
		},
		{
			label: t("helpers.grammaticalinfo.argumentrealizations"),
			field: "argumentRealizations",
		},
		{
			label: t("helpers.grammaticalinfo.binyan"),
			field: "binyan",
		},
		{
			label: t("helpers.grammaticalinfo.gender"),
			field: "gender",
		},
	];

	const lexicalFields = [
		{
			label: t("helpers.utils.gloss"),
			field: "gloss",
		},
		{
			label: t("helpers.utils.definition"),
			field: "definition",
		},
		{
			label: t("helpers.lexicalfields.semanticdomains"),
			field: "semanticDomain",
		},
		{
			label: t("helpers.utils.culturalinfo"),
			field: "culturalInfo",
		},
	];

	useEffect(() => {
		if (lessonDetails && lessonDetails.activity && lessonDetails.activity.word) {
			dispatch({ wordDetails: lessonDetails.activity.word });
		}
		if (lessonDetails && lessonDetails.stage === 0) {
			dispatch({ isAssociation: true });
		} else {
			dispatch({ isAssociation: false });
		}
	}, [lessonDetails]);

	// to play audio after introvideo playing
	useEffect(() => {
		if (!isIntroPlayng && !isAudioPlayed && isAudioLoaded) {
			setSpeedHandlerFunction();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIntroPlayng]);

	useEffect(() => {
		if (wordDetails) {
			let imageList = [];
			if (wordDetails.illustrations && wordDetails.illustrations.length > 0) {
				imageList = wordDetails.illustrations.map((audio) => ({
					url: audio.filePath,
					name: audio.caption,
				}));
			}
			dispatch({ imageList });
		}
	}, [wordDetails]);

	useEffect(() => {
		const { stage } = lessonDetails;
		if (stage === 0 || stage === 1) {
			document.addEventListener("keydown", onKeyDown);
		}
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessonDetails, disableNext]);

	const onKeyDown = (e) => {
		const keyCode = e.keyCode;
		const {
			stage,
			activity: { thumbnails },
		} = lessonDetails;
		if (stage === 0) {
			if (keyCode === 37) {
				//left
				updateAcitivity("previous");
			} else if (keyCode === 39) {
				//right
				const index = thumbnails.map((el) => el.isCurrent).indexOf(true);
				if (thumbnails.length - 1 === index || thumbnails[index].status === 1) {
					updateAcitivity(skipActivity ? "skip" : "next");
				} else {
					updateAcitivity("get", thumbnails[index + 1].activityId);
				}
			} else if (keyCode === 76) {
				// play audio
				setSpeedHandlerFunction();
			}
		} else if (stage === 1) {
			if (keyCode === 39 && !disableNext) {
				//right
				const index = thumbnails.map((el) => el.isCurrent).indexOf(true);
				if (thumbnails.length - 1 === index || thumbnails[index].status === 1) {
					updateAcitivity(skipActivity ? "skip" : "next");
				} else {
					updateAcitivity("get", thumbnails[index + 1].activityId);
				}
			} else if (keyCode === 76) {
				// play audio
				setSpeedHandlerFunction();
			}
		}
	};

	const getLabel = () => {
		let label = "";
		if (wordDetails) {
			if (deckType && wordDetails.imageCaption) {
				label = wordDetails.imageCaption;
			} else if (wordDetails.definition) {
				label = wordDetails.definition;
			} else if (wordDetails.gloss) {
				label = wordDetails.gloss;
			}
		}
		return label;
	};

	const setSpeedHandlerFunction = () => {
		const audioSpeed = audioSpeedList.filter((val) => val.audioId === wordDetails.id);
		const speed = audioSpeed && audioSpeed[0] ? audioSpeed[0]["speed"] : 1;
		togglePlay(dispatch, speed);
	};

	const getVales = (values, index) => {
		const list = [];
		fields.forEach((el) => {
			if (values[el.field]) {
				list.push({ label: el.label, value: values[el.field] });
			}
		});

		if (list.length > 0) {
			return (
				<div key="form" className="d-flex flex-column field-details mr-3">
					<label className="field-name">{t("helpers.grammaticalinfo.grammaticalforms")}</label>
					<span className={`field-value  d-flex flex-wrap`}>
						{list.map((el, idx) => (
							<div key={idx} className="inline-block">
								<span>{el.label}</span>.
								<span className={`${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`}>
									{`${el.value}${idx !== list.length - 1 ? "," : ""} `}&nbsp;
								</span>
							</div>
						))}
					</span>
				</div>
			);
		}

		return null;
	};

	const lexicalAndGrammarBody = (list, name) => {
		const fieldList = name === "lexical" ? lexicalFields : grammaticalInfo;
		return (
			<>
				<div className="d-flex flex-wrap">
					{fieldList.map(
						(field, index) =>
							list[field.field] &&
							(field.field === "grammaticalForms" ? (
								getVales(list[field.field], index)
							) : (
								<div key={field.label} className="d-flex flex-column field-details mr-2">
									<label className="field-name">{field.label}</label>
									<span
										className={`field-value ${
											(fields.field === "argumentRealizations" ||
												fields.field === "definition" ||
												field.field === "hebrewRoot" ||
												field.field === "greekRoot") &&
											` ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"}`
										}`}>
										{list[field.field]}
									</span>
								</div>
							))
					)}

					{name === "lexical" && (
						<div>
							{list.culturalInfo && (
								<div className="d-flex flex-column field-details">
									<label className="field-name">{t("helpers.utils.culturalinfo")}</label>
									<div className="d-flex flex-column ex-wrapper">
										<span
											className={`ref `}
											dangerouslySetInnerHTML={{
												__html: list.culturalInfo,
											}}></span>
									</div>
								</div>
							)}
							{list.examples && list.examples.length > 0 && (
								<div className="d-flex flex-column field-details">
									<label className="field-name">{t("languagelearning.lessons.details.examples")}</label>
									{list.examples.map((example) => (
										<div className="d-flex flex-column ex-wrapper" key={example.verse}>
											<label className="verse">{example.verse}</label>
											<span
												className={`ref ${language === "Hebrew" ? "Hebrew-font" : "Greek-font"} ${
													language === "Hebrew" ? "d-rtl" : ""
												}`}
												dangerouslySetInnerHTML={{
													__html: example.reference,
												}}></span>
										</div>
									))}
								</div>
							)}
						</div>
					)}
				</div>
			</>
		);
	};

	const renderDetailsSection = () => (
		<div className="d-flex mt-3 dtls-sction bd-highlight">
			{isAssociation && (
				<>
					<div>
						{wordDetails.hasLexicalInfo &&
							overlay(
								lexicalAndGrammarBody(wordDetails.lexicalInfo, "lexical"),
								"icon-Group",
								t("helpers.utils.lexicalinfo")
							)}
					</div>
					<div>
						{wordDetails.hasGrammaticalInfo &&
							overlay(
								lexicalAndGrammarBody(wordDetails.grammaticalInfo, "grammatical"),
								"icon-lowercase-interface-symbol",
								t("helpers.utils.grammaticalinfo")
							)}
					</div>
				</>
			)}
			{(isAssociation || lessonDetails.stage !== 2) &&
				wordDetails &&
				wordDetails.defaultPronunciation &&
				wordDetails.defaultPronunciation.filePath && (
					<div className="flex-fill">
						<div className="mr-2 d-flex bd-highlight justify-content-end align-items-center dropright">
							<button
								type="button"
								onClick={setSpeedHandlerFunction}
								className={`audio-dropdown position-relative lesson-audio dropdown-toggle btn btn-primary ${
									isAudioPlaying ? "is-playing" : ""
								}`}>
								<i className="icon-audio"></i>
							</button>
							<AudioSettingsList audioId={wordDetails.id} utilizer="wordDetails" />
						</div>
						<audio
							src={wordDetails.defaultPronunciation.filePath}
							id="pro-audio"
							className="d-none"
							onEnded={() => dispatch({ isAudioPlaying: false })}
							onLoadedData={() => {
								dispatch({ isAudioLoaded: true });
								if (!isIntroPlayng && !loading && isAssociation) {
									setSpeedHandlerFunction();
									dispatch({ isAudioPlayed: true });
								}
							}}
						/>
					</div>
				)}
		</div>
	);

	const renderTopSection = () => (
		<div className="top-section d-flex justify-content-center">
			<label
				className={`m-0 title d-flex justify-content-center flex-fill ${
					language === "Hebrew" ? "Hebrew-font" : "Greek-font"
				}`}>
				{wordDetails.senseFormLesson}
			</label>
		</div>
	);

	const archiveWord = () => {
		dispatch({ submitting: true });
		archiveword(lessonDetails.activityId).then((res) => {
			dispatch({ showModal: false, submitting: false });
			if (res.statusCode === 200) {
				NotificationManager.success(t("helpers.utils.notification.successfullarchive"), "", 3000);
			} else {
				NotificationManager.error(res.response && res.response.message, t("helpers.utils.notification.error"), 2000);
			}
		});
	};

	const renderModalBody = () => {
		return (
			<div className="text-center">
				<span>{t("helpers.utils.archivemodal.prompt")}</span>
			</div>
		);
	};

	const renderModalFooter = () => {
		return (
			<div className="mb-2 change-modal-footer">
				<button className="btn btn-close" onClick={() => dispatch({ showModal: false })}>
					{t("helpers.utils.no")}
				</button>

				<button className="btn btn-success mr-3" disabled={submitting} onClick={archiveWord}>
					{t("helpers.utils.yes")}
				</button>
			</div>
		);
	};

	return (
		<React.Fragment>
			<SharedModal
				show={showModal}
				modalBody={renderModalBody()}
				modalFooter={renderModalFooter()}
				handleClose={() => {}}
				modalClass="no-header header-student"
			/>
			{isAssociation && renderNavigationSection(lessonDetails, isAssociation, updateAcitivity, deckType, dispatch)}
			<div
				className={`${
					lessonDetails && lessonDetails.stage === 0 ? "word-details-width" : "word-detail-fullwidth"
				} relative`}>
				<div className={`word-details ${!isAssociation ? "not-association" : "is-association"}`}>
					{isAssociation && renderTopSection()}
					{imageList.length > 0 && imageList.some((img) => img.url) ? (
						<ImgSider index={activeIndex} list={imageList} />
					) : (
						<div className="no-image">{getLabel()}</div>
					)}
					{renderActionButtons(lessonDetails, disableNext, isAssociation, skipActivity, updateAcitivity)}
					{isAssociation && renderDetailsSection()}
				</div>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	disableNext: state.studentLanguageLearning.disableNext,
	skipActivity: state.studentLanguageLearning.skipActivity,
	language: state.sideMenu.language,
	deckType: state.studentLanguageLearning.deckType,
	audioSpeedList: state.studentLanguageLearning.audioSpeedList,
});

const mapDispatchToProps = {
	archiveword,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WordDetails));
