import { SETTINGS } from "./types";

const LangSetting = (state = {}, action) => {
	switch (action.type) {
		case SETTINGS.FETCH_ALL_CULTURES: {
			const responseData = action.payload.response.map((res) => {
				return {
					...res,
					module: [
						{ id: 0, title: "Language Learning", flag: 0 },
						{ id: 1, title: "Flashcard Deck", flag: 0 },
						{ id: 2, title: "Bible Reading", flag: 0 },
					],
				};
			});
			return {
				...state,
				cultures: responseData,
			};
		}
		case SETTINGS.GET_RESOURCE: {
			const responseData = JSON.stringify(action.payload.response);
			return {
				...state,
				resource: responseData,
			};
		}
		default:
			return state;
	}
};

export default LangSetting;
