import React, { useState, useEffect, useRef, useReducer } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import { Scrollbars } from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bookReducer from "./bookReducer";
import { useInfiniteScroll } from "../../customHooks/infiniteScroll";
import { getBibleData, updateBibleField } from "./actions";

const BookList = ({ getBibleData, updateBibleField, history, language }) => {

	const { t } = useTranslation();
	const initialState = {
		book: {},
		chapter: {},
		bookDetails: {},
		selectedBook: null,
		latestData: [],
		filter: { [t("helpers.bookstablist.books")]: null, Chapters: "null" },
	};
	const [list, booksDispatch] = useReducer(bookReducer, initialState);
	const [activeTab, setActiveTab] = useState(t("helpers.bookstablist.books"));
	const [bibleData, setBibleData] = useState([]);
	const chapterBoundaryRef = useRef(null);
	const bookBoundaryRef = useRef(null);
	const selectedBookBoundaryRef = useRef(null);
	const booksTabList = [t("helpers.bookstablist.books"), t("helpers.bookstablist.chapters")];
	const bookSortOptions = [
		{ label: t("helpers.booksortoptions.ascending"), value: "Asc" },
		{ label: t("helpers.booksortoptions.descending"), value: "Desc" },
	];

	useEffect(() => {
		fetchBibleData("book");
		fetchBibleData("chapter");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (activeTab === t("helpers.bookstablist.books") && list.book.records) {
			setBibleData(list.book.records);
		} else if (activeTab === t("helpers.bookstablist.chapters") && list.chapter.records) {
			setBibleData(list.chapter.records);
		} else if (list.selectedBook && list.bookDetails.records) {
			setBibleData(list.bookDetails.records);
		}
	}, [activeTab, list]);

	const onScroll = (field) => {
		booksDispatch({
			type: "LOAD_BOOKS",
			callBack: fetchBibleData,
			field,
		});
	};

	useInfiniteScroll(bookBoundaryRef, onScroll, "book");
	useInfiniteScroll(chapterBoundaryRef, onScroll, "chapter");
	useInfiniteScroll(selectedBookBoundaryRef, onScroll, "bookDetails");

	const fetchBibleData = (field, index = 0, bookId, filter = {}) => {
		const filterValue = activeTab === t("helpers.bookstablist.books") ? filter.Books : activeTab === t("helpers.bookstablist.chapters") ? filter.Chapters : "";
		updateBibleField("isLoading", true);
		getBibleData(
			`${bookId ? "chapter" : field}/percentage?Testament=${language === "Hebrew" ? "Old" : "New"}&Index=${index}${bookId ? `&BookId=${bookId}` : ""
			}${filterValue ? `&SortBy=Percentage&SortDirection=${filterValue.value}` : ""}`
		).then((res) => {
			updateBibleField("isLoading", false);
			if (res.statusCode === 200) {
				booksDispatch({ type: "SET_BOOKS", payload: res.response, field });
			}
		});
	};

	const fetchChpaters = (book, tab) => {
		if (tab === t("helpers.bookstablist.books")) {
			booksDispatch({ type: "SET_SELECTED_BOOK", payload: book });
			setActiveTab("");
			setBibleData([]);
			fetchBibleData("bookDetails", 0, book.id);
		} else {
			updateBibleField("selectedBook", book);
			history.push(`/home/${language}/bible-reading`);
		}
	};

	const showBookList = () => {
		booksDispatch({ type: "SET_SELECTED_BOOK", payload: null });
		booksDispatch({
			type: "REMOVE_BOOK_DETAILS",
			field: "bookDetails",
			value: {},
		});
		setActiveTab(t("helpers.bookstablist.books"));
	};

	const onFilterChange = (option) => {
		const filterValue = { ...list.filter };
		filterValue[activeTab] = option;
		booksDispatch({
			type: "SET_FILTER",
			value: filterValue,
			field: activeTab === t("helpers.bookstablist.books") ? "book" : "chapter",
			callBack: fetchBibleData,
		});
		setBibleData([]);
	};

	const getTitle = (tab, record) => {
		switch (tab) {
			case t("helpers.bookstablist.books"):
				return record.book;
			case t("helpers.bookstablist.chapters"):
				return `${record.book} - ${t("dashboard.reports.reading.chapter")} ${record.chapter}`;
			default:
				return `${t("dashboard.reports.reading.chapter")} ${record.chapter ? record.chapter : ""}`;
		}
	};

	const getRef = (tabName) => {
		switch (tabName) {
			case t("helpers.bookstablist.books"):
				return bookBoundaryRef;
			case t("helpers.bookstablist.chapters"):
				return chapterBoundaryRef;
			default:
				return selectedBookBoundaryRef;
		}
	};

	const renderList = (tabName) => (
		<Scrollbars>
			<div>
				{bibleData.map((record, index) => (
					<div
						key={index}
						className={`d-flex justify-content-between book-dtls`}
						onClick={() => fetchChpaters(record, tabName)}>
						<label className="m-0 book-name">{getTitle(tabName, record)}</label>
						<div className="d-flex">
							{tabName === t("helpers.bookstablist.books") && (
								<div className="mr-3 d-flex align-items-center">
									<i className="icon icon-book"></i>
									<label className=" chapter-dtl">{t("biblereading.bibleindex.totalchapters")}:</label>
									<span className="chapter-value">{record.chapters || 0}</span>
								</div>
							)}
							<div className="mr-3 d-flex align-items-center">
								<i className="icon icon-book"></i>
								<label className=" chapter-dtl">{t("biblereading.versesread")}:</label>
								<span className="chapter-value">{record.readingPercentage || 0}%</span>
							</div>
							<div>
								<i className="icon icon-text"></i>
								<label className=" chapter-dtl">{t("biblereading.wordsyoulearned")}:</label>
								<span className="chapter-value">{record.percentage}%</span>
							</div>
						</div>
					</div>
				))}
				<div ref={getRef(tabName)}></div>
			</div>
		</Scrollbars>
	);

	const renderTabContent = (tabName) => (
		<div className="tab-details d-flex flex-column">
			<div className="d-flex justify-content-between mt-2 mb-2 align-items-center">
				<span className="tab-name">{tabName}</span>
				{!list.selectedBook ? (
					<div className="d-flex align-items-center">
						<label className="sort-words">{t("biblereading.bibleindex.sortbywordslearned")}:</label>
						<Select
							value={list.filter[tabName]}
							placeholder={t("biblereading.select")}
							onChange={onFilterChange}
							options={bookSortOptions}
							className="react-select"
							classNamePrefix="react-select"
						/>
					</div>
				) : (
					<div className="view-books" onClick={showBookList}>
						{t("biblereading.bibleindex.viewallbooks")}
					</div>
				)}
			</div>
			{renderList(tabName)}
		</div>
	);

	return (
		<div className="d-flex h-100 books-list">
			<button
				className={`btn btn-default redirect-btn ${list.selectedBook ? "d-none" : ""}`}
				onClick={() => history.push(`/home/${language}/bible-reading`)}>
				{t("biblereading.bibleindex.gotobiblereading")}
				<i className="icon icon-left-arrow-1"></i>
			</button>
			<div className="tab-container w-100">
				<div className={`tab-content ${list.selectedBook ? "" : "d-none"}`}>
					{renderTabContent(list.selectedBook ? list.selectedBook.book : "")}
				</div>
				<div className={list.selectedBook ? "d-none" : "h-100"}>
					<Tabs className={`default-tabs `} activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
						{booksTabList.map((tabName) => (
							<Tab key={tabName} eventKey={tabName} title={tabName} as="div">
								{renderTabContent(tabName)}
							</Tab>
						))}
					</Tabs>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	booksList: state.bible.booksList,
	language: state.sideMenu.language,
});

const mapDispatchToProps = {
	getBibleData,
	updateBibleField,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookList));
