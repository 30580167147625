import { useEffect } from "react";

// infinite scrolling with intersection observer
export const useInfiniteScroll = (scrollRef, dispatch, field) => {
  const scrollObserver = (node, field) => {
    new IntersectionObserver((entries) => {
      entries.forEach((en) => {
        if (en.intersectionRatio > 0) {
          dispatch(field);
        }
      });
    }).observe(node);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollObserver(scrollRef.current, field);
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef]);
};
