export const DictionaryFields = [
  {
    field: "Strongs Number",
    value: "strongsNumber"
  },
  {
    field: "Components",
    value: "components"
  },
  {
    field: "Morph Type",
    value: "morphType"
  },
  {
    field: "Frequency",
    value: "frequency"
  },
  {
    field: "Morphology Tag",
    value: "morphologyTag"
  }
];

export const senseFields = [
  {
    field: "Gloss",
    value: "gloss"
  },
  {
    field: "Grammatical Info",
    value: "grammaticalInfo"
  },
  {
    field: "Semantic domains",
    value: "semanticDomain"
  },
  {
    field: "Encyclopedic info",
    value: "encyclopediaInfo"
  },
  {
    field: "Sense lesson",
    value: "lessonNumber"
  },
  {
    field: "Form",
    value: "word"
  },
  {
    field: "Picture",
    value: "morpgologyTag"
  },
  {
    field: "Binyan",
    value: "binyan",
    language: "Hebrew"
  },
  {
    field: "Language Learning definition",
    value: "languageLearningDefinition"
  },
  {
    field: "Reversal entries",
    value: "reversals"
  }
];

export const LessonFields = [
  {
    field: "GenNoun",
    value: "genN",
    language: "Greek",
    class: "Greek-font"
  },
  {
    field: "Construct",
    value: "construct",
    language: "Hebrew"
  },
  {
    field: "Plural",
    value: "plural",
    language: "Hebrew"
  },
  {
    field: "Plural Construct",
    value: "pluralConstruct",
    language: "Hebrew"
  },
  {
    field: "Dual",
    value: "dual",
    language: "Hebrew"
  },
  {
    field: "Dual Construct",
    value: "dualConstruct",
    language: "Hebrew"
  },
  {
    field: "PerfMid",
    value: "perfMid",
    language: "Greek"
  },
  {
    field: "Perfect",
    value: "perfect",
    language: "Greek",
    class: "Greek-font"
  },
  {
    field: "Future",
    value: "future",
    language: "Greek",
    class: "Greek-font"
  },
  {
    field: "AorPasv",
    value: "aorPasv",
    language: "Greek"
  },
  {
    field: "Aorist",
    value: "aorist",
    language: "Greek",
    class: "Greek-font"
  },
  {
    field: "Imperfect",
    value: "imperfect",
    language: "Greek"
  },
  {
    field: "PresMP",
    value: "presMP",
    language: "Greek"
  },
  {
    field: "ImperfectMP",
    value: "imperfectMP",
    language: "Greek"
  },
  {
    field: "Roots",
    value: "greekRoot",
    language: "Greek"
  },
  {
    field: "Roots",
    value: "hebrewRoot ",
    language: "Hebrew"
  }
];

export const lessonSenses = [
  {
    field: "Language Learning definition",
    value: "languageLearningDefinition"
  },
  {
    field: "Gloss",
    value: "gloss"
  },
  {
    field: "Grammatical Info",
    value: "grammaticalInfo"
  },
  {
    field: "Yiqtol",
    value: "yiqtol",
    language: "Hebrew"
  },
  {
    field: "Qatal",
    value: "qatal",
    language: "Hebrew"
  },
  {
    field: "Wayyiqtol",
    value: "wayyiqtol",
    language: "Hebrew"
  },
  {
    field: "Weqatal",
    value: "weqatal",
    language: "Hebrew"
  },
  {
    field: "Participle",
    value: "participle",
    language: "Hebrew"
  },
  {
    field: "Passive Participle",
    value: "passiveParticiple",
    language: "Hebrew"
  },
  {
    field: "Imperative",
    value: "imperative",
    language: "Hebrew"
  },
  {
    field: "Jussive",
    value: "jussive",
    language: "Hebrew"
  },
  {
    field: "Cohortative",
    value: "cohortative",
    language: "Hebrew"
  },
  {
    field: "Infinitive Construct",
    value: "infinitiveConstruct",
    language: "Hebrew"
  },
  {
    field: "Infinitive Absolute",
    value: "infinitiveAbsolute",
    language: "Hebrew"
  },
  {
    field: "Binyan",
    value: "binyan",
    language: "Hebrew"
  }
];

export const GrammarFields = [
  {
    field: "Lexeme form",
    value: "text"
  },
  {
    field: "Content Relationships",
    value: "contentRelationship"
  },
  {
    field: "Picture cross ref",
    value: "pictureCrossRef"
  },
  {
    field: "Picture 2 Cross ref",
    value: "picture2CrossRef"
  }
];

export const GrammarSenseFields = [
  {
    field: "Gloss",
    value: "gloss"
  },
  {
    field: "Correct answer",
    value: "correctAnswers"
  },
  {
    field: "Speaker",
    value: "speaker"
  },
  {
    field: "Addressee",
    value: "addressee"
  },
  {
    field: "Verb",
    value: "verbs"
  },
  {
    field: "Noun",
    value: "nouns"
  },
  {
    field: "P2 Noun",
    value: "picture2Nouns"
  },
  {
    field: "P2 Verb",
    value: "picture2Verbs"
  },
  {
    field: "PP3",
    value: "pP3"
  },
  {
    field: "P2 PP3",
    value: "picture2PP3"
  }
];

export const senseArrayFields = [
  "correctAnswers",
  "verbs",
  "nouns",
  "picture2Nouns",
  "picture2Verbs"
];
