import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import noData from "../../../assets/images/no-data.png";
import { getUserDetails } from "../../../utils/verifyUser";
import Header from "../../header";
import Loader from "../../sharedComponents/Loader";
import { NoContent } from "../../sharedComponents/NoContent";
import { setSideMenu } from "../../Sidemenu/actions";
import { getAllQuestions } from "./actions";
import "./qna.scss";
import QuestionCard from "./questionCard";

const StudentQnA = ({ menu, language, history, setSideMenu, getAllQuestions, questions }) => {
	const { t } = useTranslation();
	const userDetails = getUserDetails();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const location = history.location.pathname.split("/");
		setSideMenu({
			menu: [{ title: t("menu.q&a") }],
			language: location[2],
		});
	}, [history, setSideMenu, t]);

	useEffect(() => {
		setLoading(true);
		getAllQuestions(userDetails.id).then((res) => {
			setLoading(false);
		});
	}, [getAllQuestions, userDetails.id]);

	return (
		<div className="empty">
			<Header menu={menu} language={language} history={history} head={t("menu.q&a")} />
			{loading && <Loader />}
			{questions && questions.length > 0 ? (
				questions.map((item, index) => <QuestionCard questionDetails={item} key={index} />)
			) : (
				<NoContent bgImage={noData} content={t("qa.default")} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	menu: state.sideMenu.menu,
	language: state.sideMenu.language,
	questions: state.studentQnA.questionList,
});

const mapDispatchToProps = {
	setSideMenu,
	getAllQuestions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentQnA));
