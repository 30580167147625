import { SIDEMENU } from "./types";
const initialState = {
	menu: [],
	language: "Greek",
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SIDEMENU.SET_MENU:
			return {
				...state,
				menu: action.payload.menu,
				language: action.payload.language,
			};
		case SIDEMENU.SESSION_LOG:
			const sessionId = action.payload.response.sessionId;
			localStorage.setItem("sessionId", sessionId);
			return {
				...state,
			};
		default:
			return state;
	}
}
