import moment from "moment";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { NoContent } from "../../sharedComponents/NoContent";
import noData from "../../../assets/images/no-data.png";
import { useTranslation } from "react-i18next";

const AudioLibrary = ({ header, data }) => {
	const [audio, setAudio] = useState("");
	const {t} = useTranslation();

	const stopAllAudio = () => {
		const sounds = document.getElementsByTagName("audio");
		for (let i = 0; i < sounds.length; i++) {
			sounds[i].pause();
		}
	};

	const togglePlay = (filePath) => {
		if (filePath) {
			const audioData = filePath;
			const player = document.getElementById(audioData);
			if (player.paused) {
				stopAllAudio();
				setAudio(audioData);
				player.play();
			} else {
				player.pause();
				setAudio("");
				stopAllAudio();
			}
		} else {
			!(NotificationManager.listNotify && NotificationManager.listNotify.length > 0) &&
				NotificationManager.error(t("helpers.utils.notification.audionotavailablemsg"), "", 3000);
		}
	};

	return (
		<Table className="reports-table-container mb-0">
			<thead>
				<tr className="spacer"></tr>
				<tr>
					{header && header.map((el, idx) => (
						<th key={idx}>{el.label}</th>
					))}
				</tr>
			</thead>
			{data && data.records.length > 0 ? (
				<tbody className="t-body-class">
					{data.records.map((el, idx) => (
						<React.Fragment key={idx}>
							<tr className="spacer"></tr>
							<tr className="column-table options">
								{header && header.map((item, id) => {
									if (item.type === "audio" && (el.filePath || el.originalFilePath)) {
										return (
											<td className="position-relative">
												<i
													className={`position-absolute pointer ${
														audio === `${idx}-${item.key}-${el[item.key]}`
															? "icon-pause"
															: "icon-play"
													} `}
													onClick={() => togglePlay(`${idx}-${item.key}-${el[item.key]}`)}
													style={{ fontSize: 35, top: 5 }}></i>
												<audio
													controls
													src={el[item.key]}
													id={el[item.key] ? `${idx}-${item.key}-${el[item.key]}` : ""}
													className="d-none"
													onEnded={() => setAudio("")}
												/>
											</td>
										);
									} else {
										return (
											<td
												key={id}
												className={
													item.key === "value" ? (el && el.isCorrect ? "correct" : "incorrect") : ""
												}>
												{item.type === "date"
													? moment(el[item.key]).format("DD/MM/YYYY")
													: el[item.key]}
											</td>
										);
									}
								})}
							</tr>
							<tr className="spacer"></tr>
						</React.Fragment>
					))}
				</tbody>
			) : (
				<tbody>
					<tr className="spacer"></tr>
					<tr style={{ margin: "auto 0" }}>
						<td colSpan={header.length} style={{ textAlign: "center", border: 0 }}>
							<NoContent bgImage={noData} content={t("helpers.utils.nodata")} />
						</td>
					</tr>
				</tbody>
			)}
		</Table>
	);
};
export default AudioLibrary;
