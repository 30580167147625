import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import classRoomImg from "../../assets/images/classroom-img.png";
import SharedModal from "../sharedComponents/Modal";
import { deleteClassroom, getClassroomData } from "../Users/actions";
import AddClassModal from "./addClassModal";

import "./UserProfile.scss";

const ClassRooms = ({ classList, role, user, deleteClassroom, getClassroomData }) => {
	const { t } = useTranslation();
	const [showAddClassModal, setShowAddClassModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [isStudent, setIsStudent] = useState(false);
	const [selectedClass, setSelectedClass] = useState(null);
	const [roleBasedFields, setRoleBasedFields] = useState([]);

	 const classRoomFields = [
		{ label: t("profile.classroom.teachername"), field: "teacher" },
		{ label: t("profile.classroom.name"), field: "name" },
		{ label: t("flashcarddeck.flashcardsettings.sharedecktab.shareoption.options.classroomcode.name"), field: "code" },
		{ label: t("profile.classroom.language"), field: "language" },
	  ];

	useEffect(() => {
		if (classList && classList.length > 0) {
			let newFields = [...classRoomFields];
			if (role !== "Student") {
				newFields.splice(0, 1);
				setIsStudent(false);
			} else {
				setIsStudent(true);
			}
			setRoleBasedFields(newFields);
		}
	}, [classList, role]);

	const editClass = (classRoom) => {
		setSelectedClass(classRoom);
		setShowAddClassModal(true);
	};

	const showConfirmationPopup = (classRoom) => {
		setSelectedClass(classRoom);
		setShowConfirmationModal(true);
	};

	const onModalClose = () => {
		setShowConfirmationModal(false);
		setSelectedClass(null);
	};

	const deleteClass = () => {
		setDeleting(true);
		const url = role === "Student" ? "deleteforstudent" : "delete";
		deleteClassroom(selectedClass.id, url).then((res) => {
			onModalClose();
			setDeleting(false);
			if (res.statusCode === 200) {
				const url = role === "Student" ? "getforstudent" : "getforteacher";
				getClassroomData(url);
				NotificationManager.success(t("helpers.utils.notification.deleteclassroom"), "", 3000);
			} else {
				NotificationManager.error(`${res.response && res.response.message ? res.response.message : "Error"}`, "", 3000);
			}
		});
	};

	const renderModalFooter = () => {
		return (
			<div className="mb-2 delete-modal-footer">
				<button className="btn btn-cancel mr-3" onClick={onModalClose}>
					{t("helpers.utils.no")}
				</button>
				<button className="btn btn-upload" disabled={deleting} onClick={deleteClass}>
					{t("helpers.utils.yes")}
				</button>
			</div>
		);
	};

	const renderModalBody = () => {
		return (
			<div className="text-center">
				<span>{t("helpers.utils.classroommodal.deleteprompt")}</span>
			</div>
		);
	};

	const renderClass = (classRoom) => (
		<div className="row class-dtls" key={classRoom.id}>
			{roleBasedFields.map((list, index) => (
				<div className={`${isStudent ? "fb-24" : ""} col-md-3 field-value`} key={list.label}>
					{classRoom[list.field]}
				</div>
			))}
			<div className={`actions ${isStudent ? "flex-fill" : "col-md-3"}`}>
				{role !== "Student" && (
					<i title="Edit" className="icon icon-write mr-3 pointer" onClick={() => editClass(classRoom)}></i>
				)}
				<i
					title="Delete"
					onClick={() => showConfirmationPopup(classRoom)}
					className="icon icon-garbage pointer mr-2"></i>
			</div>
		</div>
	);

	const renderClassList = () => (
		<div className="class-list">
			<div className="row class-header">
				{roleBasedFields.map((list, index) => (
					<div className={`${isStudent ? "fb-24" : ""} col-md-3 title`} key={list.label}>
						{list.label}
					</div>
				))}
			</div>
			{classList.map((classRoom) => renderClass(classRoom))}
			<div className="d-flex justify-content-end align-items-center add-class-wrapper">
				<span className="round-icon mr-2">
					<i className="icon">+</i>
				</span>
				<label className="add-class m-0 pointer" onClick={() => setShowAddClassModal(true)}>
					{t("header.myprofile.sub.addclass.modal.name")}
				</label>
			</div>
		</div>
	);

	return (
		<div className="classroom-container">
			<label className="title">{t("header.myprofile.sub.name")}</label>
			{classList && classList.length > 0 ? (
				renderClassList()
			) : (
				<div className="no-content d-flex justify-content-center align-items-center">
					<div className="d-flex flex-column align-items-center mr-3">
						<label>{t("header.myprofile.sub.youhavenotcreatedanyclassroom")}</label>
						<button className="btn btn-primary pointer" onClick={() => setShowAddClassModal(true)}>
							{t("header.myprofile.sub.addclass.name")}
						</button>
					</div>
					<img src={classRoomImg} alt="" />
				</div>
			)}
			<SharedModal
				show={showConfirmationModal}
				disableHeader={true}
				modalBody={renderModalBody()}
				modalFooter={renderModalFooter()}
				handleClose={onModalClose}
				modalClass="confirmation-modal"
			/>
			<AddClassModal
				show={showAddClassModal}
				selectedClass={selectedClass}
				role={role}
				user={user}
				handleClose={() => {
					setShowAddClassModal(false);
					setSelectedClass(null);
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	classList: state.users.classRooms,
});

const mapDispatchToProps = {
	deleteClassroom,
	getClassroomData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassRooms);
