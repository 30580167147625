import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { isEqual } from "lodash";

import { userDetailsFields } from "./helper";
import Loader from "../sharedComponents/Loader";

export const UserDetails = ({
  show,
  close,
  isAdmin,
  student,
  details,
  editUser,
  isClosing,
  preferences,
}) => {
  const [userFields, setUserFields] = useState([]);
  const userName = details ? `${details.firstName} ${details.lastName}` : "";

  useEffect(() => {
    const studentFields = [
      ...userDetailsFields.slice(0, 4),
      ...userDetailsFields.slice(7, 15),
    ];
    const internalUserFields = [
      ...userDetailsFields.slice(0, 7),
      ...userDetailsFields.slice(12, 15),
    ];
    if (student && !isEqual(userFields, studentFields)) {
      setUserFields(studentFields);
    } else if (!student && !isEqual(userFields, internalUserFields)) {
      setUserFields(internalUserFields);
    }
  }, [userFields, details, student]);

  const getValue = (user, data) => {
    let teachingLanguage = "";
    let subscribedLanguage = "";
    let speakingGreek = "";
    let speakingHebrew = "";
    let teachingHebrew = "";
    let teachingGreek = "";
    let greekPronouncationType = "";
    let hebrewPronouncationType = "";

    const hebrew = preferences.find((lang) => lang.description === "Hebrew");
    const greek = preferences.find((lang) => lang.description === "Greek");
    if (details.languages && details.languages.length) {
      const hebrewDetails = details.languages.find(
        (lang) => lang.language.language === (hebrew ? hebrew.value : "")
      );
      const greekDetails = details.languages.find(
        (lang) => lang.language.language === (greek ? greek.value : "")
      );
      if (hebrewDetails) {
        speakingHebrew = hebrewDetails.experienceInSpeaking;
        teachingHebrew = hebrewDetails.experienceInTeaching;
        hebrewPronouncationType = hebrewDetails.hebrewPronunciation
          ? hebrewDetails.hebrewPronunciation.description
          : "";
      }
      if (greekDetails) {
        speakingGreek = greekDetails.experienceInSpeaking;
        teachingGreek = greekDetails.experienceInTeaching;
        greekPronouncationType = greekDetails.greekPronunciation
          ? greekDetails.greekPronunciation.description
          : "";
      }
      let selectedLang;
      if (details.languages.length === 2) {
        selectedLang = { label: "Both" };
      } else {
        selectedLang = { label: hebrewDetails ? "Hebrew" : "Greek" };
      }
      teachingLanguage = selectedLang.label;
      subscribedLanguage = selectedLang.label;
    }

    switch (user.accessor) {
      case "country":
        return data ? data.name : "";
      case "learningReason":
        return data ? data.reason : "";
      case "teachingLanguage":
        return teachingLanguage;
      case "subscribedLanguage":
        return subscribedLanguage;
      case "speakingGreek":
        return speakingGreek;
      case "speakingHebrew":
        return speakingHebrew;
      case "teachingHebrew":
        return teachingHebrew;
      case "teachingGreek":
        return teachingGreek;
      case "greekPronouncationType":
        return greekPronouncationType;
      case "hebrewPronouncationType":
        return hebrewPronouncationType;
      default:
        return data;
    }
  };

  return (
    <div>
      {show && !details && <Loader />}
      <Modal
        size="lg"
        className={`${isClosing ? "slideOutRight" : "slideInRight"}`}
        animation={false}
        show={show}
        onHide={close}
        id="user-details-modal"
      >
        <Modal.Body>
          {details && (
            <div className="header">
              <div className="top-section d-flex m-0">
                <label className="title flex-fill m-0">{userName}</label>
                {isAdmin && (
                  <i className="icon icon-write pointer" onClick={editUser}></i>
                )}
              </div>
              {details.role && (
                <p className="role mb-2">{details.role.description}</p>
              )}
              <p className="email mb-2">{details.email}</p>
              <p className="phone m-0">{details.phoneNumber}</p>
            </div>
          )}
          <div className="body">
            {details &&
              userFields.map(
                (user) =>
                  getValue(user, details[user.accessor]) && (
                    <div className="field" key={user.label}>
                      <label className="title">{user.label}</label>
                      <p className="field-value">
                        {getValue(user, details[user.accessor])}
                      </p>
                    </div>
                  )
              )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
