import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Card } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

import Header from "../../../header";
import { getUserDetails } from "../../../../utils/verifyUser";
import { updateField } from "../../LanguageLearning/actions";
import { setSideMenu } from "../../../Sidemenu/actions";
import { getLessonData, getReadingData, getPracticeData, deletePlannerTaskId, deletePlannerPlanId } from "../action";
import ReportTableComponent from "../ReportTable";
import Loader from "../../../sharedComponents/Loader";
import Pagination from "../../../sharedComponents/Pagination";
import "./planner.scss";
import { useTranslation } from "react-i18next";

const INDEX = 0;
const SIZE = 10;

const PlannerReport = ({
	match,
	menu,
	history,
	getLessonData,
	getReadingData,
	getPracticeData,
	setSideMenu,
	deletePlannerTaskId,
	deletePlannerPlanId,
}) => {
	const { t } = useTranslation();

	const header = {
		lesson: [
			{ label: t("dashboard.planner.planname"), key: "name"},
			{ label: t("dashboard.planner.nexttask"), key: "lesson", type: "taskLink" },
			{ label: t("dashboard.planner.taskdue"), key: "dueOn", type: "date" },
			{ label: t("dashboard.planner.plancompletiondate"), key: "planCompletionDate", type: "date" },
			{ label: t("helpers.utils.lessonstatus.completed"), key: "status", type: "status" },
			{ label: t("dashboard.planner.action"), key: "id", type: "action" },
		],
		reading: [
			{ label: t("dashboard.planner.planname"), key: "planName"},
			{ label: t("dashboard.planner.nexttask"), key: "task", type: "taskLink" },
			{ label: t("dashboard.planner.taskdue"), key: "dueOn", type: "date" },
			{ label: t("dashboard.planner.plancompletiondate"), key: "planEndDate", type: "date" },
			{ label: t("helpers.utils.lessonstatus.completed"), key: "status", type: "status" },
			{ label: t("dashboard.planner.action"), key: "id", type: "action" },
		],
		practice: [
			{ label: t("dashboard.planner.planname"), key: "name"},
			{ label: t("dashboard.planner.nexttask"), key: "nextTask", type: "taskLink" },
			{ label: t("dashboard.planner.taskdue"), key: "dueOn", type: "date" },
			{ label: t("dashboard.planner.plancompletiondate"), key: "planEndDate", type: "date" },
			{ label: t("helpers.utils.lessonstatus.completed"), key: "status", type: "status" },
			{ label: t("dashboard.planner.action"), key: "id", type: "action" },
		],
	};
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [activePage, setActivePage] = useState(0);
	const language = match.params.language;
	useEffect(() => {
		const isStudentMode = getUserDetails().role.description !== "Student";
		updateField("isStudentMode", isStudentMode);
		setSideMenu({
			menu: [
				{ title: t("dashboard.name"), path: `/Home/${match.params.language}/Dashboard` },
				{ title: t("dashboard.reports.name"), path: "" },
			],
			language: match.params.language,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = async (index, size) => {
		setLoading(true);
		let data = null;
		if (match.params.goals === "lesson") {
			const { response } = await getLessonData(language, index, size, true);
			data = response;
		} else if (match.params.goals === "reading") {
			const { response } = await getReadingData(language, index, size, true);
			data = response;
		} else if (match.params.goals === "practice") {
			const { response } = await getPracticeData(language, index, size, true);
			data = response;
		}
		data["planType"] = match.params.goals;
		setTableData(data);
		setLoading(false);
	};

	useEffect(() => {
		getData(INDEX, SIZE);
	}, []);

	const deletePlannerTaskCB = {
		lesson: async (id) => {
			setLoading(true);
			const { response: res, statusCode } = await deletePlannerTaskId("lesson", id);
			if (statusCode === 200) {
				const { response } = await getLessonData(language, INDEX, SIZE, true);
				setTableData(response);
				NotificationManager.success(t("dashboard.planner.notifications.taskdeletedsuccessfully"), "", 3000);
			} else {
				NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
			}
			setLoading(false);
		},
		reading: async (id) => {
			setLoading(true);
			const { response: res, statusCode } = await deletePlannerTaskId("reading", id);
			if (statusCode === 200) {
				const { response } = await getReadingData(language, INDEX, SIZE, true);
				setTableData(response);
				NotificationManager.success(t("dashboard.planner.notifications.taskdeletedsuccessfully"), "", 3000);
			} else {
				NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
			}
			setLoading(false);
		},
		practice: async (id) => {
			setLoading(true);
			const { response: res, statusCode } = await deletePlannerTaskId("practice", id);
			if (statusCode === 200) {
				const { response } = await getPracticeData(language, INDEX, SIZE, true);
				setTableData(response);
				NotificationManager.success(t("dashboard.planner.notifications.taskdeletedsuccessfully"), "", 3000);
			} else {
				NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
			}
			setLoading(false);
		},
	};

	const deletePlannerPlanCB = {
		lesson: async (id) => {
			setLoading(true);
			const { response: res, statusCode } = await deletePlannerPlanId("lesson", id);
			if (statusCode === 200) {
				const { response } = await getLessonData(language, INDEX, SIZE, true);
				setTableData(response);
				NotificationManager.success(t("dashboard.planner.notifications.plandeletedsuccessfully"), "", 3000);
			} else {
				NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
			}
			setLoading(false);
		},
		reading: async (id) => {
			setLoading(true);
			const { response: res, statusCode } = await deletePlannerPlanId("reading", id);
			if (statusCode === 200) {
				const { response } = await getReadingData(language, INDEX, SIZE, true);
				setTableData(response);
				NotificationManager.success(t("dashboard.planner.notifications.plandeletedsuccessfully"), "", 3000);
			} else {
				NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
			}
			setLoading(false);
		},
		practice: async (id) => {
			setLoading(true);
			const { response: res, statusCode } = await deletePlannerPlanId("practice", id);
			if (statusCode === 200) {
				const { response } = await getPracticeData(language, INDEX, SIZE, true);
				setTableData(response);
				NotificationManager.success(t("dashboard.planner.notifications.plandeletedsuccessfully"), "", 3000);
			} else {
				NotificationManager.error(t("dashboard.planner.notifications.failedtodeleteplan"), "", 3000);
			}
			setLoading(false);
		},
	};
	const titleSelector = (arg) => {
		if (arg === "lesson") {
			return t("dashboard.lessongoals");
		} else if (arg === "reading") {
			return t("dashboard.readinggoals");
		} else if (arg === "reading") {
			return t("dashboard.readinggoals");
		} else {
			return " ";
		}
	};
	return (
		<div className="planner-report-container empty">
			<Header
				menu={menu}
				language={match.params.language}
				history={history}
				head={match.params.goals && titleSelector(match.params.goals)}
				enableLanguageSwitch
				redirectionUrl="Dashboard"
			/>
			{loading && <Loader />}
			<div className="planner-report-content">
				<Container fluid style={{ paddingLeft: 10, paddingRight: 10, height: "100%" }}>
					{tableData && (
						<Card className="no-shadow" style={{ height: "100%" }}>
							<div className="table-wrapper">
								<ReportTableComponent
									header={header[match.params.goals]}
									data={tableData}
									taskDeleteAction={deletePlannerTaskCB[match.params.goals]}
									planDeleteAction={deletePlannerPlanCB[match.params.goals]}
									planType={tableData["planType"]}
									language={match.params.language}
								/>
								<Pagination
									activePage={activePage + 1}
									itemsCountPerPage={SIZE}
									totalItemsCount={tableData.count}
									pageRangeDisplayed={7}
									onChange={(pageNumber) => {
										setActivePage(pageNumber - 1);
										getData(pageNumber - 1, SIZE);
									}}
								/>
							</div>
						</Card>
					)}
				</Container>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({ menu: state.sideMenu.menu });
const mapDispatchToProps = {
	setSideMenu,
	updateField,
	getLessonData,
	getReadingData,
	getPracticeData,
	deletePlannerTaskId,
	deletePlannerPlanId,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlannerReport));
